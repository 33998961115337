/**
 * クラス名：装置設定の加工速度画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space } from 'antd';
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import {
  confirmUpdateContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';

const MachineSpeed = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  // 新規係数区分名modal
  const [isYousetsuSenCho, setIsYousetsuSenchou] = useState(false);
  // 板厚modal
  const [isThickness, setIsThickness] = useState(false);

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isDiscardData, setIsDiscardData] = useState(false);
  const [processName, setProcessName] = useState('');

  // 溶接線長
  const [detailsWeldlineTemp, setDetailWeldlineTemp] = useState([]);
  const [detailsWeldLine, setDetailsWeldLine] = useState([]);
  const [weldLineList, setWeldLineList] = useState([]);

  const [detailsSenchou, setDetailsSenchou] = useState([]);
  const [detailsSenchouTemp, setDetailsSenchouTemp] = useState([]);
  const [senchouList, setSenchouList] = useState([]);

  const [addSenchouMaster, setAddSenchouMaster] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);
    setProcessName(props.processName);

    setDetailsWeldLine(props.detailsWeldLine);
    setDetailWeldlineTemp(props.detailsWeldLine);
    setWeldLineList(props.weldLineLst);

    setDetailsSenchou(props.detailsWeldLine);
    setDetailsSenchouTemp(props.detailsWeldLine);
    setSenchouList(props.senchouList);

    setAddSenchouMaster(props.detailsWeldLine);
  }, [props.processName, props.editMode, props.detailsWeldLine, props.weldLineLst, props.senchouList]);

  const addYousetsuSenchoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>溶接線長</p>
    </div>
  );
  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };
  const updateDataOk = () => {
    if (isYousetsuSenCho == true) {
    }
  };
  const updateDataCancel = () => {
    if (isYousetsuSenCho == true) {
      setIsYousetsuSenchou(true);
    }
    setIsUpdateData(false);
  };

  const discardOk = () => {
    if (isYousetsuSenCho == true) {
      setIsYousetsuSenchou(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isYousetsuSenCho == true) {
      setIsYousetsuSenchou(true);
    } else if (isThickness === true) {
      setIsThickness(true);
    }
    setIsDiscardData(false);
  };
  const closeAddModal = () => {
    if (isYousetsuSenCho === true) {
      setIsYousetsuSenchou(false);
    } else if (isThickness === true) {
      setIsThickness(false);
    }
  };
  const addYousetsuSencho = (id) => {
    const copyData = detailsWeldlineTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeldlineTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsWeldlineTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailWeldlineTemp(copyData);
      setAddSenchouMaster(copyData);
    }
  };
  const deleteYousetsuSenchou = (id, e) => {
    if (detailsWeldlineTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsWeldlineTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailWeldlineTemp(updatedData);
      setAddSenchouMaster(copyData);
    }
  };
  const deleteYosetsushiageSenchou = (id, e) => {
    if (detailsSenchouTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsSenchouTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsSenchouTemp(updatedData);
      setAddSenchouMaster(copyData);
    }
  };
  const addYosetsushiageSenchou = (id) => {
    const copyData = detailsSenchouTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsSenchouTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsSenchouTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsSenchouTemp(copyData);
      setAddSenchouMaster(copyData);
    }
  };

  const handleYousetsuSenchouChange = (event, id, field) => {
    const updatedData = addSenchouMaster.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailWeldlineTemp(updatedData);
    setDetailsSenchouTemp(updatedData);
    setAddSenchouMaster(updatedData);
  };
  const handleItaatsuChange = (event, id, field) => {
    const updatedData = detailsSenchouTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsSenchouTemp(updatedData);
  };
  const cancelYousetsuSenchoMaster = () => {
    setDetailWeldlineTemp(detailsWeldLine);
    setIsYousetsuSenchou(false);
  };
  const cancelYousetsuShiageSenchoMaster = () => {
    setDetailsSenchouTemp(detailsSenchou);
    setIsYousetsuSenchou(false);
  };
  const addYousetsuSenchoMaster = () => {
    setIsYousetsuSenchou(false);

    const deletedId = [];

    detailsWeldLine.forEach((item1, index) => {
      const found = detailsWeldlineTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsWeldlineTemp
      .filter((item) => item.displayFlag && !detailsWeldLine.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = weldLineList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsWeldlineTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsWeldlineTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setDetailsWeldLine(detailsWeldlineTemp);
    setWeldLineList(sortedList);
    props.updatedYousetsuSenchou(detailsWeldlineTemp);
    props.updatedYousetsuSenchouList(sortedList);
  };

  const addYousetsuShiageSenchoMaster = () => {
    setIsYousetsuSenchou(false);

    const deletedId = [];

    detailsSenchou.forEach((item1, index) => {
      const found = detailsSenchouTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsSenchouTemp
      .filter((item) => item.displayFlag && !detailsSenchou.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = senchouList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsSenchouTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsSenchouTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setDetailsSenchou(detailsSenchouTemp);
    setSenchouList(sortedList);
    props.updatedSenchouList(sortedList);
    props.updatedYousetsuSenchou(detailsSenchouTemp);
  };
  // const cancelItaatsuMaster = () => {
  //   setDetailsSenchouTemp(detailsSenchou);
  //   setIsThickness(false);
  // };
  // const addItaatsuMaster = () => {
  //   const deletedId = [];

  //   detailsSenchou.forEach((item1, index) => {
  //     const found = detailsSenchouTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
  //     if (found) {
  //       deletedId.push(item1.id);
  //     }
  //   });

  //   const newId = detailsSenchouTemp
  //     .filter((item) => item.displayFlag && !detailsSenchou.some((data2Item) => data2Item.id === item.id))
  //     .map((item) => item.id);

  //   const deletedData = senchouList.map((item) => {
  //     return {
  //       ...item,
  //       lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
  //     };
  //   });
  //   const updatedData = deletedData.map((item) => {
  //     newId.forEach((key) => {
  //       // Add new objects to lstData
  //       item.lstData.push({
  //         key: key,
  //         value: '',
  //       });
  //     });

  //     return item;
  //   });
  //   const sortedList = updatedData.map((item) => {
  //     const sortedLstData = [...item.lstData];
  //     sortedLstData.sort((a, b) => {
  //       const aIndex = detailsSenchouTemp.findIndex((obj) => obj.id === a.key);
  //       const bIndex = detailsSenchouTemp.findIndex((obj) => obj.id === b.key);
  //       return aIndex - bIndex;
  //     });

  //     return {
  //       key: item.key,
  //       materialType: item.materialType,
  //       lstData: sortedLstData,
  //     };
  //   });

  //   setIsThickness(false);
  //   setDetailsSenchou(detailsSenchouTemp);

  //   setSenchouList(sortedList);
  //   // props.updatedItaatsu(detailsSenchouTemp);

  //   props.updatedSenchouList(sortedList);
  // };
  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {addSenchouMaster
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleYousetsuSenchouChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() =>
                              processName === '溶接' ? addYousetsuSencho(item.id) : addYosetsushiageSenchou(item.id)
                            }
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) =>
                              processName === '溶接'
                                ? deleteYousetsuSenchou(item.id, e)
                                : deleteYosetsushiageSenchou(item.id, e)
                            }
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className="mainButton"
          id="update"
          onClick={processName === '溶接' ? addYousetsuSenchoMaster : addYousetsuShiageSenchoMaster}
        >
          OK
        </Button>
        <Button
          className="cancelButton"
          style={{ marginLeft: 10 }}
          id="discard"
          onClick={processName === '溶接' ? cancelYousetsuSenchoMaster : cancelYousetsuShiageSenchoMaster}
        >
          キャンセル
        </Button>
      </Row>
    </>
  );
  // const addThicknessContent = (
  //   <>
  //     <div style={{ height: 400, overflow: 'auto' }}>
  //       <Row className="chargeAmount registerModal">
  //         <Col style={{ width: '100%' }}>
  //           <Table>
  //             <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
  //               <tr style={{ textAlign: 'center' }}>
  //                 <th style={{ width: '20%' }} className="tbl-header">
  //                   No
  //                 </th>

  //                 <th style={{ width: '60%' }} className="tbl-header">
  //                   <Space>
  //                     <label>しきい値</label>（≦）
  //                   </Space>
  //                 </th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {detailsSenchouTemp
  //                 .filter((subitem) => subitem.displayFlag === true)
  //                 .map((item, index) => (
  //                   <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
  //                     <td>{index + 1}</td>

  //                     <td>
  //                       <Input
  //                         value={item.max}
  //                         onChange={(e) => handleItaatsuChange(e, item.id, 'max')}
  //                         style={{ pointerEvents: editMode ? 'auto' : 'none' }}
  //                       ></Input>
  //                     </td>
  //                     <td
  //                       style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
  //                       className="min"
  //                     >
  //                       <Row justify="center">
  //                         <PlusCircleFilled
  //                           onClick={() => addItaatsu(item.id)}
  //                           className="add-remove-icon"
  //                           style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
  //                         />
  //                         <CloseCircleFilled
  //                           className="add-remove-icon"
  //                           onClick={(e) => deleteItaatsu(item.id, e)}
  //                           style={{ pointerEvents: editMode ? 'auto' : 'none' }}
  //                         />
  //                       </Row>
  //                     </td>
  //                   </tr>
  //                 ))}
  //             </tbody>
  //           </Table>
  //         </Col>
  //       </Row>
  //     </div>
  //     <Row justify="center" className="mt-2">
  //       <Button className="mainButton" id="update" onClick={addItaatsuMaster}>
  //         OK
  //       </Button>
  //       <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelItaatsuMaster}>
  //         キャンセル
  //       </Button>
  //     </Row>
  //   </>
  // );

  // const thickness = () => {
  //   setIsThickness(true);
  // };
  const yousetsuSenchouModal = () => {
    setIsYousetsuSenchou(true);
  };
  const yousetsuSenchouListDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...weldLineList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    setWeldLineList(updatedData);

    props.updatedYousetsuSenchouList(updatedData);
  };
  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...senchouList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setSenchouList(updatedData);
    props.updatedSenchouList(updatedData);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        {processName === '溶接' ? (
          <>
            <Row>
              <Table
                style={{
                  width:
                    detailsWeldLine.filter((i) => i.displayFlag === true).length > 8
                      ? '100%'
                      : detailsWeldLine.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        溶接種類 /
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={yousetsuSenchouModal}
                              style={{ pointerEvents: 'auto' }}
                            >
                              溶接線長
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>溶接線長</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {detailsWeldLine
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}mm</td>
                    ))}
                </tr>

                {weldLineList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                      {row.materialType}
                    </td>

                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => yousetsuSenchouListDataChange(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Table
                style={{
                  width:
                    detailsSenchou.filter((i) => i.displayFlag === true).length > 8
                      ? '100%'
                      : detailsSenchou.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="tbl-header">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        種類 /
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={yousetsuSenchouModal}
                              style={{ pointerEvents: 'auto' }}
                            >
                              線長
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>線長</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {detailsSenchou
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}mm</td>
                    ))}
                </tr>

                {senchouList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                      {row.materialType}
                    </td>

                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
          </>
        )}
      </div>

      {/* 溶接線長 */}
      {isYousetsuSenCho
        ? commonModal(
            isYousetsuSenCho,
            addYousetsuSenchoModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
      {/* 板厚 */}
      {/* {isThickness
        ? commonModal(
            isThickness,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addThicknessContent,
            null,
            null,
            true
          )
        : ''} */}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            closeAddModal,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default MachineSpeed;
