import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const kensaArr = [
  {
    id: 0,
    processName: '検査(No1)',
    calculateMethod: '子部品点数',
    totalCount: 45,
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    childPart: [
      {
        childName: '板金子部品',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 540,
        csagyoTimeKensa: '00:09:00',
        deviceCount: 0,
        timePerDevice: '00:00:00',
      },
      {
        childName: '形鋼子部品',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 140,
        csagyoTimeKensa: '00:01:40',
        deviceCount: 0,
        timePerDevice: '00:00:00',
      },
      {
        childName: '親部品購入品',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 2100,
        csagyoTimeKensa: '00:35:00',
        deviceCount: 0,
        timePerDevice: '00:00:00',
      },
    ],
    childTypeCount: 70,
    weight: 15.648,
    quality: '高品質',
  },
];

const pmx02KensaArr = [
  {
    id: 0,
    processName: '検査(No1)',
    calculateMethod: '子部品点数',
    totalCount: 45,
    dandoriAmt: 210,
    dandoriTime: '00:03:00',
    childPart: [
      {
        childName: '【板金子部品】',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 1050,
        csagyoTimeKensa: '00:15:00',
        deviceCount: 5,
        timePerDevice: '00:03:00',
      },
    ],
    childTypeCount: 3,
    weight: 8.681,
    quality: '高品質',
  },
];

const pmx03KensaArr = [
  {
    id: 0,
    processName: '検査(No1)',
    calculateMethod: '子部品点数',
    totalCount: 6,
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    childPart: [
      {
        childName: '板金子部品',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 360,
        csagyoTimeKensa: '00:06:00',
        deviceCount: 0,
        timePerDevice: '00:00:00',
      },
      {
        childName: '形鋼子部品',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 0,
        csagyoTimeKensa: '00:00:00',
        deviceCount: 0,
        timePerDevice: '00:00:00',
      },
      {
        childName: '親部品購入品',
        cdandoriAmt: 0,
        cdandoriTime: '00:00:00',
        cSagyoAmtKensa: 0,
        csagyoTimeKensa: '00:00:00',
        deviceCount: 0,
        timePerDevice: '00:00:00',
      },
    ],
    childTypeCount: 6,
    weight: 6.909,
    quality: '高品質',
  },
];

const provinceData = ['子部品点数', '重量'];
const qtyDDL = ['高品質'];

const ParentKouteiCalculate_Kensa = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [subTotalAmt, setSubTotalAmt] = useState([]);
  const [subTotalTime, setSubTotalTime] = useState([]);
  const [totalChild, setTotalChild] = useState([]);

  const [original, setOriginal] = useState([]);
  const [stateData, setStateData] = useState([]);

  const contentDandori = (
    <div>
      <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> ¥2780 = 0 + ¥2780
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br />
        00:45:40 = 00:00:00 + 00:01:00
      </p>
    </div>
  );

  const contentSagyo = (
    <div>
      <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> ¥2780 = 0 + ¥2780
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br />
        00:45:40 = 00:00:00 + 00:01:00
      </p>
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 検査難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        検査難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  const settingEditData = (arr) => {
    let dandoriAmt = 0;
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;
    let totalChild = 0;
    let childSumArr = [];
    let dandoriAmtSum = 0;
    let dandoriTimeSumSec = 0;
    //const kumitateEditArr = JSON.parse(JSON.stringify(arr));
    for (let i = 0; i < arr.length; i++) {
      dandoriAmtSum += arr[i].dandoriAmt;
      dandoriTimeSumSec += setTimetoSec(arr[i].dandoriTime);
      let childPartArr = arr[i].childPart;
      let cSagyoAmtKensaSum = 0;
      let cDadoriAmtSum = 0;
      let csagyoTimeSec = 0;
      let cdandoriTimeSec = 0;
      let subTotalAmt = 0;
      let subTotalTimeSec = 0;

      for (let j = 0; j < childPartArr.length; j++) {
        cSagyoAmtKensaSum += childPartArr[j].cSagyoAmtKensa;
        cDadoriAmtSum += childPartArr[j].cdandoriAmt;
        csagyoTimeSec += setTimetoSec(childPartArr[j].csagyoTimeKensa);
        cdandoriTimeSec += setTimetoSec(childPartArr[j].cdandoriTime);
        totalChild += childPartArr[j].childCount;
      }
      //dandoriAmt += cDadoriAmtSum;
      sagyoAmt += cSagyoAmtKensaSum;
      sagyoTimeSec += csagyoTimeSec;
      //dandoriTimeSec += cdandoriTimeSec;

      childSumArr.push({
        id: i,
        dandoriAmt: dandoriAmt,
        sagyoAmt: sagyoAmt,
        dandoriTimeSec: dandoriTimeSec,
        sagyoTimeSec: sagyoTimeSec,
        dandoriTime: secondsToHms(dandoriTimeSec),
        sagyoTime: secondsToHms(sagyoTimeSec),
        childPart: childPartArr,
        totalCount: totalChild,
        totalChildAmt: dandoriAmt + sagyoAmt,
        totalChildTime: secondsToHms(dandoriTimeSec + sagyoTimeSec),
        calculateMethod: arr[i].calculateMethod,
        childTypeCount: arr[i].childTypeCount,
        weight: arr[i].weight,
        quality: arr[i].quality,
        deviceCount: arr[i].deviceCount,
        timePerDevice: arr[i].timePerDevice,
        subTotalAmt: dandoriAmt + sagyoAmt,
        subTotalTimeSec: dandoriTimeSec + sagyoTimeSec,
        subTotalTime: secondsToHms(subTotalTimeSec),
        dandoriAmtSum: dandoriAmtSum,
        dandoriTimeSum: dandoriTimeSumSec,
      });
    }
    return childSumArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSumSec = 0;
    let dandoriTimeSumSec = 0;
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;
    let subTotalAmtArr = [];
    let subTotalTimeArr = [];
    let totalChildArr = [];
    editDataArr.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum = element.dandoriAmtSum;
      sagyoTimeSumSec += element.sagyoTimeSec;
      dandoriTimeSumSec = element.dandoriTimeSum;
      subTotalAmtArr.push(element.dandoriAmt + element.sagyoAmt);
      subTotalTimeArr.push(secondsToHms(element.dandoriTimeSec + element.sagyoTimeSec));
      totalChildArr.push(element.totalCount);
    });

    let totalSum = (sagyoAmtSum + dandoriAmtSum).toFixed(0);
    let totalTimeSum = secondsToHms(sagyoTimeSumSec + dandoriTimeSumSec);
    let sagyoTimeSum = secondsToHms(sagyoTimeSumSec);
    let dandoriTimeSum = secondsToHms(dandoriTimeSumSec);

    let totals = [];

    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSumSec: sagyoTimeSumSec,
      dandoriTimeSumSec: dandoriTimeSumSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
      subTotalAmtArr: subTotalAmtArr,
      subTotalTimeArr: subTotalTimeArr,
      totalChildArr: totalChildArr,
    });
    return totals;
  };

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    let editData = [];
    let totals = [];
    if (Object.keys(props.selectedDataDetail).length > 0) {
      if (props.selectedDataDetail.imgNo == 'zerofour-02') {
        setOriginal(JSON.parse(JSON.stringify(pmx02KensaArr)));
        editData = settingEditData(pmx02KensaArr);
      } else if (props.selectedDataDetail.imgNo == 'zerofour-03') {
        setOriginal(JSON.parse(JSON.stringify(pmx03KensaArr)));
        editData = settingEditData(pmx03KensaArr);
      } else {
        setOriginal(JSON.parse(JSON.stringify(kensaArr)));
        editData = settingEditData(kensaArr);
      }
    }
    setStateData(editData);
    totals = calculateTotals(editData);
    setTotalAmt(totals[0].totalSum);
    setTotalSum(totals[0].totalSum);

    setTotalTime(totals[0].totalTimeSum);
    setTotalTimeSum(totals[0].totalTimeSum);

    setTotalSagyoAmt(totals[0].sagyoAmtSum);
    setSagyoAmtSum(totals[0].sagyoAmtSum);

    setTotalSagyoTime(totals[0].sagyoTimeSum);
    setSagyoTimeSum(totals[0].sagyoTimeSum);

    console.log(totals[0].dandoriAmtSum);
    setTotalDandoriAmt(totals[0].dandoriAmtSum);
    setDandoriAmtSum(totals[0].dandoriAmtSum);

    setTotalDandoriTime(totals[0].dandoriTimeSum);
    setDandoriTimeSum(totals[0].dandoriTimeSum);

    setSubTotalAmt(totals[0].subTotalAmtArr);
    setSubTotalTime(totals[0].subTotalTimeArr);

    setTotalChild(totals[0].totalChildArr);
  }, [props.selectedDataDetail, props.editMode]);

  const kensa = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  const subpartKensaDetail = (i) => {
    let showHide = document.getElementById('kensaProcess' + i).style.display;

    if (showHide == 'block') {
      document.getElementById('kensaProcess' + i).style.display = 'none';
      document.getElementById('arrowDownKensa' + i).style.display = 'block';
      document.getElementById('arrowUpKensa' + i).style.display = 'none';
    } else {
      document.getElementById('kensaProcess' + i).style.display = 'block';
      document.getElementById('arrowDownKensa' + i).style.display = 'none';
      document.getElementById('arrowUpKensa' + i).style.display = 'block';
    }
  };

  const childDetail = (i) => {
    let showHide = document.getElementById('childPartKensa' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('childPartKensa' + i).style.display = 'none';
      document.getElementById('arrowUpChildKensa' + i).style.display = 'none';
      document.getElementById('arrowDownChildKensa' + i).style.display = 'block';
    } else {
      document.getElementById('childPartKensa' + i).style.display = 'block';
      document.getElementById('arrowUpChildKensa' + i).style.display = 'block';
      document.getElementById('arrowDownChildKensa' + i).style.display = 'none';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, indexi, indexj, objId, timeType, timeAct) => {
    let newState = JSON.parse(JSON.stringify(stateData));
    let oldValue;
    let oldChild = JSON.parse(JSON.stringify(newState[indexi].childPart));

    if (value === undefined) {
      value = 0;
    }

    let childPart = stateData[indexi].childPart;
    if (indexj === '') {
      oldValue = JSON.parse(JSON.stringify(stateData[indexi][objId]));
      newState[indexi][objId] = value;
    } else {
      oldValue = childPart[objId];
      if (timeType === '') {
        childPart[indexj][objId] = parseFloat(value);
      } else {
        childPart[indexj][objId] = value;
      }
      newState[indexi].childPart = childPart;
    }
    if (objId === 'csagyoTimeKensa') {
      if (document.getElementById(indexj + 'cSagyoAmtKensaCu').style.display !== undefined) {
        let showHideCu = document.getElementById(indexj + 'cSagyoAmtKensaCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(indexj + 'cSagyoAmtKensa', childPart[indexj].cSagyoAmtKensa, 'red');
        } else {
          if (timeAct === 'input') {
            let oldValue = oldChild[indexj];
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue.csagyoTimeKensa);
            childPart[indexj].cSagyoAmtKensa = changeAmtwithTime(
              newState[indexi].childPart[indexj].cSagyoAmtKensa,
              timeType,
              timeAct,
              value
            );
          } else {
            newState[indexi].childPart[indexj].cSagyoAmtKensa = changeAmtwithTime(
              newState[indexi].childPart[indexj].cSagyoAmtKensa,
              timeType,
              timeAct,
              value
            );
          }
          newState[indexi].childPart = childPart;
          /* checkInputWithColor(indexi + 'cSagyoAmtKensa', newState[indexi].sagyoAmt, 'green'); */
          checkInputWithColor(indexj + 'cSagyoAmtKensa', childPart[indexj].cSagyoAmtKensa, 'green');
        }
      }
    }

    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;
    let dandoriAmt = 0;
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;
    let sagyoTimeSumSec = 0;
    let dandoriTimeSumSec = 0;
    let totalChild = 0;
    let childSumArrState = [];
    for (let i = 0; i < newState.length; i++) {
      let childPartArr = newState[i].childPart;
      let cSagyoAmtKensaSum = 0;
      let cDadoriAmtSum = 0;
      let csagyoTimeSec = 0;
      let cdandoriTimeSec = 0;
      for (let j = 0; j < childPartArr.length; j++) {
        cSagyoAmtKensaSum += childPartArr[j].cSagyoAmtKensa;
        cDadoriAmtSum += childPartArr[j].cdandoriAmt;
        csagyoTimeSec += setTimetoSec(childPartArr[j].csagyoTimeKensa);
        cdandoriTimeSec += setTimetoSec(childPartArr[j].cdandoriTime);
        totalChild += childPartArr[j].childCount;
      }
      dandoriAmt += cDadoriAmtSum;
      sagyoAmt += cSagyoAmtKensaSum;
      sagyoTimeSec += csagyoTimeSec;
      dandoriTimeSec += cdandoriTimeSec;
      childSumArrState.push({
        id: i,
        dandoriAmt: dandoriAmt,
        sagyoAmt: sagyoAmt,
        dandoriTimeSec: dandoriTimeSec,
        sagyoTimeSec: sagyoTimeSec,
        dandoriTime: secondsToHms(dandoriTimeSec),
        sagyoTime: secondsToHms(sagyoTimeSec),
        childPart: newState[i].childPart,
        totalCount: totalChild,
        totalChildAmt: dandoriAmt + sagyoAmt,
        totalChildTime: secondsToHms(dandoriTimeSec + sagyoTimeSec),
        calculateMethod: newState[i].calculateMethod,
        childTypeCount: newState[i].childTypeCount,
        weight: newState[i].weight,
        quality: newState[i].quality,
      });
    }
    childSumArrState.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSumSec += element.sagyoTimeSec;
      dandoriTimeSumSec += element.dandoriTimeSec;
    });

    let totalSum = (sagyoAmtSum + dandoriAmtSum).toFixed(0);
    let totalTimeSum = secondsToHms(sagyoTimeSumSec + dandoriTimeSumSec);
    let sagyoTimeSum = secondsToHms(sagyoTimeSumSec);
    let dandoriTimeSum = secondsToHms(dandoriTimeSumSec);

    /* checkInputWithColor(indexi + 'kensaSubTotal', sagyoAmtSum, 'green');
    checkInputWithColor(indexi + 'kensaSubTotalTime', sagyoTimeSum, 'green'); */
    checkInputWithColor('kensaTotalSagyoAmt', sagyoAmtSum, 'green');
    //checkInputWithColor('kensaTotalDandoriAmt', dandoriAmtSum, 'green');
    checkInputWithColor('kensaTotalAmt', totalSum, 'green');
    checkInputWithColor('kensaTotalSagyoTime', sagyoTimeSum, 'green');
    //checkInputWithColor('kensaTotalDandoriTime', dandoriTimeSum, 'green');
    checkInputWithColor('kensaTotalTime', totalTimeSum, 'green');
    setTotalSagyoAmt(sagyoAmtSum);
    setTotalDandoriAmt(dandoriAmtSum);
    setTotalAmt(totalSum);
    setTotalSagyoTime(sagyoTimeSum);
    setTotalDandoriTime(dandoriTimeSum);
    setTotalTime(totalTimeSum);
    setStateData(childSumArrState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    //console.log('intAmt', typeTime);
    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={kensa}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>検査</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{JPYs.format(totalSum)}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>検査_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            検査
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="kensaTotalAmt">{JPYs.format(totalSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="kensaTotalTime">{totalTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="kensaTotalAmtIn" className="resetColor">
              {JPYs.format(totalAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="kensaTotalTimeIn" className="resetColor">
              {totalTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="kensaTotalDandoriAmt">{JPYs.format(dandoriAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="kensaTotalDandoriTime">{dandoriTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="kensaTotalDandoriAmtIn" className="resetColor">
              {/* {JPYs.format(totalDandoriAmt)} */}
              {JPYs.format(dandoriAmtSum)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="kensaTotalDandoriTimeIn" className="resetColor">
              {/* {totalDandoriTime} */}
              {dandoriTimeSum}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            作業金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="kensaTotalSagyoAmt">{JPYs.format(sagyoAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="kensaTotalSagyoTime">{sagyoTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="kensaTotalSagyoAmtIn" className="resetColor">
              {JPYs.format(totalSagyoAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="kensaTotalSagyoTimeIn" className="resetColor">
              {totalSagyoTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>

        {(() => {
          const arr = [];
          for (let i = 0; i < stateData.length; i++) {
            arr.push(
              <div>
                {(() => {
                  const subArr = [];
                  for (let j = 0; j < original[i].childPart.length; j++) {
                    subArr.push(
                      <div>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                            <div style={{ display: 'flex' }}>
                              <CaretUpOutlined id={'arrowUpChildKensa' + j} style={{ fontSize: 17, marginTop: 2 }} />
                              <CaretDownOutlined
                                id={'arrowDownChildKensa' + j}
                                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                              />
                              <span>{original[i].childPart[j].childName}作業金額/時間</span>
                            </div>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                            <label id={j + 'cSagyoAmtKensa'}>
                              {JPYs.format(original[i].childPart[j].cSagyoAmtKensa)}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                            <label id={i + 'csagyoTimeKensa' + j}>{original[i].childPart[j].csagyoTimeKensa}</label>
                          </Col>
                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'cSagyoAmtKensaIn'}
                              value={stateData[i].childPart[j].cSagyoAmtKensa}
                              defaultValue={0}
                              onValueChange={(e) => {
                                if (stateData[i].childPart[j].cSagyoAmtKensa != e) {
                                  settingNewDandoriAmtInArr(e, i, j, 'cSagyoAmtKensa', '', '');
                                  checkInputWithColor(j + 'cSagyoAmtKensa', e, 'amt');
                                }
                              }}
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'cSagyoAmtKensaCu'} style={{ display: 'none' }} className="currencyReset">
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={stateData[i].childPart[j].csagyoTimeKensa}
                                  id={i + 'csagyoTimeKensa' + j + 'In'}
                                  style={{ width: '100%' }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    //disableTimer('test');

                                    settingNewDandoriAmtInArr(
                                      e.target.value,
                                      i,
                                      j,
                                      'csagyoTimeKensa',
                                      'input',
                                      'input'
                                    );
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          stateData[i].childPart[j].csagyoTimeKensa,
                                          '10min',
                                          i + 'csagyoTimeKensa' + j
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, j, 'csagyoTimeKensa', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          stateData[i].childPart[j].csagyoTimeKensa,
                                          '10min',
                                          i + 'csagyoTimeKensa' + j
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, j, 'csagyoTimeKensa', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          stateData[i].childPart[j].csagyoTimeKensa,
                                          'min',
                                          i + 'csagyoTimeKensa' + j
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, j, 'csagyoTimeKensa', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          stateData[i].childPart[j].csagyoTimeKensa,
                                          'min',
                                          i + 'csagyoTimeKensa' + j
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, j, 'csagyoTimeKensa', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          stateData[i].childPart[j].csagyoTimeKensa,
                                          'sec',
                                          i + 'csagyoTimeKensa' + j
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, j, 'csagyoTimeKensa', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          stateData[i].childPart[j].csagyoTimeKensa,
                                          'sec',
                                          i + 'csagyoTimeKensa' + j
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, j, 'csagyoTimeKensa', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}></Col>
                          <Col span={5} className="formula-column" onClick={(e) => childDetail(j)}>
                            {/* <Popover content={contentSub}>{original[j].formula}</Popover> */}
                          </Col>
                        </Row>
                        <div id={'childPartKensa' + j} style={{ display: 'block' }}>
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">個数/1つ当たりの組立時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="deviceCount">{original[i].childPart[j].deviceCount}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="timePerDevice">{original[i].childPart[j].timePerDevice}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <InputNumber
                                value={stateData[i].childPart[j].deviceCount}
                                id="deviceCountIn"
                                min={0}
                                className={editMode ? 'input-editable' : 'input-non-editable '}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={stateData[i].childPart[j].timePerDevice}
                                    id={j + 'timePerDeviceIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      settingNewDandoriAmtInArr(
                                        e.target.value,
                                        i,
                                        j,
                                        'timePerDevice',
                                        'input',
                                        'input'
                                      );
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            stateData[i].childPart[j].timePerDevice,
                                            '10min',
                                            j + 'timePerDevice'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, i, j, 'timePerDevice', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            stateData[i].childPart[j].timePerDevice,
                                            '10min',
                                            j + 'timePerDevice'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, i, j, 'timePerDevice', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            stateData[i].childPart[j].timePerDevice,
                                            'min',
                                            j + 'timePerDevice'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, i, j, 'timePerDevice', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            stateData[i].childPart[j].timePerDevice,
                                            'min',
                                            j + 'timePerDevice'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, i, j, 'timePerDevice', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            stateData[i].childPart[j].timePerDevice,
                                            'sec',
                                            j + 'timePerDevice'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, i, j, 'timePerDevice', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            stateData[i].childPart[j].timePerDevice,
                                            'sec',
                                            j + 'timePerDevice'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, i, j, 'timePerDevice', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>

                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={4} className="formula-column"></Col>
                          </Row>
                        </div>
                      </div>
                    );
                  }
                  return subArr;
                })()}
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default ParentKouteiCalculate_Kensa;
