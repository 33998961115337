/**
 * クラス名：材質名称詳細画面
 * 説明：iQ3板金に使用の材質名称詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Select, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import checkedImg from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import { getKubunNameDDL } from '../../../common/Common.js';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialClassNameDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
  }));

  const editMode = props.editMode;

  const [selectedAutoPurchase, setSelectedAutoPurchase] = useState('');
  const [selectedAutoPrice, setSelectedAutoPrice] = useState('');

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData).length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(props.selectedData);
      setSelectedRowData(selectedData);
    } else {
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[objKey] = value;
      return updatedData;
    });
  };

  const deleteSelectedRow = (index, e, id) => {
    if (id === 'btnAutoPurchase') {
      setSelectedAutoPurchase(selectedAutoPurchase.filter((v, i) => i !== index));
    } else if (id === 'btnAutoPrice') {
      setSelectedAutoPrice(selectedAutoPrice.filter((v, i) => i !== index));
    }
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    setUpdateConfirm(true);
    setIsUpdate(true);
  };

  const discardData = () => {
    setCancelConfirm(true);
  };

  const updConfirmOk = (e) => {
    if (isUpdate) {
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };

      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
    setUpdateConfirm(false);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData(props.selectedData);
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className="mainButton" id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">No</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{props.itemNo}</label>
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="displayFlag"
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('displayFlag', e.target.value);
                      }}
                      value={selectedRowData.displayFlag}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData.displayFlag && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="classnameCode"
                    id="classnameCode"
                    value={selectedRowData.classnameCode}
                    type="text"
                    onChange={(e) => editDetailInput('classnameCode', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="materialName"
                    id="materialName"
                    value={selectedRowData.materialName}
                    type="text"
                    onChange={(e) => editDetailInput('materialName', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質区分名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質区分名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('materialClassName', e);
                        }}
                        placeholder="材質区分名"
                        id="materialRefDDL"
                        name="materialRefDDL"
                        value={selectedRowData.materialClassName}
                      >
                        {getKubunNameDDL().map((item) => (
                          <option id={'materialClassName' + item.value} value={item.label}>
                            {item.label}
                          </option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{selectedRowData.materialClassName}</label>
                  )}
                </td>
              </tr>
              {/* 比重 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">比重</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="gravity"
                    id="gravity"
                    value={selectedRowData.gravity}
                    type="text"
                    onChange={(e) => editDetailInput('gravity', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材料ロス係数 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材料ロス係数</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="lossFactor"
                    id="lossFactor"
                    value={selectedRowData.lossFactor}
                    type="text"
                    onChange={(e) => editDetailInput('lossFactor', e.target.value)}
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>{selectedRowData.created}</label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>{selectedRowData.modified}</label>
                </td>
              </tr>

              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="remark"
                    id="remark"
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    value={selectedRowData.remark}
                    type="text"
                    onChange={(e) => editDetailInput('remark', e.target.value)}
                  />
                </td>
              </tr>
              {/* 備考 */}
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default MaterialClassNameDetail;
