import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const BendingArr = [
  {
    id: 0,
    processName: 'ベンディング',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processType: '',
    processCount: 0,
  },
];

const BendingArr2 = [
  {
    id: 0,
    processName: 'ベンディング',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processType: '',
    processCount: 0,
  },
];

const BendingArr3 = [
  {
    id: 0,
    processName: 'ベンディング',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processType: '',
    processCount: 0,
  },
];

const BendingArr4 = [
  {
    id: 0,
    processName: 'ベンディング',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processType: '',
    processCount: 0,
  },
];

const BendingArr5 = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 600,
    dandoriTime: '00:08:00',
    sagyoAmt: 55,
    sagyoTime: '00:00:43',
    processType: 'V曲げ',
    processCount: 1,
  },
];

const BendingArr1_PMX = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 825,
    dandoriTime: '00:11:00',
    sagyoAmt: 958,
    sagyoTime: '00:12:46',
    processType: 'V曲げ',
    processCount: 7,
  },
];

const BendingArr2_PMX = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processType: 'V曲げ',
    processCount: 0,
  },
];

const BendingArr1_PMX2 = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 825,
    dandoriTime: '00:11:00',
    sagyoAmt: 585,
    sagyoTime: '00:07:48',
    processType: 'V曲げ',
    processCount: 4,
  },
];

const BendingArr2_PMX2 = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 825,
    dandoriTime: '00:11:00',
    sagyoAmt: 274,
    sagyoTime: '00:03:39',
    processType: 'V曲げ',
    processCount: 2,
  },
];

const BendingArr3_PMX2 = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 825,
    dandoriTime: '00:11:00',
    sagyoAmt: 274,
    sagyoTime: '00:03:39',
    processType: 'V曲げ',
    processCount: 2,
  },
];

const BendingArr4_PMX2 = [
  {
    id: 0,
    processName: 'ベンデイング',
    dandoriAmt: 600,
    dandoriTime: '00:08:00',
    sagyoAmt: 106,
    sagyoTime: '00:01:25',
    processType: 'V曲げ',
    processCount: 1,
  },
];

const provinceData = [
  'V曲げ',
  'R曲げ',
  'FR曲げ',
  '3R曲げ',
  'HM曲げ',
  'Z曲げ',
  'AMA曲げ',
  '特殊曲げ1',
  '特殊曲げ2',
  '特殊曲げ3',
];

const IQ3KouteiCalculate_Bending = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState(BendingArr);
  const [editedData, setEditedData] = useState([]);

  const settingEditData = (arr) => {
    let editDataArr = [];
    for (let i = 0; i < arr.length; i++) {
      editDataArr.push({
        id: i,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        sagyoTime: arr[i].sagyoTime,
        processType: arr[i].processType,
        processCount: arr[i].processCount,
        sumAmt: arr[i].dandoriAmt + arr[i].sagyoAmt,
        sumTime: secondsToHms(setTimetoSec(arr[i].dandoriTime) + setTimetoSec(arr[i].sagyoTime)),
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += setTimetoSec(element.sagyoTime);
      dandoriTimeSec += setTimetoSec(element.dandoriTime);
    });

    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = secondsToHms(sagyoTimeSec);
    let dandoriTimeSum = secondsToHms(dandoriTimeSec);
    let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec);

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let dataEdit = [];
    let totals = [];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail != undefined) {
      if (Object.keys(props.selectedDataDetail).length > 0) {
        if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
          setProcessArr(BendingArr);
          dataEdit = settingEditData(BendingArr);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
          setProcessArr(BendingArr2);
          dataEdit = settingEditData(BendingArr2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
          setProcessArr(BendingArr3);
          dataEdit = settingEditData(BendingArr3);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
          setProcessArr(BendingArr4);
          dataEdit = settingEditData(BendingArr4);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
          setProcessArr(BendingArr5);
          dataEdit = settingEditData(BendingArr5);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
          setProcessArr(BendingArr1_PMX);
          dataEdit = settingEditData(BendingArr1_PMX);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
          setProcessArr(BendingArr2_PMX);
          dataEdit = settingEditData(BendingArr2_PMX);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
          setProcessArr(BendingArr4);
          dataEdit = settingEditData(BendingArr4);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
          setProcessArr(BendingArr4);
          dataEdit = settingEditData(BendingArr4);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
          setProcessArr(BendingArr1_PMX2);
          dataEdit = settingEditData(BendingArr1_PMX2);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
          setProcessArr(BendingArr2_PMX2);
          dataEdit = settingEditData(BendingArr2_PMX2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
          setProcessArr(BendingArr3_PMX2);
          dataEdit = settingEditData(BendingArr3_PMX2);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
          setProcessArr(BendingArr4_PMX2);
          dataEdit = settingEditData(BendingArr4_PMX2);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
          setProcessArr(BendingArr4);
          dataEdit = settingEditData(BendingArr4);
        } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
          setProcessArr(BendingArr4);
          dataEdit = settingEditData(BendingArr4);
        } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
          setProcessArr(BendingArr4);
          dataEdit = settingEditData(BendingArr4);
        }
      } else {
        setProcessArr(BendingArr);
        dataEdit = settingEditData(BendingArr);
      }

      setEditedData(dataEdit);
      totals = calculateTotals(dataEdit);

      setTotalAmt(totals[0].totalSum);
      setTotalSum(totals[0].totalSum);

      setTotalTime(totals[0].totalSum);
      setTotalTimeSum(totals[0].totalSum);

      setTotalSagyoAmt(totals[0].sagyoAmtSum);
      setSagyoAmtSum(totals[0].sagyoAmtSum);

      setTotalSagyoTime(totals[0].sagyoTimeSum);
      setSagyoTimeSum(totals[0].sagyoTimeSum);

      setTotalDandoriAmt(totals[0].dandoriAmtSum);
      setDandoriAmtSum(totals[0].dandoriAmtSum);

      setTotalDandoriTime(totals[0].dandoriTimeSum);
      setDandoriTimeSum(totals[0].dandoriTimeSum);
    }
  }, [props.selectedDataDetail, props.editMode]);

  const yousetsu = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('bendingProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('bendingProcess' + i).style.display = 'none';
      document.getElementById('arrowDownBending' + i).style.display = 'block';
      document.getElementById('arrowUpBending' + i).style.display = 'none';
    } else {
      document.getElementById('bendingProcess' + i).style.display = 'block';
      document.getElementById('arrowDownBending' + i).style.display = 'none';
      document.getElementById('arrowUpBending' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      /* if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
      } else {
        newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
      } */

      if (document.getElementById(index + 'bendingDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'bendingDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'bendingDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'bendingDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      /* if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
      } else {
        newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
      }
      checkInputWithColor(index + 'bendingWorkAmt', newState[index].sagyoAmt, 'green'); */
      if (document.getElementById(index + 'bendingWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'bendingWorkAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'bendingWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'bendingWorkAmt', newState[index].sagyoAmt, 'green');
          checkInputWithColor(index + 'bendingWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = parseFloat(newState[index].sagyoAmt + newState[index].dandoriAmt);
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = secondsToHms(
      setTimetoSec(newState[index].sagyoTime) + setTimetoSec(newState[index].dandoriTime)
    );
    newState[index].sumTime = newSubTimeSum;
    newState.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      newSagyoTimeSec += setTimetoSec(element.sagyoTime);
      newDandoriTimeSec += setTimetoSec(element.dandoriTime);
    });
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = secondsToHms(newSagyoTimeSec);
    let newDandoriTimeSum = secondsToHms(newDandoriTimeSec);
    let newTotalTimeSum = secondsToHms(newSagyoTimeSec + newDandoriTimeSec);

    checkInputWithColor(index + 'subTotalBending', newSubSum, 'green');
    checkInputWithColor(index + 'subTotalTimeBending', newSubSum, 'green');
    checkInputWithColor('totalSagyoAmtBending', newSagyoSum, 'green');
    checkInputWithColor('totalDandoriAmtBending', newDandoriSum, 'green');
    checkInputWithColor('totalAmtBending', newSum, 'green');
    checkInputWithColor('totalSagyoTimeBending', newSagyoTimeSum, 'green');
    checkInputWithColor('totalDandoriTimeBending', newDandoriTimeSum, 'green');
    checkInputWithColor('totalTimeBending', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yousetsu}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>ベンディング</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{JPYs.format(totalSum)}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>ベンディング_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            ベンディング
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtBending">{JPYs.format(totalSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeBending">{totalTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtBendingIn" className="resetColor">
              {JPYs.format(totalAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeBendingIn" className="resetColor">
              {totalTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtBending">{JPYs.format(dandoriAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeBending">{dandoriTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtBendingIn" className="resetColor">
              {JPYs.format(totalDandoriAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeBendingIn" className="resetColor">
              {totalDandoriTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            作業金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtBending">{JPYs.format(sagyoAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeBending">{sagyoTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtBendingIn" className="resetColor">
              {JPYs.format(totalSagyoAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeBendingIn" className="resetColor">
              {totalSagyoTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        {(() => {
          const arr = [];
          for (let i = 0; i < editedData.length; i++) {
            arr.push(
              <div>
                <Row
                  style={{ color: 'black' }}
                  className="oyabuhin-detail oyabuhin-row"
                  onClick={(e) => subpartDetail(i)}
                >
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex' }}>
                      <CaretUpOutlined
                        id={'arrowUpBending' + i}
                        style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      />
                      <CaretDownOutlined id={'arrowDownBending' + i} style={{ fontSize: 17, marginTop: 2 }} />
                      <span style={{ marginLeft: '5px' }}>{processArr[i].processName}</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalBending'}>
                      {JPYs.format((processArr[i].dandoriAmt + processArr[i].sagyoAmt).toFixed(0))}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalTimeBending'}>
                      {secondsToHms(setTimetoSec(processArr[i].dandoriTime) + setTimetoSec(processArr[i].sagyoTime))}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalBendingIn'}>{JPYs.format(editedData[i].sumAmt)}</label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalTimeBendingIn'}>{editedData[i].sumTime}</label>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
                <div id={'bendingProcess' + i} style={{ display: 'none' }}>
                  {(() => {
                    const subArr = [];
                    for (let j = i; j <= i; j++) {
                      subArr.push(
                        <div>
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">段取金額/時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'bendingDandoriAmt'}>{JPYs.format(processArr[j].dandoriAmt)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'bendingDandoriTime'}>{processArr[j].dandoriTime}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'bendingDandoriAmtIn'}
                                value={editedData[j].dandoriAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].dandoriAmt != e) {
                                    settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                    checkInputWithColor(j + 'bendingDandoriAmt', e, 'amt');
                                  }
                                }}
                                /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'bendingDandoriAmt', e, 'amt');
                                  }
                                }} */
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'bendingDandoriAmtCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  {/* <span id={j + 'bendingDandoriTimeIn'}>{editedData[j].dandoriTime}</span> */}
                                  <Input
                                    value={editedData[j].dandoriTime}
                                    id={j + 'bendingDandoriTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].dandoriTime,
                                            '10min',
                                            j + 'bendingDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].dandoriTime,
                                            '10min',
                                            j + 'bendingDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].dandoriTime,
                                            'min',
                                            j + 'bendingDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].dandoriTime,
                                            'min',
                                            j + 'bendingDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].dandoriTime,
                                            'sec',
                                            j + 'bendingDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].dandoriTime,
                                            'sec',
                                            j + 'bendingDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">作業金額/時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'bendingWorkAmt'}>{JPYs.format(processArr[j].sagyoAmt)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'bendingWorkTime'}>{processArr[j].sagyoTime}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'bendingWorkAmtIn'}
                                value={editedData[j].sagyoAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].sagyoAmt != e) {
                                    settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                    checkInputWithColor(j + 'bendingWorkAmt', e, 'amt');
                                  }
                                }}
                                /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'bendingWorkAmt', e, 'amt');
                                  }
                                }} */
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'bendingWorkAmtCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].sagyoTime}
                                    id={j + 'bendingWorkTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTime,
                                            '10min',
                                            j + 'bendingWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTime,
                                            '10min',
                                            j + 'bendingWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTime,
                                            'min',
                                            j + 'bendingWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTime,
                                            'min',
                                            j + 'bendingWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTime,
                                            'sec',
                                            j + 'bendingWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTime,
                                            'sec',
                                            j + 'bendingWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">加工種類/回数</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proType">{processArr[j].processType}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proCount">{processArr[j].processCount}</label>
                            </Col>
                            {editMode ? (
                              <Col span={4} className="input-col">
                                <Select
                                  defaultValue={editedData[j].processType}
                                  style={{
                                    width: '100%',
                                    margin: '0 5px',
                                    fontSize: 14,
                                  }}
                                  className="test"
                                  id="proTypeIn"
                                  onChange={handleProvinceChange}
                                  options={provinceData.map((province) => ({ label: province, value: province }))}
                                />
                              </Col>
                            ) : (
                              <Col span={4} className="oyabuhin-detail-col">
                                <label id="proTypeIn">{editedData[j].processType}</label>
                              </Col>
                            )}

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].processCount}
                                id="proCountIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                        </div>
                      );
                    }
                    return subArr;
                  })()}
                </div>
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Bending;
