import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const zeroArr = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [],
    sagyo: [],
  },
];
const blankProcessArr = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 57, sagyoTime: '00:00:28' },
      { sagyoType: 'レーザー', sagyoAmt: 264, sagyoTime: '00:02:09' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 21, sagyoTime: '00:00:30' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 73, sagyoTime: '00:00:36' },
      { sagyoType: 'レーザー', sagyoAmt: 193, sagyoTime: '00:01:35' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 21, sagyoTime: '00:00:30' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr3 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 0, dandoriTime: '00:00:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'レーザー', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'ピアス', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'ジョイント', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr4 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 21, sagyoTime: '00:00:10' },
      { sagyoType: 'レーザー', sagyoAmt: 271, sagyoTime: '00:02:13' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 14, sagyoTime: '00:00:20' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr5 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 73, sagyoTime: '00:02:42' },
      { sagyoType: 'レーザー', sagyoAmt: 193, sagyoTime: '00:01:35' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 21, sagyoTime: '00:00:30' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr1_PMX = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 57, sagyoTime: '00:00:28' },
      { sagyoType: 'レーザー', sagyoAmt: 264, sagyoTime: '00:02:09' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 21, sagyoTime: '00:00:30' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr2_PMX = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'レーザー', sagyoAmt: 28, sagyoTime: '00:00:13' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 7, sagyoTime: '00:00:10' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr3_PMX = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '(基本)', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 8, sagyoTime: '00:00:03' },
      { sagyoType: 'レーザー', sagyoAmt: 22, sagyoTime: '00:00:10' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 7, sagyoTime: '00:00:10' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 132, sagyoTime: '00:01:00' },
    ],

    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
];

const blankProcessArr4_PMX = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 0, dandoriTime: '00:00:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'レーザー', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'ピアス', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'ジョイント', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr1_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 37, sagyoTime: '00:00:18' },
      { sagyoType: 'レーザー', sagyoAmt: 162, sagyoTime: '00:01:31' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 21, sagyoTime: '00:00:30' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr2_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'レーザー', sagyoAmt: 120, sagyoTime: '00:00:58' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 14, sagyoTime: '00:00:20' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr3_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 19, sagyoTime: '00:00:09' },
      { sagyoType: 'レーザー', sagyoAmt: 160, sagyoTime: '00:01:19' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 21, sagyoTime: '00:00:30' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr4_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 7, sagyoTime: '00:00:03' },
      { sagyoType: 'レーザー', sagyoAmt: 87, sagyoTime: '00:00:43' },
      { sagyoType: 'ピアス', sagyoAmt: 4, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 14, sagyoTime: '00:00:20' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr5_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 4, sagyoTime: '00:00:02' },
      { sagyoType: 'レーザー', sagyoAmt: 35, sagyoTime: '00:00:17' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 7, sagyoTime: '00:00:10' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr6_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'レーザー', sagyoAmt: 35, sagyoTime: '00:00:17' },
      { sagyoType: 'ピアス', sagyoAmt: 2, sagyoTime: '00:00:01' },
      { sagyoType: 'ジョイント', sagyoAmt: 7, sagyoTime: '00:00:10' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],

    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const blankProcessArr7_PMX2 = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [
      { dandoriType: '', dandoriAmt: 0, dandoriTime: '00:00:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: 'パンチ', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'レーザー', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'ピアス', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'ジョイント', sagyoAmt: 0, sagyoTime: '00:00:00' },
      { sagyoType: 'マテハン金額/時間', sagyoAmt: 0, sagyoTime: '00:00:00' },
    ],
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const settingEditData = (arr) => {
  let editDataArr = [];
  let dandoriArr = [];
  let sagyoArr = [];
  if (arr.length > 0) {
    dandoriArr = arr[0].dandori;
    sagyoArr = arr[0].sagyo;
  }

  for (let i = 0; i < arr.length; i++) {
    editDataArr.push({
      id: i,
      dandori: dandoriArr,
      sagyo: sagyoArr,
      naibuProcessValidationAmt: arr[0].naibuProcessValidationAmt,
      naibuProcessValidationTime: arr[0].naibuProcessValidationTime,
    });
  }
  return editDataArr;
};

const calculateTotals = (editData) => {
  let dandoriArr = editData[0]?.dandori;
  let sagyoArr = editData[0]?.sagyo;
  let dandoriAmt = 0;
  let sagyoAmt = 0;
  let dandoriTimeSec = 0;
  let sagyoTimeSec = 0;
  let dandoriTime, sagyoTime;
  for (let i = 0; i < dandoriArr.length; i++) {
    dandoriAmt += parseFloat(dandoriArr[i].dandoriAmt);
    dandoriTimeSec += setTimetoSec(dandoriArr[i].dandoriTime);
  }
  dandoriTime = secondsToHms(dandoriTimeSec);

  for (let i = 0; i < sagyoArr.length; i++) {
    sagyoAmt += parseFloat(sagyoArr[i].sagyoAmt);
    sagyoTimeSec += setTimetoSec(sagyoArr[i].sagyoTime);
  }
  sagyoTime = secondsToHms(sagyoTimeSec);

  let totalSum = (
    parseFloat(sagyoAmt) +
    parseFloat(dandoriAmt) +
    parseFloat(editData[0]?.naibuProcessValidationAmt)
  ).toFixed(0);
  let totalTimeSum = secondsToHms(
    sagyoTimeSec + dandoriTimeSec + setTimetoSec(editData[0]?.naibuProcessValidationTime)
  );

  let totals = [];
  let i = 0;
  totals.push({
    id: i++,
    totalSum: totalSum,
    totalTimeSum: totalTimeSum,
    dandoriAmt: dandoriAmt,
    sagyoAmt: sagyoAmt,
    dandoriTime: dandoriTime,
    sagyoTime: sagyoTime,
  });
  return totals;
};

const provinceData = ['サラ'];

const IQ3KouteiCalculate_Blank = forwardRef((props, ref) => {
  const [isBlankingdetail, setIBlankingdetail] = useState(false);
  const [isdandoriDetail, setIsDandoriDetail] = useState(false);
  const [isSagyoDetail, setIsSagyoDetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [dandoriAmt, setDandoriAmt] = useState('0');
  const [dandoriTime, setDandoriTime] = useState('');
  const [sagyoAmt, setSagyoAmt] = useState('');
  const [sagyoTime, setSagyoTime] = useState('');
  const [dandoriAmtS, setDandoriAmtS] = useState('');
  const [dandoriTimeS, setDandoriTimeS] = useState('');
  const [sagyoAmtS, setSagyoAmtS] = useState('');
  const [sagyoTimeS, setSagyoTimeS] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [processArr, setProcessArr] = useState(blankProcessArr);
  const [editedData, setEditedData] = useState([]);
  const [materialHandlingAmt, setMaterialHandlingAmt] = useState('');
  const [materialHandlingTime, setMaterialHandlingTime] = useState('');
  const [naibuProcessValidationAmt, setNaibuProcessValidationAmt] = useState('');
  const [naibuProcessValidationTime, setNaibuProcessValidationTime] = useState('');

  useEffect(() => {
    let editData = [];
    let toBEdit = [];
    let totals = [];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail !== undefined) {
      if (Object.keys(props.selectedDataDetail).length > 0) {
        if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
          toBEdit = blankProcessArr;
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
          toBEdit = blankProcessArr2;
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
          toBEdit = blankProcessArr3;
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
          toBEdit = blankProcessArr4;
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
          toBEdit = blankProcessArr5;
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
          toBEdit = blankProcessArr1_PMX;
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
          toBEdit = blankProcessArr2_PMX;
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
          toBEdit = blankProcessArr3_PMX;
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
          toBEdit = blankProcessArr4_PMX;
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
          toBEdit = blankProcessArr1_PMX2;
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
          toBEdit = blankProcessArr2_PMX2;
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
          toBEdit = blankProcessArr3_PMX2;
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
          toBEdit = blankProcessArr4_PMX2;
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
          toBEdit = blankProcessArr5_PMX2;
        } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
          toBEdit = blankProcessArr6_PMX2;
        } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
          toBEdit = blankProcessArr7_PMX2;
        }
      } else {
        toBEdit = zeroArr;
      }

      editData = settingEditData(toBEdit);
      if (editData.length > 0) {
        totals = calculateTotals(editData);
      }

      setEditedData(editData);
      setProcessArr(toBEdit);

      setTotalAmt(totals[0]?.totalSum);
      setTotalSum(totals[0]?.totalSum);
      setTotalTime(totals[0]?.totalTimeSum);
      setTotalTimeSum(totals[0]?.totalTimeSum);
      setDandoriAmt(totals[0]?.dandoriAmt);
      setDandoriAmtS(totals[0]?.dandoriAmt);
      setDandoriTime(totals[0]?.dandoriTime);
      setDandoriTimeS(totals[0]?.dandoriTime);
      setSagyoAmt(totals[0]?.sagyoAmt);
      setSagyoAmtS(totals[0]?.sagyoAmt);
      setSagyoTime(totals[0]?.sagyoTime);
      setSagyoTimeS(totals[0]?.sagyoTime);
      setNaibuProcessValidationAmt(toBEdit[0]?.naibuProcessValidationAmt);
      setNaibuProcessValidationTime(toBEdit[0]?.naibuProcessValidationTime);
      /* resetColor(); */
    }
  }, [props.selectedDataDetail, props.editMode]);

  const Blanking = () => {
    if (isBlankingdetail == true) {
      setIBlankingdetail(false);
    } else {
      setIBlankingdetail(true);
    }
  };
  const dandoriDetail = () => {
    if (isdandoriDetail == true) {
      setIsDandoriDetail(false);
    } else {
      setIsDandoriDetail(true);
    }
  };

  const sagyoDetail = () => {
    if (isSagyoDetail == true) {
      setIsSagyoDetail(false);
    } else {
      setIsSagyoDetail(true);
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    let dandori = [];
    let sagyo = [];
    let totals = [];
    let oldValue;
    if (objId == 'dandoriAmt' || objId == 'dandoriTime') {
      oldValue = JSON.parse(JSON.stringify(newState[0].dandori));
      if (value === undefined) {
        value = 0;
      }
      let dandori = newState[0].dandori;
      dandori[index][objId] = value;

      if (objId === 'dandoriTime') {
        if (document.getElementById(index + 'dandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'dandoriAmtCu').style.display;
          if (showHideCu === 'block') {
            checkInputWithColor(index + 'dandoriAmt', dandori[index].dandoriAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].dandoriTime);
              dandori[index].dandoriAmt = changeAmtwithTime(dandori[index].dandoriAmt, timeType, timeAct, value);
              checkInput(index + objId, value);
            } else {
              dandori[index].dandoriAmt = changeAmtwithTime(dandori[index].dandoriAmt, timeType, timeAct, value);
            }
            checkInputWithColor(index + 'dandoriAmt', dandori[index].dandoriAmt, 'green');
          }
        }
        newState[0].dandori = dandori;
      }
    } else if (objId == 'sagyoAmt' || objId == 'sagyoTime') {
      oldValue = JSON.parse(JSON.stringify(newState[0].sagyo));
      if (value === undefined) {
        value = 0;
      }
      let sagyo = newState[0].sagyo;
      sagyo[index][objId] = value;
      if (objId === 'sagyoTime') {
        //console.log(icon);

        if (document.getElementById(index + 'sagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'sagyoAmtCu').style.display;

          if (showHideCu === 'block') {
            checkInputWithColor(index + 'sagyoAmt', sagyo[index].sagyoAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].sagyoTime);
              sagyo[index].sagyoAmt = changeAmtwithTime(sagyo[index].sagyoAmt, timeType, timeAct, value);
              checkInput(index + objId, value);
            } else {
              sagyo[index].sagyoAmt = changeAmtwithTime(sagyo[index].sagyoAmt, timeType, timeAct, value);
            }
            checkInputWithColor(index + 'sagyoAmt', sagyo[index].sagyoAmt, 'green');
          }
        }
        newState[0].sagyo = sagyo;
      }
    } else if (objId == 'naibuProcessValidationAmt' || objId == 'naibuProcessValidationTime') {
      oldValue = newState[0][objId];
      if (value === undefined) {
        value = 0;
      }
      newState[0][objId] = value;
      if (objId === 'naibuProcessValidationTime') {
        //console.log(icon);
        if (document.getElementById('naibuProcessValidationAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById('naibuProcessValidationAmtCu').style.display;

          if (showHideCu === 'block') {
            checkInputWithColor('naibuProcessValidationAmt', newState[0].naibuProcessValidationAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[0].naibuProcessValidationTime);
              newState[0].naibuProcessValidationAmt = changeAmtwithTime(
                newState[0].naibuProcessValidationAmt,
                timeType,
                timeAct,
                value
              );
              checkInput(index + objId, value);
            } else {
              newState[0].naibuProcessValidationAmt = changeAmtwithTime(
                newState[0].naibuProcessValidationAmt,
                timeType,
                timeAct,
                value
              );
            }
            checkInputWithColor('naibuProcessValidationAmt', newState[0].naibuProcessValidationAmt, 'green');
          }
        }
        newState[0].sagyo = sagyo;
      }
    }

    totals = calculateTotals(newState);
    console.log('totals', totals);
    checkInputWithColor('totalAmtBlank', totals[0].totalSum, 'green');
    checkInputWithColor('totalTimeBlank', totals[0].totalTimeSum, 'green');
    checkInputWithColor('totalDandoriAmtBlank', totals[0].dandoriAmt, 'green');
    checkInputWithColor('totalDandoriTimeBlank', totals[0].dandoriTime, 'green');
    checkInputWithColor('totalSagyoAmtBlank', totals[0].sagyoAmt, 'green');
    checkInputWithColor('totalSagyoTimeBlank', totals[0].sagyoTime, 'green');
    setTotalAmt(totals[0].totalSum);
    setTotalTime(totals[0].totalTimeSum);
    setDandoriAmtS(totals[0].dandoriAmt);
    setDandoriTimeS(totals[0].dandoriTime);
    setSagyoAmtS(totals[0].sagyoAmt);
    setSagyoTimeS(totals[0].sagyoTime);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={Blanking}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isBlankingdetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>ブランク</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{totalSum == undefined ? '¥0' : JPYs.format(totalSum)}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>ブランク_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankingdetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            ブランク
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtBlank">¥{totalSum}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeBlank">{totalTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtBlankIn" className="resetColor">
              ¥{totalAmt}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeBlankIn" className="resetColor">
              {totalTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={dandoriDetail}>
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              {isdandoriDetail ? (
                <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
              )}
              <label> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtBlank">{dandoriAmt == undefined ? '¥0' : JPYs.format(dandoriAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeBlank">{dandoriTime}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtBlankIn" className="resetColor">
              ¥{dandoriAmtS}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeBlankIn" className="resetColor">
              {dandoriTimeS}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <div style={{ display: isdandoriDetail ? 'block' : 'none' }}>
          {(() => {
            const subArr = [];
            if (editedData.length > 0) {
              let dandoriArr = processArr[0].dandori;
              let dandoriEdit = editedData[0].dandori;
              for (let j = 0; j < dandoriArr.length; j++) {
                subArr.push(
                  <div>
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg">段取金額/時間{dandoriArr[j].dandoriType}</span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'dandoriAmt'}>{JPYs.format(dandoriArr[j].dandoriAmt)}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'dandoriTime'}>{dandoriArr[j].dandoriTime}</label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={j + 'dandoriAmtIn'}
                          value={dandoriEdit[j].dandoriAmt}
                          defaultValue={0}
                          onValueChange={(e) => {
                            if (dandoriEdit[j].dandoriAmt != e) {
                              settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                              checkInputWithColor(j + 'dandoriAmt', e, 'amt');
                            }
                          }}
                          /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(j + 'dandoriAmt', e, 'amt');
                            }
                          }} */
                          style={{ border: 'none' }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={j + 'dandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={dandoriEdit[j].dandoriTime}
                              id={j + 'dandoriTimeIn'}
                              style={{ width: '100%' }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                //disableTimer('test');
                                settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                // checkInput(j + 'dandoriTime', e.target.value);
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      dandoriEdit[j].dandoriTime,
                                      '10min',
                                      j + 'dandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                    /*  settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input'); */
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      dandoriEdit[j].dandoriTime,
                                      '10min',
                                      j + 'dandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      dandoriEdit[0].dandoriTime,
                                      'min',
                                      j + 'dandoriTime'
                                    );
                                    console.log(editedTime);
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      dandoriEdit[0].dandoriTime,
                                      'min',
                                      j + 'dandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      dandoriEdit[0].dandoriTime,
                                      'sec',
                                      j + 'dandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      dandoriEdit[0].dandoriTime,
                                      'sec',
                                      j + 'dandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                  </div>
                );
              }
            }
            return subArr;
          })()}
        </div>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={sagyoDetail}>
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              {isSagyoDetail ? (
                <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
              )}
              <label> 加工金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtBlank">{sagyoAmt == undefined ? '¥0' : JPYs.format(sagyoAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeBlank">{sagyoTime}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtBlankIn" className="resetColor">
              ¥{sagyoAmtS}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeBlankIn" className="resetColor">
              {sagyoTimeS}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <div style={{ display: isSagyoDetail ? 'block' : 'none' }}>
          {(() => {
            const subArr = [];
            if (editedData.length > 0) {
              let sagyoArr = processArr[0].sagyo;
              let sagyoEdit = editedData[0].sagyo;
              for (let j = 0; j < sagyoArr.length; j++) {
                subArr.push(
                  <div>
                    <Row
                      style={{
                        color: 'black',
                        display: j + 1 == sagyoArr.length && sagyoArr[j].sagyoAmt == 0 ? 'none' : '',
                      }}
                      className="oyabuhin-detail oyabuhin-row"
                    >
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg">
                          {' '}
                          {j + 1 == sagyoArr.length ? sagyoArr[j].sagyoType : '加工金額/時間' + sagyoArr[j].sagyoType}
                        </span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'sagyoAmt'}>{JPYs.format(sagyoArr[j].sagyoAmt)}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'sagyoTime'}>{sagyoArr[j].sagyoTime}</label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={j + 'sagyoAmtIn'}
                          value={sagyoEdit[j].sagyoAmt}
                          defaultValue={0}
                          onValueChange={(e) => {
                            if (sagyoEdit[j].sagyoAmt != e) {
                              settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                              checkInputWithColor(j + 'sagyoAmt', e, 'amt');
                            }
                          }}
                          /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(j + 'sagyoAmt', e, 'amt');
                            }
                          }} */
                          style={{ border: 'none' }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={j + 'sagyoAmtCu'} style={{ display: 'none' }}>
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={sagyoEdit[j].sagyoTime}
                              id={j + 'sagyoTimeIn'}
                              style={{ width: '100%' }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                //disableTimer('test');
                                settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      sagyoEdit[j].sagyoTime,
                                      '10min',
                                      j + 'sagyoTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      sagyoEdit[j].sagyoTime,
                                      '10min',
                                      j + 'sagyoTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(sagyoEdit[j].sagyoTime, 'min', j + 'sagyoTime');
                                    settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(sagyoEdit[j].sagyoTime, 'min', j + 'sagyoTime');
                                    settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(sagyoEdit[j].sagyoTime, 'sec', j + 'sagyoTime');
                                    settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(sagyoEdit[j].sagyoTime, 'sec', j + 'sagyoTime');
                                    settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                  </div>
                );
              }
            }
            return subArr;
          })()}
        </div>

        <Row
          style={{ color: 'black', display: naibuProcessValidationAmt == 0 ? 'none' : '' }}
          className="oyabuhin-detail oyabuhin-row"
        >
          <Col span={4} className="oyabuhin-detail-col">
            <span className="ddl_2_noImg">工程内検査金額/時間</span>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id={'naibuProcessValidationAmt'}>
              {naibuProcessValidationAmt == undefined ? '¥0' : JPYs.format(naibuProcessValidationAmt)}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id={'naibuProcessValidationTime'}>{naibuProcessValidationTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              id="naibuProcessValidationAmtIn"
              value={editedData[0]?.naibuProcessValidationAmt}
              defaultValue={0}
              onValueChange={(e) => {
                if (editedData[0]?.naibuProcessValidationAmt != e) {
                  settingNewDandoriAmtInArr(e, '', 'naibuProcessValidationAmt', '', '');
                  checkInputWithColor('naibuProcessValidationAmt', e, 'amt');
                }
              }}
              style={{ border: 'none' }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'naibuProcessValidationAmtCu'} style={{ display: 'none' }}>
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={naibuProcessValidationTime}
                  id={'naibuProcessValidationTimeIn'}
                  style={{ width: '100%' }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    //disableTimer('test');
                    // settingNewDandoriAmtInArr(e.target.value, j, 'naibuProcessValidationTime', 'input', 'input');
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        // let editedTime = handelIncreaseTime(
                        //   naibuProcessValidationTime,
                        //   '10min',
                        //    'naibuProcessValidationTime'
                        // );
                        // settingNewDandoriAmtInArr(editedTime, j, 'naibuProcessValidationTime', '10min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        // let editedTime = handelDecreaseTime(
                        //   naibuProcessValidationTime,
                        //   '10min',
                        //   j + 'naibuProcessValidationTime'
                        // );
                        // settingNewDandoriAmtInArr(editedTime, j, 'naibuProcessValidationTime', '10min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        // let editedTime = handelIncreaseTime(
                        //   naibuProcessValidationTime,
                        //   'min',
                        //   j + 'naibuProcessValidationTime'
                        // );
                        // settingNewDandoriAmtInArr(editedTime, j, 'naibuProcessValidationTime', 'min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        // let editedTime = handelDecreaseTime(
                        //   naibuProcessValidationTime,
                        //   'min',
                        //   j + 'naibuProcessValidationTime'
                        // );
                        // settingNewDandoriAmtInArr(editedTime, j, 'naibuProcessValidationTime', 'min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        // let editedTime = handelIncreaseTime(
                        //   naibuProcessValidationTime,
                        //   'sec',
                        //   j + 'naibuProcessValidationTime'
                        // );
                        // settingNewDandoriAmtInArr(editedTime, j, 'naibuProcessValidationTime', 'sec', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        // let editedTime = handelDecreaseTime(
                        //   naibuProcessValidationTime,
                        //   'sec',
                        //   j + 'naibuProcessValidationTime'
                        // );
                        // settingNewDandoriAmtInArr(editedTime, j, 'naibuProcessValidationTime', 'sec', 'dec');
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Blank;
