/**
 * クラス名：板金のブランクの材料配置画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
const Device_MaterialArrangement = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [listData, setListData] = useState(false);

  // 材料配置
  const [pierWidth1, setPierWidth1] = useState('');
  const [pierWidth2, setPierWidth2] = useState('');
  const [grabAllowance1, setGrabAllowance1] = useState('');
  const [grabAllowance2, setGrabAllowance2] = useState('');
  const [processRange1, setProcessRange1] = useState('');
  const [processRange2, setProcessRange2] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    setListData(props.materialArrangeListData);

    setPierWidth1(props.pierWidth1);
    setPierWidth2(props.pierWidth2);
    setGrabAllowance1(props.grabAllowance1);
    setGrabAllowance2(props.grabAllowance2);
    setProcessRange1(props.processRange1);
    setProcessRange2(props.processRange2);
    setPickupTime(props.pickupTime);
  }, [
    props.editMode,
    props.materialArrangeListData,
    props.pierWidth1,
    props.pierWidth2,
    props.grabAllowance1,
    props.grabAllowance2,
    props.processRange1,
    props.processRange2,
    props.pickupTime,
  ]);

  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table style={{ width: '80%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center', verticalAlign: 'middle', height: 30 }}>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                桟幅(mm)
              </th>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                掴み代(mm)
              </th>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                部品加工範囲(mm)
              </th>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                掴みかえ加算時間(秒)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ textAlign: 'center' }}>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={pierWidth1}
                  onChange={(e) => {
                    setPierWidth1(e.target.value);
                    props.updatePierWidth1(e.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={pierWidth2}
                  onChange={(e) => {
                    setPierWidth2(e.target.value);
                    props.updatePierWidth2(e.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={grabAllowance1}
                  onChange={(e) => {
                    setGrabAllowance1(e.target.value);
                    props.updateGrabAllowance1(e.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={grabAllowance2}
                  onChange={(e) => {
                    setGrabAllowance2(e.target.value);
                    props.updateGrabAllowance2(e.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={processRange1}
                  onChange={(e) => {
                    setProcessRange1(e.target.value);
                    props.updateProcessRange1(e.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={processRange2}
                  onChange={(e) => {
                    setProcessRange2(e.target.value);
                    props.updateProcessRange2(e.target.value);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={pickupTime}
                  onChange={(e) => {
                    setPickupTime(e.target.value);
                    props.updatePickupTime(e.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default Device_MaterialArrangement;
