import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Input, Select, Image, DatePicker, Checkbox } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import search from '../../assets/images/search.png';
import SearchAndGetCustomerData from '../common/SearchAndGetCustomerData';
import { Customer } from '../common/Constant';
import { ClientType } from '../common/enums';
import { increaseDecreaseKeisu } from '../common/Common';

const orderFormSelect = [
  { id: 1, value: '試作', label: '試作' },
  { id: 2, value: '単発', label: '単発' },
  { id: 3, value: 'ロット', label: 'ロット' },
  { id: 4, value: '月産', label: '月産' },
  { id: 5, value: 'その他', label: 'その他' },
];
const deadLineSelect = [
  { id: 1, value: '特急', label: '特急' },
  { id: 2, value: '急行', label: '急行' },
  { id: 3, value: '普通', label: '普通' },
  { id: 4, value: '鈍行', label: '鈍行' },
  { id: 5, value: '寝台', label: '寝台' },
];
const creatorLst = [{ id: 1, value: '村田亮佑', label: '村田亮佑' }];

const data = [];
for (let i = 1; i < 2; i++) {
  data.push({
    key: i,
    customerNm: 'ゼロフォー株式会社',
    imgNo: `KENSYUTUBAN-A-07エ(変換不可データ)`,
    partNm: `Part-0000 ${i}`,
    customerCoefficient: '',
    managementCoefficient: '9.0',
    lotNum: '02_SPHC（黒皮）',
    orderForm: '0.03 kg',
    deadLine: '1,116.042 cm',
    setUpFactor: '¥0.00',
    surfaceTreatmentFactor: '¥0.00',
    purchaseCoefficient: '¥0.00',
    materialLostFactor: '¥0.00',
    desiredDeliveryDate: '¥0.00',
    weight: '¥0.00',
    thickness: '¥0.00',
    sizeX: '',
    sizeY: '',
    sizeZ: '',
    expense: '',
    otherCost1: '',
    otherCost2: '',
    otherCost3: '',
    otherCost4: '',
    otherCost5: '',
  });
}

const labelLstData = [];
labelLstData.push(
  {
    id: 1,
    title: 'コメント',
    data: `テストデータ `,
  },
  {
    id: 2,
    title: '見積番号',
    data: `Product-20221130 084826`,
  }
);

const labelSmallLstData = [];
labelSmallLstData.push(
  {
    id: 1,
    title: '見積担当',
    data: `2022/10/01 `,
  },
  {
    id: 2,
    title: '最終更新者',
    data: `2022/10/01`,
  }
);

const dateLstData = [];
dateLstData.push({
  id: 1,
  title: '作成日',
  data: `2022/10/01 `,
});

const customizeCostData = [];
customizeCostData.push(
  {
    id: 1,
    title: '配送費',
    data: `¥1000`,
    checked: true,
  },
  {
    id: 2,
    title: '諸経費',
    data: `¥1000`,
    checked: false,
  },
  {
    id: 3,
    title: '後見積',
    data: `¥1000`,
    checked: false,
  }
);

const customizeMemo = [];
for (let i = 1; i < 3; i++) {
  customizeMemo.push({
    id: i,
    customizeMemo: `メモ${i}`,
  });
}

const parameterCostLst = [
  {
    key: 1,
    title: '諸経費',
    data: 0,
    dataIndex: 'costLst1',
  },
  {
    key: 2,
    title: '特別費',
    data: 0,
    dataIndex: 'costLst2',
  },
  {
    key: 3,
    title: '調査費​',
    data: 0,
    dataIndex: 'costLst3',
  },
  {
    key: 4,
    title: 'カスタマイズ費用',
    data: 0,
    dataIndex: 'costLst4',
  },
];
const parameterDateLst = [
  {
    key: 1,
    title: '社内納期',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst1',
  },
  {
    key: 2,
    title: '回答日',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst2',
  },
  {
    key: 3,
    title: 'カスタマイズ日付',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst3',
  },
];
const parameterLabelLst = [
  {
    key: 1,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst1',
  },
  {
    key: 2,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst2',
  },
  {
    key: 3,
    title: 'カスタマイズラベル',
    data: '',
    dataIndex: 'labelLst3',
  },
];
const parameterMemoLst = [
  {
    key: 1,
    title: '注意事項',
    data: '',
    dataIndex: 'memoLst1',
  },
  {
    key: 2,
    title: '見積備考',
    data: '',
    dataIndex: 'memoLst2',
  },
  {
    key: 3,
    title: 'コメント',
    data: '',
    dataIndex: 'memoLst3',
  },
  {
    key: 4,
    title: 'カスタマイズメモ',
    data: '',
    dataIndex: 'memoLst4',
  },
];

const ParentEstimateDetail = forwardRef((props, ref) => {
  const location = useLocation();
  const [imgNo, setImgNo] = useState('初期値');
  const [name, setName] = useState('');
  const [lotNum, setLotNum] = useState(0);
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [estimator, setEstimator] = useState('');
  const [estimateNo, setEstimateNo] = useState('');
  const [creator, setCreator] = useState('');
  const [latestUpdator, setLatestUpdator] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isOrder, setIsOrder] = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const [shippingArea, setShippingArea] = useState('');
  const [customerNm, setCustomerNm] = useState('');
  const [customerCoefficient, setCustomerCoefficient] = useState(0);
  const [managementCoefficient, setManagementCoefficient] = useState(0);
  const [purchaseCoefficient, setPurchaseCoefficient] = useState(0);
  const [materialLossCoefficient, setMaterialLossCoefficient] = useState(0);
  const [setupCoefficient, setSetupCoefficient] = useState(0);
  const [surfaceAreaCoefficient, setSurfaceAreaCoefficient] = useState(0);
  const [deliveryCoefficient, setDeliveryCoefficient] = useState(0);
  const [orderCoefficient, setOrderCoefficient] = useState(0);
  const [deliveryType, setDeliveryType] = useState('');

  const [comment, setComment] = useState('');
  const [sizeX, setSizeX] = useState('');
  const [sizeY, setSizeY] = useState('');
  const [sizeZ, setSizeZ] = useState('');

  const [labelOpen, setLabelOpen] = useState(true);
  const [lableSmallOpen, setLableSmallOpen] = useState(true);
  const [customizeOpen, setCustomizeOpen] = useState(true);
  const [dateOpen, setdateOpen] = useState(true);
  const [labelLst, setLabelLst] = useState([]);
  const [dateLst, setDateLst] = useState([]);
  const [customizeCostLst, setCustomizeCostLst] = useState([]);
  const [memoLst, setMemoLst] = useState([]);

  const [createdDate, setCreatedDate] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const { TextArea } = Input;

  const [parentEstimateInfo, setParentEstimateInfo] = useState([]);

  const [customerModal, setCustomerModal] = useState(false);

  const [tankaPriotiryChecked, setTankaPriotiryChecked] = useState(true);

  const paramCostLst = JSON.parse(localStorage.getItem('paramCommonCost')); // 追加項目の費用マスタ情報
  const paramLabelLst = JSON.parse(localStorage.getItem('paramCommonLabel')); // 追加項目のラベルマスタ情報
  const paramMemoLst = JSON.parse(localStorage.getItem('paramCommonMemo')); // 追加項目のメモマスタ情報
  const paramDateLst = JSON.parse(localStorage.getItem('paramCommonDate')); // 追加項目の日付マスタ情報

  useEffect(() => {
    let partDetail = props.selectedDataDetail != undefined ? props.selectedDataDetail : [];
    setParentEstimateInfo(partDetail);
    setImgNo(partDetail.imgNo);
    setName(partDetail.name);
    setLotNum(partDetail.lotNum);
    setCustomerNm(partDetail.customerNm);
    setCustomerCoefficient(partDetail.customerCoefficient);
    setManagementCoefficient(partDetail.managementCoefficient);
    setPurchaseCoefficient(partDetail.purchaseCoefficient);
    setMaterialLossCoefficient(partDetail.materialLossCoefficient);
    setSetupCoefficient(partDetail.setupCoefficient);
    setSurfaceAreaCoefficient(partDetail.surfaceAreaCoefficient);
    setDeliveryCoefficient(partDetail.deliveryCoefficient);
    setOrderCoefficient(partDetail.orderCoefficient);
    setIsOrder(partDetail.isOrder);
    setDeliveryType(partDetail.deliveryType);
    setShippingMethod(partDetail.shippingMethod);
    setShippingArea(partDetail.shippingArea);
    setSurfaceArea(partDetail.surfaceArea);
    setSizeX(partDetail.sizeX);
    setSizeY(partDetail.sizeY);
    setSizeZ(partDetail.sizeZ);
    setWeight(partDetail.childPartTotalWeight);
    setComment(partDetail.comment);
    setEstimator(partDetail.estimator);
    setCreator(partDetail.creator);
    setLatestUpdator(partDetail.latestUpdator);
    setCustomizeCostLst(partDetail.costLst);
    setDateLst(partDetail.dateLst);
    setLabelLst(partDetail.labelLst);
    setMemoLst(partDetail.memoLst);
    setEstimateNo(partDetail.estimateNo);
    setCreatedDate(dayjs().format(partDetail.createdDate));
    setUpdatedDate(dayjs().format(partDetail.updatedDate));

    const imgNo = partDetail.imgNo == undefined ? '' : partDetail.imgNo;
    if (imgNo == '' || imgNo == '初期値') {
      setTankaPriotiryChecked(false);
    } else {
      const costLst = partDetail.costLst == undefined ? [] : partDetail.costLst;
      if (costLst.length > 0) {
        const isAllUsedTanka = partDetail.costLst?.filter((item) => item.isUsedTanka == false);
        if (isAllUsedTanka.length == 0) {
          setTankaPriotiryChecked(true);
        } else {
          setTankaPriotiryChecked(false);
        }
      }
    }
  }, [props.selectedDataDetail]);

  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  useImperativeHandle(
    ref,
    () => ({
      updatePartData: updatePartData,
      setEditModeChange: setEditModeChange,
      getParentEstimateInfo: () => {
        return parentEstimateInfo;
      },
    }),
    [parentEstimateInfo]
  );
  const getCustomerName = () => {
    setCustomerModal(true);
  };

  const materialNmHandleChange = (value) => {
    setIsOrder(value);
  };
  const thicknessHandleChange = (value) => {
    setThickness(value);
  };
  const updatePartData = (value) => {
    console.log(`selected ${value}`);
  };
  const setEditModeChange = (mode) => {
    console.log(`setEditModeChange---- ${mode}`);
    setEditMode(mode);
  };

  const labelOpenDetail = (e, isShow) => {
    setLabelOpen(isShow);
  };
  const labelSmallOpenDetail = (e, isShow) => {
    setLableSmallOpen(isShow);
  };
  const customizeOpenDetail = (e, isShow) => {
    setCustomizeOpen(isShow);
  };
  const dateOpenDetail = (e, isShow) => {
    setdateOpen(isShow);
  };
  const onValChange = (name, value) => {
    const data = parentEstimateInfo;
    data[name] = value;
    setParentEstimateInfo(data);
  };
  const onArrValChange = (i, e, name, type) => {
    if (type == 'cost') {
      const data = [...customizeCostLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo(data);
    } else if (type == 'date') {
      const data = [...dateLst];
      data[i][name] = e;
      setParentEstimateInfo(data);
    } else if (type == 'label') {
      const data = [...labelLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo(data);
    } else if (type == 'memo') {
      const data = [...memoLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo(data);
    }
  };
  const onChangeCreatedDate = (name, dateString) => {
    setCreatedDate(dateString);
    const data = parentEstimateInfo;
    data['createdDate'] = dateString;
    setParentEstimateInfo(data);
  };
  const onChangeUpdatedDate = (name, dateString) => {
    setUpdatedDate(dateString);
    const data = parentEstimateInfo;
    data['updatedDate'] = dateString;
    setParentEstimateInfo(data);
  };
  const selectedCustomerData = (selectedCustomerData) => {
    setCustomerNm(selectedCustomerData.name);
    setCustomerModal(false);
  };
  const settingTankaPriority = (e) => {
    setTankaPriotiryChecked(e.target.checked);
    const data = [...customizeCostLst];
    for (let i = 0; i < data.length; i++) {
      data[i].isUsedTanka = e.target.checked;
    }
    setCustomizeCostLst(data);
  };

  return (
    <>
      {/* 部品プロパティアリア */}
      <div
        style={{
          // margin: '2px',
          display: 'flex',
        }}
      >
        <div style={{ float: 'left', width: '55%' }}>
          <Table className="propertiesTbl">
            <thead>
              {/** 図番 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">図番</label>
                </td>
                <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '91.7%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    //
                    name="imgNo"
                    id="imgNo"
                    value={imgNo}
                    type="text"
                    onChange={(e) => {
                      setImgNo(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
              {/** 名称 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">名称</label>
                </td>
                <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '91.7%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
              {/** お客様名／お客様係数 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">お客様名／お客様係数</label>
                </td>
                <td colSpan={8} style={{ width: '50%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="customerNm"
                    id="customerNm"
                    type="text"
                    onChange={(e) => {
                      setCustomerNm(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    value={customerNm}
                    suffix={
                      editMode ? (
                        <a onClick={getCustomerName}>
                          <Image preview={false} width={20} height={20} src={search} style={{ marginBottom: '5px' }} />
                        </a>
                      ) : (
                        ''
                      )
                    }
                  />
                </td>
                <td
                  colSpan={2}
                  style={{ width: '27.5%', borderLeft: '0.1px solid #a5a5a5' }}
                  className="detail-tbl-val"
                >
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="customerCoefficient"
                      id="customerCoefficient"
                      type="text"
                      onChange={(e) => {
                        setCustomerCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={customerCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined
                          style={{ fontSize: 16, marginTop: 7 }}
                          // onClick={(e) => setCustomerCoefficient(customerCoefficient)}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 16, marginTop: 7 }}
                          // onClick={(e) => setCustomerCoefficient(customerCoefficient)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
              {/** 数量 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">数量</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '76%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="lotNum"
                    id="lotNum"
                    value={lotNum}
                    type="text"
                    onChange={(e) => {
                      setLotNum(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
                <td colSpan={2} style={{ width: '26%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">管理係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="managementCoefficient"
                      id="managementCoefficient"
                      type="text"
                      onChange={(e) => {
                        setManagementCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={managementCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
              {/** 受注形態 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">受注形態</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={isOrder}
                      onChange={(e) => {
                        setIsOrder(e);
                        const data = parentEstimateInfo;
                        data['isOrder'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '76%' }}
                    >
                      {orderFormSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{isOrder}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">受注係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="orderCoefficient"
                      id="orderCoefficient"
                      type="text"
                      onChange={(e) => {
                        setOrderCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={orderCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
              {/** 納期 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">納期</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={deliveryType}
                      onChange={(e) => {
                        setDeliveryType(e);
                        const data = parentEstimateInfo;
                        data['deliveryType'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '76%' }}
                    >
                      {deadLineSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{deliveryType}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">納期係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="deliveryCoefficient"
                      id="deliveryCoefficient"
                      type="text"
                      onChange={(e) => {
                        setDeliveryCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={deliveryCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
              {/** 段取係数 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">段取係数</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="setupCoefficient"
                      id="setupCoefficient"
                      type="text"
                      onChange={(e) => {
                        setSetupCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={setupCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">購入品係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="purchaseCoefficient"
                      id="purchaseCoefficient"
                      type="text"
                      onChange={(e) => {
                        setPurchaseCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={purchaseCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
              {/** 表面処理係数 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">表面処理係数</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="surfaceAreaCoefficient"
                      id="surfaceAreaCoefficient"
                      type="text"
                      onChange={(e) => {
                        setSurfaceAreaCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={surfaceAreaCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材料ロス係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="materialLossCoefficient"
                      id="materialLossCoefficient"
                      type="text"
                      onChange={(e) => {
                        setMaterialLossCoefficient(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={materialLossCoefficient}
                    />
                    {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
              {/** 配送方法 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">配送方法</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={shippingMethod}
                      onChange={(e) => {
                        setShippingMethod(e);
                        const data = parentEstimateInfo;
                        data['shippingMethod'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '76%' }}
                    >
                      {deadLineSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{shippingMethod}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">配送エリア</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={shippingArea}
                      onChange={(e) => {
                        setShippingArea(e);
                        y;
                        const data = parentEstimateInfo;
                        data['shippingArea'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '76%' }}
                    >
                      {deadLineSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{shippingArea}</label>
                  )}
                </td>
              </tr>
              {/** 重量・面積 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">重量（kg）</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '76%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="weight"
                    id="weight"
                    value={weight}
                    type="text"
                    onChange={(e) => {
                      setWeight(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">
                    面積（㎜<sup>2</sup>）
                  </label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '76%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="surfaceArea"
                      id="surfaceArea"
                      type="text"
                      onChange={(e) => {
                        setSurfaceArea(e.target.value);
                        const data = parentEstimateInfo;
                        data[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={surfaceArea}
                    />
                    {/* {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )} */}
                  </div>
                </td>
              </tr>
              {/** 作成者・最終更新者 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">作成者</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={creator}
                      onChange={(e) => {
                        setCreator(e);
                        const data = parentEstimateInfo;
                        data['creator'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '76%' }}
                    >
                      {creatorLst.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{creator}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">最終更新者</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <Select
                        className="iq3-detail-select"
                        value={latestUpdator}
                        onChange={(e) => {
                          setLatestUpdator(e);
                          const data = parentEstimateInfo;
                          data['latestUpdator'] = e;
                          setParentEstimateInfo(data);
                        }}
                        style={{ marginLeft: 5, width: '76%' }}
                      >
                        {creatorLst.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>{latestUpdator}</label>
                    )}
                  </div>
                </td>
              </tr>
              {/** 作成日・更新日 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">作成日</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <DatePicker
                      style={{ padding: 5, marginLeft: 5, width: '75.7%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      onChange={onChangeCreatedDate}
                      value={dayjs(createdDate, 'YYYY-MM-DD')}
                    />
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{createdDate}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">更新日</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <DatePicker
                        style={{ padding: 5, marginLeft: 5, width: '75.7%' }}
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        onChange={onChangeUpdatedDate}
                        value={dayjs(updatedDate, 'YYYY-MM-DD')}
                      />
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>{updatedDate}</label>
                    )}
                  </div>
                </td>
              </tr>
              {/** XYZサイズ（mm） */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">XYZサイズ（mm）</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '76%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeX"
                    id="sizeX"
                    value={sizeX}
                    type="text"
                    onChange={(e) => {
                      setSizeX(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    placeholder="Xサイズ"
                  />
                </td>
                <td colSpan={2} style={{ width: '25%', border: '0.1px solid #a5a5a5' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '80%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeY"
                    id="sizeY"
                    value={sizeY}
                    type="text"
                    onChange={(e) => {
                      setSizeY(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    placeholder="Yサイズ"
                  />
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '76%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeZ"
                    id="sizeZ"
                    value={sizeZ}
                    type="text"
                    onChange={(e) => {
                      setSizeZ(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    placeholder="Zサイズ"
                  />
                </td>
              </tr>
              {/** 見積番号 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">見積番号</label>
                </td>
                <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '91.7%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="estimateNo"
                    id="estimateNo"
                    value={estimateNo}
                    type="text"
                    onChange={(e) => {
                      setEstimateNo(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
              {/** コメント */}
              <tr style={{ height: '110px' }}>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">コメント</label>
                </td>
                <td colSpan={10} style={{ width: '80%', height: '133px' }} className="detail-tbl-val">
                  <TextArea
                    style={{ padding: 8, marginLeft: 5, width: '91.7%', verticalAlign: 'Top', fontSize: '13px' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="comment"
                    id="comment"
                    value={comment}
                    type="text"
                    rows={5}
                    onChange={(e) => {
                      setComment(e.target.value);
                      const data = parentEstimateInfo;
                      data[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        <div style={{ float: 'right', width: '45%', marginLeft: '15px', overflowY: 'auto', height: '80.5vh' }}>
          <Table className="propertiesTbl">
            <thead>
              {/* 費用 */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {customizeOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => customizeOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => customizeOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>費用</label>
                  </div>
                </td>
              </tr>
              <tr style={{ display: customizeCostLst?.length > 0 && customizeOpen ? '' : 'none' }}>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{ textAlign: 'center', backgroundColor: '#d9d9d99e', color: 'black' }}
                >
                  <label style={{ marginLeft: 5 }}>名称</label>
                </td>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9d9d99e',
                    color: 'black',
                    borderLeft: '0.1px dashed #a5a5a5',
                  }}
                >
                  <label style={{ marginLeft: 5 }}>単価</label>
                </td>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9d9d99e',
                    color: 'black',
                    borderLeft: '0.1px dashed #a5a5a5',
                  }}
                >
                  <label style={{ marginLeft: 5 }}>単価優先</label>
                  <Checkbox
                    style={{ marginLeft: '5px' }}
                    disabled={editMode ? false : true}
                    onChange={(e) => settingTankaPriority(e)}
                    checked={tankaPriotiryChecked}
                  />
                </td>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9d9d99e',
                    color: 'black',
                    borderLeft: '0.1px dashed #a5a5a5',
                  }}
                >
                  <label style={{ marginLeft: 5 }}>合計</label>
                </td>
              </tr>
              {paramCostLst != undefined && customizeCostLst != undefined
                ? paramCostLst.map((item, index) => {
                    const { key, name } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    let record = customizeCostLst?.filter((record) => record.key === key);
                    const title = name;
                    const data = record.length > 0 ? record[0].data : 0;
                    const isUsedTanka = record.length > 0 ? record[0].isUsedTanka : false;

                    return (
                      <tr style={{ display: customizeOpen ? '' : 'none' }}>
                        <td colSpan={3} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', textAlign: 'center', border: 'none' }}
                            // className={editMode ? 'input-editable' : 'input-non-editable'}
                            className={'input-non-editable'}
                            name="title"
                            id="labelTitle"
                            value={title}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'cost')}
                          />
                        </td>
                        <td
                          colSpan={3}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', textAlign: 'center' }}
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name="data"
                            id="labelData"
                            value={data}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'cost')}
                            prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                          />
                        </td>
                        <td
                          colSpan={3}
                          style={{ width: '23%', textAlign: 'center', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Checkbox
                            id={title + index}
                            disabled={editMode ? false : true}
                            checked={isUsedTanka}
                            onChange={(e) => {
                              const data = [...customizeCostLst];
                              data[index].isUsedTanka = e.target.checked;
                              const isAllUsedTanka = data.filter((item) => item.isUsedTanka == false);
                              if (isAllUsedTanka.length == 0) {
                                setTankaPriotiryChecked(true);
                              } else {
                                setTankaPriotiryChecked(false);
                              }
                              setCustomizeCostLst(data);
                            }}
                          />
                        </td>
                        <td
                          colSpan={3}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', textAlign: 'center' }}
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name="data"
                            id="labelData"
                            value={data}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'cost')}
                            prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}
              {/* 日付 */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {dateOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => dateOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => dateOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>日付</label>
                  </div>
                </td>
              </tr>
              {paramDateLst != undefined && dateLst != undefined
                ? paramDateLst.map((item, index) => {
                    const { key, name } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    let record = dateLst?.filter((record) => record.key === key);
                    const title = name;
                    const data = record.length > 0 ? record[0].data : 0;

                    return (
                      <tr style={{ display: dateOpen ? '' : 'none' }}>
                        <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                            // className={editMode ? 'input-editable' : 'input-non-editable'}
                            className={'input-non-editable'}
                            name="title"
                            id="labelTitle"
                            value={title}
                            type="text"
                            // onChange={(e) => onArrValChange(index, e, e.target.name, 'date')}
                          />
                        </td>
                        <td
                          colSpan={10}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          {editMode ? (
                            <DatePicker
                              name="data"
                              id="labelData"
                              style={{ padding: 5, marginLeft: 5, width: '29.7%' }}
                              className={editMode ? 'input-editable' : 'input-non-editable'}
                              onChange={(e, dateString) => onArrValChange(index, dateString, 'data', 'date')}
                              value={dayjs(data, 'YYYY-MM-DD')}
                            />
                          ) : (
                            <label style={{ fontSize: '13px', paddingLeft: 10 }}>{data}</label>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : ''}
              {/* ラベル */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {lableSmallOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelSmallOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelSmallOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>ラベル</label>
                  </div>
                </td>
              </tr>
              {paramLabelLst != undefined && labelLst != undefined
                ? paramLabelLst.map((item, index) => {
                    const { key, name } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    let record = labelLst?.filter((record) => record.key === key);
                    const title = name;
                    const data = record.length > 0 ? record[0].data : 0;
                    return (
                      <tr style={{ display: lableSmallOpen ? '' : 'none' }}>
                        <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                            // className={editMode ? 'input-editable' : 'input-non-editable'}
                            className={'input-non-editable'}
                            name="title"
                            id="labelTitle"
                            value={title}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'label')}
                          />
                        </td>
                        <td
                          colSpan={10}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '30%' }}
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name="data"
                            id="labelData"
                            value={data}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'label')}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}

              {/** メモ */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {labelOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>メモ</label>
                  </div>
                </td>
              </tr>
              {paramMemoLst != undefined && memoLst != undefined
                ? paramMemoLst.map((item, index) => {
                    const { key, name } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    let record = memoLst?.filter((record) => record.key === key);
                    const title = name;
                    const data = record.length > 0 ? record[0].data : '';
                    return (
                      <tr style={{ display: labelOpen ? '' : 'none' }}>
                        <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '79%', border: 'none' }}
                            // className={editMode ? 'input-editable' : 'input-non-editable'}
                            className={'input-non-editable'}
                            name="title"
                            id="labelTitle"
                            value={title}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'memo')}
                          />
                        </td>
                        <td
                          colSpan={10}
                          style={{
                            width: '80%',
                            borderLeft: '0.1px dashed #a5a5a5',
                          }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%' }}
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name="data"
                            id="labelData"
                            value={data}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'memo')}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}
            </thead>
          </Table>
        </div>
      </div>

      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={selectedCustomerData}
          title={Customer}
          clientType={ClientType.Customer}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default ParentEstimateDetail;
