/**
 * クラス名：装置設定の製品重量係数画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Input, Button, Space } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';

import { commonModal } from '../../../common/CommonModal';

const ProductSize = forwardRef((props, ref) => {
  const [weightData, setWeightData] = useState([]);
  const [volumeData, setVolumeData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isVolume, setIsVolume] = useState(false);
  const [isWeight, setIsWeight] = useState(false);

  const [detailsVolumeTemp, setDetailsVolumeTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  const [sizeList, setSizelist] = useState([]);
  useEffect(() => {
    setEditMode(props.editMode);
    setWeightData(props.weight);
    setVolumeData(props.areas);
    setDetailsVolumeTemp(props.areas);
    setDetailsWeightTemp(props.weight);
    setDetailsList(props.sizeList);
    console.log('ProductSize', props.sizeList);
    setSizelist(props.dantoriSizeList);
  }, [props.weight, props.dantoriSizeList, props.area, props.editMode, props.sizeList]);
  const addArea = () => {
    setIsVolume(true);
  };
  const addWeight = () => {
    setIsWeight(true);
  };
  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>体積</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const addVolumeRow = (id) => {
    const copyData = detailsVolumeTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsVolumeTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsVolumeTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsVolumeTemp(copyData);
    }
  };
  const deleteVolume = (id, e) => {
    if (detailsVolumeTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsVolumeTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsVolumeTemp(updatedData);
    }
  };
  const deleteWeight = (id, e) => {
    if (detailsWeightTemp.filter((item) => item.displayFlag === true).length > 1) {
      const updatedData = detailsWeightTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
    }
  };
  const addWeightRow = (id) => {
    // const data = {
    //   id: detailsWeightTemp.length + 1,
    //   min: '',
    //   max: '',
    //   displayFlag: true,
    // };
    // let result = [...detailsWeightTemp, data];
    // setDetailsWeightTemp(result);
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsWeightTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };
  const closeAddModal = () => {
    if (isVolume === true) {
      setIsVolume(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    }
  };
  const handleVolumeChange = (event, id, field) => {
    const updatedData = detailsVolumeTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsVolumeTemp(updatedData);
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = detailsWeightTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };
  const cancelVolumeMaster = () => {
    setDetailsVolumeTemp(volumeData);
    setIsVolume(false);
  };
  const addVolumeMaster = () => {
    const deletedId = [];

    volumeData.forEach((item1, index) => {
      const found = detailsVolumeTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsVolumeTemp
      .filter((item) => item.displayFlag && !volumeData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = detailsList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsVolumeTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsVolumeTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsVolume(false);
    setDetailsVolumeTemp(detailsVolumeTemp);

    setDetailsList(sortedList);
    props.updatedVolume(detailsVolumeTemp);

    props.updatedSizesList(sortedList);
  };
  const cancelWeightMaster = () => {
    setDetailsWeightTemp(weightData);
    setIsWeight(false);
  };
  const deleteListData = (list, deletedId) => {
    const deletedData = list.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    return deletedData;
  };
  const updateListData = (deletedData, newId) => {
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        lstData: sortedLstData,
      };
    });
    return sortedList;
  };
  const addWeightMaster = () => {
    const deletedId = [];

    weightData.forEach((item1, index) => {
      const found = detailsWeightTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsWeightTemp
      .filter((item) => item.displayFlag && !weightData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData(detailsList, deletedId);
    const deletedData2 = deleteListData(sizeList, deletedId);
    // const result = deletedData.map((item) => {
    //   const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });

    // newId.forEach((id) => {
    //   const list = detailsWeightTemp.find((item) => item.id === id);

    //   if (list) {
    //     const newObj = {
    //       key: id,
    //       max: list.max,
    //       displayFlag: true,
    //       lstData: result[0].lstData.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '',
    //         };
    //       }),
    //     };

    //     result.push(newObj);
    //   }
    // });
    const updatedData = updateListData(deletedData, newId);
    const updatedData2 = updateListData(deletedData2, newId);
    const sortedData = sortListData(updatedData, detailsWeightTemp);
    const sortedData2 = sortListData(updatedData2, detailsWeightTemp);
    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    setDetailsList(sortedData);
    props.updatedWeight(detailsWeightTemp);

    props.updatedSizesList(sortedData);
    setSizelist(sortedData2);
    props.updatedDantoriSizeList(sortedData2);
  };
  const addVolumeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsVolumeTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleVolumeChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addVolumeRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteVolume(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addVolumeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelVolumeMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleWeightChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...detailsList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setDetailsList(updatedData);
    props.updatedSizesList(updatedData);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              weightData.filter((i) => i.displayFlag === true).length > 8
                ? '100%'
                : weightData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
          }}
        >
          <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }}>
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                        重量
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>重量</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>
            {weightData.map((row, rowIndex) => (
              <td style={{ color: 'white', width: '98px' }}>～{row.max}kg</td>
            ))}
          </tr>

          {/* <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }}>重量係数</td>

            {detailsList.map((col, colIndex) => (
              <td style={{ width: '98px' }}>
                <Input
                  value={col.lstData[0].value}
                  onChange={(e) => listDataChange(e, colIndex, 0)}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            ))}
          </tr> */}
          {detailsList
            .filter((i) => i.key === 1)
            .map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td style={{ width: '196px' }}>重量係数</td>

                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
        </Table>
      </div>
      {/* 体積 */}
      {isVolume
        ? commonModal(
            isVolume,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addVolumeContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default ProductSize;
