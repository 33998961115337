/**
 * クラス名：装置設定一覧画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Col, Input, Row, Select, Table, Button, Checkbox } from 'antd';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { Table as RTable } from 'react-bootstrap';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';

import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  JPYs,
  getKubunNameDDL,
  actionArr,
} from '../../../common/Common.js';
import DevicesDetails from './DevicesDetails';
import { kouteiMasterData } from '../../../common/Constant';
import {
  confirmCreateContent,
  confirmHakiContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDeleteContent,
} from '../../../common/CommonModal';
import { Customer } from '../../../common/Constant';
import { ClientType } from '../../../common/enums.js';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData.js';
// 溶接線長
const weldLineMasterData = [
  { id: 1, max: 11, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
// 曲げ個数
const mageKosuuData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// iq3板厚
const iq3ItaatsuData = [
  { id: 1, max: 11, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
// iq3ガス種類
const gasTypeData = [
  { id: 1, val: '酸素', displayFlag: true },
  { id: 2, val: '窒素', displayFlag: true },
  { id: 3, val: 'Easy', displayFlag: true },
  { id: 4, val: 'Air', displayFlag: true },
];
const retainedMoldData = [
  {
    id: 1,
    val: '5.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 2,
    val: '10.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 7,
    val: '20.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 6,
    val: '30.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 5,
    val: '40.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 4,
    val: '50.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 3,
    val: '80.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 8,
    val: '110.0 x 5.0',
    displayFlag: true,
  },
];
// ジョイントサイズ
const jointSizeData = [
  {
    id: 1,
    max: '100',
    displayFlag: true,
  },
  {
    id: 6,
    val: '',
    displayFlag: true,
    max: '400',
  },
  {
    id: 5,
    val: '',
    displayFlag: true,
    max: '800',
  },
  {
    id: 4,
    val: '',
    displayFlag: true,
    max: '1600',
  },
  {
    id: 3,
    val: '',
    displayFlag: true,
    max: '10000',
  },
  {
    id: 2,
    max: 99999,
    displayFlag: true,
  },
];
// iq3曲げ数
const mageSuuData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// 板厚
const itaatsuMasterData = [
  { id: 1, max: 0.8, displayFlag: true },
  { id: 2, max: 1, displayFlag: true },
  { id: 3, max: 1.2, displayFlag: true },
];
// パンチ条件
const punchData = [
  { key: 1, name: '1パンチ丸穴径', val: '' },
  { key: 2, name: '1パンチ矩形周長', val: '' },
  { key: 3, name: '二ブリングピッチ', val: '' },
];
// 材料配置

// 板厚(溶接難易度)
const yousetsuNanidoItaatsuMasterData = [
  { id: 1, max: 5, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
// 板厚(仮付き時間)
const karitsukiJikanItaatsuMasterData = [
  { id: 1, max: 1, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
// 製品重量係数
const weightMasterData = [
  { id: 1, max: 20, displayFlag: true },
  { id: 2, max: 999, displayFlag: true },
];
const taisekiMasterData = [
  { id: 1, max: 20, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
const areaMasterData = [
  { id: 1, max: 200, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
const iq3areaMasterData = [
  { id: 1, max: 100, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
const iq3SizeMasterData = [
  { id: 1, max: 1, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
const lengthMasterData = [
  { id: 1, max: 30, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
// 取付時間のサイズ
const sizeMasterData = [
  { id: 1, max: 4, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
// 生産個数係数
const quantityMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
  { id: 3, val: 3, displayFlag: true },
];
// 数量区分
const suuryouKubunMasterData = [
  { id: 1, max: 1, displayFlag: true },
  { id: 2, max: 2, displayFlag: true },
];
// 要素数
const yousosuuMasterData = [
  { id: 1, max: 10, displayFlag: true },
  { id: 2, max: 25, displayFlag: true },
  { id: 3, max: 99999, displayFlag: true },
];
// 名称
const meishouMasterData = [
  { id: 1, name: '極粗級', displayFlag: true },
  { id: 2, name: '粗級', displayFlag: true },
  { id: 3, name: '中級', displayFlag: true },
  { id: 4, name: '精級', displayFlag: true },
];
// iq3生産個数
const iq3SeisanKosuuMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// iq3本数
const iq3honsuuMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];

// 数量区分
const keisuu = ['係数'];
// 数量
const suuryouTypes = ['子部品点数', '子部品種類数'];
// デフォルト値
const defaultCharge = 4200;
// 時間チャージ
const jikanchaji = [
  {
    id: 1,
    val: defaultCharge,
  },
  {
    id: 2,
    val: defaultCharge,
  },
  {
    id: 3,
    val: defaultCharge,
  },
];

// 子部品数係数（検査と梱包）
const kobuhinData = [
  {
    key: 1,
    name: '子部品点数',
    lstData: [
      {
        key: 1,
        name: 'iQ3',
        val: '',
      },
      {
        key: 2,
        name: 'iQ5',
        val: '',
      },
      {
        key: 3,
        name: 'iQ7',
        val: '',
      },
    ],
  },
  {
    key: 2,
    name: '子部品種類数',
    lstData: [
      {
        key: 1,
        name: 'iQ3',
        val: '',
      },
      {
        key: 2,
        name: 'iQ5',
        val: '',
      },
      {
        key: 3,
        name: 'iQ7',
        val: '',
      },
    ],
  },
];
// デフォルトページの表示件数
const defaultPageSize = 25;

const DevicesList = forwardRef((props, ref) => {
  //   Content Resize
  const [sizes, setSizes] = useState(['45%']);

  const [tblColumns, setTblColumns] = useState([]);
  const navigate = useNavigate();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [clickedAction, setClickedAction] = useState(0);

  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  // 新規modal
  const [isNew, setIsNew] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isDiscardData, setIsDiscardData] = useState(false);

  // 削除Modal
  const [isDelete, setIsDelete] = useState(false);

  // 新規作成
  const [category, setCategory] = useState('標準');
  const [code, setCode] = useState('');
  const [kouteiType, setKouteiType] = useState('');
  const [kouteiName, setKouteiName] = useState('');
  const [kakouHouhou, setKakouHouhou] = useState([]);
  const [kakouName, setKakouName] = useState([]);
  const [kakouCode, setKakouCode] = useState([]);
  const [souchiCode, setSouchiCode] = useState('');
  const [souchiName, setSouchiName] = useState('');
  const [setupData, setSetupData] = useState('0');
  const [workData, setWorkData] = useState(0);
  const [tsuikaKouteiType, setTsuikaKouteiType] = useState('');
  const [tsuikaKouteiTypes, setTsuikaKouteiTypes] = useState([]);
  const [initialAmount, setInitialAmount] = useState(0);

  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  const [initialTblItemsBk, setInitialTableItemsBk] = useState([]); // 初期表示に表示する項目
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // 検索
  const [process, setProcess] = useState('');

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);

  const [flagChecked, setFlagChecked] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);

  const [customerModal, setCustomerModal] = useState(false);

  // 複写
  const [deviceCopy, setDeviceCopy] = useState([]);
  const [sourceDevice, setSourceDevice] = useState('');

  const [customerCol, setCustomerCol] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState([]);
  const [selectedCustomerRowKeys, setSelectedCustomerRowKeys] = useState([]);
  const [initialCustomerData, setInitialCustomerData] = useState([]); // 初期表示に表示する項目
  const [initialCustomerDataBk, setInitialCustomerDataBk] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const [remarks, setRemarks] = useState('');

  const [flag, setFlag] = useState(false);
  const initialValues = [8, 11, 13, 16, 17];

  const [weldLineMaster, setWeldLineMaster] = useState([]);
  const [areaMaster, setAreaMaster] = useState([]);
  const [weightMaster, setWeightMaster] = useState([]);
  const [volumeMaster, setVolumeMaster] = useState([]);

  const [suuryouKubunMaster, setSuuryouKubunMaster] = useState([]);
  // 要素数
  const [yousosuuMaster, setYousosuuMaster] = useState([]);

  // 溶接難易度
  const [yousetsuNanidoItaatsuMaster, setYousetsuNanidoItaatsuMaster] = useState([]);
  // 仮付き時間
  const [karitsukiJikanItaatsuMaster, setKaritsukiJikanItaatsuMaster] = useState([]);

  // 取付き時間
  const [toritsukiJikanItaatsuMaster, setToritsukiJikanItaatsuMaster] = useState([]);
  const [toritsukiJikanSizeMaster, setToritsukiJikanSizeMaster] = useState([]);

  // 加工速度（板厚）
  const [kakouSokudoItaatsuMaster, setKakouSokudoItaatsuMaster] = useState([]);

  // 名称
  const [meishouMaster, setMeishouMasterData] = useState([]);

  // 個数
  const [kosuuMaster, setKosuuMaster] = useState([]);

  // 段取時間
  const [dantoriTime, setDantoriTime] = useState('00:00:00');
  const [workTime, setWorkTime] = useState('00:00:00');

  const [kouteiMaster, setKouteiMaster] = useState([]);

  const [yousetsuSenChouMasterData, setYousetsuSenChouMasterData] = useState([]);
  const [yousetsuToritsukiSizeMasterData, setYousetsuToritsukiSizeMasterData] = useState([]);
  const [yousetsuToritsukiItaatsuMasterData, setYousetsuToritsukiItaatsuMasterData] = useState([]);
  const [yousetsuTypeMasterData, setYousetsuTypeMasterData] = useState([]);

  // 材質区分
  const [zaishitsuKubunn, setZaishitsuKubunn] = useState([]);

  // 絞込
  const [koteimei, setKoteimei] = useState();
  const [device, setDevice] = useState('');
  const [nameKeyword, setNameKeyWord] = useState('');

  // iq3
  const [iq3Itaatsu, setIQ3Itaatsu] = useState([]);
  // ガス種類
  const [gasType, setGasType] = useState([]);

  const [retainedMold, setRetrainedMold] = useState([]);
  // ジョイントサイズ
  const [jointSize, setJointSize] = useState([]);
  // 曲げ時間係数
  const [lengthMaster, setLengthMaster] = useState([]);
  // 曲げ数
  const [mageSuuMaster, setMageSuuMaster] = useState([]);
  // 曲げ個数
  const [mageKosuuMaster, setMageKosuuMaster] = useState([]);
  // 面積
  const [iq3AreaMaster, setIQ3AreaMaster] = useState([]);
  // 生産個数
  const [seisanKosuuMaster, setSeisanKosuuMaster] = useState([]);
  // 本数
  const [honsuuMaster, setHonsuuMaster] = useState([]);
  // サイズ
  const [iq3SizeMaster, setIQ3SizeMaster] = useState([]);
  const [itaatsu1Master, setItaatsu1Master] = useState([]);
  // ２次加工の種類
  const [secondProcessTypes, setSecondProcessTypes] = useState([]);
  const [cusCurrentPage, setCusCurrentPage] = useState(1);

  const handleDropdown = () => {
    setFlag(true);
  };

  const location = useLocation();
  const [path, setPath] = useState('');

  // テーブルColumns
  const devicesColumns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'deviceNo',
      // sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.deviceNo - b.deviceNo,
      sortOrder: 'ascend',
      width: '55px',
      className: 'cm-a-right',
      // render: (id, record, index) => {
      //   // return (cusCurrentPage - 1) * 25 + (index + 1);
      //   return index + 1;
      // },
    },
    {
      id: '2',
      title: '種別',
      dataIndex: 'deviceTypes',
      width: '65px',
    },
    {
      id: '3',
      title: 'コード',
      dataIndex: 'code',
      width: '85px',
    },
    {
      id: '4',
      title: '工程名',
      dataIndex: 'processName',
      width: '125px',
    },
    {
      id: '5',
      title: '装置コード',
      dataIndex: 'deviceCode',
      width: '85px',
    },
    {
      id: '6',
      title: '装置名',
      dataIndex: 'deviceName',
      width: '225px',
    },
    {
      id: '7',
      title: '段取チャージ',
      dataIndex: 'setupCharge',
      width: '120px',
      render: (_, amount) => <label>{amount.setupCharge >= 0 ? JPYs.format(amount.setupCharge) : '-'}</label>,
    },
    {
      id: '8',
      title: '加工チャージ',
      dataIndex: 'processCharge',
      width: '120px',
      render: (_, amount) => <label>{amount.processCharge >= 0 ? JPYs.format(amount.processCharge) : '-'}</label>,
    },
    {
      id: '9',
      title: 'チャージ金額複数有',
      dataIndex: 'multiCharge',
      width: '135px',
      render: (_, record) => (
        <a onClick={handleDropdown} style={{ textDecoration: 'underline', color: '#1063aa' }}>
          {record.multiCharge}
        </a>
      ),
    },
    {
      id: '10',
      title: '備考',
      dataIndex: 'remarks',
      width: '150px',
    },
  ];

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 55 + 'px',
      render: (id, record, index) => {
        return getCurrentData().length - index;
      },
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend',
    },
    {
      id: '2',
      title: '表示',
      dataIndex: 'display',
      width: 95 + 'px',
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '3',
      title: 'お客様フラグ',
      dataIndex: 'customerFlg',
      width: 95 + 'px',
      render: (index, item) => {
        return item['customerFlg'] === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '4',
      title: '仕入先フラグ',
      dataIndex: 'supplierFlg',
      width: 95 + 'px',
      render: (index, item) => {
        return item['supplierFlg'] === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '5',
      title: '委託先フラグ',
      dataIndex: 'outsourcerFlg',
      width: 95 + 'px',
      render: (index, item) => {
        return item['outsourcerFlg'] === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item['id']}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '6',
      title: 'コード',
      dataIndex: 'code',
      width: 120 + 'px',
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      id: '7',
      title: '枝番',
      dataIndex: 'brunchNo',
      width: 90 + 'px',
    },
    {
      id: '8',
      title: '名称',
      dataIndex: 'companyName',
      width: 150 + 'px',
    },
    {
      id: '9',
      title: 'カナ',
      dataIndex: 'companyNameKata',
      width: 150 + 'px',
    },
    {
      id: '10',
      title: '係数',
      dataIndex: 'coefficient',
      width: 70 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '11',
      title: 'Tel',
      dataIndex: 'tel',
      width: 125 + 'px',
    },
    {
      id: '12',
      title: 'Fax',
      dataIndex: 'fax',
      width: 125 + 'px',
    },
    {
      id: '13',
      title: '担当者',
      dataIndex: 'tantou',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: 'メール',
      dataIndex: 'mail',
      width: 150 + 'px',
    },
    {
      id: '15',
      title: '郵便番号',
      dataIndex: 'postalCode',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '都道府県',
      dataIndex: 'prefectures',
      width: 150 + 'px',
    },
    {
      id: '17',
      title: '住所',
      dataIndex: 'address',
      width: 150 + 'px',
    },

    {
      id: '18',
      title: 'ソート番号',
      dataIndex: 'sortNo',
      width: 95 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '19',
      title: '作成日',
      dataIndex: 'created',
      width: 100 + 'px',
    },
    {
      id: '20',
      title: '更新日',
      dataIndex: 'updated',
      width: 100 + 'px',
    },
    {
      id: '21',
      title: '備考',
      dataIndex: 'remarks',
      width: 200 + 'px',
    },
  ];

  const tableColumns = columns.filter((item) => {
    return (
      item.id ==
      initialValues.filter((i) => {
        if (i == item.id) {
          return i;
        }
      })
    );
  });
  const currentRoute = location.pathname;

  useEffect(() => {
    // const currentRoute = location.pathname;
    let deviceLst = [];
    let kosuu = [];
    // 個数
    kosuu =
      localStorage.getItem('kosuuMasterData') != undefined ? JSON.parse(localStorage.getItem('kosuuMasterData')) : [];
    // setKosuuMaster(quantityMasterData);
    const kosuuMaster1 = kosuu.filter((subitem) => subitem.displayFlag === true);

    setKosuuMaster(kosuuMaster1);
    // let deviceLst =
    //   localStorage.getItem('parent_devicesMaster') != undefined ? JSON.parse(localStorage.getItem('parent_devicesMaster')) : [];
    if (currentRoute.includes('parent')) {
      setPath(':parent.device');
      navigate(':parent.device');
      const result = kouteiMasterData.filter((i) => i.type === '親部品');
      setKouteiMaster(result);
      deviceLst =
        localStorage.getItem('parent_devicesMaster') != undefined
          ? JSON.parse(localStorage.getItem('parent_devicesMaster'))
          : [];
      deviceLst = deviceLst.filter((item) => item.type === '親部品');
      // 個数
      const kosuuUpdList = updateList2(
        deviceLst[deviceLst.length - 1].kosuuMasterData,
        kosuuMaster1,
        deviceLst[deviceLst.length - 1].kosuuLstData
      );

      deviceLst[deviceLst.length - 1]['kosuuLstData'] = kosuuUpdList;
      deviceLst[deviceLst.length - 1]['kosuuMasterData'] = kosuuMaster1;
      // setSelectedData(deviceLst.length > 0 ? deviceLst[deviceLst.length - 1] : []);
      // setSelectedRowKeys(deviceLst.length > 0 ? deviceLst[deviceLst.length - 1].deviceNo : 0);
      setSelectedData(deviceLst.length > 0 ? deviceLst[0] : []);
      setSelectedRowKeys(deviceLst.length > 0 ? deviceLst[0].deviceNo : 0);

      setInitialTableItems(deviceLst);
      setInitialTableItemsBk(deviceLst);
      // 取付Masterデータ（板厚とサイズ）
      const yousetsuTypeMasterData = result
        .filter((i) => i.processType === '溶接')[0]
        .processDetail[0].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuTypeMasterData(yousetsuTypeMasterData);
      // 取付Masterデータ（板厚とサイズ）
      const yousetsuToritsukiItaatsuMasterData = result
        .filter((i) => i.processType === '溶接')[0]
        .processDetail[1].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuToritsukiItaatsuMasterData(yousetsuToritsukiItaatsuMasterData);
      const yousetsuToritsukiSizeMasterData = result
        .filter((i) => i.processType === '溶接')[0]
        .processDetail[2].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuToritsukiSizeMasterData(yousetsuToritsukiSizeMasterData);
      // 溶接線長
      const yousetsuSenChouMasterData = result
        .filter((i) => i.processType === '溶接仕上')[0]
        .processDetail[0].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuSenChouMasterData(yousetsuSenChouMasterData);

      const data = getKubunNameDDL();

      const zaishitsu = data.map((i) => {
        return i.label;
      });
      setZaishitsuKubunn(zaishitsu);
    } else {
      navigate(':iq3.device');
      const result = kouteiMasterData.filter((i) => i.type === 'iQ3');
      setKouteiMaster(result);
      deviceLst =
        localStorage.getItem('iq3_devicesMaster') != undefined
          ? JSON.parse(localStorage.getItem('iq3_devicesMaster'))
          : [];

      deviceLst = deviceLst.filter((item) => item.type === 'iQ3');
      setSelectedData(deviceLst.length > 0 ? deviceLst[deviceLst.length - 1] : []);
      setSelectedRowKeys(deviceLst.length > 0 ? deviceLst[deviceLst.length - 1].deviceNo : 0);
      setInitialTableItems(deviceLst);
      setInitialTableItemsBk(deviceLst);

      setMageKosuuMaster(mageKosuuData);
      setIQ3AreaMaster(iq3areaMasterData);
      setSeisanKosuuMaster(iq3SeisanKosuuMasterData);
      setHonsuuMaster(iq3honsuuMasterData);
      setIQ3SizeMaster(iq3SizeMasterData);
      setItaatsu1Master(yousetsuNanidoItaatsuMasterData);
      const data = getKubunNameDDL();
      const zaishitsu = data.map((i) => {
        return i.label;
      });
      setZaishitsuKubunn(zaishitsu);
      // 取付Masterデータ（板厚とサイズ）
      const yousetsuTypeMasterData = result
        .filter((i) => i.processType === '溶接')[0]
        .processDetail[0].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuTypeMasterData(yousetsuTypeMasterData);
      // 取付Masterデータ（板厚とサイズ）
      const yousetsuToritsukiItaatsuMasterData = result
        .filter((i) => i.processType === '溶接')[0]
        .processDetail[1].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuToritsukiItaatsuMasterData(yousetsuToritsukiItaatsuMasterData);
      const yousetsuToritsukiSizeMasterData = result
        .filter((i) => i.processType === '溶接')[0]
        .processDetail[2].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuToritsukiSizeMasterData(yousetsuToritsukiSizeMasterData);
      // 溶接線長
      const yousetsuSenChouMasterData = result
        .filter((i) => i.processType === '溶接仕上')[0]
        .processDetail[0].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });
      setYousetsuSenChouMasterData(yousetsuSenChouMasterData);

      setPath(':iq3.device');
    }

    setTblColumns(devicesColumns);

    setWeldLineMaster(weldLineMasterData);

    // 製品重量係数
    setAreaMaster(areaMasterData);
    setWeightMaster(weightMasterData);
    setVolumeMaster(taisekiMasterData); //体積

    setMeishouMasterData(meishouMasterData);
    setSuuryouKubunMaster(suuryouKubunMasterData);
    setYousosuuMaster(yousosuuMasterData);
    setYousetsuNanidoItaatsuMaster(yousetsuNanidoItaatsuMasterData);
    setKaritsukiJikanItaatsuMaster(karitsukiJikanItaatsuMasterData);
    setToritsukiJikanItaatsuMaster(karitsukiJikanItaatsuMasterData);

    setToritsukiJikanSizeMaster(sizeMasterData);
    setKakouSokudoItaatsuMaster(itaatsuMasterData);

    setInitialCustomerData([]);
    setInitialCustomerDataBk([]);
    // iq3
    setIQ3Itaatsu(iq3ItaatsuData);
    setGasType(gasTypeData);
    setRetrainedMold(retainedMoldData);
    setJointSize(jointSizeData);
    setLengthMaster(lengthMasterData);
    setMageSuuMaster(mageSuuData);
    setCusCurrentPage(1);
  }, [currentRoute]);

  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, koteimei, tmpFilteredData]);
  const setCurrentPage = (value) => {
    setCusCurrentPage(value);
  };
  const result = tableColumns.map((column) => {
    if (column.title === '名称') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>名称</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'companyname')}></Input>
          </div>
        ),
      };
    } else if (column.title === 'Tel') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>Tel</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'tel')}></Input>
          </div>
        ),
      };
    } else if (column.title === '担当者') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>担当者</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'tantou')}></Input>
          </div>
        ),
      };
    } else if (column.title === '都道府県') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>都道府県</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'prefecture')}></Input>
          </div>
        ),
      };
    } else if (column.title === '住所') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>住所</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'address')}></Input>
          </div>
        ),
      };
    }
    return column;
  });

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };
  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };
  const showAddNewModal = () => {
    setClickedAction(2);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setIsNew(true);
      setKakouCode('');
      setKakouHouhou([]);
      setKakouName('');
      setKouteiType('');
      setCode('');
      setSouchiCode('');
      setSouchiName('');
      setDeviceCopy([]);
      setSourceDevice('');
      setCategory('標準');
      setTsuikaKouteiType('');
      setFlagChecked(false);
      setCheckboxChecked(false);
      setSelectDisabled(true);
      setInputDisabled(true);
      setInitialAmount(0);
      setDantoriTime('00:00:00');
      setWorkTime('00:00:00');
      setSetupData('0');
      setWorkData('0');
      setKouteiName('');
    }
  };
  const showDeleteModal = () => {
    setClickedAction(3);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      if (selectedData.length != 0) {
        setIsDelete(true);
      } else {
        alert('データを選択してください');
      }
    }
  };
  const deleteModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );
  const deleteOk = () => {
    setIsDelete(false);
    let updatedData = [...initialTblItemsBk];
    updatedData = updatedData.filter((item) => item.key !== selectedData.key);

    for (let i = 0; i < updatedData.length; i++) {
      updatedData[i].deviceNo = i + 1;
    }

    // setInitialTableItems(updatedData);
    setTmpFilteredData(updatedData.length === 0 ? [] : updatedData);
    setInitialTableItemsBk(updatedData.length === 0 ? [] : updatedData);
    if (updatedData.length === 0) {
      setInitialTableItems([]);
    }
    // setSelectedRowKeys(updatedData.length > 0 ? updatedData[updatedData.length - 1].deviceNo : 0);
    // setSelectedData(updatedData.length > 0 ? updatedData[updatedData.length - 1] : []);
    setSelectedRowKeys(updatedData.length > 0 ? updatedData[0].deviceNo : 0);
    setSelectedData(updatedData.length > 0 ? updatedData[0] : []);
    if (currentRoute.includes('parent')) {
      localStorage.setItem('parent_devicesMaster', JSON.stringify(updatedData));
    } else {
      localStorage.setItem('iq3_devicesMaster', JSON.stringify(updatedData));
    }
    setCurrentPage(1);
  };
  const deleteCancel = () => {
    setIsDelete(false);
  };

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showDeleteModal: showDeleteModal,
  }));
  const addNewModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規作成</p>
    </div>
  );
  const addCustomerModalTitle = (
    <div
      style={{
        width: 900,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>取引先</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };
  const addCustomerData = () => {
    console.log('Add');
  };
  const cancelData = () => {
    setIsDiscardData(true);
  };
  const createListData = (type, masterData) => {
    const result = type.map((materialType, key) => {
      const filteredData2 = masterData
        .filter((subitem) => subitem.displayFlag === true)
        .map((item) => {
          return {
            key: item.id,
            value: '',
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
        isUsed: false,
      };
    });
    return result;
  };
  const createKakouSokudoListData = (type, masterData) => {
    const list = [
      { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
      { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
      { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
    ];
    const result = type.map((materialType, key) => {
      const filteredData2 = masterData
        .filter((subitem) => subitem.displayFlag === true)
        .map((item) => {
          return {
            key: item.id,
            value: '',
            list: list,
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
      };
    });
    return result;
  };
  const createDetailsListData = (type, masterData, kubunTypes, itaatsuMasterData) => {
    const data = createListData(kubunTypes, itaatsuMasterData);

    const result = type.map((materialType, key) => {
      const filteredData2 = masterData
        .filter((subitem) => subitem.displayFlag === true)
        .map((item) => {
          return {
            key: item.id,
            value: '',
            list: data,
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
        isUsed: false,
      };
    });
    return result;
  };
  const createList = (masterData) => {
    const filteredData2 = masterData
      .filter((subitem) => subitem.displayFlag === true)
      .map((item) => {
        return {
          key: item.id,
          val: '',
        };
      });

    return filteredData2;
  };
  const createKosuuList = (masterData) => {
    let temp = masterData.filter((i) => i.displayFlag === true);
    const lastObject = temp[temp.length - 1];
    let valOfLastObject = lastObject.val + 1;
    let addData = { id: temp.length + 1, val: valOfLastObject, displayFlag: true };

    const data = [...masterData, addData];

    const filteredData2 = data
      .filter((subitem) => subitem.displayFlag === true)
      .map((item) => {
        return {
          key: item.id,
          val: '',
        };
      });

    return filteredData2;
  };
  const createSizeListData = (weight, area) => {
    const result = weight.map((weightItem) => {
      const lstData = area
        .filter((subitem) => subitem.displayFlag === true)
        .map((item) => {
          return {
            key: item.id,
            value: '',
          };
        });

      return {
        key: weightItem.id,
        max: weightItem.max,
        displayFlag: weightItem.displayFlag,
        lstData: lstData,
      };
    });
    return result;
  };
  const updateDataOk = () => {
    // setInitialTableItems(initialTblItemsBk); //filtertesting
    if (isNew == true) {
      setSelectedRowKeys(initialTblItemsBk.length + 1);
      setIsNew(false);
      if (sourceDevice) {
        const copyRow = initialTblItemsBk.filter((item) => item.deviceName === sourceDevice)[0];

        const data = {
          key: initialTblItemsBk.length + 1,
          type: copyRow.type,
          deviceNo: initialTblItemsBk.length + 1,
          deviceTypes: category, //種別
          code: code, // コード
          processName: kouteiName, //工程名
          processType: copyRow.processType, // 工程タイプ
          deviceCode: souchiCode, // 装置コード
          deviceName: souchiName, // 装置名
          setupCharge: category === '標準' ? '-' : setupData, // 段取チャージ
          processCharge: category === '標準' ? '-' : workData, // 加工チャージ
          multiCharge: category === '標準' ? '有' : '', //チャージ金額複数有
          remarks: copyRow.remarks, // 備考
          processDetails: copyRow.processDetails, // チャージ金額

          kihonDantoriTime: copyRow.kihonDantoriTime,

          processTimeByUnit: copyRow.processTimeByUnit,

          meishouDetails: copyRow.meishouDetails,
          jikanChajji: copyRow.jikanChajji,

          kouteiType: tsuikaKouteiType,

          yousetsuSenchouMasterData: copyRow.yousetsuSenchouMasterData,
          karitsukiJikanItaatsuMasterData: copyRow.karitsukiJikanItaatsuMasterData,
          toritsukiJikanItaatsuMasterData: copyRow.karitsukiJikanItaatsuMasterData,

          yousetsuNanidoItaatsuMasterData: copyRow.yousetsuNanidoItaatsuMasterData,

          areaMasterData: copyRow.areaMasterData,
          weightMasterData: copyRow.weightMasterData,
          volumeMasterData: copyRow.volumeMasterData,
          kosuuMasterData: copyRow.kosuuMasterData,

          yousetsuShiageSenchouMasterData: copyRow.yousetsuShiageSenchouMasterData,
          yousetsuShiageSenchouLstData: copyRow.yousetsuShiageSenchouLstData,
          yousetsuSenchouLstData: copyRow.yousetsuSenchouLstData,
          karitsukiJikanItaatsuLstData: copyRow.karitsukiJikanItaatsuLstData,
          toritsukiJikanSizeMasterData: copyRow.toritsukiJikanSizeMasterData,
          toritsukiJikanSizeLstData: copyRow.toritsukiJikanSizeLstData,
          toritsukiJikanItaatsuLstData: copyRow.toritsukiJikanItaatsuLstData,
          yousetsuNanidoItaatsuLstData: copyRow.yousetsuNanidoItaatsuLstData,
          tapWeldingItaatsuLstData: copyRow.tapWeldingItaatsuLstData,
          pitchItaatsuLstData: copyRow.pitchItaatsuLstData,
          sizeListData: copyRow.sizeListData,
          kosuuLstData: copyRow.kosuuLstData,
          dantoriSizeListData: copyRow.dantoriSizeListData,
          kobuhinKeisuuMasterData: copyRow.kobuhinKeisuuMasterData,
          dantoriSetteiSuuryouLstData: copyRow.dantoriSetteiSuuryouLstData,

          kobuhinKeisuuLstData: copyRow.kobuhinKeisuuLstData,

          customerName: customerName,
          customerCode: customerCode,
          inputFlag: flagChecked,
          itakuFlag: checkboxChecked,
          setupTime: dantoriTime,
          workTime: workTime,
          initialAmount: initialAmount,

          yousosuuMasterData: copyRow.yousosuuMasterData,
          sagyouJikanKeisuuLstData: copyRow.sagyouJikanKeisuuLstData,
          shokiDankaiJikanLstData: copyRow.shokiDankaiJikanLstData,

          // iQ3
          dantoriFlag: copyRow.dantoriFlag,
          iq3SetupAmount: copyRow.iq3SetupAmount,
          iq3WorkAmount: copyRow.iq3WorkAmount,
          iq3ProcessInspection: copyRow.iq3ProcessInspection,
          secondProcessAmount: copyRow.secondProcessAmount,
          jointBarashiAmount: copyRow.jointBarashiAmount,
          kakouType: copyRow.kakouType,
          kakouMethods: copyRow.kakouMethods,
          iq3ItaatsuMasterData: copyRow.iq3ItaatsuMasterData,
          iq3BendingTimeLstData: copyRow.iq3BendingTimeLstData,
          // 曲げ時間係数
          lengthMasterData: copyRow.lengthMasterData,
          bendingTimeFactorLstData: copyRow.bendingTimeFactorLstData, //段取設定のサイズリスト
          iq3BendingkosuuLstData: copyRow.iq3BendingkosuuLstData,
          // 曲げ数
          mageSuuMasterData: copyRow.mageSuuMasterData,
          mageSuuLstData: copyRow.mageSuuLstData,
          // 曲げ個数
          mageKosuuMasterData: copyRow.mageKosuuMasterData,
          mageKosuuLstData: copyRow.mageKosuuLstData,
          dantoriSizeLstData: copyRow.dantoriSizeLstData,
          // 曲長さ別段取時間
          iq3MagechouDantoriJikanListData: copyRow.iq3MagechouDantoriJikanListData,
          // iq3面積
          iq3AreaMasterData: copyRow.iq3AreaMasterData,
          iq3KakouJikanListData: copyRow.iq3KakouJikanListData,
          blankKakousokudoListData: copyRow.blankKakousokudoListData,
          // 生産個数
          seisanKosuuMasterData: copyRow.seisanKosuuMasterData,
          seisanKosuuLstData: copyRow.seisanKosuuLstData,
          processInspectionKosuuLstData: copyRow.processInspectionKosuuLstData,
          honsuuMasterData: copyRow.honsuuMasterData,
          honsuuLstData: copyRow.honsuuLstData,
          // 二次加工の加工時間
          iq3SizeMasterData: copyRow.iq3SizeMasterData,
          iq3KaninyuryokuListData: copyRow.iq3KaninyuryokuListData,
          itaatsu1MasterData: copyRow.itaatsu1MasterData,
          zaishitsuKubunn: copyRow.zaishitsuKubunn,
          // iq3製品重量係数
          iq3ProductSizeLstData: copyRow.iq3ProductSizeLstData,
          correctionTimeFactorLstData: copyRow.correctionTimeFactorLstData,
          // マテリアルハンドリング時間
          materialHandlingTime: copyRow.materialHandlingTime,
          // 同一サイズ、同一形状をパターン化する
          programSameSizePatternValue: copyRow.programSameSizePatternValue,
          gasTypeLstData: copyRow.gasTypeLstData,
          processInspectionTime: copyRow.processInspectionTime,

          punchConditionList: copyRow.punchConditionList,
          drillingTime: copyRow.drillingTime,
          forwardSpeed: copyRow.forwardSpeed,
          rollerSpeed: copyRow.rollerSpeed,
          piercingProcessItaatsuLstData: copyRow.piercingProcessItaatsuLstData,
          punchProcessItaatsuLstData: copyRow.punchProcessItaatsuLstData,
          okuriSokudoVal: copyRow.okuriSokudoVal,
          kegakiSokudoVal: copyRow.kegakiSokudoVal,
          blankKakousokudoItaatsuList: copyRow.blankKakousokudoItaatsuList,
          gasTypeMasterData: copyRow.gasTypeMasterData,
          gasKeissuuLstData: copyRow.gasKeissuuLstData,
          extractConditionList: copyRow.extractConditionList,
          jointSizeListData: copyRow.jointSizeListData,
          jointSizeMasterData: copyRow.jointSizeMasterData,
          jointItaatsuLstData: copyRow.jointItaatsuLstData,
          bendingCorrectionTimeFactorLstData: copyRow.bendingCorrectionTimeFactorLstData,
          retrainedMoldMasterData: copyRow.retrainedMoldMasterData,
          kakosokudo_bari: copyRow.kakosokudo_bari,
          naikei_bari: copyRow.naikei_bari,
          naikei_min: copyRow.naikei_min,
          naikei_max: copyRow.naikei_max,
          ana_min: copyRow.ana_min,
          ana_max: copyRow.ana_max,
          shirringflag: copyRow.shirringflag,
          shirringTime: copyRow.shirringTime,
        };

        // setInitialTableItems([...initialTblItems, data]);
        setInitialTableItemsBk([...initialTblItemsBk, data]);
        setTmpFilteredData([...initialTblItemsBk, data]);
        setSelectedData(data);
        setCusCurrentPage(Math.ceil(data.deviceNo / 25));
        if (currentRoute.includes('parent')) {
          localStorage.setItem('parent_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        } else {
          localStorage.setItem('iq3_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        }
      } else {
        const kouteiData = kouteiMaster.filter((i) => i.processType === kouteiType);

        let hyoumenshoriDetails = [];
        hyoumenshoriDetails = kouteiData[0].processDetail;

        if (category === '標準') {
          const getData = kouteiMaster.filter((i) => i.processType === kouteiType);
          let result = [];
          if (getData[0].processDetail.length > 0) {
            result = getData[0].processDetail[0].processDetailValue.map((i) => {
              if (i.displayFlg === true) {
                return i.detailType;
              }
            });
          }
          setSecondProcessTypes(result);

          const data = {
            key: initialTblItemsBk.length + 1,
            type: location.pathname.includes('parent') ? '親部品' : 'iQ3',
            deviceNo: initialTblItemsBk.length + 1,
            deviceTypes: category,
            code: code,
            processName: kouteiName,
            processType: kouteiType,
            deviceCode: souchiCode,
            deviceName: souchiName,
            setupCharge: '-',
            processCharge: '-',
            multiCharge: '有',
            remarks: remarks,
            processDetails: hyoumenshoriDetails.map((detail) => ({
              ...detail,
              processDetailValue: detail.processDetailValue.map(({ key, detailType, displayFlg }) => ({
                key,
                detailType,
                displayFlg,
                dantori: defaultCharge,
                kakou: defaultCharge,
                dantoriTime: '00:00:00',
                dantoriSurface: defaultCharge,
                dantoriFlag: false,
                kakouFlag: false,
              })),
            })),

            meishouDetails: meishouMaster,
            kihonDantoriTime: '00:00:00',
            processTimeByUnit: '00:00:00',
            dantoriFlag: false,
            jikanChajji: jikanchaji,
            kobuhinSuu: kobuhinData,
            pitchItaatsuLstData: createListData(zaishitsuKubunn, yousetsuNanidoItaatsuMaster), //点付、仮付ピッチのリストデータ

            tapWeldingItaatsuLstData: createListData(zaishitsuKubunn, yousetsuNanidoItaatsuMaster), //点付、仮付ピッチのリストデータ

            yousetsuNanidoItaatsuMasterData: yousetsuNanidoItaatsuMaster,
            yousetsuNanidoItaatsuLstData: createListData(zaishitsuKubunn, yousetsuNanidoItaatsuMaster),

            karitsukiJikanItaatsuMasterData: karitsukiJikanItaatsuMaster,
            karitsukiJikanItaatsuLstData: createListData(yousetsuTypeMasterData, karitsukiJikanItaatsuMaster),

            toritsukiJikanItaatsuMasterData: karitsukiJikanItaatsuMaster,
            toritsukiJikanItaatsuLstData: createListData(
              yousetsuToritsukiItaatsuMasterData,
              karitsukiJikanItaatsuMaster
            ),

            toritsukiJikanSizeMasterData: toritsukiJikanSizeMaster,
            toritsukiJikanSizeLstData: createListData(yousetsuToritsukiSizeMasterData, toritsukiJikanSizeMaster),

            yousetsuSenchouMasterData: weldLineMaster,
            yousetsuSenchouLstData: createListData(yousetsuTypeMasterData, weldLineMaster),

            yousetsuShiageSenchouMasterData: weldLineMaster,
            yousetsuShiageSenchouLstData: createListData(yousetsuSenChouMasterData, weldLineMaster),

            kobuhinKeisuuMasterData: suuryouKubunMaster,
            kobuhinKeisuuLstData: createListData(keisuu, suuryouKubunMaster),
            dantoriSetteiSuuryouLstData: createListData(suuryouTypes, suuryouKubunMaster), //段取設定の数量

            // 要素数
            yousosuuMasterData: yousosuuMaster,
            sagyouJikanKeisuuLstData: createListData(['作業時間係数'], yousosuuMaster),
            shokiDankaiJikanLstData: createListData(['初期段階時間'], yousosuuMaster),
            // meishouMaster
            meishouMasterData: meishouMaster,
            meishouLstData: createList(meishouMaster),
            // 製品重量係数
            areaMasterData: areaMaster,
            weightMasterData: weightMaster,
            // sizeListData: createSizeListData(weightMaster, areaMaster),
            sizeListData: createListData(['重量係数'], weightMaster),
            volumeMasterData: volumeMaster,
            // dantoriSizeListData: createSizeListData(weightMaster, volumeMaster), //段取設定のサイズリスト
            dantoriSizeListData: createListData(['重量係数'], weightMaster),
            // 個数
            kosuuMasterData: kosuuMaster,
            kosuuLstData: createListData(['個数'], kosuuMaster),
            iq3DantoriJikanKosuuLstData: createListData(['加算係数'], kosuuMaster), // 段取時間（生産個数）

            // iq3データ
            iq3SetupAmount: defaultCharge,
            iq3WorkAmount: defaultCharge,
            iq3ProcessInspection: defaultCharge,
            secondProcessAmount: defaultCharge,
            jointBarashiAmount: defaultCharge,
            kakouType: kakouName,
            kakouMethods: kakouHouhou,
            iq3ItaatsuMasterData: iq3Itaatsu,
            gasTypeMasterData: gasType,
            retrainedMoldMasterData: retainedMold,
            jointSizeMasterData: jointSize,
            iq3BendingTimeLstData: createListData(result, iq3Itaatsu),
            // 曲げ時間係数
            lengthMasterData: lengthMaster,
            bendingTimeFactorLstData: createSizeListData(weightMaster, lengthMaster), //段取設定のサイズリスト
            bendingCorrectionTimeFactorLstData: createSizeListData(weightMaster, lengthMaster),
            correctionTimeFactorLstData: createSizeListData(weightMaster, iq3AreaMaster),
            iq3BendingkosuuLstData: createListData(['補正時間（秒）'], kosuuMaster),
            // 曲げ数
            mageSuuMasterData: mageSuuMaster,
            mageSuuLstData: createListData(result, mageSuuMaster),
            // 曲げ個数
            mageKosuuMasterData: mageKosuuMaster,
            mageKosuuLstData: createListData(['加算係数'], mageKosuuMaster),
            dantoriSizeLstData: createSizeListData(weightMaster, lengthMaster),
            // 曲長さ別段取時間
            iq3MagechouDantoriJikanListData: createListData(['曲長さ別段取時間'], lengthMaster),
            // iq3面積
            iq3AreaMasterData: iq3AreaMaster,
            iq3KakouJikanListData: createListData(['加工時間(分)'], iq3AreaMaster),
            // 生産個数
            seisanKosuuMasterData: seisanKosuuMaster,
            seisanKosuuLstData: createListData(['加算係数'], seisanKosuuMaster),
            // 工程内検査の生産個数
            processInspectionKosuuLstData: createListData(['加算係数'], seisanKosuuMaster),
            // 本数
            honsuuMasterData: honsuuMaster,
            honsuuLstData: createListData(['金型段取時間(分)'], honsuuMaster),
            // 二次加工の加工時間
            iq3SizeMasterData: iq3SizeMaster,
            iq3KaninyuryokuListData: createDetailsListData(result, iq3SizeMaster, zaishitsuKubunn, itaatsu1Master),

            itaatsu1MasterData: itaatsu1Master,
            zaishitsuKubunn: zaishitsuKubunn,
            // iq3製品重量係数
            iq3ProductSizeLstData: createSizeListData(weightMaster, iq3AreaMaster),

            // マテリアルハンドリング時間
            materialHandlingTime: createSizeListData(weightMaster, iq3AreaMaster),

            // 同一サイズ、同一形状をパターン化する
            programSameSizePatternValue: 5,
            // ガス種類
            gasTypeLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
            jointItaatsuLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
            blankKakousokudoItaatsuList: createListData(['内加工', '外加工'], iq3Itaatsu),
            piercingProcessItaatsuLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
            punchProcessItaatsuLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
            blankKakousokudoListData: createKakouSokudoListData(zaishitsuKubunn, iq3Itaatsu),
            gasKeissuuLstData: createListData(['係数'], gasType),
            // 外形追抜条件
            extractConditionList: createListData(['加工辺長(mm)'], retainedMold),
            jointSizeListData: createListData(['ジョイント個数'], jointSize),

            // 工程内検査の基本段取時間
            processInspectionTime: '00:00:00',
            // 材料配置
            pierWidth1: '',
            pierWidth2: '',
            grabAllowance1: '',
            grabAllowance2: '',
            processRange1: '',
            processRange2: '',
            pickupTime: '',
            // パンチ条件
            punchConditionList: punchData,
            // 2工程穴加工時間
            drillingTime: '00:00:00',
            forwardSpeed: '',
            rollerSpeed: '',
            okuriSokudoVal: '',
            kegakiSokudoVal: '',
            kakosokudo_bari: '',
            naikei_bari: '',
            naikei_min: '',
            naikei_max: '',
            ana_min: '',
            ana_max: '',
            shirringflag: false,
            shirringTime: '00:00:00',
          };

          // setInitialTableItems([...initialTblItems, data]);
          setInitialTableItemsBk([...initialTblItemsBk, data]);
          setTmpFilteredData([...initialTblItemsBk, data]);
          setCusCurrentPage(Math.ceil(data.deviceNo / 25));
          console.log(Math.ceil(data.deviceNo / 25));
          setSelectedData(data);
          if (currentRoute.includes('parent')) {
            localStorage.setItem('parent_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
          } else {
            localStorage.setItem('iq3_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
          }
        } else {
          const data = {
            key: initialTblItemsBk.length + 1,
            type: location.pathname.includes('parent') ? '親部品' : 'iQ3',
            deviceNo: initialTblItemsBk.length + 1,
            deviceTypes: category,
            code: code,
            processName: kouteiName,
            processType: kouteiType,
            deviceCode: souchiCode,
            deviceName: souchiName,
            setupCharge: setupData,
            processCharge: workData,
            iq3SetupAmount: setupData,
            iq3WorkAmount: workData,
            multiCharge: '',
            remarks: remarks,
            tsuikaKouteiTypes: tsuikaKouteiTypes,
            customerName: customerName,
            customerCode: customerCode,
            inputFlag: flagChecked,
            itakuFlag: checkboxChecked,
            setupTime: dantoriTime,
            workTime: workTime,
            kosuuMasterData: kosuuMaster,
            kosuuLstData: [],
            processDetails: hyoumenshoriDetails.map((detail) => ({
              ...detail,
              processDetailValue: detail.processDetailValue.map(({ key, detailType, displayFlg }) => ({
                key,
                detailType,
                displayFlg,
              })),
            })),

            kihonDantoriTime: '00:00:00',
            processTimeByUnit: '00:00:00',
            jikanChajji: jikanchaji,
            kobuhinSuu: kobuhinData,
            kouteiType: tsuikaKouteiType,

            initialAmount: initialAmount,
          };

          // setInitialTableItems([...initialTblItems, data]);
          setInitialTableItemsBk([...initialTblItemsBk, data]);
          setTmpFilteredData([...initialTblItemsBk, data]);
          setCusCurrentPage(Math.ceil(data.deviceNo / 25));
          setSelectedData(data);
          // if (currentRoute.includes('parent')) {
          //   localStorage.setItem('parent_devices', JSON.stringify(data));
          // } else if (currentRoute.includes('iq3')) {
          //   localStorage.setItem('parent_devicesMaster', JSON.stringify(data));
          // }
          if (currentRoute.includes('parent')) {
            localStorage.setItem('parent_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
          } else {
            localStorage.setItem('iq3_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
          }
        }
      }

      // navigate(':iq3.device');
      // navigate(path);
    }
    setIsUpdateData(false);
  };
  const updateDataCancel = () => {
    if (isNew == true) {
      setIsNew(true);
    }
    setIsUpdateData(false);
  };
  const discardOk = () => {
    if (isNew == true) {
      setIsNew(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isNew == true) {
      setIsNew(true);
    }
    setIsDiscardData(false);
  };
  const closeAddModal = () => {
    setIsNew(false);
  };
  const closeCustomerModal = () => {
    setCustomerModal(false);
  };
  // masterデータにより、リスト更新
  const updateList = (masterData, updatedMasterData, listData) => {
    // 削除したid取得
    const deletedId = [];
    masterData.forEach((item1, index) => {
      const found = updatedMasterData.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);
    // リスト更新
    const deleteLstData = listData.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deleteLstData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    return updatedData;
  };
  const updateList2 = (masterData, updatedMasterData, listData) => {
    // 削除したid取得
    const deletedId = [];
    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);
    // リスト更新
    const deleteLstData = listData?.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deleteLstData?.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = updatedMasterData.findIndex((obj) => obj.id === a.key);
        const bIndex = updatedMasterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    return sortedList;
  };

  const updateList3 = (masterData, updatedMasterData, listData) => {
    const list = [
      { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
      { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
      { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
    ];
    // 削除したid取得
    const deletedId = [];
    masterData.forEach((item1, index) => {
      const found = updatedMasterData.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);
    // リスト更新
    const deleteLstData = listData.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deleteLstData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: list,
        });
      });

      return item;
    });
    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = updatedMasterData.findIndex((obj) => obj.id === a.key);
        const bIndex = updatedMasterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    return sortedList;
  };
  const deleteListData = (list, deletedId) => {
    const deletedData = list.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    return deletedData;
  };
  const updateListData = (deletedData, newId, detailsData) => {
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: detailsData,
        });
      });

      return item;
    });
    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    return sortedList;
  };
  const deleteListData2 = (data, deletedId) => {
    const updatedData = [...data];

    updatedData.forEach((item) => {
      item.lstData.forEach((subitem) => {
        subitem.list.forEach((listItem) => {
          if (listItem.lstData) {
            listItem.lstData = listItem.lstData.filter((lstItem) => !deletedId.includes(lstItem.key));
          }
        });
      });
    });

    return updatedData;
  };
  const updateListData2 = (deletedData, newId) => {
    const updatedData = JSON.parse(JSON.stringify(deletedData)); // Create a deep copy of the data array

    updatedData.forEach((item) => {
      item.lstData.forEach((i) => {
        i.list.forEach((j) => {
          if (j.lstData) {
            newId.forEach((key) => {
              // Add new objects to lstData
              j.lstData.push({
                key: key,
                value: '',
              });
            });
          }
        });
      });
    });

    return updatedData;
  };
  const sortListData2 = (list, masterData) => {
    const sortedList = JSON.parse(JSON.stringify(list));

    sortedList.forEach((item) => {
      item.lstData.forEach((i) => {
        i.list.forEach((j) => {
          if (j.lstData) {
            j.lstData.sort((a, b) => {
              const aIndex = masterData.findIndex((obj) => obj.id === a.key);
              const bIndex = masterData.findIndex((obj) => obj.id === b.key);
              return aIndex - bIndex;
            });
          }
        });
      });
    });

    return sortedList;
  };
  // ２次加工
  const updateItaatsuInsideKaniList = (itaatsu, itaatsuTemp, list) => {
    const deletedId = [];

    itaatsu.forEach((item1, index) => {
      const found = itaatsuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = itaatsuTemp
      .filter((item) => item.displayFlag && !itaatsu.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData2(list, deletedId);

    const updatedData = updateListData2(deletedData, newId);

    const sortedList = sortListData2(updatedData, itaatsuTemp);
    return sortedList;
  };
  const updateKaniSizeList = (
    zaishitsu,
    masterData,
    updatedMasterData,
    itaatsuMasterData,
    updItaatsuMasterData,
    listData
  ) => {
    const deletedId = [];

    masterData.forEach((item1, index) => {
      const found = updatedMasterData.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData(listData, deletedId);

    let detailsData = createListData(zaishitsu, itaatsuMasterData);
    const updatedData = updateListData(deletedData, newId, detailsData);

    const sortedList = sortListData(updatedData, updatedMasterData);
    const updatedListData = updateItaatsuInsideKaniList(itaatsuMasterData, updItaatsuMasterData, sortedList);
    return updatedListData;
  };
  // const updateKaniList = (sizeMasterData, updSizeMasterData, itaatsuMasterData, updItaatsuMasterData, listData) => {
  //   const sizeupdated = updateList2(sizeMasterData, updSizeMasterData, listData);
  //   return sizeupdated;
  // };
  const updateProductSizeList = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData.forEach((item1, index) => {
      const found = updatedMasterData.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = listData.filter((it) => !deletedId.includes(it.key));

    const result = deleteData.map((item) => {
      const list = updatedMasterData.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId.forEach((id) => {
      const list = updatedMasterData.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result.push(newObj);
      }
    });
    return result;
  };
  // iq3製品重量係数
  const updateProductSizeList2 = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = listData?.filter((it) => !deletedId.includes(it.key));

    const result = deleteData?.map((item) => {
      const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId?.forEach((id) => {
      const list = updatedMasterData?.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result?.push(newObj);
      }
    });
    result?.sort((a, b) => {
      const aIndex = updatedMasterData.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    return result;
  };
  const updateMeishouList = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const updatedData = listData?.filter((it) => !deletedId.includes(it.key));

    const addNewData = newId?.map((item) => ({
      key: item,
      value: '',
    }));

    updatedData?.push(...addNewData);
    return updatedData;
  };
  const updateKosuuList = (masterData, updatedMasterData, listData) => {
    const lastArrObj = updatedMasterData[updatedMasterData.length - 1];
    const lastArrObjId = lastArrObj.id;
    const lastArrObjList = listData[listData.length - 1];
    const lastArrObjVal = lastArrObjList.val;
    // change last key value
    const updatedList = [
      ...listData.slice(0, listData.length - 1),
      { ...listData[listData.length - 1], key: lastArrObjId + 1, val: lastArrObjVal },
    ];

    const deletedId = [];

    masterData.forEach((item1, index) => {
      const found = updatedMasterData.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      .filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const updatedData = updatedList.filter((it) => !deletedId.includes(it.key));

    const addNewData = newId.map((item) => ({
      key: item,
      val: '',
    }));

    updatedData.push(...addNewData);
    const sortedData = updatedData.sort((a, b) => a.key - b.key);
    return sortedData;
  };
  const onRowSelect = (record) => {
    const getData = kouteiMaster.filter((i) => i.processType === record.processType);
    let result = [];
    if (getData[0].processDetail.length > 0) {
      result = getData[0].processDetail[0].processDetailValue.map((i) => {
        if (i.displayFlg === true) {
          return i.detailType;
        }
      });
    }

    setCurrentRecordKey(record.deviceNo);
    setCurrentRecord(record);
    setClickedAction(1);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      if (record.deviceTypes === '追加') {
        setSelectedRowKeys(record.deviceNo);
        setSelectedData(record);
        // navigate(':parent.device');
        // navigate(path);
      } else {
        // update 係数区分 master data

        record['meishouDetails'] = meishouMaster;
        // record['suuryouDetails'] = suuryouKubunMaster;

        // 数量区分　子部品係数
        const suuryouKubunUpdList = updateList2(
          record.kobuhinKeisuuMasterData,
          suuryouKubunMaster,
          record.kobuhinKeisuuLstData
        );

        // 段取設定の数量
        const danntorisetteisuuryouKubunUpdList = updateList2(
          record.kobuhinKeisuuMasterData,
          suuryouKubunMaster,
          record.dantoriSetteiSuuryouLstData
        );

        record['dantoriSetteiSuuryouLstData'] = danntorisetteisuuryouKubunUpdList;
        record['kobuhinKeisuuMasterData'] = suuryouKubunMaster;
        record['kobuhinKeisuuLstData'] = suuryouKubunUpdList;

        if (record.processName === '組立' || record.processName === '検査' || record.processName === '梱包') {
          const meishouUpdList = updateMeishouList(record.meishouMasterData, meishouMaster, record.meishouLstData);
          record['meishouMasterData'] = meishouMaster;
          record['meishouLstData'] = meishouUpdList;
        }
        if (record.processName === '溶接') {
          // 溶接線長
          const yousetsuSenchouUpdList = updateList2(
            record.yousetsuSenchouMasterData,
            weldLineMaster,
            record.yousetsuSenchouLstData
          );
          record['yousetsuSenchouMasterData'] = weldLineMaster;
          record['yousetsuSenchouLstData'] = yousetsuSenchouUpdList;
        }

        if (record.processName === '溶接仕上') {
          // 溶接仕上線長
          const yousetsuShiageSenchouLstData = updateList2(
            record.yousetsuSenchouMasterData,
            weldLineMaster,
            record.yousetsuShiageSenchouLstData
          );
          record['yousetsuSenchouMasterData'] = weldLineMaster;
          record['yousetsuShiageSenchouLstData'] = yousetsuShiageSenchouLstData;
        }

        // Masterdataによって、リストデータ再更新
        const pitchUpdList = updateList2(
          record.yousetsuNanidoItaatsuMasterData,
          yousetsuNanidoItaatsuMaster,
          record.pitchItaatsuLstData
        );
        const tapWeldingUpdList = updateList2(
          record.yousetsuNanidoItaatsuMasterData,
          yousetsuNanidoItaatsuMaster,
          record.tapWeldingItaatsuLstData
        );

        const yousetsuNanidoUpdList = updateList2(
          record.yousetsuNanidoItaatsuMasterData,
          yousetsuNanidoItaatsuMaster,
          record.yousetsuNanidoItaatsuLstData
        );

        const karitsukiJikanUpdList = updateList2(
          record.karitsukiJikanItaatsuMasterData,
          karitsukiJikanItaatsuMaster,
          record.karitsukiJikanItaatsuLstData
        );

        const toritsukiJikanItaatsuUpdList = updateList2(
          record.karitsukiJikanItaatsuMasterData,
          karitsukiJikanItaatsuMaster,
          record.toritsukiJikanItaatsuLstData
        );
        const toritsukiJikanSizeUpdList = updateList2(
          record.toritsukiJikanSizeMasterData,
          toritsukiJikanSizeMaster,
          record.toritsukiJikanSizeLstData
        );

        // 加工速度
        const yousetsuShiageSenchouUpdList = updateList2(
          record.karitsukiJikanItaatsuMasterData,
          karitsukiJikanItaatsuMaster,
          record.yousetsuShiageSenchouLstData
        );

        // 個数
        const kosuuUpdList = updateList2(record.kosuuMasterData, kosuuMaster, record.kosuuLstData);

        // 製品重量係数
        // const addColList = updateList2(record.areaMasterData, areaMaster, record.sizeListData);
        // const productSizeUpdList = updateProductSizeList(record.weightMasterData, weightMaster, addColList);
        const productSizeUpdList = updateList2(record.weightMasterData, weightMaster, record.sizeListData);
        // 段取サイズ
        // const dantoriTaisekiUpdList = updateList2(record.volumeMasterData, volumeMaster, record.dantoriSizeListData);
        // const dantoriSizeUpdList = updateProductSizeList(record.weightMasterData, weightMaster, dantoriTaisekiUpdList);
        const dantoriSizeUpdList = updateList2(record.weightMasterData, weightMaster, record.dantoriSizeListData);
        // iq3製品重量係数
        // 個数
        const seihinkosuuUpdList = updateList2(record.kosuuMasterData, kosuuMaster, record.iq3BendingkosuuLstData);

        // iq3生産個数
        const seisanKosuUpdList = updateList2(
          record.seisanKosuuMasterData,
          seisanKosuuMaster,
          record.seisanKosuuLstData
        );
        // 工程内検査の生産個数

        const processInspectionKosuUpdList = updateList2(
          record.seisanKosuuMasterData,
          seisanKosuuMaster,
          record.processInspectionKosuuLstData
        );
        // 本数
        const honnsuuUpdList = updateList2(record.honsuuMasterData, honsuuMaster, record.honsuuLstData);
        // ２次加工
        // iq3KaninyuryokuListData: createDetailsListData(result, iq3SizeMaster, zaishitsuKubunn, itaatsu1Master),
        const iq3KaninyuryokuUpdList = updateKaniSizeList(
          zaishitsuKubunn,
          record.iq3SizeMasterData,
          iq3SizeMaster,
          record.itaatsu1MasterData,
          itaatsu1Master,
          record.iq3KaninyuryokuListData
        );
        // iq3製品重量係数
        const iq3ProductSizeAreaUpdList = updateList2(
          record.iq3AreaMasterData,
          iq3AreaMaster,
          record.iq3ProductSizeLstData
        );
        const iq3ProductSizeUpdList = updateProductSizeList2(
          record.weightMasterData,
          weightMaster,
          iq3ProductSizeAreaUpdList
        );
        // 補正時間係数
        const correctionTimeFactorLst = updateList2(
          record.iq3AreaMasterData,
          iq3AreaMaster,
          record.correctionTimeFactorLstData
        );
        const correctionTimeFactorUpdList = updateProductSizeList2(
          record.weightMasterData,
          weightMaster,
          correctionTimeFactorLst
        );

        // 曲げ補正時間係数
        const bendingTimeFactorUpdlengthList = updateList2(
          record.lengthMasterData,
          lengthMaster,
          record.bendingTimeFactorLstData
        );
        const bendingTimeFactorUpdList = updateProductSizeList2(
          record.weightMasterData,
          weightMaster,
          bendingTimeFactorUpdlengthList
        );
        // 曲げ時間係数
        const bendTimeFactorUpdlengthList = updateList2(
          record.lengthMasterData,
          lengthMaster,
          record.bendingCorrectionTimeFactorLstData
        );
        const bendTimeFactorUpdList = updateProductSizeList2(
          record.weightMasterData,
          weightMaster,
          bendTimeFactorUpdlengthList
        );
        // マテリアルハンドリング時間
        const materialHandlingLstData = updateList2(
          record.iq3AreaMasterData,
          iq3AreaMaster,
          record.materialHandlingTime
        );
        const materialHandlingUpdLst = updateProductSizeList2(
          record.weightMasterData,
          weightMaster,
          materialHandlingLstData
        );
        // ガス種類
        const gasTypeUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.gasTypeLstData);
        // ブランク加工速度
        const blankKakousokudoUpdList = updateList3(
          record.iq3ItaatsuMasterData,
          iq3Itaatsu,
          record.blankKakousokudoListData
        );
        // ジョイント
        const jointItaatsuUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.jointItaatsuLstData);
        // 加工速度係数
        const kakouSokudoItaatsuUpdList = updateList2(
          record.iq3ItaatsuMasterData,
          iq3Itaatsu,
          record.blankKakousokudoItaatsuList
        );
        // 1ピアス加工時間
        const piercingProcessItaatsuUpdList = updateList2(
          record.iq3ItaatsuMasterData,
          iq3Itaatsu,
          record.piercingProcessItaatsuLstData
        );

        // 1パンチ加工時間
        const punchProcessItaatsuUpdList = updateList2(
          record.iq3ItaatsuMasterData,
          iq3Itaatsu,
          record.punchProcessItaatsuLstData
        );
        // ガス係数
        const gasKeisuuUpdList = updateList2(record.gasTypeMasterData, gasType, record.gasKeissuuLstData);
        // ジョイントサイズ
        const jointSizeUpdList = updateList2(record.jointSizeMasterData, jointSize, record.jointSizeListData);

        const extractConditionUpdList = updateList2(
          record.retrainedMoldMasterData,
          retainedMold,
          record.extractConditionList
        );

        record['processInspectionKosuuLstData'] = processInspectionKosuUpdList;
        record['blankKakousokudoListData'] = blankKakousokudoUpdList;
        record['jointSizeListData'] = jointSizeUpdList;
        record['gasKeissuuLstData'] = gasKeisuuUpdList;
        record['extractConditionList'] = extractConditionUpdList;
        record['piercingProcessItaatsuLstData'] = piercingProcessItaatsuUpdList;
        record['punchProcessItaatsuLstData'] = punchProcessItaatsuUpdList;
        record['jointItaatsuLstData'] = jointItaatsuUpdList;
        record['gasTypeLstData'] = gasTypeUpdList;
        record['blankKakousokudoItaatsuList'] = kakouSokudoItaatsuUpdList;
        record['correctionTimeFactorLstData'] = correctionTimeFactorUpdList;
        record['bendingTimeFactorLstData'] = bendingTimeFactorUpdList;
        record['bendingCorrectionTimeFactorLstData'] = bendTimeFactorUpdList;
        record['iq3ProductSizeLstData'] = iq3ProductSizeUpdList;
        record['seisanKosuuMasterData'] = seisanKosuuMaster;
        record['honsuuMasterData'] = honsuuMaster;
        record['iq3SizeMasterData'] = iq3SizeMaster;
        record['itaatsu1MasterData'] = itaatsu1Master;
        record['iq3KaninyuryokuListData'] = iq3KaninyuryokuUpdList;
        record['pitchItaatsuLstData'] = pitchUpdList;

        record['tapWeldingItaatsuLstData'] = tapWeldingUpdList;

        record['yousetsuNanidoItaatsuMasterData'] = yousetsuNanidoItaatsuMaster;
        record['yousetsuNanidoItaatsuLstData'] = yousetsuNanidoUpdList;

        record['karitsukiJikanItaatsuMasterData'] = karitsukiJikanItaatsuMaster;
        record['karitsukiJikanItaatsuLstData'] = karitsukiJikanUpdList;

        record['toritsukiJikanItaatsuMasterData'] = karitsukiJikanItaatsuMaster;
        record['toritsukiJikanItaatsuLstData'] = toritsukiJikanItaatsuUpdList;

        record['toritsukiJikanSizeMasterData'] = toritsukiJikanSizeMaster;
        record['toritsukiJikanSizeLstData'] = toritsukiJikanSizeUpdList;

        record['yousetsuShiageSenchouMasterData'] = kakouSokudoItaatsuMaster;
        record['yousetsuShiageSenchouLstData'] = yousetsuShiageSenchouUpdList;

        record['areaMasterData'] = areaMaster;
        record['weightMasterData'] = weightMaster;
        record['lengthMasterData'] = lengthMaster;
        record['volumeMasterData'] = volumeMaster;
        record['sizeListData'] = productSizeUpdList;
        record['dantoriSizeListData'] = dantoriSizeUpdList;

        record['kosuuMasterData'] = kosuuMaster;
        record['kosuuLstData'] = kosuuUpdList;

        record['iq3BendingkosuuLstData'] = seihinkosuuUpdList;
        record['seisanKosuuLstData'] = seisanKosuUpdList;
        record['honsuuLstData'] = honnsuuUpdList;
        record['iq3AreaMasterData'] = iq3AreaMaster;
        record['materialHandlingTime'] = materialHandlingUpdLst;
        record['iq3ItaatsuMasterData'] = iq3Itaatsu;
        record['jointSizeMasterData'] = jointSize;

        setSelectedRowKeys(record.deviceNo);
        setSelectedData(record);
        // navigate(':parent.device');
        // navigate(path);
      }
    }
  };
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    if (clickedAction == 1) {
      // selected row change
      currentRecord['meishouDetails'] = meishouMaster;
      setSelectedRowKeys(currentRecordKey);
      setSelectedData(currentRecord);
      // navigate(':iq3.device');
      // navigate(path);
    } else if (clickedAction === 2) {
      setIsNew(true);
      setKouteiType('');
      setCode('');
      setSouchiCode('');
      setSouchiName('');
      setDeviceCopy([]);
      setSourceDevice('');
      setCategory('標準');
      setTsuikaKouteiType('');
      setFlagChecked(false);
      setCheckboxChecked(false);
      setSelectDisabled(true);
      setInputDisabled(true);
      setDantoriTime('00:00:00');
      setWorkTime('00:00:00');
      setSetupData('0');
      setWorkData('0');
    } else if (clickedAction === 3) {
      if (selectedData.length != 0) {
        setIsDelete(true);
      } else {
        alert('データを選択してください');
      }
    }
  };
  const discardConfirmCancel = () => {
    setEditMode(true);
    setEditModeCancelConfirm(false);
  };
  const onCustomerRowSelect = (record) => {
    setSelectedCustomerRowKeys(record.key);
    setSelectedCustomerData(record);
  };
  const handleSelectVal = (e) => {
    setProcess(e);
    if (e != 0) {
      let deviceData = initialTblItemsBk;
      const deviceFilter = deviceData.filter((item) => {
        return item.processName === e && item.deviceName.includes(device);
      });

      setInitialTableItems(deviceFilter);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };
  const handleInputVal = (e) => {
    setDevice(e.target.value);
  };
  const searchByDeviceName = (e) => {
    let deviceData = initialTblItemsBk;

    const deviceFilter = deviceData.filter((item) => {
      return item.deviceName.includes(e.target.value);
    });

    setInitialTableItems(deviceFilter);
  };
  const filterCustomer = (e, name) => {
    let customerData = initialCustomerDataBk;

    if (name === 'companyname') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.companyName.includes(e.target.value);
        });

        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'tantou') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.tantou.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'tel') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.tel.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'prefecture') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.prefectures.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'address') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.address.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    }
  };
  const getCustomerData = (selectedCustomerData) => {
    setCustomerCode(selectedCustomerData.code);
    setCustomerName(selectedCustomerData.name);
    setCustomerModal(false);
  };
  const changeKouteiType = (e) => {
    setKakouName('');
    setKakouHouhou([]);
    setKakouCode('');
    setTsuikaKouteiType('');
    const koutei = kouteiMaster.filter((i) => i.code === e);

    if (koutei[0].processType === 'カスタム') {
      const data = koutei
        .filter((item) => item.code === e)[0]
        .processDetail[0].processDetailValue.map((i) => {
          if (i.displayFlg === true) {
            return i.detailType;
          }
        });

      setTsuikaKouteiTypes(data);
    }
    if (koutei[0].processType === 'バリ取' || koutei[0].processType === 'ブランク') {
      const data = koutei[0].processDetail[0].processDetailValue.map((i) => {
        if (i.displayFlg === true) {
          return i;
        }
      });
      setKakouHouhou(data);
    }

    setDeviceCopy([]);
    setSourceDevice('');
    setCustomerCode('');
    setCustomerName('');
    setDantoriTime('00:00:00');
    setWorkTime('00:00:00');
    setSetupData('0');
    setWorkData('0');
    setCode(e);
    const option = kouteiMaster.find((option) => option.code === e);
    // if (initialTblItems.length > 0) {
    const deviceFilter = initialTblItemsBk.filter((item) => {
      return item.code === e;
    });
    setDeviceCopy(deviceFilter);
    // }

    setKouteiName(option.processName);
    setKouteiType(option.processType);
    setCategory(option.category);
    if (option.category === '標準') {
      setSelectDisabled(true);
      setCheckboxChecked(false);
    }
  };
  const changeKakouhouhou = (e) => {
    const option = kakouHouhou.find((option) => option.detailCode === e);
    setKakouName(option.detailType);
    setKakouCode(e);
  };
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCheckboxChecked(isChecked);
    setSelectDisabled(!isChecked);
  };
  const flagChange = (e) => {
    const isChecked = e.target.checked;
    setFlagChecked(isChecked);
    setInputDisabled(!isChecked);
  };
  const searchCustomer = () => {
    setCustomerModal(true);
    setCustomerCol(result);
  };

  const changeSourceDevice = (e) => {
    if (category === '追加' && e) {
      const copy = initialTblItemsBk.filter((item) => item.deviceName === e)[0];

      setInitialAmount(copy.initialAmount);
      setCustomerName(copy.customerName);
      setCustomerCode(copy.customerCode);
      setFlagChecked(copy.inputFlag);
      setInputDisabled(!copy.inputFlag);
      setCheckboxChecked(copy.itakuFlag);
      setSelectDisabled(!copy.itakuFlag);
      setRemarks(copy.remarks);
      setTsuikaKouteiType(copy.kouteiType);
      setDantoriTime(copy.setupTime);
      setWorkTime(copy.workTime);
      setSetupData(copy.setupCharge);
      setWorkData(copy.processCharge);
    }
    setSourceDevice(e);
  };
  const changeAmount = (name, e) => {
    if (e.startsWith('¥', 0)) {
      e = e.replace('¥', '');
    }
    if (e.indexOf(',') > 0) {
      e = e.replace(/,/g, '');
    }
    if (name === 'dantori') {
      setSetupData(e);
    } else if (name === 'kakou') {
      setWorkData(e);
    } else {
      setInitialAmount(e);
    }
  };
  const addContent = (
    <>
      <Row>
        <RTable className="propertiesTbl">
          <thead>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">工程名/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle', width: '40%' }}>
                <Select
                  placeholder="選択"
                  value={kouteiName}
                  style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
                  onChange={changeKouteiType}
                >
                  {kouteiMaster.map((item) => (
                    <option value={item.code}>{item.processName}</option>
                  ))}
                </Select>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={code}
                  style={{ padding: 5, marginLeft: 5, width: '93%', pointerEvents: 'none' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">加工方法/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle', width: '40%' }}>
                <Select
                  placeholder="選択"
                  // disabled
                  value={kakouName}
                  style={{
                    fontSize: 11,
                    width: '97%',
                    marginLeft: 5,
                    pointerEvents: kouteiName === 'バリ取' || kouteiName === 'ブランク' ? 'auto' : 'none',
                  }}
                  onChange={changeKakouhouhou}
                >
                  {kakouHouhou.map((item) => (
                    <option value={item.detailCode}>{item.detailType}</option>
                  ))}
                </Select>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  value={kakouCode}
                  type="text"
                  style={{ padding: 5, marginLeft: 5, width: '93%', pointerEvents: 'none' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">装置名/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={souchiName}
                  onChange={(e) => setSouchiName(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-editable'}
                ></Input>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={souchiCode}
                  onChange={(e) => setSouchiCode(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '93%' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row justify="start" align="middle">
        <Col span={7} style={{ textAlign: 'end' }}>
          コピー元装置：
        </Col>
        <Col span={12}>
          <Select
            placeholder="選択"
            value={sourceDevice}
            style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
            onChange={changeSourceDevice}
          >
            {deviceCopy.map((option) => (
              <option value={option.deviceName}>{option.deviceName}</option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <RTable className="propertiesTbl tsuikakoutei">
          <thead>
            <tr className={category === '標準' ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">追加工程種類</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Select
                  placeholder="選択"
                  value={tsuikaKouteiType}
                  onChange={(e) => {
                    setTsuikaKouteiType(e);
                  }}
                  style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
                >
                  {tsuikaKouteiTypes.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr className={`${category === '標準' ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">段取時間</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row style={{ marginLeft: 5 }} align="middle">
                  <Col span={5}>
                    {category === '標準' ? (
                      <>
                        <label style={{ marginLeft: 10 }}>{dantoriTime}</label>
                      </>
                    ) : (
                      <>
                        <Input
                          value={dantoriTime}
                          onChange={(e) => {
                            setDantoriTime(e.target.value);
                          }}
                        ></Input>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', marginLeft: 10 }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(dantoriTime, '10min');
                        setDantoriTime(editedTime);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(dantoriTime, '10min');
                        setDantoriTime(editedTime);
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(dantoriTime, 'min');
                        setDantoriTime(editedTime);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(dantoriTime, 'min');
                        setDantoriTime(editedTime);
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(dantoriTime, 'sec');
                        setDantoriTime(editedTime);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(dantoriTime, 'sec');
                        setDantoriTime(editedTime);
                      }}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === '標準' ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">段取時間チャージ金額</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row align="middle" style={{ marginLeft: 5 }}>
                  {category === '標準' ? (
                    <>
                      <label style={{ marginLeft: 10 }}>{JPYs.format(setupData)}</label>
                      <label style={{ marginLeft: 10 }}>（/h）</label>
                    </>
                  ) : (
                    <>
                      <Col span={5}>
                        <Input
                          value={JPYs.format(setupData)}
                          defaultValue={0}
                          onChange={(e) => changeAmount('dantori', e.target.value)}
                        />
                      </Col>
                      <Col span={12}>
                        <label style={{ marginLeft: 10 }}>（/h）</label>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
            <tr className={`${category === '標準' ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">作業時間(1箇所当たり)</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row style={{ marginLeft: 5 }} align="middle">
                  <Col span={5}>
                    {category === '標準' ? (
                      <>
                        <label style={{ marginLeft: 10 }}>{workTime}</label>
                      </>
                    ) : (
                      <>
                        <Input
                          value={workTime}
                          onChange={(e) => {
                            setWorkTime(e.target.value);
                          }}
                        ></Input>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', marginLeft: 10 }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(workTime, '10min');
                        setWorkTime(editedTime);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(workTime, '10min');
                        setWorkTime(editedTime);
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(workTime, 'min');
                        setWorkTime(editedTime);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(workTime, 'min');
                        setWorkTime(editedTime);
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(workTime, 'sec');
                        setWorkTime(editedTime);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(workTime, 'sec');
                        setWorkTime(editedTime);
                      }}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === '標準' ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">作業時間チャージ金額</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row align="middle" style={{ marginLeft: 5 }}>
                  {category === '標準' ? (
                    <>
                      <label style={{ marginLeft: 10 }}>{JPYs.format(workData)}</label>
                      <label style={{ marginLeft: 10 }}>（/h）</label>
                    </>
                  ) : (
                    <>
                      <Col span={5}>
                        <Input
                          value={JPYs.format(workData)}
                          defaultValue={0}
                          onChange={(e) => changeAmount('kakou', e.target.value)}
                        />
                      </Col>
                      <Col span={12}>
                        <label style={{ marginLeft: 10 }}>（/h）</label>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
            <tr className={category === '標準' ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">委託工程</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Checkbox
                  style={{ marginLeft: 5 }}
                  onChange={handleCheckboxChange}
                  checked={checkboxChecked}
                ></Checkbox>
              </td>
            </tr>

            <tr className={`${category === '標準' ? 'souchi' : ''} ${selectDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">取引先</label>
              </td>
              <td
                style={{
                  verticalAlign: 'middle',
                }}
                colSpan={2}
              >
                <Row>
                  <Col span={22}>
                    {category == '標準' ? (
                      ''
                    ) : (
                      <>
                        <label style={{ marginLeft: 10 }}> {customerName}</label>
                      </>
                    )}
                  </Col>
                  <Col span={2}>
                    <SearchOutlined
                      style={{ fontSize: '18px', pointerEvents: selectDisabled ? 'none' : 'auto' }}
                      onClick={searchCustomer}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === '標準' ? 'souchi' : ''} ${selectDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">取引先コード</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category == '標準' ? (
                  ''
                ) : (
                  <>
                    <label style={{ marginLeft: 10 }}> {customerCode}</label>
                  </>
                )}
              </td>
            </tr>
            <tr className={category === '標準' ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">手入力フラグ</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Checkbox style={{ marginLeft: 5 }} onChange={flagChange} checked={flagChecked}></Checkbox>
              </td>
            </tr>
            <tr className={`${category === '標準' ? 'souchi' : ''} ${inputDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">初期金額(手入力時)</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category == '標準' ? (
                  ''
                ) : (
                  <>
                    <Input
                      type="text"
                      value={JPYs.format(initialAmount)}
                      onChange={(e) => changeAmount('initialAmount', e.target.value)}
                      style={{
                        padding: 5,
                        marginLeft: 5,
                        width: '97%',
                        pointerEvents: inputDisabled ? 'none' : 'auto',
                      }}
                      className="input-editable"
                    ></Input>
                  </>
                )}
              </td>
            </tr>
            <tr className={category === '標準' ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">備考</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category == '標準' ? (
                  ''
                ) : (
                  <>
                    <Input
                      type="text"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97%' }}
                      className={'input-editable'}
                    ></Input>
                  </>
                )}
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row justify="end">
        <Button onClick={addData} className="mainButton" id="update">
          登録
        </Button>
        <Button onClick={cancelData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
          破棄
        </Button>
      </Row>
    </>
  );
  // const addCustomerContent = (
  //   <>
  //     <Row>
  //       <Table
  //         className="tblCustomer"
  //         columns={customerCol}
  //         dataSource={initialCustomerData}
  //         rowClassName={(record) => (record.key == selectedCustomerRowKeys ? 'active-row' : 'data-row')}
  //         scroll={{ y: 590, x: '10vw' }}
  //         pagination={{
  //           position: ['bottomLeft'],
  //           total: (record) => record.length,
  //           showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
  //           defaultPageSize: 25, // 本番値確認必要
  //           defaultCurrent: 1,
  //         }}
  //         onRow={(record, index) => {
  //           return {
  //             onClick: (event) => {
  //               onCustomerRowSelect(record);
  //             },
  //           };
  //         }}
  //       ></Table>
  //     </Row>
  //     <Row justify="end" style={{ marginTop: -30 }}>
  //       <Button className="mainButton" onClick={getCustomerData}>
  //         OK
  //       </Button>
  //     </Row>
  //   </>
  // );
  const updateFlag = (result) => {
    setFlag(result);
  };
  const updatedDetailsData = (result) => {
    if (result != null) {
      const data = [...initialTblItemsBk];

      const index = data.findIndex((item) => item.key === result.key);
      data[index] = result;

      setInitialTableItemsBk(data);
      setSelectedData(result);
      setTmpFilteredData(data);
      setSelectedRowKeys(result.deviceNo);
      if (result.deviceTypes != '追加') {
        setYousetsuNanidoItaatsuMaster(result.yousetsuNanidoItaatsuMasterData);
        setKaritsukiJikanItaatsuMaster(result.karitsukiJikanItaatsuMasterData);
        setToritsukiJikanItaatsuMaster(result.karitsukiJikanItaatsuMasterData);
        setToritsukiJikanSizeMaster(result.toritsukiJikanSizeMasterData);
        setWeldLineMaster(result.yousetsuSenchouMasterData);
        setKakouSokudoItaatsuMaster(result.yousetsuShiageSenchouMasterData);
        setSuuryouKubunMaster(result.kobuhinKeisuuMasterData);
        setAreaMaster(result.areaMasterData);
        setWeightMaster(result.weightMasterData);
        setVolumeMaster(result.volumeMasterData);
        setKosuuMaster(result.kosuuMasterData);
        localStorage.setItem('kosuuMasterData', JSON.stringify(result.kosuuMasterData));
        setMeishouMasterData(result.meishouDetails);
        setIQ3Itaatsu(result.iq3ItaatsuMasterData);
        setIQ3SizeMaster(result.iq3SizeMasterData);
        setItaatsu1Master(result.itaatsu1MasterData);
        setSeisanKosuuMaster(result.seisanKosuuMasterData);
        setIQ3AreaMaster(result.iq3AreaMasterData);
        setLengthMaster(result.lengthMasterData);
        setGasType(result.gasTypeMasterData);
        setJointSize(result.jointSizeMasterData);
        setHonsuuMaster(result.honsuuMasterData);
        setRetrainedMold(result.retrainedMoldMasterData);
      }
      if (currentRoute.includes('parent')) {
        localStorage.setItem('parent_devicesMaster', JSON.stringify(data));
      } else {
        localStorage.setItem('iq3_devicesMaster', JSON.stringify(data));
      }
    }
  };
  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
      }
      callMethod(action.methodName, e);
    }
  };
  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };
  const searchByKeyword = (e) => {
    nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
  };
  const searchByDataState = (value) => {
    koteimei === value ? getDataByFilter() : setKoteimei(value);
  };
  // 絞り込み、
  const getDataByFilter = () => {
    if (initialTblItemsBk.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let currentData = initialTblItemsBk;
    let souchiFIlterData = currentData;

    if (nKeyword != '') {
      souchiFIlterData = souchiFIlterData.filter((item) => item.deviceName.includes(nKeyword));
    }

    if (koteimei !== undefined && koteimei !== '全て') {
      souchiFIlterData = souchiFIlterData.filter((item) => item.processName === koteimei);
    }

    setInitialTableItems(souchiFIlterData);
    // setCurrentPage(1);
  };

  return (
    <>
      <div
        style={{
          height: 870,
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div>
            <Row className="mt-2" align="middle">
              <Col span={2} style={{ textAlign: 'center' }}>
                <label>工程名：</label>
              </Col>
              <Col span={6}>
                <Select
                  className="yousetsu-select search-select-input"
                  placeholder="選択"
                  style={{ fontSize: 11 }}
                  // onChange={handleSelectVal}
                  onChange={beforeSearch}
                  value={koteimei}
                  defaultValue={'全て'}
                >
                  <option value={'全て'}>全て</option>

                  {kouteiMaster.map((item) => (
                    <option value={item.processName}>{item.processName}</option>
                  ))}
                </Select>
              </Col>
              <Col span={7}>
                <Input
                  className="input-editable search-select-input"
                  placeholder="装置名"
                  allowClear
                  onPressEnter={beforeSearch}
                  onBlur={beforeSearch}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      beforeSearch(e);
                    }
                  }}
                  name="name"
                  id="name"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={24}>
                <Table
                  className="tblCustomerList"
                  columns={tblColumns}
                  scroll={{ y: 590, x: '10vw' }}
                  dataSource={initialTblItems}
                  rowKey={(record) => record.deviceNo}
                  rowClassName={(record) => (record.deviceNo == selectedRowKeys ? 'active-row' : 'data-row')}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    // defaultPageSize: 25, // 本番値確認必要
                    defaultPageSize: defaultPageSize,
                    defaultCurrent: 1,
                    pageSize: defaultPageSize,
                    current: cusCurrentPage,
                    onChange: (page) => {
                      setCusCurrentPage(page);
                    },
                  }}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                ></Table>
              </Col>
            </Row>
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":iq3.device"
                element={
                  <DevicesDetails
                    flag={flag}
                    updatedFlag={updateFlag}
                    editMode={editMode}
                    selectedData={selectedData}
                    updatedDetails={updatedDetailsData}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>
      {/* 新規作成 */}
      {isNew
        ? commonModal(isNew, addNewModalTitle, null, null, 600, addData, closeAddModal, addContent, null, null, true)
        : ''}

      {/* 取引先検索 */}
      {/* {customerModal
        ? commonModal(
            customerModal,
            addCustomerModalTitle,
            null,
            null,
            900,
            addCustomerData,
            closeCustomerModal,
            addCustomerContent,
            null,
            { left: 500, top: 10 },
            false
          )
        : ''} */}
      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={getCustomerData}
          title={Customer}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            closeAddModal,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
      {isDelete
        ? commonModal(
            isDelete,
            deleteModalTitle,
            commonFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default DevicesList;
