/**
 * クラス名：装置設定の点付、仮付ピッチ画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Input, Button, Space } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';

import { commonModal } from '../../../common/CommonModal';

const Pitch = forwardRef((props, ref) => {
  const [detailsItaatsu, setDetailsItaatsu] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isItaatsu, setIsItaatsu] = useState(false);
  const [detailsItaatsuTemp, setDetailsItaatsuTemp] = useState([]);
  const [list, setList] = useState([]);

  const [yousetsuNanidoItaatsuList, setYousetsuNanidoItaatsuList] = useState([]);
  const [tapItaatsuList, setTapItaatsuList] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    setDetailsItaatsu(props.detailsItaatsu);
    setDetailsItaatsuTemp(props.detailsItaatsu);

    setList(props.itaatsuList);
    setYousetsuNanidoItaatsuList(props.listData);
    setTapItaatsuList(props.tapitaatsuList);
  }, [props.detailsItaatsu, props.editMode, props.itaatsuList, props.listData, props.tapitaatsuList]);
  const thickness = () => {
    setIsItaatsu(true);
  };
  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const addItaatsu = (id) => {
    const copyData = detailsItaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsItaatsuTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsItaatsuTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsItaatsuTemp(copyData);
    }
  };
  const deleteItaatsu = (id, e) => {
    if (detailsItaatsuTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsItaatsuTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsItaatsuTemp(updatedData);
    }
  };
  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
      setDetailsItaatsuTemp(detailsItaatsu);
    }
  };
  const cancelAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
      setDetailsItaatsuTemp(detailsItaatsu);
    }
  };
  const handleItaatsuChange = (event, id, field) => {
    const updatedData = detailsItaatsuTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsItaatsuTemp(updatedData);
  };
  const deleteListData = (list, deletedId) => {
    const deletedData = list.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    return deletedData;
  };
  const updateListData = (deletedData, newId) => {
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    return sortedList;
  };
  const addItaatsuMaster = () => {
    const deletedId = [];

    detailsItaatsu.forEach((item1, index) => {
      const found = detailsItaatsuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsItaatsuTemp
      .filter((item) => item.displayFlag && !detailsItaatsu.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData(tapItaatsuList, deletedId);
    const deletedYousetsuNanidoData = deleteListData(yousetsuNanidoItaatsuList, deletedId);
    const deletedPitchData = deleteListData(list, deletedId);

    const updatedData = updateListData(deletedData, newId);
    const updatedYousetsuNanidoData = updateListData(deletedYousetsuNanidoData, newId);
    const updatedPitchData = updateListData(deletedPitchData, newId);

    const sortTapList = sortListData(updatedData, detailsItaatsuTemp);
    const sortYousetsuNanidoList = sortListData(updatedYousetsuNanidoData, detailsItaatsuTemp);
    const sortPitchList = sortListData(updatedPitchData, detailsItaatsuTemp);

    setIsItaatsu(false);
    setDetailsItaatsu(detailsItaatsuTemp);

    setList(sortPitchList);
    props.updatedItaatsu(detailsItaatsuTemp);

    props.updatedItaatsuList(sortPitchList);

    setYousetsuNanidoItaatsuList(sortYousetsuNanidoList);
    props.updatedListData(sortYousetsuNanidoList);

    setTapItaatsuList(sortTapList);
    props.updatedTapItaatsuList(sortTapList);
  };
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsItaatsuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleItaatsuChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addItaatsuMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelAddModal}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...list];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setList(updatedData);
    props.updatedItaatsuList(updatedData);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              detailsItaatsu.filter((i) => i.displayFlag === true).length > 8
                ? '100%'
                : detailsItaatsu.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header">
              <Row justify="center" className="tbl-header">
                <Space>
                  材質区分 /
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={thickness} style={{ pointerEvents: 'auto' }}>
                        板厚
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>板厚</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {detailsItaatsu
              .filter((subitem) => subitem.displayFlag === true)
              .map((item) => (
                <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}t</td>
              ))}
          </tr>

          {list.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                {row.materialType}
              </td>

              {row.lstData.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Pitch;
