import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const additionalProcessArr = [
  {
    id: 0,
    processDetailName: '追加工程(社内)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    kasho: 1,
    itaku: true,
    supplier: '',
    supplierCode: '',
  },
  {
    id: 0,
    processDetailName: '追加工程(社外)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    kasho: 1,
    itaku: true,
    supplier: '株式会社委託',
    supplierCode: '101',
  },
];

const pmx02Arr = [
  {
    id: 0,
    processDetailName: '追加工程(社内)',
    dandoriAmt: 210,
    dandoriTime: '00:08:00',
    sagyoAmt: 500,
    sagyoTime: '00:03:00',
    kasho: 1,
    itaku: true,
    supplier: '',
    supplierCode: '',
  },
  {
    id: 0,
    processDetailName: '追加工程(社外)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    kasho: 1,
    itaku: true,
    supplier: '株式会社委託',
    supplierCode: '101',
  },
];

const pmx03Arr = [
  {
    id: 0,
    processDetailName: '追加工程(社内)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    kasho: 1,
    itaku: true,
    supplier: '',
    supplierCode: '',
  },
  {
    id: 0,
    processDetailName: '追加工程(社外)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    kasho: 1,
    itaku: true,
    supplier: '株式会社委託',
    supplierCode: '101',
  },
];

const ParentKouteiCalculate_Additional = forwardRef((props, ref) => {
  const [isAdditionalaridetail, setIAdditionalaridetail] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);

  const settingEditData = (arr) => {
    let editDataArr = [];
    //for (let i = 0; i < arr.length; i++) {
    //  let processDetailArr = [];
    //  let subArr = arr[i];
    for (let j = 0; j < arr.length; j++) {
      editDataArr.push({
        id: j,
        processDetailName: arr[j].processDetailName,
        dandoriAmt: arr[j].dandoriAmt,
        dandoriTime: arr[j].dandoriTime,
        sagyoAmt: arr[j].sagyoAmt,
        sagyoTime: arr[j].sagyoTime,
        kasho: arr[j].kasho,
        itaku: arr[j].itaku,
        supplier: arr[j].supplier,
        supplierCode: arr[j].supplierCode,
        subSumAmt: parseFloat(arr[j].dandoriAmt) + parseFloat(arr[j].sagyoAmt),

        subSumTime: secondsToHms(setTimetoSec(arr[j].dandoriTime) + setTimetoSec(arr[j].sagyoTime)),
      });
    }
    /*  editDataArr.push({
        id: i,
        processDetail: processDetailArr,
      }); */
    //}
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let totalAmt = 0;
    let totalTimeSec = 0;

    editDataArr.forEach((e) => {
      totalAmt = e.subSumAmt;
      totalTimeSec = setTimetoSec(e.subSumTime);
    });

    let totalTimeSum = secondsToHms(totalTimeSec);

    let totals = [];
    let i = 0;
    totals.push({
      totalSum: totalAmt,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (Object.keys(props.selectedDataDetail).length > 0) {
      /* {
      setProcessArr(zeroArr);
      editData = settingEditData(zeroArr);
    } else  */
      if (props.selectedDataDetail.imgNo == 'zerofour-02') {
        setProcessArr(pmx02Arr);
        editData = settingEditData(pmx02Arr);
      } else if (props.selectedDataDetail.imgNo == 'zerofour-03') {
        setProcessArr(pmx03Arr);
        editData = settingEditData(pmx03Arr);
      } else {
        setProcessArr(additionalProcessArr);
        editData = settingEditData(additionalProcessArr);
      }
    }
    setEditedData(editData);

    totals = calculateTotals(editData);
    setTotalAmt(totals[0].totalSum);
    setTotalSum(totals[0].totalSum);

    setTotalTime(totals[0].totalTimeSum);
    setTotalTimeSum(totals[0].totalTimeSum);

    /*setTotalSagyoAmt(totals[0].sagyoAmtSum);
    setSagyoAmtSum(totals[0].sagyoAmtSum);

    setTotalSagyoTime(totals[0].sagyoTimeSum);
    setSagyoTimeSum(totals[0].sagyoTimeSum);

    setTotalDandoriAmt(totals[0].dandoriAmtSum);
    setDandoriAmtSum(totals[0].dandoriAmtSum);

    setTotalDandoriTime(totals[0].dandoriTimeSum);
    setDandoriTimeSum(totals[0].dandoriTimeSum); */
  }, [props.selectedDataDetail, props.editMode]);

  /* useEffect(() => {
    let dataEdit = [];
    let totals = [];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (Object.keys(props.selectedDataDetail).length > 0) {
      if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
        setProcessArr(AdditionalProcessArr);
        dataEdit = settingEditData(AdditionalProcessArr);
      } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
        setProcessArr(AdditionalProcessArr2);
        dataEdit = settingEditData(AdditionalProcessArr2);
      } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
        setProcessArr(AdditionalProcessArr3);
        dataEdit = settingEditData(AdditionalProcessArr3);
      } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
        setProcessArr(AdditionalProcessArr4);
        dataEdit = settingEditData(AdditionalProcessArr4);
      } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
        setProcessArr(AdditionalProcessArr5);
        dataEdit = settingEditData(AdditionalProcessArr5);
      } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
        setProcessArr(processArr);
        dataEdit = settingEditData(processArr);
      }
    }
    console.log('dataEdit---', dataEdit);
    setEditedData(dataEdit);
    totals = calculateTotals(dataEdit);

    setTotalAmt(totals[0].totalSum);
    setTotalSum(totals[0].totalSum);

    setTotalTime(totals[0].totalTimeSum);
    setTotalTimeSum(totals[0].totalTimeSum);
  }, [props.selectedDataDetail, props.editMode]); */

  const Additionalari = (i) => {
    let showHide = document.getElementById('arrowDownAdditionalTop' + i)?.style.display;
    if (showHide == 'block') {
      document.getElementById('tsuikaKoutei' + i).style.display = 'block';
      document.getElementById('arrowDownAdditionalTop' + i).style.display = 'none';
      document.getElementById('arrowUpAdditionalTop' + i).style.display = 'block';
    } else {
      document.getElementById('tsuikaKoutei' + i).style.display = 'none';
      document.getElementById('arrowDownAdditionalTop' + i).style.display = 'block';
      document.getElementById('arrowUpAdditionalTop' + i).style.display = 'none';
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('AdditionalProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('AdditionalProcess' + i).style.display = 'none';
      document.getElementById('arrowDownAdditional' + i).style.display = 'block';
      document.getElementById('arrowUpAdditional' + i).style.display = 'none';
    } else {
      document.getElementById('AdditionalProcess' + i).style.display = 'block';
      document.getElementById('arrowDownAdditional' + i).style.display = 'none';
      document.getElementById('arrowUpAdditional' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'AdditionalDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'AdditionalDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'AdditionalDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'AdditionalDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      if (document.getElementById(index + 'AdditionalWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'AdditionalWorkAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'AdditionalWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'AdditionalWorkAmt', newState[index].sagyoAmt, 'green');
          checkInputWithColor(index + 'AdditionalWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = parseFloat(newState[index].sagyoAmt) + parseFloat(newState[index].dandoriAmt);
    newState[index].subSumAmt = newSubSum;

    let newSubTimeSum = secondsToHms(
      setTimetoSec(newState[index].sagyoTime) + setTimetoSec(newState[index].dandoriTime)
    );
    newState[index].subSumTime = newSubTimeSum;
    newState.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      newSagyoTimeSec += setTimetoSec(element.sagyoTime);
      newDandoriTimeSec += setTimetoSec(element.dandoriTime);
    });
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = secondsToHms(newSagyoTimeSec);
    let newDandoriTimeSum = secondsToHms(newDandoriTimeSec);
    let newTotalTimeSum = secondsToHms(newSagyoTimeSec + newDandoriTimeSec);

    checkInputWithColor(index + 'autoSubTotal', newSubSum, 'green');
    checkInputWithColor(index + 'autoSubTotalTime', newSubTimeSum, 'green');
    /* checkInputWithColor('totalSagyoAmtAuto', newSagyoSum, 'green');
    checkInputWithColor('totalDandoriAmtAuto', newDandoriSum, 'green'); */
    /* checkInputWithColor('totalAmtAuto', newSum, 'green');
    checkInputWithColor('totalSagyoTimeAuto', newSagyoTimeSum, 'green');
    checkInputWithColor('totalDandoriTimeAuto', newDandoriTimeSum, 'green');
    checkInputWithColor('totalTimeAuto', newTotalTimeSum, 'green'); */
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div>
      {(() => {
        const tsuikaKoutei = [];
        for (let i = 0; i < processArr?.length; i++) {
          tsuikaKoutei.push(
            <div className="kouteiCalculateDiv">
              <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={(e) => Additionalari(i)}>
                <Col span={4} className="oyabuhin-col">
                  <div style={{ display: 'flex' }}>
                    <CaretUpOutlined
                      id={'arrowUpAdditionalTop' + i}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                    />
                    <CaretDownOutlined
                      id={'arrowDownAdditionalTop' + i}
                      style={{ fontSize: 17, marginTop: 2, display: 'block' }}
                    />
                    <label style={{ marginLeft: 5 }}>{processArr[i].processDetailName}</label>
                  </div>
                </Col>
                <Col span={6} className="oyabuhin-col">
                  <div>{JPYs.format(parseFloat(processArr[i].dandoriAmt) + parseFloat(processArr[i].sagyoAmt))}</div>
                </Col>
                <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
                  <div>
                    <label style={{ marginLeft: 5 }}>
                      {i == 0 ? '追加工程（社内）_標準' : '追加工程（社外）_標準'}
                    </label>
                  </div>
                </Col>
              </Row>
              <div id={'tsuikaKoutei' + i} style={{ display: 'none' }}>
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    工程詳細
                  </Col>
                  <Col span={6} className="oyabuhin-detail-col">
                    <Checkbox defaultChecked={true}>データ</Checkbox>
                  </Col>
                  <Col span={8} className="oyabuhin-detail-col">
                    <Checkbox> 編集データ</Checkbox>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col">
                    単位
                  </Col>
                  <Col span={5} className="formula-column">
                    計算式
                  </Col>
                </Row>

                <div>
                  <Row
                    style={{ color: 'black' }}
                    className="oyabuhin-detail oyabuhin-row"
                    /* onClick={(e) => subpartDetail(j)} */
                  >
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        {/* <CaretUpOutlined
                                id={'arrowUpAdditional' + j}
                                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                              />
                              <CaretDownOutlined
                                id={'arrowDownAdditional' + j}
                                style={{ fontSize: 17, marginTop: 2 }}
                              /> */}
                        <span style={{ marginLeft: '5px' }}>{editedData[i]?.processDetailName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'autoSubTotal'}>
                        {JPYs.format(parseFloat(processArr[i].dandoriAmt) + parseFloat(processArr[i].sagyoAmt))}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={i + 'autoSubTotalTime'}>
                          {secondsToHms(
                            setTimetoSec(processArr[i].dandoriTime) + setTimetoSec(processArr[i].sagyoTime)
                          )}
                        </label>
                      }
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col">
                      <label id={i + 'autoSubTotalIn'} className="resetColor">
                        {JPYs.format(editedData[i].subSumAmt)}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col">
                      <label id={i + 'autoSubTotalTimeIn'} className="resetColor">
                        {editedData[i].subSumTime}
                      </label>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                  {/* <div id={'AdditionalProcess' + j} style={{ display: 'none' }}> */}
                  <div>
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span>段取金額/時間</span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'AdditionalDandoriAmt'}>{JPYs.format(processArr[i].dandoriAmt)}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'AdditionalDandoriTime'}>{processArr[i].dandoriTime}</label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={i + 'AdditionalDandoriAmtIn'}
                          value={editedData[i].dandoriAmt}
                          defaultValue={0}
                          onValueChange={(e) => {
                            //checkRegex(e.target.value);
                            if (editedData[i].dandoriAmt != e) {
                              settingNewDandoriAmtInArr(e, i, 'dandoriAmt', '', '');
                              checkInputWithColor(i + 'AdditionalDandoriAmt', e, 'amt');
                            }
                          }}
                          style={{ border: 'none' }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={i + 'AdditionalDandoriAmtCu'} style={{ display: 'none' }}>
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={editedData[i].dandoriTime}
                              id={i + 'AdditionalDandoriTimeIn'}
                              style={{ width: '100%' }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                //disableTimer('test');
                                settingNewDandoriAmtInArr(e.target.value, i, 'dandoriTime', 'input', 'input');
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[i].dandoriTime,
                                      '10min',
                                      i + 'AdditionalDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'dandoriTime', '10min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[i].dandoriTime,
                                      '10min',
                                      i + 'AdditionalDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'dandoriTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[i].dandoriTime,
                                      'min',
                                      i + 'AdditionalDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'dandoriTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[i].dandoriTime,
                                      'min',
                                      i + 'AdditionalDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'dandoriTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[i].dandoriTime,
                                      'sec',
                                      i + 'AdditionalDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'dandoriTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[i].dandoriTime,
                                      'sec',
                                      i + 'AdditionalDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'dandoriTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span>作業金額/時間</span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'AdditionalWorkAmt'}>{JPYs.format(processArr[i].sagyoAmt)}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'AdditionalWorkTime'}>{processArr[i].sagyoTime}</label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={i + 'AdditionalWorkAmtIn'}
                          value={editedData[i].sagyoAmt}
                          defaultValue={0}
                          onValueChange={(e) => {
                            if (editedData[i].sagyoAmt != e) {
                              settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                              checkInputWithColor(i + 'AdditionalWorkAmt', e, 'amt');
                            }
                          }}
                          style={{ border: 'none' }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={i + 'AdditionalWorkAmtCu'} style={{ display: 'none' }}>
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={editedData[i].sagyoTime}
                              id={i + 'AdditionalWorkTimeIn'}
                              style={{ width: '100%' }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                //disableTimer('test');
                                settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[i].sagyoTime,
                                      '10min',
                                      i + 'AdditionalWorkTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[i].sagyoTime,
                                      '10min',
                                      i + 'AdditionalWorkTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[i].sagyoTime,
                                      'min',
                                      i + 'AdditionalWorkTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[i].sagyoTime,
                                      'min',
                                      i + 'AdditionalWorkTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[i].sagyoTime,
                                      'sec',
                                      i + 'AdditionalWorkTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[i].sagyoTime,
                                      'sec',
                                      i + 'AdditionalWorkTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                    <div
                      style={{
                        display: processArr[i].supplier !== '' && processArr[i].supplierCode !== '' ? 'block' : 'none',
                      }}
                    >
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col">
                          <span>取引先/コード​</span>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id="supplier">{processArr[i].supplier}</label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id="supplierCode">{processArr[i].supplierCode}</label>
                        </Col>

                        <Col span={4} className="input-col">
                          <InputNumber
                            value={editedData[i].supplier}
                            id="supplierIn"
                            min={0}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            controls={false}
                            style={{ width: '70%', border: 'none' }}
                            onChange={(e) => {}}
                          />
                        </Col>

                        <Col span={4} className="input-col">
                          <InputNumber
                            value={editedData[i].supplierCode}
                            id="supplierCodeIn"
                            min={0}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            controls={false}
                            style={{ width: '70%', border: 'none' }}
                            onChange={(e) => {}}
                          />
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col"></Col>
                        <Col span={5} className="formula-column"></Col>
                      </Row>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          );
        }
        return tsuikaKoutei;
      })()}
    </div>
  );
});

export default ParentKouteiCalculate_Additional;
