/**
 * クラス名：ログイン画面
 * 説明：システムログイン画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Form, Input, Button, Image, Checkbox, Alert } from 'antd';
import React, { forwardRef, useState } from 'react';
// import { withRouter } from 'react-router-dom';
import logo from '../assets/images/logo.ico';
import { Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { WithRouter } from '../components/WithRouter';
import { StatusCodes } from 'http-status-codes';

import { ErrorMessage } from '../views/common/Message';
import { updateAccessToken } from '../views/common/Common';
import { getCurrentUserInfo } from '../views/common/CommonAPI';
import { error, authorizeError } from '../views/common/CommonModal';
import {
  kouteiMasterData,
  paramCommonCost,
  paramCommonLabel,
  paramCommonMemo,
  paramCommonDate,
  paramCommonOrderCoeff,
  paramCommonDeliTimeCoeff,
  paramCommonPurchaseCoeff,
  paramCommonMaintainCoeff,
} from '../views/common/Constant';
// MF用サンプルデータ
import { parent_deviceMasterList, quantityMasterData } from './common/MF_ParentDeviceListSample';
import { iq3_devicesMasterList } from './common/MF_IQ3DeviceListSample';
import { iq3_processPatternMasterList } from './common/MF_IQ3ProcessPatternListSample';
import { parent_processPatternMasterList } from './common/MF_ParentProcessPatternListSample';

const provinceData = ['日本語', '英語'];

const Login = forwardRef((props, ref) => {
  const formRef = React.useRef(null);
  let params = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(params.has('ErrorMessage') ? params.get('ErrorMessage') : '');

  // ログインボタンの押下
  const handleSubmit = async (values) => {
    let name = values?.name;
    let password = values?.password;
    let remember = values?.rememberMe;

    await login(name, password, remember);
  };

  // ログイン処理
  const login = async (name, password, remember) => {
    // 必須チェック
    if (!name || !password) {
      setErrorMessage(ErrorMessage().E005);
      return;
    }
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Login/Login';
      const otherParam = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          companyId: '',
          userName: name,
          password: password,
          rememberMe: remember,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then(async (data) => {
          if (data && data?.statusCode === StatusCodes.OK) {
            // 正常の場合、
            let token = data.updateToken;
            // ログインユーザー情報を取得する
            let result = await getCurrentUserInfo(token);
            if (result === '') {
              //係数・追加項目一覧をセッションにセット。
              if (localStorage.getItem('paramCommonCost') == undefined) {
                localStorage.setItem('paramCommonCost', JSON.stringify(paramCommonCost));
              }
              if (localStorage.getItem('paramCommonLabel') == undefined) {
                localStorage.setItem('paramCommonLabel', JSON.stringify(paramCommonLabel));
              }
              if (localStorage.getItem('paramCommonMemo') == undefined) {
                localStorage.setItem('paramCommonMemo', JSON.stringify(paramCommonMemo));
              }
              if (localStorage.getItem('paramCommonDate') == undefined) {
                localStorage.setItem('paramCommonDate', JSON.stringify(paramCommonDate));
              }
              if (localStorage.getItem('paramCommonOrderCoeff') == undefined) {
                localStorage.setItem('paramCommonOrderCoeff', JSON.stringify(paramCommonOrderCoeff));
              }
              if (localStorage.getItem('paramCommonDeliTimeCoeff') == undefined) {
                localStorage.setItem('paramCommonDeliTimeCoeff', JSON.stringify(paramCommonDeliTimeCoeff));
              }
              if (localStorage.getItem('paramCommonPurchaseCoeff') == undefined) {
                localStorage.setItem('paramCommonPurchaseCoeff', JSON.stringify(paramCommonPurchaseCoeff));
              }
              if (localStorage.getItem('paramCommonMaintainCoeff') == undefined) {
                localStorage.setItem('paramCommonMaintainCoeff', JSON.stringify(paramCommonMaintainCoeff));
              }
              //装置マスターをセッションにセット。
              if (localStorage.getItem('parent_devicesMaster') == undefined) {
                localStorage.setItem('parent_devicesMaster', JSON.stringify(parent_deviceMasterList));
              }
              // 装置生産個数マスターをセッションにセット。
              if (localStorage.getItem('kosuuMasterData') == undefined) {
                localStorage.setItem('kosuuMasterData', JSON.stringify(quantityMasterData));
              }
              if (localStorage.getItem('iq3_devicesMaster') == undefined) {
                localStorage.setItem('iq3_devicesMaster', JSON.stringify(iq3_devicesMasterList));
              }
              // 工程マスターをセッションにセット。
              if (localStorage.getItem('kouteiMasterData') == undefined) {
                localStorage.setItem('kouteiMasterData', JSON.stringify(kouteiMasterData));
              }
              // 工程パターンマスターをセッションにセット。
              if (localStorage.getItem('iq3_processPattern') == undefined) {
                localStorage.setItem('iq3_processPattern', JSON.stringify(iq3_processPatternMasterList));
              }
              // 工程パターンマスターをセッションにセット。
              if (localStorage.getItem('parent_processPattern') == undefined) {
                localStorage.setItem('parent_processPattern', JSON.stringify(parent_processPatternMasterList));
              }
              // セクションにトークン情報を保持する
              updateAccessToken(token);
              // 取得正常の場合、ホームに遷移される
              props.navigate('/home');
            } else {
              // 異常の場合、
              setErrorMessage(result);
            }
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            // 異常の場合、
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          }
        })
        .catch((error) => {
          // 異常の場合、
          setErrorMessage(ErrorMessage().E010);
        });
    } catch (error) {
      // 異常の場合、
      setErrorMessage(ErrorMessage().E003);
      return error;
    }
  };

  // 入力項目に値を設定
  const setFormValue = (name, value) => {
    formRef.current?.setFieldValue(name, value);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        verticalAlign: 'center',
        backgroundColor: '#005FAB',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '60px',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
      >
        <Form
          name="Login"
          onFinish={handleSubmit}
          ref={formRef}
          layout="vertical"
          style={{ maxWidth: '500px', height: '100%' }}
          initialValues={{
            rememberMe: false,
          }}
        >
          <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <Image preview={{ visible: false }} width={100} height={100} src={logo}></Image>
          </Form.Item>
          <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: '-20px' }}>
            <p style={{ fontSize: '25px', fontWeight: 'bold', color: 'gold' }}>ZeroFour System Login</p>
          </Form.Item>
          {/* ユーザーID */}
          <Form.Item
            name="name"
            label={<label style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>ユーザーID</label>}
          >
            <Input style={{ width: '380px', height: '40px' }} placeholder="test@demo111.jp" />
          </Form.Item>
          {/* パスワード */}
          <Form.Item
            name="password"
            label={<label style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>パスワード</label>}
          >
            <Input style={{ width: '380px', height: '40px' }} type="password" placeholder="123456" />
          </Form.Item>
          {/* 自動ログイン */}
          <Form.Item
            name="rememberMe"
            valuePropName="checked"
            style={{ display: 'flex', justifyContent: 'left', height: '40px' }}
          >
            <Checkbox>
              <p style={{ color: 'white', fontSize: '15px', margin: 0 }}>次回から自動ログイン</p>
            </Checkbox>
          </Form.Item>
          {/* ログインボタン */}
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              style={{
                backgroundColor: '#ff7f50',
                color: 'white',
                width: '150px',
                height: '40px',
                fontWeight: '600',
                borderColor: '#808080',
                borderWidth: '1px',
              }}
              type="primary"
              htmlType="submit"
            >
              ログイン
            </Button>
          </Form.Item>
          {errorMessage != '' ? (
            <Form.Item name="errorMessage">
              <Alert
                style={{ width: 380, textAlign: 'center', whiteSpace: 'pre-line' }}
                message={errorMessage}
                type="error"
                showIcon
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </div>
    </div>
  );
});

export default WithRouter(Login);
