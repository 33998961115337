import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Table as AntTable, Space } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, DownSquareFilled, UpSquareFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import ParentKouteiInput_Yousetsu from './ParentKouteiInput_Yousetsu';
import ParentKouteiInput_Kumitate from './ParentKouteiInput_Kumitate';
import ParentKouteiInput_Hyoumenshori from './ParentKouteiInput_Hyoumenshori';
import ParentKouteiInput_Kensa from './ParentKouteiInput_Kensa';
import ParentKouteiInput_Konpou from './ParentKouteiInput_Konpou';
import { commonTaiochuModal, confirmHakiModal } from '../common/CommonModal';

const options = ['北海道', '北東北', '南東北', '関東', '信越', '北陸', '関西', '中国', '四国', '九州', '沖縄'];
const platingoptions = ['ユニクロメッキ', 'クロメート', 'クロームメッキ', '亜鉛メッキ', 'メッキ01', 'メッキ02'];
const paintingoptions = ['メラミン塗装', 'アクリル塗装', 'ウレタン塗装', '粉体塗装'];
const paintingoptions2 = ['カチオン塗装', 'プライマー', '塗装01', '塗装02'];
const columns = [
  {
    id: '1',
    title: '子部品種類',
    dataIndex: 'parentname',
  },
  {
    id: '2',
    title: '子部品名',
    dataIndex: 'childname',
  },
  {
    id: '3',
    title: '面積',
    dataIndex: 'surface',
  },
  {
    id: '4',
    title: '重量(kg)',
    dataIndex: 'weight',
  },
  {
    id: '5',
    title: '材質',
    dataIndex: 'quality',
  },
  {
    id: '6',
    title: '板厚',
    dataIndex: 'thick',
  },
];
const childParts = [
  {
    key: 1,
    childKey: 1,
    parentName: `iQ3板金`,
    childType: 'iQ3',
    imgNm: 'Product-20230320 105141',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 2,
    childKey: 1,
    parentName: `iQ5形鋼`,
    childType: 'iQ5',
    imgNm: 'Product-20230320 105142',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 3,
    childKey: 1,
    parentName: `iQ7機械`,
    childType: 'iQ7',
    imgNm: 'Product-20230320 105143',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
];
const surfaceData = [];

for (let i = 1; i < 10; i++) {
  surfaceData.push({
    key: i,
    parentname: `iQ3板金`,
    childname: `Test-${i}`,
    surface: '100',
    weight: 10,
    quality: 'SPCC',
    thick: 1.6,
  });
}
const yousetsu1Data1 = [
  {
    key: 1,
    yousetsuType: 'アルゴン',
    senchou: 0,
    senchouKaisu: 0,
    senchouYousetsushiage: 'on',
    total1: 0,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
];
const yousetsu1Data2 = [
  {
    key: 1,
    yousetsuType: 'アルゴン',
    senchou: 200,
    senchouKaisu: 2,
    senchouYousetsushiage: 'on',
    total1: 400,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: '',
  },
  {
    key: 2,
    yousetsuType: 'アルゴン',
    senchou: 133,
    senchouKaisu: 2,
    senchouYousetsushiage: 'on',
    total1: 266,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: '',
  },
];
const yousetsu1Data3 = [
  {
    key: 1,
    yousetsuType: 'アルゴン',
    senchou: 690,
    senchouKaisu: 2,
    senchouYousetsushiage: 'off',
    total1: 1380,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
  {
    key: 2,
    yousetsuType: 'アルゴン',
    senchou: 110.8,
    senchouKaisu: 2,
    senchouYousetsushiage: 'off',
    total1: 221.6,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
  {
    key: 3,
    yousetsuType: 'アルゴン',
    senchou: 800,
    senchouKaisu: 2,
    senchouYousetsushiage: 'off',
    total1: 1600,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
  {
    key: 4,
    yousetsuType: 'アルゴン',
    senchou: 65,
    senchouKaisu: 1,
    senchouYousetsushiage: 'off',
    total1: 65,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
  {
    key: 5,
    yousetsuType: 'アルゴン',
    senchou: 40,
    senchouKaisu: 4,
    senchouYousetsushiage: 'off',
    total1: 160,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
  {
    key: 6,
    yousetsuType: 'アルゴン',
    senchou: 65,
    senchouKaisu: 1,
    senchouYousetsushiage: 'off',
    total1: 65,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
  {
    key: 7,
    yousetsuType: 'アルゴン',
    senchou: 105,
    senchouKaisu: 2,
    senchouYousetsushiage: 'off',
    total1: 210,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'off',
    total2: 0,
  },
];
const hyoumenshoriData1 = [
  {
    key: 1,
    areaChk: false,
    method: '面積',
    area: false,
    areaValue: 0,
    weightChk: false,
    weightValue: 0,
    dasshi: false,
    plat1: false,
    both: false,
    oneside: false,
    mekki: false,
    shorione: '',
    platRdoVal: 'メッキ',
    paintRyomen: false,
    paintRyomenRdoVal: '',
    paintKatamen: false,
    paintKatamenRdoVal: '',
    totalcount: '',
    detailsBtn: 1,
    childParts: [],
    selectedChildPartCnt: 0,
    opttest: ['脱脂', 'メッキ'],
    rdooptions: platingoptions,
    total: 0,
    totalcount: 10,
  },
];
const hyoumenshoriData2 = [
  {
    key: 1,
    areaChk: false,
    method: '面積',
    area: false,
    areaValue: 0,
    weightChk: false,
    weightValue: 0,
    dasshi: false,
    plat1: false,
    both: false,
    oneside: false,
    mekki: false,
    shorione: '',
    platRdoVal: '脱脂',
    paintRyomen: false,
    paintRyomenRdoVal: '',
    paintKatamen: false,
    paintKatamenRdoVal: '',
    totalcount: '',
    detailsBtn: 1,
    childParts: [],
    selectedChildPartCnt: 5,
    opttest: ['脱脂', 'メッキ'],
    rdooptions: [],
    total: 8.681,
    totalcount: 5,
  },
  {
    key: 2,
    areaChk: false,
    method: '重量',
    area: false,
    areaValue: 0,
    weightChk: false,
    weightValue: 0,
    dasshi: false,
    plat1: false,
    both: false,
    oneside: false,
    mekki: false,
    shorione: '',
    platRdoVal: '塗装(両面)',
    paintRyomen: false,
    paintRyomenRdoVal: '',
    paintKatamen: false,
    paintKatamenRdoVal: '',
    totalcount: '',
    detailsBtn: 1,
    childParts: [],
    selectedChildPartCnt: 5,
    opttest: ['脱脂', 'メッキ'],
    rdooptions: paintingoptions,
    total: 8.681,
    totalcount: 5,
  },
];
const kumitateData2 = [
  {
    key: 1,
    kumitateType: '購入品取付時間',
    childPartNm: ['板金子部品', '形鋼子部品', '機械加工子部品'],
    childParts: childParts,

    sum: '00:06:40',
    bankin1: 5,
    bankin2: '/5',
    katakou1: 0,
    katakou2: '/0',
    kakou1: 10,
    kakou2: '/10',
    weightVal: 20,
    types: 3,
    totalCnt: 10,
    selectedChildPartCnt: 10,
  },
];
const kumitateData1 = [
  {
    key: 1,
    kumitateType: '子部品点数',
    childPartNm: ['板金子部品', '形鋼子部品', '親部品購入品'],
    childParts: childParts,

    sum: 10,
    bankin1: 9,
    bankin2: '/9',
    katakou1: 0,
    katakou2: '/0',
    kakou1: 35,
    kakou2: '/35',
    weightVal: 15.648,
    types: 6,
    totalCnt: 10,
    selectedChildPartCnt: 10,
  },
];
const kumitateData3 = [
  {
    key: 1,
    kumitateType: '子部品点数',
    childPartNm: ['板金子部品', '形鋼子部品', '親部品購入品'],
    childParts: childParts,

    sum: 12,
    bankin1: 6,
    bankin2: '/6',
    katakou1: 0,
    katakou2: '/0',
    kakou1: 3,
    kakou2: '/3',
    weightVal: 6.909,
    types: 6,
    totalCnt: 6,
    selectedChildPartCnt: 6,
  },
];
const kensaData1 = [
  {
    key: 1,
    kensaType: '子部品点数',
    childPartNm: ['板金子部品', '形鋼子部品', '親部品購入品'],
    childParts: childParts,

    sum: 10,
    bankin1: 9,
    bankin2: '/9',
    katakou1: 0,
    katakou2: '/0',

    weightVal: 15.648,
    types: 6,
    totalCnt: 10,
    selectedChildPartCnt: 10,
  },
];
const kensaData2 = [
  {
    key: 1,
    kensaType: '子部品点数',
    childPartNm: ['板金子部品', '形鋼子部品', '親部品購入品'],
    childParts: childParts,

    sum: 5,
    bankin1: 5,
    bankin2: '/5',
    katakou1: 0,
    katakou2: '/0',

    weightVal: 8.681,
    types: 3,
    totalCnt: 5,
    selectedChildPartCnt: 5,
  },
];
const kensaData3 = [
  {
    key: 1,
    kensaType: '子部品点数',
    childPartNm: ['板金子部品', '形鋼子部品', '親部品購入品'],
    childParts: childParts,

    sum: 6,
    bankin1: 6,
    bankin2: '/6',
    katakou1: 0,
    katakou2: '/0',

    weightVal: 6.909,
    types: 6,
    totalCnt: 6,
    selectedChildPartCnt: 6,
  },
];
const yousetsuCondition = ['zerofour-02'];
const KouteiInput = forwardRef((props, ref) => {
  const [value1, setValue1] = useState();
  const [platingValue, setPlatingValue] = useState();
  const [paintingValue, setPaintingValue] = useState();
  const [paintingValue2, setPaintingValue2] = useState();
  const [isDelivery, setIsDelivery] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isHyoumenShori, setIsHyoumenShori] = useState(false);
  const [isAssembly, setIsAssembly] = useState(false);
  const [isPacking, setIsPacking] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [tblSurface, setTblSurface] = useState(0);
  const [version, setVersion] = useState('iq3');

  // add new
  const [addModal, setAddModal] = useState(false);
  const [pmx, setPmx] = useState(yousetsuCondition[0]);

  const [yousetsuTbl1, setYouSetsuTbl1] = useState([]);
  const [hyoumenshoriTable, setHyoumenshoriTable] = useState([]);
  const [kumitateTbl, setKumitateTbl] = useState([]);
  const [kensaTbl, setKensaTbl] = useState([]);
  const [konpouTbl, setKonpouTbl] = useState([]);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [kouteiInputInfo, setKouteiInputInfo] = useState([]);

  // 溶接
  const yousetsu = useRef();

  // 組立
  const kumitate = useRef();

  // 表面処理
  const hyoumenshori = useRef();

  // 検査
  const kensa = useRef();

  // 梱包
  const konpou = useRef();

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    setTblSurface(surfaceData);
    if (props.selectedDataDetail != undefined) {
      if (props.selectedDataDetail.imgNo == 'zerofour-02') {
        // PMX1
        setYouSetsuTbl1(yousetsu1Data2);
        setHyoumenshoriTable(hyoumenshoriData2);
        setKumitateTbl(kumitateData2);
        setKensaTbl(kensaData2);
        setKonpouTbl(kensaData2);
      } else if (props.selectedDataDetail.imgNo == 'zerofour-03') {
        // PMX2
        setYouSetsuTbl1(yousetsu1Data3);
        setHyoumenshoriTable(hyoumenshoriData2);
        setKumitateTbl(kumitateData3);
        setKensaTbl(kensaData3);
        setKonpouTbl(kensaData3);
      } else {
        setYouSetsuTbl1(yousetsu1Data1);
        setHyoumenshoriTable(hyoumenshoriData1);
        setKumitateTbl(kumitateData1);
        setKensaTbl(kensaData1);
        setKonpouTbl(kensaData1);
      }
    }
  }, [props.selectedDataDetail, props.editMode]);

  const openAll = () => {
    setIsWelding(true);
    setIsHyoumenShori(true);
    setIsAssembly(true);
    setIsSurvey(true);
    setIsPacking(true);
  };
  const closeAll = () => {
    setIsWelding(false);
    setIsHyoumenShori(false);
    setIsAssembly(false);
    setIsSurvey(false);
    setIsPacking(false);
  };
  const onChange1 = (e) => {
    setValue1(e.target.value);
  };
  const onChangePlating = (e) => {
    setPlatingValue(e.target.value);
  };
  const onChangePainting = (e) => {
    setPaintingValue(e.target.value);
  };
  const onChangePainting2 = (e) => {
    setPaintingValue2(e.target.value);
  };
  const delivery = () => {
    if (isDelivery == true) {
      setIsDelivery(false);
    } else {
      setIsDelivery(true);
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };
  const hyoumenShori = () => {
    if (isHyoumenShori == true) {
      setIsHyoumenShori(false);
    } else {
      setIsHyoumenShori(true);
    }
  };
  const assembly = () => {
    if (isAssembly == true) {
      setIsAssembly(false);
    } else {
      setIsAssembly(true);
    }
  };
  const packing = () => {
    if (isPacking == true) {
      setIsPacking(false);
    } else {
      setIsPacking(true);
    }
  };
  const survey = () => {
    if (isSurvey == true) {
      setIsSurvey(false);
    } else {
      setIsSurvey(true);
    }
  };
  const setEditModeChange = (mode) => {
    setEditMode(mode);
    yousetsu.current.setEditModeChange(mode);
    kumitate.current.setEditModeChange(mode);
    hyoumenshori.current.setEditModeChange(mode);
    kensa.current.setEditModeChange(mode);
    konpou.current.setEditModeChange(mode);
  };
  const onRdoChange = (e) => {
    setVersion(e.target.value);
  };
  useImperativeHandle(
    ref,
    () => ({
      setEditModeChange: setEditModeChange,
      getKouteiInputInfo: () => {
        return kouteiInputInfo;
      },
    }),
    [kouteiInputInfo]
  );
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };
  const surfaceTreatementTbl1 = (
    <>
      <AntTable
        bordered
        rowSelection
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={tblSurface}
        scroll={{ y: 146, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const surfaceTreatementTbl2 = (
    <>
      <AntTable
        bordered
        rowSelection
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={tblSurface}
        scroll={{ y: 146, x: '10vw' }}
        pagination={false}
      />
    </>
  );

  return (
    <>
      <Row className="mb-2">
        <Col>
          <Space>
            <DownSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={openAll} />
            <UpSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={closeAll} />
          </Space>
        </Col>
      </Row>
      <div className="overflow-auto kouteical timecharge" style={{ height: '78vh' }}>
        {/* 溶接 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isWelding ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>溶接</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isWelding ? 'block' : 'none', margin: '5px' }}>
          <ParentKouteiInput_Yousetsu
            editMode={props.editMode}
            yousetsu1Datas={yousetsuTbl1}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div>

        {/* 表面処理 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={hyoumenShori}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isHyoumenShori ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>表面処理</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isHyoumenShori ? 'block' : 'none', margin: '5px' }}>
          <ParentKouteiInput_Hyoumenshori
            editMode={props.editMode}
            rowdata={hyoumenshoriTable}
            test={hyoumenshoriTable}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div>

        {/* 組立 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={assembly}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isAssembly ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>組立</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isAssembly ? 'block' : 'none', margin: '5px' }}>
          <ParentKouteiInput_Kumitate
            editMode={props.editMode}
            kumitateDatas={kumitateTbl}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div>

        {/* 検査　 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={survey}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isSurvey ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>検査</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isSurvey ? 'block' : 'none', margin: '5px' }}>
          {/* <ParentKouteiInput_Kumitate /> */}
          <ParentKouteiInput_Kensa
            editMode={props.editMode}
            kensaData={kensaTbl}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div>

        {/* 梱包 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={packing}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isPacking ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>梱包</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isPacking ? 'block' : 'none', margin: '5px' }}>
          {/* <ParentKouteiInput_Kumitate /> */}
          <ParentKouteiInput_Konpou
            editMode={props.editMode}
            kensaData={konpouTbl}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div>
      </div>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
});

export default KouteiInput;
