export const parent_deviceMasterList = [
  {
    key: 1,
    type: '親部品',
    deviceNo: 1,
    deviceTypes: '標準',
    code: '001',
    processName: '溶接',
    processType: '溶接',
    deviceCode: '009',
    deviceName: '溶接_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'アルゴン',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '半自動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '溶接線01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '溶接線02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '溶接線03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'YAGハンディ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:02:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'YAGロボット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'ロウ付',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 8,
            detailType: '開先取り',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 9,
            detailType: 'Vカット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '点付',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'プラグ溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '定置スポット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'テーブルスポット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動スタッド',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動スタッド',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ナット溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'ボルト溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2',
          },
          {
            key: 2,
            value: '2',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2',
          },
          {
            key: 2,
            value: '2',
          },
        ],
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '00:00:12',
          },
          {
            key: 2,
            value: '00:00:12',
          },
        ],
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '00:00:18',
          },
          {
            key: 2,
            value: '00:00:18',
          },
        ],
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '80',
          },
          {
            key: 2,
            value: '80',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '160',
          },
          {
            key: 2,
            value: '160',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 2,
    type: '親部品',
    deviceNo: 2,
    deviceTypes: '標準',
    code: '002',
    processName: '溶接仕上',
    processType: '溶接仕上',
    deviceCode: '',
    deviceName: '溶接仕上_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'バフ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '酸洗い',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'サンダー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 3,
    type: '親部品',
    deviceNo: 3,
    deviceTypes: '標準',
    code: '003',
    processName: '表面処理',
    processType: '表面処理',
    deviceCode: '',
    deviceName: '表面処理_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '脱脂',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'ユニクロメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'クロメート',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ニッケルメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'クロームメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '亜鉛メッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'メッキ01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'メッキ02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'メッキ03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'メラニン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'アクリル塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ウレタン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '粉体塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: 'カチオン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'プライマー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '塗装01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '塗装02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 4,
    type: '親部品',
    deviceNo: 4,
    deviceTypes: '標準',
    code: '004',
    processName: '組立',
    processType: '組立',
    deviceCode: '',
    deviceName: '組立_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '購入品取付時間',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '購入品取付時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '購入品取付時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '購入品取付時間',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 5,
    type: '親部品',
    deviceNo: 5,
    deviceTypes: '標準',
    code: '005',
    processName: '検査',
    processType: '検査',
    deviceCode: '',
    deviceName: '検査_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '子部品点数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '子部品種類数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:05:00',
    processTimeByUnit: '00:03:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.25',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 6,
    type: '親部品',
    deviceNo: 6,
    deviceTypes: '標準',
    code: '006',
    processName: '梱包',
    processType: '梱包',
    deviceCode: '',
    deviceName: '梱包_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '子部品点数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '子部品種類数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:05:00',
    processTimeByUnit: '00:05:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 7,
    type: '親部品',
    deviceNo: 7,
    deviceTypes: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '手動工程',
    setupCharge: '2400',
    processCharge: '3600',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:05:00',
    workTime: '00:10:00',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '',
    initialAmount: 0,
  },
  {
    key: 8,
    type: '親部品',
    deviceNo: 8,
    deviceTypes: '標準',
    code: '001',
    processName: '溶接',
    processType: '溶接',
    deviceCode: '',
    deviceName: '溶接_特殊',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'アルゴン',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '半自動',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '溶接線01',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '溶接線02',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '溶接線03',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'YAGハンディ',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:02:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'YAGロボット',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'ロウ付',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 8,
            detailType: '開先取り',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 9,
            detailType: 'Vカット',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '点付',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'プラグ溶接',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '定置スポット',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'テーブルスポット',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動スタッド',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動スタッド',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ナット溶接',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'ボルト溶接',
            displayFlg: true,
            dantori: '6400',
            kakou: '6400',
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '80',
          },
          {
            key: 2,
            value: '80',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '160',
          },
          {
            key: 2,
            value: '160',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '00:00:12',
          },
          {
            key: 2,
            value: '00:00:12',
          },
        ],
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '00:00:18',
          },
          {
            key: 2,
            value: '00:00:18',
          },
        ],
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2',
          },
          {
            key: 2,
            value: '2',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2',
          },
          {
            key: 2,
            value: '2',
          },
        ],
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
  },
  {
    key: 9,
    type: '親部品',
    deviceNo: 9,
    deviceTypes: '標準',
    code: '002',
    processName: '溶接仕上',
    processType: '溶接仕上',
    deviceCode: '',
    deviceName: '溶接仕上_特殊',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'バフ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '酸洗い',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'サンダー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 10,
    type: '親部品',
    deviceNo: 10,
    deviceTypes: '標準',
    code: '003',
    processName: '表面処理',
    processType: '表面処理',
    deviceCode: '',
    deviceName: '表面処理_特殊',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '脱脂',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'ユニクロメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'クロメート',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ニッケルメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'クロームメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '亜鉛メッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'メッキ01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'メッキ02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'メッキ03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'メラニン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'アクリル塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ウレタン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '粉体塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: 'カチオン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'プライマー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '塗装01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '塗装02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 11,
    type: '親部品',
    deviceNo: 11,
    deviceTypes: '標準',
    code: '004',
    processName: '組立',
    processType: '組立',
    deviceCode: '',
    deviceName: '組立_特殊',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '購入品取付時間',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '購入品取付時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '購入品取付時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '購入品取付時間',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 12,
    type: '親部品',
    deviceNo: 12,
    deviceTypes: '標準',
    code: '005',
    processName: '検査',
    processType: '検査',
    deviceCode: '',
    deviceName: '検査_特殊',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '子部品点数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '子部品種類数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:05:00',
    processTimeByUnit: '00:03:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.25',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 13,
    type: '親部品',
    deviceNo: 13,
    deviceTypes: '標準',
    code: '006',
    processName: '梱包',
    processType: '梱包',
    deviceCode: '',
    deviceName: '梱包_特殊',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '子部品点数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '子部品種類数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:05:00',
    processTimeByUnit: '00:05:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1.1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素:1',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素:2',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 3,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 14,
    type: '親部品',
    deviceNo: 14,
    deviceTypes: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_1',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '仮付',
    initialAmount: 0,
  },
  {
    key: 15,
    type: '親部品',
    deviceNo: 15,
    deviceTypes: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_2',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: 'ネスティング',
    initialAmount: 0,
  },
  {
    key: 16,
    type: '親部品',
    deviceNo: 16,
    deviceTypes: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_3',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '水張検査',
    initialAmount: 0,
  },
  {
    key: 17,
    type: '親部品',
    deviceNo: 17,
    deviceTypes: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_4',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '社内工程_4',
    initialAmount: 0,
  },
  {
    key: 18,
    type: '親部品',
    deviceNo: 18,
    deviceTypes: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_5',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '社内工程_5',
    initialAmount: 0,
  },
  {
    key: 19,
    type: '親部品',
    deviceNo: 19,
    deviceTypes: '追加',
    code: '008',
    processName: '追加工程②',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社外工程_1',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '株式会社社外_1',
    customerCode: '00013',
    inputFlag: false,
    itakuFlag: true,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '仮付',
    initialAmount: 0,
  },
  {
    key: 20,
    type: '親部品',
    deviceNo: 20,
    deviceTypes: '追加',
    code: '008',
    processName: '追加工程②',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社外工程_2',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: 'ネスティング',
    initialAmount: 0,
  },
  {
    key: 21,
    type: '親部品',
    deviceNo: 21,
    deviceTypes: '追加',
    code: '008',
    processName: '追加工程②',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社外工程_3',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査', '社内工程_4', '社内工程_5'],
    customerName: '株式会社社外_3',
    customerCode: '00014',
    inputFlag: false,
    itakuFlag: true,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
          {
            key: 4,
            detailType: '社内工程_4',
            displayFlg: true,
          },
          {
            key: 5,
            detailType: '社内工程_5',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '水張検査',
    initialAmount: 0,
  },
];
export const quantityMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
  { id: 3, val: 3, displayFlag: true },
];
