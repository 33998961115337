/**
 * クラス名：マイン見積
 * 説明：親部品見積・子部品見積纏めJSファイルや共通操作ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Form, Button, Row, Input, Col, Modal, Image, Tooltip, Space, Radio, Tabs, Menu, Upload, Checkbox } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { CSVLink, CSVDownload } from 'react-csv';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '../../assets/styles/common.css';
import new_icon from '../../assets/images/new_icon.png';
import calculator from '../../assets/images/calculator.png';
import save from '../../assets/images/save.png';
import pc_save from '../../assets/images/pc_save.png';
import call_detail from '../../assets/images/call_detail.png';
import pmx_import from '../../assets/images/pmx_import.png';
import reference_price from '../../assets/images/reference_price.png';
import export_report from '../../assets/images/export_report.png';
import IQ3Estimate from '../iq3/IQ3Estimate';
import ParentEstimate from '../parent/ParentEstimate';
import EstimateBasicInfo from '../parent/EstimateBasicInfo';
import ParentKouteiSentaku from '../parent/ParentKouteiSentaku';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
import { getTenjikaiSampleData, getNewParentInfo } from '../common/Common';
import {
  commonTaiochuModal,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
} from '../common/CommonModal';
import confirm_icon from '../../assets/images/confirm_icon.png';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const iconWidth = 32;

const MainEstimate = React.forwardRef((props, ref) => {
  const [activePartMenu, setActivePartMenu] = useState('');
  // PMX工程選択ダイアログ表示
  const [isPMXProcessPatternOpen, setIsPMXProcessPatternOpen] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [selectediq3Data, setSelectediq3Data] = useState([]);
  const [estimateBasicInfo, setEstimateBasicInfo] = useState([]);
  const [confirmScreenChangeModalOpen, setConfirmScreenChangeModalOpen] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [clickAddNewModal, setClickAddNewModal] = useState(false);
  const [clickPMX, setClickPMX] = useState(false);
  const [pMXFileName, setPMXFileName] = useState('zerofour-02.STEP.SB-E_WELD_v2023-1-7.PMX');
  const [clickMenu, setClickMenu] = useState('');

  // file dialog
  const inputFile = useRef();
  const iQ3EstimateRef = useRef();
  const parentEstimateRef = useRef();
  const estimateBasicInfoRef = useRef();

  const inputFile_pcInput = useRef(null);

  const inputFile_pcOutput = useRef(null);

  // 帳票出力
  const [isReportOutputOpen, setIsReportOutputOpen] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  // pc保存
  const [isEstimateDataSaveConfirm, setIsEstimateDataSaveConfirm] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let costPrice = 0.0;
    let totalCostPrice = 0.0;
    let estimatedAmount = 0.0;
    let totalEstimatedAmount = 0.0;
    let costProfit = 0.0;
    let estimateProfit = 0.0;
    let materialCost = 0.0;
    let processCost = 0.0;
    let purchaseCost = 0.0;
    let estimateDataProps = props.selectedEstimateData;
    setSelectedEstimateData(estimateDataProps);

    let iq3Data = props.selectedEstimateData != undefined ? props.selectedEstimateData.iq3DataLst : [];
    setSelectediq3Data(iq3Data);
    if (estimateDataProps != null && iq3Data != null) {
      costPrice = parseFloat(estimateDataProps.costPrice);
      totalCostPrice = parseFloat(estimateDataProps.totalCostPrice);
      estimatedAmount = parseFloat(estimateDataProps.estimatedAmount);
      totalEstimatedAmount = parseFloat(estimateDataProps.totalEstimatedAmount);
      costProfit = parseFloat(estimateDataProps.costProfit);
      estimateProfit = parseFloat(estimateDataProps.estimateProfit);
      materialCost = parseFloat(estimateDataProps.materialCost);
      processCost = parseFloat(estimateDataProps.processCost);
      purchaseCost = parseFloat(estimateDataProps.purchaseCost);
    }
    const estimateBasicInfo = {
      estimateImgNo: estimateDataProps.imgNo,
      estimateNm: estimateDataProps.name,
      customerNm: estimateDataProps.customerNm,
      weight: estimateDataProps.childPartTotalWeight, // 確認
      surfaceArea: estimateDataProps.surfaceArea, // 確認
      volume:
        estimateDataProps.volume != undefined
          ? estimateDataProps.volume.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0, // 確認
      creator: estimateDataProps.creator,
      estimatedDate: estimateDataProps.createdDate,
      deliveryDate: estimateDataProps.dateLst != undefined ? estimateDataProps.dateLst[2].data : '',
      partCnt: estimateDataProps.lotNum,

      costPrice: costPrice.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      totalCostPrice: totalCostPrice.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      estimatedAmount: estimatedAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      totalEstimatedAmount: totalEstimatedAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      costProfit: costProfit.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      estimateProfit:
        estimateProfit != undefined
          ? estimateProfit.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0,
      profitRate: estimateDataProps.rate,

      materialCost: materialCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      processCost: processCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      purchaseCost: purchaseCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),

      customerCoefficient: estimateDataProps.customerCoefficient,
      managementCoefficient: estimateDataProps.managementCoefficient,
      materialLossCoefficient: estimateDataProps.materialLossCoefficient,
      orderForm: estimateDataProps.orderCoefficient,
      setupCoefficient: estimateDataProps.setupCoefficient,
      surfaceAreaCoefficient: estimateDataProps.surfaceAreaCoefficient,
      purchaseCoefficient: estimateDataProps.purchaseCoefficient,
      deliveryCoefficient: estimateDataProps.deliveryCoefficient,
      comment: estimateDataProps.comment,
      hiyou: estimateDataProps.hiyou,
    };
    setEstimateBasicInfo(estimateBasicInfo);
    setActivePartMenu(props.activeTab);
  }, [props.selectedEstimateData]);

  useEffect(() => {
    if (activePartMenu === '1') {
      navigate(':estimate.parent');
    } else if (activePartMenu === '2') {
      navigate(':estimate.iq3');
    }
  }, [activePartMenu]);

  //編集モード切替により呼び出す
  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  const openPMXFileDialog = () => {
    setClickPMX(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      inputFile.current.click();
    }
  };

  const openFileDialog_pcInput = () => {
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      inputFile_pcInput.current.click();
    }
  };

  const openFileDialog_pcOutput = () => {
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      inputFile_pcOutput.current.click();
    }
  };

  const openPMXProcessPatternDialog = (e) => {
    let targetFileNm = e.target.files[0].name;
    setPMXFileName(targetFileNm);
    setIsPMXProcessPatternOpen(true);
  };

  const openDialogOK = (e) => {
    e.target.value = null;
    // let targetFileNm = e.target.files[0].name;
    // setPMXFileName(targetFileNm);
    // setIsPMXProcessPatternOpen(true);
  };

  const importPMXFile = () => {
    let costPrice = 0.0;
    let totalCostPrice = 0.0;
    let estimatedAmount = 0.0;
    let totalEstimatedAmount = 0.0;
    let costProfit = 0.0;
    let estimateProfit = 0.0;
    let materialCost = 0.0;
    let processCost = 0.0;
    let purchaseCost = 0.0;
    setIsNext(false);
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
    const tenjikaiSampleData = getTenjikaiSampleData();
    let listData = [];
    if (pMXFileName.includes('zerofour-02')) {
      listData = tenjikaiSampleData.parentList_PMX_1;
    } else {
      listData = tenjikaiSampleData.parentList_PMX_2;
    }
    let estimateDataProps = listData[0];
    setSelectedEstimateData(estimateDataProps);
    setSelectediq3Data(estimateDataProps.iq3DataLst);
    if (estimateDataProps != null) {
      costPrice = parseFloat(estimateDataProps.costPrice);
      totalCostPrice = parseFloat(estimateDataProps.totalCostPrice);
      estimatedAmount = parseFloat(estimateDataProps.estimatedAmount);
      totalEstimatedAmount = parseFloat(estimateDataProps.totalEstimatedAmount);
      costProfit = parseFloat(estimateDataProps.costProfit);
      estimateProfit = parseFloat(estimateDataProps.estimateProfit);
      materialCost = parseFloat(estimateDataProps.materialCost);
      processCost = parseFloat(estimateDataProps.processCost);
      purchaseCost = parseFloat(estimateDataProps.purchaseCost);
    }
    const estimateBasicInfo = {
      estimateImgNo: estimateDataProps.imgNo,
      estimateNm: estimateDataProps.name,
      customerNm: estimateDataProps.customerNm,
      weight: estimateDataProps.childPartTotalWeight, // 確認
      surfaceArea: estimateDataProps.surfaceArea, // 確認
      volume:
        estimateDataProps.volume != undefined
          ? estimateDataProps.volume.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0, // 確認
      creator: estimateDataProps.creator,
      estimatedDate: estimateDataProps.createdDate,
      deliveryDate: estimateDataProps.dateLst != undefined ? estimateDataProps.dateLst[2].data : '',
      partCnt: estimateDataProps.lotNum,

      costPrice: costPrice.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      totalCostPrice: totalCostPrice.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      estimatedAmount: estimatedAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      totalEstimatedAmount: totalEstimatedAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      costProfit: costProfit.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      estimateProfit:
        estimateProfit != undefined
          ? estimateProfit.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0,
      profitRate: estimateDataProps.rate,

      materialCost: materialCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      processCost: processCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      purchaseCost: purchaseCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),

      customerCoefficient: estimateDataProps.customerCoefficient,
      managementCoefficient: estimateDataProps.managementCoefficient,
      materialLossCoefficient: estimateDataProps.materialLossCoefficient,
      orderForm: estimateDataProps.orderCoefficient,
      setupCoefficient: estimateDataProps.setupCoefficient,
      surfaceAreaCoefficient: estimateDataProps.surfaceAreaCoefficient,
      purchaseCoefficient: estimateDataProps.purchaseCoefficient,
      deliveryCoefficient: estimateDataProps.deliveryCoefficient,
      comment: estimateDataProps.comment,
      hiyou: estimateDataProps.hiyou,
    };
    setEstimateBasicInfo(estimateBasicInfo);
    updateEstimateInfo(estimateBasicInfo);
  };

  const updateEstimateInfo = (estimateInfo) => {
    estimateBasicInfoRef.current.updateEstimateInfo(estimateInfo);
  };

  const showAddNewModal = () => {
    setClickAddNewModal(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmScreenChangeModalOpen(false);
      setConfirmDisplayDataSaveModalOpen(true);

      //
      // setSelectedEstimateData([]);
      // setSelectediq3Data([]);
      // if (activePartMenu === '1') {
      //   navigate(':estimate.parent');
      // } else if (activePartMenu === '2') {
      //   navigate(':estimate.iq3');
      // }
    }
  };
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const discardConfirmOk = () => {
    setConfirmScreenChangeModalOpen(false);
    setConfirmDisplayDataSaveModalOpen(false);
    setEditMode(false);
    props.updateEstimateEditMode(false);
    if (clickAddNewModal) {
      setClickAddNewModal(false);
      setSelectedEstimateData(getNewParentInfo(0));
      setSelectediq3Data([]);
    } else if (clickPMX) {
      setClickPMX(false);
      inputFile.current.click();
    } else {
      // setSelectedEstimateData([]);
      // setSelectediq3Data([]);
      // console.log('discard ok--', location.pathname);
    }
    if (clickMenu === '1') {
      setActivePartMenu('1');
      navigate(':estimate.parent');
    } else if (clickMenu === '2') {
      setActivePartMenu('2');
      navigate(':estimate.iq3');
    }
  };

  const discardConfirmCancel = () => {
    setConfirmScreenChangeModalOpen(false);
    setConfirmDisplayDataSaveModalOpen(false);
    setClickPMX(false);
    setClickAddNewModal(false);
  };
  const updateMainEstimateEditMode = (mode) => {
    setEditMode(mode);
    props.updateEstimateEditMode(mode);
  };

  const partMenuChange = (e) => {
    setClickMenu(e);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmScreenChangeModalOpen(false);
      setActivePartMenu(e);
      if (e === '1') {
        navigate(':estimate.parent');
      } else if (e === '2') {
        navigate(':estimate.iq3');
      }
    }
  };

  // 帳票出力
  const reportOutputChangeEvent = (e) => {
    if (e.key == 'listReport') {
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票');
    } else if (e.key == 'parentReport') {
      setIsReportOutputOpen(true);
      setChildTitle('親部品帳票');
    }
  };

  // 別名保存・上書き保存
  const estimateDataSaveEvent = (e) => {
    setIsEstimateDataSaveConfirm(true);
    setChildTitle('確認');
  };

  const childModalTitle = (
    <div
      style={{
        width: 1490,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>
        {isNext == false ? '親部品工程選択' : '板金工程選択'}
      </p>
    </div>
  );
  const updateOk = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
  };

  const updateCancel = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
  };
  const cancelPMXImport = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
  };
  const nextProcessPattern = () => {
    setIsNext(true);
  };
  const backProcessPattern = () => {
    setIsNext(false);
  };
  const reportOutputOk = () => {
    setIsReportOutputOpen(false);
    setIsEstimateDataSaveConfirm(false);
  };
  const reportOutputCancel = () => {
    setIsReportOutputOpen(false);
    setIsEstimateDataSaveConfirm(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      getSelectedEstimateData: () => {
        return selectedEstimateData;
      },
    }),
    [selectedEstimateData, editMode]
  );

  const tabItems = [
    {
      key: '1',
      label: (
        <Button
          key="parent-tab"
          type="primary"
          className={activePartMenu == '1' ? 'estimate-tab-btn-active first-btn' : 'estimate-tab-btn first-btn'}
        >
          親部品
        </Button>
      ),
      children: (
        <ParentEstimate
          editMode={editMode}
          ref={parentEstimateRef}
          selectedEstimateData={selectedEstimateData}
          updateMainEstimateEditMode={updateMainEstimateEditMode}
          activePartMenu={activePartMenu}
        />
      ),
    },
    {
      key: '2',
      label: (
        <Button
          key="iq3-tab"
          type="primary"
          className={activePartMenu == '2' ? 'estimate-tab-btn-active last-btn' : 'estimate-tab-btn last-btn'}
        >
          iQ3板金
        </Button>
      ),
      children: (
        <IQ3Estimate
          editMode={editMode}
          ref={iQ3EstimateRef}
          updateEstimateInfo={updateEstimateInfo}
          iq3Data={selectediq3Data}
          selectedEstimateData={selectedEstimateData}
          updateMainEstimateEditMode={updateMainEstimateEditMode}
          targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
          activePartMenu={activePartMenu}
        />
      ),
    },
    // {
    //   key: '3',
    //   label: `iQ5形鋼`,
    //   children: (
    //     <IQ3Estimate
    //       editMode={editMode}
    //       ref={iQ3EstimateRef}
    //       updateEstimateInfo={updateEstimateInfo}
    //       iq3Data={selectediq3Data}
    //       selectedEstimateData={selectedEstimateData}
    //       childEditMode={updateMainEstimateEditMode}
    //       targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
    //     />
    //   ),
    // },
    // ,
    // {
    //   key: '4',
    //   label: `iQ7機械`,
    //   children: (
    //     <IQ3Estimate
    //       editMode={editMode}
    //       ref={iQ3EstimateRef}
    //       updateEstimateInfo={updateEstimateInfo}
    //       iq3Data={selectediq3Data}
    //       selectedEstimateData={selectedEstimateData}
    //       childEditMode={updateMainEstimateEditMode}
    //       targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
    //     />
    //   ),
    // },
  ];

  const saveMenuItems = [
    {
      label: <Image preview={false} width={33} src={save} style={{ marginLeft: '0px' }}></Image>,
      key: 'save',
      children: [
        {
          label: '別名保存',
          key: 'otherNmSave',
        },
        {
          label: '上書き保存',
          key: 'overwrite',
        },
      ],
    },
  ];

  const reportOutputMenuitems = [
    {
      label: <Image preview={false} width={28} src={export_report} style={{ marginLeft: '25px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: 'リスト帳票',
          key: 'listReport',
        },
        {
          label: '親部品帳票',
          key: 'parentReport',
        },
      ],
    },
  ];

  const reportModalTitle = (
    <div
      style={{
        width: 550,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image
        preview={false}
        width={20}
        src={confirm_icon}
        style={{ marginTop: 9, marginLeft: 7, display: isEstimateDataSaveConfirm ? '' : 'none' }}
      ></Image>
      <p style={{ paddingTop: 8, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 帳票出力
  const reportParentList = (
    <>
      <Row style={{ marginTop: 25 }}>
        <Space size="middle">
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力元ファイル</p>
          <Input style={{ width: 300 }} />
          <Upload>
            <Button style={{ fontSize: '13.5px' }}>参照</Button>
          </Upload>
        </Space>
      </Row>
      <Row style={{ marginTop: '17px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={reportOutputOk} className="mainButton" id="update">
            出力
          </Button>

          <Button onClick={reportOutputCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            破棄
          </Button>
        </Col>
      </Row>
    </>
  );

  // PC保存
  const estimateDataSaveContent = (
    <>
      <Row style={{ marginTop: 0 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          パラメータが更新されています。最新を使用する項目を選択して下さい
        </p>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>材料費</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>装置のチャージ金額/係数</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>購入品</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={reportOutputOk} className="mainButton" id="update">
            再計算
          </Button>

          <Button onClick={reportOutputCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <Form>
        {/** 共通操作 */}
        <div
          style={{
            borderBottom: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: '#212529',
            backgroundColor: '#fafafc',
          }}
        >
          <Row style={{ marginLeft: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <Row style={{ marginTop: '1px', marginLeft: '0' }}>
                <Col span={9}>
                  <Space size="middle">
                    <Tooltip title="新規作成" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={showAddNewModal}>
                        <Image preview={false} width={iconWidth} src={new_icon} style={{ marginLeft: '0px' }}></Image>
                      </a>
                    </Tooltip>

                    <Tooltip title="再計算" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={estimateDataSaveEvent}>
                        <Image preview={false} width={iconWidth} src={calculator} style={{ marginTop: '2px' }}></Image>
                      </a>
                    </Tooltip>

                    <Tooltip title="保存" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={commonTaiochuPopupOpen}
                        mode="horizontal"
                        items={saveMenuItems}
                        style={{
                          marginLeft: '-19px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                    </Tooltip>

                    <Tooltip title="PC保存" placement="bottom" overlayClassName="tooltip-text">
                      {/* <CSVLink filename={'Parameter_取引先.csv'} preview={true} data={customersData}> */}
                      <a onClick={openFileDialog_pcOutput}>
                        <Image preview={false} width={28} src={pc_save} style={{ marginLeft: '10px' }}></Image>
                      </a>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile_pcOutput}
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={(e) => openDialogOK(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                      {/* </CSVLink> */}
                    </Tooltip>
                    <Tooltip title="PC呼出" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={openFileDialog_pcInput}>
                        <Image preview={false} width={28} src={call_detail} style={{ marginLeft: '15px' }}></Image>
                      </a>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile_pcInput}
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={(e) => openDialogOK(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Tooltip>
                    <Tooltip title="PMX取込" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={openPMXFileDialog}>
                        <Image
                          preview={false}
                          width={28}
                          src={pmx_import}
                          style={{ marginLeft: '20px', marginTop: '0px' }}
                        ></Image>
                      </a>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        accept=".PMX"
                        onChange={(e) => openPMXProcessPatternDialog(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Tooltip>
                    <Tooltip title="帳票" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={reportOutputMenuitems}
                        style={{
                          marginLeft: '-19px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="備考価格" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={commonTaiochuPopupOpen}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={reference_price}
                          style={{ marginLeft: '25px', marginTop: '0px' }}
                        ></Image>
                      </a>
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
        {/** 親部品・板金子部品・形鋼子部品 */}
        <div>
          <Row>
            <Col span={19} style={{ borderRight: '1px solid #212529' }}>
              <Tabs
                className="estimate-tab"
                type="card"
                defaultActiveKey="1"
                activeKey={activePartMenu}
                items={tabItems}
                onChange={partMenuChange}
              />
            </Col>
            <Col span={5}>
              {/* 見積基本情報 */}
              <EstimateBasicInfo estimateBasicInfo={estimateBasicInfo} ref={estimateBasicInfoRef} />
            </Col>
          </Row>
        </div>
      </Form>
      {/* PMX用工程選択画面　*/}
      <Modal
        maskClosable={false}
        title={childModalTitle}
        open={isPMXProcessPatternOpen}
        onOk={updateOk}
        onCancel={updateCancel}
        width={1490}
        className="updateModalStyle"
        bodyStyle={{ height: 762 }}
        style={{ marginTop: '-51px' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={[
          <Button key="cancel" onClick={cancelPMXImport} className="cancelButton">
            {'キャンセル'}
          </Button>,
          <Button key="back" onClick={backProcessPattern} className="cancelButton">
            {'< 戻る(B)'}
          </Button>,
          <Button key="next" type="primary" onClick={nextProcessPattern} className="cancelButton">
            {'次へ(N) >'}
          </Button>,
          <Button key="ok" onClick={importPMXFile} className={isNext == false ? 'disabledButton' : 'mainButton'}>
            {'完了(F)'}
          </Button>,
        ]}
      >
        {isNext == false ? (
          <ParentKouteiSentaku editMode={true} selectedDataDetail={selectedEstimateData} pmxMode={true} />
        ) : (
          <IQ3KouteiSentaku
            editMode={true}
            selectedIQ3DataDetail={selectediq3Data != undefined && selectediq3Data.length > 0 ? selectediq3Data[0] : []}
            pmxMode={true}
            iQ3DataLst={selectediq3Data}
          />
        )}
      </Modal>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
      {/** 画面切替警告メッセージ */}
      {confirmScreenChangeModalOpen
        ? commonModal(
            confirmScreenChangeModalOpen,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/* 帳票出力 */}
      {isReportOutputOpen
        ? commonModal(
            isReportOutputOpen,
            reportModalTitle,
            null,
            { height: 80 },
            550,
            reportOutputOk,
            reportOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/* PC保存確認 */}
      {isEstimateDataSaveConfirm
        ? commonModal(
            isEstimateDataSaveConfirm,
            reportModalTitle,
            null,
            { height: 120 },
            550,
            reportOutputOk,
            reportOutputCancel,
            estimateDataSaveContent,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDisplayDataSaveContent(discardConfirmOk, discardConfirmCancel),
            null,
            null,
            false
          )
        : ''}
    </div>
  );
});

export default MainEstimate;
