/**
 * クラス名：板金のバリ取とブランク種類画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
const Device_ProcessType = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [processame, setProcessName] = useState('');
  const [processMethod, setProcessMethod] = useState([]);
  useEffect(() => {
    setProcessName(props.processMethod);
    setProcessMethod(props.processMethods);
    setEditMode(props.editMode);
  }, [props.processMethod, props.processMethods, props.editMode]);
  const changeType = (e) => {
    console.log(e);
    setProcessName(e);
    props.updatedProcessMethod(e);
  };
  return (
    <>
      <Row style={{ marginLeft: 10 }} className="chargeAmount">
        <Col style={{ width: '20%' }}>
          <Table>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th className="tbl-header">バリ取種類</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  {/* <Select className="yousetsu-select"></Select> */}
                  <Select
                    style={{ pointerEvents: 'none' }}
                    className="yousetsu-select"
                    value={processame}
                    onChange={(e) => {
                      changeType(e);
                    }}
                  >
                    {processMethod.map((item) => (
                      <option value={item.detailType}>{item.detailType}</option>
                    ))}
                  </Select>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
});

export default Device_ProcessType;
