/**
 * クラス名：工程パターンフォーム画面
 * 説明：親部品・板金の共通使う工程パターンの詳細画面の子JSファイルである。
 * 作成者：ナンス
 * 作成日：2023/04/01
 * バージョン：1.0
 */
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Select, Input, Checkbox, Radio, Tag, Button, Row, Col, List, Carousel } from 'antd';
import { SearchOutlined, LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  patternPage,
  CarouselNextArrow,
  CarouselPrevArrow,
  groupBy,
  today,
  initialCarouselBtn,
} from '../../../common/Common';
import { commonTaiochuModal } from '../../../common/CommonModal';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData';
import { paramCommonCustomer } from '../../../common/Constant';
import { ItakuSaki, itakuSaki } from '../../../common/Constant';
import { getClientInfo } from '../../../common/CommonAPI';
import { ClientType } from '../../../common/enums';

const RadioGroup = Radio.Group;
const { Search } = Input;

const ProcessPatternForm = forwardRef((props, ref) => {
  const tableRef = useRef(null);
  const space = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
  const [listData, setListData] = useState([]);
  const [patternList, setPatternList] = useState([]);
  const [patternNo, setPatternNo] = useState('');
  const [patternName, setPatternName] = useState('');
  const [selectedPatternInfo, setSelectedPatternInfo] = useState([]);
  const [selectedPatternInfoBk, setSelectedPatternInfoBk] = useState([]);
  // 追加工程
  const [additionInfo, setAdditionInfo] = useState([]);
  const [weldingSochiList, setWeldingSochiList] = useState([]);
  const [weldFinishSochiList, setWeldFinishSochiList] = useState([]);
  const [surfaceSochiList, setSurfaceSochiList] = useState([]);
  const [assemblySochiList, setAssemblySochiList] = useState([]);
  const [packingSochiList, setPackingSochiList] = useState([]);
  const [testProSochiList, setTestProSochiList] = useState([]);
  // IQ3
  const [programProSochiList, setProgramProSochiList] = useState([]);
  const [nijiProSochiList, setNijiProSochiList] = useState([]);
  const [bendingProSochiList, setBendingProSochiList] = useState([]);
  const [shearingProSochiList, setShearingProSochiList] = useState([]);

  const [partInfo, setPartInfo] = useState(1);

  const [weldFinishProcessLst, setWeldFinishProcessLst] = useState([]);
  const [weldFinishProcessTypeLst, setWeldFinishProcessTypeLst] = useState([]);
  const [weldFinishProcessCnt, setWeldFinishProcessCnt] = useState(0);

  const [surfaceProcessLst, setSurfaceProcessLst] = useState([]);
  const [surfaceProcessTypeLst, setSurfaceProcessTypeLst] = useState([]);
  const [surfaceProcessCnt, setSurfaceProcessCnt] = useState(0);

  const [surfaceProcessTypeVal1Lst, setSurfaceProcessTypeVal1Lst] = useState([]);
  const [surfaceProcessTypeVal2Lst, setSurfaceProcessTypeVal2Lst] = useState([]);
  const [surfaceProcessTypeVal3Lst, setSurfaceProcessTypeVal3Lst] = useState([]);
  const [surfaceProcessTypeVal0Lst, setSurfaceProcessTypeVal0Lst] = useState([]);

  const [customProcessLst, setCustomProcessLst] = useState([]);
  const [blankProcessList, setBlankProcessLst] = useState([]);
  const [deburrProcessList, setDeburrProcessLst] = useState([]);

  const [customerModal, setCustomerModal] = useState(false);
  const [clientInfo, setClientInfo] = useState([]);

  const inputFile = useRef(null);

  const weldFinishProcList = [{ value: '000', label: '無し' }];
  const surfaceProcList = [
    { value: '0', label: '無し' },
    { value: '1', label: '処理全般' },
    { value: '2', label: 'メッキ' },
    { value: '3', label: '片面塗装' },
    { value: '4', label: '両面塗装' },
  ];
  const surfaceProc0List = [{ value: '000', label: 'ー' }];
  const surfaceProc1lList = [];
  const surfaceProc2lList = [];
  const surfaceProc3lList = [];

  // IQ3工程
  const [program, setProgram] = useState('');
  const [programChk, setProgramChk] = useState('');
  const [secondary, setSecondary] = useState('');
  const [secondaryChk, setSecondaryChk] = useState('');
  const [bending, setBending] = useState('');
  const [bendingChk, setBendingChk] = useState('');
  const [shearing, setShearing] = useState('');
  const [shearingChk, setShearingChk] = useState('');

  const [welding, setWelding] = useState('');
  const [weldingChk, setWeldingChk] = useState('');
  const [weldFinish, setWeldFinish] = useState('');
  const [weldFinishChk, setWeldFinishChk] = useState('');
  const [surface, setSurface] = useState('');
  const [surfaceChk, setSurfaceChk] = useState('');
  const [assembly, setAssembly] = useState('');
  const [assemblyChk, setAssemblyChk] = useState('');
  const [packing, setPacking] = useState('');
  const [packingChk, setPackingChk] = useState('');
  const [test, setTest] = useState('');
  const [testChk, setTestChk] = useState('');
  const [iq3PartList, setIq3PartList] = useState('');

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  // 追加工程のお客様情報設定用変数
  const [addProKey, setAddProKey] = useState(0);
  const [addProDeviceCode, setAddProDeviceCode] = useState(0);
  const [addProCarouselIndex, setAddProCarouselIndex] = useState(0);
  const [addProIndex, setAddProIndex] = useState(0);

  // 編集モード
  const editMode = props.editMode;

  // 呼び出す画面名
  const { page } = props;

  const caroselRef = useRef();

  const token = localStorage?.getItem('iQxWeb_AccessToken');

  // 標準工程／追加工程の表示設定
  const renderPartComponent = () => {
    return standardItem;
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const [targetKeys, setTargetKeys] = useState();
  const [carousel, setCarousel] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState('');

  // 一覧にあるかどうかチェック
  const isTargetKey = (inputValue) => {
    var isExist = targetKeys.some((key) => {
      if (key === inputValue) return true;
      else return false;
    });
    return isExist;
  };

  // 画面の初期設定
  useEffect(() => {
    if (props.selectedData == undefined && props.selectedData == null) return;
    setSelectedPatternInfo(JSON.parse(JSON.stringify(props.selectedData)));
    setSelectedPatternInfoBk(JSON.parse(JSON.stringify(props.selectedData)));
    if (page === patternPage[1] && !props.isParameter) {
      setIq3PartList(props.iq3PartList);
      setPartInfo(props.childPartNo);
    }

    console.log('patternform--', props.selectedData);
    // 工程パターンリスト情報取得
    if (props.listData != undefined && props.listData.length > 0) getPatternList(props.listData);
    if (props.selectedData != undefined && props.selectedData.length == 0) {
      setProgramChk(false);
      setProgram('');
      setBlankProcessLst([]);
      setSecondaryChk(false);
      setSecondary('');
      setBendingChk(false);
      setBending('');
      setDeburrProcessLst([]);
      setShearingChk(false);
      setShearing([]);
      setWeldingChk(false);
      setWelding('');
      setWeldFinishChk(false);
      setWeldFinish('');
      setWeldFinishProcessLst([]);
      setSurfaceChk(false);
      setSurface([]);
      setSurfaceProcessLst([]);
      setAssemblyChk(false);
      setAssembly('');
      setTestChk(false);
      setTest('');
      setCustomProcessLst([]);
      setPackingChk(false);
      setPacking('');
      setPatternNo('');
    } else {
      settingProcessPattern(props.selectedData);
    }

    // initialCarouselBtn();
  }, [props.selectedData, props.listData, props.childPartNo]);

  const settingProcessPattern = (selectedData) => {
    let processMasterData = [];
    let sochiList = [];
    let weldProcessCode = '001';
    let weldFinishProcessCode = '002';
    let surfaceProcessCode = '003';
    let kumitateProcessCode = '004';
    let kensaProcessCode = '005';
    let customProcessCode = '007';
    let konpoProcessCode = '007';
    const kouteiMasterData =
      localStorage.getItem('kouteiMasterData') != undefined ? JSON.parse(localStorage.getItem('kouteiMasterData')) : [];

    //工程マスターデータにより種類選択ボックスデータ準備
    if (page === patternPage[0]) {
      const sochiMasterData =
        localStorage.getItem('parent_devicesMaster') != undefined
          ? JSON.parse(localStorage.getItem('parent_devicesMaster'))
          : [];
      processMasterData = kouteiMasterData.filter((item) => item.type == '親部品');
      sochiList = sochiMasterData.filter((item) => item.type == '親部品');
    } else if (page === patternPage[1]) {
      const sochiMasterData =
        localStorage.getItem('iq3_devicesMaster') != undefined
          ? JSON.parse(localStorage.getItem('iq3_devicesMaster'))
          : [];
      processMasterData = kouteiMasterData.filter((item) => item.type == 'iQ3');
      sochiList = sochiMasterData.filter((item) => item.type == 'iQ3');
    }
    if (selectedData !== null && processMasterData !== null) {
      // IQ3工程設定
      if (page === patternPage[1]) {
        settingIQ3Process(processMasterData, sochiList);
      }
      // 下記共通工程設定
      //選択された工程パータンの溶接装置情報取得
      const selectedWeld = selectedData?.patternProcess?.filter((item) => item.processType == '溶接');
      if (selectedWeld !== undefined && selectedWeld.length > 0) {
        setWeldingChk(selectedWeld[0].checked);
        setWelding(selectedWeld[0].deviceCodeKey);
      }

      // 選択ボックスの溶接装置一覧取得
      const weldCodeFilter = processMasterData?.filter((item) => item.processType == '溶接');
      if (weldCodeFilter.length > 0) weldProcessCode = weldCodeFilter[0].code;
      const weldSochiTypeLst = sochiList.filter((item) => item.code == weldProcessCode);
      let weldSochiLst = [];
      for (let j = 0; j < weldSochiTypeLst.length; j++) {
        weldSochiLst.push({
          value: weldSochiTypeLst[j].key,
          label: weldSochiTypeLst[j].deviceName,
        });
      }
      setWeldingSochiList(weldSochiLst);

      //選択された工程パータンの溶接仕上装置情報取得
      const weldFinishNm = selectedData?.patternProcess?.filter((item) => item.processType == '溶接仕上');
      if (weldFinishNm !== undefined && weldFinishNm.length > 0) {
        setWeldFinishChk(weldFinishNm[0].checked);
        setWeldFinish(weldFinishNm[0].deviceCodeKey);
      }

      // 選択ボックスの溶接仕上装置一覧取得
      const weldFinishCodeFilter = processMasterData?.filter((item) => item.processType == '溶接仕上');
      if (weldFinishCodeFilter.length > 0) weldFinishProcessCode = weldFinishCodeFilter[0].code;
      const weldFinishSochiTypeLst = sochiList.filter((item) => item.code == weldFinishProcessCode);
      let weldFinishSochiLst = [];
      for (let j = 0; j < weldFinishSochiTypeLst.length; j++) {
        weldFinishSochiLst.push({
          value: weldFinishSochiTypeLst[j].key,
          label: weldFinishSochiTypeLst[j].deviceName,
        });
      }
      setWeldFinishSochiList(weldFinishSochiLst);

      //溶接仕上種類マスターデータ取得
      const weldFinishType = processMasterData.filter((item) => item.processType == '溶接仕上');
      const weldFinishProcessCnt = weldFinishType[0]?.numberOfProcess;
      setWeldFinishProcessCnt(weldFinishProcessCnt);
      for (let i = 0; i < weldFinishType[0].processDetail[0].processDetailValue.length; i++) {
        if (weldFinishType[0].processDetail[0].processDetailValue[i].displayFlg) {
          weldFinishProcList.push({
            value: weldFinishType[0].processDetail[0].processDetailValue[i].detailCode,
            label: weldFinishType[0].processDetail[0].processDetailValue[i].detailType,
          });
        }
      }
      setWeldFinishProcessTypeLst(weldFinishProcList);
      // 溶接仕上処理一覧データ準備
      const weldFinish = selectedData?.patternProcess?.filter((item) => item.processType == '溶接仕上');
      if (weldFinish !== undefined && weldFinish.length > 0) {
        const weldFinishProcLst = [];
        const weldFinishProcLstDisplay = [];
        let key = 0;
        let divider = 3; // 1div4処理表示のため
        let lastIndex = 0; //  4処理をdiv分けて設定するためのインデックス
        let weldFinishProFilter = [];
        for (let i = 0; i < weldFinishProcessCnt; i++) {
          const selectedWeldFinishDetailProcessLst = weldFinish[0].processDetailLst.filter((item) => item.key == i);
          weldFinishProcLst.push({
            key: i,
            title: `第${i + 1}処理`,
            processDetailType:
              selectedWeldFinishDetailProcessLst.length > 0
                ? selectedWeldFinishDetailProcessLst[0].processDetailType
                : weldFinishProcList[0].label,
            processDetailCode:
              selectedWeldFinishDetailProcessLst.length > 0
                ? selectedWeldFinishDetailProcessLst[0].processDetailCode
                : weldFinishProcList[0].value,
            processDetailValLst: weldFinishProcList,
          });

          // carouselのdivデータ準備（1div4処理表示）
          if (i == divider) {
            if (i == 3) {
              weldFinishProFilter = weldFinishProcLst.filter((item) => item.key >= lastIndex && item.key <= divider);
            } else {
              weldFinishProFilter = weldFinishProcLst.filter((item) => item.key > lastIndex && item.key <= divider);
            }
            weldFinishProcLstDisplay.push({
              key: key,
              weldFinishProcLst: weldFinishProFilter,
            });
            divider = divider + 4;
            lastIndex = i;
            key++;
          } else if (i + 1 == weldFinishProcessCnt) {
            // 最後のdiv設定
            weldFinishProFilter = weldFinishProcLst.filter(
              (item) => item.key > lastIndex && item.key < weldFinishProcessCnt
            );
            weldFinishProcLstDisplay.push({
              key: key,
              weldFinishProcLst: weldFinishProFilter,
            });
            key++;
          }
        }
        setWeldFinishProcessLst(weldFinishProcLstDisplay);
      }

      //表面処理装置情報取得
      const surfaceNm = selectedData?.patternProcess?.filter((item) => item.processType == '表面処理');
      if (surfaceNm !== undefined && surfaceNm.length > 0) {
        setSurfaceChk(surfaceNm[0].checked);
        setSurface(surfaceNm[0].deviceCodeKey);
      }
      // 選択ボックスの表面処理装置一覧取得
      const surfaceCodeFilter = processMasterData?.filter((item) => item.processType == '表面処理');
      if (surfaceCodeFilter.length > 0) surfaceProcessCode = surfaceCodeFilter[0].code;
      const surfaceSochiTypeLst = sochiList.filter((item) => item.code == surfaceProcessCode);
      let surfaceSochiLst = [];
      for (let j = 0; j < surfaceSochiTypeLst.length; j++) {
        surfaceSochiLst.push({
          value: surfaceSochiTypeLst[j].key,
          label: surfaceSochiTypeLst[j].deviceName,
        });
      }
      setSurfaceSochiList(surfaceSochiLst);
      // 表面処理種類マスターデータ取得
      const surfaceType = processMasterData.filter((item) => item.processType == '表面処理');
      const surfaceProcessCnt = surfaceType[0]?.numberOfProcess;
      setSurfaceProcessCnt(surfaceProcessCnt);
      setSurfaceProcessTypeLst(surfaceProcList);
      setSurfaceProcessTypeVal0Lst(surfaceProc0List);
      // 処理全般
      for (let i = 0; i < surfaceType[0].processDetail[0].processDetailValue.length; i++) {
        if (surfaceType[0].processDetail[0].processDetailValue[i].displayFlg) {
          surfaceProc1lList.push({
            value: surfaceType[0].processDetail[0].processDetailValue[i].detailCode,
            label: surfaceType[0].processDetail[0].processDetailValue[i].detailType,
          });
        }
      }
      setSurfaceProcessTypeVal1Lst(surfaceProc1lList);

      // メッキ
      for (let i = 0; i < surfaceType[0].processDetail[1].processDetailValue.length; i++) {
        if (surfaceType[0].processDetail[1].processDetailValue[i].displayFlg) {
          surfaceProc2lList.push({
            value: surfaceType[0].processDetail[1].processDetailValue[i].detailCode,
            label: surfaceType[0].processDetail[1].processDetailValue[i].detailType,
          });
        }
      }
      setSurfaceProcessTypeVal2Lst(surfaceProc2lList);
      // 塗装
      for (let i = 0; i < surfaceType[0].processDetail[2].processDetailValue.length; i++) {
        if (surfaceType[0].processDetail[2].processDetailValue[i].displayFlg) {
          surfaceProc3lList.push({
            value: surfaceType[0].processDetail[2].processDetailValue[i].detailCode,
            label: surfaceType[0].processDetail[2].processDetailValue[i].detailType,
          });
        }
      }
      setSurfaceProcessTypeVal3Lst(surfaceProc3lList);

      // 表面処理一覧データ準備
      const surface = selectedData?.patternProcess?.filter((item) => item.processType == '表面処理');
      if (surface !== undefined && surface.length > 0) {
        const surfaceProcLst = [];
        const surfaceProcLstDisplay = [];
        let key = 0;
        let surface_divider = 3; // 1div4処理表示のため
        let surface_lastIndex = 0; //  4処理をdiv分けて設定するためのインデックス
        let surfaceProFilter = [];
        for (let i = 0; i < surfaceProcessCnt; i++) {
          const selectedSurfaceDetailProcessLst = surface[0].processDetailLst?.filter((item) => item.key == i);
          let processDetailValLst = [];
          let processDetailTitleType =
            selectedSurfaceDetailProcessLst?.length > 0
              ? selectedSurfaceDetailProcessLst[0].processDetailType
              : surfaceProcList[0].value;
          if (processDetailTitleType == '0') {
            processDetailValLst = surfaceProc0List;
          } else if (processDetailTitleType == '1') {
            processDetailValLst = surfaceProc1lList;
          } else if (processDetailTitleType == '2') {
            processDetailValLst = surfaceProc2lList;
          } else if (processDetailTitleType == '3' || processDetailTitleType == '4') {
            processDetailValLst = surfaceProc3lList;
          }
          surfaceProcLst.push({
            key: i,
            title: `第${i + 1}処理`,
            processDetailType:
              selectedSurfaceDetailProcessLst?.length > 0
                ? selectedSurfaceDetailProcessLst[0].processDetailType
                : surfaceProcList[0].value,
            processDetailCode:
              selectedSurfaceDetailProcessLst?.length > 0
                ? selectedSurfaceDetailProcessLst[0].processDetailCode
                : processDetailValLst[0].value,
            processDetailValLst: processDetailValLst,
          });

          // carouselのdivデータ準備（1div4処理表示）
          if (i == surface_divider) {
            if (i == 3) {
              surfaceProFilter = surfaceProcLst.filter(
                (item) => item.key >= surface_lastIndex && item.key <= surface_divider
              );
            } else {
              surfaceProFilter = surfaceProcLst.filter(
                (item) => item.key > surface_lastIndex && item.key <= surface_divider
              );
            }
            surfaceProcLstDisplay.push({
              key: key,
              surfaceProcLst: surfaceProFilter,
            });
            surface_divider = surface_divider + 4;
            surface_lastIndex = i;
            key++;
          } else if (i + 1 == surfaceProcessCnt) {
            // 最後のdiv設定
            surfaceProFilter = surfaceProcLst.filter(
              (item) => item.key > surface_lastIndex && item.key < surfaceProcessCnt
            );
            surfaceProcLstDisplay.push({
              key: key,
              surfaceProcLst: surfaceProFilter,
            });
            key++;
          }
        }
        setSurfaceProcessLst(surfaceProcLstDisplay);
        // console.log(surfaceProcLstDisplay);
      }

      //組立装置情報取得（親部品のみ表示）
      if (page === patternPage[0]) {
        const kumitateNm = selectedData?.patternProcess?.filter((item) => item.processType == '組立');
        if (kumitateNm !== undefined && kumitateNm.length > 0) {
          setAssemblyChk(kumitateNm[0].checked);
          setAssembly(kumitateNm[0].deviceCodeKey);
        }

        // 選択ボックスの組立装置一覧取得
        const kumitateCodeFilter = processMasterData?.filter((item) => item.processType == '組立');
        if (kumitateCodeFilter.length > 0) kumitateProcessCode = kumitateCodeFilter[0].code;
        const assemblySochiTypeLst = sochiList.filter((item) => item.code == kumitateProcessCode);
        let assemblySochiLst = [];
        for (let j = 0; j < assemblySochiTypeLst.length; j++) {
          assemblySochiLst.push({
            value: assemblySochiTypeLst[j].key,
            label: assemblySochiTypeLst[j].deviceName,
          });
        }
        setAssemblySochiList(assemblySochiLst);
      }
      //検査装置情報取得
      const testProNm = selectedData?.patternProcess?.filter((item) => item.processType == '検査');
      if (testProNm !== undefined && testProNm.length > 0) {
        setTestChk(testProNm[0].checked);
        setTest(testProNm[0].deviceCodeKey);
      }

      // 選択ボックスの検査装置一覧取得
      const kensaCodeFilter = processMasterData?.filter((item) => item.processType == '検査');
      if (kensaCodeFilter.length > 0) kensaProcessCode = kensaCodeFilter[0].code;
      const testProSochiTypeLst = sochiList.filter((item) => item.code == kensaProcessCode);
      let testProSochiLst = [];
      for (let j = 0; j < testProSochiTypeLst.length; j++) {
        testProSochiLst.push({
          value: testProSochiTypeLst[j].key,
          label: testProSochiTypeLst[j].deviceName,
        });
      }
      setTestProSochiList(testProSochiLst);

      // 一覧から選択されたレコードの追加工程データ取得
      const selectedCustomInfoLst = selectedData?.patternProcess?.filter((item) => item.processType == 'カスタム');
      // 追加工程種類マスターデータ取得
      const customProcess = processMasterData.filter((item) => item.category == '追加' && item.display == true);
      if (customProcess !== undefined && customProcess.length > 0) {
        const customGroupLst = [];
        // 追加工程種類マスターデータをループして選択されたレコードの追加工程データ設定
        for (let j = 0; j < customProcess.length; j++) {
          const processDetailValue = customProcess[j].processDetail[0].processDetailValue;
          const customLst = [];
          const customProcLstDisplay = [];
          let key = 0;
          let custom_divider = 4; // 1div5種類表示のため
          let custom_lastIndex = 0; //  5種類をdiv分けて設定するためのインデックス
          let customProFilter = [];
          let deviceCode = '';
          for (let i = 0; i < processDetailValue.length; i++) {
            let selectedCustomInfo = selectedCustomInfoLst.filter(
              (item) =>
                item.processName == customProcess[j].processName && item.addProType == processDetailValue[i].detailType
            );
            let customerProType = sochiList?.filter(
              (item) =>
                item.processName === customProcess[j].processName && item.kouteiType == processDetailValue[i].detailType
            );
            if (selectedCustomInfo.length > 0) {
              deviceCode = selectedCustomInfo[0].deviceCodeKey;
            } else if (customerProType.length > 0) {
              deviceCode = customerProType[0].key;
            }

            let customChecked = selectedCustomInfo.length > 0 ? selectedCustomInfo[0].checked : false;
            // 該当装置の委託先フラグ
            let itakuFlag = sochiList?.filter(
              (item) =>
                item.processName === customProcess[j].processName &&
                item.kouteiType == processDetailValue[i].detailType &&
                item.key === deviceCode
            );
            // 該当装置の委託先名称
            let itakuInfo = itakuFlag.length > 0 ? itakuFlag[0].customerCode : '';
            let companyCode = selectedCustomInfo.length > 0 ? selectedCustomInfo[0].companyCode : '';
            companyCode = companyCode == '' ? itakuInfo : companyCode;
            // 追加工程選択ボックスデータ準備
            let customerProTypeLst = [];
            if (customerProType != null) {
              for (let k = 0; k < customerProType.length; k++) {
                customerProTypeLst.push({
                  value: customerProType[k].key,
                  label: customerProType[k].deviceName,
                  customerCode: customerProType[k].customerCode,
                  itakuFlag: customerProType[k].itakuFlag,
                });
              }
            }

            customLst.push({
              key: i,
              checked: customChecked,
              deviceCodeKey: deviceCode,
              companyCode: companyCode,
              customSochiLst: customerProTypeLst,
              itakuFlag: itakuFlag.length > 0 ? itakuFlag[0].itakuFlag : false,
            });

            // carouselのdivデータ準備（1div5種類表示）
            if (i == custom_divider) {
              if (i == 4) {
                customProFilter = customLst.filter(
                  (item) => item.key >= custom_lastIndex && item.key <= custom_divider
                );
              } else {
                customProFilter = customLst.filter((item) => item.key > custom_lastIndex && item.key <= custom_divider);
              }
              customProcLstDisplay.push({
                key: key,
                customLst: customProFilter,
              });
              custom_divider = custom_divider + 5;
              custom_lastIndex = i;
              key++;
            } else if (i + 1 == processDetailValue.length) {
              // 最後のdiv設定
              if (i < 4) {
                // 5種類未満の場合
                customProFilter = customLst.filter((item) => item.key <= processDetailValue.length);
              } else {
                // 5種類以上の場合
                customProFilter = customLst.filter(
                  (item) => item.key > custom_lastIndex && item.key < processDetailValue.length
                );
              }

              customProcLstDisplay.push({
                key: key,
                customLst: customProFilter,
              });
              key++;
            }
          }
          customGroupLst.push({
            key: j,
            groupNm: customProcess[j].processName,
            customTypeLst: customProcLstDisplay,
            groupDetailCnt: processDetailValue.length,
          });
        }
        setCustomProcessLst(customGroupLst);
        getItakusakiInfo();
      }

      // 親部品の場合、梱包表示
      if (page === patternPage[0]) {
        //梱包装置情報取得
        const packingNm = selectedData?.patternProcess?.filter((item) => item.processType == '梱包');
        if (packingNm !== undefined && packingNm.length > 0) {
          setPackingChk(packingNm[0].checked);
          setPacking(packingNm[0].deviceCodeKey);
        }

        // 選択ボックスの梱包装置一覧取得
        const packingCodeFilter = processMasterData?.filter((item) => item.processType == '梱包');
        if (packingCodeFilter.length > 0) konpoProcessCode = packingCodeFilter[0].code;
        const packingSochiTypeLst = sochiList.filter((item) => item.code == konpoProcessCode);
        let packingSochiLst = [];
        for (let j = 0; j < packingSochiTypeLst.length; j++) {
          packingSochiLst.push({
            value: packingSochiTypeLst[j].key,
            label: packingSochiTypeLst[j].deviceName,
          });
        }
        setPackingSochiList(packingSochiLst);
      } else if (page === patternPage[1]) {
      }

      // 選択中である行の情報を画面表示に設定
      // 装置番号
      setPatternNo(selectedData.patternNo);
      // 装置名称
      setPatternName(selectedData.patternName);
    } else {
      setListData([]);
    }
  };

  //　iQ3工程データ設定
  const settingIQ3Process = (processMasterData, sochiList) => {
    //プログラム装置情報取得
    const program = props.selectedData?.patternProcess?.filter((item) => item.processType == 'プログラム');
    if (program !== undefined && program.length > 0) {
      setProgramChk(program[0].checked);
      setProgram(program[0].deviceCodeKey);
    }

    // 選択ボックスのプログラム装置一覧取得
    const programSochiTypeLst = sochiList.filter((item) => item.processType == 'プログラム');
    let programSochiLst = [];
    for (let j = 0; j < programSochiTypeLst.length; j++) {
      programSochiLst.push({
        value: programSochiTypeLst[j].key,
        label: programSochiTypeLst[j].deviceName,
      });
    }
    setProgramProSochiList(programSochiLst);

    // ブランク種類マスターデータ取得
    const blankProcessMaster = processMasterData?.filter((item) => item.processType == 'ブランク');
    // 一覧から選択されたレコードのブランク工程データ取得
    const selectedBlankInfoLst = props.selectedData?.patternProcess?.filter((item) => item.processType == 'ブランク');
    const blankProLst = [];
    // ブランク工程種類マスターデータをループして選択されたレコードのブランク工程データ設定
    for (let i = 0; i < blankProcessMaster[0]?.processDetail[0]?.processDetailValue.length; i++) {
      let selectedBlankInfo = selectedBlankInfoLst?.filter(
        (item) => item.addProType == blankProcessMaster[0].processDetail[0].processDetailValue[i].detailType
      );
      let deviceCode = selectedBlankInfo?.length > 0 ? selectedBlankInfo[0].deviceCodeKey : '';
      let companyCode = selectedBlankInfo?.length > 0 ? selectedBlankInfo[0].companyCode : '';
      let blankChecked = selectedBlankInfo?.length > 0 ? selectedBlankInfo[0].checked : false;
      let blankProTypeSochiLst = sochiList?.filter(
        (item) => item.kakouType == blankProcessMaster[0].processDetail[0].processDetailValue[i].detailType
      );

      // ブランク工程選択ボックスデータ準備
      let blankProTypeLst = [];
      if (blankProTypeSochiLst != null) {
        for (let j = 0; j < blankProTypeSochiLst.length; j++) {
          blankProTypeLst.push({
            value: blankProTypeSochiLst[j].key,
            label: blankProTypeSochiLst[j].deviceName,
          });
        }
      }
      blankProLst.push({
        key: i,
        checked: blankChecked,
        deviceCodeKey: deviceCode,
        companyCode: companyCode,
        blankSochiLst: blankProTypeLst,
      });
    }
    setBlankProcessLst(blankProLst);

    //２次加工装置情報取得
    const nijiProcess = props.selectedData?.patternProcess?.filter((item) => item.processType == '2次加工');
    if (nijiProcess !== undefined && nijiProcess.length > 0) {
      setSecondaryChk(nijiProcess[0]?.checked);
      setSecondary(nijiProcess[0]?.deviceCodeKey);
    }

    // 選択ボックスの２次加工装置一覧取得
    const nijiSochiTypeLst = sochiList.filter((item) => item.processType == '2次加工');
    let nijiSochiLst = [];
    for (let j = 0; j < nijiSochiTypeLst.length; j++) {
      nijiSochiLst.push({
        value: nijiSochiTypeLst[j].key,
        label: nijiSochiTypeLst[j].deviceName,
      });
    }
    setNijiProSochiList(nijiSochiLst);

    //ベンディング装置情報取得
    const bendingProcess = props.selectedData?.patternProcess?.filter((item) => item.processType == 'ベンディング');
    if (bendingProcess !== undefined && bendingProcess.length > 0) {
      setBendingChk(bendingProcess[0]?.checked);
      setBending(bendingProcess[0]?.deviceCodeKey);
    }

    // 選択ボックスのベンディング装置一覧取得
    const bendingSochiTypeLst = sochiList.filter((item) => item.processType == 'ベンディング');
    let bendingSochiLst = [];
    for (let j = 0; j < bendingSochiTypeLst.length; j++) {
      bendingSochiLst.push({
        value: bendingSochiTypeLst[j].key,
        label: bendingSochiTypeLst[j].deviceName,
      });
    }
    setBendingProSochiList(bendingSochiLst);
    // バリ取種類マスターデータ取得
    const deburrProcessMaster = processMasterData.filter((item) => item.processType == 'バリ取');
    // 一覧から選択されたレコードのバリ取工程データ取得
    const selectedDeburrInfoLst = props.selectedData?.patternProcess?.filter((item) => item.processType == 'バリ取');

    const deburrProLst = [];
    // バリ取工程種類マスターデータをループして選択されたレコードのバリ取工程データ設定
    for (let i = 0; i < deburrProcessMaster[0].processDetail[0].processDetailValue.length; i++) {
      let selectedDeburrInfo = selectedDeburrInfoLst?.filter(
        (item) => item.addProType == deburrProcessMaster[0].processDetail[0].processDetailValue[i].detailType
      );

      let deviceCode = selectedDeburrInfo?.length > 0 ? selectedDeburrInfo[0].deviceCodeKey : '';
      let companyCode = selectedDeburrInfo?.length > 0 ? selectedDeburrInfo[0].companyCode : '';
      let deburrChecked = selectedDeburrInfo?.length > 0 ? selectedDeburrInfo[0].checked : false;
      let deburrProTypeSochiLst = sochiList?.filter(
        (item) => item.kakouType == deburrProcessMaster[0].processDetail[0].processDetailValue[i].detailType
      );
      // バリ取工程選択ボックスデータ準備
      let deburrProTypeLst = [];
      if (deburrProTypeSochiLst != null) {
        for (let j = 0; j < deburrProTypeSochiLst.length; j++) {
          deburrProTypeLst.push({
            value: deburrProTypeSochiLst[j].key,
            label: deburrProTypeSochiLst[j].deviceName,
          });
        }
      }
      deburrProLst.push({
        key: i,
        checked: deburrChecked,
        deviceCodeKey: deviceCode,
        companyCode: companyCode,
        deburrSochiLst: deburrProTypeLst,
      });
    }
    setDeburrProcessLst(deburrProLst);
    //シャーリング装置情報取得
    const shearingProcess = props.selectedData?.patternProcess?.filter((item) => item.processType == 'シャーリング');
    if (shearingProcess !== undefined && shearingProcess.length > 0) {
      setShearingChk(shearingProcess[0]?.checked);
      setShearing(shearingProcess[0]?.deviceCodeKey);
    }

    // 選択ボックスのシャーリング装置一覧取得
    const shearingSochiTypeLst = sochiList.filter((item) => item.processType == 'シャーリング');
    let shearingSochiLst = [];
    for (let j = 0; j < shearingSochiTypeLst.length; j++) {
      shearingSochiLst.push({
        value: shearingSochiTypeLst[j].key,
        label: shearingSochiTypeLst[j].deviceName,
      });
    }
    setShearingProSochiList(shearingSochiLst);
  };

  // コードで名称を取得
  function getNameByNo(no, Lists) {
    let retValue = '';
    if (no) {
      let info = Lists.filter((info) => info.value === no);
      if (info.length > 0) {
        retValue = info[0].label;
      }
    }
    return retValue;
  }

  // お客様コードでお客様名称を取得
  function getCustomerNameByCode(code, Lists) {
    let retValue = '';
    if (code) {
      let info = Lists.filter((info) => info.code === code);
      if (info.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  //　一覧情報から工程パターンNo、工程パターン名を取得
  function getPatternList(list) {
    var retVal = [];
    list.forEach((element) => {
      retVal.push({
        key: element.key,
        value: element.patternNo,
        label: element.patternName,
      });
    });
    setPatternList(retVal);
  }

  const onChangeWeldFinishProcTypeVal = (typeVal, key, groupKey) => {
    const weldFinishProcessType = [...weldFinishProcessLst];
    const weldFinishProcLst = weldFinishProcessType[groupKey].weldFinishProcLst;
    let weldFinishProc = weldFinishProcLst?.filter((item) => item.key === key);
    weldFinishProc[0].processDetailCode = typeVal;
    setWeldFinishProcessLst(weldFinishProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '溶接仕上');
    targetInfo[0].processDetailLst?.forEach((element) => {
      if (element.key === key) {
        element.processDetailCode = typeVal;
        selectedPatternInfo.patternProcess[targetInfo[0].key].processDetailLst[element.key] = element;
      }
    });
  };

  const onChangeSurfaceProcType = (type, key, groupKey) => {
    let processDetailCode = '';
    const surfaceProcessType = [...surfaceProcessLst];
    const surfaceProcLst = surfaceProcessType[groupKey].surfaceProcLst;
    let surfaceProc = surfaceProcLst?.filter((item) => item.key === key);
    surfaceProc[0]['processDetailType'] = type;
    if (type == '0') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal0Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal0Lst[0].value;
      processDetailCode = surfaceProcessTypeVal0Lst[0].value;
    } else if (type == '1') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal1Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal1Lst[0].value;
      processDetailCode = surfaceProcessTypeVal1Lst[0].value;
    } else if (type == '2') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal2Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal2Lst[0].value;
      processDetailCode = surfaceProcessTypeVal2Lst[0].value;
    } else if (type == '3' || type == '4') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal3Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal3Lst[0].value;
      processDetailCode = surfaceProcessTypeVal3Lst[0].value;
    }
    setSurfaceProcessLst(surfaceProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '表面処理');
    targetInfo[0].processDetailLst?.forEach((element) => {
      if (element.key === key) {
        element.processDetailType = type;
        element.processDetailCode = processDetailCode;
        selectedPatternInfo.patternProcess[targetInfo[0].key].processDetailLst[element.key] = element;
      }
    });
  };

  const onChangeSurfaceProcTypeVal = (typeVal, key, groupKey) => {
    const surfaceProcessType = [...surfaceProcessLst];
    const surfaceProcLst = surfaceProcessType[groupKey].surfaceProcLst;
    let surfaceProc = surfaceProcLst?.filter((item) => item.key === key);
    surfaceProc[0]['processDetailCode'] = typeVal;
    setSurfaceProcessLst(surfaceProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '表面処理');
    targetInfo[0].processDetailLst?.forEach((element) => {
      if (element.key === key) {
        element.processDetailCode = typeVal;
        selectedPatternInfo.patternProcess[targetInfo[0].key].processDetailLst[element.key] = element;
      }
    });
  };

  const onChangeAdditionalProType = (typeVal, key, deviceCode, carouselKey, groupKey) => {
    const customProcessType = [...customProcessLst];
    const customTypeLst = customProcessType[groupKey].customTypeLst;
    const customLst = customTypeLst[carouselKey].customLst;
    let custom = customLst?.filter((item) => item.key === key);
    custom[0]['deviceCodeKey'] = typeVal;

    //　選択された装置により該当する委託先情報を設定
    let selectedCustomerCode = selectedPatternInfoBk?.patternProcess?.filter(
      (item) =>
        item.processType === 'カスタム' &&
        item.processName == customProcessType[groupKey].groupNm &&
        item.deviceCodeKey === typeVal
    );
    let companyCode = '';
    let itakuFlag = false;
    if (selectedCustomerCode.length > 0) {
      companyCode = selectedCustomerCode[0].companyCode;
      itakuFlag = selectedCustomerCode[0].itakuFlag;
    } else {
      let info = custom[0].customSochiLst.filter((info) => info.value === typeVal);
      if (info.length > 0) {
        companyCode = info[0].companyCode;
        itakuFlag = info[0].itakuFlag;
      }
    }
    custom[0]['companyCode'] = companyCode;
    custom[0]['itakuFlag'] = itakuFlag;
    setCustomProcessLst(customProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter(
      (item) =>
        item.processType === 'カスタム' &&
        item.processName == customProcessType[groupKey].groupNm &&
        item.deviceCodeKey == deviceCode
    );
    if (targetInfo.length > 0) {
      targetInfo?.forEach((element) => {
        if (element.processName == customProcessType[groupKey].groupNm && element.deviceCodeKey === deviceCode) {
          element.deviceCodeKey = typeVal;
          element.companyCode = companyCode;
          element.itakuFlag = itakuFlag;
        }
      });
    }
  };

  const onChangeAdditionalProChecked = (typeVal, key, deviceCode, carouselKey, groupKey) => {
    const customProcessType = [...customProcessLst];
    const customTypeLst = customProcessType[groupKey].customTypeLst;
    const customLst = customTypeLst[carouselKey].customLst;
    let custom = customLst?.filter((item) => item.key === key);
    custom[0]['checked'] = typeVal;
    setCustomProcessLst(customProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter(
      (item) =>
        item.processType === 'カスタム' &&
        item.processName == customProcessType[groupKey].groupNm &&
        item.deviceCodeKey == deviceCode
    );
    if (targetInfo.length > 0) {
      targetInfo?.forEach((element) => {
        if (element.deviceCodeKey === deviceCode) {
          element.checked = typeVal;
          selectedPatternInfo.patternProcess[element.key] = element;
        }
      });
    }
  };

  const getCustomerName = (e, item, carouselIndex, index) => {
    let isCustomSearch = item.customSochiLst.length > 0 && item.itakuFlag ? true : false;
    if (isCustomSearch) {
      setCustomerModal(true);
      setAddProKey(item.key);
      setAddProDeviceCode(item.key);
      setAddProCarouselIndex(carouselIndex);
      setAddProIndex(index);
    } else {
      setCustomerModal(false);
    }
  };

  const getItakusakiInfo = async () => {
    let result = await getClientInfo(token);
    result = result.filter((record) => record.clientType === ClientType.Contractor && record.info.isUsed === true);
    setClientInfo(result);
  };

  const selectedCustomerData = (selectedCustomerData) => {
    const customProcessType = [...customProcessLst];
    const customTypeLst = customProcessType[addProIndex].customTypeLst;
    const customLst = customTypeLst[addProCarouselIndex].customLst;
    let custom = customLst?.filter((item) => item.key === addProKey);
    custom[0]['companyCode'] = selectedCustomerData.code;
    setCustomProcessLst(customProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === 'カスタム');
    targetInfo?.forEach((element) => {
      if (element.processName == customProcessType[addProIndex].groupNm && element.deviceCodeKey === addProDeviceCode) {
        element.companyCode = selectedCustomerData.code;
        selectedPatternInfo.patternProcess[element.key] = element;
      }
    });
    setCustomerModal(false);
  };

  const onChangeBlankProType = (typeVal, key, deviceCode) => {
    const blankProcessType = [...blankProcessList];
    blankProcessType[key]['deviceCodeKey'] = typeVal;
    setBlankProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === 'ブランク');
    targetInfo?.forEach((element) => {
      if (element.deviceCodeKey === deviceCode) {
        element.deviceCodeKey = typeVal;
        selectedPatternInfo.patternProcess[element.key] = element;
      }
    });
  };

  const onChangeBlankProChecked = (typeVal, key, deviceCode) => {
    const blankProcessType = [...blankProcessList];
    blankProcessType[key]['checked'] = typeVal;
    blankProcessType?.forEach((element) => {
      if (element.key !== key) {
        element.checked = false;
      }
    });
    setBlankProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === 'ブランク');
    targetInfo?.forEach((element) => {
      if (element.deviceCodeKey === deviceCode) {
        element.checked = typeVal;
      } else {
        element.checked = false;
      }
      selectedPatternInfo.patternProcess[element.key] = element;
    });
  };

  const onChangeDeburrProType = (typeVal, key, deviceCode) => {
    const blankProcessType = [...deburrProcessList];
    blankProcessType[key]['deviceCodeKey'] = typeVal;
    setDeburrProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === 'バリ取');
    targetInfo?.forEach((element) => {
      if (element.deviceCodeKey === deviceCode) {
        element.deviceCodeKey = typeVal;
        selectedPatternInfo.patternProcess[element.key] = element;
      }
    });
  };

  const onChangeDeburrProChecked = (typeVal, key, deviceCode) => {
    const blankProcessType = [...deburrProcessList];
    blankProcessType[key]['checked'] = typeVal;
    setDeburrProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === 'バリ取');
    targetInfo?.forEach((element) => {
      if (element.deviceCodeKey === deviceCode) {
        element.checked = typeVal;
        selectedPatternInfo.patternProcess[element.key] = element;
      }
    });
  };

  const onChangeProcessPatternInfo = (key) => {
    setPatternNo(key);
    let patternList = props.listData;
    const selectedPatternInfo = patternList.filter((item) => item.patternNo == key);
    setSelectedPatternInfo(selectedPatternInfo[0]);
    if (selectedPatternInfo.length > 0) {
      if (props.isParameter) {
        // 選択したパターンキーより一覧の選択行を変更
        props.updateSelectedData(selectedPatternInfo[0]);
      } else {
        // 選択したパターンキーより親部品工程選択・子部品工程選択の選択パターン番号を変更
        props.updateKouteiSentakuInfo(selectedPatternInfo[0]);
      }
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getUpdatePatternInfo: () => {
        selectedPatternInfo.modified = today;
        return selectedPatternInfo;
      },
    }),
    [selectedPatternInfo]
  );

  const standardItem = (
    <>
      {/* IQ3_プログラム */}
      <Row style={{ height: 37, display: page === patternPage[0] ? 'none' : '' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          プログラム
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={programChk}
            onChange={(e) => {
              setProgramChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === 'プログラム');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="program"
              name="program"
              ref={tableRef}
              value={program}
              options={programProSochiList}
              onChange={(e) => {
                setProgram(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter(
                  (item) => item.processType === 'プログラム'
                );
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(program, programProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* IQ3_ブランク */}
      <Row style={{ height: 'auto', display: page === patternPage[0] ? 'none' : '' }}>
        <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, border: '1px solid #a5a5a5' }}>
          ブランク
        </Col>
        <Col span={20} style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}>
          <List
            className="process-pattern-list"
            grid={{ gutter: 16, column: 5 }}
            dataSource={blankProcessList}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ height: 37 }}>
                  <Col
                    className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
                    style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
                  >
                    <Checkbox
                      style={{ marginLeft: 3, marginRight: 10 }}
                      type="checkbox"
                      disabled={!editMode}
                      checked={item.checked}
                      onChange={(e) => onChangeBlankProChecked(e.target.checked, item.key, item.deviceCodeKey)}
                    ></Checkbox>
                    {editMode ? (
                      <Select
                        className="pdetail-ant-select-tsuikaPro"
                        style={{ marginTop: 0 }}
                        id="deviceName"
                        name="deviceName"
                        ref={tableRef}
                        value={item.deviceCodeKey}
                        options={item.blankSochiLst}
                        onChange={(e) => onChangeBlankProType(e, item.key, item.key)}
                      />
                    ) : (
                      <>{getNameByNo(item.deviceCodeKey, item.blankSochiLst)}</>
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      {/* IQ3_2次加工 */}
      <Row style={{ height: 37, borderBottom: '1px solid #a5a5a5', display: page === patternPage[0] ? 'none' : '' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderRight: '1px solid #a5a5a5',
          }}
        >
          2次加工
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={secondaryChk}
            onChange={(e) => {
              setSecondaryChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '2次加工');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="secondary"
              name="secondary"
              ref={tableRef}
              value={secondary}
              options={nijiProSochiList}
              onChange={(e) => {
                setSecondary(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '2次加工');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(secondary, nijiProSochiList)}</>
          )}
        </Col>
        <Col span={16} className="parent-pdetail-tbl-td" style={{ borderRight: '1px solid #a5a5a5' }}></Col>
      </Row>
      {/* IQ3_ベンディング */}
      <Row style={{ height: 37, borderBottom: '1px solid #a5a5a5', display: page === patternPage[0] ? 'none' : '' }}>
        <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, borderRight: '1px solid #a5a5a5' }}>
          ベンディング
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={bendingChk}
            onChange={(e) => {
              setBendingChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter(
                (item) => item.processType === 'ベンディング'
              );
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="bending"
              name="bending"
              ref={tableRef}
              value={bending}
              options={bendingProSochiList}
              onChange={(e) => {
                setBending(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter(
                  (item) => item.processType === 'ベンディング'
                );
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(bending, bendingProSochiList)}</>
          )}
        </Col>
        <Col span={16} className="parent-pdetail-tbl-td" style={{ borderRight: '1px solid #a5a5a5' }}></Col>
      </Row>
      {/* IQ3_バリ取り */}
      <Row style={{ height: 37, borderBottom: '1px solid #a5a5a5', display: page === patternPage[0] ? 'none' : '' }}>
        <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, borderRight: '1px solid #a5a5a5' }}>
          バリ取
        </Col>
        <Col span={20} style={{ borderRight: '1px solid #a5a5a5' }}>
          <List
            className="process-pattern-list"
            grid={{ gutter: 16, column: 5 }}
            dataSource={deburrProcessList}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ height: 37 }}>
                  <Col
                    className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
                    style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
                  >
                    <Checkbox
                      style={{ marginLeft: 3, marginRight: 10 }}
                      type="checkbox"
                      disabled={!editMode}
                      checked={item.checked}
                      onChange={(e) => onChangeDeburrProChecked(e.target.checked, item.key, item.deviceCodeKey)}
                    ></Checkbox>
                    {editMode ? (
                      <Select
                        className="pdetail-ant-select-tsuikaPro"
                        style={{ marginTop: 0 }}
                        id="deviceName"
                        name="deviceName"
                        ref={tableRef}
                        value={item.deviceCodeKey}
                        options={item.deburrSochiLst}
                        onChange={(e) => onChangeDeburrProType(e, item.key, item.deviceCodeKey)}
                      />
                    ) : (
                      <>{getNameByNo(item.deviceCodeKey, item.deburrSochiLst)}</>
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      {/* 溶接 */}
      <Row style={{ height: 37 }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          溶接
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            verticalAlign: 'middle',
            padding: 3,
            borderTop: page === patternPage[0] ? '1px solid #a5a5a5' : 'none',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={weldingChk}
            onChange={(e) => {
              setWeldingChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '溶接');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="welding"
              name="welding"
              ref={tableRef}
              value={welding}
              options={weldingSochiList}
              onChange={(e) => {
                setWelding(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '溶接');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(welding, weldingSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: page === patternPage[0] ? '1px solid #a5a5a5' : 'none',
            borderRight: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* 溶接仕上 */}
      <Row style={{ height: 'auto' }}>
        <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, border: '1px solid #a5a5a5' }}>
          溶接仕上
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            verticalAlign: 'middle',
            padding: 3,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={weldFinishChk}
            onChange={(e) => {
              setWeldFinishChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '溶接仕上');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="weldFinish"
              name="weldFinish"
              ref={tableRef}
              value={weldFinish}
              options={weldFinishSochiList}
              onChange={(e) => {
                setWeldFinish(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '溶接仕上');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(weldFinish, weldFinishSochiList)}</>
          )}
        </Col>
        <Col
          id={props.isAdd ? 'pattern-weldFinish-carousel-addForm' : 'pattern-weldFinish-carousel'}
          span={16}
          className="parent-pdetail-tbl-td-mul"
          style={{ borderRight: '1px solid #a5a5a5', borderBottom: '1px solid #a5a5a5' }}
        >
          <Carousel
            ref={caroselRef}
            className="pattern-weldFinish-carousel"
            arrows
            prevArrow={
              <CarouselPrevArrow
                activeCarouselIndex={0}
                targetDivCnt={weldFinishProcessLst.length}
                name={props.isAdd ? 'pattern-weldFinish-carousel-addForm' : 'pattern-weldFinish-carousel'}
              />
            }
            nextArrow={
              <CarouselNextArrow
                activeCarouselIndex={0}
                targetDivCnt={weldFinishProcessLst.length}
                name={props.isAdd ? 'pattern-weldFinish-carousel-addForm' : 'pattern-weldFinish-carousel'}
              />
            }
          >
            {weldFinishProcessLst.map((dis, index) => {
              return (
                <div>
                  <List
                    id="carousel-div"
                    className="process-pattern-list"
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={dis.weldFinishProcLst}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ height: 37 }}>
                          <Col className="parent-pdetail-tbl-subtitle">
                            <label style={{ paddingTop: 7 }}>{item.title}</label>
                          </Col>
                        </Row>
                        <Row style={{ height: 37 }}>
                          <Col
                            className={'parent-pdetail-tbl-td-mul'}
                            style={{ verticalAlign: 'middle', textAlign: 'center' }}
                          >
                            {editMode ? (
                              <Select
                                style={{ paddingTop: 4 }}
                                className="pdetail-ant-select"
                                id="weldFinishProc1"
                                name="weldFinishProc1"
                                ref={tableRef}
                                value={item.processDetailCode || '000'}
                                options={weldFinishProcessTypeLst}
                                onChange={(e) => onChangeWeldFinishProcTypeVal(e, item.key, index)}
                              />
                            ) : (
                              <label style={{ paddingTop: 7 }}>
                                {getNameByNo(item.processDetailCode, weldFinishProcessTypeLst)}
                              </label>
                            )}
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
      {/* 表面処理 */}
      <Row style={{ height: 'auto' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        >
          表面処理
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            verticalAlign: 'middle',
            padding: 3,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={surfaceChk}
            onChange={(e) => {
              setSurfaceChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '表面処理');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="processDetailType"
              name="processDetailType"
              ref={tableRef}
              value={surface}
              options={surfaceSochiList}
              onChange={(e) => {
                setSurface(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '表面処理');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(surface, surfaceSochiList)}</>
          )}
        </Col>
        <Col
          id={props.isAdd ? 'pattern-surface-carousel-addForm' : 'pattern-surface-carousel'}
          span={16}
          className="parent-pdetail-tbl-td"
          style={{ borderRight: '1px solid #a5a5a5', borderBottom: '1px solid #a5a5a5' }}
        >
          <Carousel
            ref={inputFile}
            className="pattern-surface-carousel"
            arrows
            prevArrow={
              <CarouselPrevArrow
                activeCarouselIndex={1}
                targetDivCnt={surfaceProcessLst.length}
                name={props.isAdd ? 'pattern-surface-carousel-addForm' : 'pattern-surface-carousel'}
              />
            }
            nextArrow={
              <CarouselNextArrow
                activeCarouselIndex={1}
                targetDivCnt={surfaceProcessLst.length}
                name={props.isAdd ? 'pattern-surface-carousel-addForm' : 'pattern-surface-carousel'}
              />
            }
          >
            {surfaceProcessLst.map((dis, index) => {
              return (
                <div>
                  <List
                    className="process-pattern-list"
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={dis.surfaceProcLst}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ height: 37 }}>
                          <Col className="parent-pdetail-tbl-subtitle" style={{ borderBottom: '1px solid #a5a5a5' }}>
                            <label style={{ paddingTop: 7 }}>{item.title}</label>
                          </Col>
                        </Row>
                        <Row style={{ height: 37 }}>
                          <Col
                            className="parent-pdetail-tbl-td"
                            style={{ verticalAlign: 'middle', textAlign: 'center', borderRight: '1px solid #a5a5a5' }}
                          >
                            {editMode ? (
                              <Select
                                style={{ paddingTop: 4 }}
                                className="pdetail-ant-select"
                                id="processDetailType"
                                name="processDetailType"
                                ref={tableRef}
                                value={item.processDetailType || '無し'}
                                options={surfaceProcessTypeLst}
                                onChange={(e) => onChangeSurfaceProcType(e, item.key, index)}
                              />
                            ) : (
                              <label style={{ paddingTop: 7 }}>
                                {getNameByNo(item.processDetailType, surfaceProcessTypeLst)}
                              </label>
                            )}
                          </Col>
                        </Row>
                        <Row style={{ height: 37 }}>
                          <Col
                            className="parent-pdetail-tbl-td-mul"
                            style={{ verticalAlign: 'middle', textAlign: 'center', borderRight: '1px solid #a5a5a5' }}
                          >
                            {editMode ? (
                              <Select
                                style={{ paddingTop: 4 }}
                                id="processDetailCode"
                                name="processDetailCode"
                                ref={tableRef}
                                value={item.processDetailCode || '000'}
                                options={item.processDetailValLst}
                                onChange={(e) => onChangeSurfaceProcTypeVal(e, item.key, index)}
                                className={
                                  item.processDetailCode == surfaceProc0List[0].value
                                    ? 'input-non-editable pdetail-ant-select'
                                    : 'input-editable pdetail-ant-select'
                                }
                              />
                            ) : (
                              <label style={{ paddingTop: 7 }}>
                                {getNameByNo(item.processDetailCode, item.processDetailValLst)}
                              </label>
                            )}
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
      {/* IQ3_シャーリング */}
      <Row style={{ height: 37, borderBottom: '1px solid #a5a5a5', display: page === patternPage[0] ? 'none' : '' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderRight: '1px solid #a5a5a5',
          }}
        >
          シャーリング
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={shearingChk}
            onChange={(e) => {
              setShearingChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter(
                (item) => item.processType === 'シャーリング'
              );
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="shearing"
              name="shearing"
              ref={tableRef}
              value={shearing}
              options={shearingProSochiList}
              onChange={(e) => {
                setShearing(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter(
                  (item) => item.processType === 'シャーリング'
                );
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(shearing, shearingProSochiList)}</>
          )}
        </Col>
        <Col span={16} className="parent-pdetail-tbl-td" style={{ borderRight: '1px solid #a5a5a5' }}></Col>
      </Row>
      {/* 組立*/}
      <Row style={{ height: 37, borderBottom: '1px solid #a5a5a5', display: page === patternPage[0] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderRight: '1px solid #a5a5a5',
          }}
        >
          組立
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={assemblyChk}
            onChange={(e) => {
              setAssemblyChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '組立');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="assembly"
              name="assembly"
              ref={tableRef}
              value={assembly}
              options={assemblySochiList}
              onChange={(e) => {
                setAssembly(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '組立');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(assembly, assemblySochiList)}</>
          )}
        </Col>
        <Col span={16} className="parent-pdetail-tbl-td" style={{ borderRight: '1px solid #a5a5a5' }}></Col>
      </Row>
      {/* 梱包*/}
      <Row style={{ height: 37, borderBottom: '1px solid #a5a5a5', display: page === patternPage[0] ? '' : 'none' }}>
        <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, borderRight: '1px solid #a5a5a5' }}>
          梱包
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={packingChk}
            onChange={(e) => {
              setPackingChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '梱包');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="packing"
              name="packing"
              ref={tableRef}
              value={packing}
              options={packingSochiList}
              onChange={(e) => {
                setPacking(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '梱包');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(packing, packingSochiList)}</>
          )}
        </Col>
        <Col span={16} className="parent-pdetail-tbl-td" style={{ borderRight: '1px solid #a5a5a5' }}></Col>
      </Row>
      {/* 検査*/}
      <Row style={{ height: 37, borderBottom: '3px solid #a5a5a5' }}>
        <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, borderRight: '1px solid #a5a5a5' }}>
          検査
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={testChk}
            onChange={(e) => {
              setTestChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '検査');
              targetInfo[0].checked = e.target.checked;
              selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="test"
              name="test"
              ref={tableRef}
              value={test}
              options={testProSochiList}
              onChange={(e) => {
                setTest(e);
                let targetInfo = selectedPatternInfo?.patternProcess?.filter((item) => item.processType === '検査');
                targetInfo[0].deviceCodeKey = e;
                selectedPatternInfo.patternProcess[targetInfo[0].key] = targetInfo[0];
              }}
            />
          ) : (
            <>{getNameByNo(test, testProSochiList)}</>
          )}
        </Col>
        <Col span={16} className="parent-pdetail-tbl-td" style={{ borderRight: '1px solid #a5a5a5' }}></Col>
      </Row>
      {/* 追加工程 */}
      {customProcessLst.map((customGroup, index) => {
        return (
          <Row style={{ height: 'auto' }}>
            <Col
              span={4}
              className="parent-pdetail-tbl-val-title"
              style={{ padding: 5, borderRight: '1px solid #a5a5a5', borderBottom: '1px solid #a5a5a5' }}
            >
              {customGroup.groupNm}
            </Col>
            <Col
              id={props.isAdd ? 'pattern-custom-carousel-addForm' + index : 'pattern-custom-carousel' + index}
              span={20}
              style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}
            >
              <Carousel
                ref={inputFile}
                className="pattern-custom-carousel"
                arrows
                prevArrow={
                  <CarouselPrevArrow
                    activeCarouselIndex={index}
                    targetDivCnt={customGroup.customTypeLst.length}
                    isCustom={true}
                    name={props.isAdd ? 'pattern-custom-carousel-addForm' + index : 'pattern-custom-carousel' + index}
                  />
                }
                nextArrow={
                  <CarouselNextArrow
                    activeCarouselIndex={index}
                    targetDivCnt={customGroup.customTypeLst.length}
                    isCustom={true}
                    name={props.isAdd ? 'pattern-custom-carousel-addForm' + index : 'pattern-custom-carousel' + index}
                  />
                }
              >
                {customGroup.customTypeLst.map((dis, carouselIndex) => {
                  return (
                    <div>
                      <List
                        className="process-pattern-list"
                        grid={{ gutter: 16, column: 5 }}
                        dataSource={dis.customLst}
                        renderItem={(item) => (
                          <List.Item>
                            <Row style={{ height: 37 }}>
                              <Col
                                className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
                                style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
                              >
                                <Checkbox
                                  style={{ marginLeft: 3, marginRight: 10 }}
                                  type="checkbox"
                                  disabled={!editMode}
                                  checked={item.checked}
                                  onChange={(e) =>
                                    onChangeAdditionalProChecked(
                                      e.target.checked,
                                      item.key,
                                      item.deviceCodeKey,
                                      carouselIndex,
                                      index
                                    )
                                  }
                                ></Checkbox>
                                {editMode ? (
                                  <Select
                                    className="pdetail-ant-select-tsuikaPro"
                                    style={{ marginTop: 0 }}
                                    id="deviceName"
                                    name="deviceName"
                                    ref={tableRef}
                                    value={item.deviceCodeKey || ''}
                                    options={item.customSochiLst}
                                    onChange={(e) =>
                                      onChangeAdditionalProType(e, item.key, item.deviceCodeKey, carouselIndex, index)
                                    }
                                  />
                                ) : (
                                  <>{getNameByNo(item.deviceCodeKey, item.customSochiLst)}</>
                                )}
                              </Col>
                            </Row>
                            <Row style={{ height: 37 }}>
                              <Col
                                className="parent-pdetail-tbl-td-tsuikaPro parent-pdetail-tbl-td-align-center"
                                style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #a5a5a5',
                                }}
                              >
                                {editMode ? (
                                  <>
                                    <Button
                                      icon={<SearchOutlined />}
                                      size="small"
                                      onClick={(e) => getCustomerName(e, item, carouselIndex, index)}
                                      type="link"
                                      style={{ marginLeft: 2, marginRight: 3 }}
                                      // disabled={!outSupplier1Chk}
                                    ></Button>
                                    <Tag
                                      className="pdetail-ant-tag"
                                      id="outSupplier1Com"
                                      name="outSupplier1Com"
                                      ref={tableRef}
                                    >
                                      <label style={{ width: '80%' }}>
                                        {getCustomerNameByCode(item.companyCode, clientInfo)}
                                      </label>
                                    </Tag>
                                  </>
                                ) : (
                                  <label style={{ textAlign: 'left', marginLeft: 28 }}>
                                    {getCustomerNameByCode(item.companyCode, clientInfo)}
                                  </label>
                                )}
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        );
      })}
    </>
  );

  return (
    <>
      <div
        className="overflow-auto"
        style={{
          height: (props.isParameter && props.isAdd === undefined) || props.pmxMode ? 760 : 'auto',
          marginTop: props.isParameter ? 8 : 0,
          marginLeft: props.isParameter ? '10px' : '0px',
        }}
      >
        <div id="showData" style={{ width: '95%' }}>
          <Row style={{ height: 37, display: props.isAdd !== undefined ? 'none' : '' }}>
            <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5 }}>
              工程パターン
            </Col>
            <Col
              span={page === patternPage[1] && !props.isParameter ? 6 : 20}
              style={{ verticalAlign: 'middle', padding: 3, display: props.isParameter ? '' : 'none' }}
            >
              {editMode ? (
                <label style={{ textAlign: 'left', marginLeft: 2, marginTop: 5 }}>
                  {getNameByNo(patternNo, patternList)}
                </label>
              ) : (
                <Select
                  style={{ width: page === patternPage[1] && !props.isParameter ? '65%' : '19.8%', marginLeft: 2 }}
                  id="pattern"
                  name="pattern"
                  ref={tableRef}
                  value={patternNo}
                  options={patternList}
                  onChange={onChangeProcessPatternInfo}
                  className={'input-editable'}
                />
              )}
            </Col>
            {/** 親部品・子部品の工程選択画面み表示 */}
            <Col
              span={page === patternPage[1] && !props.isParameter ? 6 : 20}
              style={{ verticalAlign: 'middle', padding: 3, display: props.isParameter ? 'none' : '' }}
            >
              {props.kouteiSentakuEditMode ? (
                <Select
                  style={{ width: page === patternPage[1] && !props.isParameter ? '65%' : '19.8%', marginLeft: 2 }}
                  id="pattern"
                  name="pattern"
                  ref={tableRef}
                  value={patternNo}
                  options={patternList}
                  onChange={onChangeProcessPatternInfo}
                  className={'input-editable'}
                />
              ) : (
                <label style={{ textAlign: 'left', marginLeft: 2, marginTop: 5 }}>
                  {getNameByNo(patternNo, patternList)}
                </label>
              )}
            </Col>
            {/** 子部品の選択中部品情報表示、子部品画面のみ表示 */}
            <Col
              span={4}
              className="parent-pdetail-tbl-val-title"
              style={{
                padding: 5,
                display: page === patternPage[1] && !props.isParameter && !props.pmxMode ? '' : 'none',
              }}
            >
              選択中 部品
            </Col>
            <Col
              span={10}
              style={{
                verticalAlign: 'middle',
                padding: 3,
                display: page === patternPage[1] && !props.isParameter && !props.pmxMode ? '' : 'none',
              }}
            >
              <Select
                style={{ width: '65%', marginLeft: 2 }}
                id="partInfo"
                name="partInfo"
                ref={tableRef}
                value={partInfo}
                options={iq3PartList}
                onChange={(e) => {
                  setPartInfo(e);
                  if (page === patternPage[1] && !props.isParameter) {
                    props.updateSelectedData(e);
                  }
                }}
                className={props.kouteiSentakuEditMode ? 'input-editable' : 'input-non-editable'}
              />
            </Col>
          </Row>
          <div className="kouteiPatternDiv" style={{ marginTop: '10px' }}>
            {renderPartComponent()}
          </div>
        </div>
      </div>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}

      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={selectedCustomerData}
          title={ItakuSaki}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default ProcessPatternForm;
