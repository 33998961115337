/**
 * クラス名：板金子部品見積詳細
 * 説明：板板金子部品見積詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Input, Form, Row, Col, Select } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

const materialNmSelect = [
  { id: 1, value: 'SPCC - 2.3', label: 'SPCC - 2.3' },
  { id: 2, value: 'SPHC(黒皮) - 1.6', label: 'SPHC(黒皮) - 1.6' },
  { id: 3, value: 'SPHC(黒皮) - 3.2', label: 'SPHC(黒皮) - 3.2' },
];
const materialSelect = [
  { id: 1, value: '01_SPCC', label: '01_SPCC' },
  { id: 2, value: '02_SPHC（黒皮）', label: '02_SPHC（黒皮）' },
  { id: 3, value: '03_SPHC（酸洗）', label: '03_SPHC（酸洗）' },
  { id: 4, value: '04_SECC(ボンデ)', label: '04_SECC(ボンデ)' },
  { id: 5, value: '05_SS400', label: '05_SS400' },
  { id: 6, value: '06_ZAM', label: '06_ZAM' },
];
const thicknessSelect = [
  { id: 1, value: '0.8', label: '0.8' },
  { id: 2, value: '1.0', label: '1.0' },
  { id: 3, value: '1.2', label: '1.2' },
  { id: 4, value: '1.6', label: '1.6' },
  { id: 5, value: '2.0', label: '2.0' },
  { id: 6, value: '2.3', label: '2.3' },
  { id: 7, value: '2.6', label: '2.6' },
  { id: 8, value: '3.2', label: '3.2' },
  { id: 9, value: '4.5', label: '4.5' },
  { id: 10, value: '6.0', label: '6.0' },
  { id: 11, value: '9.0', label: '9.0' },
  { id: 12, value: '10.0', label: '10.0' },
];

const IQ3EstimateDetail = forwardRef((props, ref) => {
  const location = useLocation();
  const [rowKey, setRowKey] = useState(0);
  const [partNo, setPartNo] = useState('');
  const [img, setImg] = useState('');
  const [imgNo, setImgNo] = useState('');
  const [processNm, setProcessNm] = useState('');
  const [partNm, setPartNm] = useState('');
  const [partCnt, setPartCnt] = useState(0);
  const [material, setMaterial] = useState('');
  const [thickness, setThickness] = useState('');
  const [materialNm, setMaterialNm] = useState('');
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [materialCost, setMaterialCost] = useState('');
  const [processCost, setProcessCost] = useState('');
  const [purchaseCost, setPurchaseCost] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState('');
  const [totalCostPrice, setTotalCostPrice] = useState('');
  const [totalEstimatedAmount, setTotalEstimatedAmount] = useState('');
  const [costProfit, setCostProfit] = useState('');
  const [estimateProfit, setEstimateProfit] = useState('');
  const [profitRate, setProfitRate] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [totalMaterialCost, setTotalMaterialCost] = useState('');
  const [totalProcessCost, setTotalProcessCost] = useState('');

  useEffect(() => {
    const childPartDetail = props.selectedIQ3DataDetail;
    setDetailData({ ...childPartDetail });
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (childPartDetail == undefined || childPartDetail == null) {
      setRowKey('');
      setPartNo('');
      setImgNo('');
      setProcessNm('');
      setPartNm('');
      setPartCnt('');
      setMaterial('');
      setThickness('');
      setMaterialNm('');
      setWeight('');
      setSurfaceArea('');
      setMaterialCost('');
      setProcessCost('');
      setPurchaseCost('');
      setCostPrice('');
      setEstimatedAmount('');
      setTotalCostPrice('');
      setTotalEstimatedAmount('');
      setImg('');
      setCostProfit('');
      setEstimateProfit('');
      setProfitRate('');
    } else {
      setImg(childPartDetail.image);
      setPartNo(childPartDetail.no);
      setImgNo(childPartDetail.imgNo);
      setProcessNm(childPartDetail.processNm);
      setPartNm(childPartDetail.partNm);
      setPartCnt(childPartDetail.partCnt);
      setMaterial(childPartDetail.material);
      setThickness(childPartDetail.thickness);
      setMaterialNm(childPartDetail.materialNm);
      setWeight(childPartDetail.weight);
      setSurfaceArea(
        childPartDetail.surfaceArea != undefined
          ? childPartDetail.surfaceArea.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : ''
      );
      setMaterialCost(
        childPartDetail.materialCost != undefined
          ? childPartDetail.materialCost.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      let totalMaterialCost =
        childPartDetail.materialCost != undefined ? childPartDetail.materialCost * childPartDetail.partCnt : 0;
      setTotalMaterialCost(
        totalMaterialCost != undefined
          ? totalMaterialCost.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );

      setProcessCost(
        childPartDetail.processCost != undefined
          ? childPartDetail.processCost.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      let totalProcessCost =
        childPartDetail.processCost != undefined ? childPartDetail.processCost * childPartDetail.partCnt : 0;
      setTotalProcessCost(
        totalProcessCost != undefined
          ? totalProcessCost.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setPurchaseCost(
        childPartDetail.purchaseCost != undefined
          ? childPartDetail.purchaseCost.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setCostPrice(
        childPartDetail.costPrice != undefined
          ? childPartDetail.costPrice.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setEstimatedAmount(
        childPartDetail.estimatedAmount != undefined
          ? childPartDetail.estimatedAmount.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setTotalCostPrice(
        childPartDetail.totalCostPrice != undefined
          ? childPartDetail.totalCostPrice.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setTotalEstimatedAmount(
        childPartDetail.totalEstimatedAmount != undefined
          ? childPartDetail.totalEstimatedAmount.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setCostProfit(
        childPartDetail.costProfit != undefined
          ? childPartDetail.costProfit.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setEstimateProfit(
        childPartDetail.estimateProfit != undefined
          ? childPartDetail.estimateProfit.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0
      );
      setProfitRate(childPartDetail.profitRate);
    }
  }, [props.selectedIQ3DataDetail, props.editMode]);

  const onUpdateDetail = (name, value) => {
    detailData[name] = value;
    if (name == 'partCnt') {
      let processCnt = detailData.processCost * detailData.partCnt;
      let costPrice = detailData.costPrice * detailData.partCnt;
      let totalCostPrice = detailData.totalCostPrice * detailData.partCnt;
      detailData['processCost'] = processCnt == undefined ? 0 : processCnt;
      detailData['costPrice'] = costPrice == undefined ? 0 : costPrice;
      detailData['totalCostPrice'] = totalCostPrice == undefined ? 0 : totalCostPrice;
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getIQ3EstimateDetailInfo: () => {
        return detailData;
      },
    }),
    [detailData]
  );

  return (
    <>
      <div style={{ display: 'flex', float: 'left', width: '100%' }}>
        {/* 部品プロパティアリア */}
        <div style={{ width: '50%' }}>
          {' '}
          <Table className="propertiesTbl">
            <thead>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">No.</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className="input-non-editable "
                    name="partNo"
                    id="partNo"
                    value={partNo}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">図番</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable '}
                    name="imgNo"
                    value={imgNo}
                    // onChange={(e) => setImgNo(e.target.value)}
                    onChange={(e) => {
                      setImgNo(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">名称</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    name="partNm"
                    value={partNm}
                    // onChange={(e) => setPartNm(e.target.value)}
                    onChange={(e) => {
                      setPartNm(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">工程パターン</label>
                </td>

                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    name="processNm"
                    id="processNm"
                    type="text"
                    value={processNm}
                    // onChange={(e) => {
                    //   setProcessNm(e.target.value);
                    //
                    //   onUpdateDetail(e.target.name, e.target.value);
                    // }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">員数</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '35.5%' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    name="partCnt"
                    value={partCnt}
                    onChange={(e) => {
                      setPartCnt(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                    // disabled
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材料名称</label>
                </td>
                <td className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      name="materialNm"
                      value={materialNm}
                      // onChange={(e) => {
                      //   setMaterialNm(e);
                      // }}
                      onChange={(e) => {
                        setMaterialNm(e);
                        detailData['materialNm'] = e;
                        onUpdateDetail('materialNm', e);
                      }}
                      style={{ marginLeft: 5, width: '98.2%' }}
                    >
                      {materialNmSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{materialNm}</label>
                  )}
                </td>
              </tr>

              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材質</label>
                </td>
                <td className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      name="material"
                      value={material}
                      // onChange={(e) => {
                      //   setMaterial(e);
                      // }}
                      onChange={(e) => {
                        setMaterial(e);
                        detailData['material'] = e;
                        onUpdateDetail('material', e);
                      }}
                      style={{ marginLeft: 5, width: '98.2%' }}
                    >
                      {materialSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{material}</label>
                  )}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">板厚</label>
                </td>
                <td className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      name="thickness"
                      className="iq3-detail-select"
                      value={thickness}
                      // onChange={(e) => {
                      //   setThickness(e);
                      // }}
                      onChange={(e) => {
                        setThickness(e);
                        detailData['thickness'] = e;
                        onUpdateDetail('thickness', e);
                      }}
                      style={{ marginLeft: 5, width: '35.5%' }}
                    >
                      {thicknessSelect.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-non-editable '}
                      type="text"
                      value={thickness}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">重量(kg)</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    type="text"
                    value={weight}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">表面積(㎠)</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    type="text"
                    value={surfaceArea}
                  />
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        {/* 原価 */}
        <div style={{ marginLeft: '10px', width: '30%' }}>
          {/** 原価*/}
          <Row style={{ height: '45px', paddingTop: '20px' }}>
            <Col span={24}>【原価】</Col>
          </Row>
          <Row
            style={{
              // border: 'solid',
              // borderWidth: 1,
              // borderColor: '#a5a5a5',
              backgroundColor: '#1063aa',
              color: 'white',
              height: '45px',
              paddingTop: '3px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}></p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>単品</p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>合計</p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 1,
              borderColor: '#a5a5a5',
            }}
          >
            <Col span={2}>
              <p style={{ margin: 0, fontSize: '13px' }}>原価</p>
            </Col>
            <Col span={11}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>{'¥' + costPrice}</p>
            </Col>
            <Col span={11}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {'¥' + totalCostPrice}
              </p>
            </Col>
          </Row>

          {/** 原価詳細*/}
          <Row style={{ height: '45px', paddingTop: '20px' }}>
            <Col span={24}>【原価内訳】</Col>
          </Row>
          <Row
            style={{
              backgroundColor: '#1063aa',
              color: 'white',
              height: '45px',
              paddingTop: '3px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}></p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>単品</p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>合計</p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
              borderBottom: 0,
              borderBottomWidth: 0,
              borderBottomColor: 'none',
            }}
          >
            <Col span={4}>
              <p style={{ margin: 0, fontSize: '13px' }}>材料費</p>
            </Col>
            <Col span={10}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '25px' }}>
                {'¥' + materialCost}
              </p>
            </Col>
            <Col span={10}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {'¥' + totalMaterialCost}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
              borderBottom: 0,
              borderBottomWidth: 0,
              borderBottomColor: 'none',
            }}
          >
            <Col span={4}>
              <p style={{ margin: 0, fontSize: '13px' }}>加工費</p>
            </Col>
            <Col span={10}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '25px' }}>
                {'¥' + processCost}
              </p>
            </Col>
            <Col span={10}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {'¥' + totalProcessCost}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
            }}
          >
            <Col span={4}>
              <p style={{ margin: 0, fontSize: '13px' }}>購入品費</p>
            </Col>
            <Col span={10}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '25px' }}>
                {'¥' + purchaseCost}
              </p>
            </Col>
            <Col span={10}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {'¥' + purchaseCost}
              </p>
            </Col>
          </Row>
        </div>
        {/* イメージアリア */}
        <div
          style={{
            width: '20%',
            marginLeft: '10px',
            marginTop: '23px',
          }}
        >
          <Row>
            <Col span={24}>【イメージ】</Col>
          </Row>
          <div className="detailImage">{img}</div>
        </div>
      </div>
    </>
  );
});

export default IQ3EstimateDetail;
