/**
 * クラス名：板金の加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Checkbox, Space, Button, Radio } from 'antd';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { JPYs } from '../../../common/Common';
import { commonModal } from '../../../common/CommonModal';
import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';
const Device_KakouJikan = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [category, setCategory] = useState('');
  const [isLength, setIsLength] = useState(false);
  const [isItaatsu, setIsItaatsu] = useState(false);
  const [isSize, setIsSize] = useState(false);

  const [lengthData, setLengthData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  const [mode, setMode] = useState('simple');

  // 簡易入力と詳細入力
  const [sizeData, setSizeData] = useState([]);
  const [sizeDataTemp, setSizeDataTemp] = useState([]);
  const [kaniListData, setKaniListData] = useState([]);

  const [itaatsu, setItaatsu] = useState([]);
  const [itaatsuTemp, setItaatsuTemp] = useState([]);
  // 材質区分
  const [zaishitsuData, setZaishitsuData] = useState([]);

  const [btnEnable, setBtnEnable] = useState(true);

  // 員数に関わらず1カットにする
  const [flag, setFlag] = useState(false);

  const [cutTime, setCutTime] = useState('00:00:00');

  useEffect(() => {
    setEditMode(props.editMode);
    setCategory(props.processName);

    setLengthData(props.area);
    setDetailsLengthTemp(props.area);
    setDetailsList(props.listData);

    setSizeData(props.iq3SizeMaster);
    setSizeDataTemp(props.iq3SizeMaster);
    setKaniListData(props.iq3KaniListData);

    setItaatsu(props.itaatsu1);
    setItaatsuTemp(props.itaatsu1);
    setZaishitsuData(props.zaishitsu);

    setCutTime(props.cutTime);
    setFlag(props.shirringflag);
  }, [
    props.editMode,
    props.processName,
    props.area,
    props.listData,
    props.iq3SizeMaster,
    props.iq3KaniListData,
    props.itaatsu1,
    props.zaishitsu,
    props.cutTime,
    props.shirringflag,
  ]);
  const addLength = () => {
    setIsLength(true);
  };
  const thickness = () => {
    setIsItaatsu(true);
  };
  const size = () => {
    setIsSize(true);
  };
  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addSizeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>サイズ</p>
    </div>
  );
  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const closeAddModal = () => {
    if (isLength === true) {
      setIsLength(false);
    } else if (isSize === true) {
      setIsSize(false);
    } else if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };
  const handleLengthChange = (event, id, field) => {
    const updatedData = detailsLengthTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const addLengthRow = (id) => {
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsLengthTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e) => {
    if (detailsLengthTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsLengthTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
    }
  };

  const addLengthMaster = () => {
    const deletedId = [];

    lengthData.forEach((item1, index) => {
      const found = detailsLengthTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsLengthTemp
      .filter((item) => item.displayFlag && !lengthData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = detailsList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsLengthTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsLengthTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsLength(false);
    setDetailsLengthTemp(detailsLengthTemp);

    setDetailsList(sortedList);
    props.updatedArea(detailsLengthTemp);

    props.updatedIQ3KakouJikanList(sortedList);
  };
  const cancelMasterData = () => {
    if (isLength === true) {
      setDetailsLengthTemp(lengthData);
      setIsLength(false);
    } else if (isSize === true) {
      setIsSize(false);
      setSizeDataTemp(sizeData);
    } else if (isItaatsu === true) {
      setIsItaatsu(false);
      setItaatsuTemp(itaatsu);
    }
  };
  const addData = () => {};
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleLengthChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addLengthMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const handleSizeChange = (event, id, field) => {
    const updatedData = sizeDataTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setSizeDataTemp(updatedData);
  };
  const addSize = (id) => {
    const copyData = sizeDataTemp.slice(); // Create a copy of the original array

    const insertIndex = sizeDataTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: sizeDataTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSizeDataTemp(copyData);
    }
  };
  const deleteSize = (id, e) => {
    if (sizeDataTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = sizeDataTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setSizeDataTemp(updatedData);
    }
  };
  const deleteListData = (list, deletedId) => {
    const deletedData = list.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    return deletedData;
  };
  const deleteListData2 = (data, deletedId) => {
    const updatedData = [...data];

    updatedData.forEach((item) => {
      item.lstData.forEach((subitem) => {
        subitem.list.forEach((listItem) => {
          if (listItem.lstData) {
            listItem.lstData = listItem.lstData.filter((lstItem) => !deletedId.includes(lstItem.key));
          }
        });
      });
    });

    return updatedData;
  };

  const updateListData = (deletedData, newId, detailsData) => {
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: detailsData,
        });
      });

      return item;
    });
    return updatedData;
  };
  const updateListData2 = (deletedData, newId) => {
    // const updatedData = deletedData.map((item) => {
    //   newId.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //       list: detailsData,
    //     });
    //   });

    //   return item;
    // });
    // const updatedData = deletedData.map((item) => {
    //   item.lstData.map((i) => {
    //     i.list.map((j) => {
    //       newId.forEach((key) => {
    //         // Add new objects to lstData
    //         j.lstData.push({
    //           key: key,
    //           value: '',
    //         });
    //       });
    //     });
    //   });

    // return item;
    // });
    const updatedData = JSON.parse(JSON.stringify(deletedData)); // Create a deep copy of the data array

    updatedData.forEach((item) => {
      item.lstData.forEach((i) => {
        i.list.forEach((j) => {
          if (j.lstData) {
            newId.forEach((key) => {
              // Add new objects to lstData
              j.lstData.push({
                key: key,
                value: '',
              });
            });
          }
        });
      });
    });

    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    return sortedList;
  };

  const sortListData2 = (list, masterData) => {
    const sortedList = JSON.parse(JSON.stringify(list));

    sortedList.forEach((item) => {
      item.lstData.forEach((i) => {
        i.list.forEach((j) => {
          if (j.lstData) {
            j.lstData.sort((a, b) => {
              const aIndex = masterData.findIndex((obj) => obj.id === a.key);
              const bIndex = masterData.findIndex((obj) => obj.id === b.key);
              return aIndex - bIndex;
            });
          }
        });
      });
    });

    return sortedList;
  };

  const createListData = (type, masterData) => {
    const result = type.map((materialType, key) => {
      const filteredData2 = masterData
        .filter((subitem) => subitem.displayFlag === true)
        .map((item) => {
          return {
            key: item.id,
            value: '',
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
      };
    });
    return result;
  };
  const addSizeMaster = () => {
    const deletedId = [];

    sizeData.forEach((item1, index) => {
      const found = sizeDataTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = sizeDataTemp
      .filter((item) => item.displayFlag && !sizeData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData(kaniListData, deletedId);

    let detailsData = createListData(zaishitsuData, itaatsu);
    const updatedData = updateListData(deletedData, newId, detailsData);

    const sortedList = sortListData(updatedData, sizeDataTemp);

    setIsSize(false);
    setSizeData(sizeDataTemp);

    props.updatediq3SizeMaster(sizeDataTemp);

    setKaniListData(sortedList);
    props.updatediq3KaniListData(sortedList);
  };
  const addSizeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sizeDataTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleSizeChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSize(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSize(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addSizeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const handleItaatsuChange = (event, id, field) => {
    const updatedData = itaatsuTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };
  const addItaatsu = (id) => {
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: itaatsuTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  const deleteItaatsu = (id, e) => {
    if (itaatsuTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = itaatsuTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
    }
  };
  const addItaatsuMaster = () => {
    setBtnEnable(false);
    const deletedId = [];

    itaatsu.forEach((item1, index) => {
      const found = itaatsuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = itaatsuTemp
      .filter((item) => item.displayFlag && !itaatsu.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData2(kaniListData, deletedId);

    const updatedData = updateListData2(deletedData, newId);

    const sortedList = sortListData2(updatedData, itaatsuTemp);

    setIsItaatsu(false);
    setItaatsu(itaatsuTemp);
    props.updatedItaatsu(itaatsuTemp);
    setKaniListData(sortedList);

    props.updatediq3KaniListData(sortedList);
  };
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleItaatsuChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addItaatsuMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const changeMageSuuJikan = (event, rowIndex, colIndex) => {
    const copyData = [...detailsList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setDetailsList(updatedData);
    props.updatedIQ3KakouJikanList(updatedData);
  };
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...kaniListData];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setKaniListData(updatedData);
    props.updatediq3KaniListData(updatedData);
  };
  const handleInputChange = (event, rowIndex, colIndex) => {
    const copyData = [...kaniListData];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setKaniListData(updatedData);
    props.updatediq3KaniListData(updatedData);
  };
  const itaatsulistDataChange = (rowIndex, colIndex, subrowIndex, subcolIndex, value) => {
    setBtnEnable(false);
    const copyData = [...kaniListData];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    const firstList = updatedData[rowIndex].lstData[colIndex].list;

    updatedData[rowIndex].lstData[colIndex].list[subrowIndex].lstData[subcolIndex].value = value;
    setKaniListData(updatedData);
    props.updatediq3KaniListData(updatedData);
  };
  const changeflag = (e) => {
    const isChecked = e.target.checked;
    setFlag(isChecked);
    props.updateShirringFlag(isChecked);
  };
  const changeTime = (e) => {
    setCutTime(e);
    props.updateCutTime(e);
  };
  return (
    <>
      {category === 'シャーリング' ? (
        <>
          <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
            <Table style={{ width: '40%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    1カット時間(分)
                  </th>
                  <th style={{ width: '20%' }} className="tbl-header">
                    員数に関わらず1カットにする
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>
                    <Row align="middle">
                      {!editMode ? (
                        <>
                          <Col span={24}>
                            <label>{cutTime}</label>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={10}>
                            <Input
                              value={cutTime}
                              onChange={(e) => {
                                setCutTime(e.target.value);
                              }}
                            ></Input>
                          </Col>
                          <Col span={14}>
                            <CaretUpOutlined
                              style={{
                                fontSize: 16 + 'px',
                                marginLeft: 3,
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(cutTime, '10min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(cutTime, '10min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(cutTime, 'min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(cutTime, 'min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(cutTime, 'sec');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(cutTime, 'sec');
                                changeTime(editedTime);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </td>
                  <td>
                    <Checkbox
                      style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      checked={flag}
                      onChange={changeflag}
                    ></Checkbox>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </>
      ) : category === 'バリ取' ? (
        <>
          <Row className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
            <Table
              style={{
                width:
                  lengthData.filter((i) => i.displayFlag === true).length > 7
                    ? '100%'
                    : lengthData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
              }}
            >
              <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                <td style={{ width: '196px' }} className="fixedCol">
                  <Row justify="center" className="tbl-header">
                    {editMode ? (
                      <>
                        <Space>
                          <label>加工時間</label>
                          <label>/</label>
                          <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                            面積
                          </Button>
                        </Space>
                      </>
                    ) : (
                      <>
                        <Space>
                          <label>加工時間</label>
                          <label>/</label>
                          <label>面積</label>
                        </Space>
                      </>
                    )}
                  </Row>
                </td>

                {lengthData
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item) => (
                    <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                      ～{item.max}cm<sup className="tbl-header">2</sup>
                    </td>
                  ))}
              </tr>

              {detailsList.map((row, rowIndex) => (
                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px', color: 'white' }} className="tbl-header fixedCol">
                    {row.materialType}
                  </td>
                  {row.lstData.map((col, colIndex) => (
                    <td style={{ width: '98px' }}>
                      <Input
                        value={col.value}
                        onChange={(e) => changeMageSuuJikan(e, rowIndex, colIndex)}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </Table>
          </Row>
        </>
      ) : category === '2次加工' ? (
        <>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Row>
              <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
                <Radio.Button value="simple" style={{ pointerEvents: btnEnable ? 'auto' : 'none' }}>
                  簡易入力
                </Radio.Button>
                <Radio.Button value="details">詳細入力</Radio.Button>
              </Radio.Group>
            </Row>
            <Row className="qty-table-container">
              <Table
                style={{
                  width:
                    sizeData.filter((i) => i.displayFlag === true).length > 8
                      ? '100%'
                      : sizeData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        種別 /
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={size} style={{ pointerEvents: 'auto' }}>
                              サイズ
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {sizeData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td
                        style={{
                          backgroundColor: '#1063aa',
                          width:
                            mode === 'simple'
                              ? '98px'
                              : itaatsu.filter((i) => i.displayFlag === true).length > 4
                              ? '588px'
                              : itaatsu.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                        }}
                      >
                        ～M{item.max}
                      </td>
                    ))}
                </tr>

                {kaniListData.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '98px' }} className="tbl-header fixedCol2">
                      {row.materialType}
                    </td>

                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: mode === 'simple' ? '98px' : '500px' }}>
                        {mode === 'simple' ? (
                          <>
                            <Input
                              value={col.value}
                              onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                              style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable'}
                            />
                          </>
                        ) : (
                          <>
                            <Row className="subTbl">
                              <Table
                                style={{
                                  marginBottom: 0,
                                  width:
                                    itaatsu.filter((i) => i.displayFlag === true).length > 4
                                      ? '588px'
                                      : itaatsu.filter((i) => i.displayFlag === true).length * 98 + 198 + 'px',
                                }}
                              >
                                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                                  <td style={{ width: '196px' }} className="fixedCol">
                                    <Row justify="center" className="tbl-header">
                                      <Space>
                                        種別 /
                                        {editMode ? (
                                          <>
                                            <Button
                                              className="cancleButton"
                                              onClick={thickness}
                                              style={{ pointerEvents: 'auto' }}
                                            >
                                              板厚
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            <label>板厚</label>
                                          </>
                                        )}
                                      </Space>
                                    </Row>
                                  </td>

                                  {itaatsu
                                    .filter((subitem) => subitem.displayFlag === true)
                                    .map((item) => (
                                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}t</td>
                                    ))}
                                </tr>

                                {col.list.map((subrow, subrowIndex) => (
                                  <tr style={{ textAlign: 'center' }}>
                                    <td style={{ width: '196px' }} className="tbl-header fixedCol">
                                      {subrow.materialType}
                                    </td>

                                    {subrow.lstData.map((subcol, subcolIndex) => (
                                      <td style={{ width: '98px' }}>
                                        <Input
                                          value={subcol.value}
                                          // onChange={(e) => itaatsulistDataChange(e, rowIndex, colIndex)}
                                          onChange={(e) =>
                                            itaatsulistDataChange(
                                              rowIndex,
                                              colIndex,
                                              subrowIndex,
                                              subcolIndex,
                                              e.target.value
                                            )
                                          }
                                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                                        />
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </Table>
                            </Row>
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* 面積 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* サイズ */}
      {isSize
        ? commonModal(
            isSize,
            addSizeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSizeContent,
            null,
            null,
            true
          )
        : ''}
      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_KakouJikan;
