import React, { Component, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

const IQ3KouteiInput_Program = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  const [programVal, setProgramVal] = useState([]);

  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    const programs = props.programVal;
    setProgramVal(programs);
  }, [props.programVal]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const changeValue = (e, name) => {
    const updatedData = programVal.map((row) => {
      return { ...row, [name]: e.target.value };
    });
    setProgramVal(updatedData);
  };
  return (
    <>
      <Row className="mt-1 registerModal">
        <Table className="kouteiinput" style={{ width: '44%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '22%' }} className="tbl-header">
                要素数
              </th>
              <th style={{ width: '22%' }} className="tbl-header">
                自動抽出(要素数)
              </th>
            </tr>
          </thead>

          <tbody>
            {programVal.map((i) => (
              <>
                <tr>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.yousousuu}
                      onChange={(e) => changeValue(e, 'yousousuu')}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center', height: 28 }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.chuushutsu}
                      onChange={(e) => changeValue(e, 'chuushutsu')}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center', height: 28 }}
                    ></Input>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Program;
