/**
 * クラス名：板金子部品見積
 * 説明：板金子部品のタブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Button, Row, Input, Col, Space, Image, Tooltip, Tabs } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import '../../assets/styles/common.css';
import '../../assets/styles/style.css';
import down_Arrow from '../../assets/images/down_Arrow.png';
import up_Arrow from '../../assets/images/up_Arrow.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import IQ3EstimateDetail from './IQ3EstimateDetail';
import KouteiSuryouBetsuBtn from '../parent/KouteiSuryouBetsuBtn';
import IQ3Purchase from './IQ3Purchase';
import IQ3KouteiCalculate from '../iq3/IQ3KouteiCalculate';
import IQ3KouteiInput from '../iq3/IQ3KouteiInput';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
import IQ3MaterialDetail from './IQ3MaterialDetail';
import {
  commonTaiochuModal,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
} from '../common/CommonModal';
import { iq3InitData } from '../common/Constant';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Prev } from 'react-bootstrap/esm/PageItem';

dayjs.extend(customParseFormat);

const rowdata = {
  drawingno: '',
  name: '',
  customercode: '',
  customername: '',
  lotno: 1,
  order: '',
};

const IQ3Estimate = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([0]);
  const [isListOpen, setIsListOpen] = useState(true);
  const [selectedData, setSelectedData] = React.useState(0);
  const [selectedIQ3DataDetail, setSelectedIQ3DataDetail] = React.useState(0);
  const [modalTitleTxt, setMdalTitleTxt] = useState('確認');
  // add row
  const [rows, initRow] = useState([]);
  // 確認ダイアログ
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [discardMode, setDiscardMode] = useState(false);
  const [iQ3DataLst, setIQ3DataLst] = useState([]);
  const [targetRowKey, setTargetRowKey] = useState([0]);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);
  const [currentRef, setCurrentRef] = useState([]);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');

  const [isDelete, setIsDelete] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  // 板金子部品プロパティ情報
  let iq3DetailRef = useRef();

  // 工程選択
  let iq3KouteiSentakuRef = useRef();

  // 工程計算
  let iq3KouteiCalRef = useRef();

  // 工程入力
  let iq3KouteiInputRef = useRef();

  // 購入品
  let iq3PurchaseRef = useRef();

  let checkedRow = [];

  //親部品・子部品タブ切替により呼び出す
  useEffect(() => {
    setEditMode(false);
  }, [props.activePartMenu]);

  //親部品・板金子部品情報変更により呼び出す
  useEffect(() => {
    let targetKey = props.targetRowKey == undefined ? currentRecordKey : props.targetRowKey;
    setTargetRowKey(targetKey);
    setIQ3DataLst(props.iq3Data);
    setSelectedEstimateData(props.selectedEstimateData);
    setSelectedRowKeys([targetKey]);
    initRow([...rows, rowdata]);
    setSelectedData(props.iq3Data != undefined ? props.iq3Data[targetKey] : []);
    setSelectedIQ3DataDetail(props.iq3Data != undefined ? props.iq3Data[targetKey] : []);
    navigate(':estimate.iq3');
  }, [props.iq3Data]);

  //編集モード切替により呼び出す
  useEffect(() => {
    setEditMode(props.editMode);
    if (props.editMode) {
      document.getElementById('iq3-update').style.display = 'block';
      document.getElementById('iq3-discard').style.display = 'block';
      document.getElementById('iq3-edit').style.display = 'none';
    } else {
      document.getElementById('iq3-update').style.display = 'none';
      document.getElementById('iq3-discard').style.display = 'none';
      document.getElementById('iq3-edit').style.display = 'block';
    }
  }, [props.editMode]);

  // Scrollの移動
  const scrollTo = (index, blockType) => {
    // Scrollの移動
    document.getElementById(index)?.scrollIntoView({ behavior: 'smooth', block: blockType });
  };

  const discardConfirmOk = () => {
    document.getElementById('iq3-update').style.display = 'none';
    document.getElementById('iq3-discard').style.display = 'none';
    document.getElementById('iq3-edit').style.display = 'block';
    setEditModeCancelConfirm(false);
    setSelectedRowKeys(currentRecordKey);
    setSelectedData(currentRecord);
    setSelectedIQ3DataDetail(currentRecord[0]);
    setEditMode(false);
    // props.childEditMode(false);
    navigate(':estimate.iq3');
  };

  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setCurrentRecordKey(checkedRow);
    setCurrentRecord(record.slice(-1));
    if (props.editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setSelectedRowKeys(checkedRow);
      setSelectedData(record.slice(-1)[0]);
      setSelectedIQ3DataDetail(record.slice(-1)[0]);
      navigate(':estimate.iq3');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onRowCheckboxSelect,
  };

  const editData = () => {
    setEditMode(true);
    props.updateMainEstimateEditMode(true);
    document.getElementById('iq3-update').style.display = 'block';
    document.getElementById('iq3-discard').style.display = 'block';
    document.getElementById('iq3-edit').style.display = 'none';
  };

  const updateData = () => {
    setDiscardMode(false);
    setConfirmMsgOpen(true);
  };

  const discardData = () => {
    setDiscardMode(true);
    setConfirmMsgOpen(true);
  };

  const confirmOk = () => {
    setConfirmMsgOpen(false);
    setEditMode(false);
    props.updateMainEstimateEditMode(false);
    // document.getElementById('iq3-update').style.display = 'none';
    // document.getElementById('iq3-discard').style.display = 'none';
    // document.getElementById('iq3-edit').style.display = 'block';
    let selectedParentEstimateData = { ...selectedEstimateData };
    if (activeTabKey == '1') {
      // 板金基本情報
      if (!discardMode) {
        const iq3Detail = iq3DetailRef?.current?.getIQ3EstimateDetailInfo();
        if (iq3Detail != null) {
          console.log('iq3Detail--', iq3Detail.key);
          const data = [...iQ3DataLst];
          data[iq3Detail.key] = iq3Detail;
          setIQ3DataLst(data);
          updateEstiateBasicInfoBydetailUpdate(iq3Detail);
          selectedParentEstimateData.iq3DataLst = data;
          setSelectedIQ3DataDetail(iq3Detail);
        }
      }
    } else if (activeTabKey == '2') {
      // 工程選択
      if (!discardMode) {
        const kouteiSentakuInfo = currentRef?.current?.getKouteiSentakuInfo();
        const data = [...iQ3DataLst];
        selectedParentEstimateData.kouteiSentakuInfo = kouteiSentakuInfo;
      }
    } else if (activeTabKey == '3') {
      // 工程入力
      if (!discardMode) {
        const kouteiInputInfo = currentRef?.current?.getKouteiInputInfo();
        selectedParentEstimateData.kouteiInputInfo = kouteiInputInfo;
      }
    } else if (activeTabKey == '4') {
      // 工程積算
      if (!discardMode) {
        const kouteiCalInfo = currentRef?.current?.getKouteiCalInfo();
        selectedParentEstimateData.kouteiCalInfoEditData = kouteiCalInfo;
      }
    } else if (activeTabKey == '5') {
      // 購入品
      if (!discardMode) {
        const parentPurchaseInfo = currentRef?.current?.getParentPurchaseInfo();
        selectedParentEstimateData.parentPurchaseInfo = parentPurchaseInfo;
      }
    } else if (activeTabKey == '6') {
      // 添付ファイル
    } else if (activeTabKey == '7') {
      // メモ
    }
    setSelectedEstimateData(selectedParentEstimateData);
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
    // props.childEditMode(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const partListCollapseChange = (key, e) => {
    if (key === 0) {
      setIsListOpen(false);
      document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '0 1 0px';
      document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '100 1 0px';
    } else {
      setIsListOpen(true);
      document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '50 1 0px';
      document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '50 1 0px';
    }
  };

  const updateSelectedData = (selectedPartKey) => {
    let selectedData = iQ3DataLst.filter((item) => item.key == selectedPartKey);
    setSelectedIQ3DataDetail(selectedData.length > 0 ? selectedData[0] : iQ3DataLst[0]);
    let checkedRow = [];
    checkedRow.push(selectedPartKey);
    setSelectedRowKeys(checkedRow);
    scrollTo('iq3EstimateTbl', 'nearest');
  };

  // import PMX file
  const importPMXFile = (pmxData) => {
    setIQ3DataLst(pmxData);
    setSelectedRowKeys([0]);
    setSelectedData(pmxData[0]);
    setSelectedIQ3DataDetail(pmxData[0]);
    navigate(':estimate.iq3');
  };

  const updateEstiateBasicInfoBydetailUpdate = (estimateInfo) => {
    let weight = 0.0;
    let surfaceArea = 0;
    let costPrice = 0.0;
    let totalCostPrice = 0.0;
    let estimatedAmount = 0.0;
    let totalEstimatedAmount = 0.0;
    let costProfit = 0.0;
    let estimateProfit = 0.0;
    let materialCost = 0.0;
    let processCost = 0.0;
    let purchaseCost = 0.0;
    let estimateDataProps = selectedEstimateData;
    console.log('selectedEstimateData--', estimateInfo);
    if (estimateDataProps != undefined && estimateInfo != null) {
      costPrice = parseFloat(estimateDataProps.costPrice * estimateInfo.partCnt);
      totalCostPrice = parseFloat(estimateDataProps.totalCostPrice * estimateInfo.partCnt);
      estimatedAmount = parseFloat(estimateDataProps.estimatedAmount * estimateInfo.partCnt);
      totalEstimatedAmount = parseFloat(estimateDataProps.totalEstimatedAmount * estimateInfo.partCnt);
      costProfit = parseFloat(estimateDataProps.costProfit * estimateInfo.partCnt);
      estimateProfit = parseFloat(estimateDataProps.estimateProfit * estimateInfo.partCnt);
      materialCost = parseFloat(estimateDataProps.materialCost * estimateInfo.partCnt);
      processCost = parseFloat(estimateDataProps.processCost * estimateInfo.partCnt);
      purchaseCost = parseFloat(estimateDataProps.purchaseCost);
      const estimateBasicInfo = {
        estimateImgNo: estimateDataProps.imgNo,
        estimateNm: estimateDataProps.name,
        customerNm: estimateDataProps.customerNm,
        weight: estimateDataProps.childPartTotalWeight, // 確認
        surfaceArea: estimateDataProps.surfaceArea, // 確認
        volume: estimateDataProps.volume, // 確認
        creator: estimateDataProps.creator,
        estimatedDate: estimateDataProps.createdDate,
        deliveryDate: estimateDataProps.dateLst != undefined ? estimateDataProps.dateLst[2].data : '',
        partCnt: estimateDataProps.lotNum,

        costPrice: costPrice.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        totalCostPrice: totalCostPrice.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        estimatedAmount: estimatedAmount.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        totalEstimatedAmount: totalEstimatedAmount.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        costProfit: costProfit.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        estimateProfit:
          estimateProfit != undefined
            ? estimateProfit.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0,
        profitRate: estimateDataProps.rate,

        materialCost: materialCost.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        processCost: processCost.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        purchaseCost: purchaseCost.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),

        customerCoefficient: estimateDataProps.customerCoefficient,
        managementCoefficient: estimateDataProps.managementCoefficient,
        materialLossCoefficient: estimateDataProps.materialLossCoefficient,
        orderForm: estimateDataProps.orderCoefficient,
        setupCoefficient: estimateDataProps.setupCoefficient,
        surfaceAreaCoefficient: estimateDataProps.surfaceAreaCoefficient,
        purchaseCoefficient: estimateDataProps.purchaseCoefficient,
        deliveryCoefficient: estimateDataProps.deliveryCoefficient,
        comment: estimateDataProps.comment,
      };

      props.updateEstimateInfo(estimateBasicInfo);
    }
  };

  useImperativeHandle(ref, () => ({
    importPMXFile: importPMXFile,
    getIQ3EditMode: () => {
      return editMode;
    },
  }));

  // 部品追加ボタンの押下
  const addPart = () => {
    var copyPart = { ...iq3InitData };
    if (iQ3DataLst != undefined && iQ3DataLst.length > 0) {
      let tempIQ3List = [...iQ3DataLst];
      var lastRecord = tempIQ3List.sort((a, b) => b.key - a.key)?.[0];
      copyPart['key'] = lastRecord?.key + 1;
      copyPart['no'] = lastRecord?.no + 1;
      setIQ3DataLst((prev) => [...prev, copyPart]);
    } else {
      copyPart['key'] = 0;
      copyPart['no'] = 1;
      setIQ3DataLst([copyPart]);
    }
    scrollTo('iq3EstimateTbl', 'end');
    setSelectedRowKeys([copyPart?.key]);
    setSelectedData(copyPart);
    setSelectedIQ3DataDetail(copyPart);
  };

  // 部品削除ボタンの押下
  const deletePart = () => {
    setIsDelete(true);
    setChildTitle('削除');
  };

  // 削除ポップアップのOKボタンの押下
  const deleteOk = () => {
    setIsDelete(false);
    let tempIQ3List = [...iQ3DataLst];
    tempIQ3List = tempIQ3List.filter((item) => selectedRowKeys?.find((rowKey) => rowKey === item.key) === undefined);
    setIQ3DataLst(tempIQ3List);
    if (tempIQ3List.length > 0) {
      setSelectedRowKeys([tempIQ3List[0]?.key]);
      setSelectedData(tempIQ3List[0]);
      setSelectedIQ3DataDetail(tempIQ3List[0]);
    }
  };

  // 削除ポップアップのCancelボタンの押下
  const deleteCancel = () => {
    setIsDelete(false);
  };

  // 上方向ボタンの押下
  const upRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let key = selectedData?.key - 1;
    let record = tempIQ3List.filter((item) => item.key === key);
    if (record && record.length > 0) {
      setSelectedRowKeys([record[0]?.key]);
      setSelectedData(record[0]);
      setSelectedIQ3DataDetail(record[0]);
    }
    scrollTo('iq3EstimateTbl', 'nearest');
  };

  // 下方向ボタンの押下
  const downRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let key = selectedData?.key + 1;
    let record = tempIQ3List.filter((item) => item.key === key);
    if (record && record.length > 0) {
      setSelectedRowKeys([record[0]?.key]);
      setSelectedData(record[0]);
      setSelectedIQ3DataDetail(record[0]);
    }
    scrollTo('iq3EstimateTbl', 'nearest');
  };

  const menuIconList = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '60%',
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          height: 20,
        }}
      >
        <Row style={{ marginTop: '0px', marginLeft: '0', width: '-webkit-fill-available' }}>
          <Col span={9}>
            <Space size="small">
              <Tooltip title="部品追加" placement="bottom" overlayClassName="tooltip-text">
                <a onClick={addPart}>
                  <Image preview={false} width={27} src={child_add} style={{ marginLeft: '0px' }}></Image>
                </a>
              </Tooltip>
              <Tooltip title="部品削除" placement="bottom" overlayClassName="tooltip-text">
                <a onClick={deletePart}>
                  <Image preview={false} width={23} src={child_delete} style={{ marginBottom: '1px' }}></Image>
                </a>
              </Tooltip>
            </Space>
            <Space style={{ marginLeft: 45 }} size="small">
              <Tooltip title="Up" placement="bottom" overlayClassName="tooltip-text">
                <a onClick={upRowSelect}>
                  <Image preview={false} width={23} src={up_Arrow} style={{ marginBottom: '1px' }}></Image>
                </a>
              </Tooltip>
              <Tooltip title="Down" placement="bottom" overlayClassName="tooltip-text">
                <a onClick={downRowSelect}>
                  <Image preview={false} width={23} src={down_Arrow} style={{ marginBottom: '1px' }}></Image>
                </a>
              </Tooltip>
              {/* <Button
                key="submit"
                type="primary"
                onClick={commonTaiochuPopupOpen}
                className="editAllButton"
                style={{ marginLeft: 90 }}
              >
                まとめて編集
              </Button> */}
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );

  const closeComponent = (
    <>
      <CaretUpOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange(0, e)}
      />
      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange(0, e)}>閉じる</a>
      </p>
    </>
  );

  const openComponent = (
    <>
      <CaretDownOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange(1, e)}
      />

      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange(1, e)}>開く</a>
      </p>
    </>
  );

  const childPartListHeader = (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Space size="small">
          <p
            style={{
              fontSize: '13px',
              fontWeight: 'bold',
              paddingTop: 3,
              margin: 0,
              paddingLeft: 5,
              color: 'white',
            }}
          >
            部品リスト
          </p>
          {isListOpen ? closeComponent : openComponent}

          <div style={{ display: 'flex', marginLeft: '70px', marginTop: '1.3px' }}>
            {' '}
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 3,
                paddingRight: 3,
                color: 'white',
                border: 'solid',
                borderWidth: 1,
                borderColor: 'white',
                paddingTop: 3,
              }}
            >
              選択中
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                paddingLeft: 10,
                margin: 0,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedIQ3DataDetail != undefined && selectedIQ3DataDetail.no != undefined
                ? 'No.' + selectedIQ3DataDetail.no
                : 'No.0'}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedIQ3DataDetail != undefined ? selectedIQ3DataDetail.imgNo : 0}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedIQ3DataDetail != undefined ? selectedIQ3DataDetail.partNm : 0}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                paddingTop: 3,
              }}
            >
              {selectedIQ3DataDetail != undefined ? selectedIQ3DataDetail.partCnt : 0}
            </p>
          </div>
        </Space>
      </div>
    </>
  );

  const modalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  const onChange = (key) => {
    setActiveTabKey(key);
    if (key == '1') {
      // 基本情報
      setCurrentRef(iq3DetailRef);
    } else if (key == '2') {
      // 工程選択
      setCurrentRef(iq3KouteiSentakuRef);
    } else if (key == '3') {
      // 工程入力
      setCurrentRef(iq3KouteiInputRef);
    } else if (key == '4') {
      // 工程積算
      setCurrentRef(iq3KouteiCalRef);
    } else if (key == '5') {
      // 購入品
      setCurrentRef(iq3PurchaseRef);
    }
  };

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      width: 40 + 'px',
      align: 'center',
      render: (value, array, index) => index + 1,
    },
    {
      id: '2',
      title: '採用',
      dataIndex: 'adopt',
      width: 60 + 'px',
      align: 'center',
    },
    {
      id: '3',
      title: 'イメージ',
      dataIndex: 'image',
      width: 140 + 'px',
      align: 'center',
    },
    {
      id: '4',
      title: ['工程パターン', <br />, '図番番号', <br />, '名称'],
      dataIndex: 'processItems',
      width: 220 + 'px',
      align: 'center',
      render: (_, record) =>
        props.iq3Data != undefined && props.iq3Data.length >= 1 ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px' }}>{record.processNm}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>{record.imgNo}</label>
            {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 2 }} value={record.imgNo} /> */}
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>{record.partNm}</label>
            {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 3 }} value={record.partNm} /> */}
            <br />
          </div>
        ) : null,
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'partCnt',
      width: 60 + 'px',
      align: 'center',
    },
    {
      id: '6',
      title: ['材料', <br />, '材質', <br />, '板厚'],
      dataIndex: 'materialItems',
      width: 150 + 'px',
      align: 'center',
      render: (_, record) =>
        props.iq3Data != undefined && props.iq3Data.length >= 1 ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px' }}>{record.materialNm}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>{record.material}</label>

            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>{record.thickness}</label>
          </div>
        ) : null,
    },
    {
      id: '7',
      title: ['重量', <br />, '表面籍'],
      dataIndex: 'amountItems',
      width: 100 + 'px',
      align: 'center',
      render: (_, record) =>
        props.iq3Data != undefined && props.iq3Data.length >= 1 ? (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>{record.weight + 'kg'}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>
              {record.surfaceArea != undefined
                ? record.surfaceArea.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
              cm<sup>2</sup>
            </label>
          </div>
        ) : null,
    },
    {
      id: '8',
      title: ['材料費', <br />, '加工費', <br />, '購入品費'],
      dataIndex: 'costItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) =>
        props.iq3Data != undefined && props.iq3Data.length >= 1 ? (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>
              {record.materialCost != undefined
                ? '¥' +
                  record.materialCost.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>
              {record.processCost != undefined
                ? '¥' +
                  record.processCost.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>
              {record.purchaseCost != undefined
                ? '¥' +
                  record.purchaseCost.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </label>
          </div>
        ) : null,
    },
    {
      id: '9',
      title: '原価',
      dataIndex: 'estimateItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) =>
        props.iq3Data != undefined && props.iq3Data.length >= 1 ? (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>
              {record.costPrice != undefined
                ? '¥' +
                  record.costPrice.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </label>
          </div>
        ) : null,
    },
    {
      id: '10',
      title: '原価合計',
      dataIndex: 'estimateCostItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) =>
        props.iq3Data != undefined && props.iq3Data.length >= 1 ? (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>
              {record.totalCostPrice != undefined
                ? '¥' +
                  record.totalCostPrice.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </label>
          </div>
        ) : null,
    },
  ];

  const tabItems = [
    {
      key: '1',
      label: `基本情報`,
      children: (
        <Routes>
          <Route
            exact
            path=":estimate.iq3"
            element={
              <IQ3EstimateDetail
                isListOpen={isListOpen}
                selectedIQ3DataDetail={selectedIQ3DataDetail}
                ref={iq3DetailRef}
                editMode={editMode}
              />
            }
          />
        </Routes>
      ),
    },
    {
      key: '2',
      label: `工程選択`,
      children: (
        <Routes>
          <Route
            exact
            path=":estimate.iq3"
            element={
              // <div style={{ overflowY: 'auto', height: '466px' }}>
              <IQ3KouteiSentaku
                editMode={editMode}
                selectedIQ3DataDetail={selectedIQ3DataDetail}
                iQ3DataLst={iQ3DataLst}
                pmxMode={false}
                updateSelectedData={updateSelectedData}
              />
              // </div>
            }
          />
        </Routes>
      ),
    },
    {
      key: '3',
      label: `工程入力`,
      children: (
        <Routes>
          <Route
            exact
            path=":estimate.iq3"
            element={<IQ3KouteiInput editMode={editMode} selectedIQ3DataDetail={selectedIQ3DataDetail} />}
          />
        </Routes>
      ),
    },
    {
      key: '4',
      label: `工程積算`,
      children: (
        <Routes>
          <Route
            exact
            path=":estimate.iq3"
            element={<IQ3KouteiCalculate editMode={editMode} selectedIQ3DataDetail={selectedIQ3DataDetail} />}
          />
        </Routes>
      ),
    },
    {
      key: '5',
      label: `購入品`,
      children: <IQ3Purchase editMode={editMode} selectedIQ3DataDetail={selectedIQ3DataDetail} />,
    },
    {
      key: '6',
      label: `材料詳細`,
      children: <IQ3MaterialDetail editMode={editMode} selectedIQ3DataDetail={selectedIQ3DataDetail} />,
    },
  ];

  return (
    <>
      <div>
        <Form>
          {/* 一覧とプロパティ表示 */}{' '}
          <div
            style={{
              height: '85.9vh',
            }}
          >
            <Row id="iq3-rows" style={{ marginLeft: '5px', width: '99.5%' }} className="oyabuhin oyabuhin-row">
              <Col span={24}>{childPartListHeader}</Col>
            </Row>
            {menuIconList}
            <PanelGroup direction="vertical">
              <Panel id="iq3-list-panel-id" collapsible={true}>
                <div className="iq3-list-div">
                  <Table
                    id="iq3EstimateTbl"
                    showSorterTooltip={false}
                    rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
                    columns={iq3Columns}
                    rowKey={(record) => record.key}
                    dataSource={iQ3DataLst}
                    className="iq3-list-tb"
                    rowSelection={rowSelection}
                    pagination={false}
                  />
                </div>
              </Panel>
              <PanelResizeHandle className="resizeHandleOuter">
                <div className="resizeHandleInner">
                  <svg className="iq3-detail-split-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                    />
                  </svg>
                </div>
              </PanelResizeHandle>
              <Panel id="iq3-detail-panel-id" className="iq3-detail-panel" collapsible={true}>
                <div className="iq3-detail-div">
                  <Tabs
                    className="iq3-detail-tab"
                    type="card"
                    activeKey={activeTabKey}
                    defaultActiveKey="1"
                    items={tabItems}
                    onChange={onChange}
                  />
                  <div style={{ marginLeft: '-950px', display: 'flex', marginTop: '3px' }}>
                    {/* <Button
                      key="submit"
                      type="primary"
                      onClick={commonTaiochuPopupOpen}
                      className="materialDetail"
                      id="materialDetail"
                    >
                      材料詳細
                    </Button> */}
                  </div>
                  <div style={{ marginLeft: '200px', display: 'flex', marginTop: '3px' }}>
                    <KouteiSuryouBetsuBtn selectedEstimateData={selectedEstimateData} />
                  </div>
                  <div style={{ display: 'flex', marginTop: '3px' }}>
                    <Button
                      key="iq3-update"
                      type="primary"
                      onClick={updateData}
                      className="mainButton"
                      style={{ marginRight: '10px', width: 60, height: 25, display: 'none' }}
                      id="iq3-update"
                    >
                      更新
                    </Button>
                    <Button
                      key="iq3-discard"
                      type="primary"
                      onClick={discardData}
                      className="cancelButton"
                      style={{ marginRight: '30px', width: 60, height: 25, display: 'none' }}
                      id="iq3-discard"
                    >
                      破棄
                    </Button>
                    <Button
                      key="iq3-edit"
                      type="primary"
                      onClick={editData}
                      className="mainButton"
                      style={{ width: 60, marginLeft: '342px', height: 25 }}
                      id="iq3-edit"
                    >
                      編集
                    </Button>
                  </div>
                </div>
              </Panel>
              <PanelResizeHandle className="resizeHandleOuter">
                <div className="resizeHandleInner">
                  <svg className="iq3-detail-split-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                    />
                  </svg>
                </div>
              </PanelResizeHandle>
            </PanelGroup>
          </div>
        </Form>

        {/** 開発中ポップアップ */}
        {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
        {/** 編集中破棄確認ポップアップ */}
        {editModeCancelConfirm
          ? commonModal(
              editModeCancelConfirm,
              confirmScreenChangeTitle,
              commonFooter(discardConfirmOk, discardConfirmCancel),
              null,
              400,
              discardConfirmOk,
              discardConfirmCancel,
              confirmScreenChangeContent,
              null,
              null,
              false
            )
          : ''}
        {/** 更新 / 破棄の確認メッセージ */}
        {confirmMsgOpen
          ? commonModal(
              confirmMsgOpen,
              modalTitle,
              commonFooter(confirmOk, confirmCancel),
              null,
              400,
              confirmOk,
              confirmCancel,
              discardMode ? confirmDiscardContent : confirmUpdateContent,
              null,
              null,
              false
            )
          : ''}

        {/* 削除 */}
        {isDelete
          ? commonModal(
              isDelete,
              confirmModalTitle,
              commonFooter(deleteOk, deleteCancel),
              null,
              400,
              deleteOk,
              deleteCancel,
              editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
              null,
              null,
              false
            )
          : ''}
      </div>
    </>
  );
});

export default IQ3Estimate;
