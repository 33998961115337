/**
 * クラス名：購入品パラメータ
 * 説明：購入品情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Button, Row, Input, Col, Select, Space, Image, Radio } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  StarOutlined,
  StarFilled,
  SearchOutlined,
} from '@ant-design/icons';
import 'split-pane-react/esm/themes/default.css';
import SplitPane, { Pane } from 'split-pane-react';
import { StatusCodes } from 'http-status-codes';
import CurrencyInput from 'react-currency-input-field';

import PurchaseDetails from './PurchaseDetails';
import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  error,
  authorizeError,
} from '../../../common/CommonModal';
import { getPurchaseInfo, getPurchaseCategoryInfo } from '../../../common/CommonAPI';
import {
  dataStateList,
  today,
  TextArea,
  JPYs,
  removeYen,
  formatDate,
  formValidatorMode,
  updateAccessToken,
} from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData';
import { ClientType } from '../../../common/enums';
import { Supplier, purchaseCategoryDefaultValue } from '../../../common/Constant';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const iconWidth = 30;
const RadioGroup = Radio.Group;

const PurchaseParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const inputItemRef = {};

  let loginUser = JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser')));
  let token = localStorage?.getItem('iQxWeb_AccessToken');

  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // デフォルトページの表示件数
  const defaultPageSize = 25;

  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];
  document.body.style.overflowY = 'hidden';

  const [paramSelected, setParamSelected] = useState('purchase');
  const [paramType, setParamType] = useState('common');
  const [purchasesData, setPurchasesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState(0);

  const [purchaseCategoryId, setPurchaseCategoryId] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [priceLots, setPriceLots] = useState([]);
  const [priceHistories, setPriceHistories] = useState([]);
  const [isUsed, setIsUsed] = useState('');
  const [isAllocation, setIsAllocation] = useState('');
  const [model, setModel] = useState('');
  const [makerName, setMakerName] = useState('');
  const [supplierCode, setSupplierCode] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [isKeepPrice, setIsKeepPrice] = useState(true);
  const [processTime, setProcessTime] = useState(0);
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');

  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);

  const [dataState, setDataState] = useState(0);
  const [dataStatePurchaseType, setDataStatePurchaseType] = useState('');
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [supplierKeyword, setSupplierKeyWord] = useState('');

  const [hasCheckData, setHasCheckData] = useState(true);
  const [leftCol, setLeftCol] = useState(10);
  const [rightCol, setRightCol] = useState(13);
  const [middlecol, setMiddleCol] = useState(1);
  const [infoShowHide, setInfoShowHide] = useState(true);
  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [tempPurchasesData, setTempPurchasesData] = useState([]);

  const [lotThreshold, setLotThreshold] = useState([]);
  const [lotPrice, setLotPrice] = useState([]);

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);

  // ヘッダーバーの押下で情報を表示／非表示
  const [isPriceLots, setIsPriceLots] = useState(true);

  // ロット数別単価の初期値
  const initInfoPriceLots = { threshold: 0, price: 0 };

  const [tmpFilteredData, setTmpFilteredData] = useState();

  // 仕入れ先情報一覧
  const [clientModal, setClientModal] = useState(false);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPurchaseCategoryData(token);
    getPurchaseData(token);
  }, [paramType, paramSelected]);

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, modelKeyword, supplierKeyword, dataState, dataStatePurchaseType, tmpFilteredData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          setSelectedRowId(changeRecordId);
          setSelectedData(getSelectedData(purchasesData, changeRecordId));
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          setSelectedData(getSelectedData(purchasesData, selectedRowId));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 購入品種別情報をDBから取得する
  async function getPurchaseCategoryData(token) {
    try {
      let purchaseTypeLst = await getPurchaseCategoryInfo(token);
      setPurchaseCategoryInfo(purchaseTypeLst);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  // 購入品情報をDBから取得する
  async function getPurchaseData(token) {
    let purchasesData = [];
    let selectedData = [];
    try {
      purchasesData = await getPurchaseInfo(token);
      if (purchasesData.length > 0) selectedData = purchasesData[purchasesData.length - 1];
      setPurchasesData(purchasesData);
      setFilteredData(purchasesData);
      setSelectedRowId(selectedData?.id);
      setSelectedData(selectedData);
      navigate(':common.purchase');
      setParamType('common');
      props.setParam(paramType, paramSelected);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  // 購入品種別のリストボックス情報
  const purchaseCategoryOptions = (data = purchaseCategoryInfo) => {
    let list;
    list = data.map((item) => ({ value: item.id, label: item.name }));
    return list;
  };

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // ロット数別単価のヘッダーバーを押下
  const clickPriceLots = () => {
    setIsPriceLots(!isPriceLots);
  };

  // ロット数別単価に行を追加
  const addPriceLots = (e) => {
    let tempPriceLots = [...priceLots];
    tempPriceLots.push(initInfoPriceLots);
    setPriceLots(tempPriceLots);
    resetInputPriceLots();
  };

  // 入力項目の初期値を設定
  const resetInputPriceLots = () => {
    setLotThreshold((prev) => {
      return [...prev, initInfoPriceLots.threshold];
    });
    setLotPrice((prev) => {
      return [...prev, initInfoPriceLots.price];
    });
  };

  // 「ロット数別単価」情報に入力した場合、
  const onChangeValue = (name, value, index) => {
    let tempData = [];
    switch (name) {
      case 'lotThreshold': // 上限個数
        tempData = [...lotThreshold];
        tempData[index] = value;
        setLotThreshold(tempData);
        break;
      case 'lotPrice': // 単価
        tempData = [...lotPrice];
        tempData[index] = value;
        setLotPrice(tempData);
        break;
    }
  };

  // 行を削除する
  const deleteSelectedRow = (index, e, id) => {
    if (id === 'btnPriceLots') {
      // ロット数別単価
      setPriceLots(removedData(priceLots, index));
      setLotThreshold(removedData(lotThreshold, index));
      setLotPrice(removedData(lotPrice, index));
    }
  };

  // 情報を削除する
  const removedData = (data, index) => {
    return data.filter((v, i) => i !== index);
  };

  // 入力ボックスは金額タイプの場合、他の項目CSSを参照にして利用
  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList).filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };

  // 入力項目情報
  const inputFormItem = (
    <>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* 引当用 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">引当用</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                id="isAllocation"
                name="isAllocation"
                defaultValue={isAllocation}
                value={isAllocation}
                className="radioCustomerGpEdit"
                onChange={(e) => setIsAllocation(e.target.value)}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          {/* 表示 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                id="isUsed"
                name="isUsed"
                defaultValue={1}
                value={isUsed}
                className="radioCustomerGpEdit"
                onChange={(e) => setIsUsed(e.target.value)}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          {/* コード */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="code"
                id="code"
                value={code}
                type="text"
                ref={(ref) => {
                  inputItemRef['code'] = ref;
                }}
                maxLength={50}
                onChange={(e) => setCode(e.target.value)}
              />
            </td>
          </tr>
          {/* 種別 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">種別</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                id="purchaseCategoryId"
                name="purchaseCategoryId"
                defaultValue={purchaseCategoryDefaultValue}
                value={purchaseCategoryId}
                onChange={(e) => {
                  setPurchaseCategoryId(e);
                }}
              >
                {purchaseCategoryInfo.map((item) => (
                  <option id={'purchaseCategoryId' + item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </td>
          </tr>
          {/* 名称 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="name"
                id="name"
                value={name}
                type="text"
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={100}
                onChange={(e) => setName(e.target.value)}
              />
            </td>
          </tr>
          {/* 型番 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">型番</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="model"
                id="model"
                value={model}
                type="text"
                ref={(ref) => {
                  inputItemRef['model'] = ref;
                }}
                maxLength={100}
                onChange={(e) => setModel(e.target.value)}
              />
            </td>
          </tr>
          {/* メーカー名 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">メーカー名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="makerName"
                id="makerName"
                value={makerName}
                type="text"
                ref={(ref) => {
                  inputItemRef['makerName'] = ref;
                }}
                maxLength={150}
                onChange={(e) => setMakerName(e.target.value)}
              />
            </td>
          </tr>
          {/* 仕入先 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">仕入先</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <>
                <Button
                  icon={<SearchOutlined />}
                  size="small"
                  onClick={(e) => getSupplierName(e)}
                  type="link"
                  className="supplier-search-icon"
                ></Button>
                <Input
                  className={'input-non-editable supplier-input-label'}
                  name="supplierName"
                  id="supplierName"
                  value={supplierName}
                  type="text"
                  ref={(ref) => {
                    inputItemRef['supplierName'] = ref;
                  }}
                  maxLength={50}
                />
              </>
            </td>
          </tr>
          {/* 仕入先コード */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">仕入先コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-non-editable'}
                name="supplierCode"
                id="supplierCode"
                value={supplierCode}
                type="text"
                ref={(ref) => {
                  inputItemRef['supplierCode'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          {/* 単価 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">単価</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <CurrencyInput
                name="price"
                id="price"
                value={price}
                defaultValue={0}
                onValueChange={(e) => {
                  setPrice(e);
                }}
                ref={(ref) => {
                  inputItemRef['price'] = ref;
                }}
                className={getAntInputBoxClassName('makerName')}
                prefix="¥"
                decimalsLimit={100}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          {/* 単価保持 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">単価保持</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                id="isKeepPrice"
                name="isKeepPrice"
                defaultValue={1}
                value={isKeepPrice}
                className="radioCustomerGpEdit"
                onChange={(e) => setIsKeepPrice(e.target.value)}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>

          {/* ソート番号 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">ソート番号</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="sortNum"
                id="sortNum"
                value={sortNum}
                type="text"
                ref={(ref) => {
                  inputItemRef['sortNum'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
                onChange={(e) => setSortNum(e.target.value)}
              />
            </td>
          </tr>
          {/* 作成日 更新日 */}
          {isBulkEdit ? (
            ''
          ) : (
            <>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{created}</label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modified}</label>
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modifier}</label>
                </td>
              </tr>
            </>
          )}
          {/* 取付時間(秒) */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">取付時間(秒)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="processTime"
                id="processTime"
                value={processTime}
                type="text"
                ref={(ref) => {
                  inputItemRef['processTime'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
                onChange={(e) => setProcessTime(e.target.value)}
              />
            </td>
          </tr>
          {/* 備考 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">備考</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <TextArea
                className={'input-editable'}
                name="remarks"
                id="remarks"
                value={remarks}
                style={{ margin: 5, padding: 5, width: '98.3%' }}
                type="text"
                ref={(ref) => {
                  inputItemRef['remarks'] = ref;
                }}
                maxLength={2048}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </td>
          </tr>
        </thead>
      </RTable>
      {/* ロット数別単価 */}
      {isBulkEdit ? (
        ''
      ) : (
        <>
          <Row style={{ color: 'black', marginTop: 0 }} className="oyabuhin oyabuhin-row" onClick={clickPriceLots}>
            <Col span={20} className="deli-col">
              <div style={{ display: 'flex' }}>
                <label>ロット数別単価</label>
                {isPriceLots ? (
                  <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                )}
              </div>
            </Col>
          </Row>
          <div style={{ display: isPriceLots ? 'block' : 'none' }}>
            <RTable className="tblPriceLots" style={{ width: '-webkit-fill-available' }}>
              <thead>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">上限個数</td>
                  <td className="param-detail-tbl-val-title">単価</td>
                  <td style={{ width: 125, border: 'none', backgroundColor: 'white' }}></td>
                </tr>
                {[...priceLots]?.map((listValue, index) => {
                  return (
                    <tr key={index} className="detail-tbl-row">
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          className={'input-editable'}
                          name="lotThreshold"
                          id={'lotThreshold' + index}
                          value={lotThreshold[index]}
                          onChange={(e) => onChangeValue('lotThreshold', e.target.value, index)}
                          type="text"
                          ref={(ref) => {
                            inputItemRef['lotThreshold' + index] = ref;
                          }}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <CurrencyInput
                          name="lotPrice"
                          id={'lotPrice' + index}
                          value={lotPrice[index]}
                          defaultValue={0}
                          onValueChange={(e) => {
                            onChangeValue('lotPrice', e, index);
                          }}
                          ref={(ref) => {
                            inputItemRef['lotPrice' + index] = ref;
                          }}
                          className={getAntInputBoxClassName('makerName')}
                          prefix="¥"
                          decimalsLimit={100}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        />
                      </td>
                      <td style={{ border: '0px solid', textAlign: 'left' }}>
                        {priceLots?.length - 1 === index ? (
                          <PlusCircleFilled className="add-icon" onClick={(e) => addPriceLots(e, `btnPriceLots`)} />
                        ) : (
                          <></>
                        )}
                        {priceLots.length > 1 ? (
                          <CloseCircleFilled
                            className="add-icon"
                            onClick={(e) => deleteSelectedRow(index, e, 'btnPriceLots')}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </thead>
            </RTable>
          </div>
        </>
      )}
    </>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, id) => {
    let ret = [];
    let record;
    record = data.find((item) => item.id === id);
    return record ? record : ret;
  };

  // 最大ソート番号を得る
  const getMaxSortNum = () => {
    let sortedList = purchasesData.sort((a, b) => b.info.sortNum - a.info.sortNum);
    return sortedList.length > 0 ? sortedList[0].info.sortNum + 1 : 1;
  };

  // Idで降順する
  const descOrderById = (data) => {
    let sortedList = data.sort((a, b) => b.id - a.id);
    return sortedList;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let selectedPurchasesData = [];
    if (record !== undefined) {
      selectedPurchasesData.push({
        id: record.id,
        purchaseCategoryId: record.purchaseCategoryId,
        code: record.code,
        name: record.name,
        price: record.price,
        priceLots: record.priceLots,
        priceHistories: record.priceHistories,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      setChangeRecordId(record.id);
      if (editMode) {
        setDiscardChangeMode(discardChange[0]);
        setEditModeCancelConfirm(true);
      } else {
        setSelectedRowId(record.id);
        setSelectedData(selectedPurchasesData[0]);
        navigate(':common.purchase');
      }
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.purchase');
    }
  };

  const onRowSelectWithoutEditMode = (record) => {
    let selectedPurchasesData = [];
    if (record !== undefined) {
      selectedPurchasesData.push({
        id: record.id,
        purchaseCategoryId: record.purchaseCategoryId,
        code: record.code,
        name: record.name,
        price: record.price,
        priceLots: record.priceLots,
        priceHistories: record.priceHistories,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      setChangeRecordId(record.id);
      setSelectedRowId(record.id);
      setSelectedData(selectedPurchasesData[0]);
      const purchaseLst = [...filteredData];
      if (purchaseLst.length > 0) {
        setCurrentPage(Math.ceil(purchaseLst.length / 25));
      } else {
        setCurrentPage(1);
      }
      navigate(':common.purchase');
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.purchase');
    }
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    setIsDelete(true);
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    if (isDelete) {
      setIsDelete(false);
      setEditMode(false);
      let selectedId = selectedRowId;
      let deletedData = await deletePurchaseData(selectedId);

      let filterdData = [...filteredData];
      filterdData = filterdData.filter((item) => item.id !== deletedData.id);
      setFilteredData(filterdData);
      /* let lastKey = filterdData[filterdData.length - 1]?.key;
      setSelectedRowKeys(lastKey);
      let record = getSelectedData(filterdData, lastKey);
      setSelectedData(record); */

      let currentData = [...purchasesData];
      currentData = currentData.filter((item) => item.id !== deletedData.id);
      onRowSelectWithoutEditMode(
        currentData !== undefined && currentData.length > 0 ? currentData[currentData.length - 1] : []
      );
      setPurchasesData(currentData);
      setTmpFilteredData(currentData);
      setCurrentPage(1);
    }
  };

  // 購入品情報をDBに削除する
  async function deletePurchaseData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/PurchaseDelete/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          error(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const resetInputInfo = () => {
    setPurchaseCategoryId();
    setCode('');
    setName('');
    setPrice('');
    setPriceLots([initInfoPriceLots]);
    setIsUsed();
    setIsAllocation();
    setModel('');
    setMakerName('');
    setSupplierCode('');
    setSupplierName('');
    setIsKeepPrice();
    setProcessTime();
    setRemarks('');
    setSortNum('');
    setCreated(today);
    setModified(today);
    setModifier('');
    resetInputPriceLots();
  };

  // 表示の押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // 種類の押下
  const searchByDataStatePurchaseType = (value) => {
    dataStatePurchaseType === value ? getDataByFilter() : setDataStatePurchaseType(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
      case 'supplier':
        supplierKeyword === e.target.value ? getDataByFilter() : setSupplierKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (purchasesData.length <= 0) {
      return;
    }
    let tKeyword = dataStatePurchaseType;
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let rKeyword = supplierKeyword;
    let flag;
    let currentData = [...purchasesData];
    let purchaseFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      purchaseFilterData = purchaseFilterData.filter((item) => item.name?.toString().indexOf(nKeyword) >= 0);
    }
    // 型番のキーワードで絞り込み
    if (mKeyword != '') {
      purchaseFilterData = purchaseFilterData.filter((item) => item.info.model?.toString().indexOf(mKeyword) >= 0);
    }
    // 仕入先のキーワードで絞り込み
    if (rKeyword != '') {
      purchaseFilterData = purchaseFilterData.filter(
        (item) => item.info.supplierName?.toString().indexOf(rKeyword) >= 0
      );
    }

    //  表示で絞り込み
    if (dataState === 1 || dataState === 2) {
      dataState === 1 ? (flag = true) : (flag = false);
      purchaseFilterData = purchaseFilterData.filter((item) => item.info.isUsed === flag);
    }

    // 種別で絞り込み
    if (tKeyword > 0) {
      purchaseFilterData = purchaseFilterData.filter((item) => item.purchaseCategoryId === tKeyword);
    }

    /* let index = purchaseFilterData.findIndex((item) => item.key === selectedRowKeys);
    // 検索した結果に、選択中行のKeyが無い場合、
    if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (purchaseFilterData.length > 0) {
        index = purchaseFilterData.length - 1;
        setSelectedRowKeys(purchaseFilterData[index]?.key);
      }
    }
    purchaseFilterData.length > 0
      ? setSelectedData(purchaseFilterData[index]) // 検索結果がある場合、
      : setSelectedData(purchaseFilterData); // 検索結果が無い場合、
    // 一覧に表示するデータ */
    setFilteredData(purchaseFilterData);
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isCreateNew) {
      setCreateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false)) {
        return;
      }
      let lastIndex = tempPurchasesData.length - 1;
      let tempData = tempPurchasesData[lastIndex];
      let createdData = await createPurchaseData(tempData);
      setPurchasesData((prevData) => descOrderById([...prevData, createdData]));
      setTmpFilteredData(createdData);
      setFilteredData((filteredData) => descOrderById([...filteredData, createdData]));
      onRowSelectWithoutEditMode(createdData);
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit, false)) {
        return;
      }
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      // 選択された全ての対象行を更新、
      bulkEditSelectedRow.map((item) => {
        if (purchaseCategoryId !== undefined) item.purchaseCategoryId = purchaseCategoryId;
        if (code) item.code = code;
        if (name) item.name = name;
        if (price) {
          item.price = price;
        }
        if (isUsed === true || isUsed === false) item.info.isUsed = isUsed;
        if (isAllocation === true || isAllocation === false) item.info.isAllocation = isAllocation;
        if (model) item.info.model = model;
        if (makerName) item.info.makerName = makerName;
        if (supplierCode) item.info.supplierCode = supplierCode;
        if (supplierName) item.info.supplierName = supplierName;
        if (isKeepPrice === true || isKeepPrice === false) item.info.isKeepPrice = isKeepPrice;
        if (processTime) item.info.processTime = processTime;
        if (remarks) item.info.remarks = remarks;
        if (sortNum) item.info.sortNum = sortNum;
        item.modified = today;
        submitData(item);
      });
    }
    resetInputInfo();
  };

  // 購入品情報をDBに新規登録する
  async function createPurchaseData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/PurchaseInsert';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          purchaseCategoryId: createData.purchaseCategoryId,
          code: createData.code,
          name: createData.name,
          price: createData.price,
          priceLots: createData.priceLots,
          priceHistories: createData.priceHistories,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          error(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [];
    var updatedInfo = [];
    var index;

    updatedInfo = await updatePurchaseData(updateData);
    tmpData = [...purchasesData];
    index = tmpData.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setPurchasesData(tmpData);
    setTmpFilteredData(tmpData);

    tmpData = [...filteredData];
    index = tmpData.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setFilteredData(tmpData);

    // 詳細情報を再表示
    if (updatedInfo.id === selectedRowId) detailRef.current?.displayDetailInfo(updatedInfo);
  };

  // 購入品情報をDBに更新する
  async function updatePurchaseData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/PurchaseUpdate';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          purchaseCategoryId: updateData.purchaseCategoryId,
          code: updateData.code,
          name: updateData.name,
          price: updateData.price,
          priceLots: updateData.priceLots,
          priceHistories: updateData.priceHistories,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          error(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setIsUsed(true);
    setIsAllocation(false);
    setIsKeepPrice(true);
    setProcessTime(0);
    setPrice(0);
    setSortNum(getMaxSortNum());
    setChildTitle('購入品（新規作成）');
  };

  const createNewOK = () => {
    prepareCreateNewInfo();
  };

  const createNewCancel = (e) => {
    setCancelConfirm(true);
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempPurchasesData = [];
    let info = {
      isUsed: isUsed,
      isAllocation: isAllocation,
      model: model,
      makerName: makerName,
      supplierCode: supplierCode,
      supplierName: supplierName,
      isKeepPrice: isKeepPrice,
      processTime: processTime,
      remarks: remarks,
      sortNum: sortNum,
    };
    priceLots?.map((item, i) => {
      item.threshold = lotThreshold[i];
      item.price = lotPrice[i];
    });
    tempPurchasesData.push({
      purchaseCategoryId: purchaseCategoryId,
      code: code,
      name: name,
      price: price,
      info: info,
      priceLots: priceLots,
      priceHistories: priceHistories,
      created: today,
      modified: today,
    });
    setTempPurchasesData(tempPurchasesData);
    setCreateConfirm(true);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 700,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  const getPurchaseCategoryName = (id) => {
    let data = [];
    data = purchaseCategoryInfo;
    let record = data.filter((item) => item.id === id);
    let name = record.length > 0 ? record[0]?.name : '';
    return name;
  };

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 55 + 'px',
      render: (id, record, index) => {
        return (currentPage - 1) * defaultPageSize + (index + 1);
      },
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend',
      className: 'cm-a-right',
    },
    {
      id: '2',
      title: '引当用',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (index, item) => {
        return item.info.isAllocation ? <StarFilled /> : <StarOutlined />;
      },
      className: 'cm-a-center',
    },
    {
      id: '3',
      title: '表示',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.info.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '4',
      title: 'コード',
      dataIndex: 'code',
      width: 100 + 'px',
    },
    {
      id: '5',
      title: '種別',
      dataIndex: 'purchaseCategoryId',
      width: 100 + 'px',
      render: (id) => `${getPurchaseCategoryName(id)}`,
    },
    {
      id: '6',
      title: '名称',
      dataIndex: 'name',
      width: 150 + 'px',
    },
    {
      id: '7',
      title: '型番',
      dataIndex: 'info',
      width: 120 + 'px',
      render: (info) => info.model,
    },
    {
      id: '8',
      title: 'メーカー名',
      dataIndex: 'info',
      width: 120 + 'px',
      render: (info) => info.makerName,
    },
    {
      id: '9',
      title: '仕入先',
      dataIndex: 'info',
      width: 150 + 'px',
      render: (info) => info.supplierName,
    },
    {
      id: '10',
      title: '仕入先コード',
      dataIndex: 'info',
      width: 150 + 'px',
      render: (info) => info.supplierCode,
    },
    {
      id: '11',
      title: '単価',
      dataIndex: 'price',
      width: 70 + 'px',
      className: 'cm-a-right',
      render: (value) => JPYs.format(value),
    },
    {
      id: '12',
      title: '単価保持',
      dataIndex: 'info',
      width: 95 + 'px',
      render: (index, item) => {
        return item.info.isKeepPrice ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.info.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },

    {
      id: '13',
      title: 'ソート番号',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (info) => info.sortNum,
      className: 'cm-a-right',
    },
    {
      id: '14',
      title: '作成日',
      dataIndex: 'created',
      width: 100 + 'px',
      render: (created) => formatDate(created),
    },
    {
      id: '15',
      title: '更新日',
      dataIndex: 'modified',
      width: 100 + 'px',
      render: (modified) => formatDate(modified),
    },
    {
      id: '16',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150 + 'px',
    },
    {
      id: '17',
      title: '取付時間(秒)',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (info) => info.processTime,
      className: 'cm-a-right',
    },
    {
      id: '18',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
      width: 200 + 'px',
    },
    {
      id: '19',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ].filter((item) => !item.hidden);

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  // 一括編集-開始
  const onRowCheckboxSelect = (checked, record, index) => {
    let checkedRow = [...checked];
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow.length);
    navigate(':common.purchase');
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditSelectedRow([]);
    setBulkEditSelectedRowId([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('購入品（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={filteredData}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.id == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: defaultPageSize,
              defaultCurrent: 1,
            }}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  // 仕入れ先名称を検索
  const getSupplierName = (e) => {
    setClientModal(true);
  };

  // 仕入れ先情報一覧に選択した、データを表示項目にセットする
  const selectedSupplierData = (selectedData) => {
    setSupplierCode(selectedData.code);
    setSupplierName(selectedData.name);
    setClientModal(false);
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Select
                    className="search-select"
                    onChange={searchByDataState}
                    id="dataStateDrop"
                    name="dataStateDrop"
                    value={dataState}
                  >
                    {dataStateList.map((item) => (
                      <option id={'dataState' + item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <Select
                    className="search-select-input"
                    onChange={searchByDataStatePurchaseType}
                    id="dataStatePurchaseType"
                    name="dataStatePurchaseType"
                    defaultValue={0}
                  >
                    <Option id={'dataStatePurchaseType0'} value={0}>
                      {'全ての種別'}
                    </Option>
                    {purchaseCategoryInfo.map((item) => (
                      <Option id={'dataStatePurchaseType' + item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    className="input-editable search-select-input"
                    placeholder="名称"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="name"
                    id="name"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        searchByKeyword(e);
                      }
                    }}
                  />
                  <Input
                    className="input-editable search-select-input"
                    placeholder="型番"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="model"
                    id="model"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        searchByKeyword(e);
                      }
                    }}
                  />
                  <Input
                    className="input-editable search-select-input"
                    placeholder="仕入先"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="supplier"
                    id="supplier"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        searchByKeyword(e);
                      }
                    }}
                  />
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '10vw' }}
                    rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      defaultPageSize: defaultPageSize,
                      defaultCurrent: 1,
                      pageSize: defaultPageSize,
                      current: currentPage,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":purchase"
                element={
                  <PurchaseDetails
                    ref={detailRef}
                    editMode={editMode}
                    purchaseCategoryInfo={purchaseCategoryInfo}
                    selectedData={selectedData}
                    purchaseCategoryOptions={purchaseCategoryOptions}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    getPurchaseCategoryData={getPurchaseCategoryData}
                    getPurchaseCategoryName={getPurchaseCategoryName}
                    getAntInputBoxClassName={getAntInputBoxClassName}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* XML呼出 */}
      {/* <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 仕入れ先情報一覧 */}
      {clientModal ? (
        <SearchAndGetCustomerData
          customerModal={clientModal}
          selectedCustomerData={selectedSupplierData}
          title={Supplier}
          clientType={ClientType.Supplier}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default PurchaseParamList;
