import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { patternPage } from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';

const ParentKouteiSentaku = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState([]);
  const patternformRef = useRef();
  const currentRoute = location.pathname;

  useEffect(() => {
    let kouteiPatternList = [];
    let kouteiSentakuPatternNo = 0;
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail != undefined) {
      kouteiSentakuPatternNo = props.selectedDataDetail.kouteiSentakuPatternNo;
    }
    kouteiPatternList =
      localStorage.getItem('parent_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('parent_processPattern'))
        : [];
    setListData(kouteiPatternList);
    let targertProcessPatternInfo = kouteiPatternList.filter((i) => i.patternNo === kouteiSentakuPatternNo);
    if (targertProcessPatternInfo.length > 0) {
      setProcessSelectedData(targertProcessPatternInfo[0]);
    } else {
      setProcessSelectedData(kouteiPatternList[0]);
    }
  }, [props.selectedDataDetail]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
  }, [props.editMode]);

  const updateKouteiSentakuInfo = (selectedProcessPattern) => {
    setKouteiSentakuPatternNo(selectedProcessPattern.patternNo);
    setProcessSelectedData(selectedProcessPattern);
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
    }),
    [kouteiSentakuPatternNo]
  );

  return (
    <>
      <ProcessPatternForm
        ref={patternformRef}
        selectedData={processSelectedData}
        listData={listData}
        editMode={false}
        page={patternPage[0]}
        isParameter={false}
        updateKouteiSentakuInfo={updateKouteiSentakuInfo}
        kouteiSentakuEditMode={editMode}
        pmxMode={props.pmxMode}
      />
    </>
  );
});

export default ParentKouteiSentaku;
