/**
 * クラス名：工程パターン情報一覧画面
 * 説明：親部品・板金の共通使う工程パターン情報一覧ファイルである。
 * 作成者：ナンス
 * 作成日：2023/04/01
 * バージョン：1.0
 */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table, Button, Row, Input, Col, Modal, Select, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
// Content resize
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table as RTable } from 'react-bootstrap';

import '../../../../assets/styles/common.css';
import ProcessPatternDetails from './ProcessPatternDetails';
import { getInitDataForProcess, today, discardChange } from '../../../common/Common';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  commonActionFooter,
} from '../../../common/CommonModal';

dayjs.extend(customParseFormat);

const ProcessPatternParamList = forwardRef((props, ref) => {
  const detailRef = useRef();
  const [paramSelected, setParamSelected] = useState('oya_patternProcess');
  const [paramType, setParamType] = useState('parent');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  // Content Resize
  const [sizes, setSizes] = useState(['35%']);
  // add new
  const [addNewModal, setAddNewModal] = useState(false);
  const [deletePatternInfoModal, setDeletePatternInfoModal] = useState(false);
  // add all
  const [addAllModal, setAddAllModal] = useState(false);
  // add
  const [addModal, setAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState([]);
  const [surfaceSelectedRowKeys, setSurfaceSelectedRowKeys] = useState([]);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataAdd, setSelectedDataAdd] = useState([]);
  const [selectedPatternNo, setSelectedPatternNo] = useState(0);
  const [newPatternNm, setNewPatternNm] = useState('');

  // 編集モード
  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [changeRecordKey, setChangeRecordKey] = useState(0);
  const [copyPatternInfo, setCopyPatternInfo] = useState([]);
  const [defaultProcessPattern, setDefaultProcessPattern] = useState([]);
  const [createConfirm, setCreateConfirm] = useState(false);

  const [discardChangeMode, setDiscardChangeMode] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];

  // デフォルトページの表示件数
  const defaultPageSize = 25;

  useEffect(() => {
    settingDefaultProcessPattern();
  }, []);

  useEffect(() => {
    console.log(currentRoute);
    let navigatePage = ':parent.patternProcess';
    let paramType = 'parent';
    let paramSelected = 'oya_patternProcess';
    let patternLst = [];
    if (currentRoute.includes('parent')) {
      patternLst =
        localStorage.getItem('parent_processPattern') != undefined
          ? JSON.parse(localStorage.getItem('parent_processPattern'))
          : [];
    } else if (currentRoute.includes('iq3')) {
      navigatePage = ':iq3.patternProcess';
      paramType = 'iq3';
      paramSelected = 'iq3_patternProcess';
      patternLst =
        localStorage.getItem('iq3_processPattern') != undefined
          ? JSON.parse(localStorage.getItem('iq3_processPattern'))
          : [];
    }
    // DBからデータ取得
    setInitialTableItems(patternLst);
    // setSelectedRowKeys(patternLst.length > 0 ? patternLst[patternLst.length - 1].patternNo : 0);
    // setSelectedData(patternLst.length > 0 ? patternLst[patternLst.length - 1] : []);
    setSelectedRowKeys(patternLst.length > 0 ? patternLst[0].patternNo : 0);
    setSelectedData(patternLst.length > 0 ? patternLst[0] : []);
    setParamSelected(paramSelected);
    setParamType(paramType);
    props?.setParam(paramType, paramSelected);
    navigate(navigatePage);
  }, []);

  const settingDefaultProcessPattern = () => {
    let processMasterData = [];
    let processList = [];
    let sochiList = [];
    let key = 0;
    const kouteiMasterData =
      localStorage.getItem('kouteiMasterData') != undefined ? JSON.parse(localStorage.getItem('kouteiMasterData')) : [];

    // console.log('kouteiMasterData--', kouteiMasterData);
    //工程マスターデータにより種類選択ボックスデータ準備
    if (currentRoute.includes('parent')) {
      processMasterData = kouteiMasterData.filter((item) => item.type == '親部品');
      const sochiMasterData =
        localStorage.getItem('parent_devicesMaster') != undefined
          ? JSON.parse(localStorage.getItem('parent_devicesMaster'))
          : [];
      sochiList = sochiMasterData.filter((item) => item.type == '親部品');
    } else if (currentRoute.includes('iq3')) {
      processMasterData = kouteiMasterData.filter((item) => item.type == 'iQ3');
      const sochiMasterData =
        localStorage.getItem('iq3_devicesMaster') != undefined
          ? JSON.parse(localStorage.getItem('iq3_devicesMaster'))
          : [];
      sochiList = sochiMasterData.filter((item) => item.type == 'iQ3');
    }
    if (sochiList !== null && processMasterData !== null) {
      processMasterData.forEach((master) => {
        if (master.category == '追加') {
          let processDetailValue = master.processDetail[0]?.processDetailValue;
          for (let j = 0; j < processDetailValue?.length; j++) {
            let customTargetSochi = sochiList.filter(
              (item) => item.processName === master.processName && item.kouteiType == processDetailValue[j].detailType
            );
            processList.push({
              key: key,
              processType: master.processType,
              processName: master.processName,
              checked: 0,
              deviceCodeKey: customTargetSochi[0] ? customTargetSochi[0].key : '',
              deviceName:
                customTargetSochi[0] && customTargetSochi[0].deviceName ? customTargetSochi[0].deviceName : '',
              numberOfProcess: master.numberOfProcess,
              processDetailLst: [],
              companyCode: customTargetSochi[0] ? customTargetSochi[0].customerCode : '0',
              itakuFlag: customTargetSochi[0] ? customTargetSochi[0].itakuFlag : false,
              addProType: processDetailValue[j]?.detailType,
            });
            key++;
          }
        } else if (master.processType == 'ブランク' || master.processType == 'バリ取') {
          let processDetailValue = master.processDetail[0]?.processDetailValue;
          for (let j = 0; j < processDetailValue?.length; j++) {
            let customTargetSochi = sochiList.filter(
              (item) => item.processName === master.processName && item.kakouType == processDetailValue[j].detailType
            );
            processList.push({
              key: key,
              processType: master.processType,
              processName: master.processName,
              checked: 0,
              deviceCodeKey: customTargetSochi[0] ? customTargetSochi[0].key : '',
              deviceName:
                customTargetSochi[0] && customTargetSochi[0].deviceName ? customTargetSochi[0].deviceName : '',
              numberOfProcess: master.numberOfProcess,
              processDetailLst: [],
              companyCode: customTargetSochi[0] ? customTargetSochi[0].customerCode : '0',
              itakuFlag: customTargetSochi[0] ? customTargetSochi[0].itakuFlag : false,
              addProType: processDetailValue[j]?.detailType,
            });
            key++;
          }
        } else {
          let targetSouchi = sochiList.filter((item) => item.code == master.code);
          let processDetailLst = [];
          for (let i = 0; i < master.numberOfProcess; i++) {
            processDetailLst.push({
              key: i,
              processDetailType: '0',
              processDetailCode: '000',
              displayFlg: master.processDetail[0].processDetailValue[0].displayFlg,
              useFlg: master.processDetail[0].processDetailValue[0].useFlg,
            });
          }
          processList.push({
            key: key,
            processType: master.processType,
            processName: master.processName,
            checked: 0,
            deviceCodeKey: targetSouchi[0] ? targetSouchi[0].key : '',
            deviceName: targetSouchi[0] ? targetSouchi[0].deviceName : '',
            numberOfProcess: master.numberOfProcess,
            processDetailLst: processDetailLst,
            companyCode: '',
            addProType: '',
          });
          key++;
        }
      });
      setDefaultProcessPattern(processList);
    }
  };

  const onSelectChange = (checked, record, index, originNode) => {
    setSurfaceSelectedRowKeys(checked);
  };

  const rowSelection = {
    surfaceSelectedRowKeys,
    onChange: onSelectChange,
  };

  const updateSurface = () => {
    setUpdateItem(data);
    setIsUpdate(true);
  };

  const updateOK = () => {
    setUpdateConfirm(true);
  };

  const updConfirmOk = () => {
    setIsUpdate(false);
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setIsUpdate(false);
    setCancelConfirm(false);
    setSelectedRowKeys(changeRecordKey);
    setSelectedData(initialTblItems.find((item) => item.patternNo === changeRecordKey));
    detailRef?.current?.discardConfirmOk();
    if (discardChangeMode.state === discardChange[3].state) {
      setAddNewModal(true);
      setNewPatternNm(selectedData?.patternName);
      setSelectedPatternNo(selectedData?.key);
      setCopyPatternInfo(selectedData);
      setSelectedDataAdd(selectedData);
    }
  };

  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    setCancelConfirm(false);
    detailRef?.current?.discardConfirmCancel();
  };

  // 確認ポップアップのOKボタン押下
  const confirmOk = (e) => {
    setCreateConfirm(true);
  };

  const confirmCancel = (e) => {
    setCancelConfirm(true);
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    setCancelConfirm(false);
    setAddNewModal(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const updateCancel = () => {
    setCancelConfirm(true);
  };

  const addNew = () => {
    setAddNewModal(true);
  };

  const updateClose = () => {
    setIsUpdate(false);
  };

  const onRowSelect = (record) => {
    setChangeRecordKey(record.patternNo);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setSelectedRowKeys(record.patternNo);
      setSelectedData(record);
      if (currentRoute.includes('parent')) {
        navigate(':parent.patternProcess');
      } else {
        navigate(':iq3.patternProcess');
      }
    }
  };

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  const settingDiscardChangeMode = (mode) => {
    setDiscardChangeMode(mode);
  };

  const settingEditModeCancelConfirm = (mode) => {
    setEditModeCancelConfirm(mode);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setAddNewModal(true);
      setNewPatternNm(selectedData?.patternName);
      setSelectedPatternNo(selectedData?.key);
      setCopyPatternInfo(selectedData);
      setSelectedDataAdd(selectedData);
    }
  };

  const deletePatternInfo = () => {
    if (editMode) {
      setEditModeCancelConfirm(true);
      setDiscardChangeMode('');
    } else {
      setDeletePatternInfoModal(true);
    }
  };

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    addNew: addNew,
    updateSurface: updateSurface,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    showAddNewModal: showAddNewModal,
    deletePatternInfo: deletePatternInfo,
    setDiscardChangeMode: settingDiscardChangeMode,
    setEditModeCancelConfirm: settingEditModeCancelConfirm,
    discardChange: discardChange,
  }));

  const addOK = () => {
    setAddNewModal(false);
    setCreateConfirm(false);
    let newPatternInfo = [];
    let patternProcess = [];
    if (copyPatternInfo.patternProcess !== undefined) {
      patternProcess = copyPatternInfo.patternProcess;
    } else {
      patternProcess = defaultProcessPattern;
    }
    newPatternInfo.push({
      key: initialTblItems.length == 0 ? 0 : initialTblItems[initialTblItems.length - 1].key + 1,
      patternNo: initialTblItems.length + 1,
      patternName: newPatternNm,
      patternProcess: patternProcess,
      created: today,
      modified: today,
    });
    const data = [...initialTblItems];
    data.push(newPatternInfo[0]);
    setInitialTableItems(data);
    setSelectedRowKeys(newPatternInfo[0].patternNo);
    setSelectedData(data.length > 0 ? data[newPatternInfo[0].patternNo - 1] : []);
    setCurrentPage(Math.ceil(newPatternInfo[0].patternNo / 25));
    if (currentRoute.includes('parent')) {
      localStorage.setItem('parent_processPattern', JSON.stringify(data));
    } else if (currentRoute.includes('iq3')) {
      localStorage.setItem('iq3_processPattern', JSON.stringify(data));
    }
  };

  const addCancel = () => {
    // setAddNewModal(false);
    setCreateConfirm(false);
  };

  const deleteOK = () => {
    setDeletePatternInfoModal(false);
    let data = [...initialTblItems];
    data = data.filter((item) => item.key != selectedData.key);
    for (let i = 0; i < data.length; i++) {
      data[i].patternNo = i + 1;
    }
    setInitialTableItems(data);
    // setSelectedRowKeys(data.length > 0 ? data[data.length - 1].patternNo : 0);
    // setSelectedData(data.length > 0 ? data[data.length - 1] : []);
    setSelectedRowKeys(data.length > 0 ? data[0].patternNo : 0);
    setSelectedData(data.length > 0 ? data[0] : []);
    if (currentRoute.includes('parent')) {
      localStorage.setItem('parent_processPattern', JSON.stringify(data));
    } else if (currentRoute.includes('iq3')) {
      localStorage.setItem('iq3_processPattern', JSON.stringify(data));
    }
  };

  const deleteCancel = () => {
    setDeletePatternInfoModal(false);
  };

  const getInitData = (key) => {
    var data = getInitDataForProcess();
    var ret = data.find((i) => i.key === key);
    return ret;
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  const onChangeCopyPatternInfo = (key) => {
    let patternInfo = initialTblItems.filter((item) => item.key == key);
    setCopyPatternInfo(patternInfo[0]);
    setSelectedPatternNo(key);
    setSelectedDataAdd(patternInfo[0]);
  };

  const updateProcessPatternList = (updatedData) => {
    if (updatedData != null) {
      const data = [...initialTblItems];
      const index = data.findIndex((item) => item.key === updatedData.key);
      const updatedItems = [...data];
      updatedItems[index] = updatedData;
      setInitialTableItems(updatedItems);
      setSelectedData(updatedData);
      setSelectedRowKeys(updatedData.patternNo);
      if (currentRoute.includes('parent')) {
        localStorage.setItem('parent_processPattern', JSON.stringify(updatedItems));
      } else if (currentRoute.includes('iq3')) {
        localStorage.setItem('iq3_processPattern', JSON.stringify(updatedItems));
      }
    }
    setEditMode(false);
  };

  const updateSelectedData = (selectedProcessPattern) => {
    setSelectedData(selectedProcessPattern);
    setSelectedRowKeys(selectedProcessPattern.patternNo);
  };

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>選択したデータをまとめて編集</p>
    </div>
  );

  const addNewContent = (
    <>
      <Row style={{ marginTop: 0 }}>
        <RTable className="propertiesTbl">
          <thead>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">No</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Input
                  type="text"
                  value={initialTblItems.length + 1}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-non-editable'}
                ></Input>
              </td>
            </tr>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">工程パターン名</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Input
                  type="text"
                  value={newPatternNm}
                  onChange={(e) => setNewPatternNm(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row>
        <Col span={5} style={{ fontSize: 12, textAlign: 'right', paddingTop: 5 }}>
          <label>コピー元工程パターン：</label>
        </Col>
        <Col span={10}>
          <Select
            placeholder="選択"
            value={selectedPatternNo}
            style={{ fontSize: 11, width: '100%' }}
            onChange={onChangeCopyPatternInfo}
          >
            {initialTblItems?.map((option) => (
              <option value={option.key}>{option.patternName}</option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: -9 }}>
        <Col span={24} style={{ fontSize: 12 }}>
          <div className="overflow-auto" style={{ height: 690 }}>
            <ProcessPatternDetails
              ref={detailRef}
              editMode={editMode}
              selectedData={selectedDataAdd}
              listData={initialTblItems}
              getInitData={getInitData}
              updateEditMode={updateEditMode}
              updateProcessPatternList={updateProcessPatternList}
              isAdd={true}
            />
          </div>
        </Col>
      </Row>
    </>
  );

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'patternNo',
      width: 55,
      // render: (text, record, index) => <span>{initialTblItems.length - index}</span>,
      sorter: (a, b) => a.patternNo - b.patternNo,
      sortOrder: 'ascend',
      className: 'cm-a-right',
    },
    {
      id: '2',
      title: '工程パターン名',
      dataIndex: 'patternName',
      width: 150,
    },
    {
      id: '3',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
    },
    {
      id: '4',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
    },
  ];

  const initialUpdateValues = [1, 2, 3, 4, 5, 6, 7, 8];
  const tableColumns = columns.map((item) => ({
    ...item,
  }));
  const updateColumns = columns.filter((item) => {
    return (
      item.id ==
      initialUpdateValues.filter((i) => {
        if (i == item.id) {
          return i;
        }
      })
    );
  });

  return (
    <>
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            <Row style={{ marginTop: 10 }}>
              <Table
                showSorterTooltip={false}
                className="param_tbListShow"
                rowClassName={(record, index) => (record.patternNo == selectedRowKeys ? 'active-row' : 'data-row')}
                columns={tableColumns}
                rowKey={(record) => record.patternNo}
                dataSource={initialTblItems}
                scroll={{ y: 590, x: '10vw' }}
                pagination={{
                  position: ['bottomLeft'],
                  total: (record) => record.length,
                  showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                  defaultPageSize: defaultPageSize,
                  defaultCurrent: 1,
                  pageSize: defaultPageSize,
                  current: currentPage,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                      onRowSelect(record);
                    },
                  };
                }}
              />
            </Row>
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":parent.patternProcess"
                element={
                  <ProcessPatternDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    listData={initialTblItems}
                    getInitData={getInitData}
                    updateEditMode={updateEditMode}
                    updateProcessPatternList={updateProcessPatternList}
                    updateSelectedData={updateSelectedData}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 纏めて編集 */}
      <Modal
        centered
        title={editModalTitle}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={isUpdate}
        width={1300}
        onOk={updateOK}
        onCancel={updateClose}
        footer={[null]}
      >
        <Row>
          <Col span={14}>
            <p style={{ fontSize: '13.5px', marginBottom: 5, marginTop: 3, fontWeight: '700' }}>
              {surfaceSelectedRowKeys.length}件を選択中
            </p>
            <Table
              showSorterTooltip={false}
              style={{ marginTop: 10, marginBottom: -20 }}
              columns={updateColumns}
              rowKey={(record) => record.key}
              dataSource={updateItem}
              rowSelection={rowSelection}
              scroll={{ y: 600, x: '10vw' }}
              pagination={{
                position: ['bottomLeft'],
                total: (record) => record.length,
                showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                defaultPageSize: 100,
                defaultCurrent: 1,
              }}
            />
          </Col>
          <Col span={10}>
            <table className="table table-bordered" style={{ marginTop: 31 }}>
              <thead>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">表示名称</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="displayName"
                      id="displayName"
                      type="text"
                    />
                  </td>
                </tr>

                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">名称</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="name"
                      id="name"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">板厚</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="thickness"
                      id="thickness"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">比重</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="gravity"
                      id="gravity"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">重量単価</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="unitPrice"
                      id="unitPrice"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">作成日</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="createdDate"
                      id="createdDate"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">備考</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="remarks"
                      id="remarks"
                      type="text"
                    />
                  </td>
                </tr>
              </thead>
            </table>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: -20, marginBottom: -19 }}>
          <Space>
            <Button onClick={updateOK} id="update" className="mainButton">
              更新
            </Button>

            <Button onClick={updateCancel} className="cancelButton" id="discard">
              破棄
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(addOK, addCancel),
            null,
            400,
            addOK,
            addCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認_Modal */}
      <Modal
        title="確認"
        open={updateConfirm}
        onOk={updConfirmOk}
        onCancel={updConfirmCancel}
        footer={[
          <Button key="submit" onClick={updConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={updConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
      >
        <p>データを更新します。よろしいでしょうか。</p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規Modal */}
      {addNewModal
        ? commonModal(
            addNewModal,
            <div
              style={{
                width: 1200,
                backgroundColor: '#005fab',
                marginLeft: -24,
                height: 36,
                marginTop: -20,
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                fontSize: 14,
              }}
            >
              <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規追加</p>
            </div>,
            commonActionFooter(confirmOk, confirmCancel, '登録', '破棄'),
            { height: 820 },
            1200,
            confirmOk,
            confirmCancel,
            addNewContent,
            null,
            null,
            true
          )
        : ''}

      {/** 削除の確認メッセージ */}
      {deletePatternInfoModal
        ? commonModal(
            deletePatternInfoModal,
            confirmModalTitle,
            commonFooter(deleteOK, deleteCancel),
            null,
            400,
            deleteOK,
            deleteCancel,
            confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default ProcessPatternParamList;
