/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Layout, Menu, Collapse, Image } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import iQ3_create from '../assets/images/iQ3_create.png';
import iQ5_create from '../assets/images/iQ5_create.png';
import iQ7_create from '../assets/images/iQ7_create.png';
import company from '../assets/images/company.png';
import logout from '../assets/images/logout.png';
import parameter from '../assets/images/parameter.png';
import oya_list from '../assets/images/oya_list.png';
import ParentList from '../views/parent/ParentList';
import MainParameter from '../views/parameter/MainParameter';
import { WithRouter } from './WithRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import MainEstimate from '../views/common/MainEstimate';
import new_icon from '../assets/images/new_icon.png';
import {
  commonTaiochuModal,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
} from '../views/common/CommonModal';
import { getNewParentInfo } from '../views/common/Common';
import { logOutSubmit } from '../views/common/CommonAPI';
import dayjs from 'dayjs';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const MainLayout = (props, ref) => {
  const [isMenuClick, setIsMenuClick] = useState(false);
  const [paramType, setParamType] = useState('');
  const [paramSelected, setParamSelected] = useState('');
  const [partNm, setPartNm] = useState('parentList');
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  // 開発中ポップアップ
  const [commonTaiochuPopup, setCommonTaiochuPopup] = useState(false);
  // 画面切替警告メッセージポップアップ
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  // 切替する画面の情報
  const [event, setEvent] = useState('');
  const [pageName, setPageName] = useState('');
  const [partName, setPartName] = useState('');
  const [routeType, setRouteType] = useState('');
  const [iq3TargetRowKey, seIq3TargetRowKey] = useState([0]);
  const [estimateEditMode, setEstimateEditMode] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [currentRef, setCurrentRef] = useState([]);
  const [parentListClick, setParentListClick] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  const [curPageNm, setCurPageNm] = useState([]);
  const [curPartNm, setCurPartNm] = useState([]);

  const paramRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const mainEstimateRef = useRef();
  const parentListRef = useRef();
  const menuContent = useRef(null);

  useEffect(() => {
    setPartNm('parentList');
  }, []);

  const updateParamSelected = (param) => {
    console.log(param);
    setParamType(param);
    let textMenu;
    switch (param) {
      case '共通':
        textMenu = '・共通';
        break;
      case 'parent':
        textMenu = '・親部品';
        break;
      case 'iq3':
        textMenu = '・iQ3';
        break;
    }
    if (param === '共通') {
      setParamType('common');
    }
    let menus = menuContent?.current?.menu?.list?.children;
    let ul = menuContent?.current?.menu?.list;
    if (ul.classList.contains('paramMenu')) {
      ul.classList.remove('paramMenu');
      ul.classList.add('paramMenu-active');
    }
    [...menus].forEach((menu) => {
      if (menu.innerText.includes(textMenu)) {
        menu.classList.add('ant-menu-submenu-active');
        menu.classList.add('ant-menu-submenu-selected');
      } else {
        menu.classList.remove('ant-menu-submenu-active');
        menu.classList.remove('ant-menu-submenu-selected');
      }
    });
  };

  //　ログアウトボタンの押下
  const handleClick = async (e) => {
    e.preventDefault();
    const result = await logOutSubmit();
    if (result) {
      navigate('/login');
    }
  };

  // 画面切り替えする
  const discardConfirmOk = () => {
    setEstimateEditMode(false);
    setEditModeCancelConfirm(false);
    if (routeType === 1) {
      navigatePage(event, pageName, partName);
    } else if (routeType === 2) {
      paramChangeEvent(event);
    } else {
      handleClick(event);
    }
  };

  // 画面切り替えしない
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // 編集中の場合、画面切替警告メッセージポップアップを表示
  const isEditingMode = (e, type, page, part) => {
    setEvent(e);
    setRouteType(type);
    setPageName(page);
    setPartName(part);
    if (estimateEditMode || paramRef?.current?.getCurrentEditMode()) {
      setEditModeCancelConfirm(true);
      return true;
    }
    return false;
  };

  const paramChangeEvent = (e) => {
    setCurrentRef(paramRef);
    setOpenKeys([]);
    if (e.keyPath[1] == 'common' && e.key == 'c1') {
      setParamSelected('customer');
      navigate(':common.customer');
    } else if (e.keyPath[1] == 'common' && e.key == 'c2') {
      setParamSelected('personInCharge');
      navigate(':common.personInCharge');
    } else if (e.keyPath[1] == 'common' && e.key == 'c3') {
      setParamSelected('purchase');
      navigate(':common.purchase');
    } else if (e.keyPath[1] == 'common' && e.key == 'c4') {
      setParamSelected('coefficient');
      navigate(':common.coefficient');
    } else if (e.keyPath[1] == 'common' && e.key == 'c5') {
      setParamSelected('additional');
      navigate(':common.additional');
    } else if (e.keyPath[1] == 'common' && e.key == 'c6') {
      setParamSelected('project');
      navigate(':common.project');
    } else if (e.keyPath[1] == 'common' && e.key == 'c7') {
      setParamSelected('processMaster');
      navigate(':common.processMaster');
    } else if (e.keyPath[1] == 'common' && e.key == 'c8') {
      setParamSelected('materialClass');
      navigate(':common.materialClass');
    } else if (e.keyPath[1] == 'common' && e.key == 'c9') {
      setParamSelected('autoReserve');
      navigate(':common.autoReserve');
    } else if (e.keyPath[1] == 'parent' && e.key == 'p1') {
      setParamSelected('oya_processMaster');
      navigate(':parent.processMaster');
    } else if (e.keyPath[1] == 'parent' && e.key == 'p2') {
      setParamSelected('oya_chargeProcess');
      navigate(':parent.device');
    } else if (e.keyPath[1] == 'parent' && e.key == 'p3') {
      setParamSelected('oya_patternProcess');
      navigate(':parent.patternProcess');
      return;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b1') {
      setParamSelected('iq3_processMaster');
      navigate(':iq3.processMaster');
      return;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b2') {
      setParamSelected('iq3_chargeProcess');
      navigate(':iq3.device');
      return;
      // openCommonTaiochuPopup(); // 開発中
      // return false;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b3') {
      setParamSelected('iq3_patternProcess');
      navigate(':iq3.patternProcess');

      return;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b4') {
      setParamSelected('iq3_materialClassName');
      navigate(':iq3.materialClassName');
      return;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b5') {
      setParamSelected('iq3_materialName');
      navigate(':iq3.materialName');

      return;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b6') {
      setParamSelected('iq3_materialSurface');
      navigate(':iq3.materialSurface');

      return;
    } else if (e.keyPath[1] == 'iq3' && e.key == 'b7') {
      setParamSelected('iq3_materialSize');
      navigate(':iq3.materialSize');

      return;
    }
    // else if (e.keyPath[1] == 'iq5' && e.key == 'k1') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k2') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k3') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k4') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k5') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k6') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k7') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq5' && e.key == 'k8') {
    //   setParamSelected('material');
    //   navigate(':material');
    // }
    // else if (e.keyPath[1] == 'iq7' && e.key == '36') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '37') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '38') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '39') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '40') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '41') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '42') {
    //   setParamSelected('material');
    //   navigate(':material');
    // } else if (e.keyPath[1] == 'iq7' && e.key == '43') {
    //   setParamSelected('material');
    //   navigate(':material');
    // }
    setIsMenuClick(true);
    setParamType(e.keyPath[1]);
  };

  const onOpenChange = (e) => {
    setOpenKeys([]);
  };

  const onClickMenuTitle = (menuTitle, e) => {
    if (menuTitle == 'common') {
      setOpenKeys(['common']);
    } else if (menuTitle == 'parent') {
      setOpenKeys(['parent']);
    } else if (menuTitle == 'iq3') {
      setOpenKeys(['iq3']);
    } else {
      setOpenKeys([]);
    }
  };

  const getItem = (label, key, children) => {
    return {
      key,
      children,
      label,
    };
  };
  const commonItemTitle = <a onClick={(e) => onClickMenuTitle('common', e)}>{'・共通'}</a>;
  const parentItemTitle = <a onClick={(e) => onClickMenuTitle('parent', e)}>{'・親部品'}</a>;
  const iq3ItemTitle = <a onClick={(e) => onClickMenuTitle('iq3', e)}>{'・iQ3'}</a>;
  const items = [
    getItem(commonItemTitle, 'common', [
      getItem('お客様・仕入先', 'c1'),
      getItem('担当者', 'c2'),
      getItem('購入品', 'c3'),
      getItem('係数', 'c4'),
      getItem('追加項目', 'c5'),
      getItem('案件状態', 'c6'),
      getItem('工程マスター', 'c7'),
      getItem('材質区分', 'c8'),
      getItem('自動引当', 'c9'),
    ]),
    getItem(parentItemTitle, 'parent', [
      getItem('工程マスター', 'p1'),
      getItem('装置設定', 'p2'),
      getItem('工程パターン', 'p3'),
    ]),
    getItem(iq3ItemTitle, 'iq3', [
      getItem('工程マスター', 'b1'),
      getItem('装置設定', 'b2'),
      getItem('工程パターン', 'b3'),
      getItem('材質名称＊（名称毎比重、材料ロス係数）', 'b4'),
      getItem('材料名称（個別比重、単価）', 'b5'),
      getItem('材料表面', 'b6'),
      getItem('材料サイズ', 'b7'),
    ]),
    // getItem('・iQ5', 'iq5', [
    //   getItem('工程・チャージ', 'k1'),
    //   getItem('工程パターン', 'k2'),
    //   getItem('段取り・加工設定', 'k3'),
    //   getItem('鋼種区分（種類毎使用有無）', 'k4'),
    //   getItem('材質区分＊（元比重,加工速度区分に利用）', 'k5'),
    //   getItem('材質名称＊（名称毎比重、材料ロス係数）', 'k6'),
    //   getItem('材料名称（個別比重、単位重量、重量単価、ｍ単価）', 'k7'),
    //   getItem('材料サイズ', 'k8'),
    // ]),
    // getItem('・iQ7', 'iq7', [
    //   getItem('設備', '36'),
    //   getItem('加工種類', '37'),
    //   getItem('材質', '38'),
    //   getItem('段取', '39'),
    //   getItem('公差', '40'),
    //   getItem('公差グループ', '41'),
    //   getItem('材料のサイズ・重量', '42'),
    //   getItem('割増割引', '43'),
    // ]),
  ];

  const navigatePage = (e, pageNm, partNm) => {
    setIsMenuClick(false);
    setParamType('');
    setParamSelected('');
    if (partNm === 'parent' || partNm === 'iq3') {
      setCurPageNm(pageNm);
      setCurPartNm(partNm);
      setConfirmDisplayDataSaveModalOpen(true);
    } else if (partNm === 'parentList') {
      setParentListClick(!parentListClick);
      setPartNm(partNm);
      navigate(pageNm);
    }
  };

  const goToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
    setSelectedEstimateData(getNewParentInfo(selectedEstimateData?.length));
    setEstimateEditMode(true);
    setPartNm(curPartNm);
    navigate(curPageNm);
  };
  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  const renderComponent = () => {
    if (location.pathname == '/home' || location.pathname == '/home/:parentList') {
      return (
        <ParentList
          ref={parentListRef}
          updateEstimateEditMode={updateEstimateEditMode}
          getSelectedEstimateData={getSelectedEstimateData}
          editMode={estimateEditMode}
          parentListClick={parentListClick}
        />
      );
    } else if (location.pathname == '/home/:estimate.parent') {
      return (
        <MainEstimate
          ref={mainEstimateRef}
          updateEstimateEditMode={updateEstimateEditMode}
          selectedEstimateData={selectedEstimateData}
          editMode={estimateEditMode}
          activeTab="1"
        />
      );
    } else if (location.pathname == '/home/:estimate.iq3') {
      return (
        <MainEstimate
          ref={mainEstimateRef}
          updateEstimateEditMode={updateEstimateEditMode}
          selectedEstimateData={selectedEstimateData}
          iq3TargetRowKey={iq3TargetRowKey}
          editMode={estimateEditMode}
          activeTab="2"
        />
      );
    } else {
      return (
        <MainParameter
          paramType={paramType}
          paramNm={paramSelected}
          updateParamSelected={updateParamSelected}
          ref={paramRef}
        />
      );
    }
  };

  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedEstimateData(estimateData);
    seIq3TargetRowKey(iq3RowKey == null ? 0 : iq3RowKey);
    setEstimateEditMode(estimateEditMode);
  };

  const updateEstimateEditMode = (mode) => {
    setEstimateEditMode(mode);
  };

  // 開発中ポップアップを開く
  const openCommonTaiochuPopup = () => {
    setCommonTaiochuPopup(true);
  };

  // 開発中ポップアップを閉じる
  const closeCommonTaiochuPopup = () => {
    setCommonTaiochuPopup(false);
  };

  return (
    <>
      <Layout>
        {/* ヘッダー */}
        <Header />
        <Layout
          style={{
            background: 'white',
            left: 0,
          }}
          hasSider
        >
          {/* サイドメニュ */}
          <Sider
            style={{
              background: 'none',
              backgroundColor: '#fafafc',
              left: 0,
              bottom: 0,
              marginLeft: '0px',
              marginTop: '1px',
              borderRight: '1px solid #212529',
            }}
            width={75}
          >
            <div style={{ paddingLeft: '5px', display: 'flex', flexDirection: 'column', height: '94.5vh' }}>
              <div style={{ paddingTop: '0px' }}>
                <a
                  onClick={(e) =>
                    !isEditingMode(e, 1, ':parentList', 'parentList')
                      ? navigatePage(e, ':parentList', 'parentList')
                      : ''
                  }
                >
                  <Image preview={false} width={35} src={oya_list} style={{ marginLeft: '15px' }}></Image>
                  <p
                    style={{
                      color: location.pathname == '/home/:parentList' ? '#1677ff' : '#4a4747',
                      fontSize: '10.5px',
                      fontWeight: 'bold',
                    }}
                  >
                    親部品リスト
                  </p>
                </a>
              </div>

              <div>
                <a
                  onClick={(e) =>
                    !isEditingMode(e, 1, ':estimate.parent', 'parent')
                      ? navigatePage(e, ':estimate.parent', 'parent')
                      : ''
                  }
                >
                  <Image preview={false} width={33} src={new_icon} style={{ marginLeft: '15px' }}></Image>
                  <p
                    style={{
                      color: partNm == 'parent' && isMenuClick == false ? '#1677ff' : '#4a4747',
                      fontSize: '10.5px',
                      fontWeight: 'bold',
                      marginLeft: '15px',
                    }}
                  >
                    親部品
                  </p>
                </a>
              </div>

              <div>
                <a
                  onClick={(e) =>
                    !isEditingMode(e, 1, ':estimate.iq3', 'iq3') ? navigatePage(e, ':estimate.iq3', 'iq3') : ''
                  }
                >
                  <Image preview={false} width={33} src={iQ3_create} style={{ marginLeft: '15px' }}></Image>
                  <p
                    style={{
                      color: partNm == 'iq3' && isMenuClick == false ? '#1677ff' : '#4a4747',
                      fontSize: '10.5px',
                      fontWeight: 'bold',
                      marginLeft: '12px',
                    }}
                  >
                    iQ3板金
                  </p>
                </a>
              </div>

              {/* <div>
              <Image preview={false} width={33} src={iQ5_create} style={{ marginLeft: '15px' }}></Image>
              <p
                style={{
                  color: partNm == 'iq5' ? '#1677ff' : '#4a4747',
                  fontSize: '10.5px',
                  fontWeight: 'bold',
                  marginLeft: '12px',
                }}
              >
                iQ5形鋼
              </p>
            </div> */}

              {/* <div>
              <Image preview={false} width={33} src={iQ7_create} style={{ marginLeft: '15px' }}></Image>
              <p
                style={{
                  color: partNm == 'iq7' ? '#1677ff' : '#4a4747',
                  fontSize: '10.5px',
                  fontWeight: 'bold',
                  marginLeft: '12px',
                }}
              >
                iQ7機械
              </p>
            </div> */}

              <div style={{ height: '68.5vh' }}>
                <Image preview={false} width={30} src={parameter} style={{ marginLeft: '15px' }}></Image>
                <p style={{ color: '#4a4747', fontSize: '10.5px', fontWeight: 'bold', marginLeft: '5px' }}>
                  パラメータ
                </p>
                <Menu
                  className={isMenuClick ? 'paramMenu-active' : 'paramMenu'}
                  ref={menuContent}
                  selectedKeys={[paramType]}
                  onClick={(e) => (!isEditingMode(e, 2, '', '') ? paramChangeEvent(e) : '')}
                  style={{
                    width: 94,
                    marginLeft: '-10px',
                    fontSize: '10.5px',
                    color: '#4a4747',
                    height: 0,
                    marginTop: '-20px',
                    fontWeight: 'bold',
                  }}
                  mode="vertical"
                  items={items}
                  onOpenChange={onOpenChange}
                  openKeys={openKeys}
                />
              </div>

              <div>
                <a onClick={(e) => (!isEditingMode(e, 3, '', '') ? handleClick(e) : '')}>
                  <Image preview={false} width={26} src={logout} style={{ marginLeft: '15px' }}></Image>
                </a>
                <p
                  style={{ color: '#4a4747', fontSize: '10.5px', fontWeight: 'bold', marginTop: 2, marginLeft: '5px' }}
                >
                  ログアウト
                </p>
              </div>
            </div>
          </Sider>
          {/* コンテンツ */}
          <Layout style={{ background: 'white' }}>
            <Content style={{ overflow: 'initial' }}>{renderComponent()}</Content>
          </Layout>
        </Layout>
      </Layout>
      {/** 開発中ポップアップ */}
      {commonTaiochuPopup ? commonTaiochuModal(commonTaiochuPopup, closeCommonTaiochuPopup) : ''}

      {/** 画面切替警告メッセージポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToCreateParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToCreateParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}
    </>
  );
};

export default MainLayout;
