/**
 * クラス名：材質名称一覧画面
 * 説明：iQ3板金に使用の材質名称一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import MaterialClassNameDetail from './MaterialClassNameDetail';
import 'split-pane-react/esm/themes/default.css';
import { useLocation, useNavigate } from 'react-router-dom';
import SplitPane, { Pane } from 'split-pane-react';
import { Table as RTable } from 'react-bootstrap';

import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmCreateContent,
  confirmDeleteContent,
  commonActionFooter,
  confirmUpdateContent,
} from '../../../common/CommonModal';
import { getKubunNameDDL, actionArr, dataStateList, getMaterialClassNameStorage } from '../../../common/Common.js';

dayjs.extend(customParseFormat);

const { Option } = Select;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialClassNameList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [paramSelected, setParamSelected] = useState('材質名称＊（名称毎比重、材料ロス係数）');
  const [paramType, setParamType] = useState('iq3');
  const [materialClassNameData, setMaterialClassNameData] = useState([]);
  const [tempMaterialClass, setTempMaterialClass] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState();

  const [dataState, setDataState] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(0);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  useEffect(() => {
    let materialClassNameDataSample = getMaterialClassNameStorage();
    setMaterialClassNameData(materialClassNameDataSample);
    setFilteredData(materialClassNameDataSample);
    setTempMaterialClass(materialClassNameDataSample);
    setSelectedRowKeys(materialClassNameDataSample[0]?.no);
    setSelectedData(materialClassNameDataSample[0]);
    props.setParam(paramType, paramSelected);
    setIsFilter(true);
  }, []);

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      sorter: (a, b) => a.no - b.no,
      sortOrder: 'ascend',
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'displayFlag',
      title: '表示',
      dataIndex: 'displayFlag',
      width: 95,
      render: (text, record) => {
        return record.displayFlag && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
    },
    {
      key: 'classnameCode',
      title: 'コード',
      dataIndex: 'classnameCode',
      width: 120,
    },
    {
      key: 'materialName',
      title: '材質名称',
      dataIndex: 'materialName',
      width: 150,
    },
    {
      key: 'materialClassName',
      title: '材質区分名',
      dataIndex: 'materialClassName',
      width: 150,
    },
    {
      key: 'gravity',
      title: '比重',
      dataIndex: 'gravity',
      width: 100,
      className: 'cm-a-right',
    },
    {
      key: 'lossFactor',
      title: '材料ロス係数',
      dataIndex: 'lossFactor',
      width: 120,
      className: 'cm-a-right',
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
    },
    {
      key: 'remark',
      title: '備考',
      dataIndex: 'remark',
      width: 200,
    },
  ];

  const addModalContent = (
    <div id="addNewMaterialClass" style={{ marginTop: 8 }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label>{tempMaterialClass.no}</label>
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">表示</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
              <RadioGroup
                name="displayFlag"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                  }
                }}
                value={tempMaterialClass.displayFlag}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">コード</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.classnameCode}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, classnameCode: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材質名称</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.materialName}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, materialName: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材質区分名</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, materialClassName: e });
                  }
                }}
                id="materialClassNameDDL"
                name="materialClassNameDDL"
                /* value={tempMaterialClass.materialClassName} */
                defaultValue="defaultValue"
              >
                <Option id="defaultValue" value="defaultValue">
                  材質区分名を選択でください。
                </Option>
                {
                  /* console.log('getKubunNameDDL---', getKubunNameDDL()); */ getKubunNameDDL().map((item) => (
                    <option id={'materialClassName' + item.value} value={item.label}>
                      {item.label}
                    </option>
                  ))
                }
              </Select>
              {/* </div> */}
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">比重</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.gravity}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, gravity: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材料ロス係数</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.lossFactor}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, lossFactor: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">作成日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{tempMaterialClass.created}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">更新日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{tempMaterialClass.modified}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">備考</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <TextArea
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.remark}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );

  const onSelectedRowKeysChange = (checked, record, index) => {
    let selectedRowKeys = [...checked];
    setCheckedRowKeys(selectedRowKeys);
    setChkRowsCount(selectedRowKeys.length);
  };

  const rowSelection = {
    onChange: onSelectedRowKeysChange,
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const gpEditModalContent = (
    <Form>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Col span="13">
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            dataSource={filteredData}
            columns={columns}
            scroll={{ y: 480, x: '58vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
            rowKey={(record) => record.no}
          />
        </Col>
        <Col span="11">
          <div
            id="editGpDataTb"
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              // overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td className="param-detail-tbl-val-title">表示</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="displayFlag"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                        }
                      }}
                      value={tempMaterialClass.displayFlag}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>

                <tr>
                  <td className="param-detail-tbl-val-title">材質区分名</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, materialClassName: e });
                        }
                      }}
                      id="materialClassNameDDL"
                      name="materialClassNameDDL"
                      /* value={tempMaterialClass.materialClassName} */
                      defaultValue="defaultValue"
                    >
                      <Option id="defaultValue" value="defaultValue">
                        材質区分名を選択でください。
                      </Option>
                      {getKubunNameDDL().map((item) => (
                        <option id={'materialClassName' + item.value} value={item.label}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                    {/* </div> */}
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">材料ロス係数</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <Input
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={tempMaterialClass.lossFactor}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, lossFactor: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">備考</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <TextArea
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={tempMaterialClass.remark}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
          更新
        </Button>
        <Button
          /* style={{ marginLeft: 10, color: '#000000', fontSize: '13.5px' }} */ className="cancelButton"
          onClick={groupEditCancel}
        >
          破棄
        </Button>
      </Row>
    </Form>
  );

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  const onRowSelect = (record, index) => {
    setChangeRecordKey(record.no);
    // setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setEditModeCancelConfirm(true);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
    } else {
      setSelectedRowKeys(record.no);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq3.materialClassName');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    //setCancelConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    // setVisibleCSVOutput(false);
    // setVisibleAddNewModal(false);
    // setVisibleCSVInput(false);
    // setVisibleXMLInput(false);
    setVisibleAddNewModal(false);
  };

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey]);
      return newObj[objKey];
    }
  };

  const updConfirmOk = (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialClassNameData));
    if (visibleAddNewModal) {
      stateData.push(tempMaterialClass);
      localStorage.setItem('materialClassNameStorage', JSON.stringify(stateData));
      setTmpFilteredData(stateData);
      setMaterialClassNameData(stateData);
      resetAddModal(stateData.length + 1);
      //onRowSelect(tempMaterialClass);
      setSelectedRowKeys(tempMaterialClass.no);
      setSelectedData(tempMaterialClass);
      setItemNo(tempMaterialClass.no);
      setCurrentPage(Math.ceil(tempMaterialClass.no / 25));
      setVisibleAddNewModal(false);
    } else if (visibleGroupEditModal) {
      if (checkedRowKeys.length > 0) {
        const updatedData = stateData.map((obj) => {
          if (checkedRowKeys.includes(obj.key + 1)) {
            return {
              ...obj,
              displayFlag: gpEditCheck(tempMaterialClass, obj, 'displayFlag'),
              materialClassName: gpEditCheck(tempMaterialClass, obj, 'materialClassName'),
              lossFactor: gpEditCheck(tempMaterialClass, obj, 'lossFactor'),
              remark: gpEditCheck(tempMaterialClass, obj, 'remark'),
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        setMaterialClassNameData(updatedData);
        setTmpFilteredData(updatedData);
        localStorage.setItem('materialClassNameStorage', JSON.stringify(updatedData));
      }
      setVisibleGroupEditModal(false);
    }
    /* setUpdateConfirm(false); */
    setUpdateCancelConfirm(false);
    setIsFilter(true);
  };

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
      setChkRowsCount(0);
    }
  };

  const updConfirmCancel = () => {
    /* setUpdateConfirm(false); */
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[1]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
    /* setVisibleGroupEditModal(true); */
  };

  const handleGpEdit = () => {
    setTempMaterialClass({
      displayFlag: true,
      materialClassName: '',
      lossFactor: '',
      remark: '',
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  const showDeleteModal = () => {
    setVisibleDeleteModal(true);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleAddFormSubmit();
    }
  };

  const handleAddFormSubmit = () => {
    let count = materialClassNameData.length;
    setTempMaterialClass({
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (parseFloat(materialClassNameData[materialClassNameData.length - 1].classnameCode) + 1)
        .toString()
        .padStart(3, '0'),
      materialName: '',
      materialClassName: '',
      gravity: '',
      lossFactor: '',
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
      remark: '',
    });
    setVisibleAddNewModal(true);
    /* setChildTitle('材質区分'); */
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const deleteOK = () => {
    setVisibleDeleteModal(false);
    let data = [...materialClassNameData];
    data = data.filter((item) => item.key != selectedData.key);

    for (let i = 0; i < data.length; i++) {
      data[i].no = i + 1;
    }

    setMaterialClassNameData(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    localStorage.setItem('materialClassNameStorage', JSON.stringify(data));
    setSelectedRowKeys(data.length > 0 ? data[0].no : -1);
    setSelectedData(data.length > 0 ? data[0] : []);
    setIsFilter(true);
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = (count) => {
    setTempMaterialClass({
      key: count,
      displayFlag: false,
      classificationCode: (count + 1).toString().padStart(3, '0'),
      classificationName: '',
      gravity: '',
      materialRefPrice: '',
      remark: '',
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, modelKeyword, dataState, showFlag, tmpFilteredData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordKey !== undefined) {
        setSelectedRowKeys(changeRecordKey);
        setSelectedData(filteredData.find((item) => item.no === changeRecordKey));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordKey === undefined) {
        console.log(selectedRowKeys, selectedData);
        return;
      } else {
        const action = actionArr.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5].includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // 無効フラグの押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialClassNameData.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let currentData = [...materialClassNameData];
    let flag;
    let materialFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData.filter((item) => item.materialName?.toString().indexOf(nKeyword) >= 0);
    }
    // 型番のキーワードで絞り込み
    if (mKeyword != '') {
      materialFilterData = materialFilterData.filter((item) => item.gravity?.toString().indexOf(mKeyword) >= 0);
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData.filter((item) => item.displayFlag === flag);
    }

    //参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData.filter((item) => item.materialClassName === dataState);
    }
    console.log(selectedRowKeys, selectedData);
    /*  let index = materialFilterData.findIndex((item) => item.no === selectedRowKeys);

    if (index === -1) {
      if (editMode) {
        
        materialFilterData.length > 0 && setSelectedRowKeys(materialFilterData[materialFilterData?.length - 1].no);
        setFilteredData(materialFilterData);
        return;
      }
      // 検索結果がある場合、
      if (materialFilterData.length > 0) {
        index = materialFilterData.length - 1;
        setSelectedRowKeys(materialFilterData[index]?.no);
        setSelectedData(materialFilterData[index]);
      }
    }
    if (materialFilterData.length > 0 && isFilter) {
      
      setSelectedRowKeys(materialFilterData[index].no);
     
    } else if (materialFilterData.length <= 0) {
      setSelectedData([]);
    } */
    setFilteredData(materialFilterData);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = (updatedData) => {
    var tmpData = [...materialClassNameData];
    var index = tmpData.findIndex((item) => item.key === updatedData.key);
    tmpData[index] = updatedData;
    setMaterialClassNameData(tmpData);
    setTmpFilteredData(tmpData);
    setSelectedRowKeys(updatedData.no);
    setSelectedData(updatedData);
    setIsFilter(false);
    localStorage.setItem('materialClassNameStorage', JSON.stringify(tmpData));
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Select
                    style={{ width: 90 }}
                    onChange={beforeSearch /* searchByShowFlg */}
                    placeholder="表示・非表示"
                    id="showFlgDrop"
                    name="showFlgDrop"
                    value={showFlag}
                  >
                    {dataStateList.map((item) => (
                      <option id={'showFlg' + item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <Input
                    className="input-editable search-select-input"
                    placeholder="材質名称"
                    allowClear
                    onPressEnter={beforeSearch}
                    onBlur={beforeSearch}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch(e);
                      }
                    }}
                    name="name"
                    id="name"
                  />

                  <Select
                    style={{ width: 170 }}
                    onChange={beforeSearch}
                    id="materialRefDDL"
                    name="materialRefDDL"
                    value={dataState}
                    defaultValue={'全て'}
                  >
                    <Option id="allOption" value={'全て'}>
                      {'全ての材質区分名'}
                    </Option>
                    {getKubunNameDDL().map((item) => (
                      <Option id={'materialRef' + item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    className="input-editable search-select-input"
                    placeholder="比重"
                    allowClear
                    onPressEnter={beforeSearch}
                    onBlur={beforeSearch}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch(e);
                      }
                    }}
                    name="model"
                    id="model"
                  />
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '50vw' }}
                    rowClassName={(record) => (record.no == selectedRowKeys ? 'active-row' : 'data-row')}
                    rowKey={(record) => record.no}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      defaultPageSize: 25,
                      defaultCurrent: 1,
                      pageSize: 25,
                      current: currentPage,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, index);
                        },
                      };
                    }}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq3.materialClassName"
                element={
                  <MaterialClassNameDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialClassNameData.find((element) => element.no === selectedRowKeys)}
                    itemNo={itemNo}
                    /*  selectedData={selectedData} */
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質名称（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材質名称（選択したデータをまとめて編集）', 1790, -24),
          /* commonActionFooter(groupEditOK, groupEditCancel, '更新', '破棄'), */
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'updateModalStyle',
          null,
          true
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialClassNameList;
