/**
 * クラス名：装置設定の取付き時間（サイズ判断）画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Input, Button, Space } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';

import { commonModal } from '../../../common/CommonModal';

const ToritsukiJikan_Size = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [isSize, setIsSize] = useState(false);

  const [detailsSize, setDetailsSize] = useState([]);
  const [detailsSizeTemp, setDetailsSizeTemp] = useState([]);
  const [sizesList, setSizesList] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    setDetailsSize(props.detailsSizes);
    setDetailsSizeTemp(props.detailsSizes);
    setSizesList(props.sizeList);
  }, [props.editMode, props.detailsSizes, props.sizeList]);

  const sizeModal = () => {
    setIsSize(true);
  };

  const addSizeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>サイズ</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const addSize = (id) => {
    const copyData = detailsSizeTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsSizeTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsSizeTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsSizeTemp(copyData);
    }
  };

  const deleteSize = (id, e) => {
    if (detailsSizeTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsSizeTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsSizeTemp(updatedData);
    }
  };
  const closeAddModal = () => {
    if (isSize === true) {
      setIsSize(false);
      setDetailsSizeTemp(detailsSize);
    }
  };
  const cancelAddModal = () => {
    if (isSize === true) {
      setIsSize(false);
      setDetailsSizeTemp(detailsSize);
    }
  };

  const handleSizeChange = (event, id, field) => {
    const updatedData = detailsSizeTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsSizeTemp(updatedData);
  };

  const addSizeMaster = () => {
    const deletedId = [];

    detailsSize.forEach((item1, index) => {
      const found = detailsSizeTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsSizeTemp
      .filter((item) => item.displayFlag && !detailsSize.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = sizesList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsSizeTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsSizeTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    setIsSize(false);
    setDetailsSize(detailsSizeTemp);

    setSizesList(sortedList);
    props.updatedSize(detailsSizeTemp);

    props.updatedSizeList(sortedList);
  };

  const addSizeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsSizeTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleSizeChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSize(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSize(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addSizeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelAddModal}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const sizeListDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...sizesList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setSizesList(updatedData);
    props.updatedSizeList(updatedData);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Row>
          <Table
            style={{
              width:
                detailsSize.filter((i) => i.displayFlag === true).length > 8
                  ? '100%'
                  : detailsSize.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header">
                <Row justify="center" className="tbl-header">
                  <Space>
                    取付種類 /
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={sizeModal} style={{ pointerEvents: 'auto' }}>
                          サイズ
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>サイズ</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {detailsSize
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～M{item.max}</td>
                ))}
            </tr>

            {sizesList.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                  {row.materialType}
                </td>

                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => sizeListDataChange(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </Table>
        </Row>
      </div>

      {/* サイズ */}
      {isSize
        ? commonModal(
            isSize,
            addSizeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSizeContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default ToritsukiJikan_Size;
