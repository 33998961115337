/**
 * クラス名：板金子部品工程入力
 * 説明：板金子部品工程入力ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import IQ3KouteiInput_Program from './IQ3KouteiInput_Program';
import IQ3KouteiInput_Plank from './IQ3KouteiInput_Plank';
import IQ3KouteiInput_Bending from './IQ3KouteiInput_Bending';
import IQ3KouteiInput_Baritori from './IQ3KouteiInput_Baritori';
import IQ3KouteiInput_Auto from './IQ3KouteiInput_Auto';
import IQ3KouteiInput_Yousetsu from './IQ3KouteiInput_Yousetsu';
import IQ3KouteiInput_Hyoumenshori from './IQ3KouteiInput_Hyoumenshori';
import IQ3KouteiInput_2Jikakou from './IQ3KouteiInput_2Jikakou';
import IQ3KouteiInput_Shirring from './IQ3KouteiInput_Shirring';
import IQ3KouteiInput_Kensa from './IQ3KouteiInput_Kensa';

const surfaceData = [];

for (let i = 1; i < 10; i++) {
  surfaceData.push({
    key: i,
    parentname: `iQ3板金`,
    childname: `Test-${i}`,
    surface: '100',
    weight: 10,
    quality: 'SPCC',
    thick: 1.6,
  });
}
const data = [
  { id: 1, types: 'バーリング', xsize: '10', ysize: '10', perimeter: '31.41', count: '20', blankFlag: false },
];
const data2 = [{ id: 1, types: '丸', xsize: '10', ysize: '10', perimeter: '31.41', count: '20' }];

const baritoriAutodata = [{ id: 1, types: '自動', area: '', weight: '', autoExtract: '', count: '' }];
const baritoriManualdata = [
  { id: 1, types: '手動', target: '外形', length: '', autoExtract: '', count: '' },
  { id: 2, types: '手動', target: '内形', length: '', autoExtract: '', count: '' },
];
const hyoumenShoriData = [{ id: 1, types: '脱脂', details: '', area: '10', autoExtract: '10', count: '2' }];
const blankData = [{ val1: 1200, val2: 150, val3: 2.275, val4: 1800, val5: 100, val6: 150 }];
const types = [
  { id: 1, value: 'SPCC - 2.3', label: 'SPCC - 2.3' },
  { id: 2, value: 'SPCH(黒皮) - 1.2', label: 'SPCH(黒皮) - 1.2' },
];
const itaatsuTypes = [
  { id: 1, value: '2.3', label: '2.3' },
  { id: 2, value: '1.2', label: '1.2' },
];
const IQ3KouteiInput = forwardRef((props, ref) => {
  const [isHyoumenShori, setIsHyoumenShori] = useState(false);
  const [isShirring, setIsShirring] = useState(false);
  const [isKensa, setIsKensa] = useState(false);
  const [isAdditionalProcess, setIsAdditionalProcess] = useState(false);
  const [isProgram, setIsProgram] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isBending, setIsBending] = useState(false);
  const [isAuto, setIsAuto] = useState(false);
  const [isPlank, setIsPlank] = useState(false);
  const [isSecondProcess, setIsSecondProcess] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const [programValues, setProgrmaValues] = useState([]);
  const [plankValues, setPlankValues] = useState([]);
  const [firstPlankTableVal, setFirstPlankTableVal] = useState([]);
  const [rectangleTbl, setRectangleTbl] = useState([]);

  const [bendTbl, setBendTbl] = useState([]);
  const [firstBendTbl, setFirstBendTbl] = useState([]);

  const [manualTbl, setManualTbl] = useState([]);
  const [autoTbl, setAutoTbl] = useState([]);
  const [hyoumenshoriTbl, setHyoumenshori] = useState([]);
  const [yousetsuTbl, setYousetsuTbl] = useState([]);
  const [secondProcessTbl, setSecondProcessTbl] = useState([]);

  const [editMode, setEditMode] = useState(false);

  const [iq3KouteiInputInfo, setIQ3KouteiInputInfo] = useState([]);
  // カット回数
  const [numOfCuts, setNumOfCuts] = useState('');
  // 検査回数
  const [numOfKensa, setNumOfKensa] = useState('');

  //   プログラム
  const programRef = useRef();
  // 溶接
  const yousetsu = useRef();

  // 組立
  const kumitate = useRef();

  // 表面処理
  const hyoumenshoriRef = useRef();

  const plankRef = useRef();

  const bendRef = useRef();
  const manualRef = useRef();
  const autoRef = useRef();

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    const kouteiInputInfo =
      props.selectedIQ3DataDetail?.iq3KouteiInputData != undefined
        ? JSON.parse(JSON.stringify(props.selectedIQ3DataDetail.iq3KouteiInputData))
        : [];
    setIQ3KouteiInputInfo(kouteiInputInfo);
    console.log(kouteiInputInfo);
    kouteiInputInfo?.forEach((info) => {
      let data = info;
      if (data.kouteiName == 'program') {
        const program = data.kouteiContent;
        setProgrmaValues(program);
      } else if (data.kouteiName == 'plank') {
        const tblPlankData1 = data.kouteiContent;
        console.log('66666', tblPlankData1);
        setPlankValues(tblPlankData1);
      } else if (data.kouteiName == 'rectangle') {
        const rectangleSize1 = data.kouteiContent;
        console.log('Rectangle--', rectangleSize1);
        setRectangleTbl(rectangleSize1);
      } else if (data.kouteiName == 'firstPlank') {
        const plank1 = data.kouteiContent;
        setFirstPlankTableVal(plank1);
      } else if (data.kouteiName == 'bend') {
        const bending1 = data.kouteiContent;
        setBendTbl(bending1);
      } else if (data.kouteiName == 'manual') {
        const manual1 = data.kouteiContent;
        // setManualTbl(manual1);
        // setManualTbl(baritoriManualdata);
        setManualTbl(manual1[0]);
        // setAutoTbl(baritoriAutodata);
        setAutoTbl(manual1[1]);
      } else if (data.kouteiName == 'auto') {
        const auto1 = data.kouteiContent;
        // setAutoTbl(auto1);
      } else if (data.kouteiName == 'firstBending') {
        const firstBending1 = data.kouteiContent;
        setFirstBendTbl(firstBending1);
      } else if (data.kouteiName == 'hyoumenshori') {
        const tblHyoumenshoriData1 = data.kouteiContent;
        setHyoumenshori(tblHyoumenshoriData1);
        // setHyoumenshori(hyoumenShoriData);
      } else if (data.kouteiName == 'yousetsu') {
        const yousetsu1Data1 = data.kouteiContent;
        setYousetsuTbl(yousetsu1Data1);
      } else if (data.kouteiName == 'nijikakou') {
        const niji = data.kouteiContent;
        setSecondProcessTbl(niji);
      } else if (data.kouteiName == 'shirring') {
        const cuts = data.kouteiContent;
        setNumOfCuts(cuts);
      } else if (data.kouteiName == 'kensa') {
        const kensa = data.kouteiContent;
        setNumOfKensa(kensa);
      }
    });
  }, [props.selectedIQ3DataDetail, props.editMode]);

  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };

  const plank = () => {
    if (isPlank == true) {
      setIsPlank(false);
    } else {
      setIsPlank(true);
    }
  };

  const hyoumenShori = () => {
    if (isHyoumenShori === true) {
      setIsHyoumenShori(false);
    } else {
      setIsHyoumenShori(true);
    }
  };
  const shirring = () => {
    if (isShirring === true) {
      setIsShirring(false);
    } else {
      setIsShirring(true);
    }
  };
  const kensa = () => {
    if (isKensa === true) {
      setIsKensa(false);
    } else {
      setIsKensa(true);
    }
  };
  const additionalProcess = () => {
    if (isAdditionalProcess == true) {
      setIsAdditionalProcess(false);
    } else {
      setIsAdditionalProcess(true);
    }
  };

  const program = () => {
    if (isProgram == true) {
      setIsProgram(false);
    } else {
      setIsProgram(true);
    }
  };
  const secondProcess = () => {
    if (isSecondProcess === true) {
      setIsSecondProcess(false);
    } else {
      setIsSecondProcess(true);
    }
  };

  const bending = () => {
    if (isBending == true) {
      setIsBending(false);
    } else {
      setIsBending(true);
    }
  };
  const auto = () => {
    if (isAuto == true) {
      setIsAuto(false);
    } else {
      setIsAuto(true);
    }
  };
  const manual = () => {
    if (isManual == true) {
      setIsManual(false);
    } else {
      setIsManual(true);
    }
  };
  const setEditModeChange = (mode) => {
    // setEditMode(mode);
    manualRef.current.setEditModeChange(mode);
    programRef.current.setEditModeChange(mode);
    yousetsu.current.setEditModeChange(mode);
    // kumitate.current.setEditModeChange(mode);
    hyoumenshoriRef.current.setEditModeChange(mode);
    // kensa.current.setEditModeChange(mode);

    bendRef.current.setEditModeChange(mode);
    autoRef.current.setEditModeChange(mode);
    plankRef.current.setEditModeChange(mode);
  };

  const updateKouteiInput = (kouteiName, kouteiContent) => {
    iq3KouteiInputInfo.forEach((info) => {
      let data = info;
      if (data.kouteiName == kouteiName) {
        data.kouteiContent = kouteiContent;
      }
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiInputInfo: () => {
        return iq3KouteiInputInfo;
      },
    }),
    [iq3KouteiInputInfo]
  );
  const changeDetails = (event, name) => {
    const temp = JSON.parse(JSON.stringify(rectangleTbl));
    if (name === 'materialName' || name === 'quality' || name === 'thick') {
      const updatedData = temp.map((row) => {
        return { ...row, [name]: event };
      });
      setRectangleTbl(updatedData);
    } else {
      const updatedData = temp.map((row) => {
        return { ...row, [name]: event.target.value };
      });
      setRectangleTbl(updatedData);
    }
  };
  return (
    <>
      <Row className="mt-1 registerModal sticky-style">
        <Table className="kouteiinput" style={{ marginLeft: '5px', width: '99%', marginRight: '22px' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr>
              <td colSpan={3} className="tbl-header">
                材料
              </td>
              <td colSpan={4} className="tbl-header">
                矩形サイズ
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="table-light" style={{ textAlign: 'center' }}>
              <td style={{ width: '22%' }}>材料名称</td>
              <td style={{ width: '22%' }}>材質</td>
              <td style={{ width: '11%' }}>板厚</td>
              <td style={{ width: '11%' }}>Xサイズ</td>
              <td style={{ width: '11%' }}>Yサイズ</td>
              <td style={{ width: '11%' }}>重量(kg)</td>
              <td style={{ width: '11%' }}>
                表面積(cm<sup>2</sup>)
              </td>
            </tr>
            {rectangleTbl.map((i) => (
              <>
                <tr>
                  <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                    {editMode ? (
                      <>
                        <Select
                          value={i.materialName}
                          onChange={(e) => changeDetails(e, 'materialName')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                        >
                          {/* <Option>SPCH(黒皮) - 1.6</Option>
                          <Option>SPCH(黒皮) - 3.2</Option> */}
                          {types.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <>{i.materialName}</>
                    )}
                  </td>

                  <td style={{ verticalAlign: 'middle' }}>
                    {editMode ? (
                      <>
                        <Select
                          value={i.quality}
                          onChange={(e) => changeDetails(e, 'quality')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                        >
                          <Option value={i.quality}>{i.quality}</Option>
                        </Select>
                      </>
                    ) : (
                      <>{i.quality}</>
                    )}
                  </td>

                  <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                    {editMode ? (
                      <>
                        <Select
                          value={i.thick}
                          onChange={(e) => changeDetails(e, 'thick')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                        >
                          {/* <Option value={1}>1.6</Option> */}
                          {itaatsuTypes.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <>
                        <text style={{ textAlign: 'center' }}>{i.thick}</text>
                      </>
                    )}
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.xsize}
                      onChange={(e) => changeDetails(e, 'xsize')}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.ysize}
                      onChange={(e) => changeDetails(e, 'ysize')}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className="input-non-editable "
                      value={i.weight}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className="input-non-editable "
                      value={i.area}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                    ></Input>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
      <div className="kouteical timecharge">
        {/* プログラム */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={program}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isProgram ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>プログラム</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isProgram ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Program
            editMode={props.editMode}
            programVal={programValues}
            updateProgram={updateKouteiInput}
          />
        </div>

        {/* ブランク */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={plank}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isPlank ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>ブランク</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isPlank ? 'block' : 'none', margin: '5px' }}>
          {/* <ParentKouteiInput_Hyoumenshori ref={hyoumenshori} /> */}
          <IQ3KouteiInput_Plank
            editMode={props.editMode}
            plankVal={plankValues}
            firstPlankTableVal={firstPlankTableVal}
          />
        </div>

        {/* 2次加工 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={secondProcess}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isSecondProcess ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>2次加工</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isSecondProcess ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_2Jikakou
            secondProcessTblVal={secondProcessTbl}
            editMode={props.editMode}
          ></IQ3KouteiInput_2Jikakou>
        </div>

        {/* ベンディング */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={bending}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isBending ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>ベンディング</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isBending ? 'block' : 'none', margin: '5px' }}>
          {/* <ParentKouteiInput_Kumitate ref={kumitate} /> */}
          <IQ3KouteiInput_Bending editMode={props.editMode} bendData={bendTbl} firstData={firstBendTbl} />
        </div>

        {/* バリ取り */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={manual}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isManual ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>バリ取</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isManual ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Baritori
            detailsData={plankValues}
            editMode={props.editMode}
            manualData={manualTbl}
            autoData={autoTbl}
          />
        </div>

        {/* 自動バリ */}
        {/* <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={auto}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isAuto ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>自動バリ</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isAuto ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Auto editMode={props.editMode} autoData={autoTbl} />
        </div> */}

        {/* 溶接 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isWelding ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>溶接</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isWelding ? 'block' : 'none', margin: '5px' }}>
          {/* <ParentKouteiInput_Yousetsu ref={yousetsu} /> */}
          <IQ3KouteiInput_Yousetsu editMode={props.editMode} yousetsu1Datas={yousetsuTbl} />
        </div>

        {/* 表面処理 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={hyoumenShori}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isHyoumenShori ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>表面処理</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isHyoumenShori ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Hyoumenshori editMode={props.editMode} hyoumenShoriVal={hyoumenshoriTbl} />
        </div>

        {/* シャーリング */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={shirring}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isShirring ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>シャーリング</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isShirring ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Shirring editMode={props.editMode} numOfCuts={numOfCuts} />
        </div>

        {/* 検査 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kensa}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isKensa ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>検査</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isKensa ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Kensa editMode={props.editMode} numOfKensa={numOfKensa} />
        </div>
      </div>
    </>
  );
});

export default IQ3KouteiInput;
