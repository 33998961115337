import React from 'react';
import ReactDOM from 'react-dom/client';
import { Layout } from 'antd';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';

function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <Layout style={{ alignItems: 'center', backgroundColor: 'white', marginTop: '50px' }}>
      <div role="alert" style={{ marginLeft: 20 }}>
        <div style={{}}>
          <h2>予期せぬエラーが発生しました。</h2>
          <br />
          <p> お手数ですが、下記リンクによりログイン画面からログインしてください。</p>
          <a
            onClick={(e) => {
              window.location.replace(process.env.REACT_APP_FRONTEND_URL);
            }}
          >
            <p style={{ color: 'blue', textDecoration: 'underline' }}> {process.env.REACT_APP_FRONTEND_URL}</p>
          </a>
        </div>
      </div>
    </Layout>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> // Reactで初回useEffectが2回呼ばれるため、無効にする。
  <ErrorBoundary fallbackRender={fallbackRender}>
    <App />
  </ErrorBoundary>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
