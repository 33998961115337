import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Radio, Table as AntTable, Modal, Button, ConfigProvider, Space, Checkbox } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';

const selectedColumns = [
  {
    id: '1',
    title: '種別',
    dataIndex: 'childType',
    width: '10%',
  },
  {
    id: '2',
    title: '子部品名',
    dataIndex: 'childName',
    width: '25%',
  },
  {
    id: '3',
    title: '材質',
    dataIndex: 'quality',
    width: '20%',
  },
  {
    id: '4',
    title: 'サイズ',
    dataIndex: 'size',
    width: '20%',
  },
  {
    id: '5',
    title: '員数',
    dataIndex: 'itemNum',
    width: '15%',
  },
];
const childColumns = [
  {
    id: '1',
    title: '図面番号',
    dataIndex: 'imgNm',
    width: '25%',
  },
  {
    id: '2',
    title: '子部品名商',
    dataIndex: 'childName',
    width: '12%',
  },
  {
    id: '3',
    title: '員数',
    dataIndex: 'itemNum',
    width: '8%',
  },

  {
    id: '4',
    title: '材質',
    dataIndex: 'quality',
    width: '10%',
  },
  {
    id: '5',
    title: '板厚',
    dataIndex: 'thick',
    width: '10%',
  },

  {
    id: '6',
    title: '材料名称',
    dataIndex: 'materialname',
    width: '14%',
  },
  {
    id: '7',
    title: '詳細',
    dataIndex: 'details',
    width: '12%',
  },
  {
    id: '8',
    title: 'サイズ',
    dataIndex: 'size',
    width: '12%',
  },
  {
    id: '9',
    title: '面積',
    dataIndex: 'area',
    width: '10%',
  },
  {
    id: '10',
    title: '重量(kg)',
    dataIndex: 'weight',
    width: '10%',
  },
  {
    id: '11',
    title: '選択済',
    dataIndex: 'status',
    width: '8%',
  },
];
const commonColumns = [
  {
    id: '1',
    title: '種別',
    dataIndex: 'childType',
    width: '25%',
  },
  {
    id: '2',
    title: '図面番号',
    dataIndex: 'imgNm',
    width: '25%',
  },
  {
    id: '3',
    title: '子部品名商',
    dataIndex: 'childName',
    width: '12%',
  },
  {
    id: '4',
    title: '員数',
    dataIndex: 'itemNum',
    width: '8%',
  },

  {
    id: '5',
    title: '材質',
    dataIndex: 'quality',
    width: '10%',
  },
  {
    id: '6',
    title: '板厚',
    dataIndex: 'thick',
    width: '10%',
  },

  {
    id: '7',
    title: '材料名称',
    dataIndex: 'materialname',
    width: '14%',
  },
  {
    id: '8',
    title: '詳細',
    dataIndex: 'details',
    width: '12%',
  },
  {
    id: '9',
    title: 'サイズ',
    dataIndex: 'size',
    width: '12%',
  },
  {
    id: '10',
    title: '面積',
    dataIndex: 'area',
    width: '10%',
  },
  {
    id: '12',
    title: '重量(kg)',
    dataIndex: 'weight',
    width: '10%',
  },
  {
    id: '13',
    title: '選択済',
    dataIndex: 'status',
    width: '8%',
  },
];
const iq3Cols = [1, 2, 3, 4, 5, 8, 9, 10, 11];
const iq5Cols = [1, 2, 3, 6, 4, 7, 8, 11];
const iQ3Columns = childColumns.filter((item) => {
  return (
    item.id ==
    iq3Cols.filter((i) => {
      if (i == item.id) {
        return i;
      }
    })
  );
});

const iQ5Columns = childColumns.filter((item) => {
  return (
    item.id ==
    iq5Cols.filter((i) => {
      if (i == item.id) {
        return i;
      }
    })
  );
});

const ParentKouteiInput_ChildPartInfoSetting = forwardRef((props, ref) => {
  const [tbliQ3, setTbliQ3] = useState(
    localStorage.getItem('iQ3PartInfos') != undefined ? JSON.parse(localStorage.getItem('iQ3PartInfos')) : []
  );
  const [tbliQ5, setTbliQ5] = useState(
    localStorage.getItem('iQ5PartInfos') != undefined ? JSON.parse(localStorage.getItem('iQ5PartInfos')) : []
  );
  const [tbliQ7, setTbliQ7] = useState(
    localStorage.getItem('iQ7PartInfos') != undefined ? JSON.parse(localStorage.getItem('iQ7PartInfos')) : []
  );
  const [tblSelectedChildPart, setTblSelectedChildPart] = useState([]);
  const [version, setVersion] = useState('iQ3');
  const [addModal, setAddModal] = useState(true);
  const [selectedData, setSelectedData] = useState(0);
  const [selectedChildPartData, setSelectedChildPartData] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedChildPartRowKeys, setSelectedChildPartRowKeys] = useState([]);
  const [customize, setCustomize] = useState(true);
  const [shoriNm, setShoriNm] = useState('');

  const [allData, setAllData] = useState([...tbliQ3, ...tbliQ5]);
  const [rightTblDataBk, setRightTblDataBk] = useState([...tbliQ3, ...tbliQ5]);

  let checkedRow = [];

  useEffect(() => {
    setShoriNm(props.shoriNm);
    let childParts = props.curData.childParts;
    if (childParts.length == 0) {
      setTblSelectedChildPart([]);
    } else {
      setTblSelectedChildPart(childParts);
      removeSelectedRecordFromChildPartInfo(childParts);
    }
  }, []);
  const iq3Columns = [
    {
      id: '1',
      title: '種別',
      dataIndex: 'no',
      width: 40 + 'px',
      align: 'center',
    },
    {
      id: '2',
      title: '採用',
      dataIndex: 'adopt',
      width: 60 + 'px',
      align: 'center',
    },
    {
      id: '3',
      title: 'イメージ',
      dataIndex: 'image',
      width: 140 + 'px',
      align: 'center',
    },
    {
      id: '4',
      title: ['工程パターン', <br />, '図番番号', <br />, '名称'],
      dataIndex: 'processItems',
      width: 220 + 'px',
      align: 'center',
      render: (_, record) =>
        tbliQ3.length >= 1 ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px' }}>{record.processNm}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>{record.imgNo}</label>
            {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 2 }} value={record.imgNo} /> */}
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>{record.partNm}</label>
            {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 3 }} value={record.partNm} /> */}
            <br />
          </div>
        ) : null,
    },
    {
      id: '5',
      title: '',
      title: ['鉄材方法', <br />, '員数'],
      dataIndex: 'partCnt',
      width: 60 + 'px',
      align: 'center',
    },
    {
      id: '6',
      title: ['材料', <br />, '材質', <br />, '板厚'],
      dataIndex: 'materialItems',
      width: 150 + 'px',
      align: 'center',
      render: (_, record) =>
        tbliQ3.length >= 1 ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px' }}>{record.material}</label>
            <br />
            {/* <label style={{ fontSize: '13px', marginTop: 2 }}>{materialName[0]}</label> */}

            <br />
            {/* <label style={{ fontSize: '13px', marginTop: 2 }}>{thick[0]}</label> */}
          </div>
        ) : null,
    },
    {
      id: '7',
      title: ['材料費', <br />, '加工費', <br />, '購入品費'],
      dataIndex: 'costItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) =>
        tbliQ3.length >= 1 ? (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>{'¥' + record.materialCost}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>{'¥' + record.processCost}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>{'¥' + record.purchaseCost}</label>
          </div>
        ) : null,
    },
    {
      id: '8',
      title: ['重量', <br />, '表面籍'],
      dataIndex: 'amountItems',
      width: 100 + 'px',
      align: 'center',
      render: (_, record) =>
        tbliQ3.length >= 1 ? (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>{record.weight + 'kg'}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>
              {record.area}
              cm<sup>2</sup>
            </label>
          </div>
        ) : null,
    },

    {
      id: '9',
      title: '利用中',
      dataIndex: 'estimateItems',
      width: 110 + 'px',
      align: 'center',
    },
  ];

  const removeSelectedRecordFromChildPartInfo = (childParts) => {
    let childSelectedKeys = [];
    childParts.map((item, index) => {
      childSelectedKeys.push(item.childKey + item.childType);
    });
    setTbliQ3(
      tbliQ3.filter((j) => {
        return !childSelectedKeys.includes(j.key + j.childType);
      })
    );
    setTbliQ5(
      tbliQ5.filter((j) => {
        return !childSelectedKeys.includes(j.key + j.childType);
      })
    );
    setTbliQ7(
      tbliQ7.filter((j) => {
        return !childSelectedKeys.includes(j.key + j.childType);
      })
    );
    setAllData(
      allData.filter((j) => {
        return !childSelectedKeys.includes(j.key + j.childType);
      })
    );
    setRightTblDataBk(
      rightTblDataBk.filter((j) => {
        return !childSelectedKeys.includes(j.key + j.childType);
      })
    );
  };

  const commonAction = (e, name) => {
    let three = document.getElementById('iq3').checked;
    let five = document.getElementById('iq5').checked;
    let tt = rightTblDataBk;
    let filterData;

    if (e.target.checked === true && name == 'iq3' && five == true) {
      filterData = tt;
    } else if (e.target.checked === true && name == 'iq5' && three == true) {
      filterData = tt;
      // filterData = tt.filter((i) => i.childType != 'iQ5');
    } else if (e.target.checked === true && name == 'iq5' && three == false) {
      // filterData = tt;
      filterData = tt.filter((i) => i.childType != 'iQ3');
    } else if (e.target.checked === false && name == 'iq5' && three == true) {
      // filterData = tt;
      filterData = tt.filter((i) => i.childType != 'iQ5');
    } else if (e.target.checked === false && name == 'iq3' && five == true) {
      // filterData = tt;
      filterData = tt.filter((i) => i.childType != 'iQ3');
    } else if (e.target.checked === true && name == 'iq3' && five == false) {
      // filterData = tt;
      filterData = tt.filter((i) => i.childType != 'iQ5');
    } else if (e.target.checked === false && three == false && five == false) {
      // filterData = tt;
      filterData = [];
    }
    setAllData(filterData);
  };

  const addSelectedRecordToChildPartInfo = (childParts) => {
    // childParts.map((item) => {
    //   if (item.childType === 'iQ3') {
    //     let selectedRow = {
    //       key: item.childKey,
    //       parentName: item.parentName,
    //       childType: item.childType,
    //       imgNm: item.imgNm,
    //       childName: item.childName,
    //       itemNum: item.itemNum,
    //       quality: 'SPCC',
    //       thick: item.thick,
    //       area: item.area,
    //       weight: item.weight,
    //       size: item.size,
    //       details: item.details,
    //     };
    //     let addTblData = [...tbliQ3, selectedRow];
    //     setTbliQ3(addTblData);

    //   } else if (item.childType === 'iQ5') {
    //     let selectedRow = {
    //       key: item.childKey,
    //       parentName: item.parentName,
    //       childType: item.childType,
    //       imgNm: item.imgNm,
    //       childName: item.childName,
    //       itemNum: item.itemNum,
    //       quality: 'SPCC',
    //       thick: item.thick,
    //       area: item.area,
    //       weight: item.weight,
    //       size: item.size,
    //       details: item.details,
    //     };
    //     let addTblData = [...tbliQ5, selectedRow];
    //     setTbliQ5(addTblData);
    //   } else if (item.childType === 'iQ7') {
    //     let selectedRow = {
    //       key: item.childKey,
    //       parentName: item.parentName,
    //       childType: item.childType,
    //       imgNm: item.imgNm,
    //       childName: item.childName,
    //       itemNum: item.itemNum,
    //       quality: 'SPCC',
    //       thick: item.thick,
    //       area: item.area,
    //       weight: item.weight,
    //       size: item.size,
    //       details: item.details,
    //     };
    //     let addTblData = [...tbliQ7, selectedRow];
    //     setTbliQ7(addTblData);
    //   }
    // });
    // re-move data to right tbl-0421 cta
    let selectedRow = [];
    childParts.map((item) => {
      selectedRow.push({
        key: item.childKey,
        parentName: item.parentName,
        childType: item.childType,
        imgNm: item.imgNm,
        childName: item.childName,
        itemNum: item.itemNum,
        quality: 'SPCC',
        thick: item.thick,
        area: item.area,
        weight: item.weight,
        size: item.size,
        details: item.details,
      });
      let addTblData = [...allData, ...selectedRow];
      setAllData(addTblData);
      setRightTblDataBk(addTblData);
    });
  };

  const onRdoChange = (e) => {
    setVersion(e.target.value);
  };
  const renderTbl = () => {
    if (version === 'iQ3') {
      return <>{iQ3Tbl}</>;
    } else if (version === 'iQ5') {
      return <>{iQ5Tbl}</>;
    } else {
      return <>{iQ7Tbl}</>;
    }
  };
  const onRowCheckboxSelect = (checked, record) => {
    setSelectedRowKeys([...checked]);
    let rowSelectedData = [];
    for (let i = 0; i < record.length; i++) {
      rowSelectedData.push({
        // key: tblSelectedChildPart.length + 1,
        key: record[i].key,
        childKey: record[i].key,
        parentName: record[i].parentName,
        childType: record[i].childType,
        imgNm: record[i].imgNm,
        childName: record[i].childName,
        itemNum: record[i].itemNum,
        quality: record[i].quality,
        thick: record[i].thick,
        area: record[i].area,
        weight: record[i].weight,
        size: record[i].size,
        details: record[i].details,
      });
    }
    setSelectedData(rowSelectedData);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onRowCheckboxSelect,
  };
  const selectedChildPartRowChange = (checked, record) => {
    setSelectedChildPartRowKeys([...checked]);
    let rowSelectedData = [];
    for (let i = 0; i < record.length; i++) {
      rowSelectedData.push({
        // key: tblSelectedChildPart.length + 1,
        key: record[i].childKey,
        childKey: record[i].childKey,
        parentName: record[i].parentName,
        childType: record[i].childType,
        imgNm: record[i].imgNm,
        childName: record[i].childName,
        itemNum: record[i].itemNum,
        quality: record[i].quality,
        thick: record[i].thick,
        area: record[i].area,
        weight: record[i].weight,
        size: record[i].size,
        details: record[i].details,
      });
    }
    setSelectedChildPartData(rowSelectedData);
  };
  const selectedChildPartRowSelection = {
    selectedChildPartRowKeys,
    onChange: selectedChildPartRowChange,
  };
  const selectedChildTbl = (
    <>
      <AntTable
        className="childPartLstTbl"
        bordered
        rowSelection={selectedChildPartRowSelection}
        columns={selectedColumns}
        rowKey={(record) => record.key + record.childType}
        dataSource={tblSelectedChildPart}
        scroll={{ y: 165, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const iQ3Tbl = (
    <>
      <AntTable
        className="childPartLstTbl"
        bordered
        rowSelection={rowSelection}
        columns={iQ3Columns}
        // columns={iq3Columns}
        rowKey={(record) => record.key + record.childType}
        dataSource={tbliQ3}
        scroll={{ y: 165, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const iQ5Tbl = (
    <>
      <AntTable
        className="childPartLstTbl"
        bordered
        rowSelection={rowSelection}
        columns={iQ5Columns}
        rowKey={(record) => record.key + record.childType}
        dataSource={tbliQ5}
        scroll={{ y: 165, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const iQ7Tbl = (
    <>
      <AntTable
        className="childPartLstTbl"
        bordered
        rowSelection={rowSelection}
        columns={childColumns}
        rowKey={(record) => record.key + record.childType}
        dataSource={tbliQ7}
        scroll={{ y: 165, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const allTblData = (
    <>
      <AntTable
        className="childPartLstTbl"
        bordered
        rowSelection={rowSelection}
        columns={commonColumns}
        // columns={iq3Columns}
        rowKey={(record) => record.key + record.childType}
        dataSource={allData}
        scroll={{ y: 165, x: '10vw' }}
        pagination={false}
      />
    </>
  );

  const updateChildPart = () => {
    props.onUpdate(tblSelectedChildPart);
    setAddModal(false);
  };

  const cancelChildPart = () => {
    props.onCancel();
    setAddModal(false);
  };

  const moveData = () => {
    if (!props.editMode) return;
    if (selectedRowKeys.length > 0) {
      // 移動したレコードを選択子部品一覧に追加。
      let selectedRowData = [...tblSelectedChildPart, ...selectedData];
      setTblSelectedChildPart(selectedRowData);

      // 左テーブルに移動したレコードを右テーブルから削除。
      removeSelectedRecordFromChildPartInfo(selectedRowData);
      setSelectedRowKeys([]);
      setSelectedData([]);
    }
  };
  const deleteData = () => {
    if (!props.editMode) return;
    if (selectedChildPartRowKeys.length > 0) {
      // 移動したレコードを該当する子部品一覧に戻す。
      addSelectedRecordToChildPartInfo(selectedChildPartData);
      // 移動したレコードを選択子部品一覧から削除。
      setTblSelectedChildPart(
        tblSelectedChildPart.filter((j) => {
          return !selectedChildPartRowKeys.includes(j.key + j.childType);
        })
      );

      setSelectedChildPartRowKeys([]);
      setSelectedChildPartData([]);
    }
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', height: 120 }}>
      <p></p>
    </div>
  );

  return (
    <>
      {/* 子部品情報追加詳細 */}
      <Modal
        maskClosable={false}
        open={addModal}
        width={1600}
        bodyStyle={{ height: '50%' }}
        onOk={updateChildPart}
        onCancel={cancelChildPart}
        footer={null}
        className="kouteimodal"
      >
        <Row className="mt-1">
          <Col span={10}>{shoriNm}</Col>
          <Col span={11} offset={1}>
            <Row>
              <Col span={3}>
                <Checkbox id="iq3" onChange={(e) => commonAction(e, 'iq3')} defaultChecked>
                  iQ3板金
                </Checkbox>
              </Col>{' '}
              <Col span={3}>
                <Checkbox id="iq5" onChange={(e) => commonAction(e, 'iq5')} defaultChecked>
                  iQ5型鋼
                </Checkbox>
              </Col>
            </Row>
            {/* <Radio.Group value={version} onChange={onRdoChange} optionType="button">
              <Radio.Button value="iQ3" style={{ textAlign: 'center' }}>
                <p style={{ marginLeft: 40, marginRight: 40 }}>iQ3板金</p>
              </Radio.Button>

              <Radio.Button value="iQ5" style={{ textAlign: 'center' }}>
                <p style={{ marginLeft: 40, marginRight: 40 }}> iQ5型鋼</p>
              </Radio.Button>

              <Radio.Button value="iQ7" style={{ textAlign: 'center' }}>
                <p style={{ marginLeft: 40, marginRight: 40 }}>iQ7機械</p>
              </Radio.Button>
            </Radio.Group> */}
          </Col>
        </Row>
        <Row align="middle" className="mt-2 ">
          <Col span={10}>
            <div>
              <ConfigProvider renderEmpty={customize ? customizeRenderEmpty : undefined}>
                {selectedChildTbl}
              </ConfigProvider>
            </div>
          </Col>
          <Col span={1} align="center">
            <div style={{ marginTop: '-250px' }}>
              <div>
                <CaretRightOutlined style={{ fontSize: 40, color: '#4a4747' }} onClick={deleteData} />
              </div>
              <div style={{ marginTop: '15px' }}>
                <CaretLeftOutlined style={{ fontSize: 40, color: '#4a4747' }} onClick={moveData} />
              </div>
            </div>
          </Col>
          <Col span={13}>
            {/* {iQ3Tbl} */}
            {/* <div>{renderTbl()}</div> */}
            <div>{allTblData}</div>
          </Col>
        </Row>
        <Row justify="end" className="mt-2">
          <Space>
            <Button
              onClick={updateChildPart}
              className="mainButton"
              id="editok"
              style={{ display: props.editMode ? 'block' : 'none' }}
            >
              更新
            </Button>

            <Button
              onClick={cancelChildPart}
              className="cancelButton"
              id="cancelok"
              style={{ display: props.editMode ? 'block' : 'none' }}
            >
              破棄
            </Button>
          </Space>
        </Row>
      </Modal>
    </>
  );
});

export default ParentKouteiInput_ChildPartInfoSetting;
