import React, { Component, useEffect, useRef, useState } from 'react';
import { Button, Input, Form, Modal, Select, DatePicker } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { WithRouter } from '../../components/WithRouter';
import { CloseOutlined } from '@ant-design/icons';
import { JPYs } from '../common/Common.js';
import dayjs from 'dayjs';
import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
} from '../common/CommonModal';
import { getEstimateStatusData } from '../common/CommonAPI';

// const orderOptions = [
//   { id: 1, value: '作成中', label: '作成中' },
//   { id: 2, value: '再見積指示', label: '再見積指示' },
//   { id: 3, value: '承認待', label: '承認待' },
//   { id: 4, value: '承認済', label: '承認済' },
//   { id: 5, value: '未回答', label: '未回答' },
//   { id: 6, value: '受注', label: '受注' },
//   { id: 7, value: '受注値引き', label: '受注値引き' },
//   { id: 8, value: '非受注', label: '承認済' },
//   { id: 9, value: '保留', label: '保留' },
//   { id: 10, value: '無効', label: '無効' },
// ];
const ParentListDetail = (props) => {
  const location = useLocation();
  const [drawingNo, setDrawingNo] = useState('');
  const [name, setName] = useState('');
  const [lotNumber, setLotNumber] = useState('');
  const [isOrder, setIsOrder] = useState('');
  const [customer, setCustomer] = useState('');
  const [estimateNum, setEstimateNum] = useState('');

  // new
  const [grossProfit, setGrossProfit] = useState('');
  const [rate, setRate] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState('');
  const [sizeX, setSizeX] = useState('');
  const [sizeY, setSizeY] = useState('');
  const [sizeZ, setSizeZ] = useState('');
  const [hiyou1, setHiyou1] = useState('');
  const [hiyou2, setHiyou2] = useState('');
  const [hiyou3, setHiyou3] = useState('');

  const [oldDrawingNo, setOldDrawingNo] = useState('');
  const [oldName, setOldName] = useState('');
  const [oldLotNum, setOldLotNum] = useState('');
  const [oldOrder, setOldOrder] = useState('');
  const [oldGrossProfit, setOldGrossProfit] = useState('');
  const [oldEstimateAmount, setOldEstimateAmount] = useState('');
  const [oldSizeX, setoldSizeX] = useState('');
  const [oldSizeY, setoldSizeY] = useState('');
  const [oldSizeZ, setoldSizeZ] = useState('');
  const [oldRate, setOldRate] = useState('');
  const [oldHiyou1, setoldHiyou1] = useState('');
  const [oldHiyou2, setoldHiyou2] = useState('');
  const [oldHiyou3, setoldHiyou3] = useState('');
  const [oldEstimateNum, setOldEstimateNum] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const ref = useRef(null);
  const [hiyouLabels, setHiyouLabels] = useState([]);
  const [memos, setMemos] = useState([]);
  const [labels, setLabels] = useState([]);
  const [dates, setDates] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  let token = localStorage?.getItem('iQxWeb_AccessToken');

  const paramCostLst = JSON.parse(localStorage.getItem('paramCommonCost')); // 追加項目の費用マスタ情報
  const paramLabelLst = JSON.parse(localStorage.getItem('paramCommonLabel')); // 追加項目のラベルマスタ情報
  const paramMemoLst = JSON.parse(localStorage.getItem('paramCommonMemo')); // 追加項目のメモマスタ情報
  const paramDateLst = JSON.parse(localStorage.getItem('paramCommonDate')); // 追加項目の日付マスタ情報

  useEffect(() => {
    let parentDetails = props.selectedData;
    setEditMode(props.editMode != undefined ? props.editMode : false);

    let grossProfit =
      parentDetails.grossProfit == undefined
        ? 0
        : parentDetails.grossProfit.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          });
    let estimateAmount =
      parentDetails.estimatedAmount == undefined
        ? JPYs.format(0)
        : parentDetails.estimatedAmount.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          });
    let rate =
      parentDetails.rate == undefined
        ? '0%'
        : parentDetails.rate.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          }) + '%';
    setDetailsData({ ...parentDetails });
    setDrawingNo(parentDetails.imgNo);
    setName(parentDetails.name);
    setLotNumber(parentDetails.lotNum);
    setIsOrder(parentDetails.allowGaichu);
    setCustomer(parentDetails.customerNm);
    setEstimateNum(parentDetails.estimateNo);
    setGrossProfit(grossProfit);
    // setEstimatedAmount(JPYs.format(parentDetails.estimatedAmount));
    setEstimatedAmount(estimateAmount);
    setSizeX(parentDetails.sizeX);
    setSizeY(parentDetails.sizeY);
    setSizeZ(parentDetails.sizeZ);
    setRate(rate);
    setHiyouLabels(parentDetails.costLst);
    setMemos(parentDetails.memoLst);
    setLabels(parentDetails.labelLst);
    setDates(parentDetails.dateLst);
    getProjectStatusData();
  }, [props.selectedData, props.editMode]);

  const getProjectStatusData = async () => {
    // 案件状態データ
    let projectStatusData = await getEstimateStatusData(token);
    projectStatusData = projectStatusData.filter((item) => item.info.isUsed == true);
    setOrderOptions(projectStatusData);
  };

  const editData = () => {
    setEditMode(true);
    setOldDrawingNo(document.getElementById('imgNo').value);
    setOldName(document.getElementById('name').value);
    setOldLotNum(document.getElementById('lotNum').value);
    setOldOrder(document.getElementById('allowGaichu').value);
    setOldGrossProfit(document.getElementById('grossProfit').value);
    setOldEstimateAmount(document.getElementById('estimatedAmount').value);
    setoldSizeX(document.getElementById('sizeX').value);
    setoldSizeY(document.getElementById('sizeY').value);
    setoldSizeZ(document.getElementById('sizeZ').value);
    setOldRate(document.getElementById('rate').value);
    props.updateEditMode(true);
  };
  const updateData = () => {
    setUpdateConfirm(true);
  };
  const discardData = () => {
    setCancelConfirm(true);
  };

  const updConfirmOk = (e) => {
    setEditMode(false);

    setUpdateConfirm(false);
    props.onUpdateData(detailsData);
    props.updateEditMode(false);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    setCancelConfirm(false);
    setEditMode(false);
    props.updateEditMode(false);
    setDrawingNo(oldDrawingNo);
    setName(oldName);
    setLotNumber(oldLotNum);
    setGrossProfit(oldGrossProfit);
    setRate(oldRate);
    setEstimatedAmount(oldEstimateAmount);
    setSizeX(oldSizeX);
    setSizeY(oldSizeY);
    setSizeZ(oldSizeZ);
    setIsOrder(oldOrder);
    detailsData['imgNo'] = oldDrawingNo;
    detailsData['name'] = oldName;
    detailsData['lotNum'] = oldLotNum;
    detailsData['grossProfit'] = oldGrossProfit;
    detailsData['rate'] = oldRate;
    detailsData['estimatedAmount'] = oldEstimateAmount;
    detailsData['sizeX'] = oldSizeX;
    detailsData['sizeY'] = oldSizeY;
    detailsData['sizeZ'] = oldSizeZ;
    detailsData['allowGaichu'] = oldOrder;
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
    props.updateEditMode(true);
  };

  const changeValue = (i, event, name) => {
    if (name == 'cost') {
      const val = JSON.parse(JSON.stringify(hiyouLabels));
      val[i - 1]['data'] = event.target.value;

      setHiyouLabels(val);
      detailsData['costLst'] = val;
    } else if (name == 'label') {
      const val = JSON.parse(JSON.stringify(labels));
      val[i - 1]['data'] = event.target.value;

      setLabels(val);
      detailsData['labelLst'] = val;
    } else if (name == 'memo') {
      const val = JSON.parse(JSON.stringify(memos));
      val[i - 1]['data'] = event.target.value;

      setMemos(val);
      detailsData['memoLst'] = val;
    }
  };
  const changeDate = (date, dateString, key) => {
    if (dateString !== '') {
      const val = JSON.parse(JSON.stringify(dates));
      val[key - 1]['data'] = dateString;
      setDates(val);
      detailsData['dateLst'] = val;
    }
  };

  // お客様コードでお客様名称を取得
  function getNameById(id, Lists) {
    let retValue = '';
    if (id) {
      let info = Lists.filter((info) => info.code === id);
      if (info.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  return (
    <>
      <Form style={{ overflowY: 'hidden' }}>
        <div className="d-flex" style={{ marginTop: 7 }}>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="mainButton"
              id="update"
              onClick={updateData}
            >
              更新
            </Button>
          </div>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="cancelButton"
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button
              style={{
                marginRight: 10,
                display: 'none',
                display: editMode ? 'none' : 'block',
              }}
              className="mainButton"
              id="edit"
              onClick={editData}
            >
              編集
            </Button>
          </div>
        </div>
        <div className="overflow-auto" style={{ height: 760, marginTop: 10 }}>
          <Table className="propertiesTbl">
            <thead>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">お客様</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className="input-non-editable "
                    ref={ref}
                    name="customerNm"
                    id="customerNm"
                    value={customer}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">図番</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    ref={ref}
                    name="imgNo"
                    id="imgNo"
                    value={drawingNo}
                    type="text"
                    onChange={(e) => {
                      setDrawingNo(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">名称</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">数量</label>
                </td>

                <td style={{ verticalAlign: 'middle', width: '13.3%' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-non-editable "
                    ref={ref}
                    name="lotNum"
                    id="lotNum"
                    value={lotNumber}
                    type="text"
                    onChange={(e) => {
                      setLotNumber(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                  />
                </td>

                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">粗利益</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-non-editable "
                    ref={ref}
                    name="grossProfit"
                    id="grossProfit"
                    value={grossProfit}
                    type="text"
                    onChange={(e) => {
                      setGrossProfit(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                    prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                  />
                </td>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">粗利率</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '90%', textAlign: 'left' }}
                    // className={editMode ? 'input-editable' : 'input-non-editable '}
                    className="input-non-editable "
                    ref={ref}
                    name="rate"
                    id="rate"
                    value={rate}
                    type="text"
                    onChange={(e) => {
                      setRate(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                    // suffix={<label style={{ fontSize: '13px', textAlign: 'left' }}>%</label>}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">受注非受注</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={isOrder}
                      name="allowGaichu"
                      id="allowGaichu"
                      onChange={(e) => {
                        setIsOrder(e);
                        detailsData['allowGaichu'] = e;
                      }}
                      style={{ marginLeft: 5, width: '40.8%' }}
                    >
                      {orderOptions.map((option) => (
                        <option value={option.code}>{option.name}</option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      // className="input-non-editable "
                      className={'input-non-editable '}
                      ref={ref}
                      name="allowGaichu"
                      id="allowGaichu"
                      value={getNameById(isOrder, orderOptions)}
                      type="text"
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">見積金額</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-non-editable "
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    name="estimatedAmount"
                    id="estimatedAmount"
                    value={estimatedAmount}
                    onChange={(e) => {
                      setEstimatedAmount(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                    prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">サイズX</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    name="sizeX"
                    id="sizeX"
                    value={sizeX}
                    onChange={(e) => {
                      setSizeX(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                  ></Input>
                </td>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">サイズY</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    name="sizeY"
                    id="sizeY"
                    value={sizeY}
                    onChange={(e) => {
                      setSizeY(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                  ></Input>
                </td>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">サイズZ</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    name="sizeZ"
                    id="sizeZ"
                    value={sizeZ}
                    onChange={(e) => {
                      setSizeZ(e.target.value);
                      detailsData[e.target.name] = e.target.value;
                    }}
                  ></Input>
                </td>
              </tr>

              {/* 費用 */}
              {paramCostLst != undefined && hiyouLabels != undefined
                ? paramCostLst.map((item) => {
                    let { key, name } = item;
                    let data = '';
                    // 追加項目の費用マスタに存在する場合、表示
                    let record = hiyouLabels?.filter((record) => record.key === key);
                    if (record?.length > 0) {
                      data = record[0].data;
                    } else {
                      // 追加項目の費用マスタに存在しない場合、非表示
                      data = '';
                    }
                    return (
                      <>
                        <tr>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{name}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                            <Input
                              style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable '}
                              name={key}
                              id={key}
                              value={data}
                              onChange={(event) => changeValue(key, event, 'cost')}
                              prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}

              {/* 日付 */}
              {paramDateLst != undefined && dates != undefined
                ? paramDateLst.map((item) => {
                    let { key, name } = item;
                    let title = name;
                    let data = '';
                    // 追加項目の日付マスタに存在する場合、表示
                    let record = dates?.filter((record) => record.key === key);
                    if (record?.length > 0) {
                      data = record[0].data;
                    } else {
                      // 追加項目の日付マスタに存在しない場合、非表示
                      data = '';
                    }

                    return (
                      <>
                        <tr>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{title}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle', width: '33.3%' }}>
                            {editMode ? (
                              <DatePicker
                                name={key}
                                id={key}
                                style={{ padding: 5, marginLeft: 5, width: '40.8%' }}
                                className={editMode ? 'input-editable' : 'input-non-editable'}
                                value={dayjs(data, 'YYYY-MM-DD')}
                                onChange={(date, dateString) => changeDate(date, dateString, key)}
                              />
                            ) : (
                              <label style={{ fontSize: '13px', paddingLeft: 10 }}>{data}</label>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}

              {/* ラベル */}
              {paramLabelLst != undefined && labels != undefined
                ? paramLabelLst.map((item) => {
                    let { key, name } = item;
                    let data = '';
                    let title = name;
                    // 追加項目のラベルマスタに存在する場合、表示
                    let record = labels?.filter((record) => record.key === key);
                    if (record?.length > 0) {
                      data = record[0].data;
                    } else {
                      // 追加項目のラベルマスタに存在しない場合、非表示
                      data = '';
                    }
                    return (
                      <>
                        <tr>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{title}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                            <Input
                              style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable '}
                              name={'label' + key}
                              id={key}
                              value={data}
                              onChange={(event) => changeValue(key, event, 'label')}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}

              {/* メモ */}
              {paramMemoLst != undefined && memos != undefined
                ? paramMemoLst.map((item) => {
                    let { key, name } = item;
                    let title = name;
                    let data = '';
                    // 追加項目のメモマスタに存在する場合、表示
                    let record = memos?.filter((record) => record.key === key);
                    if (record?.length > 0) {
                      data = record[0].data;
                    } else {
                      // 追加項目のメモマスタに存在しない場合、非表示
                      data = '';
                    }
                    return (
                      <>
                        <tr>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{title}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                            <Input
                              style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable '}
                              name={'memo' + key}
                              id={key}
                              value={data}
                              onChange={(event) => changeValue(key, event, 'memo')}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}
            </thead>
          </Table>

          {/** 更新の確認メッセージ */}
          {updateConfirm
            ? commonModal(
                updateConfirm,
                confirmModalTitle,
                commonFooter(updConfirmOk, updConfirmCancel),
                null,
                400,
                updConfirmOk,
                updConfirmCancel,
                confirmUpdateContent,
                null,
                null,
                false
              )
            : ''}
          {/** 破棄の確認メッセージ */}
          {cancelConfirm
            ? commonModal(
                cancelConfirm,
                confirmModalTitle,
                commonFooter(discardConfirmOk, discardConfirmCancel),
                null,
                400,
                discardConfirmOk,
                discardConfirmCancel,
                confirmDiscardContent,
                null,
                null,
                false
              )
            : ''}
        </div>
      </Form>
    </>
  );
};

export default WithRouter(ParentListDetail);
