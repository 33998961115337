import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const methods = [
  { id: 1, value: '無し', label: '無し' },
  { id: 2, value: '脱脂', label: '脱脂' },
  { id: 3, value: 'メッキ', label: 'メッキ' },
  { id: 4, value: '片面塗装', label: '片面塗装' },
  { id: 5, value: '両面塗装', label: '両面塗装' },
];
const calculationMethods = [
  { id: 1, value: '面積', label: '面積' },
  { id: 2, value: '重量', label: '重量' },
];
const mekki = [
  { id: 1, value: 'ユニクロメッキ', label: 'ユニクロメッキ' },
  { id: 2, value: 'クロメート', label: 'クロメート' },
  { id: 3, value: 'クロームメッキ', label: 'クロームメッキ' },
  { id: 4, value: '亜鉛メッキ', label: '亜鉛メッキ' },
  { id: 5, value: 'メッキ01', label: 'メッキ01' },
  { id: 6, value: 'メッキ02', label: 'メッキ02' },
  { id: 7, value: 'メッキ03', label: 'メッキ03' },
  { id: 8, value: 'メッキ04', label: 'メッキ04' },
  { id: 9, value: 'メッキ05', label: 'メッキ05' },
];
const ryoumen = [
  { id: 1, value: 'メラミン塗装', label: 'メラミン塗装' },
  { id: 2, value: 'アクリル塗装', label: 'アクリル塗装' },
  { id: 3, value: 'ウレタン塗装', label: 'ウレタン塗装' },
  { id: 4, value: '粉体塗装', label: '粉体塗装' },
  { id: 5, value: 'カチオン塗装', label: 'カチオン塗装' },
  { id: 6, value: 'プライマー', label: 'プライマー' },
  { id: 7, value: '塗装01', label: '塗装01' },
  { id: 8, value: '塗装02', label: '塗装02' },
  { id: 9, value: '塗装03', label: '塗装03' },
  { id: 10, value: '塗装04', label: '塗装04' },
];
const IQ3KouteiInput_Hyoumenshori = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  const [hyoumenshoriValues, setHyoumenshoriValues] = useState([]);

  // 編集モード
  const editMode = props.editMode;
  useEffect(() => {
    const hyoumenshoriVal = props.hyoumenShoriVal;
    setHyoumenshoriValues(hyoumenshoriVal);
    console.log(props.hyoumenShoriVal);
  }, [props.hyoumenShoriVal]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const addHyoumenshori = (id) => {
    const copyData = hyoumenshoriValues.slice(); // Create a copy of the original array

    const insertIndex = hyoumenshoriValues.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = hyoumenshoriValues.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        methods: '面積',
        types: '脱脂',
        details: '',
        area: '',
        autoExtract: '',
        count: '',
        total: '',
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setHyoumenshoriValues(copyData);
    }
  };
  const deleteHyoumenshori = (id) => {
    if (hyoumenshoriValues.length > 1) {
      setHyoumenshoriValues(
        hyoumenshoriValues.filter((j) => {
          return ![id].includes(j.id);
        })
      );
    }
  };
  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(hyoumenshoriValues));
    if (types === 'types' || types === 'details' || types === 'method') {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
      setHyoumenshoriValues(updatedData);
    } else {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
      setHyoumenshoriValues(updatedData);
    }
  };
  return (
    <>
      <Row className="mt-2 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '94%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '4%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                計算方法
              </th>
              <th style={{ width: '28%' }} colSpan={2} className="tbl-header">
                詳細
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                面積/重量
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                自動抽出
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>
            {hyoumenshoriValues.map((i, index) => (
              <tr style={{ verticalAlign: 'middle' }}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <>
                      <Select
                        name="otherYousetsuType"
                        className="yousetsu-select"
                        style={{ pointerEvents: editMode ? 'auto' : 'none', fontSize: '13px' }}
                        value={i.method}
                        onChange={(e) => changeDetails(e, i.id, 'method')}
                      >
                        {calculationMethods.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>{i.method}</>
                  )}
                </td>
                <td style={{ verticalAlign: 'middle', width: '11%' }}>
                  {editMode ? (
                    <>
                      <Select
                        name="otherYousetsuType"
                        className="yousetsu-select"
                        style={{ pointerEvents: editMode ? 'auto' : 'none', fontSize: '13px' }}
                        value={i.types}
                        onChange={(e) => changeDetails(e, i.id, 'types')}
                      >
                        {methods.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>{i.types}</>
                  )}
                </td>

                <td style={{ textAlign: 'center', width: '11%' }}>
                  {i.types === '脱脂' || i.types === '無し' ? (
                    <>
                      <label style={{ textAlign: 'center' }}>-</label>
                    </>
                  ) : (
                    <>
                      {editMode ? (
                        <>
                          <Select
                            name="otherYousetsuType"
                            className="yousetsu-select"
                            style={{ pointerEvents: editMode ? 'auto' : 'none', fontSize: '13px' }}
                            value={i.details}
                            onChange={(e) => changeDetails(e, i.id, 'details')}
                          >
                            {i.types === 'メッキ' ? (
                              <>
                                {mekki.map((option) => (
                                  <option value={option.value}>{option.label}</option>
                                ))}
                              </>
                            ) : (
                              <>
                                {' '}
                                {ryoumen.map((option) => (
                                  <option value={option.value}>{option.label}</option>
                                ))}
                              </>
                            )}
                          </Select>
                        </>
                      ) : (
                        <>{i.details}</>
                      )}
                    </>
                  )}
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.area}
                    onChange={(e) => changeDetails(e, i.id, 'area')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.autoExtract}
                    onChange={(e) => changeDetails(e, i.id, 'autoExtract')}
                    style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.total}
                    style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  ></Input>
                </td>
                {editMode ? (
                  <>
                    <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                      <Row justify="center">
                        <PlusCircleFilled
                          className="add-remove-icon"
                          style={{ marginLeft: 10, marginRight: 10 }}
                          onClick={() => addHyoumenshori(i.id)}
                        />
                        <CloseCircleFilled className="add-remove-icon" onClick={() => deleteHyoumenshori(i.id)} />
                      </Row>
                    </td>
                  </>
                ) : (
                  <>
                    <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Hyoumenshori;
