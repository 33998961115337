import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const hyomenProcessArr = [
  {
    id: 0,
    name: '第1処理',
    calculateMethod: '重量',
    dandoriAmt: 350,
    dandoriTime: '00:05:00',
    sagyoAmt: 2605,
    weightCharge: 300,
    inputData: 8.681,
    processSelection1: '脱脂',
    processSelection2: '',
    formula: 'チャージ金額(/㎡) x 面積      脱脂',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 1,
    name: '第2処理',
    calculateMethod: '重量',
    inputData: 8.681,
    dandoriAmt: 350,
    dandoriTime: '00:05:00',
    sagyoAmt: 3039,
    weightCharge: 350,
    processSelection1: '塗装(両面)',
    processSelection2: 'メラミン塗装',
    formula: 'チャージ金額(/㎡) x 面積      脱脂',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const pmx02Arr01 = [
  {
    id: 0,
    name: '第1処理',
    calculateMethod: '面積',
    dandoriAmt: 350,
    dandoriTime: '00:05:00',
    sagyoAmt: 449,
    weightCharge: 1000,
    inputData: 4492.791,
    processSelection1: '脱脂',
    processSelection2: '',
    formula: 'チャージ金額(/㎡) x 面積      脱脂',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const pmx02Arr02 = [
  {
    id: 0,
    name: '第1処理',
    calculateMethod: '面積',
    dandoriAmt: 350,
    dandoriTime: '00:05:00',
    sagyoAmt: 10,
    weightCharge: 1000,
    inputData: 99.998,
    processSelection1: '脱脂',
    processSelection2: '',
    formula: 'チャージ金額(/㎡) x 面積      脱脂',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const pmx02Arr03 = [
  {
    id: 0,
    name: '第1処理',
    calculateMethod: '面積',
    dandoriAmt: 350,
    dandoriTime: '00:05:00',
    sagyoAmt: 4,
    weightCharge: 1000,
    inputData: 42.5,
    processSelection1: '脱脂',
    processSelection2: '',
    formula: 'チャージ金額(/㎡) x 面積      脱脂',
    naibuProcessValidationAmt: 132,
    naibuProcessValidationTime: '00:01:00',
    naibukensaAmt: 180,
    naibukensaTime: '00:03:00',
  },
];

const pmx02Arr04 = [
  {
    id: 0,
    name: '第1処理',
    calculateMethod: '面積',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    weightCharge: 0,
    inputData: 0,
    processSelection1: '0',
    processSelection2: '',
    formula: 'チャージ金額(/㎡) x 面積      脱脂',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const pmx03Arr = [
  {
    id: 0,
    processName: '溶接(No1)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 418,
    sagyoTime: '00:05:58',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 2,
    subTime: 0,
    subFinish: '除外',
    processLength: 690,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 1,
    processName: '溶接(No2)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 418,
    sagyoTime: '00:05:58',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 2,
    subTime: 0,
    subFinish: '除外',
    processLength: 110.8,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 2,
    processName: '溶接(No3)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 1750,
    sagyoTime: '00:25:00',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 2,
    subTime: 0,
    subFinish: '除外',
    processLength: 800,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 3,
    processName: '溶接(No4)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 114,
    sagyoTime: '00:01:37',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 2,
    subTime: 0,
    subFinish: '除外',
    processLength: 65,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 4,
    processName: '溶接(No5)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 280,
    sagyoTime: '00:04:00',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 4,
    subTime: 0,
    subFinish: '除外',
    processLength: 40,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 5,
    processName: '溶接(No6)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 114,
    sagyoTime: '00:01:37',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 1,
    subTime: 0,
    subFinish: '除外',
    processLength: 65,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
  {
    id: 6,
    processName: '溶接(No7)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 306,
    sagyoTime: '00:04:22',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 2,
    subTime: 0,
    subFinish: '除外',
    processLength: 105,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const zeroArr = [
  {
    id: 0,
    processName: '',
    processType: '',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 0,
    subTime: 0,
    subFinish: '',
    inputData: 0,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const provinceData = ['面積', '重量'];
const finishData = ['メッキ', '塗装(両面)'];
const processSelection2Data = ['ユニクロメッキ', 'アクリル塗装'];

const hyomenDDL = [
  'アルゴン',
  '半自動',
  '溶接線01',
  '溶接線02',
  '溶接線03',
  'YAGハンディ',
  'YAGロボット',
  'ロウ付',
  '開先取り',
  'Vカット',
];

const subhyomenDDL = [
  '点付',
  'プラグ溶接',
  '定置スポット',
  'テーブルスポット',
  '手動スタッド',
  '自動スタッド',
  'ナット溶接',
  'ボルト溶接',
];

const IQ3KouteiCalculate_Hyomen = forwardRef((props, ref) => {
  const [isHyomendetail, setIHyomendetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState(hyomenProcessArr);
  const [editedData, setEditedData] = useState([]);

  const [naibuProcessValidationAmt, setNaibuProcessValidationAmt] = useState('');
  const [naibuProcessValidationTime, setNaibuProcessValidationTime] = useState('');

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const settingEditData = (arr) => {
    let editDataArr = [];
    for (let i = 0; i < arr.length; i++) {
      editDataArr.push({
        id: i,
        calculateMethod: arr[i].calculateMethod,
        inputData: arr[i].inputData,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        weightCharge: arr[i].weightCharge,
        processSelection1: arr[i].processSelection1,
        processSelection2: arr[i].processSelection2,
        sumAmt: arr[i].dandoriAmt + arr[i].sagyoAmt + arr[i].naibuProcessValidationAmt,
        sumTime: secondsToHms(setTimetoSec(arr[i].dandoriTime)),
        naibuProcessValidationAmt: arr[i].naibuProcessValidationAmt,
        naibuProcessValidationTime: arr[i].naibuProcessValidationTime,
        naibukensaAmt: arr[i].naibukensaAmt,
        naibukensaTime: arr[i].naibukensaTime,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    //console.log(editDataArr);
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;

      dandoriTimeSec += setTimetoSec(element.dandoriTime);
    });

    /* let totalSum = (sagyoAmtSum + dandoriAmtSum + parseFloat(editDataArr[0].naibukensaAmt)).toFixed(0); */

    let totalSum = (editDataArr[0].sumAmt + parseFloat(editDataArr[0].naibukensaAmt)).toFixed(0);

    let dandoriTimeSum = secondsToHms(dandoriTimeSec);
    //let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editDataArr[0].naibukensaTime));

    let totalTimeSum = secondsToHms(setTimetoSec(editDataArr[0].sumTime) + setTimetoSec(editDataArr[0].naibukensaTime));

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData.length; j++) {
            subArr.push(editedData[j].dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData.length; j++) {
            subArr.push(editedData[j].dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSagyo = (
    <div>
      <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData.length; j++) {
            subArr.push(editedData[j].sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {/*  {(() => {
          const subArr = [];
          for (let j = 0; j < editedData.length; j++) {
            subArr.push(editedData[j].sagyoTime);
          }
          return subArr.join('+');
        })()} */}
      </p>
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  /* "input[data-type='currency']".on({
    keyup: function () {
      alert('test');
    },
  }); */

  useEffect(() => {
    let editData = [];
    let totals = [
      {
        id: 0,
        sagyoAmtSum: 0,
        dandoriAmtSum: 0,
        sagyoTimeSec: '00:00:00',
        dandoriTimeSec: '00:00:00',
        totalSum: 0,
        dandoriTimeSum: '00:00:00',
        totalTimeSum: '00:00:00',
        inputData: 0,
      },
    ];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    /* if (Object.keys(props.selectedDataDetail).length > 0) {
      if (props.selectedDataDetail.imgNo == 'zerofour-02') {
        setProcessArr(pmx02Arr);
        editData = settingEditData(pmx02Arr);
      } else if (props.selectedDataDetail.imgNo == 'zerofour-03') {
        setProcessArr(pmx03Arr);
        editData = settingEditData(pmx03Arr);
      } else {
        setProcessArr(hyomenProcessArr);
        editData = settingEditData(hyomenProcessArr);
      }
    } */

    if (props.selectedDataDetail !== undefined) {
      if (Object.keys(props.selectedDataDetail).length > 0) {
        if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
          setProcessArr(hyomenProcessArr);
          editData = settingEditData(hyomenProcessArr);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
          setProcessArr(hyomenProcessArr);
          editData = settingEditData(hyomenProcessArr);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
          setProcessArr(hyomenProcessArr);
          editData = settingEditData(hyomenProcessArr);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
          setProcessArr(hyomenProcessArr);
          editData = settingEditData(hyomenProcessArr);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
          setProcessArr(hyomenProcessArr);
          editData = settingEditData(hyomenProcessArr);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
          setProcessArr(pmx02Arr01);
          editData = settingEditData(pmx02Arr01);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
          setProcessArr(pmx02Arr02);
          editData = settingEditData(pmx02Arr02);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
          setProcessArr(pmx02Arr03);
          editData = settingEditData(pmx02Arr03);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
          setProcessArr(pmx02Arr04);
          editData = settingEditData(pmx02Arr04);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
          setProcessArr(pmx03Arr);
          editData = settingEditData(pmx03Arr);
        }
      } else {
        setProcessArr(zeroArr);
        editData = zeroArr;
      }

      setEditedData(editData);
      /* totals = calculateTotals(editData); */
      if (editData.length > 0) {
        totals = calculateTotals(editData);
      }
      setTotalAmt(totals[0]?.totalSum);
      setTotalSum(totals[0]?.totalSum);

      setTotalTime(totals[0]?.totalTimeSum);
      setTotalTimeSum(totals[0]?.totalTimeSum);

      setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
      setSagyoAmtSum(totals[0]?.sagyoAmtSum);

      /*  setTotalSagyoTime(totals[0].sagyoTimeSum);
    setSagyoTimeSum(totals[0].sagyoTimeSum); */

      setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
      setDandoriAmtSum(totals[0]?.dandoriAmtSum);

      setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      setDandoriTimeSum(totals[0]?.dandoriTimeSum);

      setNaibuProcessValidationAmt(editData[0]?.naibuProcessValidationAmt);
      setNaibuProcessValidationTime(editData[0]?.naibuProcessValidationTime);
    }
  }, [props.selectedDataDetail, props.editMode]);

  const hyomen = () => {
    if (isHyomendetail == true) {
      setIHyomendetail(false);
    } else {
      setIHyomendetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('hyomenProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('hyomenProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownHyomenIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpHyomenIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('hyomenProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownHyomenIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpHyomenIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      /* if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
      } else {
        newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
      } */
      if (document.getElementById(index + 'hyomenDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'hyomenDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'hyomenDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'hyomenDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      /* if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
      } else {
        newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
      } */
      checkInputWithColor(index + 'hyomenWorkAmt', newState[index].sagyoAmt, 'green');
      if (document.getElementById(index + 'hyomenWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'hyomenWorkAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'hyomenWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'hyomenWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = parseFloat(newState[index].sagyoAmt) + parseFloat(newState[index].dandoriAmt);
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = secondsToHms(
      /* setTimetoSec(newState[index].sagyoTime) + */ setTimetoSec(newState[index].dandoriTime)
    );
    newState[index].sumTime = newSubTimeSum;
    newState.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      //newSagyoTimeSec += setTimetoSec(element.sagyoTime);
      newDandoriTimeSec += setTimetoSec(element.dandoriTime);
    });
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = secondsToHms(newSagyoTimeSec);
    let newDandoriTimeSum = secondsToHms(newDandoriTimeSec);
    let newTotalTimeSum = secondsToHms(newSagyoTimeSec + newDandoriTimeSec);

    checkInputWithColor(index + 'subTotalHyomen', newSubSum, 'green');
    /*checkInputWithColor(index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor('totalSagyoAmtHyomen', newSagyoSum, 'green');
    checkInputWithColor('totalDandoriAmtHyomen', newDandoriSum, 'green');
    checkInputWithColor('totalAmtHyomen', newSum, 'green');
    //checkInputWithColor('totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor('totalDandoriTimeHyomen', newDandoriTimeSum, 'green');
    checkInputWithColor('totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={hyomen}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isHyomendetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>表面処理</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>
            {/* ¥{totalSum} */} {JPYs.format(totalSum)}
          </div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>表面処理_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isHyomendetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            表面処理
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtHyomen">
              {/* ¥{totalSum} */}
              {JPYs.format(totalSum)}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtHyomenIn" className="resetColor">
              {/* ¥{totalAmt} */}
              {JPYs.format(totalAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            {/* <label id="totalTimeIn" className="resetColor">
              {totalTime}
            </label> */}
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtHyomen">{JPYs.format(dandoriAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeHyomen">{dandoriTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtHyomenIn" className="resetColor">
              {/* ¥{totalDandoriAmt} */}
              {JPYs.format(totalDandoriAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeHyomenIn" className="resetColor">
              {totalDandoriTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            {/* <Popover content={contentDandori}>段取金額の総合計</Popover> */}
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            作業金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtHyomen">
              {/* ¥{sagyoAmtSum} */}
              {JPYs.format(sagyoAmtSum)}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            {/* <label id="totalSagyoTime">{sagyoTimeSum}</label> */}
            {processArr[0].inputData}
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtHyomenIn" className="resetColor">
              {/*  ¥{totalSagyoAmt} */}
              {JPYs.format(totalSagyoAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            {/* <label id="totalSagyoTimeIn" className="resetColor">
              {totalSagyoTime}
            </label> */}
            <label id="totalWeightChargeIn">{processArr[0].inputData /* {totalSagyoTime} */}</label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            {/* <Popover content={contentSagyo}>作業金額の総合計</Popover> */}
          </Col>
        </Row>

        <div
          style={{
            display:
              processArr[0].naibukensaAmt !== 0 && processArr[0].naibukensaTime !== '00:00:00' ? 'block' : 'none',
          }}
        >
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              工程内検査金額/時間
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="totalKensaAmtHyomen">
                {/* ¥{sagyoAmtSum} */}
                {JPYs.format(processArr[0].naibukensaAmt)}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              {/* <label id="totalSagyoTime">{sagyoTimeSum}</label> */}
              {processArr[0].naibukensaTime}
            </Col>
            <Col span={4} className="oyabuhin-detail-col">
              <label id="totalKensaAmtHyomenIn" className="resetColor">
                {/*  ¥{totalSagyoAmt} */}
                {JPYs.format(processArr[0].naibukensaAmt)}
              </label>
            </Col>
            <Col span={4} className="oyabuhin-detail-col">
              {/* <label id="totalSagyoTimeIn" className="resetColor">
              {totalSagyoTime}
            </label> */}
              <label id="totalKensaTimeHyomenIn">{processArr[0].naibukensaTime}</label>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column">
              {/* <Popover content={contentSagyo}>作業金額の総合計</Popover> */}
            </Col>
          </Row>
        </div>

        {(() => {
          const arr = [];
          for (let i = 0; i < editedData.length; i++) {
            arr.push(
              <div>
                <Row
                  style={{ color: 'black' }}
                  className="oyabuhin-detail oyabuhin-row"
                  onClick={(e) => subpartDetail(i)}
                >
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex' }}>
                      <CaretUpOutlined
                        id={'arrowUpHyomenIQ3' + i}
                        style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      />
                      <CaretDownOutlined id={'arrowDownHyomenIQ3' + i} style={{ fontSize: 17, marginTop: 2 }} />
                      <span style={{ marginLeft: '5px' }}>{processArr[i].name}</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    {/* ¥ */}
                    <label id={i + 'subTotalHyomen'}>
                      {JPYs.format(
                        (
                          processArr[i].dandoriAmt +
                          processArr[i].sagyoAmt +
                          processArr[i].naibuProcessValidationAmt
                        ).toFixed(0)
                      )}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalHyomenIn'} className="resetColor">
                      {JPYs.format(editedData[i].sumAmt)}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col"></Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
                <div id={'hyomenProcessIQ3' + i} style={{ display: 'none' }}>
                  {(() => {
                    const subArr = [];
                    for (let j = i; j <= i; j++) {
                      subArr.push(
                        <div>
                          {/* 段取金額/時間 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">段取金額/時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'hyomenDandoriAmt'}>{JPYs.format(processArr[j].dandoriAmt)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'hyomenDandoriTime'}>{processArr[j].dandoriTime}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'hyomenDandoriAmtIn'}
                                value={editedData[j].dandoriAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].dandoriAmt != e) {
                                    settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                    checkInputWithColor(j + 'hyomenDandoriAmt', e, 'amt');
                                  }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />

                              <div id={j + 'hyomenDandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].dandoriTime}
                                    id={j + 'hyomenDandoriTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].dandoriTime,
                                            '10min',
                                            j + 'hyomenDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].dandoriTime,
                                            '10min',
                                            j + 'hyomenDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].dandoriTime,
                                            'min',
                                            j + 'hyomenDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].dandoriTime,
                                            'min',
                                            j + 'hyomenDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].dandoriTime,
                                            'sec',
                                            j + 'hyomenDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].dandoriTime,
                                            'sec',
                                            j + 'hyomenDandoriTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column">
                              <Popover content={contentSub}>{processArr[j].formula}</Popover>
                            </Col>
                          </Row>
                          {/* 段取金額/時間 */}

                          {/* 作業金額/重量チャージ */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">作業金額/面積チャージ</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'hyomenWorkAmt'}>{JPYs.format(processArr[j].sagyoAmt)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'weightCharge'}>{JPYs.format(processArr[j].weightCharge)}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'hyomenWorkAmtIn'}
                                value={editedData[j].sagyoAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].sagyoAmt != e) {
                                    settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                    checkInputWithColor(j + 'hyomenWorkAmt', e, 'amt');
                                  }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'hyomenWorkAmtCu'} style={{ display: 'none' }} className="currencyReset">
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'hyomenWeightChargeIn'}
                                value={editedData[j].weightCharge}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].weightCharge != e) {
                                    settingNewDandoriAmtInArr(e, j, 'weightCharge', '', '');
                                  }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column">
                              {/* <Popover content={contentSub}>{processArr[j].formula}</Popover> */}
                            </Col>
                          </Row>
                          {/* 作業金額/時間 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">計算方法/入力値</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'calculateMethod'}>{processArr[j].calculateMethod}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'inputData'}>{processArr[j].inputData}</label>
                            </Col>
                            {editMode ? (
                              <Col span={4} className="input-col">
                                <Select
                                  defaultValue={editedData[j].calculateMethod}
                                  style={{
                                    width: '100%',
                                    margin: '0 5px',
                                    fontSize: 14,
                                  }}
                                  className="test"
                                  id={i + 'calculateMethodIn'}
                                  onChange={handleProvinceChange}
                                  options={provinceData.map((province) => ({ label: province, value: province }))}
                                />
                              </Col>
                            ) : (
                              <Col span={4} className="oyabuhin-detail-col">
                                <label id={i + 'calculateMethodIN'} className="currencyReset">
                                  {editedData[i].calculateMethod}
                                </label>
                              </Col>
                            )}
                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[i].inputData}
                                id={i + 'inputDataIN'}
                                min={0}
                                className={editMode ? 'input-editable' : 'input-non-editable '}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">加工方法</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'processSelection1'}>{processArr[j].processSelection1}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'processSelection2'}>{processArr[j].processSelection2}</label>
                            </Col>
                            {editMode ? (
                              <Col span={4} className="input-col">
                                <Select
                                  defaultValue={editedData[i].processSelection1}
                                  style={{
                                    width: '100%',
                                    margin: '0 5px',
                                    fontSize: 14,
                                  }}
                                  className="test"
                                  id={j + 'processSelection1In'}
                                  onChange={handleProvinceChange}
                                  options={finishData.map((province) => ({ label: province, value: province }))}
                                />
                              </Col>
                            ) : (
                              <Col span={4} className="oyabuhin-detail-col">
                                <label id={j + 'processSelection1In'} className="currencyReset">
                                  {editedData[j].processSelection1}
                                </label>
                              </Col>
                            )}

                            {editMode ? (
                              <Col span={4} className="input-col">
                                <Select
                                  defaultValue={editedData[j].processSelection2}
                                  style={{
                                    width: '100%',
                                    margin: '0 5px',
                                    fontSize: 14,
                                  }}
                                  className="test"
                                  id={j + 'processSelection2In'}
                                  onChange={handleProvinceChange}
                                  options={processSelection2Data.map((province) => ({
                                    label: province,
                                    value: province,
                                  }))}
                                />
                              </Col>
                            ) : (
                              <Col span={4} className="oyabuhin-detail-col">
                                <label id={j + 'processSelection2In'} className="currencyReset">
                                  {editedData[j].processSelection2}
                                </label>
                              </Col>
                            )}

                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          {/* マテハン金額/時間 */}
                          <Row
                            style={{
                              color: 'black',
                              display: processArr[j].naibuProcessValidationAmt == 0 ? 'none' : '',
                            }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">マテハン金額/時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'naibuProcessValidationAmt'}>
                                {JPYs.format(processArr[j].naibuProcessValidationAmt)}
                              </label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'naibuProcessValidationTime'}>
                                {processArr[j].naibuProcessValidationTime}
                              </label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'naibuProcessValidationAmtIn'}
                                value={editedData[j].naibuProcessValidationAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  // if (editedData[j].dandoriAmt != e) {
                                  //   settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                  //   checkInputWithColor(j + 'hyomenDandoriAmt', e, 'amt');
                                  // }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />

                              <div
                                id={j + 'naibuProcessValidationAmtCu'}
                                style={{ display: 'none' }}
                                className="currencyReset"
                              >
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].naibuProcessValidationTime}
                                    id={j + 'naibuProcessValidationTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      // settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].naibuProcessValidationTime,
                                          //   '10min',
                                          //   j + 'hyomenDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   '10min',
                                          //   j + 'hyomenDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'min',
                                          //   j + 'hyomenDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'min',
                                          //   j + 'hyomenDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'sec',
                                          //   j + 'hyomenDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'sec',
                                          //   j + 'hyomenDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column">
                              <Popover content={contentSub}>{processArr[j].formula}</Popover>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                    return subArr;
                  })()}
                </div>
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Hyomen;
