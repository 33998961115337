import iq3Img_1 from '../../assets/images/iq3Img_1.png';
import iq3Img_2 from '../../assets/images/iq3Img_2.png';
import iq3Img_3 from '../../assets/images/iq3Img_3.png';
import iq3Img_4 from '../../assets/images/iq3Img_4.png';
import iq3Img_5 from '../../assets/images/iq3Img_5.png';
import iq3Img_pmx1_1 from '../../assets/images/iq3Img_pmx1_1.png';
import iq3Img_pmx1_2 from '../../assets/images/iq3Img_pmx1_2.png';
import iq3Img_pmx1_3 from '../../assets/images/iq3Img_pmx1_3.png';
// import iq3Img_pmx1_4 from '../../assets/images/iq3Img_pmx1_4.png';
import no_image from '../../assets/images/no_image.jpg';
import iq3Img_pmx2_1 from '../../assets/images/iq3Img_pmx2_1.png';
import iq3Img_pmx2_2 from '../../assets/images/iq3Img_pmx2_2.png';
import iq3Img_pmx2_3 from '../../assets/images/iq3Img_pmx2_3.png';
import iq3Img_pmx2_4 from '../../assets/images/iq3Img_pmx2_4.png';
import iq3Img_pmx2_5 from '../../assets/images/iq3Img_pmx2_5.png';
import iq3Img_pmx2_6 from '../../assets/images/iq3Img_pmx2_6.png';
import { Image } from 'antd';
import {
  kouteiInputInfo_1,
  kouteiInputInfo_2,
  kouteiInputInfo_3,
  kouteiInputInfo_4,
  kouteiInputInfo_5,
  zero2_kouteiInputInfo_1,
  zero2_kouteiInputInfo_2,
  zero2_kouteiInputInfo_3,
  zero2_kouteiInputInfo_4,
} from './IQ3KouteiInputSampleData';

const costLst = [
  {
    key: 1,
    title: '配送費',
    data: 5000,
    isUsedTanka: true,
  },
  {
    key: 2,
    title: '諸経費​',
    data: 0,
    isUsedTanka: true,
  },
  {
    key: 3,
    title: '後見積​',
    data: 0,
    isUsedTanka: true,
  },
  {
    key: 4,
    title: '費用01',
    data: 0,
    isUsedTanka: true,
  },
  {
    key: 5,
    title: '費用02',
    data: 0,
    isUsedTanka: true,
  },
  {
    key: 6,
    title: '費用03',
    data: 0,
    isUsedTanka: true,
  },
  {
    key: 7,
    title: '費用04',
    data: 0,
    isUsedTanka: true,
  },
];

const costLst_PMX_1 = [
  {
    key: 1,
    title: '配送費',
    data: 3000,
    isUsedTanka: false,
  },
  {
    key: 2,
    title: '諸経費​',
    data: 0,
    isUsedTanka: false,
  },
  {
    key: 3,
    title: '後見積​',
    data: 0,
    isUsedTanka: false,
  },
  {
    key: 4,
    title: '費用01',
    data: 0,
    isUsedTanka: false,
  },
];

const costLst_PMX_2 = [
  {
    key: 1,
    title: '配送費',
    data: 3000,
  },
  {
    key: 2,
    title: '諸経費​',
    data: 0,
  },
  {
    key: 3,
    title: '後見積​',
    data: 0,
  },
  {
    key: 4,
    title: '費用01',
    data: 0,
  },
  {
    key: 5,
    title: '費用02',
    data: 0,
  },
  {
    key: 6,
    title: '費用03',
    data: 0,
  },
  {
    key: 7,
    title: '費用04',
    data: 0,
  },
  {
    key: 8,
    title: '費用05',
    data: 0,
  },
  {
    key: 7,
    title: '費用06',
    data: 0,
  },
  {
    key: 7,
    title: '費用07',
    data: 0,
  },
];

const dateLst = [
  {
    key: 1,
    title: '見積日',
    data: '2023/04/18',
  },
  {
    key: 2,
    title: '社内納期',
    data: '2023/06/02',
  },
  {
    key: 3,
    title: '希望納期​',
    data: '2023/06/17',
  },
  {
    key: 4,
    title: '日付2',
    data: '2023/04/24',
  },
];

const dateLst_PMX_1 = [
  {
    key: 1,
    title: '見積日',
    data: '2023/05/01',
  },
  {
    key: 2,
    title: '社内納期',
    data: '2023/05/15',
  },
  {
    key: 3,
    title: '希望納期​',
    data: '2023/05/30',
  },
];
const dateLst_PMX_2 = [
  {
    key: 1,
    title: '見積日',
    data: '2023/04/18',
  },
  {
    key: 2,
    title: '社内納期',
    data: '2023/06/02',
  },
  {
    key: 3,
    title: '希望納期​',
    data: '2023/06/17',
  },
  {
    key: 4,
    title: '日付2',
    data: '',
  },
  ,
];

const labelLst = [
  {
    key: 1,
    title: 'ラベル1',
    data: '',
  },
  {
    key: 2,
    title: 'ラベル2',
    data: '',
  },
  {
    key: 3,
    title: 'ラベル3',
    data: '',
  },
  {
    key: 4,
    title: 'ラベル4',
    data: '',
  },
  {
    key: 5,
    title: 'ラベル5',
    data: '',
  },
  {
    key: 6,
    title: 'ラベル6',
    data: '',
  },
  {
    key: 7,
    title: 'ラベル7',
    data: '',
  },
  {
    key: 8,
    title: 'ラベル8',
    data: '',
  },
];

const labelLst_PMX_1 = [
  {
    key: 1,
    title: 'ラベル1',
    data: '**************',
  },
  {
    key: 2,
    title: 'ラベル2',
    data: '**************',
  },
  {
    key: 3,
    title: 'ラベル3',
    data: '**************',
  },
  {
    key: 4,
    title: 'ラベル4',
    data: '**************',
  },
];
const labelLst_PMX_2 = [
  {
    key: 1,
    title: 'ラベル1',
    data: '',
  },
  {
    key: 2,
    title: 'ラベル2',
    data: '',
  },
  {
    key: 3,
    title: 'ラベル3',
    data: '',
  },
  {
    key: 4,
    title: 'ラベル4',
    data: '',
  },
  {
    key: 5,
    title: 'ラベル5',
    data: '',
  },
  {
    key: 6,
    title: 'ラベル6',
    data: '',
  },
  {
    key: 7,
    title: 'ラベル7',
    data: '',
  },
  {
    key: 8,
    title: 'ラベル8',
    data: '',
  },
];

const memoLst = [
  {
    key: 1,
    title: 'メモ1',
    data: '',
  },
  {
    key: 2,
    title: 'メモ2',
    data: '',
  },
  {
    key: 3,
    title: 'メモ3',
    data: '',
  },
];
const memoLst_PMX_1 = [
  {
    key: 1,
    title: 'メモ1',
    data: '**************************************************************************',
  },
  {
    key: 2,
    title: 'メモ2',
    data: '**************************************************************************',
  },
  {
    key: 3,
    title: 'メモ3',
    data: '',
  },
  {
    key: 4,
    title: 'メモ3',
    data: '',
  },
];
const memoLst_PMX_2 = [
  {
    key: 1,
    title: 'メモ1',
    data: '',
  },
  {
    key: 2,
    title: 'メモ2',
    data: '',
  },
  {
    key: 3,
    title: 'メモ3',
    data: '',
  },
  {
    key: 4,
    title: 'メモ4',
    data: '',
  },
  {
    key: 5,
    title: 'メモ5',
    data: '',
  },
  {
    key: 6,
    title: 'メモ6',
    data: '',
  },
  {
    key: 7,
    title: 'メモ7',
    data: '',
  },
  {
    key: 8,
    title: 'メモ8',
    data: '',
  },
];

const customerCoefficient = [
  {
    key: 1,
    customerCoefficient: '1.00',
    managementCoefficient: '1.25',
    materialLossCoefficient: '1.00',
    orderCoefficient: '1.00',
    setupCoefficient: '1.00',
    surfaceAreaCoefficient: '1.00',
    purchaseCoefficient: '0.00',
    deliveryCoefficient: ' 1.00',
  },
];
const parentProcess = [
  {
    key: 1,
    processNm: '溶接',
    setupAmount: Math.round((210 + Number.EPSILON) * 100) / 100,
    setupTime: '00:03:00',
    processingAmount: Math.round((1458 + Number.EPSILON) * 100) / 100,
    processingTime: '00:20:50',
  },
  {
    key: 2,
    processNm: '組立',
    setupAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    setupTime: '00:00:00',
    processingAmount: Math.round((9248 + Number.EPSILON) * 100) / 100,
    processingTime: '00:45:40',
  },
  {
    key: 3,
    processNm: '梱包',
    setupAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    setupTime: '00:00:00',
    processingAmount: Math.round((2160 + Number.EPSILON) * 100) / 100,
    processingTime: '00:06:40',
  },
  {
    key: 4,
    processNm: '検査',
    setupAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    setupTime: '00:00:00',
    processingAmount: Math.round((2160 + Number.EPSILON) * 100) / 100,
    processingTime: '00:06:40',
  },
  {
    key: 5,
    processNm: '委託塗装',
    setupAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    setupTime: '00:00:00',
    processingAmount: Math.round((75000 + Number.EPSILON) * 100) / 100,
    processingTime: '00:00:00',
  },
  {
    key: 6,
    processNm: '研磨',
    setupAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    setupTime: '00:00:00',
    processingAmount: Math.round((1500 + Number.EPSILON) * 100) / 100,
    processingTime: '00:00:00',
  },
];
const parentProcess_PMX_1 = [
  {
    key: 1,
    processNm: '溶接',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((1565 + Number.EPSILON) * 100) / 100,
    processingTime: '00:22:21',
  },
  {
    key: 2,
    processNm: '表面処理',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((1911 + Number.EPSILON) * 100) / 100,
    processingTime: '00:00:00',
  },
  {
    key: 3,
    processNm: '組立',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((2110 + Number.EPSILON) * 100) / 100,
    processingTime: '00:15:00',
  },
  {
    key: 4,
    processNm: '梱包',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((704 + Number.EPSILON) * 100) / 100,
    processingTime: '00:05:00',
  },
  {
    key: 5,
    processNm: '検査',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((704 + Number.EPSILON) * 100) / 100,
    processingTime: '00:05:00',
  },
];
const parentProcess_PMX_2 = [
  {
    key: 1,
    processNm: '溶接',
    setupAmount: Math.round((210 + Number.EPSILON) * 100) / 100,
    setupTime: '00:03:00',
    processingAmount: Math.round((4491 + Number.EPSILON) * 100) / 100,
    processingTime: '00:52:52',
  },
  {
    key: 2,
    processNm: '組立',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((1055 + Number.EPSILON) * 100) / 100,
    processingTime: '00:09:00',
  },
  {
    key: 3,
    processNm: '梱包',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((704 + Number.EPSILON) * 100) / 100,
    processingTime: '00:06:00',
  },
  {
    key: 4,
    processNm: '検査',
    setupAmount: 0,
    setupTime: '00:00:00',
    processingAmount: Math.round((704 + Number.EPSILON) * 100) / 100,
    processingTime: '00:06:00',
  },
];
const iq3Process = [
  {
    key: 1,
    processNm: 'プログラム',
    setupAmount: Math.round((65860 + Number.EPSILON) * 100) / 100,
    setupTime: '18:17:40',
    processingAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    processingTime: '00:00:00',
  },
  {
    key: 2,
    processNm: '複合機',
    setupAmount: Math.round((1217 + Number.EPSILON) * 100) / 100,
    setupTime: '00:10:00',
    processingAmount: Math.round((2821 + Number.EPSILON) * 100) / 100,
    processingTime: '00:25:29',
  },
  {
    key: 3,
    processNm: 'プレスブレーキ',
    setupAmount: Math.round((1200 + Number.EPSILON) * 100) / 100,
    setupTime: '00:16:00',
    processingAmount: Math.round((1335 + Number.EPSILON) * 100) / 100,
    processingTime: '00:17:18',
  },
  {
    key: 4,
    processNm: '手動バリ取り',
    setupAmount: Math.round((250 + Number.EPSILON) * 100) / 100,
    setupTime: '00:05:00',
    processingAmount: Math.round((798 + Number.EPSILON) * 100) / 100,
    processingTime: '00:15:57',
  },
  {
    key: 5,
    processNm: '自動バリ取り',
    setupAmount: Math.round((875 + Number.EPSILON) * 100) / 100,
    setupTime: '00:15:00',
    processingAmount: Math.round((263 + Number.EPSILON) * 100) / 100,
    processingTime: '00:04:30',
  },
];
const iq3Process_PMX_1 = [
  {
    key: 1,
    processNm: 'プログラム',
    setupAmount: Math.round((2560 + Number.EPSILON) * 100) / 100,
    setupTime: '00:42:40',
    processingAmount: 0,
    processingTime: '00:00:00',
  },
  {
    key: 2,
    processNm: '複合機',
    setupAmount: Math.round((730 + Number.EPSILON) * 100) / 100,
    setupTime: '00:06:00',
    processingAmount: Math.round((493 + Number.EPSILON) * 100) / 100,
    processingTime: '00:04:49',
  },
  {
    key: 3,
    processNm: 'プレスブレーキ',
    setupAmount: Math.round((825 + Number.EPSILON) * 100) / 100,
    setupTime: '00:11:00',
    processingAmount: Math.round((493 + Number.EPSILON) * 100) / 100,
    processingTime: '00:12:46',
  },
  {
    key: 4,
    processNm: '手動バリ取り',
    setupAmount: Math.round((150 + Number.EPSILON) * 100) / 100,
    setupTime: '00:03:00',
    processingAmount: Math.round((230 + Number.EPSILON) * 100) / 100,
    processingTime: '00:04:35',
  },
  {
    key: 5,
    processNm: '自動バリ取り',
    setupAmount: Math.round((525 + Number.EPSILON) * 100) / 100,
    setupTime: '00:09:00',
    processingAmount: Math.round((111 + Number.EPSILON) * 100) / 100,
    processingTime: '00:01:54',
  },
  {
    key: 6,
    processNm: '溶接',
    setupAmount: Math.round((70 + Number.EPSILON) * 100) / 100,
    setupTime: '00:01:00',
    processingAmount: Math.round((453 + Number.EPSILON) * 100) / 100,
    processingTime: '00:06:28',
  },
];
const iq3Process_PMX_2 = [
  {
    key: 1,
    processNm: 'プログラム',
    setupAmount: Math.round((5140 + Number.EPSILON) * 100) / 100,
    setupTime: '01:25:40',
    processingAmount: 0,
    processingTime: '00:00:00',
  },
  {
    key: 2,
    processNm: '複合機',
    setupAmount: Math.round((1460 + Number.EPSILON) * 100) / 100,
    setupTime: '00:12:00',
    processingAmount: Math.round((785 + Number.EPSILON) * 100) / 100,
    processingTime: '00:07:46',
  },
  {
    key: 3,
    processNm: 'プレスブレーキ',
    setupAmount: Math.round((3075 + Number.EPSILON) * 100) / 100,
    setupTime: '00:41:00',
    processingAmount: Math.round((1239 + Number.EPSILON) * 100) / 100,
    processingTime: '00:16:31',
  },
  {
    key: 4,
    processNm: '手動バリ取り',
    setupAmount: Math.round((300 + Number.EPSILON) * 100) / 100,
    setupTime: '00:06:00',
    processingAmount: Math.round((362 + Number.EPSILON) * 100) / 100,
    processingTime: '00:07:14',
  },
  {
    key: 5,
    processNm: '自動バリ取り',
    setupAmount: Math.round((1050 + Number.EPSILON) * 100) / 100,
    setupTime: '00:18:00',
    processingAmount: Math.round((152 + Number.EPSILON) * 100) / 100,
    processingTime: '00:02:36',
  },
];
const iq5Process = [
  {
    key: 1,
    processNm: '切断',
    setupAmount: Math.round((100 + Number.EPSILON) * 100) / 100,
    setupTime: '00:01:40',
    processingAmount: Math.round((72 + Number.EPSILON) * 100) / 100,
    processingTime: '00:01:12',
  },
  {
    key: 2,
    processNm: '穴',
    setupAmount: Math.round((300 + Number.EPSILON) * 100) / 100,
    setupTime: '00:05:00',
    processingAmount: Math.round((495 + Number.EPSILON) * 100) / 100,
    processingTime: '00:08:15',
  },
];
const parentKouteiCalInfo_yosetsu = [
  {
    id: 0,
    processName: '溶接(No1)',
    processType: 'アルゴン',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 0,
    subTime: 0,
    subFinish: '',
    processLength: 0,
    sumAmt: 0,
    sumTime: '00:00:00',
  },
  {
    id: 1,
    processName: 'サブ溶接(No1)',
    processType: 'ナット溶接',
    dandoriAmt: 210,
    dandoriTime: '00:03:00',
    sagyoAmt: 1458,
    sagyoTime: '00:20:50',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 10.0,
    subCount: 35,
    subTime: 1,
    subFinish: '除外',
    processLength: 0,
    sumAmt: 1668,
    sumTime: '00:23:50',
  },
];

const parentKouteiCalInfo = [
  {
    key: 0,
    kouteiName: 'yosetsu',
    kouteiContent: parentKouteiCalInfo_yosetsu,
  },
];

const programArr = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 1800,
    dandoriTime: '00:30:00',
    sagyoAmt: 54880,
    sagyoTime: '15:14:40',
    eleCount: 2744,
  },
];

const iq3KouteiCalInfo = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: programArr,
  },
];

// let iq3Data = [];
// for (let i = 0; i < 50; i++) {
//   iq3Data.push({
//     key: i,
//     no: i + 1,
//     adopt: `自動`,
//     image: <Image preview={true} width={120} height={77} src={iq3Img_1} style={{ marginLeft: '0px' }}></Image>,
//     partCnt: 1,
//     processNm: `標準`,
//     partNm: `看板本体`,
//     imgNo: `看板本体`,
//     material: '02_SPHC（黒皮）',
//     thickness: '1.6',
//     materialNm: 'SPHC（黒皮） - 1.6',
//     weight: 2.275,
//     surfaceArea: 1800,
//     materialCost: 376.0,
//     processCost: 58659.0,
//     purchaseCost: 0.0,
//     costPrice: 59036.0,
//     estimatedAmount: 0.0,
//     totalCostPrice: 59036.0,
//     totalEstimatedAmount: 0.0,
//     costProfit: 0.0,
//     estimateProfit: 0.0,
//     profitRate: '20.00',
//     iq3KouteiSentakuPatternNo: i < 5 ? 1 : 3,
//     iq3KouteiInputData: kouteiInputInfo_1,
//     iq3KouteiCalInfoData: JSON.parse(JSON.stringify(iq3KouteiCalInfo)),
//     iq3KouteiCalInfoEditData: JSON.parse(JSON.stringify(iq3KouteiCalInfo)),
//     iq3PurchaseData: [],
//   });
// }
const iq3Data = [
  {
    key: 0,
    no: 1,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_1} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `看板本体`,
    imgNo: `看板本体`,
    material: '02_SPHC（黒皮）',
    thickness: '1.6',
    materialNm: 'SPHC（黒皮） - 1.6',
    weight: 2.275,
    surfaceArea: 1800,
    materialCost: 376.0,
    processCost: 58659.0,
    purchaseCost: 0.0,
    costPrice: 59036.0,
    estimatedAmount: 0.0,
    totalCostPrice: 59036.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: kouteiInputInfo_1,
  },
  {
    key: 1,
    no: 2,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_2} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `看板本体-2塗装`,
    imgNo: `看板本体-2塗装`,
    material: '02_SPHC（黒皮）',
    thickness: '1.6',
    materialNm: 'SPHC（黒皮） - 1.6',
    weight: 2.275,
    surfaceArea: 1800,
    materialCost: 376.0,
    processCost: 3585.0,
    purchaseCost: 0.0,
    costPrice: 3961.0,
    estimatedAmount: 0.0,
    totalCostPrice: 3961.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: kouteiInputInfo_2,
  },
  {
    key: 2,
    no: 3,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_3} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `看板スタンド-1`,
    imgNo: `看板スタンド-1`,
    material: '02_SPHC（黒皮）',
    thickness: '3.2',
    materialNm: 'SPHC（黒皮） - 3.2',
    weight: 7.129,
    surfaceArea: 281988,
    materialCost: 1019.0,
    processCost: 6650.0,
    purchaseCost: 0.0,
    costPrice: 7669.0,
    estimatedAmount: 0.0,
    totalCostPrice: 7669.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: kouteiInputInfo_3,
  },
  {
    key: 3,
    no: 4,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_4} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `看板スタンド-2`,
    imgNo: `看板スタンド-2`,
    material: '02_SPHC（黒皮）',
    thickness: '3.2',
    materialNm: 'SPHC（黒皮） - 3.2',
    weight: 0.758,
    surfaceArea: 300,
    materialCost: 360.0,
    processCost: 1842.0,
    purchaseCost: 0.0,
    costPrice: 2203.0,
    estimatedAmount: 0.0,
    totalCostPrice: 2203.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: kouteiInputInfo_4,
  },
  {
    key: 4,
    no: 5,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_5} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 5,
    processNm: `標準`,
    partNm: `看板スタンド-3x5`,
    imgNo: `看板スタンド-3x5`,
    material: '02_SPHC（黒皮）',
    thickness: '3.2',
    materialNm: 'SPHC（黒皮） - 3.2',
    weight: 0.377,
    surfaceArea: 148.94,
    materialCost: 120.0,
    processCost: 1708.0,
    purchaseCost: 0.0,
    costPrice: 1827.0,
    estimatedAmount: 0.0,
    totalCostPrice: 1827.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.47,
    estimateProfit: 0.47,
    profitRate: '20.00',
    iq3KouteiInputData: kouteiInputInfo_5,
  },
];
const iq3Data_PMX_1 = [
  {
    key: 0,
    no: 1,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx1_1} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `MRO-M08A-4110-01`,
    imgNo: `MRO-M08A-4110-01`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 8.163,
    surfaceArea: 4492.791,
    materialCost: 1207.0,
    processCost: 7144.0,
    purchaseCost: 0.0,
    costPrice: 8351.0,
    estimatedAmount: 0.0,
    totalCostPrice: 8351.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: zero2_kouteiInputInfo_1,
  },
  {
    key: 1,
    no: 2,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx1_2} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 2,
    processNm: `標準`,
    partNm: `MRO-M08A-4110-02`,
    imgNo: `MRO-M08A-4110-02`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 0.182,
    surfaceArea: 99.998,
    materialCost: 64.0,
    processCost: 465.0,
    purchaseCost: 0.0,
    costPrice: 529.0,
    estimatedAmount: 0.0,
    totalCostPrice: 1058.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: zero2_kouteiInputInfo_2,
  },
  {
    key: 2,
    no: 3,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx1_3} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 2,
    processNm: `標準`,
    partNm: `MRO-M08A-4110-03`,
    imgNo: `MRO-M08A-4110-03`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 0.077,
    surfaceArea: 42.5,
    materialCost: 38.0,
    processCost: 470.0,
    purchaseCost: 0.0,
    costPrice: 508.0,
    estimatedAmount: 0.0,
    totalCostPrice: 1017.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: zero2_kouteiInputInfo_3,
  },
  {
    key: 3,
    no: 4,
    adopt: `自動`,
    image: (
      <Image
        preview={true}
        width={120}
        height={77}
        src={no_image}
        style={{ marginLeft: '0px', border: '0.1px solid #a5a5a5' }}
      ></Image>
    ),
    partCnt: 10,
    processNm: `標準`,
    partNm: `MRO-M08A-4110-04`,
    imgNo: `MRO-M08A-4110-04`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 0,
    surfaceArea: 0,
    materialCost: 0.0,
    processCost: 0.0,
    purchaseCost: 1500.0,
    costPrice: 0.0,
    estimatedAmount: 0.0,
    totalCostPrice: 0.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
    iq3KouteiInputData: zero2_kouteiInputInfo_4,
  },
];
const iq3Data_PMX_2 = [
  {
    key: 0,
    no: 1,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx2_1} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-01`,
    imgNo: `ISZ-HRK1-1300-01`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 2.832,
    surfaceArea: 1558.8411,
    materialCost: 556.0,
    processCost: 5421.0,
    purchaseCost: 0.0,
    costPrice: 5977.0,
    estimatedAmount: 0.0,
    totalCostPrice: 5977.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
  {
    key: 1,
    no: 2,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx2_2} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-02`,
    imgNo: `ISZ-HRK1-1300-02`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 0.577,
    surfaceArea: 317.676,
    materialCost: 217.0,
    processCost: 2599.0,
    purchaseCost: 0.0,
    costPrice: 2726.0,
    estimatedAmount: 0.0,
    totalCostPrice: 2726.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
  {
    key: 2,
    no: 3,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx2_3} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-03`,
    imgNo: `ISZ-HRK1-1300-03`,
    material: '01_SPCC',
    thickness: '2.3',
    materialNm: 'SPCC – 2.3',
    weight: 2.57,
    surfaceArea: 1414.56,
    materialCost: 489.0,
    processCost: 3593.0,
    purchaseCost: 0.0,
    costPrice: 4081.0,
    estimatedAmount: 0.0,
    totalCostPrice: 4081.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
  {
    key: 3,
    no: 4,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx2_4} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-04`,
    imgNo: `ISZ-HRK1-1300-04`,
    material: '02_SPHC(黒皮)',
    thickness: '6.0',
    materialNm: 'SPHC(黒皮) – 6.0',
    weight: 0.691,
    surfaceArea: 145.873,
    materialCost: 1943.0,
    processCost: 1778.0,
    purchaseCost: 0.0,
    costPrice: 3721.0,
    estimatedAmount: 0.0,
    totalCostPrice: 3721.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
  {
    key: 4,
    no: 5,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx2_5} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-05`,
    imgNo: `ISZ-HRK1-1300-05`,
    material: '01_SPCC',
    thickness: '3.2',
    materialNm: 'SPCC – 3.2',
    weight: 0.119,
    surfaceArea: 47.0,
    materialCost: 62.0,
    processCost: 849.0,
    purchaseCost: 0.0,
    costPrice: 911.0,
    estimatedAmount: 0.0,
    totalCostPrice: 911.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
  {
    key: 5,
    no: 6,
    adopt: `自動`,
    image: <Image preview={true} width={120} height={77} src={iq3Img_pmx2_6} style={{ marginLeft: '0px' }}></Image>,
    partCnt: 1,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-06`,
    imgNo: `ISZ-HRK1-1300-06`,
    material: '01_SPCC',
    thickness: '3.2',
    materialNm: 'SPCC – 3.2',
    weight: 0.119,
    surfaceArea: 47,
    materialCost: 62.0,
    processCost: 825.0,
    purchaseCost: 0.0,
    costPrice: 887.0,
    estimatedAmount: 0.0,
    totalCostPrice: 887.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
  {
    key: 6,
    no: 7,
    adopt: `自動`,
    image: '',
    partCnt: 3,
    processNm: `標準`,
    partNm: `ISZ-HRK1-1300-07`,
    imgNo: `ISZ-HRK1-1300-07`,
    material: '',
    thickness: '',
    materialNm: '',
    weight: 0.0,
    surfaceArea: 0,
    materialCost: 0.0,
    processCost: 0.0,
    purchaseCost: 450.0,
    costPrice: 450.0,
    estimatedAmount: 0.0,
    totalCostPrice: 450.0,
    totalEstimatedAmount: 0.0,
    costProfit: 0.0,
    estimateProfit: 0.0,
    profitRate: '20.00',
  },
];

export const parentList = [
  {
    key: 0,
    id: 1,
    customerCode: '00002-001',
    customerNm: 'メタルテック株式会社',
    imgNo: 'YPH0946-A',
    name: 'YPH0946-A',
    lotNum: 1,
    grossProfit: Math.round((35625 + Number.EPSILON) * 100) / 100,
    rate: Math.round((41.6165929006473 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((75056 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((75056 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((9054 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((49977.875 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((14955 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((7949 + Number.EPSILON) * 100) / 100,
    purchaseCost: 0,
    managementCost: Math.round((15011 + Number.EPSILON) * 100) / 100,
    isOrder: '月産',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 3200,
    sizeY: 500,
    sizeZ: 20,
    childPartTotalWeight: Math.round((38.531 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((38.531 + Number.EPSILON) * 100) / 100,
    iq5Weight: 0,
    iq7Weight: 0,
    childPartTypeCnt: 9,
    iq3TypeCnt: 9,
    iq5TypeCnt: 0,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 25,
    iq3TotalProduceCnt: 25,
    iq5TotalProduceCnt: 0,
    iq7TotalProduceCnt: 0,
    surfaceArea: 7472,
    kumitate: Math.round((3516 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((3516 + Number.EPSILON) * 100) / 100,
    packing: Math.round((3516 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 0,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 1,
    id: 2,
    customerCode: '00002-001',
    customerNm: 'メタルテック株式会社',
    imgNo: 'ZFsample-iQx005',
    name: 'ZFsample-iQx005',
    lotNum: 1,
    grossProfit: Math.round((140275 + Number.EPSILON) * 100) / 100,
    rate: Math.round((38.7462538152389 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((268795 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((12119 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((221760 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((28416 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((67713 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((0 + Number.EPSILON) * 100) / 100,
    purchaseCost: 0,
    managementCost: Math.round((53759 + Number.EPSILON) * 100) / 100,
    isOrder: '月産',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    childPartTotalWeight: Math.round((53.347 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((23.758 + Number.EPSILON) * 100) / 100,
    iq5Weight: Math.round((29.589 + Number.EPSILON) * 100) / 100,
    iq7Weight: 0,
    childPartTypeCnt: 26,
    iq3TypeCnt: 14,
    iq5TypeCnt: 12,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 63,
    iq3TotalProduceCnt: 28,
    iq5TotalProduceCnt: 35,
    iq7TotalProduceCnt: 0,
    surfaceArea: 7472,
    kumitate: Math.round((3516 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((3516 + Number.EPSILON) * 100) / 100,
    packing: Math.round((3516 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 0,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 113304-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 2,
    id: 3,
    customerCode: '00002-000',
    customerNm: 'メタルテック株式会社',
    imgNo: 'SR6039C',
    name: 'SR6039C',
    lotNum: 1,
    grossProfit: Math.round((89617 + Number.EPSILON) * 100) / 100,
    rate: Math.round((30.43 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((279452 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((279452 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((75631 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((204929 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((20927 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((16546 + Number.EPSILON) * 100) / 100,
    purchaseCost: 0,
    managementCost: Math.round((55890 + Number.EPSILON) * 100) / 100,
    isOrder: '月産',
    deliveryType: '普通',
    img: '',
    allowGaichu: '非受注',
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    childPartTotalWeight: Math.round((317.79 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((261.17 + Number.EPSILON) * 100) / 100,
    iq5Weight: 56.62,
    iq7Weight: 0,
    childPartTypeCnt: 24,
    iq3TypeCnt: 11,
    iq5TypeCnt: 13,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 39,
    iq3TotalProduceCnt: 17,
    iq5TotalProduceCnt: 22,
    iq7TotalProduceCnt: 0,
    surfaceArea: 76730,
    kumitate: Math.round((5031 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((5031 + Number.EPSILON) * 100) / 100,
    packing: Math.round((5031 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 0,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 113333-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 3,
    id: 4,
    customerCode: '00002-001',
    customerNm: 'メタルテック株式会社',
    imgNo: 'YDE5069',
    name: 'YDE5069',
    lotNum: 1,
    grossProfit: Math.round((111953 + Number.EPSILON) * 100) / 100,
    rate: Math.round((36.01 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((275098 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((275098 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((60225 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((198958 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((42385 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((20874 + Number.EPSILON) * 100) / 100,
    purchaseCost: 0,
    managementCost: Math.round((55019 + Number.EPSILON) * 100) / 100,
    isOrder: '月産',
    deliveryType: '特急',
    img: '',
    allowGaichu: 1,
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    childPartTotalWeight: Math.round((114.587 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 0,
    iq7Weight: 0,
    childPartTypeCnt: 31,
    iq3TypeCnt: 28,
    iq5TypeCnt: 3,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 59,
    iq3TotalProduceCnt: 52,
    iq5TotalProduceCnt: 7,
    iq7TotalProduceCnt: 0,
    surfaceArea: 39661,
    kumitate: Math.round((11938 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((11938 + Number.EPSILON) * 100) / 100,
    packing: Math.round((11938 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 0,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/21(土)',
    estimateNo: '20230418 113359-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 4,
    id: 5,
    customerCode: '00003-000',
    customerNm: 'プレシジョン・メタル工業株式会社',
    imgNo: '3D図面②',
    name: 'Product-20220422 163325',
    lotNum: 1,
    grossProfit: Math.round((1881 + Number.EPSILON) * 100) / 100,
    rate: Math.round((19.09 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((9403 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((9403 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((872 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((7973 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((4908 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((1742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((1881 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: '承認待',
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    childPartTotalWeight: Math.round((6.419 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((6.419 + Number.EPSILON) * 100) / 100,
    iq5Weight: 0,
    iq7Weight: 0,
    childPartTypeCnt: 2,
    iq3TypeCnt: 2,
    iq5TypeCnt: 0,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 2,
    iq3TotalProduceCnt: 2,
    iq5TotalProduceCnt: 0,
    iq7TotalProduceCnt: 0,
    surfaceArea: 0,
    kumitate: Math.round((150 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((150 + Number.EPSILON) * 100) / 100,
    packing: Math.round((150 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 0,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/22(月)',
    estimateNo: '20220422 163325-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 5,
    id: 6,
    customerCode: '00003-000',
    customerNm: 'プレシジョン・メタル工業株式会社',
    imgNo: 'パーツ多めProduct-20221207 155917',
    name: 'Product-20221207 155917',
    lotNum: 1,
    grossProfit: Math.round((29538 + Number.EPSILON) * 100) / 100,
    rate: Math.round((18.32 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((147690 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((147690 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 6,
    id: 7,
    customerCode: '00003-000',
    customerNm: 'プレシジョン・メタル工業株式会社',
    imgNo: '写真Product-20220713 194925',
    name: 'Product-20220713 194925',
    lotNum: 1,
    grossProfit: Math.round((608 + Number.EPSILON) * 100) / 100,
    rate: Math.round((18.89 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((3038 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((3038 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 7,
    id: 8,
    customerCode: '00008-000',
    customerNm: 'アルパイン・メタル株式会社',
    imgNo: '製缶含むProduct-20230301 135157',
    name: 'Product-20230301 135157',
    lotNum: 1,
    grossProfit: Math.round((17830 + Number.EPSILON) * 100) / 100,
    rate: Math.round((16.61 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((89148 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((89148 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 8,
    id: 9,
    customerCode: '00009-000',
    customerNm: 'メタルハウス・テクノロジー有限会社',
    imgNo: 'Product-20220822 135655',
    name: 'Product-20220822 135655',
    lotNum: 1,
    grossProfit: Math.round((1019 + Number.EPSILON) * 100) / 100,
    rate: Math.round((19.31 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((5098 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((5098 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 9,
    id: 10,
    customerCode: '00010-000',
    customerNm: 'ノバメタル・ソリューションズ株式会社',
    imgNo: 'Product-20230322 110941-test',
    name: 'Product-20230322 110941',
    lotNum: 1,
    grossProfit: Math.round((32136 + Number.EPSILON) * 100) / 100,
    rate: Math.round((28.82 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((105864 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((105864 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 10,
    id: 11,
    customerCode: '00010-000',
    customerNm: 'ノバメタル・ソリューションズ株式会社',
    imgNo: 'ZFsample-iQx005',
    name: 'ZFsample-iQx005',
    lotNum: 1,
    grossProfit: Math.round((277142 + Number.EPSILON) * 100) / 100,
    rate: Math.round((54.8 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((414662 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((414662 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 11,
    id: 12,
    customerCode: '00010-000',
    customerNm: 'ノバメタル・ソリューションズ株式会社',
    imgNo: '見積確認図面⑤',
    name: 'Product-20220420 211700',
    lotNum: 1,
    grossProfit: Math.round((862 + Number.EPSILON) * 100) / 100,
    rate: Math.round((19.19 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((4312 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((4312 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 12,
    id: 13,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'Product-20230418 153526',
    name: 'Daice 50x50x50',
    lotNum: 100,
    grossProfit: Math.round((48442 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((2423 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((2423 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 13,
    id: 14,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'Product20230418 170020',
    name: '看板(石田板金製作所)',
    lotNum: 1,
    grossProfit: Math.round((66175 + Number.EPSILON) * 100) / 100,
    rate: Math.round((53.82 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((122959 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((122959 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 14,
    id: 15,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'Product20230418 1800抜き文字',
    name: '抜き文字',
    lotNum: 1,
    grossProfit: Math.round((70263 + Number.EPSILON) * 100) / 100,
    rate: Math.round((55.09 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((127541 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((127541 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 15,
    id: 16,
    customerCode: '00002-001',
    customerNm: 'メタルテック株式会社',
    imgNo: 'ZFsample-iQx002',
    name: 'ZFsample-iQx002',
    lotNum: 1,
    grossProfit: Math.round((43481 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((217406 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((217406 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 16,
    id: 17,
    customerCode: '00002-001',
    customerNm: 'メタルテック株式会社',
    imgNo: 'YDE5069',
    name: 'YDE5069',
    lotNum: 1,
    grossProfit: Math.round((22928 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((114641 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((114641 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 17,
    id: 18,
    customerCode: '00002-000',
    customerNm: 'メタルテック株式会社',
    imgNo: 'ZFsample-iQx002-1',
    name: 'ZFsample-iQx002-1',
    lotNum: 1,
    grossProfit: Math.round((29548 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((147739 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((147739 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 18,
    id: 19,
    customerCode: '00002-001',
    customerNm: 'メタルテック株式会社',
    imgNo: 'A63560ZZ-03C01',
    name: 'A63560ZZ-03C01',
    lotNum: 1,
    grossProfit: Math.round((2163 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((10816 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((10816 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 19,
    id: 20,
    customerCode: '00009-000',
    customerNm: 'メタルハウス・テクノロジー有限会社',
    imgNo: 'YDE5069-1',
    name: 'YDE5069-1',
    lotNum: 1,
    grossProfit: Math.round((109330 + Number.EPSILON) * 100) / 100,
    rate: Math.round((70.42 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((155255 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((155255 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 20,
    id: 21,
    customerCode: '00010-000',
    customerNm: 'ノバメタル・ソリューションズ株式会社',
    imgNo: 'ZFsample-iQx003',
    name: 'ZFsample-iQx003',
    lotNum: 1,
    grossProfit: Math.round((61939 + Number.EPSILON) * 100) / 100,
    rate: Math.round((70.13 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((88324 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((88324 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 21,
    id: 22,
    customerCode: '00010-000',
    customerNm: 'ノバメタル・ソリューションズ株式会社',
    imgNo: 'ZFsample-iQx003-1',
    name: 'ZFsample-iQx003-1',
    lotNum: 1,
    grossProfit: Math.round((206108 + Number.EPSILON) * 100) / 100,
    rate: Math.round((70.76 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((291276 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((291276 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 22,
    id: 23,
    customerCode: '00010-000',
    customerNm: 'ノバメタル・ソリューションズ株式会社',
    imgNo: 'ZFsample-iQx002-2',
    name: 'ZFsample-iQx002-2',
    lotNum: 1,
    grossProfit: Math.round((354152 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((177077 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((1770761 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 23,
    id: 24,
    customerCode: '00008-000',
    customerNm: 'アルパイン・メタル株式会社',
    imgNo: 'ZFsample-iQx002111',
    name: 'ZFsample-iQx002111',
    lotNum: 1,
    grossProfit: Math.round((133276 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((666380 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((666380 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 24,
    id: 25,
    customerCode: '00008-000',
    customerNm: 'アルパイン・メタル株式会社',
    imgNo: 'ZFsample-iQx002-0000',
    name: 'ZFsample-iQx002-0000',
    lotNum: 1,
    grossProfit: Math.round((11229 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 100) / 100,
    unitPrice: Math.round((56143 + Number.EPSILON) * 100) / 100,
    estimatedAmount: Math.round((56143 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: 1200,
    sizeY: 150,
    sizeZ: 150,
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 100) / 100,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    surfaceArea: 3598,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: [],
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
  {
    key: 25,
    id: 26,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'Product-20230419 142814',
    name: '看板(完成)_石田板金製作所',
    lotNum: 1,
    grossProfit: Math.round((43821 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 1000) / 1000,
    unitPrice: Math.round((219107.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((219107 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: '003',
    sizeX: '1200.0',
    sizeY: '150.0',
    sizeZ: '150.0',
    childPartTotalWeight: Math.round((15.648 + Number.EPSILON) * 1000) / 1000,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MEX金沢用サンプルデータ\n開催期間：2023/5/18(木)~5/20(土)',
    estimateNo: '20230418 110550-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/18',
    costLst: costLst,
    dateLst: dateLst,
    labelLst: labelLst,
    memoLst: memoLst,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: customerCoefficient[0].purchaseCoefficient,
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((866.458 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess,
    iq3Process: iq3Process,
    iq5Process: iq5Process,
    iq3MaterialAmount: Math.round((2729 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: Math.round((204 + Number.EPSILON) * 100) / 100,
    iq3DataLst: iq3Data,
    volume: Math.round((2700.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((181653.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((227067.0 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((45413.0 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((2934 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((157321 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((2800 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
    kouteiCalInfoData: JSON.parse(JSON.stringify(parentKouteiCalInfo)),
    kouteiCalInfoEditData: JSON.parse(JSON.stringify(parentKouteiCalInfo)),
    kouteiSentakuPatternNo: 3,
  },
  {
    key: 26,
    id: 27,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'zerofour-02',
    name: 'zerofour-02',
    lotNum: 1,
    grossProfit: Math.round((43821 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 1000) / 1000,
    unitPrice: Math.round((219107.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((219107 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: '005',
    sizeX: '700.0',
    sizeY: '400.0',
    sizeZ: '105.0',
    childPartTotalWeight: Math.round((8.681 + Number.EPSILON) * 1000) / 1000,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MF-TOKYO2023用サンプルデータ\n開催期間：2023/7/12(水)~7/15(土)',
    estimateNo: '20230424 094153-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/24',
    updatedDate: '2023/04/24',
    costLst: costLst_PMX_1,
    dateLst: dateLst_PMX_1,
    labelLst: labelLst_PMX_1,
    memoLst: memoLst_PMX_1,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: '1.00',
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '通常',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((47777.788 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess_PMX_1,
    iq3Process: iq3Process_PMX_1,
    iq5Process: null,
    iq3MaterialAmount: Math.round((1410 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: 0,
    iq3DataLst: iq3Data_PMX_1,
    volume: Math.round((29400.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((20010 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((20010 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((25012 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((25012 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((5002 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((5002 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((1410 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((13622 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((1500 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
];
export const parentList_PMX_1 = [
  {
    key: 0,
    id: 1,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'zerofour-02',
    name: 'zerofour-02',
    lotNum: 1,
    grossProfit: Math.round((43821 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 1000) / 1000,
    unitPrice: Math.round((219107.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((219107 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: '700.0',
    sizeY: '400.0',
    sizeZ: '105.0',
    childPartTotalWeight: Math.round((8.681 + Number.EPSILON) * 1000) / 1000,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 1.328,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MF-TOKYO2023用サンプルデータ\n開催期間：2023/7/12(水)~7/15(土)',
    estimateNo: '20230424 094153-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/24',
    updatedDate: '2023/04/24',
    costLst: costLst_PMX_1,
    dateLst: dateLst_PMX_1,
    labelLst: labelLst_PMX_1,
    memoLst: memoLst_PMX_1,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: '1.00',
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '通常',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((47777.788 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess_PMX_1,
    iq3Process: iq3Process_PMX_1,
    iq5Process: null,
    iq3MaterialAmount: Math.round((1410 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: 0,
    iq3DataLst: iq3Data_PMX_1,
    volume: Math.round((29400.0 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((20010 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((20010 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((25012 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((25012 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((5002 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((5002 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((1410 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((13622 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((1500 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
];
export const parentList_PMX_2 = [
  {
    key: 0,
    id: 1,
    customerCode: '00012-000',
    customerNm: '株式会社石田板金製作所',
    imgNo: 'zerofour-03',
    name: 'zerofour-03',
    lotNum: 1,
    grossProfit: Math.round((43821 + Number.EPSILON) * 100) / 100,
    rate: Math.round((20.0 + Number.EPSILON) * 1000) / 1000,
    unitPrice: Math.round((219107.0 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((219107 + Number.EPSILON) * 100) / 100,
    materialAmount: Math.round((2934 + Number.EPSILON) * 100) / 100,
    matufacturingAmount: Math.round((175285 + Number.EPSILON) * 100) / 100,
    setupAmount: Math.round((70012 + Number.EPSILON) * 100) / 100,
    processingAmount: Math.round((83742 + Number.EPSILON) * 100) / 100,
    purchaseCost: 2800,
    managementCost: Math.round((43821 + Number.EPSILON) * 100) / 100,
    isOrder: 'ロット',
    deliveryType: '普通',
    img: '',
    allowGaichu: 1,
    sizeX: '950.5',
    sizeY: '120.0',
    sizeZ: '115.0',
    childPartTotalWeight: Math.round((6.909 + Number.EPSILON) * 1000) / 1000,
    iq3Weight: Math.round((14.32 + Number.EPSILON) * 100) / 100,
    iq5Weight: 0,
    iq7Weight: 0,
    childPartTypeCnt: 6,
    iq3TypeCnt: 5,
    iq5TypeCnt: 1,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 10,
    iq3TotalProduceCnt: 9,
    iq5TotalProduceCnt: 1,
    iq7TotalProduceCnt: 0,
    kumitate: Math.round((2800 + Number.EPSILON) * 100) / 100,
    inspection: Math.round((2160 + Number.EPSILON) * 100) / 100,
    packing: Math.round((2160 + Number.EPSILON) * 100) / 100,
    temporaryAttachment: 5000,
    outsourcing: 0,
    polishing: 0,
    comment: 'MF-TOKYO2023用サンプルデータ\n開催期間：2023/7/12(水)~7/15(土)',
    estimateNo: '20230424 095739-0001',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: '2023/04/18',
    updatedDate: '2023/04/25',
    costLst: costLst_PMX_2,
    dateLst: dateLst_PMX_2,
    labelLst: labelLst_PMX_2,
    memoLst: memoLst_PMX_2,
    customerCoefficient: customerCoefficient[0].customerCoefficient,
    managementCoefficient: customerCoefficient[0].managementCoefficient,
    materialLossCoefficient: customerCoefficient[0].materialLossCoefficient,
    orderCoefficient: customerCoefficient[0].orderForm,
    setupCoefficient: customerCoefficient[0].setupCoefficient,
    surfaceAreaCoefficient: customerCoefficient[0].surfaceAreaCoefficient,
    purchaseCoefficient: '1.00',
    deliveryCoefficient: customerCoefficient[0].deliveryCoefficient,
    orderCoefficient: customerCoefficient[0].orderCoefficient,
    shippingMethod: '一般配送',
    shippingArea: '関東',
    weight: 0,
    surfaceArea: Math.round((3530.95 + Number.EPSILON) * 1000) / 1000,
    parentProcess: parentProcess_PMX_2,
    iq3Process: iq3Process_PMX_2,
    iq5Process: null,
    iq3MaterialAmount: Math.round((3328 + Number.EPSILON) * 100) / 100,
    iq5MaterialAmount: 0,
    iq3DataLst: iq3Data_PMX_2,
    volume: Math.round((0.013116 + Number.EPSILON) * 1000) / 1000,
    costPrice: Math.round((28918 + Number.EPSILON) * 1000) / 1000,
    totalCostPrice: Math.round((28918 + Number.EPSILON) * 1000) / 1000,
    estimatedAmount: Math.round((36147 + Number.EPSILON) * 1000) / 1000,
    totalEstimatedAmount: Math.round((36147 + Number.EPSILON) * 1000) / 1000,
    costProfit: Math.round((7229 + Number.EPSILON) * 1000) / 1000,
    estimateProfit: Math.round((7229 + Number.EPSILON) * 1000) / 1000,
    materialCost: Math.round((3328 + Number.EPSILON) * 1000) / 1000,
    processCost: Math.round((22138 + Number.EPSILON) * 1000) / 1000,
    purchaseCost: Math.round((450 + Number.EPSILON) * 1000) / 1000,
    hiyou: Math.round((3000 + Number.EPSILON) * 1000) / 1000,
  },
];

// parameter
export const paramCommonPurchase = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `00001`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3× 6`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 10,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    isAllocation: true,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 10, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 2,
    id: 2,
    no: 2,
    isAllocation: true,
    code: `00002`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3× 8`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 10,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 10, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 3,
    id: 3,
    no: 3,
    isAllocation: true,
    code: `00003`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3×10`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 10,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 10, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 4,
    id: 4,
    no: 4,
    isAllocation: true,
    code: `00004`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3×12`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 5,
    id: 5,
    no: 5,
    isAllocation: true,
    code: `00005`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3×15`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 6,
    id: 6,
    no: 6,
    isAllocation: true,
    code: `00006`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×18`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 7,
    id: 7,
    no: 7,
    isAllocation: true,
    code: `00007`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×20`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 8,
    id: 8,
    no: 8,
    code: `00008`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×25`,
    makerName: null,
    supplier: null,
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：50個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 9,
    id: 9,
    no: 9,
    code: `00009`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×30`,
    makerName: null,
    supplier: null,
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：50個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 10,
    id: 10,
    no: 10,
    code: `00010`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×35`,
    makerName: null,
    supplier: null,
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：50個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 11,
    id: 11,
    no: 11,
    code: `00011`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×  6`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 12,
    id: 12,
    no: 12,
    code: `00012`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×  8`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 13,
    id: 13,
    no: 13,
    code: `00013`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×10`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 14,
    id: 14,
    no: 14,
    code: `00014`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×12`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 15,
    id: 15,
    no: 15,
    code: `00015`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×15`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 16,
    id: 16,
    no: 16,
    code: `00016`,
    purType: 4,
    name: `オネジスタッド`,
    model: `OST-M4×20`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 17,
    id: 17,
    no: 17,
    code: `00017`,
    purType: 4,
    name: `オネジスタッド`,
    model: `OST-M4×25`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 18,
    id: 18,
    no: 18,
    code: '00018',
    purType: 4,
    name: 'オネジスタッド',
    model: 'OST-M4×30',
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: '購入数量：50個',
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 19,
    id: 19,
    no: 19,
    code: '00019',
    purType: 4,
    name: 'オネジスタッド',
    model: 'OST-M4×35',
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 20,
    remark: '購入数量：50個',
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 20, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 20,
    id: 20,
    no: 20,
    code: '00020',
    purType: 5,
    name: 'オネジスタッド',
    model: 'OST-M4×40',
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 20,
    remark: '購入数量：50個',
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 20, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
];

export const paramCommonPurchaseType = [
  { no: 1, used: 1, name: 'スタッド' },
  { no: 2, used: 1, name: 'ナット' },
  { no: 3, used: 1, name: 'スペーサー' },
  { no: 4, used: 1, name: 'ソケット' },
  { no: 5, used: 1, name: 'その他' },
];

export const paramCommonOrderCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `試作`,
    coefficient: `2`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `単発`,
    coefficient: `1.5`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `ロット`,
    coefficient: `1`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    code: `004`,
    name: `月産`,
    coefficient: `0.9`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 5,
    id: 5,
    no: 5,
    code: `005`,
    name: `その他`,
    coefficient: `0.8`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 6,
    id: 6,
    no: 6,
    code: `005`,
    name: `大口(年間契約)`,
    coefficient: `0.7`,
    remarks: ``,
    sortNo: 0,
    display: 1,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
];

export const paramCommonDeliTimeCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `特急`,
    coefficient: `2.0`,
    estimateDays: `~2`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `急行`,
    coefficient: `1.2`,
    estimateDays: `~4`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `普通`,
    coefficient: `1.0`,
    estimateDays: `~9`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    code: `004`,
    name: `鈍行`,
    coefficient: `0.9`,
    estimateDays: `~29`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 5,
    id: 5,
    no: 5,
    code: `005`,
    name: `寝台`,
    coefficient: `1.1`,
    estimateDays: `30~`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
];

export const paramCommonPurchaseCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `普通`,
    coefficient: `1.00`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `高コスト`,
    coefficient: `1.25`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `低コスト`,
    coefficient: `0.8`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
];

export const paramCommonMaintainCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `普通`,
    coefficient: `1.00`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `特殊管理`,
    coefficient: `1.75`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
];

export const paramCommonCost = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `諸経費`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `特別費`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    data: 5000,
    isUsedTanka: true,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `調査費`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    data: 5000,
    isUsedTanka: true,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    display: 1,
    code: `004`,
    name: `カスタマイズ費用`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    data: 5000,
    isUsedTanka: true,
  },
];

export const paramCommonLabel = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `製造部管理ラベル`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `社内仕分けラベル`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `カスタマイズラベル`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
];

export const paramCommonMemo = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `注意事項`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `見積備考`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `コメント`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    display: 1,
    code: `004`,
    name: `カスタマイズメモ`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
];

export const paramCommonDate = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `社内納期`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `回答日`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `カスタマイズ日付`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
];

export const paramCommonProjectStatus = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `無効`,
    remark: ``,
    sortNo: 10,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `保留`,
    remark: ``,
    sortNo: 9,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `非受注`,
    remark: ``,
    sortNo: 8,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    code: `004`,
    name: `受注値引き`,
    remark: ``,
    sortNo: 7,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 5,
    id: 5,
    no: 5,
    code: `005`,
    name: `受注`,
    remark: ``,
    sortNo: 6,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 6,
    id: 6,
    no: 6,
    code: `006`,
    name: `未回答`,
    remark: ``,
    sortNo: 5,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 7,
    id: 7,
    no: 7,
    code: `007`,
    name: `承認済`,
    remark: ``,
    sortNo: 4,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 8,
    id: 8,
    no: 8,
    code: `008`,
    name: `承認待`,
    remark: ``,
    sortNo: 3,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 9,
    id: 9,
    no: 9,
    code: `009`,
    name: `再見積指示`,
    remark: ``,
    sortNo: 2,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 10,
    id: 10,
    no: 10,
    code: `010`,
    name: `作成中`,
    remark: ``,
    sortNo: 1,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
];

const yosetsuDetailType1 = [
  'アルゴン',
  '半自動',
  '溶接線01',
  '溶接線02',
  '溶接線03',
  'YAGハンディ',
  'YAGロボット',
  'ロウ付',
  '開先取り',
  'Vカット',
];

const yosetsuDetailType2 = ['点付', 'プラグ溶接', '定置スポット', 'テーブルスポット'];

const yosetsuDetailType3 = ['手動スタッド', '自動スタッド', 'ナット溶接', 'ボルト溶接'];

const yosetsuFinishDetailType = ['バフ', '酸洗い', 'サンダー'];

const hyomenDetailType1 = [
  'ユニクロメッキ',
  'クロメート',
  'ニッケルメッキ',
  'クロームメッキ',
  '亜鉛メッキ',
  'メッキ01',
  'メッキ02',
  'メッキ03',
];

const hyomenDetailType2 = [
  'メラニン塗装',
  'アクリル塗装',
  'ウレタン塗装',
  '粉体塗装',
  'カチオン塗装',
  'プライマー',
  '塗装01',
  '塗装02',
];

const hyomenDetailType3 = ['脱脂'];

const blank = ['レーザー', 'パンチ', '複合機'];

const bending = [
  '曲げ_V',
  '曲げ_R',
  '曲げ_FR',
  '曲げ_3R',
  '曲げ_HM',
  '曲げ_Z',
  '曲げ_AMA',
  '曲げ_01',
  '曲げ_02',
  '曲げ_03',
];

const bari = ['手動', '自動'];

const kumitateDetailType = ['購入品取付時間', '重量'];

const kensaKonpoDetailType = ['子部品点数', '子部品種類数', '重量'];

const nijikako = ['バーリング', 'タップ', 'サラ', 'カシメ', 'ボルト圧入', 'ナット圧入', '成形型穴', '多工程穴'];

const iq3yosetsuDetailType2 = [
  '点付',
  'プラグ溶接',
  '定置スポット',
  'テーブルスポット',
  '手動スタッド',
  '自動スタッド',
  'ナット溶接',
  'ボルト溶接',
  '取付01',
  '取付02',
];

const iq3YosetsuFinish = ['バフ', '酸洗い', 'サンダー', 'Vカット', '開先'];

const additionalDatakouteiMaster = ['仮付', 'ネスティング', '水張検査'];

const additionalDatakouteiMaster_iq3 = ['仮付', 'ネスティング', '水張検査'];

const releaseDate = '2023-10-01';

export const detailArrCreate = (arrDetailType) => {
  let detailArr = [];
  arrDetailType.forEach((element, index) => {
    let detailValue = {
      key: index,
      processDetailNo: index + 1,
      displayFlg: true,
      detailCode: (index + 1).toString().padStart(3, '0'),
      detailType: arrDetailType[index],
      // detailGroup: index < 6 ? '追加工程グループ①' : index < 12 ? '追加工程グループ②' : '追加工程グループ③',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      useFlg: false,
    };
    detailArr.push(detailValue);
  });
  return detailArr;
};

export const kouteiMasterData = [
  {
    key: 0,
    No: 1,
    display: true,
    type: '親部品',
    category: '標準',
    code: '001',
    processName: '溶接',
    processType: '溶接',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: detailArrCreate(yosetsuDetailType1),
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType2),
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType3),
        defaultRow: 5,
      },
    ],
  },
  {
    key: 1,
    No: 2,
    display: true,
    type: '親部品',
    category: '標準',
    code: '002',
    processName: '溶接仕上',
    processType: '溶接仕上',
    remark: '',
    created: releaseDate,
    modified: releaseDate,
    numberOfProcess: 10,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: detailArrCreate(yosetsuFinishDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 2,
    No: 3,
    display: true,
    type: '親部品',
    category: '標準',
    code: '003',
    processName: '表面処理',
    processType: '表面処理',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    numberOfProcess: 6,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: detailArrCreate(hyomenDetailType3),
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: detailArrCreate(hyomenDetailType1),
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: detailArrCreate(hyomenDetailType2),
        defaultRow: 8,
      },
    ],
  },
  {
    key: 3,
    No: 4,
    display: true,
    type: '親部品',
    category: '標準',
    code: '004',
    processName: '組立',
    processType: '組立',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kumitateDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 4,
    No: 5,
    display: true,
    type: '親部品',
    category: '標準',
    code: '005',
    processName: '検査',
    processType: '検査',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kensaKonpoDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 5,
    No: 6,
    display: true,
    type: '親部品',
    category: '標準',
    code: '006',
    processName: '梱包',
    processType: '梱包',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kensaKonpoDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 6,
    No: 7,
    display: true,
    type: '親部品',
    category: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    created: '2023-10-01',
    modified: '2023-07-06',
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            processDetailNo: 1,
            displayFlg: true,
            detailCode: '001',
            detailType: '仮付',
            created: '2023-10-01',
            modified: '2023-10-01',
            remark: '',
            useFlg: false,
          },
          {
            key: 1,
            processDetailNo: 2,
            displayFlg: true,
            detailCode: '002',
            detailType: 'ネスティング',
            created: '2023-10-01',
            modified: '2023-10-01',
            remark: '',
            useFlg: false,
          },
          {
            key: 2,
            processDetailNo: 3,
            displayFlg: true,
            detailCode: '003',
            detailType: '水張検査',
            created: '2023-10-01',
            modified: '2023-10-01',
            remark: '',
            useFlg: false,
          },
          {
            key: 4,
            processDetailNo: 4,
            displayFlg: true,
            detailCode: '004',
            detailType: '社内工程_4',
            created: '2023-07-06',
            modified: '2023-07-06',
            remark: '',
          },
          {
            key: 5,
            processDetailNo: 5,
            displayFlg: true,
            detailCode: '005',
            detailType: '社内工程_5',
            created: '2023-07-06',
            modified: '2023-07-06',
            remark: '',
          },
        ],
        defaultRow: 0,
      },
    ],
  },
  {
    key: 7,
    No: 8,
    display: true,
    type: '親部品',
    category: '追加',
    code: '008',
    processName: '追加工程②',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 8,
    No: 9,
    display: true,
    type: '親部品',
    category: '追加',
    code: '009',
    processName: '追加工程③',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 9,
    No: 10,
    display: true,
    type: '親部品',
    category: '追加',
    code: '010',
    processName: '追加工程④',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 10,
    No: 11,
    display: true,
    type: '親部品',
    category: '追加',
    code: '011',
    processName: '追加工程⑤',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 11,
    No: 12,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '012',
    processName: 'プログラム',
    processType: 'プログラム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [],
  },
  {
    key: 12,
    No: 13,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: detailArrCreate(blank),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 13,
    No: 14,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '014',
    processName: 'ベンディング',
    processType: 'ベンディング',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '曲げ種類',
        processDetailValue: detailArrCreate(bending),
        defaultRow: 10,
      },
    ],
  },
  {
    key: 14,
    No: 15,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '015',
    processName: 'バリ取',
    processType: 'バリ取',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: detailArrCreate(bari),
        defaultRow: 2,
      },
    ],
  },
  {
    key: 15,
    No: 16,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '016',
    processName: '表面処理',
    processType: '表面処理',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    numberOfProcess: 4,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: detailArrCreate(hyomenDetailType3),
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: detailArrCreate(hyomenDetailType1),
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: detailArrCreate(hyomenDetailType2),
        defaultRow: 8,
      },
    ],
  },
  {
    key: 16,
    No: 17,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '017',
    processName: '2次加工',
    processType: '2次加工',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '2次加工種類',
        processDetailValue: detailArrCreate(nijikako),
        defaultRow: 8,
      },
    ],
  },
  {
    key: 17,
    No: 18,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '018',
    processName: '溶接',
    processType: '溶接',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: detailArrCreate(yosetsuDetailType1),
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType2),
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType3),
        defaultRow: 5,
      },
    ],
  },
  {
    key: 18,
    No: 19,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '019',
    processName: '溶接仕上',
    processType: '溶接仕上',
    remark: '',
    created: releaseDate,
    modified: releaseDate,
    numberOfProcess: 4,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: detailArrCreate(iq3YosetsuFinish),
        defaultRow: 5,
      },
    ],
  },
  {
    key: 19,
    No: 20,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '020',
    processName: 'シャーリング',
    processType: 'シャーリング',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [],
  },
  {
    key: 20,
    No: 21,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '021',
    processName: '検査',
    processType: '検査',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kensaKonpoDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 21,
    No: 22,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '022',
    processName: '追加工程①',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 22,
    No: 23,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '023',
    processName: '追加工程②',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 23,
    No: 24,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '024',
    processName: '追加工程③',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 24,
    No: 25,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '025',
    processName: '追加工程④',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 25,
    No: 26,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '026',
    processName: '追加工程⑤',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
];

export const ItakuSaki = '委託先';
export const Supplier = '仕入先';
export const Customer = 'お客様';

export const purchaseCategoryDefaultValue = '種別を選んでください。';

export const sameSizePatternValue = [
  { label: '4個', value: 4 },
  { label: '5個以上', value: 5 },
];
export const IQ3MaterialDetailData = [
  {
    id: 1,
    size: 1,
    quantity: 165,
    price: 13671,
    yield: 43.9,
    pattern1: 43.9,
    p1PlacementNum: 1,
    pattern2: 0,
    p2PlacementNum: 0,
    maxYield: 43.9,
    maxProcessNum: 1,
  },
  {
    id: 2,
    size: 2,
    quantity: 3,
    price: 9112,
    yield: 65.8,
    pattern1: 67.0,
    p1PlacementNum: 56,
    pattern2: 63.4,
    p2PlacementNum: 53,
    maxYield: 67.0,
    maxProcessNum: 56,
  },
  {
    id: 3,
    size: 3,
    quantity: 2,
    price: 10800,
    yield: 55.5,
    pattern1: 74.0,
    p1PlacementNum: 110,
    pattern2: 37.0,
    p2PlacementNum: 55,
    maxYield: 74.0,
    maxProcessNum: 110,
  },
  {
    id: 4,
    size: 4,
    quantity: 1,
    price: 8440,
    yield: 71.0,
    pattern1: 71.0,
    p1PlacementNum: 165,
    pattern2: 0,
    p2PlacementNum: 0,
    maxYield: 78.4,
    maxProcessNum: 182,
  },
  {
    id: 5,
    size: 5,
    quantity: 3,
    price: 10902,
    yield: 55.0,
    pattern1: 72.0,
    p1PlacementNum: 72,
    pattern2: 21.0,
    p2PlacementNum: 21,
    maxYield: 21.0,
    maxProcessNum: 72,
  },
  {
    id: 6,
    size: 6,
    quantity: 1,
    price: 14536,
    yield: 41.3,
    pattern1: 41.3,
    p1PlacementNum: 165,
    pattern2: 0,
    p2PlacementNum: 0,
    maxYield: 76.5,
    maxProcessNum: 306,
  },
  {
    id: 7,
    size: 7,
    quantity: 2,
    price: 11356,
    yield: 52.8,
    pattern1: 70.4,
    p1PlacementNum: 110,
    pattern2: 35.2,
    p2PlacementNum: 55,
    maxYield: 70.4,
    maxProcessNum: 1104,
  },
];

export const iq3InitData = {
  no: 1,
  adopt: `自動`,
  image: null,
  partCnt: 0,
  processNm: ``,
  partNm: ``,
  imgNo: ``,
  material: '',
  thickness: '',
  materialNm: '',
  weight: 0,
  surfaceArea: 0,
  materialCost: 0,
  processCost: 0,
  purchaseCost: 0,
  costPrice: 0,
  estimatedAmount: 0,
  totalCostPrice: 0,
  totalEstimatedAmount: 0,
  costProfit: 0.0,
  estimateProfit: 0.0,
  profitRate: '0',
  iq3KouteiSentakuPatternNo: 0,
  iq3KouteiInputData: [],
  iq3KouteiCalInfoData: [],
  iq3KouteiCalInfoEditData: [],
  iq3PurchaseData: [],
};
