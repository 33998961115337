/**
 * クラス名：iq3の装置設定の曲げ時間係数画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Input, Button, Space } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';

import { commonModal } from '../../../common/CommonModal';

const Device_BendingCorrectionTimeFactor = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [isArea, setIsArea] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [category, setCategory] = useState('');

  const [weightData, setWeightData] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);
  // 曲げ補正時間係数リスト
  const [detailsList, setDetailsList] = useState([]);
  // 補正時間係数リスト
  const [correctionTimeFactorList, setcorrectionTimeFactorList] = useState([]);

  // 製品サイズ係数
  const [seihinSizeList, setSeihinSizeList] = useState([]);

  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);
  // 加工時間
  const [kakoujikanList, setKakoujikanList] = useState([]);
  useEffect(() => {
    setEditMode(props.editMode);
    setWeightData(props.weight);
    setLengthData(props.length);
    setAreaData(props.area);
    setDetailsAreaTemp(props.area);
    setDetailsLengthTemp(props.length);
    setDetailsWeightTemp(props.weight);
    setDetailsList(props.bendingTimeFactorLst);

    setSeihinSizeList(props.iq3SeihinSizeLst);

    setCategory(props.processName);
    setcorrectionTimeFactorList(props.correctionTimeFactorListData);
    setMaterialHandlingTimeList(props.materialHandlingTimeList);

    setKakoujikanList(props.listData);
  }, [
    props.weight,
    props.length,
    props.area,
    props.editMode,
    props.bendingTimeFactorLst,
    props.iq3SeihinSizeLst,
    props.processName,
    props.correctionTimeFactorListData,
    props.materialHandlingTimeList,
    props.listData,
  ]);
  const addLength = () => {
    setIsLength(true);
  };
  const addWeight = () => {
    setIsWeight(true);
  };
  const addArea = () => {
    setIsArea(true);
  };
  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>曲長</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );
  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const addLengthRow = (id) => {
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsLengthTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e) => {
    if (detailsLengthTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsLengthTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
    }
  };
  const deleteWeight = (id, e) => {
    if (detailsWeightTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsWeightTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
    }
  };
  const addWeightRow = (id) => {
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsWeightTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };
  const closeAddModal = () => {
    if (isLength === true) {
      setIsLength(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isArea === true) {
      setIsArea(false);
    }
  };
  const handleLengthChange = (event, id, field) => {
    const updatedData = detailsLengthTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = detailsWeightTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };
  const cancelLengthMaster = () => {
    setDetailsLengthTemp(lengthData);
    setIsLength(false);
  };
  const addLengthMaster = () => {
    const deletedId = [];

    lengthData.forEach((item1, index) => {
      const found = detailsLengthTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsLengthTemp
      .filter((item) => item.displayFlag && !lengthData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = detailsList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsLengthTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsLengthTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsLength(false);
    setDetailsLengthTemp(detailsLengthTemp);

    setDetailsList(sortedList);
    props.updatedLength(detailsLengthTemp);

    props.updatedSizesList(sortedList);
  };
  const cancelWeightMaster = () => {
    setDetailsWeightTemp(weightData);
    setIsWeight(false);
  };
  const addWeightMaster = () => {
    const deletedId = [];

    weightData.forEach((item1, index) => {
      const found = detailsWeightTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsWeightTemp
      .filter((item) => item.displayFlag && !weightData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = detailsList.filter((it) => !deletedId.includes(it.key));
    const deletedData2 = seihinSizeList.filter((it) => !deletedId.includes(it.key));
    const deletedData3 = correctionTimeFactorList.filter((it) => !deletedId.includes(it.key));
    const deletedData4 = materialHandlingTimeList.filter((it) => !deletedId.includes(it.key));
    const result = deletedData.map((item) => {
      const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });
    const result2 = deletedData2.map((item) => {
      const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });
    const result3 = deletedData3.map((item) => {
      const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });
    const result4 = deletedData4.map((item) => {
      const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });
    newId.forEach((id) => {
      const list = detailsWeightTemp.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };
        const newObj2 = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result2[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };
        const newObj3 = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result3[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };
        const newObj4 = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result4[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result.push(newObj);
        result2.push(newObj2);
        result3.push(newObj3);
        result4.push(newObj4);
      }
    });
    result.sort((a, b) => {
      const aIndex = detailsWeightTemp.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    result2.sort((a, b) => {
      const aIndex = detailsWeightTemp.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    result3.sort((a, b) => {
      const aIndex = detailsWeightTemp.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    result4.sort((a, b) => {
      const aIndex = detailsWeightTemp.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    setDetailsList(result);
    props.updatedWeight(detailsWeightTemp);

    props.updatedSizesList(result);
    setSeihinSizeList(result2);
    props.updatediq3ProductSizesList(result2);

    setcorrectionTimeFactorList(result3);
    props.updatedcorrectionTimeFactorListData(result3);

    setMaterialHandlingTimeList(result4);
    props.updateMaterialHandlingList(result4);
  };
  const handleAreaChange = (event, id, field) => {
    const updatedData = detailsAreaTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };
  const addAreaRow = (id) => {
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsAreaTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };
  const deleteArea = (id, e) => {
    if (detailsAreaTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsAreaTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
    }
  };
  const addAreaMaster = () => {
    const deletedId = [];

    areaData.forEach((item1, index) => {
      const found = detailsAreaTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsAreaTemp
      .filter((item) => item.displayFlag && !areaData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = correctionTimeFactorList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const deleteData2 = seihinSizeList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const deleteData3 = materialHandlingTimeList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const deleteData4 = kakoujikanList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedData2 = deleteData2.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedData3 = deleteData3.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedData4 = deleteData4.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updatedData2.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });
    const sortedList3 = updatedData3.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });
    const sortedList4 = updatedData4.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });
    setIsArea(false);
    setDetailsAreaTemp(detailsAreaTemp);

    setcorrectionTimeFactorList(sortedList);
    props.updatedArea(detailsAreaTemp);

    props.updatedcorrectionTimeFactorListData(sortedList);

    setSeihinSizeList(sortedList2);
    props.updatediq3ProductSizesList(sortedList2);

    setMaterialHandlingTimeList(sortedList3);
    props.updateMaterialHandlingList(sortedList3);

    setKakoujikanList(sortedList4);
    props.updatedIQ3KakouJikanList(sortedList4);
  };
  const cancelAreaMaster = () => {
    setDetailsAreaTemp(areaData);
    setIsArea(false);
  };
  const addAreaContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleAreaChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelAreaMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleLengthChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addLengthMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelLengthMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleWeightChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const listDataChange = (event, rowIndex, colIndex, tblName) => {
    if (tblName === 'magechou') {
      const copyData = [...detailsList];
      const updatedData = JSON.parse(JSON.stringify(copyData));
      updatedData[rowIndex].lstData[colIndex].value = event.target.value;
      setDetailsList(updatedData);
      props.updatedSizesList(updatedData);
    } else {
      const copyData = [...correctionTimeFactorList];
      const updatedData = JSON.parse(JSON.stringify(copyData));
      updatedData[rowIndex].lstData[colIndex].value = event.target.value;
      setcorrectionTimeFactorList(updatedData);
      props.updatedcorrectionTimeFactorListData(updatedData);
    }
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        {category === 'ベンディング' ? (
          <>
            <Table
              style={{
                width:
                  lengthData.filter((i) => i.displayFlag === true).length > 8
                    ? '100%'
                    : lengthData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
              }}
            >
              <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                <td style={{ width: '196px' }} className="fixedCol">
                  <Row justify="center" className="tbl-header">
                    <Space>
                      {editMode ? (
                        <>
                          <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                            重量
                          </Button>
                          /
                          <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                            曲長
                          </Button>
                        </>
                      ) : (
                        <>
                          <label>重量 / 曲長</label>
                        </>
                      )}
                    </Space>
                  </Row>
                </td>

                {lengthData
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item) => (
                    <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}mm</td>
                  ))}
              </tr>

              {detailsList.map((row, rowIndex) => (
                <tr style={{ textAlign: 'center' }}>
                  <td className="fixedCol" style={{ backgroundColor: '#1063aa', color: 'white', width: '196px' }}>
                    ～{row.max}kg
                  </td>

                  {row.lstData.map((col, colIndex) => (
                    <td style={{ width: '98px' }}>
                      <Input
                        value={col.value}
                        onChange={(e) => listDataChange(e, rowIndex, colIndex, 'magechou')}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </Table>
          </>
        ) : (
          <>
            <Table
              style={{
                width:
                  areaData.filter((i) => i.displayFlag === true).length > 8
                    ? '100%'
                    : areaData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
              }}
            >
              <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                <td style={{ width: '196px' }} className="fixedCol">
                  <Row justify="center" className="tbl-header">
                    <Space>
                      {editMode ? (
                        <>
                          <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                            重量
                          </Button>
                          /
                          <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                            面積
                          </Button>
                        </>
                      ) : (
                        <>
                          <label>重量 / 面積</label>
                        </>
                      )}
                    </Space>
                  </Row>
                </td>

                {areaData
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item) => (
                    <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                      ～{item.max}cm<sup className="tbl-header">2</sup>
                    </td>
                  ))}
              </tr>

              {correctionTimeFactorList.map((row, rowIndex) => (
                <tr style={{ textAlign: 'center' }}>
                  <td style={{ color: 'white', width: '196px' }} className="fixedCol">
                    ～{row.max}kg
                  </td>

                  {row.lstData.map((col, colIndex) => (
                    <td style={{ width: '98px' }}>
                      <Input
                        value={col.value}
                        onChange={(e) => listDataChange(e, rowIndex, colIndex, 'menseki')}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </Table>
          </>
        )}
      </div>
      {/* 面積 */}
      {isArea
        ? commonModal(
            isArea,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAreaContent,
            null,
            null,
            true
          )
        : ''}
      {/* 曲長 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_BendingCorrectionTimeFactor;
