/**
 * クラス名：板金の装置設定のジョイント画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space } from 'antd';
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { commonModal } from '../../../common/CommonModal';
import { deleteListColData, updateListColData, sortListData } from '../../../common/Common';

const Device_Blank_Joint = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isItaatsu, setIsItaatsu] = useState(false);
  const [isSize, setIsSize] = useState(false);

  // 板厚
  const [itaatsuTemp, setItaatsuTemp] = useState([]);
  const [itaatsuData, setItaatsuData] = useState([]);
  const [jointItaatsuList, setJointItaatsuList] = useState([]);
  const [gasTypeList, setGasTypeList] = useState([]);
  const [kakousokudoItaatsuList, setKakousokudoItaatsuList] = useState([]);
  const [piercingProcessList, setPiercingProcessList] = useState([]);
  const [punchProcessItaatsuListData, setPunchProcessItaatsuListData] = useState([]);

  const [listData, setListData] = useState([]);

  // サイズ
  const [size, setSize] = useState([]);
  const [sizeTemp, setSizeTemp] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    setItaatsuData(props.itaatsuData);
    setItaatsuTemp(props.itaatsuData);
    setJointItaatsuList(props.jointItaatsuList);
    setGasTypeList(props.gasTypeList);
    setKakousokudoItaatsuList(props.kakouSokudoItaatsuList);
    setPiercingProcessList(props.piercingProcessList);
    setListData(props.blankKakousokudolistData);
    setPunchProcessItaatsuListData(props.punchProcessItaatsuListData);

    setSize(props.jointSizeMaster);
    setSizeTemp(props.jointSizeMaster);
    setSizeList(props.jointSizeList);
  }, [
    props.processName,
    props.editMode,
    props.kakouSokudoItaatsuList,
    props.itaatsuData,
    props.jointItaatsuList,
    props.gasTypeList,
    props.piercingProcessList,
    props.jointSizeMaster,
    props.jointSizeList,
    props.blankKakousokudolistData,
  ]);

  const addItaatsuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const addSizeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>サイズ</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
    } else if (isSize === true) {
      setIsSize(false);
    }
  };
  const addItaatsu = (id) => {
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: itaatsuTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  const addSize = (id) => {
    const copyData = sizeTemp.slice(); // Create a copy of the original array

    const insertIndex = sizeTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: sizeTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSizeTemp(copyData);
    }
  };
  const deleteItaatsu = (id, e) => {
    if (itaatsuTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = itaatsuTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
    }
  };
  const deleteSize = (id, e) => {
    if (sizeTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = sizeTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setSizeTemp(updatedData);
    }
  };
  const handleItaatsuChange = (event, id, field) => {
    const updatedData = itaatsuTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };
  const handleSizeChange = (event, id, field) => {
    const updatedData = sizeTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setSizeTemp(updatedData);
  };
  const cancelMasterData = () => {
    if (isItaatsu === true) {
      setItaatsuTemp(itaatsuData);
      setIsItaatsu(false);
    } else if (isSize === true) {
      setSizeTemp(size);
      setIsSize(false);
    }
  };
  const updateListData = (deletedData, newId) => {
    const list = [
      { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
      { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
      { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
    ];
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: list,
        });
      });

      return item;
    });
    return updatedData;
  };
  const addItaatsuMaster = () => {
    setIsItaatsu(false);

    const deletedId = [];

    itaatsuData.forEach((item1, index) => {
      const found = itaatsuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = itaatsuTemp
      .filter((item) => item.displayFlag && !itaatsuData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListColData(jointItaatsuList, deletedId);
    const deletedData2 = deleteListColData(gasTypeList, deletedId);
    const deleteData3 = deleteListColData(kakousokudoItaatsuList, deletedId);
    const deleteData4 = deleteListColData(piercingProcessList, deletedId);
    const deleteData5 = deleteListColData(punchProcessItaatsuListData, deletedId);
    const deleteData6 = deleteListColData(listData, deletedId);

    const updatedData = updateListColData(deletedData, newId);
    const updatedData2 = updateListColData(deletedData2, newId);
    const updatedData3 = updateListColData(deleteData3, newId);
    const updatedData4 = updateListColData(deleteData4, newId);
    const updatedData5 = updateListColData(deleteData5, newId);
    const updatedData6 = updateListData(deleteData6, newId);

    const sortedList = sortListData(updatedData, itaatsuTemp);
    const sortedList2 = sortListData(updatedData2, itaatsuTemp);
    const sortedList3 = sortListData(updatedData3, itaatsuTemp);
    const sortedList4 = sortListData(updatedData4, itaatsuTemp);
    const sortedList5 = sortListData(updatedData5, itaatsuTemp);
    const sortedList6 = sortListData(updatedData6, itaatsuTemp);

    setItaatsuData(itaatsuTemp);

    setJointItaatsuList(sortedList);
    props.updatedItaatsuData(itaatsuTemp);

    props.updatedJointItaatsuList(sortedList);

    setGasTypeList(sortedList2);
    props.updatedGasTypeList(sortedList2);
    setKakousokudoItaatsuList(sortedList3);
    props.updateblankKakouSokudoItaatsuList(sortedList3);

    setPiercingProcessList(sortedList4);
    props.updatePiercingProcessList(sortedList4);
    setPunchProcessItaatsuListData(sortedList5);
    props.updatePunchProcessList(sortedList5);

    setListData(sortedList6);
    props.updatedBlankKakousokudolistData(sortedList6);
  };
  const addSizeMaster = () => {
    setIsSize(false);

    const deletedId = [];

    size.forEach((item1, index) => {
      const found = sizeTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = sizeTemp
      .filter((item) => item.displayFlag && !size.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListColData(sizeList, deletedId);

    const updatedData = updateListColData(deletedData, newId);

    const sortedList = sortListData(updatedData, sizeTemp);
    setSize(sizeTemp);

    setSizeList(sortedList);
    props.updateJointSizeMaster(sizeTemp);

    props.updateJointSizeList(sortedList);
  };
  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleItaatsuChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addItaatsuMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addSizeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sizeTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleSizeChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSize(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSize(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addSizeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const itaatsuModal = () => {
    setIsItaatsu(true);
  };
  const sizeModal = () => {
    setIsSize(true);
  };
  const jointItaatsuListDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...jointItaatsuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    setJointItaatsuList(updatedData);

    props.updatedJointItaatsuList(updatedData);
  };
  const jointSizeListDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...sizeList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    setSizeList(updatedData);

    props.updateJointSizeList(updatedData);
  };
  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row className="table-container">
          <Table
            style={{
              width:
                size.filter((i) => i.displayFlag === true).length > 8
                  ? '100%'
                  : size.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    <label>個数</label>
                    <label>/</label>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={sizeModal} style={{ pointerEvents: 'auto' }}>
                          サイズ
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>サイズ</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {size
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    ～{item.max}cm<sup className="tbl-header">2</sup>
                  </td>
                ))}
            </tr>

            {sizeList.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td style={{ color: 'white', width: '196px' }} className="fixedCol">
                  {row.materialType}
                </td>

                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => jointSizeListDataChange(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </Table>
        </Row>
        <Row className="table-container">
          <Table
            style={{
              width:
                itaatsuData.filter((i) => i.displayFlag === true).length > 8
                  ? '100%'
                  : itaatsuData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    <label>材質区分</label>
                    <label>/</label>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={itaatsuModal} style={{ pointerEvents: 'auto' }}>
                          板厚
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>板厚</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {itaatsuData
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}t</td>
                ))}
            </tr>

            {jointItaatsuList.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td style={{ color: 'white', width: '196px' }} className="fixedCol">
                  {row.materialType}
                </td>

                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => jointItaatsuListDataChange(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </Table>
        </Row>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addItaatsuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
      {/* サイズ */}
      {isSize
        ? commonModal(
            isSize,
            addSizeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSizeContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_Joint;
