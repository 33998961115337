import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];
const IQ3KouteiInput_Plank = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);

  const [plankValues, setPlankValues] = useState([]);
  const [firstplankValues, setFirstPlankValues] = useState([]);

  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    const plankData = props.plankVal;
    const firstTable = props.firstPlankTableVal;
    console.log(props.plankVal);
    console.log('Blankkkk---', props.firstPlankTableVal);
    setPlankValues(plankData);

    setFirstPlankValues(firstTable);
  }, [props.plankVal]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(plankValues));
    if (types === 'types') {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
      setPlankValues(updatedData);
    } else {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
      setPlankValues(updatedData);
    }
  };
  const addRow = (id) => {
    const copyData = plankValues.slice(); // Create a copy of the original array

    const insertIndex = plankValues.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = plankValues.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: '',
        xsize: '',
        ysize: '',
        perimeter: '',
        count: '',
        blankFlag: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setPlankValues(copyData);
    }
  };
  const deleteRow = (id) => {
    if (plankValues.length > 1) {
      const updatedData = plankValues.filter((item) => item.id !== id);

      setPlankValues(updatedData);
    }
  };
  const changeValue = (e, name) => {
    const updatedData = firstplankValues.map((row) => {
      return { ...row, [name]: e.target.value };
    });
    setFirstPlankValues(updatedData);
  };
  return (
    <>
      <Row className="mt-1 registerModal">
        <Table className="kouteiinput " style={{ width: '66%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '11%' }} className="tbl-header">
                外径周長(mm)
              </th>
              <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(外径周長)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                内径周長(mm)
              </th>
              <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(内径周長)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                空送距離(mm)
              </th>
              <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(空送距離)
              </th>
            </tr>
          </thead>
          <tbody>
            {firstplankValues.map((i) => (
              <>
                <tr>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.val1}
                      onChange={(e) => changeValue(e, 'val1')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.val2}
                      onChange={(e) => changeValue(e, 'val2')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      value={i.val3}
                      onChange={(e) => changeValue(e, 'val3')}
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      value={i.val4}
                      onChange={(e) => changeValue(e, 'val4')}
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.val5}
                      onChange={(e) => changeValue(e, 'val5')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.val6}
                      onChange={(e) => changeValue(e, 'val6')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '72%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
            </tr>
          </thead>
          <tbody>
            {plankValues.map((i, index) => (
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <>
                      <Select
                        defaultValue={1}
                        size="middle"
                        value={i.types}
                        onChange={(e) => changeDetails(e, i.id, 'types')}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                      >
                        {/* <Option value={1}> {i[1]}</Option> */}
                        {types.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>{i.types}</>
                  )}
                </td>

                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {editMode ? (
                  <>
                    <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                      <Row justify="center">
                        <PlusCircleFilled
                          className="add-remove-icon"
                          onClick={() => addRow(i.id)}
                          style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                        <CloseCircleFilled
                          className="add-remove-icon"
                          onClick={() => deleteRow(i.id)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                      </Row>
                    </td>
                  </>
                ) : (
                  <>
                    {' '}
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Plank;
