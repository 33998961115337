import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

const IQ3KouteiInput_Kensa = forwardRef((props, ref) => {
  // 編集モード
  const editMode = props.editMode;
  const [numOfKensa, setNumOfKensa] = useState('');

  useEffect(() => {
    setNumOfKensa(props.numOfKensa);
  }, [props.numOfKensa]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  return (
    <>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '21%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                検査回数
              </th>
            </tr>
          </thead>
          <tbody>
            {numOfKensa === '' ? (
              <></>
            ) : (
              <>
                {' '}
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>1</td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', verticalAlign: 'middle' }}>
                    {editMode ? (
                      <>
                        <Input
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          value={numOfKensa}
                          onChange={(e) => setNumOfKensa(e.target.value)}
                          style={{ height: 32, textAlign: 'center' }}
                        ></Input>
                      </>
                    ) : (
                      <>{numOfKensa}</>
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Kensa;
