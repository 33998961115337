/**
 * クラス名：板金の手動バリ取対象サイズ画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
const Device_ManualBari = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [naikei_min, setNaikeiMin] = useState('');
  const [naikei_max, setNaikeiMax] = useState('');
  const [ana_min, setAnaMin] = useState('');
  const [ana_max, setAnaMax] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    setNaikeiMin(props.naikei_min);
    setNaikeiMax(props.naikei_max);
    setAnaMax(props.ana_max);
    setAnaMin(props.ana_min);
  }, [props.editMode, props.naikei_min, props.naikei_max, props.ana_max, props.ana_min]);
  return (
    <>
      <Row style={{ marginLeft: 10 }} className="chargeAmount">
        <Col style={{ width: '40%' }}>
          <Table>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th className="tbl-header" style={{ width: '20%' }} colSpan={2}>
                  内径矩形周長
                </th>
                <th className="tbl-header" style={{ width: '20%' }} colSpan={2}>
                  丸穴
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-light" style={{ textAlign: 'center' }}>
                <td>Min</td>
                <td>Max</td>
                <td>Min</td>
                <td>Max</td>
              </tr>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  <Input
                    value={naikei_min}
                    onChange={(e) => {
                      setNaikeiMin(e.target.value);
                      props.updateNaikeiMin(e.target.value);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
                <td>
                  <Input
                    value={naikei_max}
                    onChange={(e) => {
                      setNaikeiMax(e.target.value);
                      props.updateNaikeiMax(e.target.value);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
                <td>
                  <Input
                    value={ana_min}
                    onChange={(e) => {
                      setAnaMin(e.target.value);
                      props.updateAnaMin(e.target.value);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
                <td>
                  <Input
                    value={ana_max}
                    onChange={(e) => {
                      setAnaMax(e.target.value);
                      props.updateAnaMax(e.target.value);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
});

export default Device_ManualBari;
