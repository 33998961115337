/**
 * クラス名：板金のブランクの2工程穴加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';

const Device_Blank_DrillingTime = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [drillingTime, setDrillingTime] = useState('00:00:00');
  useEffect(() => {
    setEditMode(props.editMode);
    setDrillingTime(props.drillingTime);
  }, [props.editMode, props.drillingTime]);
  const changeTime = (e) => {
    setDrillingTime(e);
    props.updateDrillingTime(e);
  };
  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Col style={{ width: '20%' }}>
          <Table>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center', height: 30 }}>
                <th className="tbl-header">2工程穴加工時間(秒)</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  <Row align="middle">
                    {!editMode ? (
                      <>
                        <Col span={24}>
                          <label>{drillingTime}</label>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={10}>
                          <Input value={drillingTime} onChange={(event) => changeTime(event.target.value)}></Input>
                        </Col>
                        <Col span={14}>
                          <CaretUpOutlined
                            style={{
                              fontSize: 16 + 'px',
                              marginLeft: 3,
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(drillingTime, '10min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(drillingTime, '10min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(drillingTime, 'min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(drillingTime, 'min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(drillingTime, 'sec');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(drillingTime, 'sec');
                              changeTime(editedTime);
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
});

export default Device_Blank_DrillingTime;
