/**
 * クラス名：装置設定の段取設定画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { Component, useEffect, forwardRef, useState } from 'react';
import { Row, Col, Input, Button, Space, Checkbox } from 'antd';
import { Table } from 'react-bootstrap';
import { CaretUpOutlined, CaretDownOutlined, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import {
  confirmUpdateContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';
const SetupSetting = forwardRef((props, ref) => {
  // 段取設定
  const [dantoriKouteiDetails, setDantoriKouteiDetails] = useState([]);

  const [category, setCategory] = useState('');
  const [editMode, setEditMode] = useState(false);
  // 新規modal
  const [isKeisuu, setIsKeisuu] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isDiscardData, setIsDiscardData] = useState(false);

  const [suuryouData, setSuuryouData] = useState([]);
  const [suuryouDataTemp, setSuuryouDataTemp] = useState([]);
  const [suuryouList, setSuuryouList] = useState([]);

  // 基本段取時間
  const [basicTime, setTime] = useState('');

  const [isSuuryou, setIsSuuryou] = useState(false);

  // 子部品数量
  const [dantoriSuuryouList, setDantoriSuuryouList] = useState([]);

  const [weight, setWeight] = useState([]);
  const [weightTemp, setWeightTemp] = useState([]);
  const [volume, setVolume] = useState([]);
  const [volumeTemp, setVolumeTemp] = useState([]);
  const [sizeList, setSizelist] = useState([]);

  const [isVolume, setIsVolume] = useState(false);
  const [isWeight, setIsWeight] = useState(false);

  // 製品重量係数のサイズリスト
  const [productSizeList, setProductSizeList] = useState([]);

  // 要素数
  // const [isYousosuu, setIsYousosuu] = useState(false);
  // const [yousosuu, setYousosuu] = useState([]);
  // const [yousosuuTemp, setYousosuuTemp] = useState([]);
  // const [yousosuuLst, setYousosuuLst] = useState([]);
  // const [sagyouYousuuList, setSagyouYousuuList] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);
    if (props.detailsData != undefined) {
      setDantoriKouteiDetails(props.detailsData);
      setCategory(props.processName);
    }
    setTime(props.kihonDantoriTime);
    // 数量
    setSuuryouData(props.suuryouData);
    setSuuryouDataTemp(props.suuryouData);
    setSuuryouList(props.dantoriSetteiSuuryouList);

    // 子部品数量(同時にリスト更新)
    setDantoriSuuryouList(props.kobuhinsuuryouList);

    // 重量と体積
    setWeight(props.weight);
    setWeightTemp(props.weight);
    setVolume(props.volume);
    setVolumeTemp(props.volume);
    setSizelist(props.dantoriSizeList);

    // 製品重量係数のサイズリスト
    setProductSizeList(props.sizeList);

    // 要素数
    // setYousosuu(props.yousosuuMasterData);
    // setYousosuuTemp(props.yousosuuMasterData);
    // setYousosuuLst(props.shokidankaiYousosuuList);
    // setSagyouYousuuList(props.sagyouKeisuuYousosuuList);
  }, [
    props.detailsData,
    props.processName,
    props.editMode,
    props.kihonDantoriTime,
    props.suuryouData,
    props.kobuhinsuuryouList,
    props.weight,
    props.volume,
    props.dantoriSizeList,
    props.sizeList,
    // props.yousosuuMasterData,
    // props.shokidankaiYousosuuList,
    // props.sagyouKeisuuYousosuuList,
    // props.dantoriSetteiSuuryouList,
  ]);

  const changeDantoriTime = (itemId, subItemId, newName) => {
    const updatedData = dantoriKouteiDetails.map((item) => {
      if (item.key === itemId) {
        const updatedSubItems = item.processDetailValue.map((subItem) => {
          if (subItem.key === subItemId) {
            return { ...subItem, dantoriTime: newName };
          }
          return subItem;
        });

        return { ...item, processDetailValue: updatedSubItems };
      }
      return item;
    });

    setDantoriKouteiDetails(updatedData);
    props.updatedDantoriSetting(updatedData);
  };
  const addSuuryouModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>数量</p>
    </div>
  );
  // const openYousosuuModal = () => {
  //   setIsYousosuu(true);
  // };
  const addData = () => {
    setIsUpdateData(true);
  };
  const updateDataOk = () => {
    if (isKeisuu == true) {
    }
  };
  const updateDataCancel = () => {
    if (isKeisuu == true) {
      setIsKeisuu(true);
    }
    setIsUpdateData(false);
  };

  const discardOk = () => {
    if (isKeisuu == true) {
      setIsKeisuu(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isKeisuu == true) {
      setIsKeisuu(true);
    }
    setIsDiscardData(false);
  };
  const closeAddModal = () => {
    if (isVolume === true) {
      setIsVolume(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isSuuryou === true) {
      setIsSuuryou(false);
    } else if (isYousosuu === true) {
      setIsYousosuu(false);
    }
  };
  // const addYousosuu = () => {
  //   const data = {
  //     id: yousosuuTemp.length + 1,

  //     max: '',
  //     displayFlag: true,
  //   };
  //   let result = [...yousosuuTemp, data];
  //   setYousosuuTemp(result);
  // };
  // const deleteYousosuu = (id, e) => {
  //   if (yousosuuTemp.filter((item) => item.displayFlag === true).length > 1) {
  //     const updatedData = yousosuuTemp.map((row) => {
  //       if (row.id === id) {
  //         return { ...row, displayFlag: false };
  //       }
  //       return row;
  //     });
  //     setYousosuuTemp(updatedData);
  //   }
  // };
  const cancelMasterData = () => {
    if (isSuuryou === true) {
      setSuuryouDataTemp(suuryouData);
      setIsSuuryou(false);
    }
  };

  const changeTime = (e) => {
    setTime(e);
    props.updatedKihonDantoriTime(e);
  };

  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...sizeList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setSizelist(updatedData);
    props.updatedDantoriSizeList(updatedData);
  };
  const suuryou = () => {
    setIsSuuryou(true);
  };
  const addSuuryou = (id) => {
    const copyData = suuryouDataTemp.slice(); // Create a copy of the original array

    const insertIndex = suuryouDataTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: suuryouDataTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSuuryouDataTemp(copyData);
    }
  };
  const deleteSuuryou = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(suuryouDataTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = suuRyou[suuRyou.length - 2].max;
      if (previousValue) {
        updatedData[updatedData.length - 1].max = (parseInt(previousValue) + 1).toString();
        setSuuryouDataTemp(updatedData);
      } else {
        setSuuryouDataTemp(updatedData);
      }
    }
  };

  const addSuuryouMasterData = () => {
    const deletedId = [];

    suuryouData.forEach((item1, index) => {
      const found = suuryouDataTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = suuryouDataTemp
      .filter((item) => item.displayFlag && !suuryouData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = suuryouList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const afterDeleted1 = dantoriSuuryouList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const kobuhinupdatedData = afterDeleted1.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = suuryouDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = suuryouDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    const sortedList2 = kobuhinupdatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = suuryouDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = suuryouDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });

    setIsSuuryou(false);
    setSuuryouData(suuryouDataTemp);

    setSuuryouList(sortedList);
    props.updatedSuuryouData(suuryouDataTemp);
    setDantoriSuuryouList(sortedList2);
    props.updatedSuuryouList(sortedList2);
    props.updateddantoriSetteiSuuryouList(sortedList);
  };
  // const addYousosuuMasterData = () => {
  //   const deletedId = [];

  //   yousosuu.forEach((item1, index) => {
  //     const found = yousosuuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
  //     if (found) {
  //       deletedId.push(index + 1);
  //     }
  //   });

  //   const newId = yousosuuTemp
  //     .filter((item) => item.displayFlag && !yousosuu.some((data2Item) => data2Item.id === item.id))
  //     .map((item) => item.id);

  //   const afterDeleted = yousosuuLst.map((item) => {
  //     return {
  //       ...item,
  //       lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
  //     };
  //   });

  //   const sagyouYousuuDelete = sagyouYousuuList.map((item) => {
  //     return {
  //       ...item,
  //       lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
  //     };
  //   });
  //   const updatedData = afterDeleted.map((item) => {
  //     newId.forEach((key) => {
  //       // Add new objects to lstData
  //       item.lstData.push({
  //         key: key,
  //         value: '',
  //       });
  //     });

  //     return item;
  //   });
  //   const updatedSagyouYousuuData = sagyouYousuuDelete.map((item) => {
  //     newId.forEach((key) => {
  //       // Add new objects to lstData
  //       item.lstData.push({
  //         key: key,
  //         value: '',
  //       });
  //     });

  //     return item;
  //   });

  //   setIsYousosuu(false);
  //   setYousosuuTemp(yousosuuTemp);

  //   setYousosuuLst(updatedData);
  //   props.updatedYousosuuMasterData(yousosuuTemp);

  //   props.updatedYousosuuList(updatedData);

  //   setSagyouYousuuList(updatedSagyouYousuuData);
  //   props.updatedSagyouYousosuuList(updatedSagyouYousuuData);
  // };
  const handleSuuryouChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(suuryouDataTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });

    const previousValue = updatedData[updatedData.length - 2].max;
    if (previousValue) {
      updatedData[updatedData.length - 1].max = (parseInt(previousValue) + 1).toString();
      setSuuryouDataTemp(updatedData);
    } else {
      setSuuryouDataTemp(updatedData);
    }
  };
  const addSuuryouContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値（≦）</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {suuryouDataTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleSuuryouChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSuuryou(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSuuryou(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addSuuryouMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const changeSuuryou = (event, rowIndex, colIndex) => {
    const copyData = [...suuryouList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setSuuryouList(updatedData);
    props.updateddantoriSetteiSuuryouList(updatedData);
  };
  // const changeYousuu = (event, rowIndex, colIndex) => {
  //   const copyData = [...yousosuuLst];
  //   const updatedData = JSON.parse(JSON.stringify(copyData));
  //   updatedData[rowIndex].lstData[colIndex].value = event.target.value;
  //   setYousosuuLst(updatedData);
  //   props.updatedYousosuuList(updatedData);
  // };
  const addWeight = () => {
    setIsWeight(true);
  };
  const addVolume = () => {
    setIsVolume(true);
  };
  const addVolumeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>体積</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );
  // const addYousosuuModalTitle = (
  //   <div
  //     style={{
  //       width: 400,
  //       backgroundColor: '#005fab',
  //       marginLeft: -24,
  //       height: 36,
  //       marginTop: -20,
  //       borderTopLeftRadius: 7,
  //       borderTopRightRadius: 7,
  //       fontSize: 14,
  //     }}
  //   >
  //     <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>要素数</p>
  //   </div>
  // );
  const deleteWeight = (id, e) => {
    if (weightTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = weightTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setWeightTemp(updatedData);
    }
  };
  const addWeightRow = (id) => {
    // const data = {
    //   id: weightTemp.length + 1,
    //   max: '',
    //   displayFlag: true,
    // };
    // let result = [...weightTemp, data];
    // setWeightTemp(result);

    const copyData = weightTemp.slice(); // Create a copy of the original array

    const insertIndex = weightTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: weightTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setWeightTemp(copyData);
    }
  };
  const cancelWeightMaster = () => {
    setWeightTemp(weight);
    setIsWeight(false);
  };
  const deleteListData = (list, deletedId) => {
    const deletedData = list.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    return deletedData;
  };
  const updateListData = (deletedData, newId) => {
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        lstData: sortedLstData,
      };
    });
    return sortedList;
  };
  const addWeightMaster = () => {
    const deletedId = [];

    weight.forEach((item1, index) => {
      const found = weightTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = weightTemp
      .filter((item) => item.displayFlag && !weight.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    // const test = sizeList.filter((it) => !deletedId.includes(it.key));
    // const productSizeListUpd = productSizeList.filter((it) => !deletedId.includes(it.key));
    const deletedData = deleteListData(sizeList, deletedId);
    const deletedData2 = deleteListData(productSizeList, deletedId);
    // const result = test.map((item) => {
    //   const list = weightTemp.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });

    // newId.forEach((id) => {
    //   const list = weightTemp.find((item) => item.id === id);

    //   if (list) {
    //     const newObj = {
    //       key: id,
    //       max: list.max,
    //       displayFlag: true,
    //       lstData: result[0].lstData.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '',
    //         };
    //       }),
    //     };

    //     result.push(newObj);
    //   }
    // });
    const updatedData = updateListData(deletedData, newId);
    const updatedData2 = updateListData(deletedData2, newId);
    const sortedData = sortListData(updatedData, weightTemp);
    const sortedData2 = sortListData(updatedData2, weightTemp);

    setIsWeight(false);
    setWeight(weightTemp);

    setSizelist(sortedData);
    props.updatedWeight(weightTemp);

    props.updatedDantoriSizeList(sortedData);
    setProductSizeList(sortedData2);
    props.updatedSizesList(sortedData2);
  };
  const handleVolumeChange = (event, id, field) => {
    const updatedData = volumeTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setVolumeTemp(updatedData);
  };
  // const handleYousosuuChange = (event, id, field) => {
  //   const updatedData = yousosuuTemp.map((row) => {
  //     if (row.id === id) {
  //       return { ...row, [field]: event.target.value };
  //     }
  //     return row;
  //   });
  //   setYousosuuTemp(updatedData);
  // };
  const addVolumeRow = (id) => {
    const copyData = volumeTemp.slice(); // Create a copy of the original array

    const insertIndex = volumeTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: volumeTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setVolumeTemp(copyData);
    }
  };
  const cancelVolumeMaster = () => {
    setVolumeTemp(volume);
    setIsVolume(false);
  };
  const addVolumeMaster = () => {
    const deletedId = [];

    volume.forEach((item1, index) => {
      const found = volumeTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = volumeTemp
      .filter((item) => item.displayFlag && !volume.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = sizeList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = volumeTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = volumeTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsVolume(false);
    setVolume(volumeTemp);

    setSizelist(sortedList);
    props.updatedVolume(volumeTemp);

    props.updatedDantoriSizeList(sortedList);
  };
  const deleteVolume = (id, e) => {
    if (volumeTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = volumeTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setVolumeTemp(updatedData);
    }
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = weightTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setWeightTemp(updatedData);
  };

  const addVolumeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label> （≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {volumeTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleVolumeChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addVolumeRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteVolume(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addVolumeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelVolumeMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label> （≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {weightTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleWeightChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  // const addYousosuuContent = (
  //   <>
  //     <div style={{ height: 400, overflow: 'auto' }}>
  //       <Row className="chargeAmount registerModal">
  //         <Col style={{ width: '100%' }}>
  //           <Table>
  //             <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
  //               <tr style={{ textAlign: 'center' }}>
  //                 <th style={{ width: '20%' }}>No</th>

  //                 <th style={{ width: '60%' }}>
  //                   <Space>
  //                     <label>しきい値</label>（≦）
  //                   </Space>
  //                 </th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {yousosuuTemp
  //                 .filter((subitem) => subitem.displayFlag === true)
  //                 .map((item, index) => (
  //                   <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
  //                     <td>{index + 1}</td>

  //                     <td>
  //                       <Input
  //                         value={item.max}
  //                         onChange={(e) => handleYousosuuChange(e, item.id, 'max')}
  //                         style={{ pointerEvents: editMode ? 'auto' : 'none' }}
  //                       ></Input>
  //                     </td>
  //                     <td
  //                       style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
  //                       className="min"
  //                     >
  //                       <Row justify="center">
  //                         <PlusCircleFilled
  //                           onClick={addYousosuu}
  //                           className="add-remove-icon"
  //                           style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
  //                         />
  //                         <CloseCircleFilled
  //                           className="add-remove-icon"
  //                           onClick={(e) => deleteYousosuu(item.id, e)}
  //                           style={{ pointerEvents: editMode ? 'auto' : 'none' }}
  //                         />
  //                       </Row>
  //                     </td>
  //                   </tr>
  //                 ))}
  //             </tbody>
  //           </Table>
  //         </Col>
  //       </Row>
  //     </div>
  //     <Row justify="center" className="mt-2">
  //       <Button className="mainButton" id="update" onClick={addYousosuuMasterData}>
  //         OK
  //       </Button>
  //       <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
  //         キャンセル
  //       </Button>
  //     </Row>
  //   </>
  // );
  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        {category === '溶接' || category === '溶接仕上' || category === '表面処理' ? (
          <>
            {dantoriKouteiDetails.map((item) => (
              <Row key={item.key} className="chargeAmount">
                {item.processDetailValue.map((subItem) => (
                  <Col style={{ width: '20%' }}>
                    <Table>
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">{subItem.detailType}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ textAlign: 'center', height: 35 }}>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{subItem.dantoriTime}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={10}>
                                    <Input
                                      value={subItem.dantoriTime}
                                      onChange={(event) => changeDantoriTime(item.key, subItem.key, event.target.value)}
                                    ></Input>
                                  </Col>
                                  <Col span={14}>
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 16 + 'px',
                                        marginLeft: 3,
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, '10min');

                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, '10min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, 'min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, 'min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, 'sec');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, 'sec');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ))}
              </Row>
            ))}
          </>
        ) : (
          <>
            <Row className="chargeAmount">
              <Table style={{ width: '20%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="tbl-header">基本段取時間</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center', height: 35 }}>
                    <td>
                      <Row style={{ textAlign: 'center' }} align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{basicTime}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input value={basicTime} onChange={(event) => changeTime(event.target.value)}></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(basicTime, '10min');

                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(basicTime, '10min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(basicTime, 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(basicTime, 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(basicTime, 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(basicTime, 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        )}

        <div className="table-container">
          <Row>
            <label style={{ fontSize: 14, fontWeight: 400 }}>段取係数1</label>
          </Row>
          <Table
            style={{
              width:
                suuryouData.filter((i) => i.displayFlag === true).length > 7
                  ? '100%'
                  : suuryouData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header">
                <Row justify="center" className="tbl-header">
                  <Space>
                    {editMode ? (
                      <>
                        <label>種類</label>/
                        <Button className="cancleButton" onClick={suuryou} style={{ pointerEvents: 'auto' }}>
                          数量
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>種類 / 数量</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {suuryouData
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    {suuryouData[suuryouData.length - 1].id === item.id ? <>{item.max}個 ～</> : <>～{item.max}個</>}
                  </td>
                ))}
            </tr>

            {suuryouList.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td style={{ width: '196px' }}>
                  <Row className="tbl-header">
                    <Col span={5}>
                      <Checkbox
                        style={{ marginLeft: 10 }}
                        type="checkbox"
                        disabled={!editMode}
                        checked={row.isUsed}
                        onChange={(e) => {
                          const data = [...suuryouList];
                          data[rowIndex].isUsed = e.target.checked;
                          data?.forEach((element) => {
                            if (element.key !== data[rowIndex].key) {
                              element.isUsed = false;
                            }
                          });
                          setSuuryouList(data);
                        }}
                      ></Checkbox>
                    </Col>
                    <Col span={19}>
                      <label style={{ color: 'white' }}>{row.materialType}</label>
                    </Col>
                  </Row>
                </td>
                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => changeSuuryou(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </Table>
          <Row>
            <label style={{ fontSize: 14, fontWeight: 400 }}>段取係数2</label>
          </Row>
          <Table
            style={{
              width:
                weight.filter((i) => i.displayFlag === true).length > 8
                  ? '100%'
                  : weight.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header">
                <Row justify="center" className="tbl-header">
                  <Space>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                          重量
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>重量</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>
              {weight.map((row, rowIndex) => (
                <td style={{ color: 'white', width: '98px' }}>～{row.max}kg</td>
              ))}
            </tr>
            {/* <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '196px' }}>重量係数</td>

              {sizeList.map((row, rowIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={row.lstData[0].value}
                    onChange={(e) => listDataChange(e, rowIndex, 0)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr> */}
            {sizeList
              .filter((i) => i.key === 1)
              .map((row, rowIndex) => (
                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }}>重量係数</td>

                  {row.lstData.map((col, colIndex) => (
                    <td style={{ width: '98px' }}>
                      <Input
                        value={col.value}
                        onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </Table>
        </div>
      </div>

      {/* 数量 */}
      {isSuuryou
        ? commonModal(
            isSuuryou,
            addSuuryouModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSuuryouContent,
            null,
            null,
            true
          )
        : ''}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            closeAddModal,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
      {/* 体積 */}
      {isVolume
        ? commonModal(
            isVolume,
            addVolumeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addVolumeContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
      {/* 要素数
      {isYousosuu
        ? commonModal(
            isYousosuu,
            addYousosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addYousosuuContent,
            null,
            null,
            true
          )
        : ''} */}
    </>
  );
});

export default SetupSetting;
