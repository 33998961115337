/**
 * クラス名：材質名称詳細画面
 * 説明：iQ3板金に使用の材質名称詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Select, Input, Row, Col, Tabs } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { StarFilled } from '@ant-design/icons';

import checkedImg from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  modalTitle,
} from '../../../common/CommonModal';
import { getKubunNameDDL, secondsToHms } from '../../../common/Common.js';
import { categoryList, createKouteiDDL, getProcessTypeDDL } from './AutoReserveList.js';
import CommonPurchaseList from '../../../common/CommonPurchaseList';
import { getPurchaseInfo } from '../../../common/CommonAPI';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const AutoReserveDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
  }));

  const editMode = props.editMode;

  const [selectedAutoPurchase, setSelectedAutoPurchase] = useState('');
  const [selectedAutoPrice, setSelectedAutoPrice] = useState('');

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);
  const [changePurchase, setChangePurchase] = useState(false);
  const [selectedPurchaseDetail, setSelectedPurchaseDetail] = useState([]);
  const [categoryDDL, setCategoryDDL] = useState();
  const [processDDL, setProcessDDL] = useState();
  const [processTypeDDL, setProcessTypeDDL] = useState();
  const [processDDLArr, setProcessDDLArr] = useState([]);
  const [processTypeDDLArr, setProcessTypeDDLArr] = useState([]);

  const [visiblePurchaseTbl, setVisiblePurchaseTbl] = useState();
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [checkedPurchasesData, setCheckedPurchasesData] = useState(false);

  const [purchaseMstInfo, setPurchaseMstInfo] = useState([]);

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData).length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(props.selectedData);
      setSelectedPurchaseDetail(
        props.selectedData.purchaseDetail && Object.keys(props.selectedData.purchaseDetail).length > 0
          ? props.selectedData.purchaseDetail
          : []
      );
      setSelectedRowData(selectedData);
      setCategoryDDL(props.selectedData.category);
      setProcessDDL(props.selectedData.process);
      setProcessTypeDDL(props.selectedData.processType);
    } else {
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  useEffect(() => {
    setProcessDDLArr(createKouteiDDL(selectedRowData.category));
  }, [categoryDDL]);

  useEffect(() => {
    setProcessTypeDDLArr(getProcessTypeDDL(selectedRowData.category, selectedRowData.process));
  }, [processDDL]);

  // 購入品マスタ情報を取得する
  const getPurchaseMstData = async () => {
    let purchaseMstData = await getPurchaseInfo();
    setPurchaseMstInfo(purchaseMstData);
  };

  const purchaseTblContent = (activeTabKey) => {
    return (
      <div id="addNewAutoReserve" style={{ marginTop: 8 }}>
        <table className="table table-bordered upperReserveDetail">
          <thead style={{ backgroundColor: '#fafafa', color: '#1063aa', height: 20 }}>
            <tr>
              <td className="param-detail-tbl-val-title">種別</td>
              <td className="param-detail-tbl-val-title">工程</td>
              <td className="param-detail-tbl-val-title">加工</td>
              <td className="param-detail-tbl-val-title">材質区分</td>
              <td className="param-detail-tbl-val-title">サイズ</td>
            </tr>
          </thead>
          <tr>
            <td>
              <label>{selectedRowData.category}</label>
            </td>
            <td>
              <label>{selectedRowData.process}</label>
            </td>
            <td>
              <label>{selectedRowData.processType}</label>
            </td>
            <td>
              <label>{selectedRowData.materialClass}</label>
            </td>
            <td>
              <label>{selectedRowData.size}</label>
            </td>
          </tr>
        </table>
        {/** 一覧 */}
        <div>
          <Tabs
            className="estimate-detail-tab"
            type="card"
            defaultActiveKey="1"
            activeKey={activeTabKey}
            items={tabItems}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };

  const onChange = (key) => {
    setActiveTabKey(key);
  };

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[objKey] = value;
      return updatedData;
    });
  };

  const getPurchaseData = (data) => {
    setCheckedPurchasesData(data[0]);
  };

  const tabItems = [
    {
      key: '1',
      label: `検索`,
      children: <CommonPurchaseList data={purchaseMstInfo} getPurchaseData={getPurchaseData} />,
    },
    {
      key: '2',
      label: <StarFilled />,
      children: (
        <CommonPurchaseList
          data={purchaseMstInfo.filter((item) => item.info.isAllocation)}
          getPurchaseData={getPurchaseData}
        />
      ),
    },
  ];

  const deleteSelectedRow = (index, e, id) => {
    if (id === 'btnAutoPurchase') {
      setSelectedAutoPurchase(selectedAutoPurchase.filter((v, i) => i !== index));
    } else if (id === 'btnAutoPrice') {
      setSelectedAutoPrice(selectedAutoPrice.filter((v, i) => i !== index));
    }
  };

  const showPurchaseTbl = () => {
    setVisiblePurchaseTbl(true);
    getPurchaseMstData();
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    setUpdateConfirm(true);
    setIsUpdate(true);
  };

  const discardData = () => {
    setCancelConfirm(true);
  };

  const updConfirmOk = (e) => {
    if (isUpdate) {
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };
      updateData['purchaseDetail'] = selectedPurchaseDetail;
      if (updDateFlg || changePurchase) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
    setUpdateConfirm(false);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData(props.selectedData);
    setCategoryDDL(props.selectedData.category);
    setProcessDDL(props.selectedData.process);
    setProcessTypeDDL(props.selectedData.processType);
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  const purchaseOK = (e) => {
    if (selectedPurchaseDetail.code !== checkedPurchasesData.code) {
      setSelectedPurchaseDetail(checkedPurchasesData);
      setChangePurchase(true);
    }
    setVisiblePurchaseTbl(false);
  };

  const purchaseCancel = () => {
    setVisiblePurchaseTbl(false);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 6 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className="mainButton" id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 6 }}>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col" style={{ textAlign: 'center' }}>
            <label style={{ marginRight: 5, textAlign: 'center' }}>加工選択</label>
          </Col>
        </Row>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl" style={{ marginBottom: 0 }}>
            <thead>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="display"
                      defaultValue={selectedRowData.display}
                      className="radioCustomerGpEdit"
                      onChange={(e) => editDetailInput('display', e.target.value)}
                      value={selectedRowData.display}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData.display && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
              {/* 種別 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">種別</label>
                </td>

                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('category', e);
                          setCategoryDDL(e);
                          setProcessDDL('defaultValue');
                          setProcessTypeDDL('defaultValue');
                          setProcessTypeDDLArr([]);
                        }}
                        placeholder="材質区分名"
                        id="categoryDDL"
                        name="categoryDDL"
                        value={selectedRowData.category}
                        defaultValue="defaultValue"
                      >
                        <Option id="defaultValue" value="defaultValue">
                          工程を選択でください。
                        </Option>
                        {[...categoryList].map((item) => (
                          <option value={item.categoryName}>{item.categoryName}</option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{selectedRowData.category}</label>
                  )}
                </td>
              </tr>
              {/* 工程 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">工程</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('process', e);
                          setProcessDDL(e);
                          setProcessTypeDDL('defaultValue');
                        }}
                        placeholder="材質区分名"
                        id="processDDL"
                        name="processDDL"
                        value={processDDL} /* {selectedRowData.process} */
                        defaultValue="defaultValue"
                      >
                        <Option id="defaultValue" value="defaultValue">
                          工程を選択でください。
                        </Option>
                        {processDDLArr.map((item) => (
                          <option id={'process' + item.key} value={item.koutei}>
                            {item.koutei}
                          </option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{selectedRowData.process}</label>
                  )}
                </td>
              </tr>
              {/* 加工 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">加工</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('processType', e);
                          setProcessTypeDDL(e);
                        }}
                        placeholder="加工名"
                        id="processType"
                        name="processType"
                        value={processTypeDDL /* selectedRowData.processType */}
                        defaultValue="defaultValue"
                      >
                        <Option id="defaultValue" value="defaultValue">
                          工程を選択でください。
                        </Option>
                        {processTypeDDLArr.map((item) => (
                          <option id={'processType' + item.key} value={item.koutei}>
                            {item.koutei}
                          </option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{selectedRowData.processType}</label>
                  )}
                </td>
              </tr>
              {/* 材質区分名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質区分</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('materialClass', e);
                        }}
                        placeholder="材質区分名"
                        id="materialRefDDL"
                        name="materialRefDDL"
                        value={selectedRowData.materialClass}
                      >
                        {getKubunNameDDL(selectedRowData).map((item) => (
                          <option id={'materialClass' + item.value} value={item.label}>
                            {item.label}
                          </option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{selectedRowData.materialClass}</label>
                  )}
                </td>
              </tr>
              {/* サイズ */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">サイズ</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="size"
                    id="size"
                    value={selectedRowData.size}
                    type="text"
                    onChange={(e) => editDetailInput('size', e.target.value)}
                  />
                </td>
              </tr>
            </thead>
          </Table>
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col" style={{ textAlign: 'center' }}>
              <label style={{ marginRight: 5, textAlign: 'center' }}>購入品選択</label>
            </Col>
          </Row>

          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* 購入品名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  {editMode ? (
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button className="cancleButton" onClick={showPurchaseTbl} style={{ pointerEvents: 'auto' }}>
                        購入品名称
                      </Button>
                    </span>
                  ) : (
                    <>
                      <label className="param_detail-title-label">購入品名称</label>
                    </>
                  )}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="purchaseName"
                    id="purchaseName"
                    value={selectedPurchaseDetail.name}
                    type="text"
                  />
                </td>
              </tr>
              {/* 仕入先 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">仕入先</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="supplier"
                    id="supplier"
                    value={selectedPurchaseDetail.supplier}
                    type="text"
                    /* onChange={(e) => editDetailInput('classnameCode', e.target.value)} */
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="unitPrice"
                    id="unitPrice"
                    value={selectedPurchaseDetail.unitPrice}
                    type="text"
                    /* onChange={(e) => editDetailInput('unitPrice', e.target.value)} */
                  />
                </td>
              </tr>
              {/* 取付時間*/}

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">取付時間</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="processTime"
                    id="processTime"
                    value={secondsToHms(selectedPurchaseDetail.processTime)}
                    type="text"
                    /* onChange={(e) => editDetailInput('gravity', e.target.value)} */
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="remark"
                    id="remark"
                    value={selectedRowData.remark}
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    type="text"
                    onChange={(e) => editDetailInput('remark', e.target.value)}
                  />
                </td>
              </tr>
            </thead>
          </Table>

          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 購入品検索 */}
            {visiblePurchaseTbl &&
              commonModal(
                visiblePurchaseTbl,
                modalTitle('購入品検索', 1000, -24),
                commonFooter(purchaseOK, purchaseCancel),
                null,
                1000,
                purchaseOK,
                purchaseCancel,
                purchaseTblContent(activeTabKey),
                'addNewAutoReserveModal',
                null,
                false
              )}
          </div>
        </div>
      </div>
    </>
  );
});

export default AutoReserveDetails;
