/**
 * クラス名：アプリの初期起動画面
 * 説明：最初に起動するファイルであり、ルーティングページを指定する。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import './App.css';
import React, { forwardRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Routes, Navigate, useParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';

import Login from './views/Login';
import MainLayout from './components/MainLayout';
import { updateAccessToken } from '../src/views/common/Common';
import { loginPath, getCurrentUserInfo } from '../src/views/common/CommonAPI';
import { ErrorMessage } from '../src/views/common/Message';

const MainLayoutRoutes = ['/home/*', '/:customer/*', '/:parentList/*'];

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // Activate the event listener
    this.setupBeforeUnloadListener();
  }
  async componentWillMount() {
    await this.getPathName();
  }
  // Browser閉じるの場合、ログイン情報を削除
  doSomethingBeforeUnload = () => {
    localStorage.removeItem('iQxWeb_LoginUser');
    localStorage.removeItem('iQxWeb_AccessToken');
    return '';
  };

  // Browser閉じるイベント
  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (ev) => {
      // ev.preventDefault();
      return this.doSomethingBeforeUnload();
    });
  };

  loginPath(message) {
    if (message) {
      return loginPath + '?ErrorMessage=' + message;
    } else {
      return loginPath;
    }
  }

  // パス名を取得する
  async getPathName() {
    try {
      let token = '';
      // パラメータ情報を取得する
      let params = new URLSearchParams(window.location.search);
      let pathName = window.location?.pathname;
      // パラメータがある場合、
      if (params.size !== 0 && pathName === '/autoLogin') {
        if (params.has('token') == true) {
          // パラメータのトークン情報を取得する
          token = params.get('token');
          // パラメータのトークン情報を削除する
          params.delete('token');
        }
        let result = await this.autoLogin(token);
        if (result?.statusCode == StatusCodes.OK) {
          // 正常の場合、
          token = result.updateToken;
          // セクションにトークン情報を保持する
          updateAccessToken(token);
          // ログインユーザー情報を取得する
          result = await getCurrentUserInfo(token);
          if (result === '') {
            // 取得正常の場合、ホームに遷移される
            window.location.replace(process.env.REACT_APP_FRONTEND_URL + 'home');
          } else {
            // 異常の場合、
            window.location.replace(this.loginPath(result));
          }
        } else if (result?.statusCode != StatusCodes.OK) {
          // 異常の場合、
          window.location.replace(this.loginPath(ErrorMessage()[result?.messageCode]));
        }
      } else if (localStorage.getItem('iQxWeb_AccessToken') && pathName === '/') {
        // 自動ログインはOnである場合、
        token = localStorage.getItem('iQxWeb_AccessToken');
        let result = await this.autoLogin(token);
        if (result?.statusCode == StatusCodes.OK && !result?.isExpired) {
          // 正常の場合、
          token = result.updateToken;
          // セクションにトークン情報を保持する
          updateAccessToken(token);
          // 取得正常の場合、ホームに遷移される
          window.location.replace(process.env.REACT_APP_FRONTEND_URL + 'home');
        } else {
          // 異常の場合、
          window.location.replace(this.loginPath());
        }
      }
    } catch (error) {
      // 異常の場合、
      window.location.replace(this.loginPath(error.message));
      return error;
    }
  }

  // 自動ログイン
  async autoLogin(token) {
    let ret;
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Login/AutoLogin';

      const otherParam = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          companyId: '',
          userName: token,
          password: '',
          rememberMe: false,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            window.location.replace(this.loginPath(ErrorMessage().E006));
            return;
          }
        })
        .then(async (data) => {
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data;
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            window.location.replace(this.loginPath(ErrorMessage()[data?.messageCode]));
          }
        })
        .catch((error) => {
          // 異常の場合、
          window.location.replace(this.loginPath(ErrorMessage().E010));
        });
    } catch (error) {
      // 異常の場合、
      window.location.replace(this.loginPath(error.message));
    }
    return ret;
  }

  render() {
    return (
      <Router>
        <Routes basename="iqx.webestimate">
          {/* ログイン */}
          <Route path="/login" element={<Login />} />
          {MainLayoutRoutes.map((path) => (
            <Route path={path} element={<MainLayout />} />
          ))}
          <Route path="*" element={<Navigate to="/login" replace />} />
          {/* 自動ログイン */}
          <Route path="/autoLogin" />
        </Routes>
      </Router>
    );
  }
}

export default App;
