/**
 * クラス名：係数パラメータ
 * 説明：係数情報詳細画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { Component, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, Row, Col, Divider, Button, Radio, Image, Form, DatePicker, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { WithRouter } from '../../../../components/WithRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import { today, TextArea } from '../../../common/Common';
import checked from '../../../../assets/images/checked.png';

const RadioGroup = Radio.Group;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const CoefficientDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    displayDetailInfo: displayDetailInfo,
  }));

  const editMode = props.editMode;

  const [id, setID] = useState('');
  const [display, setDisplay] = useState();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [coefficient, setCoefficient] = useState('');
  const [estimateDays, setEstimateDays] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNo, setSortNo] = useState();
  const [created, setCreated] = useState('');
  const [updated, setUpdated] = useState('');
  const [tableNo, setTableNo] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);

  const [isNoData, setIsNoData] = useState(false);

  // 選択した行の情報を設定
  useEffect(() => {
    let selectedRowData = props.selectedData;
    if (selectedRowData == null) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      setIsNoData(false);
    } else if (props.selectedData?.length === 0) {
      // 一覧の検索結果が無い場合、
      resetDetailInfo();
      setIsNoData(true);
      props.updateEditMode(false);
    } else if (tableNo !== selectedRowData?.tableNo || id !== selectedRowData?.id) {
      // 一覧の検索結果がある場合、
      displayDetailInfo(selectedRowData);
      setIsNoData(false);
      props.updateEditMode(false);
    }
  }, [props.selectedData]);

  // 編集モードより、「更新、破壊、編集」ボタンの表示・非表示を判断する
  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  // 詳細情報をリセットする。
  const resetDetailInfo = () => {
    setID('');
    setDisplay('');
    setName('');
    setCoefficient('');
    setEstimateDays('');
    setCode('');
    setRemarks('');
    setSortNo('');
    setCreated('');
    setUpdated('');
    setTableNo('');
  };

  // 詳細情報を表示する
  const displayDetailInfo = (data) => {
    setID(data.id);
    setDisplay(data.display);
    setName(data.name);
    setCoefficient(data.coefficient);
    setEstimateDays(data.estimateDays);
    setCode(data.code);
    setRemarks(data.remarks);
    setSortNo(data.sortNo);
    setCreated(data.created);
    setUpdated(data.updated);
    setTableNo(data.tableNo);
  };

  // 編集ボタンの押下
  const editData = () => {
    props.updateEditMode(true);
  };

  // 更新ボタンの押下
  const updateData = () => {
    setUpdateConfirm(true);
  };

  // 破壊ボタンの押下
  const discardData = () => {
    setCancelConfirm(true);
  };

  // 更新確認ポップアップのOKボタン押下
  const updConfirmOk = (e) => {
    props.updateEditMode(false);
    submitData();
    setUpdateConfirm(false);
  };

  // 更新確認ポップアップのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    displayDetailInfo(props.selectedData);
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 入力された情報を更新する
  const submitData = () => {
    if (props.selectedData && props.selectedData.id === id) {
      props.selectedData.display = display;
      props.selectedData.name = name;
      props.selectedData.coefficient = coefficient;
      props.selectedData.estimateDays = estimateDays;
      props.selectedData.code = code;
      props.selectedData.remarks = remarks;
      props.selectedData.sortNo = sortNo;
      props.selectedData.created = created;
      props.selectedData.updated = today;
      props.selectedData.tableNo = tableNo;
      props.submitData(props.selectedData);
    }
  };

  return (
    <>
      <Form>
        {/* ボタン部分 */}
        <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
          <div>
            <Button className="mainButton" style={{ marginLeft: 10, display: 'none' }} id="update" onClick={updateData}>
              更新
            </Button>
          </div>
          <div>
            <Button
              className="cancelButton"
              style={{ marginLeft: 10, display: 'none' }}
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
              編集
            </Button>
          </div>
        </div>
        {/* プロパティ表示 */}
        <div className="overflow-auto" style={{ height: 760, marginTop: 10 }}>
          <div id="showData">
            <Table id="param_detail_tbl" className="propertiesTbl">
              <thead>
                {/* 表示 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {editMode ? (
                      <RadioGroup
                        name="display"
                        defaultValue={display}
                        className="radioCustomerGpEdit"
                        onChange={(e) => setDisplay(e.target.value)}
                        value={display}
                      >
                        <Radio value={1}>オン</Radio>
                        <Radio value={0}>オフ</Radio>
                      </RadioGroup>
                    ) : display === 1 ? (
                      <Image preview={false} width={13} src={checked} id="imgOutsourcingFg" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                {/* コード */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">コード</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="code"
                      id="code"
                      value={code}
                      type="text"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 名称 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">名称</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="name"
                      id="name"
                      value={name}
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 係数 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">係数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="coefficient"
                      id="coefficient"
                      value={coefficient}
                      type="text"
                      onChange={(e) => setCoefficient(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 目安日数 */}
                {estimateDays ? (
                  <tr className="detail-tbl-row">
                    <td className="param-detail-tbl-val-title">
                      <label className="param_detail-title-label">目安日数</label>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Input
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        name="estimateDays"
                        id="estimateDays"
                        value={estimateDays}
                        type="text"
                        onChange={(e) => setEstimateDays(e.target.value)}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}

                {/* ソート番号 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">ソート番号</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="sortNo"
                      id="sortNo"
                      value={sortNo}
                      type="text"
                      onChange={(e) => setSortNo(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 作成日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">作成日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{created}</label>
                  </td>
                </tr>
                {/* 更新日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">更新日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{updated}</label>
                  </td>
                </tr>
                {/* 備考 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="remarks"
                      id="remarks"
                      value={remarks}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      type="text"
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
      </Form>
      {/* 更新確認_Modal */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/* 破棄確認_Modal */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default CoefficientDetails;
