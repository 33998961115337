import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Input, Button, Space, Select } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { commonModal } from '../../../common/CommonModal';
import { sameSizePatternValue } from '../../../common/Constant';

const Device_Sagyoukeisuu = forwardRef((props, ref) => {
  const [yousosuu, setYousosuu] = useState([]);
  const [yousosuuTemp, setYousosuuTemp] = useState([]);
  const [yousosuuLst, setYousosuuLst] = useState([]);
  const [shokiDankaiJikanLst, setShokiDankaiJikanLst] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isYousosuu, setIsYousosuu] = useState(false);
  const [programSameSizePatternValue, setProgramSameSizePatternValue] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    setYousosuu(props.yousosuuMasterData);
    setYousosuuTemp(props.yousosuuMasterData);
    setYousosuuLst(props.sagyouKeisuuYousosuuList);
    setShokiDankaiJikanLst(props.shokidankaiYousosuuList);
    setProgramSameSizePatternValue(props.programSameSizePatternValue);
  }, [
    props.yousosuuMasterData,
    props.editMode,
    props.processName,
    props.sagyouKeisuuYousosuuList,
    props.shokidankaiYousosuuList,
  ]);
  const openYousosuuModal = () => {
    setIsYousosuu(true);
  };
  const addYousosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>要素数</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const addYousosuu = (id) => {
    const copyData = yousosuuTemp.slice(); // Create a copy of the original array

    const insertIndex = yousosuuTemp.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: yousosuuTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setYousosuuTemp(copyData);
    }
  };
  const closeAddModal = () => {
    if (isYousosuu === true) {
      setIsYousosuu(false);
    }
  };
  const cancelMasterData = () => {
    setYousosuuTemp(yousosuu);
    setIsYousosuu(false);
  };
  const addYousosuuMasterData = () => {
    const deletedId = [];

    yousosuu.forEach((item1, index) => {
      const found = yousosuuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = yousosuuTemp
      .filter((item) => item.displayFlag && !yousosuu.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = yousosuuLst.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const deleteData = shokiDankaiJikanLst.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updateList = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = yousosuuTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = yousosuuTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updateList.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = yousosuuTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = yousosuuTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    setIsYousosuu(false);
    setYousosuuTemp(yousosuuTemp);

    setYousosuuLst(sortedList);
    props.updatedYousosuuMasterData(yousosuuTemp);

    props.updatedYousosuuList(sortedList);

    setShokiDankaiJikanLst(sortedList2);
    props.updatedShokidankaiYousosuuList(sortedList2);
  };
  const handleYousosuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(yousosuuTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].max;
    if (previousValue) {
      updatedData[updatedData.length - 1].max = (parseInt(previousValue) + 1).toString();
      setYousosuuTemp(updatedData);
    } else {
      setYousosuuTemp(updatedData);
    }
  };
  const deleteYousosuu = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(yousosuuTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = suuRyou[suuRyou.length - 2].val;
      if (previousValue) {
        updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
        setYousosuuTemp(updatedData);
      } else {
        setYousosuuTemp(updatedData);
      }
    }
  };
  const addYousosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }}>No</th>

                  <th style={{ width: '60%' }}>
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {yousosuuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleYousosuuChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={(e) => addYousosuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteYousosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addYousosuuMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const changeYousuu = (event, rowIndex, colIndex) => {
    const copyData = [...yousosuuLst];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setYousosuuLst(updatedData);
    props.updatedYousosuuList(updatedData);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              yousosuu.filter((i) => i.displayFlag === true).length > 7
                ? '100%'
                : yousosuu.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', verticalAlign: 'middle', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header">
              <Row justify="center" align="middle" className="tbl-header">
                <Space>
                  <label>（秒）</label>
                  <label>/</label>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={openYousosuuModal} style={{ pointerEvents: 'auto' }}>
                        要素数
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>要素数</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {yousosuu
              .filter((subitem) => subitem.displayFlag === true)
              .map((item) => (
                <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}</td>
              ))}
          </tr>

          {yousosuuLst.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '196px' }}>{row.materialType}</td>

              {row.lstData.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => changeYousuu(e, rowIndex, colIndex)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
        {/*同一サイズ、同一形状をパターン化する*/}
        <Table style={{ width: '30%' }}>
          <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
            <th className="tbl-header">同一サイズ、同一形状をパターン化する</th>
          </tr>
          <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <td style={{ backgroundColor: '#ffff !important' }}>
              <Select
                style={{ width: '98.2%', pointerEvents: editMode ? 'auto' : 'none' }}
                className="yousetsu-select"
                value={programSameSizePatternValue}
                onChange={(e) => {
                  setProgramSameSizePatternValue(e);
                  props.updatedProgramSameSizePatternValue(e);
                }}
              >
                {sameSizePatternValue.map((item) => (
                  <option value={item.value}>{item.label}</option>
                ))}
              </Select>
            </td>
          </tr>
        </Table>
      </div>
      {/* 要素数 */}
      {isYousosuu
        ? commonModal(
            isYousosuu,
            addYousosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addYousosuuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Sagyoukeisuu;
