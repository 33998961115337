import { Button, Row, Input, Col, Image, Space, Modal, Radio } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '../../assets/styles/common.css';
import child_add from '../../assets/images/child_add.png';
import minus_icon from '../../assets/images/minus_icon.png';
import { getTenjikaiSampleData } from '../common/Common';
import { commonTaiochuModal, commonModal } from '../common/CommonModal';
import { CloseOutlined } from '@ant-design/icons';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

const KouteibetsuInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [propsParam, setPropsParam] = useState(props.paramNm);
  const [showDetailRow, setShowDetailRow] = useState(true);
  const [showDetailRowIQ3, setShowDetailRowIQ3] = useState(true);
  const [showDetailRowIQ5, setShowDetailRowIQ5] = useState(true);
  const [showDetailRowIQ7, setShowDetailRowIQ7] = useState(true);
  const [showProcessTotalAmount, setShowProcessTotalAmount] = useState(false);
  const [showQuantityComponent, setShowQuantityComponent] = useState(false);
  const [activePartMenu, setActivePartMenu] = useState('unit');
  const [parentProcess, setParentProcess] = useState([]);
  const [iq3Process, setIq3Process] = useState([]);
  const [iq5Process, setIq5Process] = useState([]);
  const [setupTotalTime, setSetupTotalTime] = useState(0);
  const [processingTotalTime, setProcessingTotalTime] = useState(0);
  const [setupTotalAmount, setSetupTotalAmount] = useState(0);
  const [processingTotalAmount, setProcessingTotalAmount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tanka, setTanka] = useState(0);
  // iq3
  const [setupTotalTime_IQ3, setSetupTotalTime_IQ3] = useState(0);
  const [processingTotalTime_IQ3, setProcessingTotalTime_IQ3] = useState(0);
  const [setupTotalAmount_IQ3, setSetupTotalAmount_IQ3] = useState(0);
  const [processingTotalAmount_IQ3, setProcessingTotalAmount_IQ3] = useState(0);
  const [totalTime_IQ3, setTotalTime_IQ3] = useState(0);
  const [totalAmount_IQ3, setTotalAmount_IQ3] = useState(0);
  const [tanka_IQ3, setTanka_IQ3] = useState(0);
  const [iq3MaterialAmount, setIq3MaterialAmount] = useState(0);

  // iq5
  const [setupTotalTime_IQ5, setSetupTotalTime_IQ5] = useState(0);
  const [processingTotalTime_IQ5, setProcessingTotalTime_IQ5] = useState(0);
  const [setupTotalAmount_IQ5, setSetupTotalAmount_IQ5] = useState(0);
  const [processingTotalAmount_IQ5, setProcessingTotalAmount_IQ5] = useState(0);
  const [totalTime_IQ5, setTotalTime_IQ5] = useState(0);
  const [totalAmount_IQ5, setTotalAmount_IQ5] = useState(0);
  const [tanka_IQ5, setTanka_IQ5] = useState(0);
  const [iq5MaterialAmount, setIq5MaterialAmount] = useState(0);

  // all total
  const [setupAllTotalTime, setSetupAllTotalTime] = useState(0);
  const [setupAllTotalAmount, setSetupAllTotalAmount] = useState(0);
  const [processingAllTotalTime, setProcessingAllTotalTime] = useState(0);
  const [processingAllTotalAmount, setProcessingAllTotalAmount] = useState(0);
  const [allTotalTime, setAllTotalTime] = useState(0);
  const [allTotalAmount, setAllTotalAmount] = useState(0);
  const [tanka_all, setTanka_all] = useState(0);
  const [allMaterialAmount, setallMaterialAmount] = useState(0);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  useEffect(() => {
    console.log('targetParent--', props.selectedEstimateData);
    if (props.selectedEstimateData == undefined || props.selectedEstimateData == null) return;

    const tenjikaiSampleData = getTenjikaiSampleData();
    // const targetParent = tenjikaiSampleData.parentList[3];
    const targetParent = props.selectedEstimateData;

    let setupAllTotalTime = 0;
    let setupAllTotalAmount = 0;
    let processingAllTotalTime = 0;
    let processingAllTotalAmount = 0;
    // parent
    setParentProcess(targetParent.parentProcess);
    let setupAmount = 0;
    let processingAmount = 0;
    let setupTime = 0;
    let processingTime = 0;
    let totalTime = 0;
    let totalAmount = 0;
    if (targetParent.parentProcess != null) {
      for (let i = 0; i < targetParent.parentProcess.length; i++) {
        setupAmount += parseFloat(targetParent.parentProcess[i].setupAmount);
        processingAmount += parseFloat(targetParent.parentProcess[i].processingAmount);
        totalAmount +=
          parseFloat(targetParent.parentProcess[i].setupAmount) +
          parseFloat(targetParent.parentProcess[i].processingAmount);
        setupTime += setTimetoSec(targetParent.parentProcess[i].setupTime);
        processingTime += setTimetoSec(targetParent.parentProcess[i].processingTime);
        totalTime +=
          setTimetoSec(targetParent.parentProcess[i].setupTime) +
          setTimetoSec(targetParent.parentProcess[i].processingTime);
      }
      setSetupTotalAmount(setupAmount);
      setProcessingTotalAmount(processingAmount);
      setTotalAmount(totalAmount);
      setTanka(totalAmount);
      setSetupTotalTime(secondsToHms(setupTime));
      setProcessingTotalTime(secondsToHms(processingTime));
      setTotalTime(secondsToHms(totalTime));
    }

    // iq3
    let setupAmount_IQ3 = 0;
    let processingAmount_IQ3 = 0;
    let setupTime_IQ3 = 0;
    let processingTime_IQ3 = 0;
    let totalTime_IQ3 = 0;
    let totalAmount_IQ3 = 0;
    if (targetParent.iq3Process != null) {
      console.log('targetParent.iq3Process--', targetParent.iq3Process);
      for (let i = 0; i < targetParent.iq3Process.length; i++) {
        setupAmount_IQ3 += parseFloat(targetParent.iq3Process[i].setupAmount);
        processingAmount_IQ3 += parseFloat(targetParent.iq3Process[i].processingAmount);
        totalAmount_IQ3 +=
          parseFloat(targetParent.iq3Process[i].setupAmount) + parseFloat(targetParent.iq3Process[i].processingAmount);
        setupTime_IQ3 += setTimetoSec(targetParent.iq3Process[i].setupTime);
        processingTime_IQ3 += setTimetoSec(targetParent.iq3Process[i].processingTime);
        totalTime_IQ3 +=
          setTimetoSec(targetParent.iq3Process[i].setupTime) + setTimetoSec(targetParent.iq3Process[i].processingTime);
      }
      setSetupTotalAmount_IQ3(setupAmount_IQ3);
      setProcessingTotalAmount_IQ3(processingAmount_IQ3);
      setTotalAmount_IQ3(totalAmount_IQ3);
      setTanka_IQ3(totalAmount_IQ3);
      setSetupTotalTime_IQ3(secondsToHms(setupTime_IQ3));
      setProcessingTotalTime_IQ3(secondsToHms(processingTime_IQ3));
      setTotalTime_IQ3(secondsToHms(totalTime_IQ3));
      setIq3MaterialAmount(targetParent.iq3MaterialAmount);
      setIq3Process(targetParent.iq3Process);
    }

    // iq5
    let setupAmount_IQ5 = 0;
    let processingAmount_IQ5 = 0;
    let setupTime_IQ5 = 0;
    let processingTime_IQ5 = 0;
    let totalTime_IQ5 = 0;
    let totalAmount_IQ5 = 0;
    if (targetParent.iq5Process != null) {
      for (let i = 0; i < targetParent.iq5Process.length; i++) {
        setupAmount_IQ5 += parseFloat(targetParent.iq5Process[i].setupAmount);
        processingAmount_IQ5 += parseFloat(targetParent.iq5Process[i].processingAmount);
        totalAmount_IQ5 +=
          parseFloat(targetParent.iq5Process[i].setupAmount) + parseFloat(targetParent.iq5Process[i].processingAmount);
        setupTime_IQ5 += setTimetoSec(targetParent.iq5Process[i].setupTime);
        processingTime_IQ5 += setTimetoSec(targetParent.iq5Process[i].processingTime);
        totalTime_IQ5 +=
          setTimetoSec(targetParent.iq5Process[i].setupTime) + setTimetoSec(targetParent.iq5Process[i].processingTime);
      }
      setSetupTotalAmount_IQ5(setupAmount_IQ5);
      setProcessingTotalAmount_IQ5(processingAmount_IQ5);
      setTotalAmount_IQ5(totalAmount_IQ5);
      setTanka_IQ5(totalAmount_IQ5);
      setSetupTotalTime_IQ5(secondsToHms(setupTime_IQ5));
      setProcessingTotalTime_IQ5(secondsToHms(processingTime_IQ5));
      setTotalTime_IQ5(secondsToHms(totalTime_IQ5));
      setIq5MaterialAmount(targetParent.iq5MaterialAmount);
      setIq5Process(targetParent.iq5Process);
    }
    // All total
    setSetupAllTotalAmount(setupAmount + setupAmount_IQ3 + setupAmount_IQ5);
    setSetupAllTotalTime(secondsToHms(setupTime + setupTime_IQ3 + setupTime_IQ5));
    setProcessingAllTotalAmount(processingAmount + processingAmount_IQ3 + processingAmount_IQ5);
    setProcessingAllTotalTime(secondsToHms(processingTime + processingTime_IQ3 + processingTime_IQ5));
    setAllTotalTime(
      secondsToHms(setupTime + setupTime_IQ3 + setupTime_IQ5 + processingTime + processingTime_IQ3 + processingTime_IQ5)
    );
    setAllTotalAmount(
      setupAmount + setupAmount_IQ3 + setupAmount_IQ5 + processingAmount + processingAmount_IQ3 + processingAmount_IQ5
    );
    setTanka_all(
      setupAmount + setupAmount_IQ3 + setupAmount_IQ5 + processingAmount + processingAmount_IQ3 + processingAmount_IQ5
    );
    setallMaterialAmount(targetParent.iq3MaterialAmount + targetParent.iq5MaterialAmount);
    setShowProcessTotalAmount(true);
  }, [props.selectedEstimateData]);

  const partMenuChange = (e) => {
    console.log('onchange--', e.target.value);
    setActivePartMenu(e.target.value);
  };
  const secondsToHms = (totalIncSec) => {
    if (totalIncSec > 0) {
      var h = Math.floor(totalIncSec / 3600).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      var m = Math.floor((totalIncSec % 3600) / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      var s = Math.floor((totalIncSec % 3600) % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      return h + ':' + m + ':' + s;
    } else {
      return '00:00:00';
    }
  };

  const setTimetoSec = (timeStr) => {
    var splitTime = timeStr.split(':');
    var seconds = +splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2];
    return seconds;
  };
  // 開発中ポップアップ呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  /* 工程別合計コンポーネント開始 */
  const tblTile = (
    <>
      <Row className="estimate-tab2-title-row">
        <Col span={1} className="estimate-tab2-title-col">
          No.
        </Col>
        <Col span={5} className="estimate-tab2-title-col">
          工程
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          段取時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          段取金額
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          加工時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          加工金額
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          合計時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計金額
        </Col>
        <Col span={3} className="estimate-tab2-title-col" style={{ display: activePartMenu === 'unit' ? '' : 'none' }}>
          単価
        </Col>
      </Row>
    </>
  );
  const totalRow = (
    <>
      {tblTile}
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col-total"></Col>
        <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
          合計
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {setupAllTotalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {setupAllTotalAmount != undefined
            ? '¥' +
              setupAllTotalAmount.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0}
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {processingAllTotalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {processingAllTotalAmount != undefined
            ? '¥' +
              processingAllTotalAmount.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0}
        </Col>
        <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allTotalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allTotalAmount != undefined
            ? '¥' +
              allTotalAmount.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {tanka_all != undefined
            ? '¥' +
              tanka_all.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0}
        </Col>
      </Row>
      <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
        <Col span={16}></Col>
        <Col
          span={2}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ textAlign: 'right', paddingRight: '25px' }}
        >
          材料費
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allMaterialAmount != undefined
            ? '¥' +
              allMaterialAmount.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {allMaterialAmount != undefined
            ? '¥' +
              allMaterialAmount.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0}
        </Col>
      </Row>
    </>
  );
  // let parentItems = [
  //   {
  //     id: 1,
  //     process: `親部品工程_1`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `親部品工程_2`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  // ];
  let parentItemComponent = [];
  parentProcess != null
    ? parentProcess.forEach((item, index) => {
        parentItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.setupTime}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount != undefined
                ? '¥' +
                  item.setupAmount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.processingTime}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount != undefined
                ? '¥' +
                  item.processingAmount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
              {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {item.setupAmount != undefined
                ? '¥' +
                  (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
            >
              {item.setupAmount != undefined
                ? '¥' +
                  (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
          </Row>
        );
      })
    : '';
  const parentTBL = (
    <>
      <div>
        {tblTile}
        {showDetailRow ? parentItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount != undefined
              ? '¥' +
                setupTotalAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount != undefined
              ? '¥' +
                processingTotalAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalTime}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount != undefined
              ? '¥' +
                totalAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {tanka != undefined
              ? '¥' +
                tanka.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq3工程 */
  // let iq3Items = [
  //   {
  //     id: 1,
  //     process: `プログラム`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `レーザー`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  // ];
  let iq3ItemComponent = [];
  iq3Process != null
    ? iq3Process.forEach((item, index) => {
        iq3ItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.setupTime}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount != undefined
                ? '¥' +
                  item.setupAmount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.processingTime}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount != undefined
                ? '¥' +
                  item.processingAmount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
              {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {item.setupAmount != undefined
                ? '¥' +
                  (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
            >
              {item.setupAmount != undefined
                ? '¥' +
                  (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
          </Row>
        );
      })
    : '';
  const iq3TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ3 ? iq3ItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime_IQ3}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount_IQ3 != undefined
              ? '¥' +
                setupTotalAmount_IQ3.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime_IQ3}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount_IQ3 != undefined
              ? '¥' +
                processingTotalAmount_IQ3.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalTime_IQ3}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount_IQ3 != undefined
              ? '¥' +
                totalAmount_IQ3.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {tanka_IQ3 != undefined
              ? '¥' +
                tanka_IQ3.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
          <Col span={16}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px' }}
          >
            材料費
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {iq3MaterialAmount != undefined
              ? '¥' +
                iq3MaterialAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {iq3MaterialAmount != undefined
              ? '¥' +
                iq3MaterialAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq5工程 */
  // let iq5Items = [
  //   {
  //     id: 1,
  //     process: `切断`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `穴`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  // ];
  let iq5ItemComponent = [];
  iq5Process != null
    ? iq5Process.forEach((item, index) => {
        iq5ItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.setupTime}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount != undefined
                ? '¥' +
                  item.setupAmount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.processingTime}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount != undefined
                ? '¥' +
                  item.processingAmount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
              {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {item.setupAmount != undefined
                ? '¥' +
                  (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
            >
              {item.setupAmount != undefined
                ? '¥' +
                  (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })
                : 0}
            </Col>
          </Row>
        );
      })
    : '';
  const iq5TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ5 ? iq5ItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime_IQ5}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount_IQ5 != undefined
              ? '¥' +
                setupTotalAmount_IQ5.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime_IQ5}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount_IQ5 != undefined
              ? '¥' +
                processingTotalAmount_IQ5.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalTime_IQ5}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount_IQ5 != undefined
              ? '¥' +
                totalAmount_IQ5.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {tanka_IQ5 != undefined
              ? '¥' +
                tanka_IQ5.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
          <Col span={16}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px' }}
          >
            材料費
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {iq5MaterialAmount != undefined
              ? '¥' +
                iq5MaterialAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {iq5MaterialAmount != undefined
              ? '¥' +
                iq5MaterialAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq7工程 */
  // let iq7Items = [
  //   {
  //     id: 1,
  //     process: `切断`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `穴`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //   },
  // ];
  // let iq7ItemComponent = [];
  // iq7Items.forEach((item, index) => {
  //   iq7ItemComponent.push(
  //     <Row className="estimate-tab2-row">
  //       <Col className="estimate-tab2-col" style={{ width: 50 }}>
  //         {item.id}
  //       </Col>
  //       <Col className="estimate-tab2-col" style={{ textAlign: 'center' }}>
  //         {item.process}
  //       </Col>
  //       <Col className="estimate-tab2-col">{item.setupTime}</Col>
  //       <Col className="estimate-tab2-col">{item.setupAmount}</Col>
  //       <Col className="estimate-tab2-col">{item.setupTime}</Col>
  //       <Col className="estimate-tab2-col">{item.processTime}</Col>
  //       <Col className="estimate-tab2-col">{item.processAmount}</Col>
  //       <Col className="estimate-tab2-col">{item.totalTime}</Col>
  //       <Col className="estimate-tab2-col">{item.totalAmount}</Col>
  //     </Row>
  //   );
  // });
  // const iq7TBL = (
  //   <>
  //     <div>
  //       {tblTile}
  //       {showDetailRowIQ7 ? iq7ItemComponent : ''}
  //       <Row className="estimate-tab2-row">
  //         <Col className="estimate-tab2-col-total" style={{ width: 50 }}></Col>
  //         <Col className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
  //           合計
  //         </Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //       </Row>
  //     </div>
  //   </>
  // );
  /* 工程別合計コンポーネント */

  const showDetail = (e, type) => {
    if (type == 'parent') {
      setShowDetailRow(false);
    } else if (type == 'iq3') {
      setShowDetailRowIQ3(false);
    } else if (type == 'iq5') {
      setShowDetailRowIQ5(false);
    } else if (type == 'iq7') {
      setShowDetailRowIQ7(false);
    }
  };
  const showHide = (e, type) => {
    if (type === 'parent') {
      setShowDetailRow(true);
    } else if (type === 'iq3') {
      setShowDetailRowIQ3(true);
    } else if (type === 'iq5') {
      setShowDetailRowIQ5(true);
    } else if (type === 'iq7') {
      setShowDetailRowIQ7(true);
    }
  };
  const processTotalAmountComponent = (
    <>
      <div style={{ overflowY: 'auto', marginTop: 15, height: '715px' }}>
        <Row id="totalRow" style={{ marginBottom: '5px' }}>
          <div style={{ float: 'left', width: '60%' }}>
            <Radio.Group value={activePartMenu} onChange={partMenuChange}>
              <Radio.Button value="total" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>合計</p>
              </Radio.Button>
              <Radio.Button value="unit" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>単品</p>
              </Radio.Button>
            </Radio.Group>
          </div>

          <div style={{ float: 'right', width: '40%' }}>
            <Button key="submit" type="primary" className="csvoutput" onClick={commonTaiochuPopupOpen}>
              CSV出力
            </Button>
          </div>
        </Row>
        {/* <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="合計" />
        </Row> */}
        {totalRow}

        <Row id="parentTitleRow" style={{ marginTop: '13px' }}>
          <Input className="estimate-tab2-tbl-thead" value="親部品工程" />
          {showDetailRow ? (
            <a onClick={(e) => showDetail(e, 'parent')}>
              {/* <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} /> */}
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'parent')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
              {/* <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} /> */}
            </a>
          )}
        </Row>
        {parentTBL}

        <Row id="iq3TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="板金子部品工程" />
          {showDetailRowIQ3 ? (
            <a onClick={(e) => showDetail(e, 'iq3')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq3')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {iq3TBL}
        {/* <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="形鋼子部品工程" />
          {showDetailRowIQ5 ? (
            <a onClick={(e) => showDetail(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {iq5TBL} */}
        {/* <Row id="iq7TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="機械子部品工程" />
          {showDetailRowIQ7 ? (
            <a onClick={(e) => showDetail(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        <Row id="iq7Row" style={{ marginTop: 3 }}>
          {iq7TBL}
        </Row> */}
      </div>
    </>
  );
  /* 工程別合計コンポーネント 終了*/

  const confirmCancel = () => {
    setShowProcessTotalAmount(false);
    setShowQuantityComponent(false);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title={<p style={{ fontSize: '14px', fontWeight: '600', margin: 0 }}>工程別合計</p>}
        width={1150}
        open={showProcessTotalAmount}
        onOk={null}
        onCancel={confirmCancel}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'black', fontSize: '13.5px' }} />}
      >
        {processTotalAmountComponent}
      </Modal>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
};

export default KouteibetsuInfo;
