import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import '../../assets/styles/common.css';
import IQ3KouteiCalculate_Program from './IQ3KouteiCalculate_Program';
import IQ3KouteiCalculate_2jikakou from './IQ3KouteiCalculate_2jikakou';
import IQ3KouteiCalculate_MenualBari from './IQ3KouteiCalculate_MenualBari';
import IQ3KouteiCalculate_AutoBari from './IQ3KouteiCalculate_AutoBari';
import IQ3KouteiCalculate_Hyomen from './IQ3KouteiCalculate_Hyomen';
import IQ3KouteiCalculate_Blank from './IQ3KouteiCalculate_Blank';
import IQ3KouteiCalculate_Bending from './IQ3KouteiCalculate_Bending';
import IQ3KouteiCalculate_AddtionalProcess from './IQ3KouteiCalculate_AddtionalProcess';
import IQ3KouteiCalculate_Yosetsu from './IQ3KouteiCalculate_Yosetsu';
import IQ3KouteiCalculate_YosetsuFinish from './IQ3KouteiCalculate_YosetsuFinish';

import { Row, Col } from 'antd';
import '../../assets/styles/common.css';

const KouteiCalculate = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [iq3KouteiCalInfo, setIq3KouteiCalInfo] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [programEditData, setProgramEditData] = useState([]);
  const [iq3SelectedDataFlg, setiq3SelectedDataFlg] = useState(0);
  const iq3KouteiCalculateRef = useRef(ref);

  useEffect(() => {
    console.log('props.selectedDataDetail---1', props.selectedIQ3DataDetail);
    if (props.selectedIQ3DataDetail == undefined || props.selectedIQ3DataDetail?.length == 0) {
      setProgramData([]);
      setProgramEditData([]);
    } else {
      // 編集データ設定
      if (props.selectedIQ3DataDetail.iq3KouteiCalInfoEditData != undefined) {
        let kouteiCalInfo = props.selectedIQ3DataDetail?.iq3KouteiCalInfoEditData;
        setIq3KouteiCalInfo(kouteiCalInfo);
        kouteiCalInfo?.forEach((info) => {
          let data = info;
          // プログラム
          if (data.kouteiName == 'program') {
            const programEdit = data.kouteiContent;
            setProgramEditData(programEdit);
          }
        });
      } else {
        setProgramEditData([]);
      }

      // データ設定
      if (props.selectedIQ3DataDetail.iq3KouteiCalInfoData != undefined) {
        let kouteiCalInfoData = props.selectedIQ3DataDetail?.iq3KouteiCalInfoData;
        kouteiCalInfoData?.forEach((info) => {
          let data = info;
          // プログラム
          if (data.kouteiName == 'program') {
            const programData = data.kouteiContent;
            setProgramData(programData);
          }
        });
      } else {
        setProgramData([]);
      }
    }
  }, [props.selectedIQ3DataDetail]);

  const sendSelectedDataFlg = (flg) => {
    setiq3SelectedDataFlg(flg);
  };

  const updateIQ3KouteiCal = (kouteiName, kouteiContent) => {
    let updatedKouteiContent = JSON.parse(JSON.stringify(iq3KouteiCalInfo));
    updatedKouteiContent.forEach((info) => {
      let data = info;
      if (data.kouteiName == kouteiName) {
        data.kouteiContent = JSON.parse(JSON.stringify(kouteiContent));
      }
    });
    setIq3KouteiCalInfo(updatedKouteiContent);
  };

  useImperativeHandle(
    ref,
    () => ({
      getIQ3KouteiCalInfo: () => {
        return iq3KouteiCalInfo;
      },
      getIQ3SelectedFlg: () => {
        return iq3SelectedDataFlg;
      },
    }),
    [iq3KouteiCalInfo, iq3SelectedDataFlg]
  );

  return (
    <>
      <div className="overflow-auto kouteical KouteiCalculate">
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row ">
          <Col span={4} className="oyabuhin-col">
            <div style={{ display: 'flex' }}>
              <label style={{ marginLeft: 5, fontWeight: 700 }}>工程名</label>
            </div>
          </Col>
          <Col span={6} className="oyabuhin-col">
            <div>
              <b>工程積算費用</b>
            </div>
          </Col>
          <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
            <div>
              <label style={{ marginLeft: 5, fontWeight: 700 }}> 装置名</label>
            </div>
          </Col>
        </Row>
        {/* プログラム */}
        {
          <IQ3KouteiCalculate_Program
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateBlank={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_Program>
        }
        {/* プログラム */}
        {/* プランク  */}
        {
          <IQ3KouteiCalculate_Blank
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateBlank={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_Blank>
        }
        {/* プランク  */}
        {/* ベンディング  */}
        {
          <IQ3KouteiCalculate_Bending
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateBending={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_Bending>
        }
        {/* ベンディング   */}
        {/* 2次加工  */}
        {
          <IQ3KouteiCalculate_2jikakou
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            update2jikakou={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_2jikakou>
        }
        {/* 2次加工  */}
        {/* 自動バリ  */}
        {
          <IQ3KouteiCalculate_AutoBari
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateAutoBari={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_AutoBari>
        }
        {/* 自動バリ  */}
        {/* 手動バリ  */}
        {
          <IQ3KouteiCalculate_MenualBari
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateMenualBari={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_MenualBari>
        }
        {/* 手動バリ  */}
        {/* 溶接  */}
        {
          <IQ3KouteiCalculate_Yosetsu
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateHyomen={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_Yosetsu>
        }
        {/* 溶接  */}
        {/* 溶接仕上 */}
        {
          <IQ3KouteiCalculate_YosetsuFinish
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateHyomen={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_YosetsuFinish>
        }
        {/* 溶接仕上 */}
        {/* 表面処理  */}
        {
          <IQ3KouteiCalculate_Hyomen
            ref={iq3KouteiCalculateRef}
            selectedDataDetail={props.selectedIQ3DataDetail}
            editMode={props.editMode}
            updateHyomen={updateIQ3KouteiCal}
          ></IQ3KouteiCalculate_Hyomen>
        }
        {/* 表面処理  */}
      </div>
    </>
  );
});

export default KouteiCalculate;
