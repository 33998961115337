/**
 * クラス名：自動引当一覧画面
 * 説明：iQ3板金に使用の自動引当一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Button, Row, Input, Col, Modal, Select, Space, Image, Radio, Tabs } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined, StarFilled } from '@ant-design/icons';
import AutoReserveDetails from './AutoReserveDetails';
import 'split-pane-react/esm/themes/default.css';
import SplitPane from 'split-pane-react';

import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmCreateContent,
  confirmDeleteContent,
  commonActionFooter,
  confirmUpdateContent,
} from '../../../common/CommonModal';
import { getKubunNameDDL, actionArr, dataStateList, secondsToHms } from '../../../common/Common.js';
import CommonPurchaseList from '../../../common/CommonPurchaseList';
import { getPurchaseInfo } from '../../../common/CommonAPI';

dayjs.extend(customParseFormat);

const { Option } = Select;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

export const categoryList = [
  {
    key: 0,
    categoryName: '親部品',
  },
  {
    key: 1,
    categoryName: 'iQ3板金子部品',
  },
];

export const createKouteiDDL = (category) => {
  let kouteiDDL = [];
  switch (category) {
    case '親部品':
      kouteiDDL.push({
        key: 0,
        koutei: '溶接',
      });
      break;

    case 'iQ3板金子部品':
      kouteiDDL.push(
        {
          key: 0,
          koutei: '溶接',
        },
        {
          key: 1,
          koutei: '2次加工',
        }
      );
      break;
  }
  /* setProcessDDLArr(kouteiDDL); */
  return kouteiDDL;
};

export const getProcessTypeDDL = (category, process) => {
  let processTypeDDL = [];
  const kouteiMasterData =
    window.localStorage.getItem('kouteiMasterData') != undefined
      ? JSON.parse(window.localStorage.getItem('kouteiMasterData'))
      : [];
  let categoryName = category === 'iQ3板金子部品' ? 'iQ3' : category;

  let processDetail = kouteiMasterData.filter((item) => {
    if (categoryName === item.type && process === item.processName) {
      return item;
    }
  })[0]?.processDetail;
  // console.log(processDetail);
  let processDetailValue = [];
  if (processDetail !== undefined) {
    switch (process) {
      case '溶接':
        if (processDetail[2]?.processDetailTitle === '取付種類（サイズ判断）') {
          processDetailValue = processDetail[2]?.processDetailValue;
        }
        break;
      case '2次加工':
        if (processDetail[0]?.processDetailTitle === '2次加工種類') {
          processDetailValue = processDetail[0]?.processDetailValue;
        }
        break;
    }
    let count = 0;
    processDetailValue?.forEach((item) => {
      item.displayFlg &&
        processTypeDDL.push({
          key: count++,
          koutei: item.detailType,
        });
    });
  }
  return processTypeDDL;
  /* setProcessTypeDDLArr(processTypeDDL); */
};

const AutoReserveList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const detailRef = useRef();

  const [paramType, setParamType] = useState('iq3');
  const [autoReserve, setAutoReserve] = useState([]);
  const [tmpAutoReserve, setTmpAutoReserve] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState();

  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(0);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(true);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [latestData, setLatestData] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();

  const [activeTabKey, setActiveTabKey] = useState('1');

  const [is2jikakou, setIs2jikakou] = useState(false);
  const [isParentYousetsu, setIsParentYousetsu] = useState(false);
  const [isIQ3Yousetsu, setIsIQ3Yousetsu] = useState(false);

  const [nijikakouArr, setNijikakouArr] = useState([]);
  const [parentYousetsuArr, setParentYousetsuArr] = useState([]);
  const [iQ3YousetsuArr, setIQ3YousetsuArr] = useState([]);

  const [selectedPurchasesData, setSelectedPurchasesData] = useState(false);
  const [categoryDDL, setCategoryDDL] = useState();
  const [processDDL, setProcessDDL] = useState();
  const [processTypeDDL, setProcessTypeDDL] = useState();
  const [processDDLArr, setProcessDDLArr] = useState([]);
  const [processTypeDDLArr, setProcessTypeDDLArr] = useState([]);

  const [keyword, setKeyWord] = useState('');
  const [isResize, setIsResize] = useState(false);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [purchaseMstInfo, setPurchaseMstInfo] = useState([]);

  const createGpWithProcessType = (tblData) => {
    const separatedData = tblData.reduce((result, item) => {
      const processType = item.processType;
      if (!result[processType]) {
        result[processType] = [];
      }
      result[processType].push(item);
      return result;
    }, {});

    return Object.entries(separatedData).map(([processType, data]) => ({
      ['processType']: processType,
      ['category']: data[0].category,
      ['process']: data[0].process,
      ['processTypeArr']: data,
    }));
  };

  const categorizedAutoReserve = (dataArr, filterFlg) => {
    let parentYousetsu = [];
    let iQ3Yousetsu = [];
    let nijikakou = [];

    let catAutoReserveArr = [];

    filterFlg ? (catAutoReserveArr = [...dataArr]) : (catAutoReserveArr = [...autoReserve]);

    catAutoReserveArr?.forEach((item) => {
      switch (item.category) {
        case '親部品': {
          parentYousetsu.push(item);
          break;
        }

        case 'iQ3板金子部品':
          switch (item.process) {
            case '溶接':
              iQ3Yousetsu.push(item);
              break;

            case '2次加工':
              nijikakou.push(item);
              break;
          }
          break;
      }
    });
    setParentYousetsuArr(createGpWithProcessType(parentYousetsu));
    setIQ3YousetsuArr(createGpWithProcessType(iQ3Yousetsu));
    setNijikakouArr(createGpWithProcessType(nijikakou));
    setIsFilter(false);
  };

  useEffect(() => {
    let autoReserveSample =
      window.localStorage.getItem('AutoReserveStorage') != undefined
        ? JSON.parse(window.localStorage.getItem('AutoReserveStorage'))
        : [];

    setAutoReserve(autoReserveSample);
    setFilteredData(autoReserveSample);
    setTmpAutoReserve(autoReserveSample);
    // setSelectedRowKeys(
    //   autoReserveSample !== undefined && autoReserveSample?.length > 0
    //     ? autoReserveSample[autoReserveSample?.length - 1].key
    //     : -1
    // );
    // setSelectedData(
    //   autoReserveSample !== undefined && autoReserveSample?.length > 0
    //     ? autoReserveSample[autoReserveSample?.length - 1]
    //     : []
    // );
    setSelectedRowKeys(1);
    setSelectedData(autoReserveSample !== undefined && autoReserveSample?.length > 0 ? autoReserveSample[1] : []);
    setIsFilter(false);
    setCategoryDDL('defaultValue');
    setProcessDDL('defaultValue');
    setLatestData(false);
  }, []);

  useEffect(() => {
    categorizedAutoReserve(autoReserve, false);
  }, [autoReserve]);

  // 購入品マスタ情報を取得する
  const getPurchaseMstData = async () => {
    let purchaseMstData = await getPurchaseInfo();
    setPurchaseMstInfo(purchaseMstData);
  };

  const getPurchaseData = (data) => {
    setSelectedPurchasesData(data[0]);
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      sorter: (a, b) => a.key - b.key,
      sortOrder: 'ascend',
      render: (text, record, index) => <span>{index + 1}</span>,
      className: 'cm-a-right',
    },
    {
      id: '2',
      title: '表示',
      dataIndex: 'display',
      width: 95,
      render: (index, item) => {
        return item['display'] ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            key={item['key']}
            id={item['id']}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      key: 'materialClass',
      title: '材質区分',
      dataIndex: 'materialClass',
      width: 95,
    },
    {
      key: 'size',
      title: 'サイズ',
      dataIndex: 'size',
      width: 50,
    },
    {
      key: 'purchaseName',
      title: '購入品名称',
      dataIndex: 'purchaseName',
      render: (text, record, index) => <span>{record.purchaseDetail.name}</span>,
      width: 150,
    },
    {
      key: 'suppiler',
      title: '仕入先',
      dataIndex: 'suppiler',
      render: (text, record, index) => <span>{record.purchaseDetail.supplier}</span>,
      width: 150,
    },
    {
      key: 'unitPrice',
      title: '単価',
      dataIndex: 'unitPrice',
      render: (text, record, index) => <span>{record.purchaseDetail.unitPrice}</span>,
      width: 80,
      className: 'cm-a-right',
    },
    {
      key: 'processTime',
      title: '取付時間',
      dataIndex: 'processTime',
      render: (text, record, index) => <span>{secondsToHms(record.purchaseDetail.processTime)}</span>,
      width: 100,
      className: 'cm-a-right',
    },
    {
      key: 'remark',
      title: '備考',
      dataIndex: 'remark',
      render: (text, record, index) => <span>{record.remark}</span>,
      width: 200,
    },
  ];

  const tabItems = [
    {
      key: '1',
      label: `検索`,
      children: <CommonPurchaseList data={purchaseMstInfo} getPurchaseData={getPurchaseData} />,
    },
    {
      key: '2',
      label: <StarFilled />,
      children: (
        <CommonPurchaseList
          data={purchaseMstInfo.filter((item) => item.info.isAllocation)}
          getPurchaseData={getPurchaseData}
        />
      ),
    },
  ];

  const onChange = (key) => {
    setActiveTabKey(key);
  };

  const addModalContent = (
    <div id="addNewAutoReserve" style={{ marginTop: 8 }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <td className="param-detail-tbl-val-title">表示</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
              <RadioGroup
                name="displayFlag"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTmpAutoReserve({ ...tmpAutoReserve, display: e.target.value });
                  }
                }}
                value={tmpAutoReserve.display}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">種別</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTmpAutoReserve({ ...tmpAutoReserve, category: e });
                    setCategoryDDL(e);
                    setProcessDDL('defaultValue');
                    setProcessTypeDDL('defaultValue');
                    setProcessTypeDDLArr([]);
                  }
                }}
                id="categroyDDL"
                name="categroyDDL"
                //value={categoryDDL}
                defaultValue={categoryDDL}
              >
                <Option id="defaultValue" value="defaultValue">
                  種別を選択でください。
                </Option>
                {[...categoryList].map((item) => (
                  <option value={item.categoryName} id={item.categoryName}>
                    {item.categoryName}
                  </option>
                ))}
              </Select>
              {/* </div> */}
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">工程</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTmpAutoReserve({ ...tmpAutoReserve, process: e });
                    setProcessDDL(e);
                    setProcessTypeDDL('defaultValue');
                  }
                }}
                id="processDDL"
                name="processDDL"
                value={processDDL}
                defaultValue="defaultValue"
              >
                <Option id="defaultValue" value="defaultValue">
                  工程を選択でください。
                </Option>
                {processDDLArr.map((item) => (
                  <option id={'process' + item.key} value={item.koutei}>
                    {item.koutei}
                  </option>
                ))}
              </Select>
              {/* </div> */}
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">加工</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTmpAutoReserve({ ...tmpAutoReserve, processType: e });
                    setProcessTypeDDL(e);
                  }
                }}
                id="processTypeDDL"
                name="processTypeDDL"
                value={processTypeDDL}
                defaultValue="defaultValue"
              >
                <Option id="defaultValue" value="defaultValue">
                  加工を選択でください。
                </Option>
                {
                  /* getProcessTypeDDL() */ processTypeDDLArr.map((item) => (
                    <option id={'processType' + item.id} value={item.koutei}>
                      {item.koutei}
                    </option>
                  ))
                }
              </Select>
              {/* </div> */}
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">材質区分名</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTmpAutoReserve({ ...tmpAutoReserve, materialClass: e });
                  }
                }}
                id="materialClassDDL"
                name="materialClassDDL"
                /* value={tmpAutoReserve.AutoReserve} */
                defaultValue="defaultValue"
              >
                <Option id="defaultValue" value="defaultValue">
                  材質区分名を選択でください。
                </Option>
                {getKubunNameDDL().map((item) => (
                  <option id={'materialClass' + item.value} value={item.label}>
                    {item.label}
                  </option>
                ))}
              </Select>
              {/* </div> */}
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材質名称</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tmpAutoReserve.size}
                onChange={(e) => {
                  {
                    setTmpAutoReserve({ ...tmpAutoReserve, size: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
        </thead>
      </table>
      {/** 一覧 */}
      <div>
        <Tabs
          className="estimate-detail-tab"
          type="card"
          defaultActiveKey="1"
          activeKey={activeTabKey}
          items={tabItems}
          onChange={onChange}
        />
      </div>
    </div>
  );

  const changeParentYousetsu = () => {
    if (isParentYousetsu == true) {
      setIsParentYousetsu(false);
    } else {
      setIsParentYousetsu(true);
    }
    setLatestData(true);
    setCategoryDDL('親部品');
    setProcessDDL('溶接');
  };

  const changeIQ3Yousetsu = () => {
    if (isIQ3Yousetsu == true) {
      setIsIQ3Yousetsu(false);
    } else {
      setIsIQ3Yousetsu(true);
    }
    setLatestData(true);
    setCategoryDDL('iQ3板金子部品');
    setProcessDDL('溶接');
  };

  const change2jikakou = () => {
    if (is2jikakou == true) {
      setIs2jikakou(false);
    } else {
      setIs2jikakou(true);
    }
    setLatestData(true);
    setCategoryDDL('iQ3板金子部品');
    setProcessDDL('2次加工');
  };

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  const onRowSelect = (record) => {
    setChangeRecordKey(record.key);
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setEditModeCancelConfirm(true);
    } else {
      setSelectedRowKeys(record.key);
      setSelectedData(record);
      //navigate(':iq3.AutoReserve');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    //setCancelConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    // setVisibleCSVOutput(false);
    // setVisibleAddNewModal(false);
    // setVisibleCSVInput(false);
    // setVisibleXMLInput(false);
    setVisibleAddNewModal(false);
  };

  const updConfirmOk = (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(autoReserve));
    if (visibleAddNewModal) {
      tmpAutoReserve.category = categoryDDL;
      tmpAutoReserve.process = processDDL;
      tmpAutoReserve.purchaseDetail = selectedPurchasesData;
      console.log(tmpAutoReserve);
      stateData.push(tmpAutoReserve);
      localStorage.setItem('AutoReserveStorage', JSON.stringify(stateData));
      setTmpFilteredData(stateData);
      setAutoReserve(stateData);
      resetAddModal(stateData.length + 1);
      onRowSelect(tmpAutoReserve);
      setLatestData(false);
      setVisibleAddNewModal(false);
    }
    setUpdateCancelConfirm(false);
  };

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
      setChkRowsCount(0);
    }
  };

  const updConfirmCancel = () => {
    setLatestData(false);
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[1]);
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
  };

  const handleGpEdit = () => {
    setTmpAutoReserve({
      display: true,
      AutoReserve: '',
      lossFactor: '',
      remark: '',
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  const showDeleteModal = () => {
    setVisibleDeleteModal(true);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      setEditModeCancelConfirm(true);
    } else {
      handleAddFormSubmit();
    }
  };

  const handleAddFormSubmit = () => {
    let count = autoReserve.length + 1;
    setTmpAutoReserve({
      key: count,
      code: parseFloat(count).toString().padStart(3, '0'),
      display: true,
      category: '',
      process: '',
      processType: '',
      materialClass: '',
      size: 0,
      purchaseDetail: [],
      remark: '',
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    if (!latestData) {
      setCategoryDDL('defaultValue');
      setProcessDDL('defaultValue');
      setProcessTypeDDL('defaultValue');
    }
    setVisibleAddNewModal(true);
    getPurchaseMstData();
    /* setChildTitle('材質区分'); */
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const deleteOK = () => {
    setVisibleDeleteModal(false);
    let data = [...autoReserve];
    data = data.filter((item) => item.key != selectedData.key);

    setAutoReserve(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    localStorage.setItem('AutoReserveStorage', JSON.stringify(data));
    setSelectedRowKeys(data.length > 0 ? data[0].key : -1);
    setSelectedData(data.length > 0 ? data[0] : []);
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = (count) => {
    setTmpAutoReserve({
      key: count,
      display: false,
      classificationCode: (count + 1).toString().padStart(3, '0'),
      classificationName: '',
      gravity: '',
      materialRefPrice: '',
      remark: '',
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [keyword, showFlag, tmpFilteredData]);

  useEffect(() => {
    //setProcessDDLArr(createKouteiDDL(tmpAutoReserve.category));
    tmpAutoReserve.category = categoryDDL !== 'defaultValue' ? categoryDDL : '';
    setProcessDDLArr(createKouteiDDL(tmpAutoReserve.category));
  }, [categoryDDL]);

  useEffect(() => {
    //setProcessTypeDDLArr(getProcessTypeDDL(tmpAutoReserve.category, tmpAutoReserve.process));
    tmpAutoReserve.process = processDDL !== 'defaultValue' ? processDDL : '';
    setProcessTypeDDLArr(getProcessTypeDDL(tmpAutoReserve.category, tmpAutoReserve.process));
  }, [processDDL]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordKey !== undefined) {
        setSelectedRowKeys(changeRecordKey);
        setSelectedData(filteredData.find((item) => item.key === changeRecordKey));
      } else if (discardOKAction.key === 0 && changeRecordKey === undefined) {
        console.log(selectedRowKeys, selectedData);
        return;
      } else {
        const action = actionArr.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5].includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e) => {
    {
      console.log(typeof e);
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // 無効フラグの押下
  /*  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  }; */

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  /*  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  }; */

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (autoReserve.length <= 0) {
      return;
    }
    let searchBarText = keyword;
    let currentData = [...autoReserve];
    let flag;
    let currentFilterData = currentData;

    // キーワードで絞り込み
    if (searchBarText !== '') {
      currentFilterData = currentData.filter((item) => {
        return Object.keys(item).some((key) => {
          return String(item[key]).toLowerCase().includes(searchBarText.toLowerCase());
        });
      });
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      currentFilterData = currentFilterData.filter((item) => item.display === flag);
    }
    setIsFilter(true);
    setFilteredData(currentFilterData);
    categorizedAutoReserve(currentFilterData, true);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = (updatedData) => {
    var tmpData = [...autoReserve];
    var index = tmpData.findIndex((item) => item.key === updatedData.key);
    console.log('submitData---', updatedData, index);
    tmpData[index] = updatedData;
    console.log(tmpData);
    setAutoReserve(tmpData);
    setTmpAutoReserve(tmpData);
    setTmpFilteredData(tmpData);
    setSelectedRowKeys(updatedData.key);
    setSelectedData(updatedData);

    localStorage.setItem('AutoReserveStorage', JSON.stringify(tmpData));
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  const changeProcessType = (process) => {
    if (document.getElementById('tbl' + process) !== null) {
      let showHide = document.getElementById('tbl' + process)?.style.display;
      if (showHide == 'block') {
        document.getElementById('tbl' + process).style.display = 'none';
        document.getElementById('arrowUp' + process).style.display = 'none';
        document.getElementById('arrowDown' + process).style.display = 'block';
      } else {
        document.getElementById('tbl' + process).style.display = 'block';
        document.getElementById('arrowUp' + process).style.display = 'block';
        document.getElementById('arrowDown' + process).style.display = 'none';
      }
      isResize ? setIsResize(false) : !isResize && setIsResize(true);
    }
  };

  const itemCollapse = (processType, purchaseTbl, category) => {
    const process = category + processType;
    // console.log(purchaseTbl);
    // console.log(selectedData, selectedRowKeys);
    return (
      <div>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col" onClick={(e) => changeProcessType(process)}>
            <div style={{ display: 'flex' }} className="ddl_2_noImg">
              <CaretUpOutlined id={'arrowUp' + process} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
              <CaretDownOutlined id={'arrowDown' + process} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginRight: 5 }}>{processType}</label>
            </div>
          </Col>
        </Row>
        <div id={'tbl' + process} style={{ display: 'none' }}>
          <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
            <Table
              columns={hasCheckData ? tableColumns : chkData}
              dataSource={purchaseTbl}
              scroll={{ y: 250, x: '10vw' }}
              rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
              rowKey={(record) => record.key}
              // pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
              pagination={false}
              className="drop-down-tbl"
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    onRowSelect(record);
                  },
                };
              }}
            />
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Select
                    style={{ width: 90 }}
                    onChange={beforeSearch /* searchByShowFlg */}
                    placeholder="表示・非表示"
                    id="showFlgDrop"
                    name="showFlgDrop"
                    value={showFlag}
                  >
                    {dataStateList.map((item) => (
                      <option id={'showFlg' + item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>

                  <Input
                    className="input-editable search-select-input"
                    placeholder="キーワードを入力"
                    allowClear
                    onPressEnter={beforeSearch}
                    onBlur={beforeSearch}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch(e);
                      }
                    }}
                    name="model"
                    id="model"
                  />
                </Space>
                {/* 一覧アリア */}
                <div className="overflow-auto autoReservedContainer" style={{ maxHeight: 806 }}>
                  <Row style={{ color: 'black', marginTop: 10 }} className="oyabuhin oyabuhin-row">
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>親部品</label>
                      </div>
                    </Col>
                  </Row>
                  {/*　溶接 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={changeParentYousetsu}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        {isParentYousetsu ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginRight: 5 }}>溶接</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isParentYousetsu ? 'block' : 'none' }}>
                    {(() => {
                      const subArr = [];
                      if (parentYousetsuArr !== undefined) {
                        for (let i = 0; i < parentYousetsuArr?.length; i++) {
                          subArr.push(
                            itemCollapse(
                              parentYousetsuArr[i].processType,
                              parentYousetsuArr[i].processTypeArr,
                              parentYousetsuArr[i].category
                            )
                          );
                        }
                      }
                      return subArr;
                    })()}
                  </div>

                  {/* iQ3板金子部品 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>iQ3板金子部品</label>
                      </div>
                    </Col>
                  </Row>
                  {/*　溶接 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={changeIQ3Yousetsu}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        {isIQ3Yousetsu ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginRight: 5 }}>溶接</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isIQ3Yousetsu ? 'block' : 'none' }}>
                    {(() => {
                      const subArr = [];
                      if (iQ3YousetsuArr !== undefined) {
                        for (let i = 0; i < iQ3YousetsuArr?.length; i++) {
                          subArr.push(
                            itemCollapse(
                              iQ3YousetsuArr[i].processType,
                              iQ3YousetsuArr[i].processTypeArr,
                              iQ3YousetsuArr[i].category
                            )
                          );
                        }
                      }
                      return subArr;
                    })()}
                  </div>
                  {/* 2次加工 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={change2jikakou}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        {is2jikakou ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginRight: 5 }}>2次加工</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: is2jikakou ? 'block' : 'none' }}>
                    {(() => {
                      const subArr = [];
                      if (nijikakouArr !== undefined) {
                        for (let i = 0; i < nijikakouArr?.length; i++) {
                          subArr.push(
                            itemCollapse(
                              nijikakouArr[i].processType,
                              nijikakouArr[i].processTypeArr,
                              nijikakouArr[i].category
                            )
                          );
                        }
                      }
                      return subArr;
                    })()}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":common.autoReserve"
                element={
                  <AutoReserveDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    paramType={paramType}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質名称（新規追加）', 1000, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          1000,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewAutoReserveModal',
          null,
          false
        )}

      {/* 一括編集　Modal */}
      {/* {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材質名称（選択したデータをまとめて編集）', 1790, -24),
         
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'updateModalStyle',
          null,
          true
        )} */}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default AutoReserveList;
