const programValues1 = [
  {
    yousousuu: 2740,
    chuushutsu: 2744,
  },
];
const programValues2 = [
  {
    yousousuu: 39,
    chuushutsu: 39,
  },
];
const programValues3 = [
  {
    yousousuu: 60,
    chuushutsu: 60,
  },
];
const programValues4 = [
  {
    yousousuu: 14,
    chuushutsu: 14,
  },
];
const programValues5 = [
  {
    yousousuu: 225,
    chuushutsu: 225,
  },
];
const pmxprogramValues1 = [
  {
    yousousuu: 71,
    chuushutsu: 71,
  },
];
const pmxprogramValues2 = [
  {
    yousousuu: 3,
    chuushutsu: 3,
  },
];
const pmxprogramValues3 = [
  {
    yousousuu: 6,
    chuushutsu: 6,
  },
];
const pmx2programValues1 = [
  {
    yousousuu: 96,
    chuushutsu: 96,
  },
];
const pmx2programValues2 = [
  {
    yousousuu: 14,
    chuushutsu: 14,
  },
];
const pmx2programValues3 = [
  {
    yousousuu: 35,
    chuushutsu: 35,
  },
];
const pmx2programValues4 = [
  {
    yousousuu: 10,
    chuushutsu: 10,
  },
];
const pmx2programValues5 = [
  {
    yousousuu: 5,
    chuushutsu: 5,
  },
];
const pmx2programValues6 = [
  {
    yousousuu: 4,
    chuushutsu: 4,
  },
];
const tblPlankData1 = [
  { id: 1, types: '丸', xsize: '10', ysize: '10', perimeter: '31.41', count: '20' },
  { id: 2, types: '丸', xsize: '10', ysize: '10', perimeter: '0', count: '20' },
  { id: 3, types: 'その他', xsize: '0', ysize: '0', perimeter: '454.5', count: '1' },
  { id: 4, types: 'その他', xsize: '0', ysize: '0', perimeter: '272.05', count: '1' },
  { id: 5, types: 'その他', xsize: '0', ysize: '0', perimeter: '426.66', count: '1' },
  { id: 6, types: 'その他', xsize: '0', ysize: '0', perimeter: '623.85', count: '1' },
  { id: 7, types: 'その他', xsize: '0', ysize: '0', perimeter: '75.29', count: '1' },
  { id: 8, types: 'その他', xsize: '0', ysize: '0', perimeter: '98.06', count: '1' },
  { id: 9, types: 'その他', xsize: '0', ysize: '0', perimeter: '33.97', count: '1' },
  { id: 10, types: 'その他', xsize: '0', ysize: '0', perimeter: '58.76', count: '1' },
  { id: 11, types: 'その他', xsize: '0', ysize: '0', perimeter: '236.43', count: '1' },
  { id: 12, types: 'その他', xsize: '0', ysize: '0', perimeter: '400.05', count: '1' },
  { id: 13, types: 'その他', xsize: '0', ysize: '0', perimeter: '483.63', count: '1' },
  { id: 14, types: 'その他', xsize: '0', ysize: '0', perimeter: '52.34', count: '1' },
  { id: 15, types: 'その他', xsize: '0', ysize: '0', perimeter: '126.57', count: '1' },
  { id: 16, types: 'その他', xsize: '0', ysize: '0', perimeter: '348.68', count: '1' },
  { id: 17, types: 'その他', xsize: '0', ysize: '0', perimeter: '246.87', count: '1' },
  { id: 18, types: 'その他', xsize: '0', ysize: '0', perimeter: '248.79', count: '1' },
  { id: 19, types: 'その他', xsize: '0', ysize: '0', perimeter: '414.83', count: '1' },
  { id: 20, types: 'その他', xsize: '0', ysize: '0', perimeter: '345.28', count: '1' },
  { id: 21, types: 'その他', xsize: '0', ysize: '0', perimeter: '345.28', count: '1' },
  { id: 22, types: 'その他', xsize: '0', ysize: '0', perimeter: '52.34', count: '1' },
  { id: 23, types: 'その他', xsize: '0', ysize: '0', perimeter: '126.57', count: '1' },
  { id: 24, types: 'その他', xsize: '0', ysize: '0', perimeter: '348.68', count: '1' },
  { id: 25, types: 'その他', xsize: '0', ysize: '0', perimeter: '246.87', count: '1' },
  { id: 26, types: 'その他', xsize: '0', ysize: '0', perimeter: '298.15', count: '1' },
  { id: 27, types: 'その他', xsize: '0', ysize: '0', perimeter: ' 1041.71', count: '1' },
];
const tblPlankData2 = [{ id: 1, types: '丸', xsize: '10', ysize: '10', perimeter: '31.41', count: '20' }];
const tblPlankData3 = [{ id: 1, types: '丸', xsize: '10', ysize: '10', perimeter: '31.41', count: '15' }];
const tblPlankData4 = [{ id: 1, types: '丸', xsize: '10', ysize: '10', perimeter: '31.41', count: '4' }];
const pmxtblPlankData = [
  { id: 1, types: '丸', xsize: '9', ysize: '', perimeter: '28.26', count: '10' },
  { id: 2, types: '丸', xsize: '12', ysize: '', perimeter: '37.68', count: '4' },
  { id: 3, types: '丸', xsize: '16', ysize: '', perimeter: '50.24', count: '2' },
];
const pmxtblPlankData2 = [{ id: 1, types: '丸', xsize: '0', ysize: '', perimeter: '0', count: '0' }];
const pmxtblPlankData3 = [{ id: 1, types: '丸', xsize: '8', ysize: '', perimeter: '25.13', count: '2' }];
const pmx2tblPlankData1 = [[1, '丸', 12, 12, 376.8, 10]];
const pmx2tblPlankData2 = [[1, '丸', 0, 0, 0, 0]];
const pmx2tblPlankData3 = [[1, '丸', 10, 10, 157.08, 5]];
const pmx2tblPlankData4 = [[1, '丸', 10, 10, 37.7, 1]];
const pmx2tblPlankData5 = [[1, '丸', 10, 10, 37.7, 1]];
const pmx2tblPlankData6 = [[1, '丸', 0, 0, 0, 0]];
const tblHyoumenshoriData1 = [
  {
    id: 1,
    method: '面積',
    types: '脱脂',
    details: '',
    area: 1800,
    autoExtract: 1800,
    count: '1',
    total: 1800,
  },
  {
    id: 2,
    method: '重量',
    types: '両面塗装',
    details: 'メラミン塗装',
    area: 2.275,
    autoExtract: 2.275,
    count: '1',
    total: 1800,
  },
];
const tblHyoumenshoriData4 = [
  {
    id: 1,
    method: '面積',
    types: '脱脂',
    details: '',
    area: 1800,
    autoExtract: 1800,
    count: '1',
    total: 1800,
  },
  {
    id: 2,
    method: '重量',
    types: '両面塗装',
    details: 'メラミン塗装',
    area: 2.275,
    autoExtract: 2.275,
    count: '1',
    total: 2.275,
  },
];
const tblHyoumenshoriData3 = [
  {
    id: 1,
    method: '面積',
    types: '脱脂',
    details: '',
    area: 2819.88,
    autoExtract: 2819.88,
    count: '1',
    total: 2819.88,
  },
  {
    id: 2,
    method: '重量',
    types: '両面塗装',
    details: 'メラミン塗装',
    area: 7.129,
    autoExtract: 7.129,
    count: '1',
    total: 7.129,
  },
];
const tblHyoumenshoriData5 = [
  {
    id: 1,
    method: '面積',
    types: '脱脂',
    details: '',
    area: 148.94,
    autoExtract: 148.94,
    count: '1',
    total: 148.94,
  },
  {
    id: 2,
    method: '重量',
    types: '両面塗装',
    details: 'メラミン塗装',
    area: 0.377,
    autoExtract: 0.377,
    count: '1',
    total: 0.377,
  },
];
const pmxtblHyoumenshoriData2 = [
  {
    id: 1,
    method: '面積',
    types: '脱脂',
    details: '',
    area: '99.998',
    autoExtract: '99.998',
    count: '1',
    total: '99.998',
  },
];
const pmxtblHyoumenshoriData3 = [
  // [1, '脱脂', '85.0', '50.0', '42.5', 1],
  // [2, 'メラミン塗装(両面)', '85.0', '50.0', '42.5', 1],
  { id: 1, method: '面積', types: '脱脂', details: '', area: '42.5', autoExtract: '42.5', count: '1', total: '42.5' },
];
const pmxtblHyoumenshoriData1 = [
  // [1, '脱脂', 859.46, 501.73, 4492.791, 1],
  // [2, 'メラミン塗装(両面)', 859.46, 501.73, 4492.791, 1],
  {
    id: 1,
    method: '面積',
    types: '脱脂',
    details: '',
    area: '4492.791',
    autoExtract: '4492.791',
    count: '1',
    total: '4492.791',
  },
];
const pmx2tblHyoumenshoriData1 = [
  [1, '脱脂', 992.42, 157.08, 1558.841, 1],
  [2, 'メラミン塗装(両面)', 992.42, 157.08, 1558.841, 1],
];
const pmx2tblHyoumenshoriData2 = [
  [1, '脱脂', '690.0', 46.04, 317.676, 1],
  [2, 'メラミン塗装(両面)', 46.04, 317.676, 1],
];
const pmx2tblHyoumenshoriData3 = [
  [1, '脱脂', '800.0', 176.82, 1414.56, 1],
  [2, 'メラミン塗装(両面)', 176.82, 1414.56, 1],
];
const pmx2tblHyoumenshoriData4 = [
  [1, '脱脂', 224.42, '65.0', 145.873, 1],
  [2, 'メラミン塗装(両面)', 224.42, '65.0', 145.873, 1],
];
const pmx2tblHyoumenshoriData5 = [
  [1, '脱脂', 117.5, '40.0', '47.0', 1],
  [2, 'メラミン塗装(両面)', 117.5, '40.0', '47.0', 1],
];
const pmx2tblHyoumenshoriData6 = [
  [1, '脱脂', 117.5, '40.0', '47.0', 1],
  [2, 'メラミン塗装(両面)', 117.5, '40.0', '47.0', 1],
];
const rectangleSize1 = [
  {
    xsize: 1200,
    ysize: 150,
    weight: 2.275,
    area: 1800,
    materialName: 'SPCH(黒皮) - 1.6',
    quality: '02_SPCH(黒皮)',
    thick: 1.6,
  },
];
const rectangleSize2 = [
  {
    xsize: 1200,
    ysize: 234.99,
    weight: 7.129,
    area: '2,819.88',
    materialName: 'SPCH(黒皮) - 3.2',
    quality: '02_SPCH(黒皮)',
    thick: 3.2,
  },
];
const rectangleSize4 = [
  {
    xsize: 1200,
    ysize: '25.0',
    weight: 0.785,
    area: '300.00',
    materialName: 'SPCH(黒皮) - 3.2',
    quality: '02_SPCH(黒皮)',
    thick: 3.2,
  },
];
const rectangleSize5 = [
  {
    xsize: 1200,
    ysize: 50,
    weight: 0.377,
    area: 148.94,
    materialName: 'SPCH(黒皮) - 3.2',
    quality: '02_SPCH(黒皮)',
    thick: 3.2,
  },
];
const pmxrectangleSize = [
  {
    xsize: 895.46,
    ysize: 501.73,
    weight: 8.163,
    area: 4492.791,
    materialName: 'SPCC - 2.3',
    quality: '01_SPCC',
    thick: 2.3,
  },
];
const pmxrectangleSize2 = [
  {
    xsize: 141.42,
    ysize: 70.71,
    weight: 0.182,
    area: 99.998,
    materialName: 'SPCH(黒皮) - 2.3',
    quality: '01_SPCC',
    thick: 2.3,
  },
];
const pmxrectangleSize3 = [
  {
    xsize: 85,
    ysize: 50,
    weight: 0.77,
    area: 42.5,
    materialName: 'SPCC - 2.3',
    quality: '01_SPCC',
    thick: 2.3,
  },
];
const pmx2rectangleSize1 = [
  {
    xsize: 992.42,
    ysize: 157.08,
    weight: 2.832,
    area: 1558.841,
    materialName: 'SPCC - 2.3',
    quality: '01_SPCC',
    thick: 2.3,
  },
];
const pmx2rectangleSize2 = [
  {
    xsize: '690.0',
    ysize: 46.04,
    weight: 0.577,
    area: 317.676,
    materialName: 'SPCC - 2.3',
    quality: '01_SPCC',
    thick: 2.3,
  },
];
const pmx2rectangleSize3 = [
  {
    xsize: '800.0',
    ysize: 176.82,
    weight: 2.57,
    area: 1414.56,
    materialName: 'SPCC - 2.3',
    quality: '01_SPCC',
    thick: 2.3,
  },
];
const pmx2rectangleSize4 = [
  {
    xsize: 244.42,
    ysize: '65.0',
    weight: 0.681,
    area: 145.873,
    materialName: 'SPCH (黒皮) -6.0',
    quality: '02_SPCH (黒皮)',
    thick: '6.0',
  },
];
const pmx2rectangleSize5 = [
  {
    xsize: 117.5,
    ysize: '40.0',
    weight: 0.119,
    area: '47.0',
    materialName: 'SPCC -3.2',
    quality: '01_SPCC',
    thick: 3.2,
  },
];
const pmx2rectangleSize6 = [
  {
    xsize: 117.5,
    ysize: '40.0',
    weight: 0.119,
    area: '47.0',
    materialName: 'SPCC -3.2',
    quality: '01_SPCC',
    thick: 3.2,
  },
];
const plank1 = [
  {
    val1: 1200,
    val2: 150,
    val3: 2.275,
    val4: 1800,
    val5: 0,
    val6: 0,
  },
];
const plank3 = [
  {
    val1: 4549.93,
    val2: 4549.93,
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  },
];
const plank4 = [
  {
    val1: 2450,
    val2: 2450,
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  },
];
const plank5 = [
  {
    val1: '695.76',
    val2: '695.76',
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  },
];
const pmxplank = [
  {
    val1: 3235.73,
    val2: 3235.73,
    val3: 534.07,
    val4: 534.07,
    val5: 0,
    val6: 0,
  },
];
const pmxplank2 = [
  {
    val1: 341.42,
    val2: 341.42,
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  },
];
const pmxplank3 = [
  {
    val1: 270,
    val2: 270,
    val3: 50.27,
    val4: 50.27,
    val5: 0,
    val6: 0,
  },
];
const pmx2plank1 = [
  {
    val1: 2287.07,
    val2: 2287.07,
    val3: 0,
    val4: 0,
  },
];
const pmx2plank2 = [
  {
    val1: 1472.08,
    val2: 1472.08,
    val3: 0,
    val4: 0,
  },
];
const pmx2plank3 = [
  {
    val1: 1979.23,
    val2: 1979.23,
    val3: 0,
    val4: 0,
  },
];
const pmx2plank4 = [
  {
    val1: 578.84,
    val2: 578.84,
    val3: 0,
    val4: 0,
  },
];
const pmx2plank5 = [
  {
    val1: '315.0',
    val2: '315.0',
    val3: 0,
    val4: 0,
  },
];
const pmx2plank6 = [
  {
    val1: '315.0',
    val2: '315.0',
    val3: 0,
    val4: 0,
  },
];

const bending1 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: 0,
    lines: 0,
    bendNum: 0,
    num: 0,
  },
];
const bending3 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: '50.0',
    lines: 10,
    bendNum: 0,
    num: 10,
  },
];
const pmxbending = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: 35.375,
    lines: 1,
    bendNum: 0,
  },
  {
    key: 2,
    types: 'V曲げ',
    bendlength: 387.4,
    lines: 2,
    bendNum: 0,
  },
  {
    key: 3,
    types: 'V曲げ',
    bendlength: 389.629,
    lines: 2,
    bendNum: 0,
  },
  {
    key: 4,
    types: 'V曲げ',
    bendlength: 549.375,
    lines: 1,
    bendNum: 0,
  },
  {
    key: 5,
    types: 'V曲げ',
    bendlength: 622.8,
    lines: 1,
    bendNum: 0,
  },
];
const pmxbending2 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: 0,
    lines: 0,
    bendNum: 0,
    num: 0,
  },
];
const pmx2bending1 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: 808.1,
    lines: 4,
    bendNum: 0,
    num: 4,
  },
];
const pmx2bending2 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: '690.0',
    lines: 2,
    bendNum: 0,
    num: 2,
  },
];
const pmx2bending3 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: '800.0',
    lines: 2,
    bendNum: 0,
    num: 2,
  },
];
const pmx2bending4 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: '65.0',
    lines: 1,
    bendNum: 0,
    num: 1,
  },
];
const pmx2bending5 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: 0,
    lines: 0,
    bendNum: 0,
    num: 0,
  },
];
const pmx2bending6 = [
  {
    key: 1,
    types: 'V曲げ',
    bendlength: 0,
    lines: 0,
    bendNum: 0,
    num: 0,
  },
];
const firstBending1 = [
  {
    val1: 2700,
    val2: 2700,
  },
];
const firstBending4 = [
  {
    val1: 0,
    val2: 0,
  },
];
const firstBending5 = [
  {
    val1: '50.0',
    val2: '50.0',
  },
];
const pmxBending = [
  {
    val1: 622.8,
    val2: 622.8,
  },
];
const pmxBending2 = [
  {
    val1: 0,
    val2: 0,
  },
];
const pmx2Bending1 = [
  {
    val1: 808.1,
    val2: 808.1,
  },
];
const pmx2Bending2 = [
  {
    val1: '690.0',
    val2: '690.0',
  },
];
const pmx2Bending3 = [
  {
    val1: '800.0',
    val2: '800.0',
  },
];
const pmx2Bending4 = [
  {
    val1: '65.0',
    val2: '65.0',
  },
];
const pmx2Bending5 = [
  {
    val1: 0,
    val2: 0,
  },
];
const pmx2Bending6 = [
  {
    val1: 0,
    val2: 0,
  },
];
const manual1 = [
  { id: 1, types: '手動', target: '外形', length: 2700, autoExtract: 2700, count: '1' },
  { id: 2, types: '手動', target: '内形', length: 0, autoExtract: 0, count: '1' },
];
const manual3 = [
  { id: 1, types: '手動', target: '外形', length: 454.93, autoExtract: 454.93, count: '1' },
  { id: 2, types: '手動', target: '内形', length: 0, autoExtract: 0, count: '1' },
];
const manual4 = [
  { id: 1, types: '手動', target: '外形', length: 300, autoExtract: 300, count: '1' },
  { id: 2, types: '手動', target: '内形', length: 0, autoExtract: 0, count: '1' },
];
const manual5 = [
  { id: 1, types: '手動', target: '外形', length: 695.76, autoExtract: 695.76, count: '1' },
  { id: 2, types: '手動', target: '内形', length: 0, autoExtract: 0, count: '1' },
];
const pmxmanual = [
  { id: 1, types: '手動', target: '外形', length: '3235.73', autoExtract: '3235.73', count: '1' },
  { id: 2, types: '手動', target: '内形', length: '534.07', autoExtract: '534.07', count: '1' },
];
const pmxmanual2 = [
  { id: 1, types: '手動', target: '外形', length: '341.42', autoExtract: '341.42', count: '1' },
  { id: 2, types: '手動', target: '内形', length: '0', autoExtract: '0', count: '0' },
];
const pmxmanual3 = [
  { id: 1, types: '手動', target: '外形', length: '270', autoExtract: '270', count: '1' },
  { id: 2, types: '手動', target: '内形', length: '50.27', autoExtract: '50.27', count: '1' },
];
const pmx2manual1 = [
  {
    no: 1,
    types: '外径',
    xsize: 992.42,
    ysize: 157.08,
    lenght: 2287.07,
    num: 1,
  },
];
const pmx2manual2 = [
  {
    no: 1,
    types: '外径',
    xsize: '690.0',
    ysize: 46.04,
    lenght: 1472.08,
    num: 1,
  },
];
const pmx2manual3 = [
  {
    no: 1,
    types: '外径',
    xsize: '800.0',
    ysize: 176.82,
    lenght: 1979.23,
    num: 1,
  },
];
const pmx2manual4 = [
  {
    no: 1,
    types: '外径',
    xsize: 224.42,
    ysize: '65.0',
    lenght: 578.84,
    num: 1,
  },
];
const pmx2manual5 = [
  {
    no: 1,
    types: '外径',
    xsize: 117.5,
    ysize: '40.0',
    lenght: '315.0',
    num: 1,
  },
];
const pmx2manual6 = [
  {
    no: 1,
    types: '外径',
    xsize: 117.5,
    ysize: '40.0',
    lenght: '315.0',
    num: 1,
  },
];
const auto1 = [
  {
    id: 1,
    types: '自動',
    area: 0,
    autoExtract1: 0,
    weight: 0,
    autoExtract2: 0,
    count: '',
  },
];
const auto3 = [
  {
    id: 1,
    types: '自動',
    area: 0,
    autoExtract1: 0,
    weight: 0,
    autoExtract2: 0,
    count: 0,
  },
];
const auto4 = [
  {
    id: 1,
    types: '自動',
    area: 0,
    autoExtract1: 0,
    weight: 0,
    autoExtract2: 0,
    count: '',
  },
];
const auto5 = [
  {
    id: 1,
    types: '自動',
    area: 0,
    autoExtract1: 0,
    weight: 0,
    autoExtract2: 0,
    count: '',
  },
];
const pmxauto = [
  {
    id: 1,
    types: '自動',
    area: '4492.791',
    autoExtract1: '4492.791',
    weight: '8.163',
    autoExtract2: '8.163',
    count: '',
  },
];
const pmxauto2 = [
  { id: 1, types: '自動', area: '99.998', autoExtract1: '99.998', weight: '0.182', autoExtract2: '0.182', count: 1 },
];
const pmxauto3 = [
  { id: 1, types: '自動', area: '42.5', autoExtract1: '42.5', weight: '0.77', autoExtract2: '0.77', count: 1 },
];
const pm2xauto1 = [
  {
    no: 1,
    types: '自動パリ',
    xsize: 992.42,
    ysize: 157.08,
    lenght: 1558.841,
    num: 1,
  },
];
const pm2xauto2 = [
  {
    no: 1,
    types: '自動パリ',
    xsize: '690.0',
    ysize: 46.04,
    lenght: 317.676,
    num: 1,
  },
];
const pm2xauto3 = [
  {
    no: 1,
    types: '自動パリ',
    xsize: '800.0',
    ysize: 176.82,
    lenght: 1414.56,
    num: 1,
  },
];
const pm2xauto4 = [
  {
    no: 1,
    types: '自動パリ',
    xsize: 224.42,
    ysize: '65.0',
    lenght: 145.873,
    num: 1,
  },
];
const pm2xauto5 = [
  {
    no: 1,
    types: '自動パリ',
    xsize: 117.5,
    ysize: '40.0',
    lenght: '47.0',
    num: 1,
  },
];
const pm2xauto6 = [
  {
    no: 1,
    types: '自動パリ',
    xsize: 117.5,
    ysize: '40.0',
    lenght: '47.0',
    num: 1,
  },
];
// 溶接
const yousetsu1Data1 = [
  {
    key: 1,
    yousetsuType: 'アルゴン',
    senchou: 0,
    senchouKaisu: 0,
    senchouYousetsushiage: 'on',
    total1: 0,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
];
const nijikakouData1 = [
  { id: 1, types: 'バーリング', xsize: '', ysize: '', perimeter: '', count: '', blankFlag: false },
];
const shirringData1 = 0;
const kensaData1 = 0;
const pmxyousetsu1Data1 = [
  {
    key: 1,

    yousetsuType: 'アルゴン',
    senchou: 103.5,
    senchouKaisu: 2,
    senchouYousetsushiage: 'on',
    total1: 207,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
];
const pmx2jikakouData1 = [
  { id: 1, types: 'バーリング', xsize: '10', ysize: '', perimeter: '', count: '10', blankFlag: false },
  { id: 2, types: 'タップ', xsize: '10', ysize: '', perimeter: '', count: '10', blankFlag: false },
];
const pmx2jikakouData2 = [
  { id: 1, types: 'バーリング', xsize: '0', ysize: '', perimeter: '', count: '0', blankFlag: false },
];
const pmx2jikakouData3 = [
  { id: 1, types: 'バーリング', xsize: '0', ysize: '', perimeter: '', count: '0', blankFlag: false },
];
const pmx2ShirringData2 = 0;
const pmx2ShirringData1 = 0;
const pmx2ShirringData3 = 0;
const pmx2KensaData1 = 0;
const pmx2KensaData2 = 0;
const pmx2KensaData3 = 0;
export const kouteiInputInfo_1 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: programValues1,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: tblPlankData1,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: rectangleSize1,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: plank1,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: bending1,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [manual1, auto1],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: auto1,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: firstBending1,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: tblHyoumenshoriData1,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: nijikakouData1,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: shirringData1,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: kensaData1,
  },
];

export const kouteiInputInfo_2 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: programValues2,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: tblPlankData2,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: rectangleSize2,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: plank1,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: bending1,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [manual1, auto1],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: auto1,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: firstBending1,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: tblHyoumenshoriData1,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: nijikakouData1,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: shirringData1,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: kensaData1,
  },
];

export const kouteiInputInfo_3 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: programValues3,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: tblPlankData3,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: rectangleSize2,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: plank3,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: bending3,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [manual3, auto3],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: auto3,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: firstBending1,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: tblHyoumenshoriData3,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: nijikakouData1,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: shirringData1,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: kensaData1,
  },
];

export const kouteiInputInfo_4 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: programValues4,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: tblPlankData4,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: rectangleSize4,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: plank4,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: bending3,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [manual4, auto4],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: auto4,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: firstBending4,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: tblHyoumenshoriData4,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: nijikakouData1,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: shirringData1,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: kensaData1,
  },
];

export const kouteiInputInfo_5 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: programValues5,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: tblPlankData4,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: rectangleSize5,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: plank5,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: bending3,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [manual5, auto5],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: auto5,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: firstBending5,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: tblHyoumenshoriData5,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: nijikakouData1,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: shirringData1,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: kensaData1,
  },
];

export const zero2_kouteiInputInfo_1 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: pmxprogramValues1,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: pmxtblPlankData,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: pmxrectangleSize,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: pmxplank,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: pmxbending,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [pmxmanual, pmxauto],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: pmxauto,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: pmxBending,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: pmxtblHyoumenshoriData1,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: pmxyousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: pmx2jikakouData1,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: pmx2ShirringData1,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: pmx2KensaData1,
  },
];

export const zero2_kouteiInputInfo_2 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: pmxprogramValues2,
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: pmxtblPlankData2,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: pmxrectangleSize2,
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: pmxplank2,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: pmxbending2,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [pmxmanual2, pmxauto2],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: pmxauto2,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: pmxBending2,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: pmxtblHyoumenshoriData2,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: pmx2jikakouData2,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: pmx2ShirringData2,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: pmx2KensaData2,
  },
];

export const zero2_kouteiInputInfo_3 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: pmxprogramValues3,
  },
  {
    key: 1,
    kouteiName: 'firstPlank',
    kouteiContent: pmxplank3,
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: pmxrectangleSize3,
  },
  {
    key: 3,
    kouteiName: 'plank',
    kouteiContent: pmxtblPlankData3,
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: pmxbending2,
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [pmxmanual3, pmxauto3],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: pmxauto3,
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: pmxBending2,
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: pmxtblHyoumenshoriData3,
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: pmx2jikakouData3,
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: pmx2ShirringData3,
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: pmx2KensaData3,
  },
];

export const zero2_kouteiInputInfo_4 = [
  {
    key: 0,
    kouteiName: 'program',
    kouteiContent: [],
  },
  {
    key: 1,
    kouteiName: 'plank',
    kouteiContent: [],
  },
  {
    key: 2,
    kouteiName: 'rectangle',
    kouteiContent: [],
  },
  {
    key: 3,
    kouteiName: 'firstPlank',
    kouteiContent: [],
  },
  {
    key: 4,
    kouteiName: 'bend',
    kouteiContent: [],
  },
  {
    key: 5,
    kouteiName: 'manual',
    kouteiContent: [[], []],
  },
  {
    key: 6,
    kouteiName: 'auto',
    kouteiContent: [],
  },
  {
    key: 7,
    kouteiName: 'firstBending',
    kouteiContent: [],
  },
  {
    key: 8,
    kouteiName: 'hyoumenshori',
    kouteiContent: [],
  },
  {
    key: 9,
    kouteiName: 'yousetsu',
    kouteiContent: [],
  },
  {
    key: 10,
    kouteiName: 'nijikakou',
    kouteiContent: [],
  },
  {
    key: 11,
    kouteiName: 'shirring',
    kouteiContent: '',
  },
  {
    key: 12,
    kouteiName: 'kensa',
    kouteiContent: '',
  },
];
