import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Collapse, Checkbox, Space } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import { handelIncreaseTime, handelDecreaseTime, checkInput, checkInputWithColor } from '../common/Common.js';
import { Route, Outlet, Routes } from 'react-router-dom';
import ParentKouteiCalculate_Oyabuhin from './ParentKouteiCalculate_Oyabuhin';
import ParentKouteiCalculate_Yosetsu from './ParentKouteiCalculate_Yosetsu';

import ParentKouteiCalculate_YosetsuFinish from './ParentKouteiCalculate_YosetsuFinish';
import ParentKouteiCalculate_Hyomen from './ParentKouteiCalculate_Hyomen';
import ParentKouteiCalculate_Konpo from './ParentKouteiCalculate_Konpo';
import ParentKouteiCalculate_Kumitate from './ParentKouteiCalculate_Kumitate';
import ParentKouteiCalculate_Kensa from './ParentKouteiCalculate_Kensa';
import ParentKouteiCalculate_Additional from './ParentKouteiCalculate_Additional';

const ParentKouteiCalculate = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [kouteiCalInfo, setKouteiCalInfo] = useState([]);
  const [yosetsuInfoData, setYosetsuInfoData] = useState([]);
  const [yosetsuInfoEditData, setYosetsuInfoEditData] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const kouteiCalculateRef = useRef(ref);

  const sendSelectedDataFlg = (flg) => {
    setSelectedDataFlg(flg);
  };

  const sendSelectedFlgtoParent = (flg) => {
    setSelectedDataFlg(flg);
  };

  useEffect(() => {
    console.log('kouteiCalculate---1', props.editMode);
    if (props.selectedDataDetail == undefined || props.selectedDataDetail?.length == 0) {
      setYosetsuInfoEditData([]);
      setYosetsuInfoData([]);
    } else {
      // 編集データ設定
      if (props.selectedDataDetail.kouteiCalInfoEditData != undefined) {
        let kouteiCalInfo = props.selectedDataDetail?.kouteiCalInfoEditData;
        setKouteiCalInfo(kouteiCalInfo);
        kouteiCalInfo?.forEach((info) => {
          let data = info;
          // 溶接
          if (data.kouteiName == 'yosetsu') {
            const yosetsuInfo = data.kouteiContent;
            setYosetsuInfoEditData(yosetsuInfo);
          }
        });
      } else {
        setYosetsuInfoEditData([]);
      }

      // データ設定
      if (props.selectedDataDetail.kouteiCalInfoData != undefined) {
        let kouteiCalInfo_data = props.selectedDataDetail?.kouteiCalInfoData;
        kouteiCalInfo_data?.forEach((info) => {
          let data = info;
          // 溶接
          if (data.kouteiName == 'yosetsu') {
            const yosetsuInfo = data.kouteiContent;

            setYosetsuInfoData(yosetsuInfo);
          }
        });
      } else {
        setYosetsuInfoData([]);
      }
    }
  }, [props.selectedDataDetail]);

  const setEditModeChange = (editMode) => {
    kouteiCalculateRef.current.setEditModeChange(editMode);
    setEditMode(editMode);
  };

  const updateKouteiCal = (kouteiName, kouteiContent) => {
    let updatedKouteiContent = JSON.parse(JSON.stringify(kouteiCalInfo));
    updatedKouteiContent.forEach((info) => {
      let data = info;
      if (data.kouteiName == kouteiName) {
        data.kouteiContent = JSON.parse(JSON.stringify(kouteiContent));
      }
    });
    setKouteiCalInfo(updatedKouteiContent);
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiCalInfo: () => {
        return kouteiCalInfo;
      },
      getSelectedFlg: () => {
        return selectedDataFlg;
      },
    }),
    [kouteiCalInfo, selectedDataFlg]
  );

  return (
    <>
      <div className="overflow-auto kouteical KouteiCalculate" style={{ height: '81.4vh' }}>
        {/* 親部品 */}
        {/* <KouteiCalculate_Oyabuhin ref={kouteiCalculateRef}></KouteiCalculate_Oyabuhin> */}
        {/* 親部品 */}

        {/* 溶接  */}
        <ParentKouteiCalculate_Yosetsu
          /*  ref={kouteiCalculateRef}
          yosetsuInfoData={yosetsuInfoData}
          yosetsuInfoEditData={yosetsuInfoEditData}
          editMode={props.editMode}
          updateYosetsu={updateKouteiCal}
          sendSelectedDataFlg={sendSelectedDataFlg} */
          ref={kouteiCalculateRef}
          selectedDataDetail={props.selectedDataDetail}
          editMode={props.editMode}
        ></ParentKouteiCalculate_Yosetsu>
        {/* 溶接  */}

        {/* 溶接仕上 */}
        {
          <ParentKouteiCalculate_YosetsuFinish
            ref={kouteiCalculateRef}
            selectedDataDetail={props.selectedDataDetail}
            editMode={props.editMode}
          ></ParentKouteiCalculate_YosetsuFinish>
        }
        {/* 溶接仕上 */}

        {/* 表面処理 */}
        <ParentKouteiCalculate_Hyomen
          ref={kouteiCalculateRef}
          selectedDataDetail={props.selectedDataDetail}
          editMode={props.editMode}
        ></ParentKouteiCalculate_Hyomen>
        {/* 表面処理 */}

        {/* 研磨 */}
        <ParentKouteiCalculate_Kumitate
          ref={kouteiCalculateRef}
          selectedDataDetail={props.selectedDataDetail}
          editMode={props.editMode}
        ></ParentKouteiCalculate_Kumitate>
        {/* 研磨 */}

        {/* 検査 */}
        <ParentKouteiCalculate_Kensa
          ref={kouteiCalculateRef}
          selectedDataDetail={props.selectedDataDetail}
          editMode={props.editMode}
        ></ParentKouteiCalculate_Kensa>
        {/* 検査 */}

        {/* 梱包 */}
        <ParentKouteiCalculate_Konpo
          ref={kouteiCalculateRef}
          selectedDataDetail={props.selectedDataDetail}
          editMode={props.editMode}
        ></ParentKouteiCalculate_Konpo>
        {/* 梱包 */}


        {/* 追加工程 */}

        <ParentKouteiCalculate_Additional
          ref={kouteiCalculateRef}
          selectedDataDetail={props.selectedDataDetail}
          editMode={props.editMode}
        ></ParentKouteiCalculate_Additional>

        {/* 追加工程 */}
      </div>
    </>
  );
});

export default ParentKouteiCalculate;
