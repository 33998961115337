/**
 * クラス名：装置設定詳細画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Form, Col, Input, Row, Button, Checkbox, Select, Table as AntTable, Space } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
  DownSquareFilled,
  UpSquareFilled,
} from '@ant-design/icons';

import ChargeAmount from './ChargeAmount';
import SetupSetting from './SetupSetting';
import MachineSpeed from './MachineSpeed';
import KaritsukiTime from './KaritsukiTime';
import TapWelding from './TapWelding';
import ProductSize from './ProductSize';
import Quantity from './Quantity';
import YousetsuDifficulty from './YousetsuDifficulty';
import Pitch from './Pitch';
import ChildPart from './ChildPart';
import WorkAccuracy from './WorkAccuracy';
import ToritsukiJikan_Itaatsu from './ToritsukiJikan_Itaatsu';
import ToritsukiJikan_Size from './ToritsukiJikan_Size';
import Device_Sagyoukeisuu from '../../iq3/devices/Device_Sagyoukeisuu';
import Device_DantoriJikan from '../../iq3/devices/Device_DantoriJikan';

import { souchiHandleIncreaseTime, souchiHandleDecreaseTime, JPYs } from '../../../common/Common';
import { Customer } from '../../../common/Constant';
import { ClientType } from '../../../common/enums.js';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData.js';
import {
  confirmModalTitle,
  confirmDiscardContent,
  confirmUpdateContent,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
// import { paramCommonCustomer } from '../../../common/Constant';
import Device_Bending from '../../iq3/devices/Device_Bending';
import Device_BendingTimeFactor from '../../iq3/devices/Device_BendingTimeFactor';
import Device_BendingCorrectionTime from '../../iq3/devices/Device_BendingCorrectionTime';
import Device_SetupSetting from '../../iq3/devices/Device_SetupSetting';
import Device_ProcessType from '../../iq3/devices/Device_ProcessType';
import Device_KakouSokudo from '../../iq3/devices/Device_KakouSokudo';
import Device_KakouJikan from '../../iq3/devices/Device_KakouJikan';
import Device_ManualBari from '../../iq3/devices/Device_ManualBari';
import Device_ProductSize from '../../iq3/devices/Device_ProductSize';
import Device_BendingCorrectionTimeFactor from '../../iq3/devices/Device_BendingCorrectionTimeFactor';
import Device_ProcessInspection from '../../iq3/devices/Device_ProcessInspection';
import Device_MaterialHandlingTime from '../../iq3/devices/Device_MaterialHandlingTime';
import Device_MaterialArrangement from '../../iq3/devices/Device_MaterialArrangement';
import Device_Blank_DrillingTime from '../../iq3/devices/Device_Blank_DrillingTime';
import Device_Blank_GasType from '../../iq3/devices/Device_Blank_GasType';
import Device_Blank_Joint from '../../iq3/devices/Device_Blank_Joint';
import Device_Blank_KakouSokudoKeisuu from '../../iq3/devices/Device_Blank_KakouSokudoKeisuu';
import Device_Blank_PiercingProcessTime from '../../iq3/devices/Device_Blank_PiercingProcessTime';
import Device_Blank_GasKeisuu from '../../iq3/devices/Device_Blank_GasKeisuu';
import Device_Blank_Kakousokudo from '../../iq3/devices/Device_Blank_Kakousokudo';
import Device_Blank_PunchCondition from '../../iq3/devices/Device_Blank_PunchCondition';
import Device_Blank_OkuriSokudo from '../../iq3/devices/Device_Blank_OkuriSokudo';
import Device_Blank_RollerSpeed from '../../iq3/devices/Device_Blank_RollerSpeed';
import Device_Blank_ExtractCondition from '../../iq3/devices/Device_Blank_ExtractCondition';
import Device_Blank_PunchProcessTime from '../../iq3/devices/Device_Blank_PunchProcessTime';

const DevicesDetails = forwardRef((props, ref) => {
  const [isBaritoriShurui, setIsBaritoriShurui] = useState(false);
  const [isCharge, setIsCharge] = useState(false);
  const [isDantoriJikan, setIsDantoriJikan] = useState(false);
  const [isSetupSetting, setIsSetupSetting] = useState(false);
  const [isKouteiNaikensa, setIsKouteiNaikensa] = useState(false);
  const [isMagekakouJikan, setIsMagekakouJikan] = useState(false);
  const [isArrangeMaterial, setIsArrangeMaterial] = useState(false);
  const [isPunch, setIsPunch] = useState(false);
  const [isPiercingProcess, setIsPiercingProcess] = useState(false);
  const [isPunchProcess, setIsPunchProcess] = useState(false);
  const [isMageHoseiJikan, setIsMageHoseiJikan] = useState(false);
  const [isMageHoseiJikanFactor, setIsMageHoseiJikanFactor] = useState(false);
  const [isProcessSizeFactor, setIsProcessSizeFactor] = useState(false);
  const [isBendingTimeFactor, setIsBendingTimeFactor] = useState(false);
  const [isProcessSpeed, setIsProcessSpeed] = useState(false);
  const [isSagyouSokudo, setIsSagyouSokudo] = useState(false);
  const [isSagyouKeisuu, setIsSagyouKeisuu] = useState(false);
  const [isiq3DantoriJikan, setIsiq3DantoriJikan] = useState(false);
  const [isiq3KakouJikan, setIsiq3KakouJikan] = useState(false);
  const [isBlankKakouSokudo, setIsBlankKakouSokudo] = useState(false);
  const [isBlankKakouSokudoKeisuu, setIsBlankKakouSokudoKeisuu] = useState(false);
  const [isBlankGasuKeisuu, setIsBlankGasuKeisuu] = useState(false);
  const [isExtractCondition, setIsExtractCondition] = useState(false);
  const [isBlankGasuType, setIsBlankGasuType] = useState(false);
  const [isBlankDrillingTime, setIsBlankDrillingTime] = useState(false);
  const [isOkuriSokudo, setIsOkuriSokudo] = useState(false);
  const [isRollerKakouSokudo, setIsRollerKakouSokudo] = useState(false);
  const [isBlankJoint, setIsBlankJoint] = useState(false);
  const [isiq3KakouSokudo, setIsiq3KakouSokudo] = useState(false);
  const [isiq3ManualBari, setIsiq3ManualBari] = useState(false);
  const [isProcessTime, setIsProcessTime] = useState(false);
  const [isAttach, setIsAttach] = useState(false);
  const [isToritsukiJikanItaatsu, setIsToritsukiJikanItaatsu] = useState(false);
  const [isToritsukiJikanSize, setIsToritsukiJikanSize] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isDeviceDetails, setIsDeviceDetails] = useState(false);
  const [isTapWelding, setIsTapWelding] = useState(false);
  const [isPitch, setIsPitch] = useState(false);
  const [isProductSize, setIsProductSize] = useState(false);
  const [isProductQuantity, setIsProductQuantity] = useState(false);
  const [isChildPart, setIsChildPart] = useState(false);
  const [isWorkAccuracy, setIsWorkAccuracy] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [deviceType, setDeviceType] = useState('標準');
  const [dantoriCharge, setDantoriChange] = useState('');
  const [detailsProcess, setDetailProcess] = useState([]);

  const [detailsData, setDetailsData] = useState([]);
  const initialValues = [8, 11, 13, 16, 17];
  // データ表示
  const [code, setCode] = useState('');
  const [processName, setProcessName] = useState('');
  const [processType, setProcessType] = useState('');
  const [deviceCode, setDeviceCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [kouteiType, setKouteiType] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const [iniAmount, setIniAmount] = useState('');
  const [koubuhinnKeisuu, setKobuhinKeisuu] = useState('');
  const [tsuikaKouteiTypes, setTsuikaKouteiTypes] = useState([]);

  // 追加工程の段取金額と作業金額
  const [setupAmount, setSetupAmount] = useState('');
  const [workAmount, setWorkAmount] = useState('');
  // 段取時間
  const [dantoriTime, setDantoriTime] = useState('00:00:00');
  const [workTime, setWorkTime] = useState('00:00:00');
  const [sagyouTime, setSagyouTime] = useState('00:00:00');

  const [area, setArea] = useState([]);
  const [weight, setWeight] = useState([]);
  const [volume, setVolume] = useState([]);
  const [sizesDetailsList, setSizeDetailsList] = useState([]);
  const [dantoriSizeDetailsList, setDantoriSizeDetailsList] = useState([]);

  const [kihonDantori, setKihonDantori] = useState('');
  // 段取を個別に持つ
  const [dantoriFlag, setDantoriFlag] = useState('');
  const [selectedDeviceData, setSelectedDeviceData] = useState([]);
  // 名称
  const [meishou, setMeishou] = useState([]);
  const [meishouList, setMeishouList] = useState([]);
  // 数量区分
  const [suuryouData, setSuuryouData] = useState([]);
  const [suuryouList, setSuuryouList] = useState([]);
  const [dantoriSetteiSuuryouList, setdantoriSetteiSuuryouList] = useState([]);

  // 要素数
  const [yousosuuData, setYousosuuData] = useState([]);
  const [sagyouKeisuuYousosuuList, setSagyouKeisuuYousosuuList] = useState([]);
  const [shokiDankaiJikanList, setShokiDankaiJikanList] = useState([]);

  const [jikanCharge, setJikanChaji] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);

  const [initialCustomerData, setInitialCustomerData] = useState([]); // 初期表示に表示する項目
  const [initialCustomerDataBk, setInitialCustomerDataBk] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState([]);
  const [selectedCustomerRowKeys, setSelectedCustomerRowKeys] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);

  const [flagChecked, setFlagChecked] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);

  const [pitchItaatsuList, setPitchItaatsuList] = useState([]);

  const [tapWeldItaatsuList, setTapWeldItaatsuList] = useState([]);

  const [yousetsuNanidoItaatsuMasterData, setYousetsuNanidoItaatsuMasterData] = useState([]);
  const [yousetsuNanidoItaatsuList, setYousetsuNanidoItaatsuList] = useState([]);

  const [karitsukiTimeItaatsuMasterData, setKaritsukiTimeItaatsuMasterData] = useState([]);
  const [karitsukiTimeItaatsuList, setKaritsukiTimeItaatsuList] = useState([]);

  // 取付時間（サイズ、板厚）
  const [toritsukiTimeItaatsuMasterData, setToritsukiTimeItaatsuMasterData] = useState([]);
  const [toritsukiTimeItaatsuList, setToritsukiTimeItaatsuList] = useState([]);
  const [toritsukiTimeSizeMasterData, setToritsukiTimeSizeMasterData] = useState([]);
  const [toritsukiTimeSizeList, setToritsukiTimeSizeList] = useState([]);

  // 溶接線長
  const [detailsWeldLine, setWeldLine] = useState([]);
  const [yousetsuWeldLineList, setYousetsuWeldLineList] = useState([]);

  // 加工速度（溶接仕上線長）
  const [kakouSokudoSenchouMasterData, setKakouSokudoSenchouMasterData] = useState([]);
  const [kakouSokudoSenchouList, setKakouSokudoSenchouList] = useState([]);

  // 個数
  const [kosuu, setKosuu] = useState([]);
  const [kosuuList, setKousuuList] = useState([]);
  const [iq3KosuuList, setIQ3KosuuList] = useState([]);
  const [iq3Kosuu, setIq3Kosuu] = useState([]);

  // iq3
  const [iq3SetupAmount, setiq3SetupAmount] = useState('');
  const [iq3WorkAmount, setiq3WorkAmount] = useState('');
  const [iq3ProcessInspection, setIQ3ProcessInspection] = useState('');
  const [secondProcessAmount, setSecondProcessAmount] = useState('');
  const [jointBarashiAmount, setJointBarashiAmount] = useState('');
  const [iq3ItaatsuMaster, setIQ3ItaatsuMaster] = useState([]);
  const [jointSizeMaster, setJointSizeMaster] = useState([]);
  const [iq3BendingTimeListData, setIQ3BendingTimeListData] = useState([]);
  const [gasTypeMaster, setGasTypeMaster] = useState([]);
  // 保有金型
  const [retainedMoldMaster, setRetainedMoldMaster] = useState([]);
  // ジョイントサイズ
  const [jointSizeList, setJointSizeList] = useState([]);
  // ガス種類
  const [gasTypeListData, setGasTypeListData] = useState([]);
  // ジョイント
  const [jointItaatsuListData, setJointItaatsuListData] = useState([]);
  // 加工速度係数
  const [blankKakouSokudoItaatsuList, setBlankKakouSokudoItaatsuList] = useState([]);
  // ガス係数
  const [gasKeisuuList, setGasKeisuuList] = useState([]);
  const [extractConditionList, setExtractConditionList] = useState([]);
  // 1ピアス加工時間
  const [piercingProcessItaatsuListData, setPiercingProcessItaatsuListData] = useState([]);
  // 1パンチ加工時間
  const [punchProcessItaatsuListData, setPunchProcessItaatsuListData] = useState([]);

  // 曲げ時間係数
  const [length, setLength] = useState([]);
  const [bendingTimeFactorListData, setBendingTimeFactorListData] = useState([]);
  const [bendingCorrectionTimeFactorListData, setBendingCorrectionTimeFactorListData] = useState([]);
  // 補正時間係数
  const [correctionTimeFactorListData, setCorrectionTimeFactorListData] = useState([]);
  const [iq3BendingKosuuList, setiQ3BendingKousuuList] = useState([]);
  // 曲げ数
  const [iq3MageSuuMaster, setIQ3MageSuuMaster] = useState([]);
  const [iq3MageSuuListData, setIQ3MageSuuListData] = useState([]);
  // 曲げ個数
  const [iq3MageKosuuMaster, setIQ3MageKosuuMaster] = useState([]);
  const [iq3MageKosuuListData, setIQ3MageKosuuListData] = useState([]);
  // 段取設定の重量と曲げ長リスト
  const [dantoriJuuryouMagechouList, setDantoriJuuryouMagechouList] = useState([]);
  // 曲げ長さ別段取時間
  const [magechouDantoriJikanListData, setMagechouDantoriJikanListData] = useState([]);
  // 面積
  const [iq3AreaMaster, setIQ3AreaMaster] = useState([]);
  const [iq3KakouJikanListData, setIQ3KakouJikanListData] = useState([]);
  // ブランク加工速度
  const [blankKakousokudolistData, setBlankKakousokudolistData] = useState([]);

  const [types, setTypes] = useState('');
  // 加工方法
  const [processMethod, setProcessMethod] = useState('');
  const [processMethods, setProcessMethods] = useState([]);
  // 生産個数
  const [seisanKosuu, setSeisanKosuu] = useState([]);
  const [seisanKosuuList, setSeisanKosuuList] = useState([]);
  // 工程内検査の生産個数
  const [processInspectionKosuuList, setProcessInspectionKosuuList] = useState([]);

  // 本数
  const [honSuu, setHonsuu] = useState([]);
  const [honSuuList, setHonSuuList] = useState([]);
  // サイズ
  const [iq3SizeMaster, setIQ3SizeMaster] = useState([]);
  const [iq3KaniListData, setIQ3KaniListData] = useState([]);
  const [itaatsu1, setItaatsu1] = useState([]);
  const [zaishitsu, setZaishitsu] = useState([]);
  // 製品重量係数
  const [iq3ProductSizeListData, setiq3ProductSizeListData] = useState([]);

  // 一部品あたり作業時間
  const [processTimeByUnit, setProcessTimeByUnit] = useState('');

  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);
  const [isMaterialHandling, setIsMaterialHandling] = useState(false);

  // 同一サイズ、同一形状をパターン化する
  const [programSameSizePatternValue, setProgramSameSizePatternValue] = useState([]);

  // 工程内検査
  const [processInspectionTime, setProcessInspectionTime] = useState('');

  // 材料配置
  const [pierWidth1, setPierWidth1] = useState('');
  const [pierWidth2, setPierWidth2] = useState('');
  const [grabAllowance1, setGrabAllowance1] = useState('');
  const [grabAllowance2, setGrabAllowance2] = useState('');
  const [processRange1, setProcessRange1] = useState('');
  const [processRange2, setProcessRange2] = useState('');
  const [pickupTime, setPickupTime] = useState('');

  // パンチ条件
  const [punchConditionList, setPunchConditionList] = useState('');

  const [drillingTime, setDrillingTime] = useState('');
  // 送り速度
  const [forwardSpeed, setForwardSpeed] = useState('');
  // ローラー/ホイール/ケガキ金型　加工速度
  const [rollerSpeed, setRollerSpeed] = useState('');

  // 送り速度
  const [okuriSokudoVal, setOkuriSokudoVal] = useState('');
  // ケガキ速度
  const [kegakiSokudoVal, setKegakiSokudoVal] = useState('');

  const [kakosokudo_bari, setkakosokudo_bari] = useState('');
  const [naikei_bari, setNaikeibari] = useState('');
  const [naikei_min, setNaikeiMin] = useState('');
  const [naikei_max, setNaikeiMax] = useState('');
  const [ana_min, setAnaMin] = useState('');
  const [ana_max, setAnaMax] = useState('');

  // 員数に関わらず1カットにする
  const [shirringflag, setShirringFlag] = useState(false);

  const [cutTime, setCutTime] = useState('00:00:00');

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 50 + 'px',
      render: (id, record, index) => {
        return getCurrentData().length - index;
      },
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend',
    },
    {
      id: '2',
      title: '表示',
      dataIndex: 'display',
      width: 95 + 'px',
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '3',
      title: 'お客様フラグ',
      dataIndex: 'customerFlg',
      width: 95 + 'px',
      render: (index, item) => {
        return item['customerFlg'] === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '4',
      title: '仕入先フラグ',
      dataIndex: 'supplierFlg',
      width: 95 + 'px',
      render: (index, item) => {
        return item['supplierFlg'] === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '5',
      title: '委託先フラグ',
      dataIndex: 'outsourcerFlg',
      width: 95 + 'px',
      render: (index, item) => {
        return item['outsourcerFlg'] === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item['id']}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '6',
      title: 'コード',
      dataIndex: 'code',
      width: 120 + 'px',
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      id: '7',
      title: '枝番',
      dataIndex: 'brunchNo',
      width: 90 + 'px',
    },
    {
      id: '8',
      title: '名称',
      dataIndex: 'companyName',
      width: 150 + 'px',
    },
    {
      id: '9',
      title: 'カナ',
      dataIndex: 'companyNameKata',
      width: 150 + 'px',
    },
    {
      id: '10',
      title: '係数',
      dataIndex: 'coefficient',
      width: 70 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '11',
      title: 'Tel',
      dataIndex: 'tel',
      width: 125 + 'px',
    },
    {
      id: '12',
      title: 'Fax',
      dataIndex: 'fax',
      width: 125 + 'px',
    },
    {
      id: '13',
      title: '担当者',
      dataIndex: 'tantou',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: 'メール',
      dataIndex: 'mail',
      width: 150 + 'px',
    },
    {
      id: '15',
      title: '郵便番号',
      dataIndex: 'postalCode',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '都道府県',
      dataIndex: 'prefectures',
      width: 150 + 'px',
    },
    {
      id: '17',
      title: '住所',
      dataIndex: 'address',
      width: 150 + 'px',
    },

    {
      id: '18',
      title: 'ソート番号',
      dataIndex: 'sortNo',
      width: 95 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '19',
      title: '作成日',
      dataIndex: 'created',
      width: 100 + 'px',
    },
    {
      id: '20',
      title: '更新日',
      dataIndex: 'updated',
      width: 100 + 'px',
    },
    {
      id: '21',
      title: '備考',
      dataIndex: 'remarks',
      width: 200 + 'px',
    },
  ];

  const tableColumns = columns.filter((item) => {
    return (
      item.id ==
      initialValues.filter((i) => {
        if (i == item.id) {
          return i;
        }
      })
    );
  });
  useEffect(() => {
    let selectedRowData = props.selectedData;
    setEditMode(props.editMode != undefined ? props.editMode : false);
    setSelectedDeviceData(selectedRowData);
    setDetailsData({ ...selectedRowData });

    if (selectedRowData.length != 0) {
      setkakosokudo_bari(selectedRowData.kakosokudo_bari);
      setNaikeibari(selectedRowData.naikei_bari);
      setNaikeiMin(selectedRowData.naikei_min);
      setNaikeiMax(selectedRowData.naikei_max);
      setAnaMin(selectedRowData.ana_min);
      setAnaMin(selectedRowData.ana_min);
      setAnaMax(selectedRowData.ana_max);
      setShirringFlag(selectedRowData.shirringflag);
      setCutTime(selectedRowData.shirringTime);
      // iq3
      setIQ3BendingTimeListData(selectedRowData.iq3BendingTimeLstData);
      setGasTypeListData(selectedRowData.gasTypeLstData);
      setJointSizeList(selectedRowData.jointSizeListData);
      setJointItaatsuListData(selectedRowData.jointItaatsuLstData);
      setBlankKakouSokudoItaatsuList(selectedRowData.blankKakousokudoItaatsuList);
      setPiercingProcessItaatsuListData(selectedRowData.piercingProcessItaatsuLstData);
      setPunchProcessItaatsuListData(selectedRowData.punchProcessItaatsuLstData);
      setGasKeisuuList(selectedRowData.gasKeissuuLstData);
      setExtractConditionList(selectedRowData.extractConditionList);
      setIQ3ItaatsuMaster(selectedRowData.iq3ItaatsuMasterData);
      setGasTypeMaster(selectedRowData.gasTypeMasterData);
      setRetainedMoldMaster(selectedRowData.retrainedMoldMasterData);
      setJointSizeMaster(selectedRowData.jointSizeMasterData);

      setFlagChecked(selectedRowData.inputFlag);
      setInputDisabled(!selectedRowData.inputFlag);
      setCheckboxChecked(selectedRowData.itakuFlag);
      setSelectDisabled(!selectedRowData.itakuFlag);

      setKosuu(selectedRowData.kosuuMasterData);
      setKousuuList(selectedRowData.kosuuLstData);
      setDetailProcess(selectedRowData.processDetails);
      setDantoriChange(selectedRowData.processCharge);
      setDeviceType(selectedRowData.deviceTypes);
      setCode(selectedRowData.code);
      setProcessName(selectedRowData.processName);
      setProcessType(selectedRowData.processType);
      setDeviceCode(selectedRowData.deviceCode);
      setDeviceName(selectedRowData.deviceName);
      setRemarks(selectedRowData.remarks);
      // 溶接線長
      setWeldLine(selectedRowData.yousetsuSenchouMasterData);
      setYousetsuWeldLineList(selectedRowData.yousetsuSenchouLstData);

      // 加工速度（板厚）
      // setKakouSokudoSenchouMasterData(selectedRowData.yousetsuShiageSenchouMasterData);
      setKakouSokudoSenchouList(selectedRowData.yousetsuShiageSenchouLstData);

      setCustomerName(selectedRowData.customerName);
      setCustomerCode(selectedRowData.customerCode);

      setArea(selectedRowData.areaMasterData);
      setWeight(selectedRowData.weightMasterData);
      setSizeDetailsList(selectedRowData.sizeListData);
      setVolume(selectedRowData.volumeMasterData);
      setDantoriSizeDetailsList(selectedRowData.dantoriSizeListData);

      setKihonDantori(selectedRowData.kihonDantoriTime);
      setDantoriFlag(selectedRowData.dantoriFlag);
      setProcessInspectionTime(selectedRowData.processInspectionTime);

      setProcessTimeByUnit(selectedRowData.processTimeByUnit);

      // 名称
      setMeishou(selectedRowData.meishouDetails);
      setMeishouList(selectedRowData.meishouLstData);
      // 数量区分
      setSuuryouData(selectedRowData.kobuhinKeisuuMasterData);
      setSuuryouList(selectedRowData.kobuhinKeisuuLstData);
      setdantoriSetteiSuuryouList(selectedRowData.dantoriSetteiSuuryouLstData);

      // 要素数
      setYousosuuData(selectedRowData.yousosuuMasterData);
      setSagyouKeisuuYousosuuList(selectedRowData.sagyouJikanKeisuuLstData);
      setShokiDankaiJikanList(selectedRowData.shokiDankaiJikanLstData);

      setJikanChaji(selectedRowData.jikanChajji);

      setKouteiType(selectedRowData.kouteiType);
      setInitialCustomerData([]);
      setInitialCustomerDataBk([]);
      setIniAmount(selectedRowData.initialAmount);
      setKobuhinKeisuu(selectedRowData.kobuhinSuu);
      setPitchItaatsuList(selectedRowData.pitchItaatsuLstData);
      setTapWeldItaatsuList(selectedRowData.tapWeldingItaatsuLstData);

      setYousetsuNanidoItaatsuMasterData(selectedRowData.yousetsuNanidoItaatsuMasterData);
      setYousetsuNanidoItaatsuList(selectedRowData.yousetsuNanidoItaatsuLstData);

      setKaritsukiTimeItaatsuMasterData(selectedRowData.karitsukiJikanItaatsuMasterData);
      setKaritsukiTimeItaatsuList(selectedRowData.karitsukiJikanItaatsuLstData);

      setToritsukiTimeItaatsuList(selectedRowData.toritsukiJikanItaatsuLstData); //別々テーブル
      setToritsukiTimeItaatsuMasterData(selectedRowData.karitsukiJikanItaatsuMasterData);

      setToritsukiTimeSizeMasterData(selectedRowData.toritsukiJikanSizeMasterData);
      setToritsukiTimeSizeList(selectedRowData.toritsukiJikanSizeLstData);

      setSetupAmount(selectedRowData.setupCharge);
      setWorkAmount(selectedRowData.processCharge);
      setDantoriTime(selectedRowData.setupTime);
      setWorkTime(selectedRowData.workTime);
      setTsuikaKouteiTypes(selectedRowData.tsuikaKouteiTypes);

      setiq3SetupAmount(selectedRowData.iq3SetupAmount);
      setiq3WorkAmount(selectedRowData.iq3WorkAmount);
      setIQ3ProcessInspection(selectedRowData.iq3ProcessInspection);
      setSecondProcessAmount(selectedRowData.secondProcessAmount);
      setJointBarashiAmount(selectedRowData.jointBarashiAmount);
      setIQ3KosuuList(selectedRowData.iq3DantoriJikanKosuuLstData);
      // setIq3Kosuu(selectedRowData.iq3KosuuLstData);
      setBendingTimeFactorListData(selectedRowData.bendingTimeFactorLstData);
      setBendingCorrectionTimeFactorListData(selectedRowData.bendingCorrectionTimeFactorLstData);
      setCorrectionTimeFactorListData(selectedRowData.correctionTimeFactorLstData);
      setiq3ProductSizeListData(selectedRowData.iq3ProductSizeLstData);
      // console.log('PS--detaisl---', selectedRowData.iq3ProductSizeLstData);
      setLength(selectedRowData.lengthMasterData);
      setiQ3BendingKousuuList(selectedRowData.iq3BendingkosuuLstData);
      setIQ3MageSuuMaster(selectedRowData.mageSuuMasterData);
      setIQ3MageSuuListData(selectedRowData.mageSuuLstData);
      setIQ3MageKosuuMaster(selectedRowData.mageKosuuMasterData);
      setIQ3MageKosuuListData(selectedRowData.mageKosuuLstData);
      setTypes(selectedRowData.type);
      setDantoriJuuryouMagechouList(selectedRowData.dantoriSizeLstData);
      setMagechouDantoriJikanListData(selectedRowData.iq3MagechouDantoriJikanListData);
      setIQ3AreaMaster(selectedRowData.iq3AreaMasterData);
      setIQ3KakouJikanListData(selectedRowData.iq3KakouJikanListData);
      setBlankKakousokudolistData(selectedRowData.blankKakousokudoListData);
      setProcessMethod(selectedRowData.kakouType);
      setProcessMethods(selectedRowData.kakouMethods);
      setSeisanKosuu(selectedRowData.seisanKosuuMasterData);
      setSeisanKosuuList(selectedRowData.seisanKosuuLstData);
      setProcessInspectionKosuuList(selectedRowData.processInspectionKosuuLstData);
      setHonsuu(selectedRowData.honsuuMasterData);
      setHonSuuList(selectedRowData.honsuuLstData);
      setIQ3SizeMaster(selectedRowData.iq3SizeMasterData);

      setIQ3KaniListData(selectedRowData.iq3KaninyuryokuListData);
      setItaatsu1(selectedRowData.itaatsu1MasterData);

      setZaishitsu(selectedRowData.zaishitsuKubunn);
      setMaterialHandlingTimeList(selectedRowData.materialHandlingTime);
      setProgramSameSizePatternValue(selectedRowData.programSameSizePatternValue);

      setPierWidth1(selectedRowData.pierWidth1);
      setPierWidth2(selectedRowData.pierWidth2);
      setGrabAllowance1(selectedRowData.grabAllowance1);
      setGrabAllowance2(selectedRowData.grabAllowance2);
      setProcessRange1(selectedRowData.processRange1);
      setProcessRange2(selectedRowData.processRange2);
      setPickupTime(selectedRowData.pickupTime);
      setPunchConditionList(selectedRowData.punchConditionList);
      setDrillingTime(selectedRowData.drillingTime);
      setForwardSpeed(selectedRowData.forwardSpeed);
      setRollerSpeed(selectedRowData.rollerSpeed);
      setOkuriSokudoVal(selectedRowData.okuriSokudoVal);
      setKegakiSokudoVal(selectedRowData.kegakiSokudoVal);
    } else {
      setProcessName('');
      setCode('');
      setDeviceCode('');
      setDeviceName('');
      setRemarks('');
    }
  }, [props.selectedData, props.editMode]);

  useEffect(() => {
    setIsCharge(props.flag);
  }, [, props.flag]);

  useImperativeHandle(ref, () => ({}));

  const filterCustomer = (e, name) => {
    let customerData = initialCustomerDataBk;

    if (name === 'companyname') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.companyName.includes(e.target.value);
        });

        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'tantou') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.tantou.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'tel') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.tel.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'prefecture') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.prefectures.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'address') {
      if (e.target.value) {
        const deviceFilter = customerData.filter((item) => {
          return item.address.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    }
  };
  const result = tableColumns.map((column) => {
    if (column.title === '名称') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>名称</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'companyname')}></Input>
          </div>
        ),
      };
    } else if (column.title === 'Tel') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>Tel</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'tel')}></Input>
          </div>
        ),
      };
    } else if (column.title === '担当者') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>担当者</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'tantou')}></Input>
          </div>
        ),
      };
    } else if (column.title === '都道府県') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>都道府県</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'prefecture')}></Input>
          </div>
        ),
      };
    } else if (column.title === '住所') {
      return {
        ...column,
        title: (
          <div style={{ textAlign: 'center' }}>
            <div>住所</div>
            <Input onPressEnter={(e) => filterCustomer(e, 'address')}></Input>
          </div>
        ),
      };
    }
    return column;
  });
  const chargeAmount = () => {
    if (isCharge == true) {
      setIsCharge(false);
      props.updatedFlag(false);
    } else {
      setIsCharge(true);
      props.updatedFlag(true);
    }
  };
  const baritoriShurui = () => {
    if (isBaritoriShurui == true) {
      setIsBaritoriShurui(false);
    } else {
      setIsBaritoriShurui(true);
    }
  };
  const dantoriJikan = () => {
    if (isDantoriJikan === true) {
      setIsDantoriJikan(false);
    } else {
      setIsDantoriJikan(true);
    }
  };
  const setupSetting = () => {
    if (isSetupSetting == true) {
      setIsSetupSetting(false);
    } else {
      setIsSetupSetting(true);
    }
  };
  const kouteiNaikensa = () => {
    if (isKouteiNaikensa == true) {
      setIsKouteiNaikensa(false);
    } else {
      setIsKouteiNaikensa(true);
    }
  };
  const magekakouJikan = () => {
    if (isMagekakouJikan === true) {
      setIsMagekakouJikan(false);
    } else {
      setIsMagekakouJikan(true);
    }
  };
  const arrangeMaterial = () => {
    if (isArrangeMaterial === true) {
      setIsArrangeMaterial(false);
    } else {
      setIsArrangeMaterial(true);
    }
  };
  const punch = () => {
    if (isPunch === true) {
      setIsPunch(false);
    } else {
      setIsPunch(true);
    }
  };
  const piercingProcess = () => {
    if (isPiercingProcess === true) {
      setIsPiercingProcess(false);
    } else {
      setIsPiercingProcess(true);
    }
  };
  const punchProcess = () => {
    if (isPunchProcess === true) {
      setIsPunchProcess(false);
    } else {
      setIsPunchProcess(true);
    }
  };
  const mageHoseiJikan = () => {
    if (isMageHoseiJikan === true) {
      setIsMageHoseiJikan(false);
    } else {
      setIsMageHoseiJikan(true);
    }
  };
  const mageHoseiJikanFactor = () => {
    if (isMageHoseiJikanFactor === true) {
      setIsMageHoseiJikanFactor(false);
    } else {
      setIsMageHoseiJikanFactor(true);
    }
  };
  const processSizeFactor = () => {
    if (isProcessSizeFactor === true) {
      setIsProcessSizeFactor(false);
    } else {
      setIsProcessSizeFactor(true);
    }
  };
  const bendingTimeFactor = () => {
    if (isBendingTimeFactor === true) {
      setIsBendingTimeFactor(false);
    } else {
      setIsBendingTimeFactor(true);
    }
  };
  const processSpeed = () => {
    if (isProcessSpeed == true) {
      setIsProcessSpeed(false);
    } else {
      setIsProcessSpeed(true);
    }
  };
  const sagyouSokudo = () => {
    if (isSagyouSokudo == true) {
      setIsSagyouSokudo(false);
    } else {
      setIsSagyouSokudo(true);
    }
  };
  const sagyouKeisuu = () => {
    if (isSagyouKeisuu == true) {
      setIsSagyouKeisuu(false);
    } else {
      setIsSagyouKeisuu(true);
    }
  };
  const iq3DantoriJikan = () => {
    if (isiq3DantoriJikan === true) {
      setIsiq3DantoriJikan(false);
    } else {
      setIsiq3DantoriJikan(true);
    }
  };
  const iq3KakouJikan = () => {
    if (isiq3KakouJikan === true) {
      setIsiq3KakouJikan(false);
    } else {
      setIsiq3KakouJikan(true);
    }
  };
  const blankKakouSokudo = () => {
    if (isBlankKakouSokudo === true) {
      setIsBlankKakouSokudo(false);
    } else {
      setIsBlankKakouSokudo(true);
    }
  };
  const blankKakouSokudoKeisuu = () => {
    if (isBlankKakouSokudoKeisuu === true) {
      setIsBlankKakouSokudoKeisuu(false);
    } else {
      setIsBlankKakouSokudoKeisuu(true);
    }
  };
  const blankGasuKeisuu = () => {
    if (isBlankGasuKeisuu === true) {
      setIsBlankGasuKeisuu(false);
    } else {
      setIsBlankGasuKeisuu(true);
    }
  };
  const extractCondition = () => {
    if (isExtractCondition === true) {
      setIsExtractCondition(false);
    } else {
      setIsExtractCondition(true);
    }
  };
  const blankGasuType = () => {
    if (isBlankGasuType === true) {
      setIsBlankGasuType(false);
    } else {
      setIsBlankGasuType(true);
    }
  };
  const blankDrillingTime = () => {
    if (isBlankDrillingTime === true) {
      setIsBlankDrillingTime(false);
    } else {
      setIsBlankDrillingTime(true);
    }
  };
  const okuriSokudo = () => {
    if (isOkuriSokudo === true) {
      setIsOkuriSokudo(false);
    } else {
      setIsOkuriSokudo(true);
    }
  };
  const rollerKakouSokudo = () => {
    if (isRollerKakouSokudo === true) {
      setIsRollerKakouSokudo(false);
    } else {
      setIsRollerKakouSokudo(true);
    }
  };
  const blankJoint = () => {
    if (isBlankJoint === true) {
      setIsBlankJoint(false);
    } else {
      setIsBlankJoint(true);
    }
  };
  const iq3KakouSokudo = () => {
    if (isiq3KakouSokudo === true) {
      setIsiq3KakouSokudo(false);
    } else {
      setIsiq3KakouSokudo(true);
    }
  };
  const iq3ManualBari = () => {
    if (isiq3ManualBari === true) {
      setIsiq3ManualBari(false);
    } else {
      setIsiq3ManualBari(true);
    }
  };
  const processTime = () => {
    if (isProcessTime == true) {
      setIsProcessTime(false);
    } else {
      setIsProcessTime(true);
    }
  };
  const attach = () => {
    if (isAttach == true) {
      setIsAttach(false);
    } else {
      setIsAttach(true);
    }
  };

  const toritsukiJikanItaatsu = () => {
    if (isToritsukiJikanItaatsu === true) {
      setIsToritsukiJikanItaatsu(false);
    } else {
      setIsToritsukiJikanItaatsu(true);
    }
  };
  const toritsukiJikanSize = () => {
    if (isToritsukiJikanSize === true) {
      setIsToritsukiJikanSize(false);
    } else {
      setIsToritsukiJikanSize(true);
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };
  const deviceDetails = () => {
    if (isDeviceDetails === true) {
      setIsDeviceDetails(false);
    } else {
      setIsDeviceDetails(true);
    }
  };
  const tapWelding = () => {
    if (isTapWelding == true) {
      setIsTapWelding(false);
    } else {
      setIsTapWelding(true);
    }
  };
  const pitch = () => {
    if (isPitch == true) {
      setIsPitch(false);
    } else {
      setIsPitch(true);
    }
  };
  const productSize = () => {
    if (isProductSize == true) {
      setIsProductSize(false);
    } else {
      setIsProductSize(true);
    }
  };
  const productQuantity = () => {
    if (isProductQuantity == true) {
      setIsProductQuantity(false);
    } else {
      setIsProductQuantity(true);
    }
  };
  const childPart = () => {
    if (isChildPart == true) {
      setIsChildPart(false);
    } else {
      setIsChildPart(true);
    }
  };
  const workAccuracy = () => {
    if (isWorkAccuracy == true) {
      setIsWorkAccuracy(false);
    } else {
      setIsWorkAccuracy(true);
    }
  };
  const materialHandling = () => {
    if (isMaterialHandling == true) {
      setIsMaterialHandling(false);
    } else {
      setIsMaterialHandling(true);
    }
  };
  const editData = () => {
    setEditMode(true);
    props.updatedFlag(isCharge);
    props.updateEditMode(true);
  };
  const updateData = () => {
    setUpdateConfirm(true);
  };
  const discardData = () => {
    setCancelConfirm(true);
  };
  const updConfirmOk = (e) => {
    setEditMode(false);
    props.updateEditMode(false);
    setUpdateConfirm(false);

    // update list data
    detailsData['deviceCode'] = deviceCode;
    detailsData['deviceName'] = deviceName;
    detailsData['customerName'] = customerName;
    detailsData['customerCode'] = customerCode;
    detailsData['inputFlag'] = flagChecked;
    detailsData['itakuFlag'] = checkboxChecked;
    detailsData['kobuhinSuu'] = koubuhinnKeisuu;
    detailsData['initialAmount'] = iniAmount;
    detailsData['remarks'] = remarks;
    detailsData['setupCharge'] = setupAmount;
    detailsData['processCharge'] = workAmount;
    detailsData['setupTime'] = dantoriTime;
    detailsData['workTime'] = workTime;
    detailsData['kouteiType'] = kouteiType;
    detailsData['processDetails'] = detailsProcess;

    detailsData['kihonDantoriTime'] = kihonDantori;
    detailsData['processTimeByUnit'] = processTimeByUnit;
    detailsData['meishouDetails'] = meishou;
    detailsData['jikanChajji'] = jikanCharge;
    detailsData['pitchItaatsuLstData'] = pitchItaatsuList;
    detailsData['tapWeldingItaatsuLstData'] = tapWeldItaatsuList;

    detailsData['yousetsuNanidoItaatsuMasterData'] = yousetsuNanidoItaatsuMasterData;
    detailsData['yousetsuNanidoItaatsuLstData'] = yousetsuNanidoItaatsuList;

    detailsData['karitsukiJikanItaatsuMasterData'] = karitsukiTimeItaatsuMasterData;
    detailsData['karitsukiJikanItaatsuLstData'] = karitsukiTimeItaatsuList;

    detailsData['toritsukiJikanItaatsuMasterData'] = toritsukiTimeItaatsuMasterData;
    detailsData['toritsukiJikanItaatsuLstData'] = toritsukiTimeItaatsuList;

    detailsData['toritsukiJikanSizeMasterData'] = toritsukiTimeSizeMasterData;
    detailsData['toritsukiJikanSizeLstData'] = toritsukiTimeSizeList;

    detailsData['yousetsuSenchouMasterData'] = detailsWeldLine;
    detailsData['yousetsuSenchouLstData'] = yousetsuWeldLineList;

    // detailsData['yousetsuShiageSenchouMasterData'] = kakouSokudoSenchouMasterData;
    detailsData['yousetsuShiageSenchouLstData'] = kakouSokudoSenchouList;

    detailsData['kobuhinKeisuuMasterData'] = suuryouData;
    detailsData['kobuhinKeisuuLstData'] = suuryouList;
    detailsData['dantoriSetteiSuuryouLstData'] = dantoriSetteiSuuryouList;

    detailsData['meishouMasterData'] = meishou;
    detailsData['meishouLstData'] = meishouList;

    detailsData['weightMasterData'] = weight;
    detailsData['volumeMasterData'] = volume;
    detailsData['sizeListData'] = sizesDetailsList;
    detailsData['dantoriSizeListData'] = dantoriSizeDetailsList;

    detailsData['kosuuMasterData'] = kosuu;
    detailsData['kosuuLstData'] = kosuuList;

    detailsData['yousosuuMasterData'] = yousosuuData;
    detailsData['sagyouJikanKeisuuLstData'] = sagyouKeisuuYousosuuList;
    detailsData['shokiDankaiJikanLstData'] = shokiDankaiJikanList;

    // iq3
    detailsData['iq3SetupAmount'] = iq3SetupAmount;
    detailsData['iq3WorkAmount'] = iq3WorkAmount;
    detailsData['iq3ProcessInspection'] = iq3ProcessInspection;
    detailsData['secondProcessAmount'] = secondProcessAmount;
    detailsData['jointBarashiAmount'] = jointBarashiAmount;

    detailsData['iq3ItaatsuMasterData'] = iq3ItaatsuMaster;
    detailsData['iq3BendingTimeLstData'] = iq3BendingTimeListData;

    detailsData['lengthMasterData'] = length;
    detailsData['bendingTimeFactorLstData'] = bendingTimeFactorListData;
    detailsData['bendingCorrectionTimeFactorLstData'] = correctionTimeFactorListData;

    detailsData['mageSuuMasterData'] = iq3MageSuuMaster;
    detailsData['mageSuuLstData'] = iq3MageSuuListData;

    detailsData['mageKosuuMasterData'] = iq3MageKosuuMaster;
    detailsData['mageKosuuLstData'] = iq3MageKosuuListData;
    detailsData['dantoriSizeLstData'] = dantoriJuuryouMagechouList;
    detailsData['iq3MagechouDantoriJikanListData'] = magechouDantoriJikanListData;

    detailsData['iq3AreaMasterData'] = iq3AreaMaster;
    detailsData['iq3KakouJikanListData'] = iq3KakouJikanListData;
    detailsData['seisanKosuuMasterData'] = seisanKosuu;
    detailsData['seisanKosuuLstData'] = seisanKosuuList;
    detailsData['iq3KaninyuryokuListData'] = iq3KaniListData;
    detailsData['itaatsu1MasterData'] = itaatsu1;
    detailsData['iq3SizeMasterData'] = iq3SizeMaster;
    detailsData['iq3BendingkosuuLstData'] = iq3BendingKosuuList;
    detailsData['iq3ProductSizeLstData'] = iq3ProductSizeListData;
    detailsData['kakouType'] = processMethod;
    detailsData['iq3DantoriJikanKosuuLstData'] = iq3KosuuList;
    detailsData['correctionTimeFactorLstData'] = correctionTimeFactorListData;
    detailsData['bendingCorrectionTimeFactorLstData'] = bendingCorrectionTimeFactorListData;
    detailsData['materialHandlingTime'] = materialHandlingTimeList;
    detailsData['programSameSizePatternValue'] = programSameSizePatternValue;
    detailsData['gasTypeLstData'] = gasTypeListData;
    detailsData['jointItaatsuLstData'] = jointItaatsuListData;
    detailsData['blankKakousokudoItaatsuList'] = blankKakouSokudoItaatsuList;
    detailsData['piercingProcessItaatsuLstData'] = piercingProcessItaatsuListData;
    detailsData['punchProcessItaatsuLstData'] = punchProcessItaatsuListData;
    detailsData['gasKeissuuLstData'] = gasKeisuuList;
    detailsData['gasTypeMasterData'] = gasTypeMaster;
    detailsData['jointSizeMasterData'] = jointSizeMaster;
    detailsData['jointSizeListData'] = jointSizeList;
    detailsData['blankKakousokudoListData'] = blankKakousokudolistData;
    detailsData['honsuuMasterData'] = honSuu;
    detailsData['honsuuLstData'] = honSuuList;
    detailsData['processInspectionTime'] = processInspectionTime;
    detailsData['processInspectionKosuuLstData'] = processInspectionKosuuList;
    detailsData['dantoriFlag'] = dantoriFlag;
    detailsData['pierWidth1'] = pierWidth1;
    detailsData['pierWidth2'] = pierWidth2;
    detailsData['grabAllowance1'] = grabAllowance1;
    detailsData['grabAllowance2'] = grabAllowance2;
    detailsData['processRange1'] = processRange1;
    detailsData['processRange2'] = processRange2;
    detailsData['pickupTime'] = pickupTime;
    detailsData['punchConditionList'] = punchConditionList;
    detailsData['drillingTime'] = drillingTime;
    detailsData['forwardSpeed'] = forwardSpeed;
    detailsData['rollerSpeed'] = rollerSpeed;
    detailsData['extractConditionList'] = extractConditionList;
    detailsData['retrainedMoldMasterData'] = retainedMoldMaster;
    detailsData['okuriSokudoVal'] = okuriSokudoVal;
    detailsData['kegakiSokudoVal'] = kegakiSokudoVal;
    detailsData['naikei_bari'] = naikei_bari;
    detailsData['kakosokudo_bari'] = kakosokudo_bari;

    detailsData['naikei_min'] = naikei_min;
    detailsData['naikei_max'] = naikei_max;

    detailsData['ana_min'] = ana_min;
    detailsData['ana_max'] = ana_max;
    detailsData['shirringflag'] = shirringflag;
    detailsData['shirringTime'] = cutTime;
    props.updatedDetails(detailsData);
  };
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    setCancelConfirm(false);
    setEditMode(false);
    setDeviceName(detailsData['deviceName']);
    setDeviceCode(detailsData['deviceCode']);
    setCustomerName(detailsData['customerName']);
    setCustomerCode(detailsData['customerCode']);
    setIniAmount(detailsData['initialAmount']);
    setRemarks(detailsData['remarks']);
    setDetailProcess(detailsData['processDetails']);
    setKobuhinKeisuu(detailsData['kobuhinSuu']);
    setSetupAmount(detailsData['setupCharge']);
    setWorkAmount(detailsData['processCharge']);
    setDantoriTime(detailsData['setupTime']);
    setWorkTime(detailsData['workTime']);
    // setSagyouTime(detailsData[''])
  };
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
    props.updateEditMode(true);
  };
  const updatedChargeAmount = (updDetails) => {
    setDetailProcess(updDetails);
  };
  const updatedCharge = (result) => {
    setJikanChaji(result);
  };
  const updateForwardSpeed = (result) => {
    setForwardSpeed(result);
  };
  const updateRollerSpeed = (result) => {
    setRollerSpeed(result);
  };
  const updateDrillingTime = (result) => {
    setDrillingTime(result);
  };
  const updatePunchList = (result) => {
    setPunchConditionList(result);
  };
  const updatePierWidth1 = (result) => {
    setPierWidth1(result);
  };
  const updatePierWidth2 = (result) => {
    setPierWidth2(result);
  };
  const updateGrabAllowance1 = (result) => {
    setGrabAllowance1(result);
  };
  const updateGrabAllowance2 = (result) => {
    setGrabAllowance2(result);
  };
  const updateProcessRange1 = (result) => {
    setProcessRange1(result);
  };
  const updateProcessRange2 = (result) => {
    setProcessRange2(result);
  };
  const updatePickupTime = (result) => {
    setPickupTime(result);
  };
  const updateProcessMethod = (result) => {
    setProcessMethod(result);
  };
  const updateSetupAmount = (result) => {
    setiq3SetupAmount(result);
  };
  const updateWorkAmount = (result) => {
    setiq3WorkAmount(result);
  };
  const updateiq3ProcessInspection = (result) => {
    setIQ3ProcessInspection(result);
  };
  const updatesecondProcessAmount = (result) => {
    setSecondProcessAmount(result);
  };
  const updatejointBarashiAmount = (result) => {
    setJointBarashiAmount(result);
  };
  const updatedDantori = (updDetails) => {
    setDetailProcess(updDetails);
  };
  const updateSuuryouData = (result) => {
    setSuuryouData(result);
  };
  const updatedantoriSetteiSuuryouList = (result) => {
    setdantoriSetteiSuuryouList(result);
  };
  const updatedTime = (result) => {
    setKihonDantori(result);
  };

  const updatedYousetsuSenchouData = (result) => {
    setWeldLine(result);
  };
  const updateYousetsuSenchouList = (result) => {
    setYousetsuWeldLineList(result);
  };

  const updateKakousokudoSenchouList = (result) => {
    setKakouSokudoSenchouList(result);
  };
  const upadateKaritsukiItaatsuData = (result) => {
    setKaritsukiTimeItaatsuMasterData(result);
  };
  const updateKaritsukiTimeList = (result) => {
    setKaritsukiTimeItaatsuList(result);
  };
  const updateYousetsuNaniItaatsu = (result) => {
    setYousetsuNanidoItaatsuMasterData(result);
  };
  const updatedLists = (result) => {
    setYousetsuNanidoItaatsuList(result);
  };

  const updateItaatsuLst = (result) => {
    setPitchItaatsuList(result);
  };
  const updateProductSizeArea = (result) => {
    setArea(result);
  };
  const updateKosuu = (result) => {
    setKosuu(result);
  };
  const updateSeisanKosuu = (result) => {
    setSeisanKosuu(result);
  };
  const updateHonsuu = (result) => {
    setHonsuu(result);
  };
  const updateiq3SeisanKosuuList = (result) => {
    setSeisanKosuuList(result);
  };
  const updateProcessInspectionKosuuList = (result) => {
    setProcessInspectionKosuuList(result);
  };
  const updateHonsuuList = (result) => {
    setHonSuuList(result);
  };
  const updateDantoriTime = (result) => {
    setKihonDantori(result);
  };
  const updateDantoriFlag = (result) => {
    setDantoriFlag(result);
  };
  const updateProcessInspectionTime = (result) => {
    setProcessInspectionTime(result);
  };
  const updateMageKosuu = (result) => {
    setIQ3MageKosuuMaster(result);
  };
  const updateMageKosuList = (result) => {
    setIQ3MageKosuuListData(result);
  };
  const updateiq3BendingKosuuList = (result) => {
    setiQ3BendingKousuuList(result);
  };
  const updateiq3KosuuList = (result) => {
    setSeisanKosuuList(result);
  };
  const updateKousuuLst = (result) => {
    setIq3Kosuu(result);
  };
  const updateKosuuList = (result) => {
    setKousuuList(result);
  };
  const updatename = (result) => {
    setMeishou(result);
  };
  const updateMeishouList = (result) => {
    setMeishouList(result);
  };
  const updateProductSizeWeight = (result) => {
    setWeight(result);
  };
  const updateBendingTimeArea = (result) => {
    setIQ3AreaMaster(result);
  };
  const updateDantoriJuuryoMagechouListData = (result) => {
    setDantoriJuuryouMagechouList(result);
  };
  const updateMagechouDantoriJikanList = (result) => {
    setMagechouDantoriJikanListData(result);
  };
  const updateBendingTimeFactorListData = (result) => {
    setBendingTimeFactorListData(result);
  };
  const updateBendingCorrectionTimeFactorListData = (result) => {
    setBendingCorrectionTimeFactorListData(result);
  };
  const updatecorrectionTimeFactorListData = (result) => {
    setCorrectionTimeFactorListData(result);
  };
  const updateIQ3ProductSizeListData = (result) => {
    setiq3ProductSizeListData(result);
  };
  const updateBendingTimeLength = (result) => {
    setLength(result);
  };
  const updateIQ3AreaMaster = (result) => {
    setIQ3AreaMaster(result);
  };
  const updateIQ3KakouJikanList = (result) => {
    setIQ3KakouJikanListData(result);
  };
  const updatediq3SizeMaster = (result) => {
    setIQ3SizeMaster(result);
  };
  const updatediq3KaniListData = (result) => {
    setIQ3KaniListData(result);
  };
  const updateItaatsu1 = (result) => {
    setItaatsu1(result);
  };
  const updateCutTime = (result) => {
    setCutTime(result);
  };
  const updateShirringFlag = (result) => {
    setShirringFlag(result);
  };
  const updateVolume = (result) => {
    setVolume(result);
  };
  const updateDantoriSizeList = (result) => {
    setDantoriSizeDetailsList(result);
  };
  const updateProductSizeList = (result) => {
    setSizeDetailsList(result);
  };
  const updateSuuryouDetails = (result) => {
    setSuuryouData(result);
  };
  const updateYousosuu = (result) => {
    setYousosuuData(result);
  };
  const updateMagesuuData = (result) => {
    setIQ3MageSuuMaster(result);
  };
  const updateMagesuuList = (result) => {
    setIQ3MageSuuListData(result);
  };
  const updateIQ3Itaatsu = (result) => {
    setIQ3ItaatsuMaster(result);
  };
  const updatedBlankKakousokudolistData = (result) => {
    setBlankKakousokudolistData(result);
  };
  const updateNaikeibari = (result) => {
    setNaikeibari(result);
  };
  const updateKakousokudobari = (result) => {
    setkakosokudo_bari(result);
  };
  const updateNaikeiMin = (result) => {
    setNaikeiMin(result);
  };
  const updateNaikeiMax = (result) => {
    setNaikeiMax(result);
  };
  const updateAnaMax = (result) => {
    setAnaMax(result);
  };
  const updateAnaMin = (result) => {
    setAnaMin(result);
  };
  const updateGasType = (result) => {
    setGasTypeMaster(result);
  };
  const updatedRetainedMold = (result) => {
    setRetainedMoldMaster(result);
  };
  const updateBendingTimeList = (result) => {
    setIQ3BendingTimeListData(result);
  };
  const updateGasTypeList = (result) => {
    setGasTypeListData(result);
  };
  const updatePiercingProcessList = (result) => {
    setPiercingProcessItaatsuListData(result);
  };
  const updatePunchProcessList = (result) => {
    setPunchProcessItaatsuListData(result);
  };
  const updateJointSizeMaster = (result) => {
    setJointSizeMaster(result);
  };
  const updateJointSizeList = (result) => {
    setJointSizeList(result);
  };
  const updateJointItaatsuList = (result) => {
    setJointItaatsuListData(result);
  };
  const updateblankKakouSokudoItaatsuList = (result) => {
    setBlankKakouSokudoItaatsuList(result);
  };
  const updateOkuriSokudoVal = (result) => {
    setOkuriSokudoVal(result);
  };
  const updateKegakiSokudoVal = (result) => {
    setKegakiSokudoVal(result);
  };
  const updateGasKeisuuList = (result) => {
    setGasKeisuuList(result);
  };
  const updateExtractConditionList = (result) => {
    setExtractConditionList(result);
  };
  const updateshokiDankaiJikanListData = (result) => {
    setShokiDankaiJikanList(result);
  };
  const updateProgramSameSizePatternValue = (result) => {
    setProgramSameSizePatternValue(result);
  };
  const updateSuuryouListData = (result) => {
    setSuuryouList(result);
  };
  const updateDantoriProductSizeWeight = (result) => {
    setWeight(result);
  };
  const updatKobuhinnKeisuu = (result) => {
    setKobuhinKeisuu(result);
  };

  const updateTapWeldItaatsuLst = (result) => {
    setTapWeldItaatsuList(result);
  };
  const updateToritsukiSizeData = (result) => {
    setToritsukiTimeSizeMasterData(result);
  };
  const updateSizeList = (result) => {
    setToritsukiTimeSizeList(result);
  };

  const updateToritsukiItaatsuList = (result) => {
    setToritsukiTimeItaatsuList(result);
  };

  const changeTime = (e) => {
    setProcessTimeByUnit(e);
  };
  // マテリアルハンドリング時間更新
  const updateMaterialHandlingList = (result) => {
    setMaterialHandlingTimeList(result);
  };

  // マテリアルハンドリング時間更新
  const updateYousosuuListData = (result) => {
    setSagyouKeisuuYousosuuList(result);
  };

  // バリ取種類
  const baritoriTypesContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={baritoriShurui}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>{processName === 'バリ取' ? 'バリ取' : 'ブランク'}種類</label>
            {isBaritoriShurui ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBaritoriShurui ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_ProcessType
              processMethods={processMethods}
              processMethod={processMethod}
              editMode={editMode}
              updatedProcessMethod={updateProcessMethod}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // チャージ金額
  const chargeAmountContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={chargeAmount}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>チャージ金額</label>
            {isCharge ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(¥/h)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isCharge ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <ChargeAmount
              detailsData={detailsProcess}
              chajiDetails={jikanCharge}
              processName={processType}
              processMethod={processMethod}
              editMode={editMode}
              updatedAmount={updatedChargeAmount}
              updatedCharge={updatedCharge}
              iq3SetupAmount={iq3SetupAmount}
              updatediq3SetupAmount={updateSetupAmount}
              iq3WorkAmount={iq3WorkAmount}
              updatediq3WorkAmount={updateWorkAmount}
              iq3ProcessInspection={iq3ProcessInspection} //工程内検査
              updatediq3ProcessInspection={updateiq3ProcessInspection}
              secondProcessAmount={secondProcessAmount} //２次加工
              updatedsecondProcessAmount={updatesecondProcessAmount}
              jointBarashiAmount={jointBarashiAmount}
              updatedjointBarashiAmount={updatejointBarashiAmount}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 段取設定
  const dantoriSettingContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={setupSetting}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取設定</label>
            {isSetupSetting ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isSetupSetting ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            {processName === 'プログラム' ||
            processName === 'ブランク' ||
            processName === 'ベンディング' ||
            processName === 'バリ取' ||
            processName === '2次加工' ||
            processName === 'シャーリング' ? (
              <>
                {' '}
                <Device_SetupSetting
                  processName={processType}
                  processMethod={processMethod}
                  editMode={editMode}
                  mageSuuData={iq3MageSuuMaster}
                  updatedMagesuuData={updateMagesuuData}
                  mageSuuLst={iq3MageSuuListData}
                  updatedMagesuuList={updateMagesuuList}
                  // 要素数
                  yousosuuMasterData={yousosuuData}
                  updatedYousosuuMasterData={updateYousosuu}
                  shokidankaiYousosuuList={shokiDankaiJikanList}
                  updatedYousosuuList={updateshokiDankaiJikanListData}
                  // 要素数により、作業係数のリスト更新
                  sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
                  updatedSagyouYousosuuList={updateYousosuuListData}
                  // 曲げ個数
                  mageKosuu={iq3MageKosuuMaster}
                  updatedMageKosuu={updateMageKosuu}
                  mageKosuuList={iq3MageKosuuListData}
                  updatedMageKosuuList={updateMageKosuList}
                  // 重量と曲げ長
                  length={length}
                  updatedLength={updateBendingTimeLength}
                  weight={weight}
                  updatedWeight={updateProductSizeWeight}
                  dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
                  updatedDantoriJuuryouMagechouList={updateDantoriJuuryoMagechouListData}
                  magechouDantoriJikanList={magechouDantoriJikanListData}
                  updatedmagechouDantoriJikanListData={updateMagechouDantoriJikanList}
                  // 二次加工
                  detailsData={detailsProcess}
                  updatedDantoriSetting={updatedDantori} //段取
                  // 生産個数
                  kosuu={seisanKosuu}
                  updatedKosuu={updateSeisanKosuu}
                  kosuuList={seisanKosuuList}
                  updatedKosuuList={updateiq3SeisanKosuuList}
                  processInspectionKosuuListosuuList={processInspectionKosuuList}
                  updatedProcessInspectionKosuuList={updateProcessInspectionKosuuList}
                  // 本数
                  honSuu={honSuu}
                  updateHonsuu={updateHonsuu}
                  honSuuList={honSuuList}
                  updateHonsuuList={updateHonsuuList}
                  // 基本段取時間
                  dantoriTime={kihonDantori}
                  updatedDantoriTime={updateDantoriTime}
                  // 段取を個別に持つ
                  dantoriFlag={dantoriFlag}
                  updateDantoriFlag={updateDantoriFlag}
                  area={iq3AreaMaster}
                  updatedArea={updateBendingTimeArea}
                  // 重量と面積
                  correctionTimeFactorListData={correctionTimeFactorListData}
                  updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
                  // マテリアルハンドリング時間
                  materialHandlingTimeList={materialHandlingTimeList}
                  updateMaterialHandlingList={updateMaterialHandlingList}
                  // 製品重量係数
                  iq3SeihinSizeLst={iq3ProductSizeListData}
                  updatediq3ProductSizesList={updateIQ3ProductSizeListData}
                />
              </>
            ) : (
              <>
                {' '}
                <SetupSetting
                  editMode={editMode}
                  processName={processType}
                  detailsData={detailsProcess}
                  updatedDantoriSetting={updatedDantori} //段取　秒更新データ
                  kihonDantoriTime={kihonDantori} //基本段取時間
                  updatedKihonDantoriTime={updatedTime}
                  suuryouData={suuryouData} //数量Master
                  updatedSuuryouData={updateSuuryouData}
                  dantoriSetteiSuuryouList={dantoriSetteiSuuryouList}
                  updateddantoriSetteiSuuryouList={updatedantoriSetteiSuuryouList}
                  // 子部品数量
                  kobuhinsuuryouList={suuryouList}
                  updatedSuuryouList={updateSuuryouListData}
                  // 重量と体積
                  weight={weight}
                  updatedWeight={updateDantoriProductSizeWeight}
                  volume={volume}
                  updatedVolume={updateVolume}
                  dantoriSizeList={dantoriSizeDetailsList}
                  updatedDantoriSizeList={updateDantoriSizeList}
                  sizeList={sizesDetailsList}
                  updatedSizesList={updateProductSizeList}
                />
              </>
            )}
            {/* {types === '親部品' ? (
              <>
                <SetupSetting
                  editMode={editMode}
                  processName={processType}
                  detailsData={detailsProcess}
                  updatedDantoriSetting={updatedDantori} //段取　秒更新データ
                  kihonDantoriTime={kihonDantori} //基本段取時間
                  updatedKihonDantoriTime={updatedTime}
                  suuryouData={suuryouData} //数量Master
                  updatedSuuryouData={updateSuuryouData}
                  dantoriSetteiSuuryouList={dantoriSetteiSuuryouList}
                  updateddantoriSetteiSuuryouList={updatedantoriSetteiSuuryouList}
                  // 子部品数量
                  kobuhinsuuryouList={suuryouList}
                  updatedSuuryouList={updateSuuryouListData}
                  // 重量と体積
                  weight={weight}
                  updatedWeight={updateDantoriProductSizeWeight}
                  volume={volume}
                  updatedVolume={updateVolume}
                  dantoriSizeList={dantoriSizeDetailsList}
                  updatedDantoriSizeList={updateDantoriSizeList}
                />
              </>
            ) : (
              <>
                <Device_SetupSetting
                  processName={processType}
                  processMethod={processMethod}
                  editMode={editMode}
                  mageSuuData={iq3MageSuuMaster}
                  updatedMagesuuData={updateMagesuuData}
                  mageSuuLst={iq3MageSuuListData}
                  updatedMagesuuList={updateMagesuuList}
                  // 要素数
                  yousosuuMasterData={yousosuuData}
                  updatedYousosuuMasterData={updateYousosuu}
                  shokidankaiYousosuuList={shokiDankaiJikanList}
                  updatedYousosuuList={updateshokiDankaiJikanListData}
                  // 要素数により、作業係数のリスト更新
                  sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
                  updatedSagyouYousosuuList={updateYousosuuListData}
                  // 曲げ個数
                  mageKosuu={iq3MageKosuuMaster}
                  updatedMageKosuu={updateMageKosuu}
                  mageKosuuList={iq3MageKosuuListData}
                  updatedMageKosuuList={updateMageKosuList}
                  // 重量と曲げ長
                  length={length}
                  updatedLength={updateBendingTimeLength}
                  weight={weight}
                  updatedWeight={updateProductSizeWeight}
                  dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
                  updatedDantoriJuuryouMagechouList={updateDantoriJuuryoMagechouListData}
                  magechouDantoriJikanList={magechouDantoriJikanListData}
                  updatedmagechouDantoriJikanListData={updateMagechouDantoriJikanList}
                  // 二次加工
                  detailsData={detailsProcess}
                  updatedDantoriSetting={updatedDantori} //段取
                  // 生産個数
                  kosuu={seisanKosuu}
                  updatedKosuu={updateSeisanKosuu}
                  kosuuList={seisanKosuuList}
                  updatedKosuuList={updateiq3SeisanKosuuList}
                  processInspectionKosuuListosuuList={processInspectionKosuuList}
                  updatedProcessInspectionKosuuList={updateProcessInspectionKosuuList}
                  // 本数
                  honSuu={honSuu}
                  updateHonsuu={updateHonsuu}
                  honSuuList={honSuuList}
                  updateHonsuuList={updateHonsuuList}
                  // 基本段取時間
                  dantoriTime={kihonDantori}
                  updatedDantoriTime={updateDantoriTime}
                  // 段取を個別に持つ
                  dantoriFlag={dantoriFlag}
                  updateDantoriFlag={updateDantoriFlag}
                  area={iq3AreaMaster}
                  updatedArea={updateBendingTimeArea}
                  // 重量と面積
                  correctionTimeFactorListData={correctionTimeFactorListData}
                  updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
                  // マテリアルハンドリング時間
                  materialHandlingTimeList={materialHandlingTimeList}
                  updateMaterialHandlingList={updateMaterialHandlingList}
                  // 製品重量係数
                  iq3SeihinSizeLst={iq3ProductSizeListData}
                  updatediq3ProductSizesList={updateIQ3ProductSizeListData}
                />
              </>
            )} */}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 工程内検査
  const kouteiNaiKensaContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kouteiNaikensa}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>工程内検査</label>
            {isKouteiNaikensa ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isKouteiNaikensa ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_ProcessInspection
              processName={processType}
              editMode={editMode}
              // 生産個数
              kosuu={seisanKosuu}
              updatedKosuu={updateSeisanKosuu}
              kosuuList={processInspectionKosuuList}
              updatedKosuuList={updateProcessInspectionKosuuList}
              // 段取設定の生産個数
              dantorikosuuList={seisanKosuuList}
              updatedDantorisKosuuList={updateiq3SeisanKosuuList}
              // 基本段取時間
              inspectionTime={processInspectionTime}
              updatedDantoriTime={updateProcessInspectionTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // マテリアルハンドリング時間
  const materialHandlingContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={materialHandling}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>マテリアルハンドリング時間</label>
            {isMaterialHandling ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isMaterialHandling ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_MaterialHandlingTime
              processName={processType}
              editMode={editMode}
              mageSuuData={iq3MageSuuMaster}
              updatedMagesuuData={updateMagesuuData}
              mageSuuLst={iq3MageSuuListData}
              updatedMagesuuList={updateMagesuuList}
              // 重量と面積
              length={length}
              updatedLength={updateBendingTimeLength}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              area={iq3AreaMaster}
              updatedArea={updateBendingTimeArea}
              dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
              // 段取設定の曲げ係数
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 製品重量係数
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ加工時間
  const bendingTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={magekakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isMagekakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isMagekakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Bending
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              itaatsuLst={iq3BendingTimeListData}
              updatedItaatsuList={updateBendingTimeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 材料配置
  const materialArrangementContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={arrangeMaterial}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>材料配置</label>
            {isArrangeMaterial ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isArrangeMaterial ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_MaterialArrangement
              editMode={editMode}
              pierWidth1={pierWidth1}
              updatePierWidth1={updatePierWidth1}
              pierWidth2={pierWidth2}
              updatePierWidth2={updatePierWidth2}
              grabAllowance1={grabAllowance1}
              updateGrabAllowance1={updateGrabAllowance1}
              grabAllowance2={grabAllowance2}
              updateGrabAllowance2={updateGrabAllowance2}
              processRange1={processRange1}
              updateProcessRange1={updateProcessRange1}
              processRange2={processRange2}
              updateProcessRange2={updateProcessRange2}
              pickupTime={pickupTime}
              updatePickupTime={updatePickupTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // パンチ条件
  const punchConditionContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={punch}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>パンチ条件</label>
            {isPunch ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPunch ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_PunchCondition
              editMode={editMode}
              punchConditionList={punchConditionList}
              updateList={updatePunchList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 1ピアス加工時間
  const piercingProcessTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={piercingProcess}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>1ピアス加工時間</label>
            {isPiercingProcess ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒/箇所)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isPiercingProcess ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_PiercingProcessTime
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // ガス種類
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 1パンチ加工時間
  const punchProcessTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={punchProcess}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>1パンチ加工時間</label>
            {isPunchProcess ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPunchProcess ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_PunchProcessTime
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // ガス種類
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ時間係数
  const bendingTimeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={bendingTimeFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>曲げ時間係数</label>
            {isBendingTimeFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isBendingTimeFactor ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_BendingTimeFactor
              processName={processType}
              editMode={editMode}
              length={length}
              updatedLength={updateBendingTimeLength}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              // 曲げ時間係数リスト
              bendingTimeFactorLst={bendingCorrectionTimeFactorListData}
              updatedSizesList={updateBendingCorrectionTimeFactorListData}
              // 曲げ補正時間係数
              bendingCorrectionTimeFactorLst={bendingTimeFactorListData}
              updatedbendingCorrectionTimeFactorLst={updateBendingTimeFactorListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ補正時間
  const bendingCorrectionTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={mageHoseiJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {processName === '2次加工' || processName === 'バリ取' ? '補正時間' : '曲げ補正時間'}
            </label>
            {isMageHoseiJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isMageHoseiJikan ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_BendingCorrectionTime
              editMode={editMode}
              kosuu={kosuu}
              updatedKosuu={updateKosuu}
              kosuuList={iq3BendingKosuuList}
              updatedKosuuList={updateiq3BendingKosuuList}
              seisanKosuuList={kosuuList}
              updatedSeisanKosuuList={updateKosuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ補正時間係数
  const bendingCorrectionTimeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={mageHoseiJikanFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {processName === '2次加工' || processName === 'バリ取' ? '補正時間係数' : '曲げ補正時間係数'}
            </label>
            {isMageHoseiJikanFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isMageHoseiJikanFactor ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_BendingCorrectionTimeFactor
              processName={processType}
              editMode={editMode}
              area={iq3AreaMaster}
              updatedArea={updateBendingTimeArea}
              length={length}
              updatedLength={updateBendingTimeLength}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              // 曲げ補正リスト
              bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateBendingTimeFactorListData}
              // 補正リスト
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 製品重量係数
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
              listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 製品重量係数
  const productSizeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processSizeFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>製品重量係数</label>
            {isProcessSizeFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessSizeFactor ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_ProductSize
              editMode={editMode}
              area={iq3AreaMaster}
              updatedArea={updateBendingTimeArea}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              // 補正時間係数
              // bendingTimeFactorLst={bendingTimeFactorListData}
              // updatedSizesList={updateBendingTimeFactorListData}
              bendingTimeFactorLst={correctionTimeFactorListData}
              updatedSizesList={updatecorrectionTimeFactorListData}
              // 段取設定の曲げ係数
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 加工速度
  const machineSpeedContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processSpeed}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度</label>
            {isProcessSpeed ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm/分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessSpeed ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <MachineSpeed
              processName={processType}
              editMode={editMode}
              detailsWeldLine={detailsWeldLine}
              //　線長マスター更新
              updatedYousetsuSenchou={updatedYousetsuSenchouData}
              //溶接線長
              weldLineLst={yousetsuWeldLineList}
              updatedYousetsuSenchouList={updateYousetsuSenchouList}
              // 溶接仕上線長
              senchouList={kakouSokudoSenchouList}
              updatedSenchouList={updateKakousokudoSenchouList}
              // detailsSenchou={detailsWeldLine}
              // updatedItaatsu={upadateKaritsukiItaatsuData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業速度
  const sagyouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={sagyouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isSagyouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isSagyouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Row style={{ marginLeft: 10 }} className="chargeAmount">
              <Col style={{ width: '30%' }}>
                <RTable>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header">作業時間取（秒/要素）</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Row style={{ marginLeft: 5 }} align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{sagyouTime}</label>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={10}>
                                <Input
                                  value={sagyouTime}
                                  onChange={(e) => {
                                    setSagyouTime(e.target.value);
                                  }}
                                ></Input>
                              </Col>
                              <Col span={14}>
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    marginLeft: 10,
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(sagyouTime, '10min');
                                    setSagyouTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(sagyouTime, '10min');
                                    setSagyouTime(editedTime);
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(sagyouTime, 'min');
                                    setSagyouTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(sagyouTime, 'min');
                                    setSagyouTime(editedTime);
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(sagyouTime, 'sec');
                                    setSagyouTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(sagyouTime, 'sec');
                                    setSagyouTime(editedTime);
                                  }}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業係数など
  const sagyouKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={sagyouKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業係数など</label>
            {isSagyouKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSagyouKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Sagyoukeisuu
              editMode={editMode}
              processName={processType}
              yousosuuMasterData={yousosuuData}
              updatedYousosuuMasterData={updateYousosuu}
              sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
              updatedYousosuuList={updateYousosuuListData}
              // 要素数により、リスト更新
              shokidankaiYousosuuList={shokiDankaiJikanList}
              updatedShokidankaiYousosuuList={updateshokiDankaiJikanListData}
              // 同一サイズ、同一形状をパターン化する
              programSameSizePatternValue={programSameSizePatternValue}
              updatedProgramSameSizePatternValue={updateProgramSameSizePatternValue}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3段取時間
  const iq3DantoriJikanContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3DantoriJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取時間</label>
            {isiq3DantoriJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3DantoriJikan ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_DantoriJikan
              editMode={editMode}
              kosuu={seisanKosuu}
              updatedKosuu={updateSeisanKosuu}
              kosuuList={seisanKosuuList}
              updatedKosuuList={updateiq3KosuuList}
              // 基本段取時間
              dantoriTime={kihonDantori}
              updatedDantoriTime={updateDantoriTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3加工時間(２次加工)
  const iq3KakouJikanContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3KakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isiq3KakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3KakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_KakouJikan
              editMode={editMode}
              processName={processType}
              area={iq3AreaMaster}
              updatedArea={updateIQ3AreaMaster}
              listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
              // 二次加工
              iq3SizeMaster={iq3SizeMaster}
              updatediq3SizeMaster={updatediq3SizeMaster}
              iq3KaniListData={iq3KaniListData}
              updatediq3KaniListData={updatediq3KaniListData}
              itaatsu1={itaatsu1}
              updatedItaatsu={updateItaatsu1}
              zaishitsu={zaishitsu}
              shirringflag={shirringflag}
              updateShirringFlag={updateShirringFlag}
              updateCutTime={updateCutTime}
              cutTime={cutTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク加工速度
  const BlankKakouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankKakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度</label>
            {isBlankKakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm/分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankKakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_Kakousokudo
              editMode={editMode}
              processName={processType}
              itaatsu={iq3ItaatsuMaster}
              updatedItaatsu={updateIQ3Itaatsu}
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // ガス種類
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              okuriSokudoVal={okuriSokudoVal}
              updateOkuriSokudoVal={updateOkuriSokudoVal}
              kegakiSokudoVal={kegakiSokudoVal}
              updateKegakiSokudoVal={updateKegakiSokudoVal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク加工速度係数
  const BlankKakouSokudoKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankKakouSokudoKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度係数</label>
            {isBlankKakouSokudoKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankKakouSokudoKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_KakouSokudoKeisuu
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // ガス種類
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクガス係数
  const BlankGasuKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankGasuKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ガス係数</label>
            {isBlankGasuKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankGasuKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_GasKeisuu
              editMode={editMode}
              gasTypeData={gasTypeMaster}
              updatedGasType={updateGasType}
              gasKeisuuList={gasKeisuuList}
              updateGasKeisuuList={updateGasKeisuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク外形追抜条件
  const extractConditionContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={extractCondition}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>外形追抜条件</label>
            {isExtractCondition ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isExtractCondition ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_ExtractCondition
              editMode={editMode}
              retainedMold={retainedMoldMaster}
              updatedRetainedMold={updatedRetainedMold}
              extractConditionList={extractConditionList}
              updateExtractConditionList={updateExtractConditionList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクガス種類
  const BlankGasuTypeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankGasuType}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ガス種類</label>
            {isBlankGasuType ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankGasuType ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_GasType
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク2工程穴加工時間
  const BlankDrillingTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankDrillingTime}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>2工程穴加工時間</label>
            {isBlankDrillingTime ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒/箇所)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankDrillingTime ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_DrillingTime
              editMode={editMode}
              drillingTime={drillingTime}
              updateDrillingTime={updateDrillingTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク送り速度
  const okuriSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={okuriSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>送り速度</label>
            {isOkuriSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm/分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isOkuriSokudo ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_OkuriSokudo
              editMode={editMode}
              forwardSpeed={forwardSpeed}
              updateForwardSpeed={updateForwardSpeed}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ローラー/ホイール/ケガキ金型　加工速度
  const rollerKakouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={rollerKakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ローラー/ホイール/ケガキ金型　加工速度</label>
            {isRollerKakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm/分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isRollerKakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_RollerSpeed
              editMode={editMode}
              rollerSpeed={rollerSpeed}
              updateRollerSpeed={updateRollerSpeed}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクジョイント
  const BlankJointContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankJoint}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ジョイント</label>
            {isBlankJoint ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankJoint ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_Blank_Joint
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // ガス種類
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // サイズc
              jointSizeMaster={jointSizeMaster}
              updateJointSizeMaster={updateJointSizeMaster}
              jointSizeList={jointSizeList}
              updateJointSizeList={updateJointSizeList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3加工速度
  const iq3KakousokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3KakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度</label>
            {isiq3KakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm/分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3KakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_KakouSokudo
              editMode={editMode}
              naikei_bari={naikei_bari}
              updateNaikeibari={updateNaikeibari}
              kakosokudo_bari={kakosokudo_bari}
              updateKakousokudobari={updateKakousokudobari}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3手動バリ取対象サイズ
  const iq3ManualBariContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3ManualBari}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>手動バリ取対象サイズ</label>
            {isiq3ManualBari ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3ManualBari ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Device_ManualBari
              editMode={editMode}
              naikei_min={naikei_min}
              updateNaikeiMin={updateNaikeiMin}
              naikei_max={naikei_max}
              updateNaikeiMax={updateNaikeiMax}
              ana_max={ana_max}
              updateAnaMax={updateAnaMax}
              ana_min={ana_min}
              updateAnaMin={updateAnaMin}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 仮付時間
  const karitsukiTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={attach}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>仮付時間</label>
            {isAttach ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isAttach ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <KaritsukiTime
              editMode={editMode}
              detailsItaatsu={karitsukiTimeItaatsuMasterData}
              updatedItaatsu={upadateKaritsukiItaatsuData}
              listData={karitsukiTimeItaatsuList}
              updatedListData={updateKaritsukiTimeList}
              // 板厚データにより、取付き時間の板厚リスト更新
              itaatsuList={toritsukiTimeItaatsuList}
              updatedItaatsuList={updateToritsukiItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 取付＿板厚
  const toritsukiTimeItaatsuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={toritsukiJikanItaatsu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>取付時間(板厚判断)</label>
            {isToritsukiJikanItaatsu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isToritsukiJikanItaatsu ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <ToritsukiJikan_Itaatsu
              editMode={editMode}
              itaatsuList={toritsukiTimeItaatsuList}
              updatedItaatsuList={updateToritsukiItaatsuList}
              detailsItaatsu={karitsukiTimeItaatsuMasterData}
              updatedItaatsu={upadateKaritsukiItaatsuData}
              // 板厚データにより、仮付時間の板厚リスト更新
              listData={karitsukiTimeItaatsuList}
              updatedListData={updateKaritsukiTimeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 取付＿サイズ
  const toritsukiTimeSizeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={toritsukiJikanSize}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>取付時間(サイズ判断)</label>
            {isToritsukiJikanSize ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(秒)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isToritsukiJikanSize ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <ToritsukiJikan_Size
              editMode={editMode}
              detailsSizes={toritsukiTimeSizeMasterData}
              updatedSize={updateToritsukiSizeData}
              sizeList={toritsukiTimeSizeList}
              updatedSizeList={updateSizeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 溶接難易度
  const yousetsuDifficultyContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>溶接難易度</label>
            {isWelding ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isWelding ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <YousetsuDifficulty
              editMode={editMode}
              detailsItaatsu={yousetsuNanidoItaatsuMasterData}
              updatedItaatsu={updateYousetsuNaniItaatsu}
              listData={yousetsuNanidoItaatsuList}
              updatedListData={updatedLists}
              // 溶接難易度の板厚より、タップ溶接のリスト更新
              itaatsuList={tapWeldItaatsuList}
              updatedItaatsuList={updateTapWeldItaatsuLst}
              // 溶接難易度の板厚より、点付、仮付ピッチのリスト更新
              pitchItaatsuList={pitchItaatsuList}
              updatedPitchItaatsuList={updateItaatsuLst}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // タップ溶接
  const tappuYousetsuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={tapWelding}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>タップ溶接</label>
            {isTapWelding ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(%/辺)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isTapWelding ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <TapWelding
              editMode={editMode}
              detailsItaatsu={yousetsuNanidoItaatsuMasterData}
              updatedItaatsu={updateYousetsuNaniItaatsu}
              itaatsuList={tapWeldItaatsuList}
              updatedItaatsuList={updateTapWeldItaatsuLst}
              // 溶接難易度の板厚より、点付、仮付ピッチのリスト更新
              pitchItaatsuList={pitchItaatsuList}
              updatedPitchItaatsuList={updateItaatsuLst}
              // 溶接難易度の板厚より、溶接難易度のリスト更新
              listData={yousetsuNanidoItaatsuList}
              updatedListData={updatedLists}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 点付、仮付ピッチ
  const pitchContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={pitch}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>点付、仮付ピッチ</label>
            {isPitch ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(mm/辺)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isPitch ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Pitch
              editMode={editMode}
              detailsItaatsu={yousetsuNanidoItaatsuMasterData}
              updatedItaatsu={updateYousetsuNaniItaatsu}
              itaatsuList={pitchItaatsuList}
              updatedItaatsuList={updateItaatsuLst}
              // 溶接難易度の板厚より、タップ溶接のリスト更新
              tapitaatsuList={tapWeldItaatsuList}
              updatedTapItaatsuList={updateTapWeldItaatsuLst}
              // 溶接難易度の板厚より、溶接難易度のリスト更新
              listData={yousetsuNanidoItaatsuList}
              updatedListData={updatedLists}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 製品重量係数
  const productSizeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={productSize}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>製品重量係数</label>
            {isProductSize ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProductSize ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <ProductSize
              editMode={editMode}
              areas={area}
              updatedVolume={updateProductSizeArea}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              sizeList={sizesDetailsList}
              updatedSizesList={updateProductSizeList}
              dantoriSizeList={dantoriSizeDetailsList}
              updatedDantoriSizeList={updateDantoriSizeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 生産個数係数
  const quantityContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={productQuantity}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>生産個数係数</label>
            {isProductQuantity ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProductQuantity ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <Quantity
              editMode={editMode}
              kosuu={kosuu}
              updatedKosuu={updateKosuu}
              kosuuList={kosuuList}
              updatedKosuuList={updateKosuuList}
              // ２次加工の補正時間
              jikankosuuList={iq3BendingKosuuList}
              updatedJikanKosuuList={updateiq3BendingKosuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業・加工時間
  const childPartContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={childPart}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isChildPart ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isChildPart ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            {/* <ChildPart
              editMode={editMode}
              processName={processType}
              suuryouDetails={suuryouData}
              updatedSuuryou={updateSuuryouDetails}
              suuryouList={suuryouList}
              updatedSuuryouList={updateSuuryouListData}
              kobuhinnData={koubuhinnKeisuu}
              updatedKobuhinnData={updatKobuhinnKeisuu}
              // 数量により、段取設定の数量詳細リスト更新
              dantoriSetteiSuuryouList={dantoriSetteiSuuryouList}
              updateddantoriSetteiSuuryouList={updatedantoriSetteiSuuryouList}
            /> */}
            <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
              <RTable style={{ width: '20%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="tbl-header">1部品当たりの作業時間</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center', height: 35 }}>
                    <td>
                      <Row style={{ textAlign: 'center' }} align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{processTimeByUnit}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input
                                value={processTimeByUnit}
                                onChange={(event) => changeTime(event.target.value)}
                              ></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(processTimeByUnit, '10min');

                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(processTimeByUnit, '10min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(processTimeByUnit, 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(processTimeByUnit, 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(processTimeByUnit, 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(processTimeByUnit, 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業精度
  const workAccuracyContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={workAccuracy}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業精度</label>
            {isWorkAccuracy ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isWorkAccuracy ? 'block' : 'none' }}>
        {selectedDeviceData.length != 0 ? (
          <>
            <WorkAccuracy
              editMode={editMode}
              meishouData={meishou}
              updatedName={updatename}
              meishouLst={meishouList}
              updatedMeishouLst={updateMeishouList}
            />
          </>
        ) : (
          <></>
        )}{' '}
      </div>
    </>
  );
  const content =
    processName === '溶接' ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {machineSpeedContent}
          {karitsukiTimeContent}
          {toritsukiTimeItaatsuContent}
          {toritsukiTimeSizeContent}
          {yousetsuDifficultyContent}
          {tappuYousetsuContent}
          {pitchContent}
          {productSizeContent}
          {quantityContent}
        </div>
      </>
    ) : processName === '溶接仕上' ? (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {machineSpeedContent}
        {productSizeContent}
        {quantityContent}
      </div>
    ) : processName === '表面処理' ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {productSizeContent}
          {types === '親部品' ? '' : materialHandlingContent}
          {quantityContent}
        </div>
      </>
    ) : processName === '組立' || processName === '検査' || processName === '梱包' ? (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {childPartContent}
        {productSizeContent}
        {/* {workAccuracyContent} */}
        {quantityContent}
      </div>
    ) : processName === 'プログラム' ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {sagyouSokudoContent}
          {sagyouKeisuuContent}
        </div>
      </>
    ) : processName === 'シャーリング' ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {iq3KakouJikanContent}
          {quantityContent}
        </div>
      </>
    ) : processName === 'ベンディング' ? (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {kouteiNaiKensaContent}
        {materialHandlingContent}
        {bendingTimeContent}
        {bendingTimeFactorContent}
        {bendingCorrectionTimeContent}
        {bendingCorrectionTimeFactorContent}
        {productSizeFactorContent}
        {quantityContent}
      </div>
    ) : processName === '2次加工' ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {iq3KakouJikanContent}
          {bendingCorrectionTimeContent}
          {bendingCorrectionTimeFactorContent}
          {productSizeFactorContent}
          {quantityContent}
        </div>
      </>
    ) : processName === 'バリ取' ? (
      <div>
        {baritoriTypesContent}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialHandlingContent}
        {processMethod === '自動' ? (
          <>
            {kouteiNaiKensaContent}
            {iq3KakouJikanContent}
          </>
        ) : (
          <>
            {iq3KakousokudoContent} {iq3ManualBariContent}
          </>
        )}
        {processMethod === '自動' ? (
          <>
            {bendingCorrectionTimeContent}
            {bendingCorrectionTimeFactorContent}
          </>
        ) : (
          <></>
        )}

        {productSizeFactorContent}
        {quantityContent}
      </div>
    ) : processName === 'ブランク' ? (
      <>
        {baritoriTypesContent}
        {chargeAmountContent}
        {dantoriSettingContent}
        {kouteiNaiKensaContent}
        {materialArrangementContent}
        {processMethod === 'パンチ' || processMethod === '複合機' ? <>{punchConditionContent}</> : <></>}
        {processMethod === '複合機' ? <>{punchProcessTimeContent}</> : <></>}
        {piercingProcessTimeContent}
        {processMethod === 'パンチ' ? <> {extractConditionContent}</> : <></>}
        {processMethod === 'レーザー' || processMethod === '複合機' ? (
          <>
            {BlankKakouSokudoContent}
            {BlankKakouSokudoKeisuuContent}
            {BlankGasuKeisuuContent}
            {BlankGasuTypeContent}
            {BlankJointContent}
          </>
        ) : (
          <></>
        )}
        {BlankDrillingTimeContent}

        {processMethod === 'パンチ' ? (
          <>
            {' '}
            {okuriSokudoContent}
            {rollerKakouSokudoContent}
            {BlankJointContent}
          </>
        ) : (
          <></>
        )}
        {productSizeFactorContent}
        {quantityContent}
      </>
    ) : (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {machineSpeedContent}
        {karitsukiTimeContent}

        {toritsukiTimeItaatsuContent}
        {toritsukiTimeSizeContent}
        {yousetsuDifficultyContent}
        {tappuYousetsuContent}
        {pitchContent}
        {productSizeContent}
        {quantityContent}
      </div>
    );

  const searchCustomer = () => {
    setCustomerModal(true);
  };
  const getCustomerData = (selectedCustomerData) => {
    setCustomerCode(selectedCustomerData.code);
    setCustomerName(selectedCustomerData.name);
    setCustomerModal(false);
  };
  const addCustomerData = () => {
    console.log('Add');
  };
  const addCustomerModalTitle = (
    <div
      style={{
        width: 900,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>取引先</p>
    </div>
  );
  const closeCustomerModal = () => {
    setCustomerModal(false);
  };
  const onCustomerRowSelect = (record) => {
    setSelectedCustomerRowKeys(record.key);
    setSelectedCustomerData(record);
  };
  const addCustomerContent = (
    <>
      <Row>
        <AntTable
          className="tblCustomer"
          columns={result}
          dataSource={initialCustomerData}
          rowClassName={(record) => (record.key == selectedCustomerRowKeys ? 'active-row' : 'data-row')}
          scroll={{ y: 590, x: '10vw' }}
          pagination={{
            position: ['bottomLeft'],
            total: (record) => record.length,
            showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
            defaultPageSize: 25, // 本番値確認必要
            defaultCurrent: 1,
          }}
          onRow={(record, index) => {
            return {
              onClick: (event) => {
                onCustomerRowSelect(record);
              },
            };
          }}
        ></AntTable>
      </Row>
      <Row justify="end" style={{ marginTop: -30 }}>
        <Button className="mainButton" onClick={getCustomerData}>
          OK
        </Button>
      </Row>
    </>
  );
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCheckboxChecked(isChecked);
    setSelectDisabled(!isChecked);
  };
  const flagChange = (e) => {
    const isChecked = e.target.checked;
    setFlagChecked(isChecked);
    setInputDisabled(!isChecked);
  };
  const changeAmount = (name, e) => {
    const regex = /^[0-9,¥]+$/;
    if (regex.test(e)) {
      if (e.startsWith('¥', 0)) {
        e = e.replace('¥', '');
      }
      if (e.indexOf(',') > 0) {
        e = e.replace(/,/g, '');
      }
    } else {
      e = 0;
    }
    if (name === 'dantori') {
      setSetupAmount(e);
    } else if (name === 'kakou') {
      setWorkAmount(e);
    } else {
      setIniAmount(e);
    }
  };
  const closeAll = () => {
    props.updatedFlag(false);
    setIsCharge(false);
    setIsSetupSetting(false);
    setIsProcessSpeed(false);
    setIsAttach(false);
    setIsToritsukiJikanItaatsu(false);
    setIsToritsukiJikanSize(false);
    setIsWorkAccuracy(false);
    setIsTapWelding(false);
    setIsPitch(false);
    setIsProductSize(false);
    setIsProductQuantity(false);
    setIsChildPart(false);
    setIsWorkAccuracy(false);
    setIsDantoriJikan(false);
    setIsDeviceDetails(false);
    setIsProcessTime(false);
    setIsWelding(false);
    setIsSagyouSokudo(false);
    setIsSagyouKeisuu(false);
    setIsiq3DantoriJikan(false);
    setIsiq3KakouJikan(false);
    setIsBendingTimeFactor(false);
    setIsMagekakouJikan(false);
    setIsMageHoseiJikanFactor(false);
    setIsMageHoseiJikan(false);
    setIsBaritoriShurui(false);
    setIsiq3KakouSokudo(false);
    setIsiq3ManualBari(false);
    setIsProcessSizeFactor(false);
    setIsKouteiNaikensa(false);
    setIsMaterialHandling(false);
    setIsArrangeMaterial(false);
    setIsPiercingProcess(false);
    setIsBlankKakouSokudo(false);
    setIsBlankKakouSokudoKeisuu(false);
    setIsBlankGasuKeisuu(false);
    setIsBlankGasuType(false);
    setIsBlankJoint(false);
    setIsBlankDrillingTime(false);
    setIsPunch(false);
    setIsOkuriSokudo(false);
    setIsRollerKakouSokudo(false);
    setIsExtractCondition(false);
    setIsPunchProcess(false);
  };
  const openAll = () => {
    props.updatedFlag(true);
    setIsCharge(true);
    setIsSetupSetting(true);
    setIsProcessSpeed(true);
    setIsAttach(true);
    setIsToritsukiJikanItaatsu(true);
    setIsToritsukiJikanSize(true);
    setIsWorkAccuracy(true);
    setIsTapWelding(true);
    setIsPitch(true);
    setIsProductSize(true);
    setIsProductQuantity(true);
    setIsChildPart(true);
    setIsWorkAccuracy(true);
    setIsDantoriJikan(true);
    setIsDeviceDetails(true);
    setIsProcessTime(true);
    setIsWelding(true);
    setIsSagyouSokudo(true);
    setIsSagyouKeisuu(true);
    setIsiq3DantoriJikan(true);
    setIsiq3KakouJikan(true);
    setIsBendingTimeFactor(true);
    setIsMagekakouJikan(true);
    setIsMageHoseiJikanFactor(true);
    setIsMageHoseiJikan(true);
    setIsBaritoriShurui(true);
    setIsiq3KakouSokudo(true);
    setIsiq3ManualBari(true);
    setIsProcessSizeFactor(true);
    setIsKouteiNaikensa(true);
    setIsMaterialHandling(true);
    setIsArrangeMaterial(true);
    setIsPiercingProcess(true);
    setIsBlankKakouSokudo(true);
    setIsBlankKakouSokudoKeisuu(true);
    setIsBlankGasuKeisuu(true);
    setIsBlankGasuType(true);
    setIsBlankJoint(true);
    setIsBlankDrillingTime(true);
    setIsPunch(true);
    setIsOkuriSokudo(true);
    setIsRollerKakouSokudo(true);
    setIsExtractCondition(true);
    setIsPunchProcess(true);
  };
  return (
    <>
      <Form>
        <div className="d-flex" style={{ marginTop: 7 }}>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="mainButton"
              id="update"
              onClick={updateData}
            >
              更新
            </Button>
          </div>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="cancelButton"
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button
              style={{
                marginRight: 10,
                display: 'none',
                display: editMode ? 'none' : 'block',
                pointerEvents: selectedDeviceData.length != 0 ? 'auto' : 'none',
              }}
              className="mainButton"
              id="edit"
              onClick={editData}
            >
              編集
            </Button>
          </div>
        </div>
        <div className="overflow-auto kouteical timecharge registerModal" style={{ height: '77.1vh', marginTop: 22 }}>
          <div className="upperDeviceTbl">
            <Row className=" chargeAmount ">
              <Col style={{ width: '70%' }}>
                <RTable>
                  <thead className="table-light">
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '10%' }}>コード</th>
                      <th style={{ width: '10%' }}>工程名</th>
                      <th style={{ width: '10%' }}>装置コード</th>
                      <th style={{ width: '20%' }}>装置名</th>
                      <th style={{ width: '20%' }}>備考</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td style={{ verticalAlign: 'middle' }}>{code}</td>
                      <td style={{ verticalAlign: 'middle' }}>{processName}</td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          name="deviceCode"
                          id="deviceCode"
                          value={deviceCode}
                          type="text"
                          onChange={(e) => {
                            setDeviceCode(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          name="deviceName"
                          id="deviceName"
                          value={deviceName}
                          type="text"
                          onChange={(e) => {
                            setDeviceName(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          value={remarks}
                          type="text"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Space>
                  <DownSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={openAll} />
                  <UpSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={closeAll} />
                </Space>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              {deviceType == '標準' ? (
                <>{content}</>
              ) : (
                <>
                  <Row style={{ marginLeft: 10 }} className="chargeAmount">
                    <Col style={{ width: '20%' }}>
                      <RTable>
                        <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                          <tr style={{ textAlign: 'center' }}>
                            <th className="tbl-header">追加工程種類</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ textAlign: 'center' }}>
                            <td>
                              <Select
                                style={{ width: '100%', pointerEvents: editMode ? 'auto' : 'none' }}
                                value={kouteiType}
                                onChange={(e) => {
                                  setKouteiType(e);
                                }}
                              >
                                {detailsProcess.map((item) => (
                                  <>
                                    {item.processDetailValue
                                      .filter((subitem) => subitem.displayFlg === true)
                                      .map((subItem) => (
                                        <option value={subItem.detailType}>{subItem.detailType}</option>
                                      ))}
                                  </>
                                ))}
                              </Select>
                            </td>
                          </tr>
                        </tbody>
                      </RTable>
                    </Col>
                  </Row>
                  {/* チャージ金額 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={chargeAmount}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>チャージ金額</label>
                        {isCharge ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginLeft: 10 }}>(￥/h)</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isCharge ? 'block' : 'none' }}>
                    <Row style={{ marginLeft: 10 }} className="chargeAmount">
                      <Col style={{ width: '20%' }}>
                        <RTable>
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th colSpan={2} className="tbl-header">
                                時間チャージ金額
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ textAlign: 'center' }}>
                              <td>段取</td>
                              <td>加工</td>
                            </tr>
                            <tr>
                              <td>
                                <Input
                                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                  value={JPYs.format(setupAmount)}
                                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                                  onChange={(e) => changeAmount('dantori', e.target.value)}
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                  value={JPYs.format(workAmount)}
                                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                                  onChange={(e) => changeAmount('kakou', e.target.value)}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </RTable>
                      </Col>
                    </Row>
                  </div>
                  {/* 段取時間 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={dantoriJikan}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>段取時間</label>
                        {isDantoriJikan ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isDantoriJikan ? 'block' : 'none' }}>
                    <Row style={{ marginLeft: 10 }} className="chargeAmount">
                      <Col style={{ width: '20%' }}>
                        <RTable>
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th className="tbl-header">基本段取時間</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                              <td>
                                <Row align="middle">
                                  {!editMode ? (
                                    <>
                                      <Col span={24}>
                                        <label>{dantoriTime}</label>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col span={10}>
                                        <Input
                                          value={dantoriTime}
                                          onChange={(e) => {
                                            setDantoriTime(e.target.value);
                                          }}
                                        ></Input>
                                      </Col>
                                      <Col span={14}>
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 16 + 'px',
                                            marginLeft: 3,
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(dantoriTime, '10min');
                                            setDantoriTime(editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(dantoriTime, '10min');
                                            setDantoriTime(editedTime);
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(dantoriTime, 'min');
                                            setDantoriTime(editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(dantoriTime, 'min');
                                            setDantoriTime(editedTime);
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(dantoriTime, 'sec');
                                            setDantoriTime(editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(dantoriTime, 'sec');
                                            setDantoriTime(editedTime);
                                          }}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        </RTable>
                      </Col>
                    </Row>
                  </div>
                  {/* 加工時間 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processTime}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>加工時間</label>
                        {isProcessTime ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isProcessTime ? 'block' : 'none' }}>
                    <Row style={{ marginLeft: 10 }} className="chargeAmount">
                      <Col style={{ width: '20%' }}>
                        <RTable>
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th className="tbl-header">基本加工時間</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ textAlign: 'center' }}>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Row style={{ marginLeft: 5 }} align="middle">
                                  {!editMode ? (
                                    <>
                                      <Col span={24}>
                                        <label>{workTime}</label>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col span={10}>
                                        <Input
                                          value={workTime}
                                          onChange={(e) => {
                                            setWorkTime(e.target.value);
                                          }}
                                        ></Input>
                                      </Col>
                                      <Col span={14}>
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 16 + 'px',
                                            marginLeft: 3,
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(workTime, '10min');
                                            setWorkTime(editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(workTime, '10min');
                                            setWorkTime(editedTime);
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(workTime, 'min');
                                            setWorkTime(editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(workTime, 'min');
                                            setWorkTime(editedTime);
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(workTime, 'sec');
                                            setWorkTime(editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: editMode ? 'auto' : 'none',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(workTime, 'sec');
                                            setWorkTime(editedTime);
                                          }}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        </RTable>
                      </Col>
                    </Row>
                  </div>
                  {/* 委託装置詳細 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={deviceDetails}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>委託装置詳細</label>
                        {isDeviceDetails ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isDeviceDetails ? 'block' : 'none' }}>
                    <Row style={{ marginLeft: 10 }} className="chargeAmount">
                      <Col style={{ width: '70%' }}>
                        <RTable className="tsuikakoutei">
                          <thead>
                            <tr>
                              <td className="device-detail-tbl-tsuika">
                                <label className="detail-tbl-val-title-label">委託工程</label>
                              </td>
                              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                <Checkbox
                                  style={{ marginLeft: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                                  onChange={handleCheckboxChange}
                                  checked={checkboxChecked}
                                ></Checkbox>
                              </td>
                            </tr>
                            <tr className={selectDisabled ? 'souchi' : ''}>
                              <td className="device-detail-tbl-tsuika">
                                <label className="detail-tbl-val-title-label">取引先</label>
                              </td>
                              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                <Row style={{ marginLeft: 10 }}>
                                  <Col span={16}>{customerName}</Col>
                                  <Col span={7} style={{ textAlign: 'end' }}>
                                    <SearchOutlined
                                      style={{
                                        fontSize: '18px',
                                        pointerEvents: selectDisabled || !editMode ? 'none' : 'auto',
                                      }}
                                      onClick={searchCustomer}
                                    />
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                            <tr className={selectDisabled ? 'souchi' : ''}>
                              <td className="device-detail-tbl-tsuika">
                                <label className="detail-tbl-val-title-label">取引先コード</label>
                              </td>
                              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                <label style={{ marginLeft: 10 }}>{customerCode}</label>
                              </td>
                            </tr>
                            <tr>
                              <td className="device-detail-tbl-tsuika">
                                <label className="detail-tbl-val-title-label">手入力フラグ</label>
                              </td>
                              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                <Checkbox
                                  style={{ marginLeft: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                                  onChange={flagChange}
                                  checked={flagChecked}
                                ></Checkbox>
                              </td>
                            </tr>
                            <tr className={inputDisabled ? 'souchi' : ''}>
                              <td className="device-detail-tbl-tsuika">
                                <label className="detail-tbl-val-title-label">初期金額(手入力時)</label>
                              </td>
                              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                <Input
                                  value={JPYs.format(iniAmount)}
                                  onChange={(e) => changeAmount('iniAmount', e.target.value)}
                                  type="text"
                                  style={{
                                    padding: 5,
                                    marginLeft: 5,
                                    width: '97%',
                                    pointerEvents: inputDisabled || !editMode ? 'none' : 'auto',
                                  }}
                                  className="input-editable"
                                ></Input>
                              </td>
                            </tr>
                          </thead>
                        </RTable>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Form>
      {/** 更新の確認メッセージ */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/* 取引先検索 */}
      {/* {customerModal
        ? commonModal(
            customerModal,
            addCustomerModalTitle,
            null,
            null,
            900,
            addCustomerData,
            closeCustomerModal,
            addCustomerContent,
            null,
            { left: 500, top: 10 },
            false
          )
        : ''} */}
      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={getCustomerData}
          title={Customer}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default DevicesDetails;
