import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Row, Col, Input, Radio, Checkbox, Select, Table as AntTable, Modal, Button, Image, Space } from 'antd';
import { CloseOutlined, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';

const RadioGroup = Radio.Group;

const yousetsuTypes = [
  { id: 1, value: 'アルゴン', label: 'アルゴン' },
  { id: 2, value: '半自動', label: '半自動' },
  { id: 3, value: '溶接線01', label: '溶接線01' },
  { id: 4, value: '溶接線02', label: '溶接線02' },
  { id: 5, value: '溶接線03', label: '溶接線03' },
  { id: 6, value: 'YAGハンディ', label: 'YAGハンディ' },
  { id: 7, value: 'YAGロボット', label: 'YAGロボット' },
  { id: 8, value: 'ロウ付', label: 'ロウ付' },
  { id: 9, value: '開先取り', label: '開先取り' },
  { id: 10, value: 'Vカット', label: 'Vカット' },
];

const yousetsuType_tentsuke = [
  { id: 1, value: '点付', label: '点付' },
  { id: 2, value: 'プラグ溶接', label: 'プラグ溶接' },
  { id: 3, value: '定置スポット', label: '定置スポット' },
  { id: 4, value: 'テーブルスポット', label: 'テーブルスポット' },
  { id: 5, value: '手動スタッド', label: '手動スタッド' },
  { id: 6, value: '自動スタッド', label: '自動スタッド' },
  { id: 7, value: 'ナット溶接', label: 'ナット溶接' },
  { id: 8, value: 'ボルト溶接', label: 'ボルト溶接' },
];

let yousetsu1Datas = [];
yousetsu1Datas.push({
  key: 1,
  yousetsuType: 'アルゴン',
  senchou: 0,
  senchouKaisu: 0,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 0,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'off',
});
let yousetsu2Datas = [];
yousetsu2Datas.push({
  key: 1,
  otherYousetsuType: 'ナット溶接',
  otherSenchou: '',
  count: '',
  otherSenchouCnt: '',
  otherSenchouKaisu: '',
  otherSenchouYousetsushiage: 'off',
});
let initialYousetsu1 = [];
initialYousetsu1.push({
  key: 1,
  senchou: '',

  senchouKaisu: '',
  senchouYousetsushiage: 'on',

  karitsukeCnt: '',
  karitsukeKaisu: '',
  karitsukeYousetsushiage: 'on',
});
let initialYousetsu2 = [];
initialYousetsu2.push({
  key: 1,
  senchou: 100,

  senchouKaisu: 0,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 0,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'on',
});

const IQ3KouteiInput_Yousetsu = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  // add new
  const [addModal, setAddModal] = useState(false);
  const [yousetsu1DataLst, setYousetsu1DataLst] = useState([]);
  const [yousetsu2DataLst, setYousetsu2DataLst] = useState([]);

  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    var radioItem = document.getElementsByName('arugonRadio');
    radioItem.disabled = true;
    if (props.yousetsu1Datas.length == 0) {
      // 設定から初期データ表示
      setYousetsu1DataLst([]);
    } else {
      console.log('Yousetsu---', props.yousetsu1Datas);
      setYousetsu1DataLst(props.yousetsu1Datas);
    }
    if (props.yousetsu1Datas.length == 0) {
      // 設定から初期データ表示
      setYousetsu2DataLst([]);
    } else {
      setYousetsu2DataLst(yousetsu2Datas);
    }
  }, [props.yousetsu1Datas]);

  // const setEditModeChange = (mode) => {
  //   setEditMode(mode);
  // };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addNew = () => {
    setAddModal(true);
  };
  const addOK = () => {
    setAddModal(false);
  };
  const addCancel = () => {
    setAddModal(false);
  };

  const addYousetsu1 = (id) => {
    // if (yousetsu1DataLst.length > 0) {
    //   //
    //   const data = {
    //     key: yousetsu1DataLst.slice(-1)[0].key + 1,
    //     yousetsuType: 'アルゴン',
    //     senchou: 0.0,

    //     senchouKaisu: 0,
    //     senchouYousetsushiage: 'on',

    //     karitsukeCnt: 0,
    //     karitsukeKaisu: 0,
    //     karitsukeYousetsushiage: 'on',
    //     total1: 0,
    //     total2: 0,
    //   };
    //   let addArr = [...yousetsu1DataLst, data];
    //   setYousetsu1DataLst(addArr);
    // }
    const copyData = yousetsu1DataLst.slice(); // Create a copy of the original array

    const insertIndex = yousetsu1DataLst.findIndex((item) => item.key === id); // Find the clicked index of the item
    const largestId = yousetsu1DataLst.reduce((maxId, item) => {
      return item.key > maxId ? item.key : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        key: largestId + 1,
        yousetsuType: 'アルゴン',
        senchou: '',

        senchouKaisu: '',
        senchouYousetsushiage: 'on',

        karitsukeCnt: '',
        karitsukeKaisu: '',
        karitsukeYousetsushiage: 'on',
        total1: '',
        total2: '',
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setYousetsu1DataLst(copyData);
    }
  };
  const deleteYousetsu1 = (e, index) => {
    if (yousetsu1DataLst.length > 1) {
      setYousetsu1DataLst(
        yousetsu1DataLst.filter((j) => {
          return ![e].includes(j.key);
        })
      );
      // const dataRow = [...yousetsu1DataLst];
      // dataRow.splice(index, 1);
      // setYousetsu1DataLst(dataRow);
    }
  };
  const addYousetsu2 = (id) => {
    //
    // const data = {
    //   key: yousetsu2DataLst.slice(-1)[0].key + 1,
    //   otherYousetsuType: '点付',
    //   otherSenchou: 0,
    //   otherSenchouCnt: 0,
    //   otherSenchouKaisu: 0,
    //   otherSenchouYousetsushiage: 'on',
    // };
    // let addArr = [...yousetsu2DataLst, data];
    // setYousetsu2DataLst(addArr);
    const copyData = yousetsu2DataLst.slice(); // Create a copy of the original array

    const insertIndex = yousetsu2DataLst.findIndex((item) => item.key === id); // Find the clicked index of the item
    const largestId = yousetsu2DataLst.reduce((maxId, item) => {
      return item.key > maxId ? item.key : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        key: largestId + 1,
        otherYousetsuType: '点付',
        otherSenchou: '',
        count: '',
        otherSenchouCnt: '',
        otherSenchouKaisu: '',
        otherSenchouYousetsushiage: 'on',
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setYousetsu2DataLst(copyData);
    }
  };
  const deleteYousetsu2 = (e, index) => {
    if (yousetsu2DataLst.length > 1) {
      setYousetsu2DataLst(
        yousetsu2DataLst.filter((j) => {
          return ![e].includes(j.key);
        })
      );
      // const dataRow = [...yousetsu2DataLst];
      // dataRow.splice(index, 1);
      // setYousetsu2DataLst(dataRow);
    }
  };
  const onYousetsu1ValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...yousetsu1DataLst];
    data[i][name] = value;
    setYousetsu1DataLst(data);
  };
  const onYousetsuRdoValUpdate = (id, e, rdoname) => {};
  const onYousetsu2ValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...yousetsu2DataLst];
    data[i][name] = value;

    setYousetsu2DataLst(data);
  };
  const onYousetsu1ValSelectUpdate = (i, event, name) => {
    if (name == 'yousetsuType') {
      const data = [...yousetsu1DataLst];
      data[i][name] = event;
      setYousetsu1DataLst(data);
    } else {
      const data = [...yousetsu2DataLst];
      data[i][name] = event;
      setYousetsu2DataLst(data);
    }
  };

  const firstYousetsuComponent = (
    <>
      {yousetsu1DataLst.map((item, index) => {
        const {
          key,
          yousetsuType,
          senchou,
          senchouKaisu,
          senchouYousetsushiage,
          karitsukeCnt,
          karitsukeKaisu,
          total1,
          total2,
          karitsukeYousetsushiage,
        } = item;
        return (
          <>
            <tr>
              <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {index + 1}
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                {editMode ? (
                  <>
                    <Select
                      name="yousetsuType"
                      className="yousetsu-select"
                      style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      // onChange={(e) => setArugonSel(e.target.value)}
                      onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'yousetsuType')}
                      // defaultValue={yousetsuType[0].value}
                      value={yousetsuType}
                    >
                      {yousetsuTypes.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>{yousetsuType}</>
                )}
              </td>

              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>線長</td>
              <td>
                <Input
                  name="senchou"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={senchou}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="senchouKaisu"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={senchouKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="senchouYousetsushiage"
                  defaultValue={'on'}
                  className={editMode ? 'radioCustomerGpEdit input-editable' : 'radioCustomerGpEdit input-non-editable'}
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                  // onChange={(event) => onYousetsuRdoValUpdate(index, event, 'senchouYousetsushiage')}
                  value={senchouYousetsushiage}
                >
                  <Radio value={'on'}>適用</Radio>
                  <Radio value={'off'}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ pointerEvents: 'none', textAlign: 'center' }}
                  value={total1}
                ></Input>
              </td>

              {editMode ? (
                <>
                  <td
                    style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                    className="min"
                    rowSpan={2}
                  >
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 10, marginRight: 10 }}
                        // onClick={addYousetsu1}
                        onClick={() => addYousetsu1(key)}
                      />
                      <CloseCircleFilled className="add-remove-icon" onClick={(event) => deleteYousetsu1(key, event)} />
                    </Row>
                  </td>
                </>
              ) : (
                <>
                  <td
                    rowSpan={2}
                    style={{ border: '1px', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                  ></td>
                </>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>仮付</td>
              <td>
                <Input
                  name="karitsukeCnt"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={karitsukeCnt}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="karitsukeKaisu"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={karitsukeKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="karitsukeYousetsushiage"
                  defaultValue={'on'}
                  className={editMode ? 'radioCustomerGpEdit input-editable' : 'radioCustomerGpEdit input-non-editable'}
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                  value={karitsukeYousetsushiage}
                >
                  <Radio value={'on'}>適用</Radio>
                  <Radio value={'off'}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ pointerEvents: 'none', textAlign: 'center' }}
                  value={total2}
                ></Input>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
  const secondYousetsuComponent = (
    <>
      {yousetsu2DataLst.map((item, index) => {
        const {
          otherYousetsuType,
          otherSenchou,
          count,
          otherSenchouCnt,
          otherSenchouKaisu,
          otherSenchouYousetsushiage,
        } = item;
        return (
          <>
            <tr>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
              <td style={{ verticalAlign: 'middle' }}>
                {editMode ? (
                  <>
                    <Select
                      name="otherYousetsuType"
                      className="yousetsu-select"
                      // onChange={(e) => setShudousutaddoSel(e.target.value)}
                      onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'otherYousetsuType')}
                      // style={{ fontSize: '13px' }}
                      // defaultValue={yousetsuType_tentsuke[0].value}
                      value={otherYousetsuType}
                    >
                      {yousetsuType_tentsuke.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>{otherYousetsuType}</>
                )}
              </td>

              <td>
                <Input
                  name="otherSenchou"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={otherSenchou}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="count"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={count}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="otherSenchouKaisu"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={otherSenchouKaisu}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="otherSenchouYousetsushiage"
                  defaultValue={'on'}
                  className={editMode ? 'radioCustomerGpEdit input-editable' : 'radioCustomerGpEdit input-non-editable'}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                  value={otherSenchouYousetsushiage}
                >
                  <Radio value={'on'}>適用</Radio>
                  <Radio value={'off'}>除外</Radio>
                </RadioGroup>
              </td>
              <td>
                <Input
                  name="otherSenchouCnt"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  value={otherSenchouCnt}
                ></Input>
              </td>
              {editMode ? (
                <>
                  <td
                    style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                    className="min"
                  >
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 10, marginRight: 10 }}
                        // onClick={addYousetsu2}
                        onClick={() => addYousetsu2(item.key)}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(event) => deleteYousetsu2(item.key, event)}
                      />
                    </Row>
                  </td>
                </>
              ) : (
                <>
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}></td>
                </>
              )}
            </tr>
          </>
        );
      })}
    </>
  );

  return (
    <>
      {/* 溶接 */}

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput " style={{ width: '83%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '4%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                溶接種類
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                種類/形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                詳細
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                溶接仕上
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>{yousetsu1DataLst.length === 0 ? <></> : <>{firstYousetsuComponent}</>} </tbody>
        </Table>
      </Row>

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '83%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '4%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                サプ溶接種類
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                径(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                溶接仕上
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>{yousetsu2DataLst.length === 0 ? <></> : <>{secondYousetsuComponent}</>} </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Yousetsu;
