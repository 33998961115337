/**
 * クラス名：材料名称詳細画面
 * 説明：iQ3板金に使用の材料名称詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Select, InputNumber, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import checkedImg from '../../../../assets/images/checked.png';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import { JPYs, getZaishittsuNameDDL } from '../../../common/Common.js';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialNameDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
  }));

  const editMode = props.editMode;

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [prevUnitPrice, setPrevUnitPrice] = useState();
  const [updDateFlg, setUpdDateFlg] = useState(false);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData).length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(props.selectedData);
      setSelectedRowData(selectedData);
      /* setUpdDate(selectedData.modified); */
      setPrevUnitPrice(selectedData.pricePerWeight);
    } else {
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const editDetailInput = (objKey, value) => {
    if (objKey === 'pricePerWeight') {
      if (value.startsWith('¥', 0)) {
        value = value.replace('¥', '');
      }
      if (value.indexOf(',') > 0) {
        value = value.replace(/,/g, '');
      }
    }
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);

    if (objKey === 'pricePerWeight') {
      value = parseFloat(value);
    }
    setPrevUnitPrice(parseFloat(selectedRowData.pricePerWeight));
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[objKey] = value;
      return updatedData;
    });
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    setUpdateConfirm(true);
    setIsUpdate(true);
  };

  const discardData = () => {
    setCancelConfirm(true);
  };

  const updConfirmOk = (e) => {
    let updateData = [];
    if (isUpdate) {
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      updateData = { ...selectedRowData };
      if (parseFloat(selectedRowData.pricePerWeight) !== parseFloat(prevUnitPrice)) {
        updateData = addNewHistory(updateData);
      }
      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
    setUpdateConfirm(false);
  };

  const addNewHistory = (updatedData) => {
    let unitPriceHistory = JSON.parse(JSON.stringify(updatedData.updHistory));
    unitPriceHistory.push({
      updId: unitPriceHistory.length,
      updateDate: dayjs(dayjs()).format('YYYY-MM-DD'),
      updPrice: parseFloat(selectedRowData.pricePerWeight),
    });
    updatedData.updHistory = unitPriceHistory;
    return updatedData;
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData(originData);
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className="mainButton" id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">No</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{props.itemNo}</label>
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="displayFlag"
                      //defaultValue={selectedRowData.displayFlag}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('displayFlag', e.target.value);
                      }}
                      value={selectedRowData.displayFlag}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData.displayFlag && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
              {/* 材料名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材料名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="detailMaterialName"
                    id="detailMaterialName"
                    value={selectedRowData.detailMaterialName}
                    type="text"
                    onChange={(e) => editDetailInput('detailMaterialName', e.target.value)}
                  />
                </td>
              </tr>

              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      style={{ marginLeft: 5, width: '98.2%' }}
                      onChange={(e) => {
                        editDetailInput('materialName', e);
                      }}
                      placeholder="材質区分名"
                      id="materialRefDDL"
                      name="materialRefDDL"
                      value={selectedRowData.materialName}
                      defaultValue={'全て'}
                    >
                      <Option id="allOption" value={'全て'}>
                        {'材質名称を選択してください。'}
                      </Option>
                      {getZaishittsuNameDDL().map((item) => (
                        <option id={'materialName' + item.value} value={item.label}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <label className="param_detail-title-label">{selectedRowData.materialName}</label>
                  )}
                </td>
              </tr>
              {/* 板厚 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">板厚</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <InputNumber
                    className={editMode ? 'input-editable inputNumber' : 'input-non-editable inputNumber'}
                    name="thickness"
                    id="thickness"
                    style={{ marginLeft: 5, paddingLeft: 0, width: '98.2%', height: '30px' }}
                    value={selectedRowData.thickness}
                    controls={false}
                    type="text"
                    onChange={(e) => editDetailInput('thickness', e)}
                  />
                </td>
              </tr>

              {/* 材料ロス係数 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">重量単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="pricePerWeight"
                    id="pricePerWeight"
                    value={JPYs.format(selectedRowData.pricePerWeight)}
                    type="text"
                    onChange={(e) => editDetailInput('pricePerWeight', e.target.value)}
                  />
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>{selectedRowData.created}</label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>{selectedRowData.modified}</label>
                </td>
              </tr>

              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    type="text"
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    data-name="name"
                    name="remark"
                    id="remark"
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    value={selectedRowData.remark}
                    onChange={(e) => editDetailInput('remark', e.target.value)}
                  />
                </td>
              </tr>
              {/* 備考 */}
            </thead>
          </Table>
          <div className="updHistoryDiv" style={{ maxHeight: '292px', overflowY: 'auto' }}>
            <Table className="updHistory">
              <thead
                style={{
                  backgroundColor: '#fafafa',
                  color: '#1063aa',
                  height: 20,
                  position: 'sticky',
                  top: 0,
                }}
              >
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '50%' }}>更新日</th>
                  <th style={{ width: '50%' }}>重量単価</th>
                </tr>
              </thead>

              <tbody style={{ textAlign: 'center' }}>
                {(() => {
                  const tblRow = [];
                  let updHistory = { ...selectedRowData }.updHistory || [];

                  for (let j = 0; j < updHistory.length; j++) {
                    tblRow.push(
                      <tr>
                        <td style={{ width: '50%' }}>
                          <label>{updHistory[j].updateDate}</label>
                        </td>
                        <td style={{ width: '50%' }}>
                          <label>{JPYs.format(updHistory[j].updPrice)}</label>
                        </td>
                      </tr>
                    );
                  }
                  return tblRow;
                })()}
              </tbody>
            </Table>
          </div>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default MaterialNameDetail;
