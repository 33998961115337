/**
 * クラス名：板金の装置設定のマテリアルハンドリング時間
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Checkbox } from 'antd';
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { commonModal } from '../../../common/CommonModal';
import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';

const Device_MaterialHandlingTime = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isMagesuu, setIsMageSuu] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [isArea, setIsArea] = useState(false);

  // 曲げ数
  const [magesuuTemp, setMagesuuTemp] = useState([]);
  const [mageSuuData, setMageSuuData] = useState([]);

  // 要素数
  const [isYousosuu, setIsYousosuu] = useState(false);
  const [yousosuu, setYousosuu] = useState([]);
  const [yousosuuTemp, setYousosuuTemp] = useState([]);

  //   曲げ個数
  const [mageKosuuData, setMageKosuuData] = useState([]);
  const [mageKosuuDataTemp, setMageKosuuDataTemp] = useState([]);
  const [isMageKosuu, setIsMageKosuu] = useState(false);
  //   重量と曲げ長
  const [weightData, setWeightData] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);

  // 重量と面積
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);

  // 曲げ長さ別段取時間
  const [magechouDantoriJikanList, setMagechouDantoriJikanList] = useState([]);

  // 段取設定
  const [dantoriKouteiDetails, setDantoriKouteiDetails] = useState([]);

  // 製品重量係数
  const [iq3SeihinSizeLst, setIq3SeihinSizeLst] = useState([]);

  // 段取設定の曲げ係数
  const [correctionTimeFactorList, setcorrectionTimeFactorList] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    setWeightData(props.weight);
    setDetailsWeightTemp(props.weight);
    setLengthData(props.length);

    setAreaData(props.area);
    setDetailsAreaTemp(props.area);
    setMaterialHandlingTimeList(props.materialHandlingTimeList);
    // 製品重量係数
    setIq3SeihinSizeLst(props.iq3SeihinSizeLst);
    // 段取設定の曲げ係数
    setcorrectionTimeFactorList(props.correctionTimeFactorListData);
  }, [
    props.processName,
    props.editMode,
    props.mageSuuData,
    props.mageSuuLst,
    props.area,
    props.materialHandlingTimeListData,
  ]);

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isMagesuu === true) {
      setIsMageSuu(false);
    } else if (isYousosuu === true) {
      setIsYousosuu(false);
    } else if (isMageKosuu === true) {
      setIsMageKosuu(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isArea === true) {
      setIsArea(false);
    } else if (isLength === true) {
      setIsLength(false);
    }
  };

  const cancelMasterData = () => {
    if (isYousosuu === true) {
      setYousosuuTemp(yousosuu);
      setIsYousosuu(false);
    } else if (isMagesuu === true) {
      setMagesuuTemp(mageSuuData);
      setIsMageSuu(false);
    } else if (isMageKosuu === true) {
      setMageKosuuDataTemp(mageKosuuData);
      setIsMageKosuu(false);
    } else if (isLength === true) {
      setDetailsLengthTemp(lengthData);
      setIsLength(false);
    } else if (isWeight === true) {
      setDetailsWeightTemp(weightData);
      setIsWeight(false);
    }
  };

  const handleAreaChange = (event, id, field) => {
    const updatedData = detailsAreaTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };
  const addAreaRow = (id) => {
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsAreaTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };
  const deleteArea = (id, e) => {
    if (detailsAreaTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsAreaTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
    }
  };
  const addAreaMaster = () => {
    const deletedId = [];

    areaData.forEach((item1, index) => {
      const found = detailsAreaTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsAreaTemp
      .filter((item) => item.displayFlag && !areaData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = materialHandlingTimeList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsArea(false);
    setDetailsAreaTemp(detailsAreaTemp);

    setMaterialHandlingTimeList(sortedList);
    props.updatedArea(detailsAreaTemp);

    props.updateMaterialHandlingList(sortedList);

    // 面積マスター更新により製品重量係数一覧更新
    updateIq3SeihinSizeLstByAreaMaster(deletedId, newId, detailsAreaTemp);
    // 面積マスター更新により段取設定の曲げ係数一覧更新
    updateProductSizesListByAreaMaster(deletedId, newId, detailsAreaTemp);
  };
  // 面積マスター更新により製品重量係数一覧更新
  const updateIq3SeihinSizeLstByAreaMaster = (deletedId, newId, detailsAreaTemp) => {
    const deleteData = iq3SeihinSizeLst.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIq3SeihinSizeLst(sortedList);
    props.updatediq3ProductSizesList(sortedList);
  };
  // 面積マスター更新により段取設定の曲げ係数一覧更新
  const updateProductSizesListByAreaMaster = (deletedId, newId, detailsAreaTemp) => {
    const deleteData = correctionTimeFactorList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setcorrectionTimeFactorList(sortedList);
    props.updatedcorrectionTimeFactorListData(sortedList);
  };
  const cancelAreaMaster = () => {
    setDetailsAreaTemp(areaData);
    setIsArea(false);
  };
  const addArea = () => {
    setIsArea(true);
  };
  const addAreaContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleAreaChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelAreaMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );

  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...materialHandlingTimeList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    // setDetailsList(updatedData);
    setMaterialHandlingTimeList(updatedData);
    props.updateMaterialHandlingList(updatedData);
  };
  const addWeightMaster = () => {
    const deletedId = [];

    weightData.forEach((item1, index) => {
      const found = detailsWeightTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsWeightTemp
      .filter((item) => item.displayFlag && !weightData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const test = materialHandlingTimeList.filter((it) => !deletedId.includes(it.key));

    const result = test.map((item) => {
      const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId.forEach((id) => {
      const list = detailsWeightTemp.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result.push(newObj);
      }
    });
    result.sort((a, b) => {
      const aIndex = detailsWeightTemp.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    // setDetailsList(result);
    setMaterialHandlingTimeList(result);
    props.updatedWeight(detailsWeightTemp);

    props.updateMaterialHandlingList(result);
  };
  const handleLengthChange = (event, id, field) => {
    const updatedData = detailsLengthTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = detailsWeightTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };
  const addLengthRow = (id) => {
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsLengthTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e) => {
    if (detailsLengthTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsLengthTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
    }
  };

  const addLengthMaster = () => {
    const deletedId = [];

    lengthData.forEach((item1, index) => {
      const found = detailsLengthTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsLengthTemp
      .filter((item) => item.displayFlag && !lengthData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = materialHandlingTimeList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const dantoriJikanMagechou = magechouDantoriJikanList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedData2 = dantoriJikanMagechou.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsLengthTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsLengthTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updatedData2.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsLengthTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsLengthTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsLength(false);
    setDetailsLengthTemp(detailsLengthTemp);

    // setDetailsList(sortedList);
    setMaterialHandlingTimeList(sortedList);
    props.updatedLength(detailsLengthTemp);

    props.updatedDantoriJuuryouMagechouList(sortedList);

    setMagechouDantoriJikanList(sortedList2);
    props.updatedmagechouDantoriJikanListData(sortedList2);
  };
  const deleteWeight = (id, e) => {
    if (detailsWeightTemp.filter((item) => item.displayFlag === true).length > 3) {
      const updatedData = detailsWeightTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
    }
  };
  const addWeightRow = (id) => {
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsWeightTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };

  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleWeightChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addLength = () => {
    setIsLength(true);
  };
  const addWeight = () => {
    setIsWeight(true);
  };

  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row className="table-container">
          <Table
            style={{
              width:
                lengthData.filter((i) => i.displayFlag === true).length > 8
                  ? '100%'
                  : lengthData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                          重量
                        </Button>
                        /
                        <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                          面積
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>重量 / 面積</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {areaData
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    ～{item.max}cm<sup className="tbl-header">2</sup>
                  </td>
                ))}
            </tr>

            {materialHandlingTimeList.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td style={{ backgroundColor: '#1063aa', color: 'white', width: '196px' }}>～{row.max}kg</td>

                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </Table>
        </Row>
      </div>

      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}

      {/* 面積 */}
      {isArea
        ? commonModal(
            isArea,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAreaContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_MaterialHandlingTime;
