/**
 * クラス名：装置設定のチャージ金額画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import React, { Component, useEffect, forwardRef, useState } from 'react';
import { Row, Col, Checkbox, Button } from 'antd';
import { Table } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

import { JPYs } from '../../../common/Common';

const ChargeAmount = forwardRef((props, ref) => {
  const [processDetails, setProcessDetails] = useState([]);
  const [category, setCategory] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [jikanCharge, setJikanCharge] = useState([]);

  // iq3
  const [setupAmount, setSetupAmount] = useState('');
  const [workAmount, setWorkAmount] = useState('');
  const [iq3ProcessInspection, setIq3ProcessInspection] = useState('');
  const [secondProcessAmount, setSecondProcessAmount] = useState('');
  const [jointBarashiAmount, setJointBarashiAmount] = useState('');
  const [processMethod, setProcessMethod] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    if (props.detailsData != undefined) {
      setProcessDetails(props.detailsData);
      setCategory(props.processName);
    }
    setJikanCharge(props.chajiDetails);
    // iq3
    setSetupAmount(props.iq3SetupAmount);
    setWorkAmount(props.iq3WorkAmount);
    setIq3ProcessInspection(props.iq3ProcessInspection);
    setSecondProcessAmount(props.secondProcessAmount);
    setJointBarashiAmount(props.jointBarashiAmount);
    setProcessMethod(props.processMethod);
  }, [
    props.detailsData,
    props.processName,
    props.editMode,
    props.chajiDetails,
    props.iq3SetupAmount,
    props.iq3WorkAmount,
    props.iq3ProcessInspection,
    props.secondProcessAmount,
    props.jointBarashiAmount,
    props.processMethod,
  ]);

  const changeDantori = (itemId, subItemId, value, types) => {
    // const regex = /^([0-9]|￥)+$/;
    // const regex = /^[0-9,¥]+$/;
    // value = value.replace(/[０-９]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xfee0));
    if (types === 'dantori') {
      // if (regex.test(value)) {
      // if (value.startsWith('¥', 0)) {
      //   value = value.replace('¥', '');
      // }
      // if (value.indexOf(',') > 0) {
      //   value = value.replace(/,/g, '');
      // }

      const updatedData = processDetails.map((item) => {
        if (item.key === itemId) {
          const updatedSubItems = item.processDetailValue.map((subItem) => {
            if (subItem.key === subItemId) {
              return { ...subItem, dantori: value };
            }
            return subItem;
          });

          return { ...item, processDetailValue: updatedSubItems };
        }
        return item;
      });

      setProcessDetails(updatedData);
      props.updatedAmount(updatedData);
      // } else {
      //   value = 0;
      //   const updatedData = processDetails.map((item) => {
      //     if (item.key === itemId) {
      //       const updatedSubItems = item.processDetailValue.map((subItem) => {
      //         if (subItem.key === subItemId) {
      //           return { ...subItem, dantori: value };
      //         }
      //         return subItem;
      //       });

      //       return { ...item, processDetailValue: updatedSubItems };
      //     }
      //     return item;
      //   });

      //   setProcessDetails(updatedData);
      //   props.updatedAmount(updatedData);
      // }
    } else if (types === 'kakou') {
      // if (regex.test(value)) {
      //   if (value.startsWith('¥', 0)) {
      //     value = value.replace('¥', '');
      //   }
      //   if (value.indexOf(',') > 0) {
      //     value = value.replace(/,/g, '');
      //   }
      const updatedData = processDetails.map((item) => {
        if (item.key === itemId) {
          const updatedSubItems = item.processDetailValue.map((subItem) => {
            if (subItem.key === subItemId) {
              return { ...subItem, kakou: value };
            }
            return subItem;
          });

          return { ...item, processDetailValue: updatedSubItems };
        }
        return item;
      });
      setProcessDetails(updatedData);
      props.updatedAmount(updatedData);
      // } else {
      //   value = 0;
      //   const updatedData = processDetails.map((item) => {
      //     if (item.key === itemId) {
      //       const updatedSubItems = item.processDetailValue.map((subItem) => {
      //         if (subItem.key === subItemId) {
      //           return { ...subItem, kakou: value };
      //         }
      //         return subItem;
      //       });

      //       return { ...item, processDetailValue: updatedSubItems };
      //     }
      //     return item;
      //   });
      //   setProcessDetails(updatedData);
      //   props.updatedAmount(updatedData);
      // }
    } else if (types === 'dantoriSurface') {
      // if (regex.test(value)) {
      //   if (value.startsWith('¥', 0)) {
      //     value = value.replace('¥', '');
      //   }
      //   if (value.indexOf(',') > 0) {
      //     value = value.replace(/,/g, '');
      //   }

      const updatedData = processDetails.map((item) => {
        if (item.key === itemId) {
          const updatedSubItems = item.processDetailValue.map((subItem) => {
            if (subItem.key === subItemId) {
              return { ...subItem, dantoriSurface: value };
            }
            return subItem;
          });

          return { ...item, processDetailValue: updatedSubItems };
        }
        return item;
      });

      setProcessDetails(updatedData);
      props.updatedAmount(updatedData);
      // } else {
      //   value = 0;
      //   const updatedData = processDetails.map((item) => {
      //     if (item.key === itemId) {
      //       const updatedSubItems = item.processDetailValue.map((subItem) => {
      //         if (subItem.key === subItemId) {
      //           return { ...subItem, dantoriSurface: value };
      //         }
      //         return subItem;
      //       });

      //       return { ...item, processDetailValue: updatedSubItems };
      //     }
      //     return item;
      //   });

      //   setProcessDetails(updatedData);
      //   props.updatedAmount(updatedData);
      // }
    }
  };
  const changeJikanCharge = (id, e) => {
    const val = JSON.parse(JSON.stringify(jikanCharge));
    // const amount = val[id - 1]['val'];
    // const regex = /^[0-9,¥]+$/;
    // if (regex.test(e)) {
    // if (e.startsWith('¥', 0)) {
    //   e = e.replace('¥', '');
    // }
    // if (e.indexOf(',') > 0) {
    //   e = e.replace(/,/g, '');
    // }
    val[id - 1]['val'] = e;

    setJikanCharge(val);
    props.updatedCharge(val);
    // } else {
    //   val[id - 1]['val'] = 0;

    //   setJikanCharge(val);
    //   props.updatedCharge(val);
    // }
  };
  const changeAmount = (name, e) => {
    // const regex = /^[0-9,¥]+$/;
    // if (regex.test(e)) {
    //   if (e.startsWith('¥', 0)) {
    //     e = e.replace('¥', '');
    //   }
    //   if (e.indexOf(',') > 0) {
    //     e = e.replace(/,/g, '');
    //   }
    // } else {
    //   e = 0;
    // }
    if (name === 'dantori') {
      setSetupAmount(e);
      props.updatediq3SetupAmount(e);
    } else if (name === 'kakou') {
      setWorkAmount(e);
      props.updatediq3WorkAmount(e);
    } else if (name === 'kensa') {
      setIq3ProcessInspection(e);
      props.updatediq3ProcessInspection(e);
    } else if (name === 'niji') {
      setSecondProcessAmount(e);
      props.updatedsecondProcessAmount(e);
    } else if (name === 'joint') {
      setJointBarashiAmount(e);
      props.updatedjointBarashiAmount(e);
    }
  };

  return (
    <>
      <div className="registerModal" style={{ marginLeft: 10, marginRight: 10 }}>
        {category === '溶接' || category === '溶接仕上' || category === '表面処理' || category === '2次加工' ? (
          <>
            {processDetails.map((item) => (
              <Row key={item.key} className="chargeAmount">
                {item.processDetailValue
                  .filter((subitem) => subitem.displayFlg === true)
                  .map((subItem) => (
                    <Col style={{ width: category === '表面処理' ? '33%' : '20%' }}>
                      <Table>
                        <thead>
                          <tr style={{ textAlign: 'center' }}>
                            <th className="tbl-header" colSpan={category === '表面処理' ? 3 : 2}>
                              {subItem.detailType}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '30.3%' }}>
                              {category == '溶接' || category == '溶接仕上' || category === '2次加工' ? (
                                '段取'
                              ) : (
                                <Row>
                                  <Col span={5}>
                                    <Checkbox
                                      style={{ marginLeft: 3 }}
                                      type="checkbox"
                                      disabled={!editMode}
                                      checked={subItem.dantoriFlag}
                                      onChange={(e) => {
                                        const data = [...processDetails];
                                        const processDetailValue = data[item.key].processDetailValue;
                                        processDetailValue[subItem.key].dantoriFlag = e.target.checked;
                                        if (e.target.checked) {
                                          processDetailValue[subItem.key].kakouFlag = false;
                                        }
                                        setProcessDetails(data);
                                      }}
                                    ></Checkbox>
                                  </Col>
                                  <Col span={19}>
                                    <label>
                                      面積(￥/m<sup>2</sup>)
                                    </label>
                                  </Col>
                                </Row>
                              )}
                            </td>
                            <td style={{ width: '30.3%' }}>
                              {category == '溶接' || category == '溶接仕上' || category === '2次加工' ? (
                                '加工'
                              ) : (
                                <Row>
                                  <Col span={5}>
                                    <Checkbox
                                      style={{ marginLeft: 3 }}
                                      type="checkbox"
                                      disabled={!editMode}
                                      checked={subItem.kakouFlag}
                                      onChange={(e) => {
                                        const data = [...processDetails];
                                        const processDetailValue = data[item.key].processDetailValue;
                                        processDetailValue[subItem.key].kakouFlag = e.target.checked;
                                        if (e.target.checked) {
                                          processDetailValue[subItem.key].dantoriFlag = false;
                                        }
                                        setProcessDetails(data);
                                      }}
                                    ></Checkbox>
                                  </Col>
                                  <Col span={19}>
                                    <label>重量(￥/kg)</label>
                                  </Col>
                                </Row>
                              )}
                            </td>
                            {category === '表面処理' ? (
                              <td style={{ width: '30.3%' }}>
                                <>段取(￥/h)</>
                              </td>
                            ) : (
                              ''
                            )}
                          </tr>
                          <tr style={{ textAlign: 'center' }}>
                            <td>
                              <CurrencyInput
                                value={subItem.dantori}
                                style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                                className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                                onValueChange={(event) => changeDantori(item.key, subItem.key, event, 'dantori')}
                                maxLength={Number.MAX_SAFE_INTEGER}
                                prefix="¥"
                                decimalsLimit={100}
                                defaultValue={0}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                value={subItem.kakou}
                                style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                                className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                                onValueChange={(event) => changeDantori(item.key, subItem.key, event, 'kakou')}
                                maxLength={Number.MAX_SAFE_INTEGER}
                                prefix="¥"
                                decimalsLimit={100}
                                defaultValue={0}
                              />
                            </td>
                            {category === '表面処理' ? (
                              <td>
                                <CurrencyInput
                                  value={subItem.dantoriSurface}
                                  style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                                  className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                                  onValueChange={(event) =>
                                    changeDantori(item.key, subItem.key, event, 'dantoriSurface')
                                  }
                                  maxLength={Number.MAX_SAFE_INTEGER}
                                  prefix="¥"
                                  decimalsLimit={100}
                                  defaultValue={0}
                                />
                              </td>
                            ) : (
                              ''
                            )}
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  ))}
              </Row>
            ))}
          </>
        ) : category === '組立' || category === '検査' || category === '梱包' ? (
          <>
            <Row className="chargeAmount">
              <Table style={{ width: '30%' }}>
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="tbl-header" colSpan={3}>
                      時間チャージ金額
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '10%' }}>時間(￥/h)</td>
                    <td style={{ width: '10%' }}>重量(￥/kg)</td>
                    <td style={{ width: '10%' }}>段取(￥/h)</td>
                  </tr>
                  <tr style={{ textAlign: 'center' }}>
                    {jikanCharge.map((i) => {
                      const { id, val } = i;
                      return (
                        <>
                          <td>
                            <CurrencyInput
                              style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                              value={val}
                              id={id}
                              className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                              onValueChange={(event) => changeJikanCharge(id, event)}
                              maxLength={Number.MAX_SAFE_INTEGER}
                              prefix="¥"
                              decimalsLimit={100}
                              defaultValue={0}
                            />
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : category === 'プログラム' || category === 'シャーリング' ? (
          <>
            <Row className="chargeAmount">
              <Table style={{ width: '20%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th style={{ width: '10%' }} className="tbl-header">
                      {category === 'プログラム' ? '段取' : '段取/加工'}
                    </th>
                    <th style={{ width: '10%' }} className="tbl-header">
                      {category === 'プログラム' ? '作業' : '工程内検査'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={setupAmount}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('dantori', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={workAmount}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('kakou', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : category === 'ベンディング' || category === 'バリ取' ? (
          <>
            <Row className="chargeAmount">
              <Table style={{ width: category === 'ベンディング' || category === 'バリ取' ? '30%' : '50%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <th style={{ width: '10%' }} className="tbl-header">
                      段取
                    </th>

                    <th style={{ width: '10%' }} className="tbl-header">
                      加工
                    </th>
                    {category === 'ブランク' ? (
                      <>
                        <th style={{ width: '10%' }} className="tbl-header">
                          2次加工
                        </th>
                        <th style={{ width: '10%' }} className="tbl-header">
                          ジョイントバラシ
                        </th>
                      </>
                    ) : (
                      <></>
                    )}
                    <th style={{ width: '10%' }} className="tbl-header">
                      工程内検査
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={setupAmount}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('dantori', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={workAmount}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('kakou', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    {category === 'ブランク' ? (
                      <>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            value={secondProcessAmount}
                            onValueChange={(e) => changeAmount('niji', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            value={jointBarashiAmount}
                            onValueChange={(e) => changeAmount('joint', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={iq3ProcessInspection}
                        onValueChange={(e) => changeAmount('kensa', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : category === 'ブランク' ? (
          <>
            <Row className="chargeAmount">
              <Table style={{ width: processMethod === '複合機' ? '90%' : '75%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <th style={{ width: '15%', verticalAlign: 'middle' }} className="tbl-header">
                      段取
                    </th>
                    {processMethod === '複合機' ? (
                      <>
                        <th style={{ width: '15%' }} className="tbl-header">
                          パンチ加工
                        </th>
                        <th style={{ width: '15%' }} className="tbl-header">
                          レーザー加工
                        </th>
                      </>
                    ) : (
                      <>
                        <th style={{ width: '15%' }} className="tbl-header">
                          加工
                        </th>
                      </>
                    )}

                    <th style={{ width: '15%' }} className="tbl-header">
                      2次加工
                    </th>
                    <th style={{ width: '15%' }} className="tbl-header">
                      ジョイントバラシ
                    </th>

                    <th style={{ width: '15%' }} className="tbl-header">
                      工程内検査
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={setupAmount}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('dantori', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    {processMethod === '複合機' ? (
                      <>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            value={workAmount}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            onValueChange={(e) => changeAmount('kakou', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            value={workAmount}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            onValueChange={(e) => changeAmount('kakou', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            value={workAmount}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            onValueChange={(e) => changeAmount('kakou', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                      </>
                    )}

                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={secondProcessAmount}
                        onValueChange={(e) => changeAmount('niji', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={jointBarashiAmount}
                        onValueChange={(e) => changeAmount('joint', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>

                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={iq3ProcessInspection}
                        onValueChange={(e) => changeAmount('kensa', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

export default ChargeAmount;
