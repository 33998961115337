/**
 * クラス名：係数パラメータ
 * 説明：係数情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Form, Button, Row, Input, Col, Modal, Select, Checkbox, Space, Image, Radio, DatePicker } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import CoefficientDetails from './CoefficientDetails';
import { dataStateList, today, TextArea } from '../../../common/Common';
import checked from '../../../../assets/images/checked.png';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';

const columns = [
  {
    id: '1',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    render: (id, record, index) => {
      return index + 1;
    },
    sorter: (a, b) => a.no - b.no,
    sortOrder: 'ascend',
    className: 'cm-a-right',
  },
  {
    id: '2',
    title: '表示',
    dataIndex: 'display',
    width: 95,
    render: (index, item) => {
      return item['display'] === 1 ? (
        <Image
          preview={false}
          width={13}
          src={checked}
          style={{ marginLeft: '3px' }}
          key={item['key']}
          id={item['id']}
        />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
  },
  {
    id: '3',
    title: 'コード',
    dataIndex: 'code',
    width: 120,
  },
  {
    id: '4',
    title: '名称',
    dataIndex: 'name',
    width: 150,
  },
  {
    id: '5',
    title: '係数',
    dataIndex: 'coefficient',
    width: 100,
    className: 'cm-a-right',
  },

  {
    id: '6',
    title: 'ソート番号',
    dataIndex: 'sortNo',
    width: 95,
    className: 'cm-a-right',
  },
  {
    id: '7',
    title: '作成日',
    dataIndex: 'created',
    width: 100,
  },
  {
    id: '8',
    title: '更新日',
    dataIndex: 'updated',
    width: 100,
  },
  {
    id: '9',
    title: '備考',
    dataIndex: 'remarks',
    width: 200,
  },
  {
    id: '10',
    title: '',
    dataIndex: 'tableNo',
    hidden: true,
  },
].filter((item) => !item.hidden);

const deliTimeColumns = [
  {
    id: '1',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    sorter: (a, b) => a.no - b.no,
    sortOrder: 'ascend',
    className: 'cm-a-right',
  },
  {
    id: '2',
    title: '表示',
    dataIndex: 'display',
    width: 95,
    render: (index, item) => {
      return item['display'] === 1 ? (
        <Image
          preview={false}
          width={13}
          src={checked}
          style={{ marginLeft: '3px' }}
          key={item['key']}
          id={item['id']}
        />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
  },
  {
    id: '3',
    title: 'コード',
    dataIndex: 'code',
    width: 120,
  },
  {
    id: '4',
    title: '名称',
    dataIndex: 'name',
    width: 150,
  },
  {
    id: '5',
    title: '係数',
    dataIndex: 'coefficient',
    width: 100,
    className: 'cm-a-right',
  },
  {
    id: '6',
    title: '目安日数',
    dataIndex: 'estimateDays',
    width: 100,
    className: 'cm-a-right',
  },

  {
    id: '7',
    title: 'ソート番号',
    dataIndex: 'sortNo',
    width: 95,
    className: 'cm-a-right',
  },
  {
    id: '8',
    title: '作成日',
    dataIndex: 'created',
    width: 100,
  },
  {
    id: '9',
    title: '更新日',
    dataIndex: 'updated',
    width: 100,
  },
  {
    id: '10',
    title: '備考',
    dataIndex: 'remarks',
    width: 200,
  },
  {
    id: '11',
    title: '',
    dataIndex: 'tableNo',
    hidden: true,
  },
].filter((item) => !item.hidden);

const tableColumns = columns.map((item) => ({
  ...item,
}));

const deliTimeTableColumns = deliTimeColumns.map((item) => ({
  ...item,
}));

const CoefficientParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  let checkedRow = [];
  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];
  const coefficientType = [
    { value: 1, label: '受注係数' },
    { value: 2, label: '納期係数' },
    { value: 3, label: '購入品係数' },
    { value: 4, label: '管理係数' },
  ];

  const [paramSelected, setParamSelected] = useState('coefficient');
  const [paramType, setParamType] = useState('common');
  const [orderCoeffData, setOrderCoeffData] = useState([]);
  const [orderCoeffDataTmp, setOrderCoeffDataTmp] = useState([]);
  const [orderFilteredData, setOrderFilteredData] = useState([]);
  const [deliTimeCoeffData, setDeliTimeCoeffData] = useState([]);
  const [deliTimeCoeffDataTmp, setDeliTimeCoeffDataTmp] = useState([]);
  const [deliTimeFilteredData, setDeliTimeFilteredData] = useState([]);
  const [purchaseCoeffData, setPurchaseCoeffData] = useState([]);
  const [purchaseCoeffDataTmp, setPurchaseCoeffDataTmp] = useState([]);
  const [purchaseFilteredData, setPurchaseFilteredData] = useState([]);
  const [maintainCoeffData, setMaintainCoeffData] = useState([]);
  const [maintainCoeffDataTmp, setMaintainCoeffDataTmp] = useState([]);
  const [maintainFilteredData, setMaintainFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [tempCoefficientData, setTempCoefficientData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState(0);
  const [changeTableNo, setChangeTableNo] = useState();

  const [display, setDisplay] = useState();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [coefficient, setCoefficient] = useState('');
  const [estimateDays, setEstimateDays] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNo, setSortNo] = useState('');
  const [created, setCreated] = useState('');
  const [updated, setUpdated] = useState(today);
  const [tableNo, setTableNo] = useState(1);

  const [dataState, setDataState] = useState(0);
  const [keyword, setKeyWord] = useState('');

  const [outsourcingChecked, setOutsourcingChecked] = useState(false);
  const [invalidFlgChecked, setInvalidFlgChecked] = useState(false);
  const [hasCheckData, setHasCheckData] = useState(true);
  const [chkData, setChkData] = useState(0);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditOrdSelectedRow, setBulkEditOrdSelectedRow] = useState([]);
  const [bulkEditDltSelectedRow, setBulkEditDltSelectedRow] = useState([]);
  const [bulkEditPurSelectedRow, setBulkEditPurSelectedRow] = useState([]);
  const [bulkEditMainSelectedRow, setBulkEditMainSelectedRow] = useState([]);
  const [bulkEditOrdSelectedKeys, setBulkEditOrdSelectedKeys] = useState([]);
  const [bulkEditDltSelectedKeys, setBulkEditDltSelectedKeys] = useState([]);
  const [bulkEditPurSelectedKeys, setBulkEditPurSelectedKeys] = useState([]);
  const [bulkEditMainSelectedKeys, setBulkEditMainSelectedKeys] = useState([]);

  const [isOrderCoeff, setIsOrderCoeff] = useState(true);
  const [isDeliTimeCoeff, setIsDeliTimeCoeff] = useState(true);
  const [isPurchaseCoeff, setIsPurchaseCoeff] = useState(true);
  const [isMaintainCoeff, setIsMaintainCoeff] = useState(true);

  const [isBulkEditOrderCoeff, setIsBulkEditOrderCoeff] = useState(true);
  const [isBulkEditDeliTimeCoeff, setIsBulkEditDeliTimeCoeff] = useState(true);
  const [isBulkEditPurchaseCoeff, setIsBulkEditPurchaseCoeff] = useState(true);
  const [isBulkEditMaintainCoeff, setIsBulkEditMaintainCoeff] = useState(true);

  // 新規作成
  const [coeffType, setCoeffType] = useState(1);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  useEffect(() => {
    let tempData = [];
    let selectedData = [];

    tempData =
      localStorage.getItem('paramCommonOrderCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonOrderCoeff'));
    selectedData = tempData;
    setOrderCoeffData(tempData);
    setOrderFilteredData(tempData);
    // setSelectedRowKeys(tempData?.length);
    // setSelectedData(selectedData[selectedData?.length - 1]);
    setSelectedRowKeys(1);
    setSelectedData(selectedData != undefined && selectedData.length > 0 ? selectedData[1] : []);
    setSelectedRowsData(tempData);
    setTableNo(1);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonDeliTimeCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonDeliTimeCoeff'));
    setDeliTimeCoeffData(tempData);
    setDeliTimeFilteredData(tempData);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonPurchaseCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonPurchaseCoeff'));
    setPurchaseCoeffData(tempData);
    setPurchaseFilteredData(tempData);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonMaintainCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonMaintainCoeff'));
    setMaintainCoeffData(tempData);
    setMaintainFilteredData(tempData);

    navigate(':common.coefficient');
    setParamSelected('coefficient');
    setParamType('common');
    setIsOrderCoeff(true);
    props.setParam(paramType, paramSelected);
  }, []);

  // フラグ又はキーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [keyword, dataState, orderCoeffDataTmp, deliTimeCoeffDataTmp, purchaseCoeffDataTmp, maintainCoeffDataTmp]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      let tempData = [];
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          tempData = getDataByType(changeTableNo);
          setTableNo(changeTableNo);
          setSelectedRowKeys(changeRecordKey);
          setSelectedData(getSelectedData(tempData, changeRecordKey));
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          tempData = getDataByType(tableNo);
          setSelectedData(getSelectedData(tempData, selectedRowKeys));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 一括編集の行チェックボックスを押下
  useEffect(() => {
    let chkCount =
      bulkEditOrdSelectedRow.length +
      bulkEditDltSelectedRow.length +
      bulkEditPurSelectedRow.length +
      bulkEditMainSelectedRow.length;
    setChkRowsCount(chkCount);
  }, [bulkEditOrdSelectedRow, bulkEditDltSelectedRow, bulkEditPurSelectedRow, bulkEditMainSelectedRow]);

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 入力項目情報
  const inputFormItem = (
    <RTable id="param_detail_tbl" className="propertiesTbl">
      <thead>
        {/* 種類名 */}
        {isCreateNew ? (
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">係数種類名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="coeffType"
                defaultValue={coeffType}
                className="radioCustomerGpEdit"
                onChange={(e) => setCoeffType(e.target.value)}
                value={coeffType}
              >
                {coefficientType.map((item) => (
                  <Radio value={item.value}>{item.label}</Radio>
                ))}
              </RadioGroup>
            </td>
          </tr>
        ) : (
          <></>
        )}
        {/* 表示 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">表示</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <RadioGroup
              name="display"
              defaultValue={1}
              className="radioCustomerGpEdit"
              onChange={(e) => setDisplay(e.target.value)}
              value={display}
            >
              <Radio value={1}>オン</Radio>
              <Radio value={0}>オフ</Radio>
            </RadioGroup>
          </td>
        </tr>
        {/* コード */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">コード</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="code"
              id="code"
              value={code}
              type="text"
              onChange={(e) => setCode(e.target.value)}
            />
          </td>
        </tr>
        {/* 名称 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">名称</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="name"
              id="name"
              value={name}
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
          </td>
        </tr>
        {/* 係数 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">係数</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="coefficient"
              id="coefficient"
              value={coefficient}
              type="text"
              onChange={(e) => setCoefficient(e.target.value)}
            />
          </td>
        </tr>

        {/* ソート番号 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">ソート番号</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="sortNo"
              id="sortNo"
              value={sortNo}
              type="text"
              onChange={(e) => setSortNo(e.target.value)}
            />
          </td>
        </tr>
        {/* 作成日 更新日 */}
        {isBulkEdit ? (
          ''
        ) : (
          <>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">作成日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{created}</label>
              </td>
            </tr>

            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{updated}</label>
              </td>
            </tr>
          </>
        )}
        {/* 備考 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">備考</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <TextArea
              className={'input-editable'}
              name="remarks"
              id="remarks"
              value={remarks}
              type="text"
              style={{ margin: 5, padding: 5, width: '98.3%' }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </td>
        </tr>
      </thead>
    </RTable>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, key) => {
    let ret = [];
    let record;
    record = data.find((item) => item.key === key);
    return record ? record : ret;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let selectedData = [];
    selectedData.push({
      key: record.key,
      id: record.id,
      no: record.no,
      display: record.display,
      code: record.code,
      name: record.name,
      coefficient: record.coefficient,
      estimateDays: record.estimateDays,
      remarks: record.remarks,
      sortNo: record.sortNo,
      created: record.created,
      updated: record.updated,
      tableNo: record.tableNo,
    });
    setChangeRecordKey(record.key);
    setChangeTableNo(record.tableNo);
    if (editMode) {
      setDiscardChangeMode(discardChange[0]);
      setEditModeCancelConfirm(true);
    } else {
      setTableNo(record.tableNo);
      setSelectedRowKeys(record.key);
      setSelectedData(selectedData[0]);
      navigate(':common.coefficient');
    }
  };

  // 一覧画面の行を選択した場合、
  const onRowSelectWithoutEditMode = (record) => {
    let selectedData = [];
    selectedData.push({
      key: record.key,
      id: record.id,
      no: record.no,
      display: record.display,
      code: record.code,
      name: record.name,
      coefficient: record.coefficient,
      estimateDays: record.estimateDays,
      remarks: record.remarks,
      sortNo: record.sortNo,
      created: record.created,
      updated: record.updated,
      tableNo: record.tableNo,
    });
    setChangeRecordKey(record.key);
    setChangeTableNo(record.tableNo);

    setTableNo(record.tableNo);
    setSelectedRowKeys(record.key);
    setSelectedData(selectedData[0]);
    navigate(':common.coefficient');
  };

  const handleOkCSVInput = (e) => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    setIsDelete(true);
    setChildTitle('削除');
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = (e) => {
    if (isDelete) {
      setIsDelete(false);
      setEditMode(false);
      let selectedKey = selectedRowKeys;
      let filterdData = getFilteredDataByType(tableNo);
      filterdData = filterdData.filter((item) => item.key !== selectedKey);
      setFilteredDataByType(tableNo, filterdData);

      let currentData = getDataByType(tableNo);
      currentData = currentData.filter((item) => item.key !== selectedKey);
      currentData = currentData.map((item, index) => {
        item.no = index + 1;
        return item;
      });
      setDataByType(tableNo, currentData);

      //一番上のテーブルの最初の行を選択;
      let rowSelectTbl = [];
      outerLoop: for (let i = 1; i <= 4; i++) {
        tableNo === i ? (rowSelectTbl = currentData) : (rowSelectTbl = getDataByType(i));
        if (rowSelectTbl.length <= 0) {
          continue outerLoop;
        } else {
          break outerLoop;
        }
      }
      let lastKey = rowSelectTbl[rowSelectTbl.length - 1]?.key;
      let record = getSelectedData(rowSelectTbl, lastKey);
      onRowSelectWithoutEditMode(record);
    }
  };

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(true);
    setChildTitle('CSV入力');
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
    setChildTitle('XML入力');
  };

  const resetInputInfo = () => {
    setCoeffType(1);
    setDisplay();
    setCode('');
    setName('');
    setCoefficient('');
    setEstimateDays('');
    setRemarks('');
    setSortNo();
    setCreated('');
    setUpdated(today);
  };

  // 表示を押下の場合、
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
  };

  // 絞り込み、
  const getDataByFilter = () => {
    let searchBarText = keyword;
    let flag;
    let currentData = [];
    let currentFilterData = [];
    let getAllFilteredData = [];
    let isDataExist = false;
    for (let i = 4; i >= 1; i--) {
      currentData = getDataByType(i);
      if (currentData.length <= 0) {
        return;
      }
      currentFilterData = currentData;
      // キーワードで絞り込み
      if (searchBarText != '') {
        currentFilterData = currentData.filter(
          (item) =>
            item.code?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.name?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.coefficient?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.estimateDays?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.remarks?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.created?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.updated?.toLowerCase().indexOf(searchBarText) >= 0
        );
      }

      //  表示で絞り込み
      if (dataState === 1 || dataState === 2) {
        dataState === 1 ? (flag = 1) : (flag = 0);
        currentFilterData = currentFilterData.filter((item) => item.display === flag);
      }

      // 「受注係数・納期係数・購入品係数・管理係数」を絞り込んだ、纏め情報
      if (currentFilterData.length > 0) getAllFilteredData.push(...currentFilterData);
    }

    let index = getAllFilteredData.findIndex((item) => item.key === selectedRowKeys && item.tableNo === tableNo);
    // 検索した結果に、選択中行のKeyが無い場合、
    /*  if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (getAllFilteredData.length > 0) {
        index = getAllFilteredData.length - 1;
        setTableNo(getAllFilteredData[index]?.tableNo);
        setSelectedRowKeys(getAllFilteredData[index]?.key);
        scrollTo('table' + getAllFilteredData[index]?.tableNo);
      }
    } 

    // 検索結果の有無チェック、
    getAllFilteredData.length > 0 ? setSelectedData(getAllFilteredData[index]) : setSelectedData(getAllFilteredData);*/

    // 一覧に表示するデータ
    for (let i = 1; i <= 4; i++) {
      let tmpData = getAllFilteredData.filter((item) => item.tableNo === i);
      setFilteredDataByType(i, tmpData);
    }
  };

  // Scrollの移動
  const scrollTo = (index) => {
    // Scrollの移動
    document.getElementById(index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const addNewModalTitle = (
    <div
      style={{
        width: 700,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = (e) => {
    let bulkEditSelectedRow = [
      ...bulkEditOrdSelectedRow,
      ...bulkEditDltSelectedRow,
      ...bulkEditPurSelectedRow,
      ...bulkEditMainSelectedRow,
    ];
    if (isCreateNew) {
      let lastIndex = tempCoefficientData.length - 1;
      let tempData = tempCoefficientData[lastIndex];
      let currentFilterData = getFilteredDataByType(coeffType);
      setDataByType(coeffType, tempCoefficientData);
      setFilteredDataByType(coeffType, [...currentFilterData, tempData]);
      onRowSelectWithoutEditMode(tempData);
      setIsCreateNew(false);
      setCreateConfirm(false);
      scrollTo('table' + coeffType);
    } else if (isBulkEdit) {
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      // 選択された全ての対象行を更新、
      bulkEditSelectedRow.map((item) => {
        if (display === 0 || display === 1) item.display = display;
        if (code) item.code = code;
        if (name) item.name = name;
        if (coefficient) item.coefficient = coefficient;
        if (remarks) item.remarks = remarks;
        if (sortNo) item.sortNo = sortNo;
        item.updated = today;
        submitData(item);
        // 詳細情報を再表示
        if (item.key === selectedRowKeys && item.tableNo === tableNo) detailRef.current?.displayDetailInfo(item);
      });
      setUpdateConfirm(false);
    }
    resetInputInfo();
  };

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = (updatedData) => {
    var tmpData = [];
    var key = updatedData.key;
    var tableNo = updatedData.tableNo;
    var index;

    tmpData = getDataByType(tableNo);
    index = tmpData.findIndex((i) => i.key === key);
    if (index !== -1) {
      tmpData[index] = updatedData;
    }
    setDataByType(tableNo, tmpData);

    tmpData = getFilteredDataByType(tableNo);
    index = tmpData.findIndex((i) => i.key === key);
    if (index !== -1) {
      tmpData[index] = updatedData;
    }
    setFilteredDataByType(tableNo, tmpData);
  };

  // 受注・納期・購入品・管理によりデータを返す
  const getDataByType = (tableNo) => {
    let tempData = [];
    switch (tableNo) {
      case 1:
        tempData = [...orderCoeffData];
        break;
      case 2:
        tempData = [...deliTimeCoeffData];
        break;
      case 3:
        tempData = [...purchaseCoeffData];
        break;
      case 4:
        tempData = [...maintainCoeffData];
        break;
    }
    return tempData;
  };

  // 絞り込んだ「受注・納期・購入品・管理」によりデータを返す
  const getFilteredDataByType = (tableNo) => {
    let tempData = [];
    switch (tableNo) {
      case 1:
        tempData = [...orderFilteredData];
        break;
      case 2:
        tempData = [...deliTimeFilteredData];
        break;
      case 3:
        tempData = [...purchaseFilteredData];
        break;
      case 4:
        tempData = [...maintainFilteredData];
        break;
    }
    return tempData;
  };

  // 受注・納期・購入品・管理によりデータを保存する
  const setDataByType = (tableNo, value) => {
    switch (tableNo) {
      case 1:
        setOrderCoeffData(value);
        setOrderCoeffDataTmp(value);
        localStorage.setItem('paramCommonOrderCoeff', JSON.stringify(value));
        break;
      case 2:
        setDeliTimeCoeffData(value);
        setDeliTimeCoeffDataTmp(value);
        localStorage.setItem('paramCommonDeliTimeCoeff', JSON.stringify(value));
        break;
      case 3:
        setPurchaseCoeffData(value);
        setPurchaseCoeffDataTmp(value);
        localStorage.setItem('paramCommonPurchaseCoeff', JSON.stringify(value));
        break;
      case 4:
        setMaintainCoeffData(value);
        setMaintainCoeffDataTmp(value);
        localStorage.setItem('paramCommonMaintainCoeff', JSON.stringify(value));
        break;
    }
  };

  // 絞り込んだ「受注・納期・購入品・管理」によりデータを保存する
  const setFilteredDataByType = (tableNo, value) => {
    switch (tableNo) {
      case 1:
        setOrderFilteredData(value);
        break;
      case 2:
        setDeliTimeFilteredData(value);
        break;
      case 3:
        setPurchaseFilteredData(value);
        break;
      case 4:
        setMaintainFilteredData(value);
        break;
    }
  };

  const clickOrderCoeff = () => {
    setIsOrderCoeff(!isOrderCoeff);
  };

  const clickDeliTimeCoeff = () => {
    setIsDeliTimeCoeff(!isDeliTimeCoeff);
  };

  const clickPurchaseCoeff = () => {
    setIsPurchaseCoeff(!isPurchaseCoeff);
  };

  const clickMaintainCoeff = () => {
    setIsMaintainCoeff(!isMaintainCoeff);
  };

  const clickBulkEditOrderCoeff = () => {
    setIsBulkEditOrderCoeff(!isBulkEditOrderCoeff);
  };

  const clickBulkEditDeliTimeCoeff = () => {
    setIsBulkEditDeliTimeCoeff(!isBulkEditDeliTimeCoeff);
  };

  const clickBulkEditPurchaseCoeff = () => {
    setIsBulkEditPurchaseCoeff(!isBulkEditPurchaseCoeff);
  };

  const clickBulkEditMaintainCoeff = () => {
    setIsBulkEditMaintainCoeff(!isBulkEditMaintainCoeff);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setCreated(today);
    setUpdated(today);
    setDisplay(1);
    setChildTitle('係数（新規作成）');
  };

  const createNewOK = () => {
    prepareCreateNewInfo();
  };

  const createNewCancel = (e) => {
    setCancelConfirm(true);
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempData = getDataByType(coeffType);
    let lastIndex = tempData.length - 1;
    tempData.push({
      key: tempData[lastIndex].key + 1,
      id: tempData[lastIndex].id + 1,
      no: tempData[lastIndex].no + 1,
      display: display,
      code: code,
      name: name,
      coefficient: coefficient,
      estimateDays: estimateDays,
      remarks: remarks,
      sortNo: sortNo,
      created: today,
      updated: today,
      tableNo: coeffType,
    });
    setTempCoefficientData(tempData);
    setCreateConfirm(true);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 475,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  // 一括編集-開始
  const onRowOrdCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditOrdSelectedRow([...record]);
    setBulkEditOrdSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const onRowDltCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditDltSelectedRow([...record]);
    setBulkEditDltSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const onRowPurCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditPurSelectedRow([...record]);
    setBulkEditPurSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const onRowMainCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditMainSelectedRow([...record]);
    setBulkEditMainSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const bulkEditOrdRowSelect = {
    onChange: onRowOrdCheckboxSelect,
  };

  const bulkEditDltRowSelect = {
    onChange: onRowDltCheckboxSelect,
  };

  const bulkEditPurRowSelect = {
    onChange: onRowPurCheckboxSelect,
  };

  const bulkEditMainRowSelect = {
    onChange: onRowMainCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditOrdSelectedRow([]);
    setBulkEditDltSelectedRow([]);
    setBulkEditPurSelectedRow([]);
    setBulkEditMainSelectedRow([]);
    setBulkEditOrdSelectedKeys([]);
    setBulkEditDltSelectedKeys([]);
    setBulkEditPurSelectedKeys([]);
    setBulkEditMainSelectedKeys([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('係数（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <div className="overflow-auto" style={{ height: 750 }}>
            {/* 受注係数 */}
            <Row
              style={{ color: 'black', marginTop: 0 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditOrderCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[0].label}</label>
                  {isBulkEditOrderCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditOrderCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={orderFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditOrdSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditOrdRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 納期係数 */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditDeliTimeCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[1].label}</label>
                  {isBulkEditDeliTimeCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditDeliTimeCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={deliTimeTableColumns}
                  rowKey={(record) => record.id}
                  dataSource={deliTimeFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditDltSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditDltRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 購入品係数 */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditPurchaseCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[2].label}</label>
                  {isBulkEditPurchaseCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditPurchaseCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={purchaseFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditPurSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditPurRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 管理係数 */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditMaintainCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[3].label}</label>
                  {isBulkEditMaintainCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditMaintainCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={maintainFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditMainSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditMainRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
          </div>
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/* 検索アリア */}
            <Space size="middle">
              <Select
                className="search-select"
                onChange={searchByDataState}
                id="dataStateDrop"
                name="dataStateDrop"
                value={dataState}
              >
                {dataStateList.map((item) => (
                  <option id={'dataState' + item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
              <Input
                className="input-editable search-select-input"
                placeholder="キーワードを入力"
                allowClear
                onPressEnter={searchByKeyword}
                onBlur={searchByKeyword}
                onChange={(e) => {
                  if (e.target.value === '') {
                    searchByKeyword(e);
                  }
                }}
              />
            </Space>

            <div className="overflow-auto kouteical " style={{ height: 830 }}>
              {/* 受注係数 */}
              <Row
                style={{ color: 'black', marginTop: 10 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickOrderCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[0].label}</label>
                    {isOrderCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isOrderCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table1"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={orderFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.key == selectedRowKeys && record.tableNo === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 納期係数 */}
              <Row
                style={{ color: 'black', marginTop: 5 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickDeliTimeCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[1].label}</label>
                    {isDeliTimeCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isDeliTimeCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table2"
                    columns={hasCheckData ? deliTimeTableColumns : chkData}
                    dataSource={deliTimeFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.key == selectedRowKeys && record.tableNo === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 購入品係数 */}
              <Row
                style={{ color: 'black', marginTop: 5 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickPurchaseCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[2].label}</label>
                    {isPurchaseCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isPurchaseCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table3"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={purchaseFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.key == selectedRowKeys && record.tableNo === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 管理係数 */}
              <Row
                style={{ color: 'black', marginTop: 5 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickMaintainCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[3].label}</label>
                    {isMaintainCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isMaintainCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table4"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={maintainFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.key == selectedRowKeys && record.tableNo === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </Row>
              </div>
            </div>
          </div>
          {/* プロパティ表示 */}
          <div>
            <Routes>
              <Route
                exact
                path=":common.coefficient"
                element={
                  <CoefficientDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* CSV取込 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleCSVInput}
        onOk={handleOkCSVInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkCSVInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          PCに保存した情報を読み込みます。<br></br>よろしいでしょうか？
        </p>
      </Modal> */}

      {/* XML呼出 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から
          <br />
          取り込みます。
          <br />
          装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default CoefficientParamList;
