/**
 * クラス名：工程マスター詳細画面
 * 説明：工程マスター詳細画面である
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { Component, useEffect, useState, forwardRef } from 'react';
import { Modal, Row, Col, Select, Button, Radio, Image, Form, Collapse, Checkbox } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table as BootstrapTable } from 'react-bootstrap';
import { Input, DatePicker } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  PlusCircleFilled,
  CloseOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { today } from '../../../common/Common.js';

const showProcessDetail = (i) => {
  let showHide = document.getElementById('detailPart' + i).style.display;
  if (showHide == 'block') {
    document.getElementById('detailPart' + i).style.display = 'none';
    document.getElementById('arrowUpDetail' + i).style.display = 'none';
    document.getElementById('arrowDownDetail' + i).style.display = 'block';
  } else {
    document.getElementById('detailPart' + i).style.display = 'block';
    document.getElementById('arrowUpDetail' + i).style.display = 'block';
    document.getElementById('arrowDownDetail' + i).style.display = 'none';
  }
};

const noAddBtnArr = ['組立', '検査', '梱包', 'バリ取', 'ブランク'];

const noAddButEdit = ['溶接', 'ベンディング', '2次加工'];

const ProcessMasterDetails = (props) => {
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [updDateFlg, setUpdDateFlg] = useState(false);

  //詳細工程テーブルに新しい行追加
  const addRows = (index, jIndex) => {
    setSelectedRowData((prevData) => {
      const updatedData = JSON.parse(JSON.stringify(prevData));
      const processDetail = updatedData.processDetail[index] || [];
      const detailValueLength = processDetail.processDetailValue.length + 1;
      const detailValue = {
        key: detailValueLength,
        processDetailNo: detailValueLength,
        displayFlg: true,
        detailCode: detailValueLength.toString().padStart(3, '0'),
        detailType: '',
        created: dayjs().format('YYYY-MM-DD'),
        modified: dayjs().format('YYYY-MM-DD'),
        remark: '',
      };
      processDetail.processDetailValue.splice(jIndex, 0, detailValue);
      updatedData.processDetail[index] = processDetail;
      setOriginData(updatedData);
      setUpdDateFlg(true);
      return updatedData;
    });
  };

  //詳細工程テーブルの行削除
  const deleteRows = (indexi, indexj) => {
    setSelectedRowData((prevData) => {
      const updatedData = JSON.parse(JSON.stringify(prevData));
      const processDetail = updatedData.processDetail[indexi];
      if (
        processDetail &&
        processDetail.processDetailValue.length > 1 &&
        !processDetail.processDetailValue[indexj].useFlg &&
        processDetail.processDetailValue.length > indexj
      ) {
        processDetail.processDetailValue.splice(indexj, 1);
      }
      setOriginData(updatedData);
      return updatedData;
    });
  };

  const editDetailInput = (indexi, indexj, objKey, value) => {
    let showData = JSON.parse(JSON.stringify(selectedRowData));
    let updatedData = JSON.parse(JSON.stringify(originData));
    setSelectedRowData(() => {
      if (indexi !== '' && indexj !== '') {
        let processDetailValueEdt = showData.processDetail[indexi].processDetailValue || [];
        processDetailValueEdt[indexj][objKey] = value;
        showData.processDetail[indexi].processDetailValue = processDetailValueEdt;
        // 更新用Stateオブジェクト

        let processDetailValue = updatedData.processDetail[indexi].processDetailValue || [];
        processDetailValue[indexj][objKey] = value;
        processDetailValue[indexj]['modified'] = dayjs(dayjs()).format('YYYY-MM-DD');
      } else {
        const isValueChanged = String(updatedData[objKey]) !== String(value);
        setUpdDateFlg(isValueChanged);
        updatedData[objKey] = value;
        showData[objKey] = value;
      }
      setOriginData(updatedData);
      console.log(updatedData);
      return showData;
    });
  };

  useEffect(() => {
    if (Object.keys(props.selectedData).length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      let origin = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(origin);
      setSelectedRowData(selectedData);
    }
  }, [props.selectedData]);

  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const getSelectedRowData = () => {
    let selectedRowData = props.selectedData;
    //let selectedRowData = originData;
    setSelectedRowData(selectedRowData);
  };

  const editData = () => {
    /* setEditMode(true); */
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };
  const updateData = () => {
    setUpdateConfirm(true);
    setIsUpdate(true);
    console.log('original--', originData);
  };
  const discardData = () => {
    setCancelConfirm(true);
  };

  const updEditDate = (obj, objOrigin) => {
    let numKey = ['displayFlg', 'detailCode', 'detailType', 'remark'];
    let isUpd = false;
    numKey.forEach((k) => {
      if (objOrigin[k] !== obj[k]) {
        isUpd = true;
      }
    });
    return isUpd;
  };

  const updConfirmOk = (e) => {
    if (isUpdate) {
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      if (props.selectedData && props.selectedData.key === originData.key) {
        props.selectedData.code = originData.code;
        props.selectedData.display = originData.display;
        props.selectedData.processName = originData.processName;
        props.selectedData.processType = originData.processType;
        props.selectedData.remark = originData.remark;

        if (props.selectedData.numberOfProcess !== undefined) {
          props.selectedData.numberOfProcess = originData.numberOfProcess;
        }

        const unmatchedElements = [];
        for (let i = 0; i < originData.processDetail.length; i++) {
          let detailValueOld = props.selectedData.processDetail[i].processDetailValue;
          let detailValueNew = originData.processDetail[i].processDetailValue;
          props.selectedData.processDetail[i].processDetailValue = detailValueNew;

          const unmatchedValues = detailValueNew.filter(
            (newItem) =>
              !detailValueOld.some(
                (oldItem) =>
                  newItem.key === oldItem.key &&
                  newItem.detailCode === oldItem.detailCode &&
                  newItem.detailType === oldItem.detailType
              )
          );
          unmatchedElements.push(unmatchedValues);
        }
        if (updDateFlg || unmatchedElements.length > 0) {
          props.selectedData.modified = dayjs().format('YYYY-MM-DD');
        }
        props.submitData(props.selectedData);
      }
      setSelectedRowData(originData);
      setIsUpdate(false);
    }
    setUpdateConfirm(false);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    /* setEditMode(false); */
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    getSelectedRowData();
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  return (
    <>
      <Form>
        {/* ボタン部分 */}
        <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
          <div>
            <Button className="mainButton" style={{ marginLeft: 10, display: 'none' }} id="update" onClick={updateData}>
              更新
            </Button>
          </div>
          <div>
            <Button
              className="cancelButton"
              style={{ marginLeft: 10, display: 'none' }}
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button className="mainButton" id="edit" onClick={editData}>
              編集
            </Button>
          </div>
        </div>
        {/* プロパティ表示 */}

        <div
          id="showData"
          className="kouteiMaster overflow-auto"
          style={{ height: 741, marginTop: 8, textAlign: 'center' }}
        >
          <div className="upperTbl">
            <BootstrapTable className="kouteiProcess">
              <thead style={{ backgroundColor: '#fafafa', color: '#1063aa', height: 20 }}>
                <tr style={{ textAlign: 'center' }}>
                  <th>No</th>
                  <th>表示</th>
                  <th>分類</th>
                  <th>種別</th>
                  <th>コード</th>
                  <th>工程名</th>
                  <th>工程タイプ</th>
                  <th>備考</th>
                </tr>
              </thead>

              <tbody style={{ textAlign: 'center' }}>
                <tr>
                  <td style={{ width: '6.5%' }}>
                    <label>
                      {/* {selectedRowData.No} */}
                      {props.itemNo}
                    </label>
                  </td>
                  <td style={{ width: '6.5%' }}>
                    <Checkbox
                      checked={selectedRowData.display == true}
                      disabled={!props.editMode || selectedRowData.processType !== 'カスタム'}
                      style={{ marginRight: 6 }}
                      onChange={(e) => editDetailInput('', '', 'display', e.target.checked)}
                    ></Checkbox>
                  </td>
                  <td style={{ width: '6.5%' }}>
                    <label>{selectedRowData.type}</label>
                  </td>
                  <td style={{ width: '11%' }}>
                    <label>{selectedRowData.category}</label>
                  </td>
                  <td style={{ width: '17.4%' }}>
                    <Input
                      value={selectedRowData.code}
                      id={'code'}
                      style={{ width: '100%', textAlign: 'center' }}
                      className={props.editMode ? 'input-editable' : 'input-non-editable'}
                      onChange={(e) => editDetailInput('', '', 'code', e.target.value)}
                    ></Input>
                  </td>
                  <td style={{ width: '17.4%' }}>
                    {selectedRowData.processType === 'カスタム' ||
                    selectedRowData.processType === '検査' ||
                    selectedRowData.processType === '梱包' ? (
                      <Input
                        value={selectedRowData.processName}
                        id={'processName'}
                        style={{ width: '100%', textAlign: 'center' }}
                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                        onChange={(e) => editDetailInput('', '', 'processName', e.target.value)}
                      ></Input>
                    ) : (
                      <label>{selectedRowData.processName}</label>
                    )}
                  </td>
                  <td style={{ width: '17.4%' }}>
                    <label>{selectedRowData.processType}</label>
                  </td>

                  <td style={{ width: '17.4%' }}>
                    <Input
                      value={selectedRowData.remark}
                      id={'remark'}
                      style={{ width: '100%', textAlign: 'center!important', height: '20px!important' }}
                      className={props.editMode ? 'input-editable' : 'input-non-editable'}
                      onChange={(e) => editDetailInput('', '', 'remark', e.target.value)}
                    ></Input>
                  </td>
                </tr>
              </tbody>
            </BootstrapTable>
          </div>
          {selectedRowData.numberOfProcess !== undefined && (
            <div class="numOfProcess">
              <Row>
                <Col span={3}>
                  <BootstrapTable className="detailInfo" style={{ marginLeft: 10, marginBottom: 8, width: '95%' }}>
                    <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                      <tr style={{ textAlign: 'center' }}>
                        <th>加工処理数</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {props.editMode ? (
                            <Select
                              defaultValue="4"
                              style={{
                                width: '100%',
                                fontSize: 13,
                              }}
                              className="numOfProcessDDL"
                              value={selectedRowData.numberOfProcess}
                              onChange={(e) => editDetailInput('', '', 'numberOfProcess', e)}
                              options={[4, 5, 6, 7, 8, 9, 10].map((province) => ({
                                label: province,
                                value: province,
                              }))}
                            />
                          ) : (
                            <label>{selectedRowData.numberOfProcess}</label>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </BootstrapTable>
                </Col>
              </Row>
            </div>
          )}
          {(() => {
            const subArr = [];
            let i = 0;
            const noAddProcess = noAddBtnArr.includes(selectedRowData.processType);
            const noAddCanEdit = noAddButEdit.includes(selectedRowData.processType);
            let processDetail = selectedRowData.processDetail || [];
            /* console.log('method--', { ...processDetail }); */
            for (i = 0; i < processDetail.length; i++) {
              let index = i;
              subArr.push(
                <div className="detailTbl">
                  <Row
                    style={{ color: 'black' }}
                    className="oyabuhin oyabuhin-row"
                    onClick={(e) => showProcessDetail(index)}
                  >
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>{processDetail[i].processDetailTitle}</label>

                        <CaretUpOutlined
                          id={'arrowUpDetail' + i}
                          style={{ fontSize: 18, marginTop: 2, display: 'none' }}
                        />
                        <CaretDownOutlined id={'arrowDownDetail' + i} style={{ fontSize: 18, marginTop: 2 }} />
                      </div>
                    </Col>
                  </Row>

                  <div id={'detailPart' + i} style={{ display: 'block', margin: '3px 10px 8px' }}>
                    <Row>
                      <Col span={24}>
                        <BootstrapTable className="detailInfo">
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th style={{ width: '6%' }}>No</th>
                              <th style={{ width: '6%' }}>使用中</th>
                              <th style={{ width: '16%' }}>コード</th>
                              <th style={{ width: '16%' }}>種類</th>
                              <th style={{ width: '16%' }}>作成日</th>
                              <th style={{ width: '16%' }}>更新日</th>
                              <th style={{ width: '16%' }}>備考</th>
                            </tr>
                          </thead>

                          <tbody>
                            {(() => {
                              const tblRow = [];
                              let processDetailValue = { ...selectedRowData }.processDetail[i].processDetailValue || [];

                              for (let j = 0; j < processDetailValue.length; j++) {
                                tblRow.push(
                                  <tr>
                                    <td style={{ width: '6%' }}>{j + 1}</td>
                                    <td style={{ width: '6%' }}>
                                      <Checkbox
                                        checked={processDetailValue[j].displayFlg == true}
                                        disabled={!props.editMode}
                                        style={{ marginRight: 6 }}
                                        onChange={(e) => editDetailInput(index, j, 'displayFlg', e.target.checked)}
                                      ></Checkbox>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].detailCode}
                                        id={i + 'detailCode' + j}
                                        style={{ width: '100%' }}
                                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                                        // disabled={j < originRow ? true : false}
                                        onChange={(e) => editDetailInput(index, j, 'detailCode', e.target.value)}
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].detailType}
                                        id={i + 'detailType' + j}
                                        style={{ width: '100%' }}
                                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                                        disabled={noAddProcess}
                                        onChange={(e) => editDetailInput(index, j, 'detailType', e.target.value)}
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].created}
                                        id={i + 'created' + j}
                                        style={{ width: '100%' }}
                                        className="input-non-editable"
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].modified}
                                        id={i + 'modified' + j}
                                        style={{ width: '100%' }}
                                        className="input-non-editable"
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].remark}
                                        id={i + 'remark' + j}
                                        style={{ width: '100%' }}
                                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                                        //disabled={j < originRow ? true : false}
                                        onChange={(e) => editDetailInput(index, j, 'remark', e.target.value)}
                                      ></Input>
                                    </td>
                                    <td style={{ border: '0px solid', textAlign: 'left' }}>
                                      {props.editMode && (
                                        <div>
                                          {!noAddProcess && !noAddCanEdit && j === processDetailValue.length - 1 && (
                                            <PlusCircleFilled
                                              className="add-icon"
                                              onClick={() => addRows(index, j + 1)}
                                            />
                                          )}

                                          {!processDetailValue[j].useFlg && j >= processDetail[index].defaultRow && (
                                            <CloseCircleFilled
                                              className="add-icon"
                                              onClick={() => deleteRows(index, j)}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                              return tblRow;
                            })()}
                          </tbody>
                        </BootstrapTable>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            }
            return subArr;
          })()}
        </div>
      </Form>
      {/* 更新確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        width={400}
        open={updateConfirm}
        onOk={updConfirmOk}
        onCancel={updConfirmCancel}
        footer={[
          <Button key="submit" onClick={updConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={updConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>データを更新します。よろしいでしょうか。</p>
      </Modal>
      {/* 破棄確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        width={400}
        open={cancelConfirm}
        onOk={discardConfirmOk}
        onCancel={discardConfirmCancel}
        footer={[
          <Button key="submit" type="primary" onClick={discardConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={discardConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>データを破棄します。よろしいでしょうか。</p>
      </Modal>
    </>
  );
};

export default ProcessMasterDetails;
