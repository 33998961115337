/**
 * クラス名：材料名称一覧画面
 * 説明：iQ3板金に使用の材料名称一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  commonActionFooter,
  confirmUpdateContent,
} from '../../../common/CommonModal';
import {
  JPYs,
  getZaishittsuNameDDL,
  dataStateList,
  actionArr,
  getMaterialNameStorage,
  ResizableTitle,
} from '../../../common/Common.js';
import MaterialNameDetail from './MaterialNameDetail';

dayjs.extend(customParseFormat);

const plusMinus = [
  {
    label: '+',
    key: '1',
  },
  {
    label: '-',
    key: '2',
  },
];

const { Option } = Select;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialNameList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const detailRef = useRef();
  const navigate = useNavigate();

  const [paramSelected, setParamSelected] = useState('材料名称＊（名称毎比重、材料ロス係数）');
  const [paramType, setParamType] = useState('iq3');
  const [materialNameData, setMaterialNameData] = useState([]);
  const [tempMaterialClass, setTempMaterialClass] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState();
  const [dataState, setDataState] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(0);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [chkData, setChkData] = useState(0);
  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [unitPrice, setUnitPrice] = useState(10);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [plusMinusAct, setPlusMinusAct] = useState('+');
  const [isAdd, setIsAdd] = useState(false);
  const [zairyoName, setZairyoName] = useState();
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  //　テーブル列Resizable用変数
  // 一覧用変数
  const [detailMaterialNameWidth, setDetailMaterialNameWidth] = useState(150);
  const [materialNameWidth, setMaterialNameWidth] = useState(150);
  const [pricePerWeightWidth, setPricePerWeightWidth] = useState(100);
  const [remarkWidth, setRemarkWidth] = useState(200);
  // 一括編集用変数
  const [detailMaterialNameWidthEdit, setDetailMaterialNameWidthEdit] = useState(150);
  const [materialNameWidthEdit, setMaterialNameWidthEdit] = useState(150);
  const [pricePerWeightWidthEdit, setPricePerWeightWidthEdit] = useState(100);
  const [remarkWidthEdit, setRemarkWidthEdit] = useState(200);
  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  const releaseDate = '2023-10-01';

  useEffect(() => {
    let materialNameDataSample = getMaterialNameStorage();
    setMaterialNameData(materialNameDataSample);
    setFilteredData(materialNameDataSample);
    setTempMaterialClass(materialNameDataSample[0]);
    setSelectedRowKeys(materialNameDataSample[0].no);
    setSelectedData(materialNameDataSample[0]);
    props.setParam(paramType, paramSelected);
    setIsFilter(true);
  }, []);

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      sorter: (a, b) => a.no - b.no,
      sortOrder: 'ascend',
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'displayFlag',
      title: '表示',
      dataIndex: 'displayFlag',
      width: 95,
      render: (text, record) => {
        return record.displayFlag && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
    },
    {
      key: 'detailMaterialName',
      title: '材料名',
      dataIndex: 'detailMaterialName',
      width: visibleGroupEditModal ? detailMaterialNameWidthEdit : detailMaterialNameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setDetailMaterialNameWidthEdit(size.width);
          } else {
            setDetailMaterialNameWidth(size.width);
          }
        },
      }),
    },
    {
      key: 'materialName',
      title: '材質名称',
      dataIndex: 'materialName',
      width: visibleGroupEditModal ? materialNameWidthEdit : materialNameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setMaterialNameWidthEdit(size.width);
          } else {
            setMaterialNameWidth(size.width);
          }
        },
      }),
    },
    {
      key: 'thickness',
      title: '板厚',
      dataIndex: 'thickness',
      width: 80,
      className: 'cm-a-right',
    },

    {
      key: 'pricePerWeight',
      title: '重量単価',
      dataIndex: 'pricePerWeight',
      className: 'cm-a-right',
      render: (text, record) => {
        return JPYs.format(record.pricePerWeight);
      },
      width: visibleGroupEditModal ? pricePerWeightWidthEdit : pricePerWeightWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setPricePerWeightWidthEdit(size.width);
          } else {
            setPricePerWeightWidth(size.width);
          }
        },
      }),
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
    },
    {
      key: 'remark',
      title: '備考',
      dataIndex: 'remark',
      width: visibleGroupEditModal ? remarkWidthEdit : remarkWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setRemarkWidthEdit(size.width);
          } else {
            setRemarkWidth(size.width);
          }
        },
      }),
    },
  ];

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  const addModalContent = (
    <div id="addNewMaterialName" style={{ marginTop: 8 }}>
      <table className="table table-bordered" style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label> {tempMaterialClass.key + 1} </label>
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">表示</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
              <RadioGroup
                name="displayFlag"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                  }
                }}
                value={tempMaterialClass.displayFlag}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材質名称</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  // setTempMaterialClass({ ...tempMaterialClass, materialName: e });
                  const data = { ...tempMaterialClass };
                  data['materialName'] = e;
                  if (tempMaterialClass.thickness !== '' && zairyoName === '') {
                    data['detailMaterialName'] = e + '_' + data.thickness;
                    setZairyoName(e + '_' + data.thickness);
                  }
                  setTempMaterialClass(data);
                }}
                id="materialClassNameDDL"
                name="materialClassNameDDL"
                /* value={tempMaterialClass.materialName} */
                defaultValue="defaultValue"
              >
                <Option id="defaultValue" value="defaultValue">
                  材質名称を選択してください。
                </Option>
                {getZaishittsuNameDDL().map((item) => (
                  <Option id={'materialClassName' + item.value} value={item.label}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">板厚</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                /* value={tempMaterialClass.thickness} */
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, thickness: e.target.value });
                  }
                }}
                onBlur={(e) => {
                  {
                    if (
                      tempMaterialClass.materialName !== '' &&
                      tempMaterialClass.thickness !== '' &&
                      zairyoName === ''
                    ) {
                      setTempMaterialClass({
                        ...tempMaterialClass,
                        detailMaterialName: tempMaterialClass.materialName + '_' + e.target.value,
                      });
                      setZairyoName(tempMaterialClass.materialName + '_' + e.target.value);
                    }
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材料名</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={zairyoName}
                id="zairyoName"
                onChange={(e) => {
                  {
                    console.log('material detail name--', tempMaterialClass);
                    setZairyoName(e.target.value);
                    setTempMaterialClass({ ...tempMaterialClass, detailMaterialName: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">重量単価</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <CurrencyInput
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.pricePerWeight}
                onValueChange={(e) => {
                  const value = e;
                  let formattedValue = String(value);
                  if (value === '' || value === undefined) {
                    formattedValue = 0;
                  }
                  if (value.startsWith('¥', 0)) {
                    formattedValue = formattedValue.replace('¥', '');
                  }
                  formattedValue = formattedValue.replace(/,/g, '');

                  setTempMaterialClass({
                    ...tempMaterialClass,
                    pricePerWeight: formattedValue,
                  });

                  setTempMaterialClass({
                    ...tempMaterialClass,
                    pricePerWeight: formattedValue,
                    updHistory: [
                      {
                        updId: 0,
                        updateDate: dayjs().format('YYYY-MM-DD'),
                        updPrice: formattedValue,
                      },
                    ],
                  });
                }}
                prefix="¥"
                decimalsLimit={100}
                className="currencyInput"
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">作成日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{tempMaterialClass.created}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">更新日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{tempMaterialClass.modified}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">備考</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <TextArea
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.remark}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );

  const onSelectedRowKeysChange = (checked, record, index) => {
    let selectedRowKeys = [...checked];
    setCheckedRowKeys(selectedRowKeys);
    setChkRowsCount(selectedRowKeys.length);
  };

  const rowSelection = {
    onChange: onSelectedRowKeysChange,
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const gpEditModalContent = (
    <Form>
      <Row>
        <Col span="13">
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            components={components}
            dataSource={filteredData}
            columns={tableColumns}
            scroll={{ y: 480, x: '50vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
          />
        </Col>
        <Col span="11">
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td className="param-detail-tbl-val-title">表示</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="displayFlag"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                        }
                      }}
                      value={tempMaterialClass.displayFlag}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">材質名称</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, materialName: e });
                        }
                      }}
                      id="materialClassNameDDL"
                      name="materialClassNameDDL"
                      /* value={tempMaterialClass.materialName} */
                      defaultValue="defaultValue"
                    >
                      <Option id="defaultValue" value="defaultValue">
                        材質名称を選択してください。
                      </Option>
                      {getZaishittsuNameDDL().map((item) => (
                        <Option id={'materialRef' + item.value} value={item.label}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">板厚</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <Input
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={tempMaterialClass.thickness}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, thickness: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="param-detail-tbl-val-title">重量単価</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <CurrencyInput
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={tempMaterialClass.pricePerWeight}
                      onValueChange={(e) => {
                        const value = e;
                        let formattedValue = String(value);
                        if (value === '' || value === undefined) {
                          formattedValue = 0;
                        }
                        if (value.startsWith('¥', 0)) {
                          formattedValue = formattedValue.replace('¥', '');
                        }
                        formattedValue = formattedValue.replace(/,/g, '');

                        setTempMaterialClass({
                          ...tempMaterialClass,
                          pricePerWeight: formattedValue,
                        });

                        setTempMaterialClass({
                          ...tempMaterialClass,
                          pricePerWeight: formattedValue,
                          updHistory: [
                            {
                              updId: 0,
                              updateDate: dayjs().format('YYYY-MM-DD'),
                              updPrice: formattedValue,
                            },
                          ],
                        });
                      }}
                      prefix="¥"
                      decimalsLimit={100}
                      className="currencyInput"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">備考</td>
                  <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                    <TextArea
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={tempMaterialClass.remark}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
          更新
        </Button>
        <Button className="cancelButton" onClick={groupEditCancel}>
          破棄
        </Button>
      </Row>
    </Form>
  );

  const onRowSelect = (record, index) => {
    setChangeRecordKey(record.no);
    //setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      setEditModeCancelConfirm(true);
    } else {
      setSelectedRowKeys(record.no);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq3.materialName');
    }
  };

  const handleOk = (e) => {
    setUpdateConfirm(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
  };

  const handleCancel = (e) => {
    setCancelConfirm(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setVisibleCSVInput(false);
    setVisibleXMLInput(false);
    setUpdateCancelConfirm(false);
  };

  const yenToNum = (value) => {
    let formattedValue;
    if (value === '' || value === undefined) {
      formattedValue = 0;
    }
    if (value.startsWith('¥', 0)) {
      formattedValue = formattedValue.replace('¥', '');
    }
    formattedValue = formattedValue.replace(/,/g, '');
    return parseFloat(formattedValue);
  };

  const gpEditHistory = (obj) => {
    let numKey = { thickness, pricePerWeight };
    for (let key in numKey) {
      tempMaterialClass[key] = parseFloat(tempMaterialClass[key]);
      if (key === 'pricePerWeight') {
        tempMaterialClass[pricePerWeight] = yenToNum[tempMaterialClass[pricePerWeight]];
      }
    }
    if (
      obj.displayFlag !== tempMaterialClass.displayFlag ||
      (tempMaterialClass.materialName !== '' && obj.materialName !== tempMaterialClass.materialName) ||
      (tempMaterialClass.thickness !== 0 && obj.thickness !== tempMaterialClass.thickness) ||
      (tempMaterialClass.pricePerWeight !== 0 && obj.pricePerWeight !== tempMaterialClass.pricePerWeight) ||
      (tempMaterialClass.remark !== '' && obj.remark !== tempMaterialClass.remark)
    ) {
      if (tempMaterialClass.pricePerWeight !== 0 && obj.pricePerWeight !== tempMaterialClass.pricePerWeight) {
        obj.updHistory = updHistoryAdd(obj.updHistory, tempMaterialClass.pricePerWeight);
      }
      obj.modified = dayjs(dayjs()).format('YYYY-MM-DD');
    }
  };

  const updHistoryAdd = (updHistoryObj, pricePerWeight) => {
    let unitPriceHistory = JSON.parse(JSON.stringify(updHistoryObj));
    unitPriceHistory.push({
      updId: unitPriceHistory.length,
      updateDate: dayjs(dayjs()).format('YYYY-MM-DD'),
      updPrice: parseFloat(pricePerWeight),
    });
    return unitPriceHistory;
  };

  const updConfirmOk = (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialNameData));
    if (visibleAddNewModal) {
      stateData.push(tempMaterialClass);
      localStorage.setItem('materialNameStorage', JSON.stringify(stateData));
      setTmpFilteredData(stateData);
      setMaterialNameData(stateData);
      resetAddModal(stateData.length + 1);
      //onRowSelect(tempMaterialClass);
      setSelectedRowKeys(tempMaterialClass.no);
      setSelectedData(tempMaterialClass);
      setItemNo(tempMaterialClass.no);
      setCurrentPage(Math.ceil(tempMaterialClass.no / 25));
      setVisibleAddNewModal(false);
    } else if (visibleGroupEditModal) {
      if (checkedRowKeys.length > 0) {
        const updatedData = stateData.map((obj) => {
          if (checkedRowKeys.includes(obj.key)) {
            gpEditHistory(obj);
            return {
              ...obj,
              displayFlag: tempMaterialClass.displayFlag,
              materialName: tempMaterialClass.materialName === '' ? obj.materialName : tempMaterialClass.materialName,
              thickness: tempMaterialClass.thickness === 0 ? obj.thickness : tempMaterialClass.thickness,

              pricePerWeight:
                tempMaterialClass.pricePerWeight === 0 ? obj.pricePerWeight : tempMaterialClass.pricePerWeight,
              remark: tempMaterialClass.remark === '' ? obj.remark : tempMaterialClass.remark,
            };
          }
          return obj;
        });
        localStorage.setItem('materialNameStorage', JSON.stringify(updatedData));
        setTmpFilteredData(updatedData);
        setMaterialNameData(updatedData);
      }
      setVisibleGroupEditModal(false);
    }
    setUpdateCancelConfirm(false);
    setIsFilter(true);
  };

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  const handleGpEdit = () => {
    setTempMaterialClass({
      displayFlag: true,
      materialName: '',
      thickness: 0,

      pricePerWeight: 0,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [{ updId: 0, updateDate: releaseDate, updPrice: 120 }],
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
    }
  };

  const updConfirmCancel = () => {
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      updateEditMode(false);
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
    /* setVisibleGroupEditModal(true); */
  };

  const showDeleteModal = () => {
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = () => {
    setVisibleDeleteModal(false);
    let data = [...filteredData];
    data = data.filter((item) => item.key != selectedData.key);
    for (let i = 0; i < data.length; i++) {
      data[i].no = i + 1;
    }
    setMaterialNameData(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    localStorage.setItem('materialNameStorage', JSON.stringify(data));
    setSelectedRowKeys(data.length > 0 ? data[0].no : -1);
    setSelectedData(data.length > 0 ? data[0] : []);
    setIsFilter(true);
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      updateEditMode(false);
      setEditModeCancelConfirm(true);
    } else {
      handleAddFormSubmit();
    }
  };

  const handleAddFormSubmit = () => {
    let count = materialNameData.length;
    let edtDateHistory = dayjs().format('YYYY-MM-DD');
    let edtPriceHistory = 0;
    setTempMaterialClass({
      key: count,
      no: count + 1,
      displayFlag: true,
      detailMaterialName: '',
      materialName: '',
      thickness: '',
      pricePerWeight: edtPriceHistory,
      remark: '',
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
      updHistory: [
        {
          updId: 0,
          updateDate: edtDateHistory,
          updPrice: edtPriceHistory,
        },
      ],
    });
    setZairyoName('');
    setVisibleAddNewModal(true);
    setChildTitle('材料名称');
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
    /* setVisibleAddNewModal(false); */
  };

  const resetAddModal = (count) => {
    setTempMaterialClass({
      key: count,
      displayFlag: true,
      classificationCode: (count + 1).toString().padStart(3, '0'),
      classificationName: '',

      materialRefPrice: '',
      remark: '',
    });
    setZairyoName('');
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, modelKeyword, dataState, showFlag, tmpFilteredData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordKey !== undefined) {
        setSelectedRowKeys(changeRecordKey);
        setSelectedData(filteredData.find((item) => item.no === changeRecordKey));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordKey === undefined) {
        return;
      } else {
        const action = actionArr.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5].includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        //searchByKeyword
        case 'object':
          action = actionArr[1];
          break;
        //材質ddl
        case 'string':
          action = actionArr[4];
          break;
        //表示.非表示
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // 無効フラグの押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    console.log(e);
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialNameData.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let currentData = [...materialNameData];
    let materialFilterData = currentData;
    let flag;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData.filter((item) => item.materialName?.toString().indexOf(nKeyword) >= 0);
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData.filter((item) => item.displayFlag === flag);
    }

    //参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData.filter((item) => item.materialName === dataState);
    }

    /*let index = materialFilterData.findIndex((item) => item.no === selectedRowKeys);

     if (index === -1) {
      if (editMode) {
     
        setEditModeCancelConfirm(true);
        materialFilterData.length > 0 && setChangeRecordKey(materialFilterData[materialFilterData?.length - 1].no);
        return;
      }
      // 検索結果がある場合、
      if (materialFilterData.length > 0) {
        index = materialFilterData.length - 1;
        setSelectedRowKeys(materialFilterData[index]?.no);
        setSelectedData(materialFilterData[index]);
      }
    }
    if (materialFilterData.length > 0 && isFilter) {
    
      setSelectedRowKeys(materialFilterData[index].no);
      setSelectedData(materialFilterData[index]);
    } else if (materialFilterData.length <= 0) {
      setSelectedData([]);
    } */
    setFilteredData(materialFilterData);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = (updatedData) => {
    var tmpData = [...materialNameData];
    var index = tmpData.findIndex((item) => item.key === updatedData.key);
    tmpData[index] = updatedData;
    setMaterialNameData(tmpData);
    setTmpFilteredData(tmpData);
    setSelectedRowKeys(updatedData.no);
    setSelectedData(updatedData);
    setIsFilter(false);
    localStorage.setItem('materialNameStorage', JSON.stringify(tmpData));
  };

  const handleChangeUnitPrice = () => {
    let priceUpdData = filteredData.map((item) => {
      let updatedPrice;

      if (plusMinusAct === '+') {
        updatedPrice = parseFloat(item.pricePerWeight) + unitPrice;
      } else if (plusMinusAct === '-') {
        updatedPrice = parseFloat(item.pricePerWeight) - unitPrice;
        if (updatedPrice < 0) {
          updatedPrice = 0;
        }
      }

      return {
        ...item,
        pricePerWeight: updatedPrice,
        updHistory: updHistoryAdd(item.updHistory, updatedPrice),
        modified: dayjs().format('YYYY-MM-DD'),
      };
    });

    let nameData = [...materialNameData];

    nameData.forEach((firstObj) => {
      const secondObj = priceUpdData.find((secondObj) => secondObj['key'] === firstObj['key']);
      if (secondObj) {
        Object.assign(firstObj, secondObj);
      }
    });
    setFilteredData(priceUpdData);
    setTmpFilteredData(nameData);
    setMaterialNameData(nameData);
    localStorage.setItem('materialNameStorage', JSON.stringify(nameData));
    /*onRowSelect(selectedData, itemNo);*/
    setIsFilter(true);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
        className="materialName"
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            <>
              <Space size="middle">
                <Select
                  style={{ width: 90 }}
                  onChange={beforeSearch}
                  placeholder="表示・非表示"
                  id="showFlgDrop"
                  name="showFlgDrop"
                  value={showFlag}
                >
                  {dataStateList.map((item) => (
                    <option id={'showFlg' + item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <Select
                  style={{ width: 170 }}
                  onChange={beforeSearch}
                  id="materialRefDDL"
                  name="materialRefDDL"
                  value={dataState}
                  defaultValue={'全て'}
                >
                  <Option id="allOption" value={'全て'}>
                    {'全ての材質名称'}
                  </Option>
                  {getZaishittsuNameDDL().map((item) => (
                    <Option id={'materialClassName' + item.value} value={item.label}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Space>
              <Space className="unitPriceAction" size="middle" style={{ gap: 0, position: 'absolute', right: 0 }}>
                <label>重量単価更新：</label>
                <div className="containerStyle">
                  <Select
                    defaultValue={'+'}
                    id="plusMinusDDL"
                    name="plusMinusDDL"
                    className={plusMinusAct === '-' ? 'plusMinusDDL minus' : 'plusMinusDDL'}
                    value={plusMinusAct}
                    onChange={(e) => {
                      setPlusMinusAct(e);
                    }}
                  >
                    {plusMinus.map((item, index) => (
                      <Option id={'plusMinus' + item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                  <CurrencyInput
                    name="unitPrice"
                    id="unitPrice"
                    style={{ width: 80, border: 'none', textAlign: 'center' }}
                    value={unitPrice}
                    type="text"
                    prefix="¥"
                    decimalsLimit={100}
                    className="currencyInput"
                    onValueChange={(e) =>
                      setUnitPrice(() => {
                        let value = e;
                        let formattedValue = value;

                        if (e == '' || e === undefined) {
                          formattedValue = 0;
                        } else {
                          if (value.startsWith('¥', 0)) {
                            formattedValue = formattedValue.replace('¥', '');
                          }
                        }
                        return parseFloat(formattedValue);
                      })
                    }
                  />
                </div>
                <SyncOutlined className="submitUnitPrice" style={{ margin: '0 5' }} onClick={handleChangeUnitPrice} />
              </Space>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 10 }}>
                <Table
                  showSorterTooltip={false}
                  columns={hasCheckData ? tableColumns : chkData}
                  components={components}
                  dataSource={filteredData}
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record.no == selectedRowKeys ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.no}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    defaultPageSize: 25,
                    defaultCurrent: 1,
                    pageSize: 25,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  className="param_tbListShow"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                />
              </Row>
            </>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq3.materialName"
                element={
                  <MaterialNameDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={getMaterialNameStorage().find((element) => element.no === selectedRowKeys)}
                    itemNo={itemNo}
                    /*selectedData={selectedData}
                     originData={materialNameDataSample} */
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材料名称（選択したデータをまとめて編集）', 1790, -24),
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          true
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal
        ? commonModal(
            visibleAddNewModal,
            modalTitle('材料名称（新規追加）', 800, -24),
            commonActionFooter(addOK, addCancel, '登録', '破棄'),
            null,
            800,
            handleOk,
            handleCancel,
            addModalContent,
            'addNewMaterialClassModal',
            null,
            false
          )
        : ''}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialNameList;
