import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input, Checkbox } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const types = [
  { id: 1, value: 'バーリング', label: 'バーリング' },
  { id: 2, value: 'タップ', label: 'タップ' },
  { id: 3, value: 'サラ', label: 'サラ' },
  { id: 4, value: 'カシメ', label: 'カシメ' },
  { id: 5, value: 'ボルト圧入', label: 'ボルト圧入' },
  { id: 6, value: 'ナット圧入', label: 'ナット圧入' },
  { id: 7, value: '成形型', label: '成形型' },
  { id: 8, value: '多工程穴', label: '多工程穴' },
];
const IQ3KouteiInput_2Jikakou = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);
  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    setListData(props.secondProcessTblVal);
  }, [props.secondProcessTblVal]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const addRow = (id) => {
    const copyData = listData.slice(); // Create a copy of the original array

    const insertIndex = listData.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = listData.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: '',
        xsize: '',
        ysize: '',
        perimeter: '',
        count: '',
        blankFlag: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setListData(copyData);
    }
  };
  const deleteRow = (id) => {
    if (listData.length > 1) {
      const updatedData = listData.filter((item) => item.id !== id);

      setListData(updatedData);
    }
  };

  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(listData));
    if (types === 'types') {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
      setListData(updatedData);
    } else if (types === 'blankFlag') {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.checked };
        }
        return row;
      });
      setListData(updatedData);
    } else {
      const updatedData = temp.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
      setListData(updatedData);
    }
  };
  return (
    <>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '84%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                ブランク
              </th>
            </tr>
          </thead>
          <tbody>
            {listData.map((i, index) => (
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <>
                      <Select
                        defaultValue={1}
                        size="middle"
                        value={i.types}
                        onChange={(e) => changeDetails(e, i.id, 'types')}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                      >
                        {types.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>{i.types}</>
                  )}
                </td>

                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <Checkbox
                    checked={i.blankFlag}
                    onChange={(e) => changeDetails(e, i.id, 'blankFlag')}
                    style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                  ></Checkbox>
                </td>
                {editMode ? (
                  <>
                    <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                      <Row justify="center">
                        <PlusCircleFilled
                          className="add-remove-icon"
                          onClick={() => addRow(i.id)}
                          style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                        <CloseCircleFilled
                          className="add-remove-icon"
                          onClick={() => deleteRow(i.id)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                      </Row>
                    </td>
                  </>
                ) : (
                  <>
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_2Jikakou;
