import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Checkbox,
  Space,
  Image,
  Tooltip,
  Menu,
  Upload,
  Radio,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '../../assets/styles/common.css';
import { RightCircleFilled, LeftCircleFilled, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import ParentListDetail from './ParentListDetail';

import { WithRouter } from '../../components/WithRouter';
import '../../assets/styles/common.css';
import new_icon from '../../assets/images/new_icon.png';
import child_delete_icon from '../../assets/images/child_delete_icon.png';
import call_detail from '../../assets/images/call_detail.png';
import export_csv_icon from '../../assets/images/export_csv_icon.png';
import copy from '../../assets/images/copy.png';
import jisen_register from '../../assets/images/jisen_register.png';
import detail_confirm from '../../assets/images/detail_confirm.png';
import display_setting from '../../assets/images/display_setting.png';
import detail_display from '../../assets/images/detail_display.png';
import edit_list from '../../assets/images/edit_list.png';
import search_list from '../../assets/images/search_list.png';
import export_report from '../../assets/images/export_report.png';
import analyze from '../../assets/images/analyze.png';
import '../../assets/styles/common.css';
import { Route, Routes } from 'react-router-dom';
// Content resize
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { Table as RTable } from 'react-bootstrap';
import Sorting from '../common/Sorting';
import KouteibetsuInfo from './KouteibetsuInfo';
import { getTenjikaiSampleData } from '../common/Common';
import { useNavigate } from 'react-router-dom';
import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
} from '../common/CommonModal';
import { arrayMoveImmutable } from 'array-move';
import filter_clear_icon from '../../assets/images/filter_clear_icon.png';
import { JPYs, getNewParentInfo } from '../common/Common.js';
import { getEstimateStatusData } from '../common/CommonAPI';

dayjs.extend(customParseFormat);
const iconWidth = 30;
const now = new Date();
const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
const { Search } = Input;

const ParentList = forwardRef((props, ref) => {
  const [hasData, setHasData] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form] = Form.useForm();

  const [selectedData, setSelectedData] = React.useState(0);

  const [hasCheckData, setCheckData] = useState(true);

  const [childTitle, setChildTitle] = useState('子部品確認');

  const [isCopy, setIsCopy] = useState(false);

  // 表示設定
  const [isDisplay, setIsDisplay] = useState(false); // ポップアップ表示／未表示
  const [value, setValue] = useState('1');
  const [pageSize, setPageSize] = useState(25);
  const [chkData, setChkData] = useState([]); // ポップアップでチェックしている表示する項目

  // csv export
  const [csvExportData, setCSVExportData] = useState(0);
  const [initialTblItems, setInitialTableItems] = useState(0); // 初期表示に表示する項目
  const [initialTblItemsBk, setInitialTableItemsBk] = useState(0); // 初期表示に表示する項目
  const [olddata, setOldData] = useState(0);

  // まとめて編集
  const [isUpdate, setIsUpdate] = useState(false);
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [updateItem, setUpdateItem] = useState([]);

  // 子部品確認
  const [isChildOpen, setIsChildOpen] = useState(false);

  // 加工確認
  const [isManufactureOpen, setIsManufactureOpen] = useState(false);

  const [selectedEstimateData, setSelectedEstimateData] = useState([]);

  // 削除
  const [isDelete, setIsDelete] = useState(false);

  // 帳票出力
  const [isReportOutputOpen, setIsReportOutputOpen] = useState(false);

  // リスト出力
  const [isListOutputOpen, setIsListOutputOpen] = useState(false);

  // 事前登録
  const [preRegister, setPreRegister] = useState(false);
  // 絞込み設定
  const [isFilter, setIsFilter] = useState(false);
  //  全てクリア
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);

  const [isUpdateData, setIsUpdateData] = useState(false);

  // 絞込み破棄
  const [isDiscardData, setIsDiscardData] = useState(false);

  // add row
  const [rows, initRow] = useState([]);

  const [customerRdo, setCustomerRdo] = useState(2);

  // Content Resize
  const [sizes, setSizes] = useState(['50%']);
  const [btnShowHide, setBtnShowHide] = useState(true);

  const [sortingData, setSortingData] = useState([]);

  const [datesData, setDatesData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const [memoData, setMemoData] = useState([]);
  const [costData, setCostData] = useState([]);

  // 検索アリア
  const [customer, setCustomer] = useState('');
  const [imageNum, setImageNum] = useState('');
  const [name, setName] = useState('');
  const [keyword, setKeywords] = useState('');

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  const [confirmScreenChangeModalOpen, setConfirmScreenChangeModalOpen] = useState(false);

  const [clickedAction, setClickedAction] = useState(0);

  // 子部品確認画面のselected key
  const [childSelectedKey, setChildSelectedKey] = useState(0);

  const [page, setPage] = useState(1);

  const [orderOptions, setOrderOptions] = useState([]);

  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);

  const navigate = useNavigate();

  const sortingRef = useRef();

  const customersCode = [
    '00001',
    '00002',
    '0003',
    '0004',
    '0005',
    '0006',
    '0007',
    '0008',
    '0009',
    '00015',
    '00016',
    '00017',
    '00018',
    '00019',
    '00020',
    '00021',
    '00022',
    '00023',
    '00024',
    '00025',
    '00026',
    '00027',
    '00028',
    '00029',
    '00030',
    '00031',
    '00032',
    '00033',
    '00034',
    '00035',
    '00036',
    '00037',
    '00038',
    '00039',
    '00040',
    '00041',
    '00012',
  ];
  const companies = [
    {
      key: 1,
      id: 1,
      no: 1,
      code: `00001`,
      brunchNo: `000`,
      companyName: `株式会社テクノロジー・ソリューションズ`,
      companyNameKata: `カブシキガイシャテクノロジー・ソリューションズ`,
      coefficient: 1.0,
      tel: `03-1234-5678`,
      fax: `03-1234-5678`,
      tantou: `田中`,
      prefectures: `東京都千代田区`,
      mail: ``,
      postalCode: `100-0005`,
      address: `東京都千代田区丸の内1-5-1 丸の内ビルディング17階`,
      remarks: `特になし`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2023-04-01`,
      updated: `2023-04-01`,
    },
    {
      key: 2,
      id: 2,
      no: 2,
      code: `00001`,
      brunchNo: `001`,
      companyName: `株式会社テクノロジー・ソリューションズ(大阪)`,
      companyNameKata: `カブシキガイシャテクノロジー・ソリューションズ`,
      coefficient: 1.0,
      tel: `03-1234-5678`,
      fax: `03-1234-5678`,
      tantou: `田中`,
      prefectures: `大阪府大阪市`,
      mail: ``,
      postalCode: `530-0001`,
      address: `大阪府大阪市北区梅田2-1-1 大阪駅前第3ビル21階`,
      remarks: `大阪支店`,
      invalidFlg: 0,
      sortNo: 0,
      created: '2023-04-01',
      updated: '2023-04-01',
    },
    {
      key: 3,
      id: 3,
      no: 3,
      code: `00002`,
      brunchNo: `000`,
      companyName: `株式会社グローバル・インダストリーズ`,
      companyNameKata: `カブシキガイシャグローバル・インダストリーズ`,
      coefficient: 1.0,
      tel: `03-2345-6789`,
      fax: `03-2345-6789`,
      tantou: `山田`,
      prefectures: `東京都新宿区`,
      mail: ``,
      postalCode: `160-0022`,
      address: `東京都新宿区新宿3-38-1 新宿フロントタワー西館33階`,
      remarks: '本社',
      invalidFlg: 0,
      sortNo: 0,
      created: '2023-04-01',
      updated: '2023-04-01',
    },
    {
      key: 4,
      id: 4,
      no: 4,
      code: `00002`,
      brunchNo: `001`,
      companyName: `株式会社グローバル・インダストリーズ(名古屋)`,
      companyNameKata: `カブシキガイシャグローバル・インダストリーズ`,
      coefficient: 1.0,
      tel: `03-2345-6789`,
      fax: `03-2345-6789`,
      tantou: `山田`,
      prefectures: `愛知県名古屋市`,
      mail: ``,
      postalCode: `460-0008`,
      address: `愛知県名古屋市中区栄4-4-20 伏見フロア21 12階`,
      remarks: `名古屋支店`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2023-04-01`,
      updated: `2023-04-01`,
    },
    {
      key: 5,
      id: 5,
      no: 5,
      code: '0003',
      brunchNo: '000',
      companyName: '株式会社フューチャーテック',
      companyNameKata: 'カブシキガイシャフューチャーテック',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '佐藤',
      prefectures: '東京都中央区',
      mail: 'tanaka@example.com',
      postalCode: '104-8137',
      address: '東京都中央区晴海1-8-1 ららぽーと東京ベイ北館22階',
      remarks: '本社',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },
    {
      key: 6,
      id: 6,
      no: 6,
      code: '0003',
      brunchNo: '001',
      companyName: '株式会社フューチャーテック(京都支店)',
      companyNameKata: 'カブシキガイシャフューチャーテック',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '佐藤',
      prefectures: '京都府京都市区',
      mail: 'tanaka@example.com',
      postalCode: '604-8135',
      address: '京都府京都市中京区烏丸通六角下る塩小路町97-1 京都駅前ビル18階',
      remarks: '京都支店',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },
    {
      key: 7,
      id: 7,
      no: 7,
      code: '0004',
      brunchNo: '000',
      companyName: '株式会社ユニバーサル・コンプライアンス',
      companyNameKata: 'カブシキガイシャユニバーサル・コンプライアンス',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '伊藤',
      prefectures: '東京都中央区',
      mail: '',
      postalCode: '160-0022',
      address: '東京都新宿区新宿3-38-1 新宿フロントタワー西館28階',
      remarks: '本社',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },

    {
      key: 9,
      id: 9,
      no: 9,
      code: '0005',
      brunchNo: '000',
      companyName: '株式会社金属テック',
      companyNameKata: 'カブシキガイシャキンゾクテック',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '山田',
      prefectures: '大阪府大阪市',
      mail: '',
      postalCode: '530-0001',
      address: '大阪府大阪市北区梅田2-5-25 大阪駅前第2ビル10階',
      remarks: '',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },
    {
      key: 10,
      id: 10,
      no: 10,
      code: '0006',
      brunchNo: '000',
      companyName: '株式会社アルミテック',
      companyNameKata: 'カブシキガイシャアルミテック',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '田中',
      prefectures: '愛知県名古屋市',
      mail: '',
      postalCode: '460-0008',
      address: '愛知県名古屋市中区栄4-4-20 伏見フロア21 12階',
      remarks: '',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },

    {
      key: 12,
      id: 12,
      no: 12,
      code: '0007',
      brunchNo: '000',
      companyName: '株式会社スチールテック',
      companyNameKata: 'カブシキガイシャスチールテック',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '伊藤',
      prefectures: '東京都千代田区',
      mail: '',
      postalCode: '100-0005 ',
      address: '東京都千代田区丸の内1-8-3 丸の内トラストタワー本館9階',
      remarks: '本社',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },

    {
      key: 14,
      id: 14,
      no: 14,
      code: '0008',
      brunchNo: '000',
      companyName: '株式会社メタルワークス',
      companyNameKata: 'カブシキガイシャメタルワークス',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '伊藤',
      prefectures: '北海道札幌市',
      mail: '',
      postalCode: '060-0005',
      address: '北海道札幌市中央区南5条西4丁目2-1 札幌三越本店7階',
      remarks: '',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },
    {
      key: 15,
      id: 15,
      no: 15,
      code: '0009',
      brunchNo: '000',
      companyName: '株式会社鉄鋼テクノ',
      companyNameKata: 'カブシキガイシャテッコウテクノ',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '佐々木',
      prefectures: '福岡県福岡市',
      mail: '',
      postalCode: '810-0001 ',
      address: '福岡県福岡市中央区天神1-12-1 天神ビルディング12階',
      remarks: '',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },
    {
      key: 15,
      id: 15,
      no: 15,
      code: `00015`,
      brunchNo: `000`,
      companyName: `株式会社アイアン`,
      companyNameKata: `カブシキガイシャアイアン`,
      coefficient: 1.0,
      tel: `090-1234-5678`,
      fax: `090-1234-5679`,
      tantou: `田中`,
      prefectures: `東京都千代田区`,
      mail: `tanaka@iron.co.jp`,
      postalCode: `100-0001`,
      address: `東京都千代田区丸の内1-1-1`,
      remarks: `特になし`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2023-04-01`,
      updated: `2023-04-01`,
    },
    {
      key: 16,
      id: 16,
      no: 16,
      code: `00016`,
      brunchNo: `000`,
      companyName: `株式会社ステンレスワークス`,
      companyNameKata: `カブシキガイシャステンレスワークス`,
      coefficient: 1.0,
      tel: `090-2345-6789`,
      fax: `090-2345-6790`,
      tantou: `山本`,
      prefectures: `神奈川県横浜市`,
      mail: `yamamoto@stainless-works.co.jp`,
      postalCode: `220-0001`,
      address: `神奈川県横浜市中区本町1-1-1`,
      remarks: `加工物によっては、納期に余裕を持っていただけると助かります。`,
      invalidFlg: 1,
      sortNo: 0,
      created: `2023-04-02`,
      updated: `2023-04-03`,
    },
    {
      key: 17,
      id: 17,
      no: 17,
      code: `00017`,
      brunchNo: `000`,
      companyName: `株式会社鉄工所`,
      companyNameKata: `カブシキガイシャテッコウジョ`,
      coefficient: 1.0,
      tel: `090-3456-7890`,
      fax: `090-3456-7891`,
      tantou: `佐藤`,
      prefectures: `大阪府大阪市`,
      mail: `sato@tekko.co.jp`,
      postalCode: `530-0001`,
      address: `大阪府大阪市北区梅田1-1-1`,
      remarks: `鉄の加工が得意です。数量によっては割引がありますので、お問い合わせください。`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2023-04-04`,
      updated: `2023-04-04`,
    },
    {
      key: 18,
      id: 18,
      no: 18,
      code: '00018',
      brunchNo: '000',
      companyName: '株式会社山田金属加工',
      companyNameKata: 'ヤマダキンゾクカコウ',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '山田',
      prefectures: '東京都千代田区',
      mail: 'yamada@metalworks.com',
      postalCode: '100-0001',
      address: '東京都千代田区千代田1-1',
      remarks: '金属板加工を得意とする。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-04-05',
    },
    {
      key: 19,
      id: 19,
      no: 19,
      code: '00019',
      brunchNo: '000',
      companyName: '有限会社鈴木金属工業',
      companyNameKata: 'スズキキンゾクコウギョウ',
      coefficient: 1.0,
      tel: '03-2345-6789',
      fax: '03-2345-6790',
      tantou: '鈴木',
      prefectures: '東京都中央区',
      mail: 'suzuki@metalworks.com',
      postalCode: '104-0061',
      address: '東京都中央区銀座1-1',
      remarks: '自動車部品の製造を得意とする。',
      invalidFlg: 1,
      sortNo: 0,
      created: '2022-03-15',
      updated: '2022-04-03',
    },
    {
      key: 20,
      id: 20,
      no: 20,
      code: '00020',
      brunchNo: '000',
      companyName: '株式会社田中製作所',
      companyNameKata: 'タナカセイサクショ',
      coefficient: 1.0,
      tel: '03-3456-7890',
      fax: '03-3456-7891',
      tantou: '田中',
      prefectures: '東京都港区',
      mail: 'tanaka@metalworks.com',
      postalCode: '108-0075',
      address: '東京都港区港南2-16-4',
      remarks: '航空機部品の製造を得意とする。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-02-20',
      updated: '2022-03-10',
    },
    {
      key: 21,
      id: 21,
      no: 21,
      code: '00021',
      brunchNo: '012',
      companyName: '株式会社鉄工所',
      companyNameKata: 'カブシキガイシャテッコウジョ',
      coefficient: 1.0,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '田中',
      prefectures: '東京都港区',
      mail: 'tanaka@example.com',
      postalCode: '123-4567',
      address: '東京都港区南青山1-2-3',
      remarks: '鉄とアルミの切断加工を得意としています。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-01',
      updated: '2022-05-20',
    },
    {
      key: 22,
      id: 22,
      no: 22,
      code: '00022',
      brunchNo: '000',
      companyName: '鋼板加工株式会社',
      companyNameKata: 'コウハンカコウカブシキガイシャ',
      coefficient: 1.0,
      tel: '03-2345-6789',
      fax: '03-2345-6790',
      tantou: '山田',
      prefectures: '東京都千代田区',
      mail: 'yamada@example.com',
      postalCode: '123-4567',
      address: '東京都千代田区神田神保町1-2-3',
      remarks: '鋼板の切断や曲げ加工を行います。',
      invalidFlg: 1,
      sortNo: 0,
      created: '2022-04-02',
      updated: '2022-05-19',
    },
    {
      key: 23,
      id: 23,
      no: 23,
      code: '00023',
      brunchNo: '000',
      companyName: '株式会社金属製作所',
      companyNameKata: 'カブシキガイシャキンゾクセイサクショ',
      coefficient: 1.0,
      tel: '03-3456-7890',
      fax: '03-3456-7891',
      tantou: '佐藤',
      prefectures: '東京都中央区',
      mail: 'sato@example.com',
      postalCode: '123-4567',
      address: '東京都中央区日本橋1-2-3',
      remarks: 'オーダーメイドの金属製品を制作します。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2022-04-03',
      updated: '2022-05-18',
    },
    {
      key: 24,
      id: 24,
      no: 24,
      code: `	00024`,
      brunchNo: `000`,
      companyName: `株式会社サカモト`,
      companyNameKata: `カブシキガイシャサカモト`,
      coefficient: 1.0,
      tel: `012-345-6789`,
      fax: `012-345-6789`,
      tantou: `高橋`,
      prefectures: `東京都江東区`,
      mail: `takahashi@metal.jp`,
      postalCode: `135-0021`,
      address: `東京都江東区木場1-2-3`,
      remarks: `特注の小ロット生産にも対応可能です。`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2022-03-05`,
      updated: `2022-05-12`,
    },
    {
      key: 25,
      id: 25,
      no: 25,
      code: `00025`,
      brunchNo: `000`,
      companyName: `株式会社ミツワ`,
      companyNameKata: `カブシキガイシャミツワ`,
      coefficient: 1.0,
      tel: `03-1234-5678`,
      fax: `03-1234-5678`,
      tantou: `田中`,
      prefectures: `東京都墨田区`,
      mail: `tanaka@mitsuwa.jp`,
      postalCode: `130-0022`,
      address: `東京都墨田区横川3-4-5`,
      remarks: `高精度・高品質な製品をリーズナブルな価格で提供します。`,
      invalidFlg: 1,
      sortNo: 0,
      created: `2022-02-15`,
      updated: `2022-05-01`,
    },
    {
      key: 26,
      id: 26,
      no: 26,
      code: `00026`,
      brunchNo: `000`,
      companyName: `株式会社タケヤ`,
      companyNameKata: `カブシキガイシャタケヤ`,
      coefficient: 1.0,
      tel: `03-9876-5432`,
      fax: `03-9876-5432`,
      tantou: `佐々木`,
      prefectures: `千葉県柏市`,
      mail: `sasaki@takeya.co.jp`,
      postalCode: `277-0005`,
      address: `千葉県柏市若柴1-2-3`,
      remarks: `板金加工や溶接加工など、幅広く対応しています。`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2022-01-10`,
      updated: `2022-04-23`,
    },
    {
      key: 27,
      id: 27,
      no: 27,
      code: `	00027`,
      brunchNo: `000`,
      companyName: `株式会社アクロ`,
      companyNameKata: `カブシキガイシャアクロ`,
      coefficient: 1,
      tel: `012-345-6789`,
      fax: `012-345-6789`,
      tantou: `高橋`,
      prefectures: `東京都千代田区`,
      mail: `takahashi@acro.co.jp`,
      postalCode: `100-0001`,
      address: `東京都千代田区丸の内1-1-1`,
      remarks: `特殊鋼、ステンレス、アルミ、チタン、銅等の材料に対して、板、棒、管などの金属材料を加工することが可能です。`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2023-04-25`,
      updated: `2023-04-26`,
    },
    {
      key: 28,
      id: 28,
      no: 28,
      code: `00028`,
      brunchNo: `000`,
      companyName: `株式会社ニッケン`,
      companyNameKata: `カブシキガイシャニッケン`,
      coefficient: 1,
      tel: `03-1234-5678`,
      fax: `03-1234-5678`,
      tantou: `山口`,
      prefectures: `東京都豊島区`,
      mail: `yamaguchi@nikken.co.jp`,
      postalCode: `170-0013`,
      address: `東京都豊島区池袋1-1-1`,
      remarks: `ニッケル銅合金における世界トップクラスの技術を持ち、実績豊富な特殊金属加工メーカーです。`,
      invalidFlg: 1,
      sortNo: 0,
      created: `2023-04-24`,
      updated: `2023-04-27`,
    },
    {
      key: 29,
      id: 29,
      no: 29,
      code: `	00029`,
      brunchNo: `000`,
      companyName: `株式会社ニチワ`,
      companyNameKata: `カブシキガイシャニチワ`,
      coefficient: 1,
      tel: `06-7890-1234`,
      fax: `06-7890-1234`,
      tantou: `中村`,
      prefectures: `大阪府大阪市北区`,
      mail: `nakamura@nichiwa.co.jp`,
      postalCode: `530-0011`,
      address: `大阪府大阪市北区梅田1-1-1`,
      remarks: `板金、機械加工、レーザー加工`,
      invalidFlg: 1,
      sortNo: 0,
      created: `2023-04-24`,
      updated: `2023-04-27`,
    },
    {
      key: 30,
      id: 30,
      no: 30,
      code: '00030',
      brunchNo: '000',
      companyName: '株式会社ヤマシタ',
      companyNameKata: 'カブシキガイシャヤマシタ',
      coefficient: 1,
      tel: '03-1234-5678',
      fax: '03-1234-5679',
      tantou: '山下',
      prefectures: '東京都中央区',
      mail: 'yamashita1@gmail.com',
      postalCode: '103-0028',
      address: '東京都中央区八重洲1-6-16',
      remarks: 'CNCマシンを多数保有しています。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2023-04-01',
      updated: '2023-04-01',
    },
    {
      key: 31,
      id: 31,
      no: 31,
      code: '00031',
      brunchNo: '000',
      companyName: '株式会社タカノ',
      companyNameKata: 'カブシキガイシャタカノ',
      coefficient: 1,
      tel: '06-2345-6789',
      fax: '06-2345-6790',
      tantou: '高野',
      prefectures: '大阪府堺市',
      mail: 'takano2@gmail.com',
      postalCode: '590-0104',
      address: '大阪府堺市中区大道東町4-12-6',
      remarks: '強度測定機を導入しました。',
      invalidFlg: 1,
      sortNo: 0,
      created: '2023-04-01',
      updated: '2023-04-02',
    },
    {
      key: 32,
      id: 32,
      no: 32,
      code: '00032',
      brunchNo: '000',
      companyName: '株式会社ニシムラ',
      companyNameKata: 'カブシキガイシャニシムラ',
      coefficient: 1,
      tel: '08-7654-3210',
      fax: '08-7654-3211',
      tantou: '西村',
      prefectures: '福岡県福岡市',
      mail: 'nishimura3@gmail.com',
      postalCode: '810-0022',
      address: '福岡県福岡市中央区天神1-14-10',
      remarks: '研削技術に特化しています。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2023-04-02',
      updated: '2023-04-02',
    },

    {
      key: 34,
      id: 34,
      no: 34,
      code: '00034',
      brunchNo: '000',
      companyName: '有限会社アルミ',
      companyNameKata: 'ユウゲンガイシャアルミ',
      coefficient: 1,
      tel: '06-1234-5678',
      fax: '06-1234-5679',
      tantou: '山田',
      prefectures: '大阪府大阪市中央区',
      mail: 'yamada@alumi.co.jp',
      postalCode: '541-0052',
      address: '大阪府大阪市中央区島之内1-15-16',
      remarks: 'アルミ製品の加工に特化している。',
      invalidFlg: 1,
      sortNo: 0,
      created: '2022-02-11',
      updated: '2022-06-04',
    },

    {
      key: 36,
      id: 36,
      no: 36,
      code: `00036`,
      brunchNo: `000`,
      companyName: `株式会社東京鉄工所`,
      companyNameKata: `カブシキガイシャ トウキョウ テッコウジョ`,
      coefficient: 1,
      tel: `03-1234-5678`,
      fax: `03-1234-5679`,
      tantou: `山田`,
      prefectures: `東京都中央区`,
      mail: `yamada@tokyo-tekkojo.com`,
      postalCode: `103-0012`,
      address: `東京都中央区日本橋本石町1-5-8`,
      remarks: `精密加工に強みを持つ`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2022-03-25`,
      updated: `2022-04-01`,
    },
    {
      key: 37,
      id: 37,
      no: 37,
      code: `00037`,
      brunchNo: `000`,
      companyName: `大阪製鋼所株式会社`,
      companyNameKata: `カブシキガイシャ オオサカ セイコウショ`,
      coefficient: 1,
      tel: `06-1234-5678`,
      fax: `06-1234-5679`,
      tantou: `佐藤`,
      prefectures: `大阪府吹田市`,
      mail: `sato@osaka-seikojo.com`,
      postalCode: `564-8680`,
      address: `大阪府吹田市南正雀1-11-39`,
      remarks: `鉄道車両部品の製造に特化`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2022-01-15`,
      updated: `2022-05-10`,
    },
    {
      key: 38,
      id: 38,
      no: 38,
      code: `00038`,
      brunchNo: `000`,
      companyName: `株式会社三菱重工業`,
      companyNameKata: `カブシキガイシャ ミツビシ ジュウコウギョウ`,
      coefficient: 1,
      tel: `03-3211-3311`,
      fax: `03-3211-3061`,
      tantou: `伊藤`,
      prefectures: `東京都千代田区`,
      mail: `ito@mhi.co.jp`,
      postalCode: `100-8310`,
      address: `東京都千代田区丸の内2-16-5`,
      remarks: `航空機エンジンなどの製造を手掛ける`,
      invalidFlg: 0,
      sortNo: 0,
      created: `2021-12-01`,
      updated: '2022-09-12',
    },
    {
      key: 39,
      id: 39,
      no: 39,
      code: '00039',
      brunchNo: '000',
      companyName: '株式会社ライトメタル',
      companyNameKata: 'カブシキガイシャライトメタル',
      coefficient: 1,
      tel: '080-1234-5678',
      fax: '080-1234-5679',
      tantou: '佐藤',
      prefectures: '北海道札幌市',
      mail: 'sato.lightmetal@gmail.com',
      postalCode: '060-0808',
      address: '北海道札幌市北区北8条西4丁目1-1',
      remarks: '自動車部品加工に特化しています。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2021-05-01',
      updated: '2022-09-12',
    },
    {
      key: 40,
      id: 40,
      no: 40,
      code: '00040',
      brunchNo: '000',
      companyName: '株式会社ヘヴィメタル',
      companyNameKata: 'カブシキガイシャヘヴィメタル',
      coefficient: 1,
      tel: '090-2345-6789',
      fax: '090-2345-6790',
      tantou: '田中',
      prefectures: '青森県青森市',
      mail: 'tanaka.heavymetal@gmail.com',
      postalCode: '030-0825',
      address: '青森県青森市東造道1丁目1-1',
      remarks: '鉄道部品加工を中心に事業を展開しています。',
      invalidFlg: 1,
      sortNo: 0,
      created: '2021-07-15',
      updated: '2023-02-23',
    },
    {
      key: 41,
      id: 41,
      no: 41,
      code: '00041',
      brunchNo: '000',
      companyName: '株式会社メタルマスター',
      companyNameKata: 'カブシキガイシャメタルマスター',
      coefficient: 1,
      tel: '03-4567-8901',
      fax: '03-4567-8902',
      tantou: '山田',
      prefectures: '東京都千代田区',
      mail: 'yamada.metalmaster@gmail.com',
      postalCode: '100-0001',
      address: '東京都千代田区千代田1-1',
      remarks: '高品質なアルミニウム製品を提供しています。',
      invalidFlg: 0,
      sortNo: 0,
      created: '2021-06-30',
      updated: '2022-08-20',
    },
    {
      key: 42,
      id: 42,
      no: 42,
      code: '00012',
      brunchNo: '000',
      companyName: '株式会社石田板金製作所',
      companyNameKata: 'カブシキガイシャイシダバンキンセイサクジョ',
      coefficient: 1,
      tel: '0297-21-6200',
      fax: '0297-21-6201',
      tantou: '石田',
      prefectures: '茨城県坂東市',
      mail: '',
      postalCode: '306-0515',
      address: '茨城県坂東市沓掛2000-1',
      remarks: 'R&Dセンター',
      invalidFlg: 0,
      sortNo: 0,
      created: '2021-06-30',
      updated: '2022-08-20',
    },
  ];
  const companiesName = companies.map((item) => item.companyName);
  const columnsone = [
    {
      id: '1',
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'descend',
      className: 'cm-a-right',
      width: 50,
      // render: (id, record, index) => {
      //   return (page - 1) * 25 + (index + 1);
      // },
    },

    {
      id: '2',
      title: 'お客様コード',
      dataIndex: 'customerCode',
      width: 95 + 'px',
    },

    {
      id: '3',
      title: 'お客様',
      dataIndex: 'customerNm',
      width: 250 + 'px',
    },
    {
      id: '4',
      title: '図番',
      dataIndex: 'imgNo',
      width: 200 + 'px',
    },
    {
      id: '5',
      title: '名称',
      dataIndex: 'name',
      width: 200 + 'px',
    },

    {
      id: '6',
      title: '数量',
      dataIndex: 'lotNum',
      width: 55 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.lotNum}</label>
        </div>
      ),
    },
    {
      id: '7',
      title: '粗利益',
      dataIndex: 'grossProfit',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.grossProfit != undefined
              ? '¥' +
                record.grossProfit.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },

    {
      id: '8',
      title: '粗利率',
      dataIndex: 'rate',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.rate != undefined
              ? record.rate.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                }) + '%'
              : '0%'}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: '見積単価',
      dataIndex: 'unitPrice',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.unitPrice != undefined
              ? '¥' +
                record.unitPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '10',
      title: '見積金額',
      dataIndex: 'estimatedAmount',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.estimatedAmount != undefined
              ? '¥' +
                record.estimatedAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '11',
      title: '材料原価',
      dataIndex: 'materialAmount',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.materialAmount != undefined
              ? '¥' +
                record.materialAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '12',
      title: '製造原価',
      dataIndex: 'matufacturingAmount',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.matufacturingAmount != undefined
              ? '¥' +
                record.matufacturingAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '13',
      title: '段取金額',
      dataIndex: 'setupAmount',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.setupAmount != undefined
              ? '¥' +
                record.setupAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '14',
      title: '加工金額',
      dataIndex: 'processingAmount',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.processingAmount != undefined
              ? '¥' +
                record.processingAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '15',
      title: '購入品費',
      dataIndex: 'purchaseCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.purchaseCost != undefined
              ? '¥' +
                record.purchaseCost.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '16',
      title: '管理費',
      dataIndex: 'managementCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.managementCost != undefined
              ? '¥' +
                record.managementCost.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },

    {
      id: '17',
      title: '受注ﾀｲﾌﾟ',
      dataIndex: 'isOrder',
      width: 100 + 'px',
    },

    {
      id: '18',
      title: '納期ﾀｲﾌﾟ',
      dataIndex: 'deliveryType',
      width: 100 + 'px',
    },
    {
      id: '19',
      title: 'Image',
      dataIndex: 'img',
      width: 100 + 'px',
    },
    {
      id: '20',
      title: '受注非受注',
      dataIndex: 'allowGaichu',
      // filters: [
      //   { text: '承認済', value: '承認済' },
      //   { text: '承認中', value: '承認中' },
      // ],
      // onFilter: (value, record) => record.isOrder.startsWith(value),
      // filterSearch: true,
      width: 100 + 'px',
    },
  ];
  const columnstwo = [
    {
      id: '1',
      title: 'サイズX',
      dataIndex: 'sizeX',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeX}</label>
        </div>
      ),
    },
    {
      id: '2',
      title: 'サイズY',
      dataIndex: 'sizeY',
      width: 120 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeY}</label>
        </div>
      ),
    },
    {
      id: '3',
      title: 'サイズZ',
      dataIndex: 'sizeZ',
      width: 120 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeZ}</label>
        </div>
      ),
    },
    {
      id: '4',
      title: '子部品総重量',
      dataIndex: 'childPartTotalWeight',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.childPartTotalWeight}</label>
        </div>
      ),
    },
    {
      id: '5',
      title: '板金重量',
      dataIndex: 'iq3Weight',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.iq3Weight}</label>
        </div>
      ),
    },
    // {
    //   id: '6',
    //   title: '形鋼重量',
    //   dataIndex: 'iq5Weight',
    //   width: 100 + 'px',
    //   render: (_, record) => (
    //     <div style={{ textAlign: 'right' }}>
    //       <label style={{ fontSize: '13px' }}>{record.iq5Weight}</label>
    //     </div>
    //   ),
    // },
    // {
    //   id: '7',
    //   title: '機械重量',
    //   dataIndex: 'iq7Weight',
    //   width: 120 + 'px',
    // },
    {
      id: '6',
      title: '子部品種類',
      dataIndex: 'childPartTypeCnt',
      width: 120 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.childPartTypeCnt}</label>
        </div>
      ),
    },
    {
      id: '7',
      title: '板金種類',
      dataIndex: 'iq3TypeCnt',
      width: 120 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.iq3TypeCnt}</label>
        </div>
      ),
    },
    // {
    //   id: '10',
    //   title: '形鋼種類',
    //   dataIndex: 'iq5TypeCnt',
    //   width: 100 + 'px',
    // },
    // {
    //   id: '11',
    //   title: '機械種類',
    //   dataIndex: 'iq7TypeCnt',
    //   width: 100 + 'px',
    // },
    {
      id: '8',
      title: '子部品製作数',
      dataIndex: 'childPartTotalProduceCnt',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.childPartTotalProduceCnt}</label>
        </div>
      ),
    },
    {
      id: '9',
      title: '板金製作数',
      dataIndex: 'iq3TotalProduceCnt',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.iq3TotalProduceCnt}</label>
        </div>
      ),
    },
    // {
    //   id: '14',
    //   title: '形鋼製作数',
    //   dataIndex: 'iq5TotalProduceCnt',
    //   width: 100 + 'px',
    // },

    // {
    //   id: '15',
    //   title: '機械製作数',
    //   dataIndex: 'iq7TotalProduceCnt',
    //   width: 100 + 'px',
    // },
  ];
  const columnsthree = [
    {
      id: '1',
      title: '表面処理',
      dataIndex: 'surfaceArea',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.surfaceArea != undefined
              ? '¥' +
                record.surfaceArea.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '2',
      title: '組立',
      dataIndex: 'kumitate',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.kumitate != undefined
              ? '¥' +
                record.kumitate.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '3',
      title: '検査',
      dataIndex: 'inspection',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.inspection != undefined
              ? '¥' +
                record.inspection.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '4',
      title: '梱包',
      dataIndex: 'packing',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.packing != undefined
              ? '¥' +
                record.packing.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '5',
      title: '板付',
      dataIndex: 'temporaryAttachment',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.temporaryAttachment != undefined
              ? '¥' +
                record.temporaryAttachment.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '6',
      title: '外注',
      dataIndex: 'outsourcing',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.outsourcing != undefined
              ? '¥' +
                record.outsourcing.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: '7',
      title: '研暦',
      dataIndex: 'polishing',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.polishing != undefined
              ? '¥' +
                record.polishing.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
  ];

  const rowdata = {
    drawingno: '',
    name: '',
    customercode: '',
    customername: '',
    lotno: 1,
    order: '受注',
  };
  const radioOptions = [
    { label: '100', value: '1' },
    { label: '200', value: '2' },
    { label: '300', value: '3' },
    { label: '500', value: '4' },
    { label: '1000', value: '5' },
  ];
  const tenjikaiSampleData = getTenjikaiSampleData();
  // 費用
  const paramCostLst = JSON.parse(localStorage.getItem('paramCommonCost'));
  const costLstData = paramCostLst?.length > 0 ? paramCostLst : tenjikaiSampleData.parentList.map((i) => i.costLst);
  const costLst =
    tenjikaiSampleData.parentList[
      tenjikaiSampleData.parentList.length == 0 ? 0 : tenjikaiSampleData.parentList.length - 1
    ].costLst;

  // ラベル
  const paramLabelLst = JSON.parse(localStorage.getItem('paramCommonLabel'));
  const labelLstData = paramLabelLst?.length > 0 ? paramLabelLst : tenjikaiSampleData.parentList.map((i) => i.labelLst);
  const labelLst =
    tenjikaiSampleData.parentList[
      tenjikaiSampleData.parentList.length == 0 ? 0 : tenjikaiSampleData.parentList.length - 1
    ].labelLst;

  // メモ
  const paramMemoLst = JSON.parse(localStorage.getItem('paramCommonMemo'));
  const memoLstData = paramMemoLst?.length > 0 ? paramMemoLst : tenjikaiSampleData.parentList.map((i) => i.memoLst);
  const memoLst =
    tenjikaiSampleData.parentList[
      tenjikaiSampleData.parentList.length == 0 ? 0 : tenjikaiSampleData.parentList.length - 1
    ].memoLst;

  // 日付
  const paramDateLst = JSON.parse(localStorage.getItem('paramCommonDate'));
  const dateLstData = paramDateLst?.length > 0 ? paramDateLst : tenjikaiSampleData.parentList.map((i) => i.dateLst);
  const dateLst =
    tenjikaiSampleData.parentList[
      tenjikaiSampleData.parentList.length == 0 ? 0 : tenjikaiSampleData.parentList.length - 1
    ].dateLst;

  // 費用
  const hiyouColumns = costLstData
    .map((paramCost, index) => {
      // return costLst.map((costValue) => {
      return {
        title: paramCost.name,
        dataIndex: 'costLst' + parseInt(index + 1),
        width: 100 + 'px',
        className: 'cm-a-right',
        render: (_) => {
          const targetCostLst = costLst.find((item) => item?.key === paramCost?.key);
          return targetCostLst ? <> {JPYs.format(targetCostLst.data)}</> : <> {JPYs.format(0)}</>;
        },
      };
      // });
    })
    .flat();
  // const temphiyouColumns = hiyouColumns.slice(0, 3).map((item) => ({
  //   ...item,
  // }));
  const temphiyouColumns = hiyouColumns;

  // ラベル
  const labelColumns = labelLstData
    .map((record, index) => {
      // return record.map((i) => {
      return {
        title: record.name,
        dataIndex: 'labelLst' + parseInt(index + 1),
        width: 100 + 'px',
        render: (_) => {
          const targetLabelLst = labelLst.find((item) => item?.key === record?.key);
          return targetLabelLst ? <> {targetLabelLst.data}</> : <> ''</>;
        },
      };
      // });
    })
    .flat();
  // const templabelColumns = labelColumns.slice(0, 2).map((item) => ({
  //   ...item,
  // }));
  const templabelColumns = labelColumns;

  // メモ
  const memoColumns = memoLstData
    .map((record, index) => {
      // return record.map((i) => {
      return {
        title: record.name,
        dataIndex: 'memoLst' + parseInt(index + 1),
        width: 100 + 'px',
        render: (_) => {
          const targetMemoLst = memoLst.find((item) => item?.key === record?.key);
          return targetMemoLst ? <> {targetMemoLst.data}</> : <> ''</>;
        },
      };
      // });
    })
    .flat();
  // const tempmemoColumns = memoColumns.slice(0, 1).map((item) => ({
  //   ...item,
  // }));
  const tempmemoColumns = memoColumns;

  // 日付
  const dateColumns = dateLstData
    .map((record, index) => {
      // return record.map((i) => {
      return {
        title: record.name,
        dataIndex: 'dateLst' + parseInt(index + 1),
        width: 100 + 'px',
        render: (_) => {
          const targetDateLst = dateLst.find((item) => item?.key === record?.key);
          return targetDateLst ? <> {targetDateLst.data}</> : <> ''</>;
        },
      };
      // });
    })
    .flat();
  // const tempdateColumns = dateColumns.slice(0, 2).map((item) => ({
  //   ...item,
  // }));
  const tempdateColumns = dateColumns;

  const columns = [
    ...columnsone,
    ...columnstwo,
    ...columnsthree,
    ...hiyouColumns,
    ...labelColumns,
    ...memoColumns,
    ...dateColumns,
  ];

  const displaySettingItems = [
    ...columnsone,
    ...columnstwo,
    ...columnsthree,
    // ...costLst,
    ...hiyouColumns,
    // ...labelLst,
    ...labelColumns,
    // ...memoLst,
    ...memoColumns,
    // ...dateLst,
    ...dateColumns,
  ];

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'imgNo',
      width: 140 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'partNm',
      width: 150 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processNm',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'partCnt',
      width: 55 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.partCnt}</label>
        </div>
      ),
    },
    {
      id: '6',
      title: '材質',
      dataIndex: 'material',
      width: 150 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 150 + 'px',
    },
    {
      id: '8',
      title: '板厚',
      dataIndex: 'thickness',
      width: 60 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.thickness}</label>
        </div>
      ),
    },
    {
      id: '9',
      title: 'サイズX',
      dataIndex: 'sizeX',
      width: 70 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeX}</label>
        </div>
      ),
    },
    {
      id: '10',
      title: 'サイズY',
      dataIndex: 'sizeY',
      width: 70 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeY}</label>
        </div>
      ),
    },
    {
      id: '11',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.weight}</label>
        </div>
      ),
    },
    {
      id: '12',
      title: '面積',
      dataIndex: 'surfaceArea',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.surfaceArea}</label>
        </div>
      ),
    },
    {
      id: '13',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.materialCost}</label>
        </div>
      ),
    },
    {
      id: '14',
      title: '加工費',
      dataIndex: 'processCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.processCost}</label>
        </div>
      ),
    },
    {
      id: '15',
      title: '購入品',
      dataIndex: 'purchaseCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.purchaseCost}</label>
        </div>
      ),
    },
    {
      id: '16',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const iq5Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'id',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 120 + 'px',
      height: 50 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processPattern',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'proCount',
      width: 100 + 'px',
    },
    {
      id: '6',
      title: '鋼材',
      dataIndex: 'steel',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 100 + 'px',
    },
    {
      id: '8',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '9',
      title: 'サイズ長さ',
      dataIndex: 'sizeLen',
      width: 100 + 'px',
    },
    {
      id: '11',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
    },
    {
      id: '12',
      title: '面積',
      dataIndex: 'area',
      width: 100 + 'px',
    },
    {
      id: '13',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: '加工費',
      dataIndex: 'processingCost',
      width: 100 + 'px',
    },
    {
      id: '15',
      title: '購入品',
      dataIndex: 'purchase',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const iq7Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'id',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 120 + 'px',
      height: 50 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processPattern',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'proCount',
      width: 100 + 'px',
    },
    {
      id: '6',
      title: 'タイプ',
      dataIndex: 'type',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 100 + 'px',
    },
    {
      id: '8',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '9',
      title: 'サイズX・直接',
      dataIndex: 'sizeXArea',
      width: 100 + 'px',
    },
    {
      id: '10',
      title: 'サイズY・長さ',
      dataIndex: 'sizeYLen',
      width: 100 + 'px',
    },
    {
      id: '11',
      title: 'サイズZ',
      dataIndex: 'sizeZ',
      width: 100 + 'px',
    },
    {
      id: '12',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
    },
    {
      id: '13',
      title: '面積',
      dataIndex: 'area',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
    },
    {
      id: '15',
      title: '加工費',
      dataIndex: 'processingCost',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '購入品',
      dataIndex: 'purchase',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '18',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const childType = [
    { id: 1, value: 'すべて', label: 'すべて' },
    { id: 2, value: '板金部品', label: '板金部品' },
    { id: 3, value: '形鋼部品', label: '形鋼部品' },
  ];

  const reportOutputMenuitems = [
    {
      label: <Image preview={false} width={iconWidth} src={export_report} style={{ marginLeft: '0px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: 'リスト帳票',
          key: 'listReport',
        },
        {
          label: '親部品帳票',
          key: 'parentReport',
        },
      ],
    },
  ];
  const listOutputMenuitems = [
    {
      label: (
        <Image
          preview={false}
          width={iconWidth}
          src={export_csv_icon}
          style={{ marginLeft: '0px', textDecoration: 'none' }}
        ></Image>
      ),
      key: 'listOutput',
      children: [
        {
          label: 'CSV出力(表示)',
          key: 'listReport',
        },
        {
          label: 'CSV出力(全て)',
          key: 'allReport',
        },
        {
          label: 'iQ見積アナライザ',
          key: 'iQReport',
        },
        {
          label: '明細出力',
          key: 'detailsReport',
        },
      ],
    },
  ];

  const [testData, setTestData] = useState([
    ...columnsone,
    ...columnstwo,
    ...columnsthree,
    ...temphiyouColumns,
    ...templabelColumns,
    ...tempmemoColumns,
    ...tempdateColumns,
  ]);
  const [updTblColumns, setUpdTblColumns] = useState([
    ...columnsone,
    ...columnstwo,
    ...columnsthree,
    ...temphiyouColumns,
    ...templabelColumns,
    ...tempmemoColumns,
    ...tempdateColumns,
  ]);
  // 絞り込み
  const filterSettingItems = [...columnstwo, ...columnsthree, ...costLst, ...labelLst, ...memoLst, ...dateLst];

  const data = [];
  const inputData = [];
  for (let i = 1; i <= filterSettingItems.length + 20; i++) {
    data.push(true);
    inputData.push(['', '', '']);
  }
  const [checkedState, setCheckedState] = useState(data);
  const [inputVal, setInputVal] = useState(inputData);
  let res = testData.map((s) => s.dataIndex);

  const updatedDetails = (updatedData) => {
    if (updatedData != null) {
      const data = [...initialTblItems];

      const index = data.findIndex((item) => item.id === updatedData.id);
      const updatedItems = [...data];
      updatedItems[index] = updatedData;
      setInitialTableItems(updatedItems);

      const updatedItems2 = [...initialTblItemsBk];
      updatedItems.forEach((update) => {
        const index = updatedItems2.findIndex((item) => item.id === update.id);
        updatedItems2[index] = update;
      });
      setInitialTableItemsBk(updatedItems2);
      setSelectedData(updatedData);
    }
  };

  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateEstimateEditMode(mode);
  };
  const customerFilterChange = (e) => {
    setCustomerRdo(e.target.value);
  };

  const changeDisplayCount = (e) => {
    setValue(e.target.value);
  };

  let checkedRow = [];
  let token = localStorage?.getItem('iQxWeb_AccessToken');
  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    const tenjikaiSampleData = getTenjikaiSampleData();
    const listData = tenjikaiSampleData.parentList;
    const listDataLatestRowKey = listData.length == 0 ? 0 : listData.length - 1;
    setInitialTableItems(listData);
    setInitialTableItemsBk(listData);
    setSelectedData(listData[listDataLatestRowKey]);
    setSelectedEstimateData(listData[listDataLatestRowKey]);
    setSelectedRowKeys([listDataLatestRowKey]);
    initRow([...rows, rowdata]);
    navigate(':parentList');
    setCSVExportData([listData]);

    let arr = [];
    arr = [
      ...columnsone,
      ...columnstwo,
      ...columnsthree,
      ...temphiyouColumns,
      ...templabelColumns,
      ...tempmemoColumns,
      ...tempdateColumns,
    ];
    setChkData(arr);
    // 絞り込み
    setDatesData(listData[listDataLatestRowKey].dateLst);
    setLabelsData(listData[listDataLatestRowKey].labelLst);
    setMemoData(listData[listDataLatestRowKey].memoLst);
    setCostData(listData[listDataLatestRowKey].costLst);
    getProjectStatusData();
  }, [props.parentListClick]);

  const getProjectStatusData = async () => {
    // 案件状態データ
    let projectStatusData = await getEstimateStatusData(token);
    setOrderOptions(projectStatusData);
  };

  let tempColumnsArray = [];

  const updateSortingData = (data) => {
    setSortingData(data);
  };

  const onRowSelect = (record) => {
    setClickedAction(1);
    setCurrentRecordKey(record.key);
    setCurrentRecord(record);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      props.updateEstimateEditMode(false);
      setSelectedRowKeys(record.key);
      setCSVExportData(record);
      setSelectedData(record);
      navigate(':parentList');
    }
  };

  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
    setChkRowsCount(checkedRow.length);
    // setCSVExportData(record);
    setSelectedData(record.slice(-1));
  };

  const rowSelection = {
    onChange: onRowCheckboxSelect,
  };
  const getCurrentEditMode = () => {
    return editMode;
  };
  useImperativeHandle(ref, () => ({
    getCurrentEditMode: getCurrentEditMode,
  }));

  const onFinish = (values) => {};

  const contentResizeBtn = () => {
    setSizes(['100%']);
    setBtnShowHide(false);
  };
  const contentResizeBackwardBtn = () => {
    setSizes(['60%']);
    setBtnShowHide(true);
  };

  // keywordで検索
  const changeKeywords = (e) => {
    setKeywords(e.target.vaue);
  };
  const enterChange = (e) => {
    getDataByFilter(e.target.value);
  };

  const getDataByFilter = (value) => {
    let searchBarText = value;
    let parentLstData = initialTblItemsBk;
    const parentLstFilterData = parentLstData.filter(
      (item) =>
        item.id.toString().indexOf(searchBarText) >= 0 ||
        item.customerCode.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.grossProfit.toString().indexOf(searchBarText) >= 0 ||
        item.lotNum.toString().indexOf(searchBarText) >= 0 ||
        item.rate.toString().indexOf(searchBarText) >= 0 ||
        item.unitPrice.toString().indexOf(searchBarText) >= 0 ||
        item.materialAmount.toString().indexOf(searchBarText) >= 0 ||
        item.matufacturingAmount.toString().indexOf(searchBarText) >= 0 ||
        item.setupAmount.toString().indexOf(searchBarText) >= 0 ||
        item.processingAmount.toString().indexOf(searchBarText) >= 0 ||
        item.name.indexOf(searchBarText) >= 0 ||
        item.isOrder.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.imgNo.indexOf(searchBarText) >= 0 ||
        item.customerNm.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateNo.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.creator.toLowerCase().indexOf(searchBarText) >= 0
    );
    if (searchBarText != '') {
      setInitialTableItems(parentLstFilterData);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };

  // お客様で検索
  const changeCustomer = (e) => {
    setCustomer(e.target.vaue);
  };

  const searchByCustomer = (e) => {
    getCustomerByFilter(e.target.value);
  };
  const getCustomerByFilter = (value) => {
    let searchBarText = value;
    let parentLstData = initialTblItemsBk;

    const parentLstFilterData = parentLstData.filter((item) => item.customerNm.includes(searchBarText));
    if (searchBarText != '') {
      setInitialTableItems(parentLstFilterData);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };
  // 図番で検索
  const changeDrawingNo = (e) => {
    setImageNum(e.target.vaue);
  };
  const searchByDrawingNo = (e) => {
    getDrawingNoByFilter(e.target.value);
  };
  const getDrawingNoByFilter = (value) => {
    let searchBarText = value;
    let parentLstData = initialTblItemsBk;
    const parentLstFilterData = parentLstData.filter((item) => item.imgNo.indexOf(searchBarText) >= 0);

    if (searchBarText != '') {
      setInitialTableItems(parentLstFilterData);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };
  // 名称で検索
  const changeName = (e) => {
    setName(e.target.vaue);
  };
  const searchByName = (e) => {
    getNameByFilter(e.target.value);
  };
  const getNameByFilter = (value) => {
    let searchBarText = value;
    let parentLstData = initialTblItemsBk;
    const parentLstFilterData = parentLstData.filter((item) => item.name.indexOf(searchBarText) >= 0);
    if (searchBarText != '') {
      setInitialTableItems(parentLstFilterData);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };
  const clearAllFilter = (e) => {
    setCustomer('');
    setImageNum('');
    setName('');
    setKeywords('');

    setInitialTableItems(initialTblItemsBk);
  };

  const copyOk = () => {
    setOpenCommonTaiochuPopup(true);
    // let tempCsvExportData = {};
    // let drawingNumber = document.getElementById('drawingNo').value;

    // data.map((i) => {
    //   return i;
    // });
    // setIsCopy(false);
    // tempCsvExportData = {
    //   createdDate: '2024/01/18',
    //   customer: 'お客様. 5',
    //   customerCode: '○○○○株式会社 5',
    //   delivery: '5',
    //   drawingNo: '図番図番図番. 5',
    //   estimateNo: '00001 5',
    //   estimatedAmount: '99999',
    //   estimatedDate: '2024/01/18',
    //   grossProfit: 1000,
    //   id: 5,
    //   isOrder: '非受注',
    //   key: 5,
    //   lotNum: 'ロット 5',
    //   manager: '管理者. 5',
    //   materialAmount: 20,
    //   matufacturingAmount: 600000,
    //   name: '名称. 5',
    //   packing: '2',
    //   processingAmount: 20000,
    //   rate: 10,
    //   setupAmount: 10000,
    //   survey: 'ok',
    //   treatement: 2,
    //   unitPrice: 3,
    // };
    // temparr = [...[tempCsvExportData], ...data];

    // setInitialTableItems(temparr);
    // setInitialTableItemsBk(temparr);
    // setHasData(false);
    // setOldData(temparr);
    // localStorage.setItem('csvsession', temparr);
  };

  const copyCancel = () => {
    setIsCopy(false);
  };

  const setCurrentPage = (value) => {
    setPage(value);
  };

  // まとめて編集
  const updateAll = () => {
    setClickedAction(4);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      props.updateEstimateEditMode(false);
      setUpdateItem(initialTblItems);
      setIsUpdate(true);
      setChildTitle('選択したデータをまとめて編集');
    }
  };
  // 子部品確認
  const childOpen = () => {
    if (initialTblItems.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setUpdateItem(selectedData);
      setIsChildOpen(true);
      setChildTitle('子部品確認');
    }
  };
  // 加工確認
  const manufactureOpen = () => {
    if (initialTblItems.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setSelectedEstimateData(selectedData);
      setUpdateItem(selectedData);
      setIsManufactureOpen(true);
      setChildTitle('加工確認');
    }
  };
  // 帳票出力
  const reportOutputChangeEvent = (e) => {
    // setOpenCommonTaiochuPopup(true);
    if (e.key == 'listReport') {
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票');
    } else if (e.key == 'parentReport') {
      setIsReportOutputOpen(true);
      setChildTitle('親部品帳票');
    }
  };
  // リスト出力
  const listOutputChangeEvent = (e) => {
    setOpenCommonTaiochuPopup(true);
    // if (e.key == 'listReport') {
    //   setIsListOutputOpen(true);
    //   setChildTitle('CSV出力(表示)');
    // } else if (e.key == 'allReport') {
    //   setIsListOutputOpen(true);
    //   setChildTitle('CSV出力(全て)');
    // } else if (e.key == 'iQReport') {
    //   setIsListOutputOpen(true);
    //   setChildTitle('CSV出力(全て)');
    // } else {
    //   setIsListOutputOpen(true);
    //   setChildTitle('明細出力');
    // }
  };
  const listOutputOk = () => {
    setIsListOutputOpen(false);
  };
  const listOutputCancel = () => {
    setIsListOutputOpen(false);
  };
  // 新規作成
  const createParent = () => {
    setClickedAction(2);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };
  const goToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
    setEditMode(false);
    props.updateEstimateEditMode(false);
    props.getSelectedEstimateData(getNewParentInfo(initialTblItems?.length), null, true);
    navigate(':estimate.parent');
  };
  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const reportOutputOpen = () => {
    if (initialTblItems.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setUpdateItem(selectedData);
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票出力');
    }
  };
  const reportOutputOk = () => {
    setIsReportOutputOpen(false);
  };
  const reportOutputCancel = () => {
    setIsReportOutputOpen(false);
  };
  // 事前登録
  const preRegistertation = () => {
    setClickedAction(3);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      props.updateEstimateEditMode(false);
      setPreRegister(true);
      setChildTitle('事前登録');
    }
  };

  const preRegisterModalClose = () => {
    setPreRegister(false);
  };

  const updateOk = () => {
    setIsUpdate(false);
    setIsChildOpen(false);
    setIsManufactureOpen(false);
    setIsReportOutputOpen(false);
    setIsListOutputOpen(false);
  };

  const updateCancel = () => {
    setIsUpdate(false);
    setIsChildOpen(false);
    setIsManufactureOpen(false);
    setIsReportOutputOpen(false);
    setIsListOutputOpen(false);
  };
  // 削除
  const deleteRow = () => {
    if (csvExportData.length < 1) {
      alert('削除するデータを選択してください');
    } else {
      setIsDelete(true);
      setChildTitle('削除');
    }
  };

  const deleteOk = () => {
    setIsDelete(false);
    let data = [...initialTblItems];
    data = data.filter((item) => item.id != selectedData.id);
    for (let i = 0; i < data.length; i++) {
      data[i].id = i + 1;
    }
    setInitialTableItems(data);
    setSelectedRowKeys(data.length > 0 ? data[data.length - 1].patternNo : 0);
    setSelectedData(data.length > 0 ? data[data.length - 1] : []);
  };

  const deleteCancel = () => {
    setIsDelete(false);
  };
  // end_削除

  const discardOk = () => {
    if (isFilter == true) {
      setIsFilter(false);
    }
    if (preRegister == true) {
      setPreRegister(false);
    }
    if (isUpdate == true) {
      setIsUpdate(false);
    }
    if (isDisplay == true) {
      setTestData(chkData);
      setIsDisplay(false);
      setSortingData(chkData);
      sortingRef.current.setItems(chkData);
      sortingRef.current.setCurIndex(0);
    }

    setIsDiscardData(false);
  };

  const discardCancel = () => {
    if (isFilter == true) {
      setIsFilter(true);
    }
    if (preRegister == true) {
      setPreRegister(true);
    }
    if (isUpdate == true) {
      setIsUpdate(true);
    }
    if (isDisplay == true) {
      setIsDisplay(true);
    }
    setIsDiscardData(false);
  };
  const updateDataOk = () => {
    if (isFilter == true) {
      // merge
      const mergedArray = checkedState.map((check, index) => {
        return { ...inputVal[index], check };
      });
      const filterData = mergedArray.filter((i) => i.check == true);
      let parentLstData = initialTblItemsBk;
      if (filterData.length >= 0) {
        const parentLstFilterData = parentLstData.filter((item) =>
          filterData.every((filterItem) => {
            const { 0: minValue, 1: maxValue, 2: key } = filterItem;
            const itemValue = item[key];
            if (
              key === 'id' ||
              key === 'lotNum' ||
              key === 'grossProfit' ||
              key === 'rate' ||
              key === 'unitPrice' ||
              key === 'estimatedAmount' ||
              key === 'materialAmount' ||
              key === 'matufacturingAmount' ||
              key === 'setupAmount' ||
              key === 'processingAmount' ||
              key === 'purchaseCost' ||
              key === 'managementCost' ||
              key === 'sizeX' ||
              key === 'sizeY' ||
              key === 'sizeZ' ||
              key === 'childPartTotalWeight' ||
              key === 'iq3Weight' ||
              key === 'childPartTypeCnt' ||
              key === 'iq3TypeCnt' ||
              key === 'childPartTotalProduceCnt' ||
              key === 'iq3TotalProduceCnt' ||
              key === 'surfaceArea' ||
              key === 'kumitate' ||
              key === 'inspection' ||
              key === 'packing' ||
              key === 'temporaryAttachment' ||
              key === 'outsourcing' ||
              key === 'polishing' ||
              key === 'costLst1' ||
              key === 'costLst2' ||
              key === 'costLst3' ||
              key === 'costLst4' ||
              key === 'costLst5' ||
              key === 'costLst6' ||
              key === 'costLst7' ||
              key === 'labelLst1' ||
              key === 'labelLst2' ||
              key === 'labelLst3' ||
              key === 'labelLst4' ||
              key === 'labelLst5' ||
              key === 'labelLst6' ||
              key === 'labelLst7' ||
              key === 'labelLst8' ||
              key === 'memoLst1' ||
              key === 'memoLst2' ||
              key === 'memoLst3'
            ) {
              const intValue = parseInt(itemValue);
              if (minValue === '' && maxValue === '') return true;
              if (minValue === '') return intValue <= parseInt(maxValue);
              if (maxValue === '') return intValue >= parseInt(minValue);
              return intValue >= parseInt(minValue) && intValue <= parseInt(maxValue);
            } else if (key === 'customerCode') {
              if (minValue === '' && maxValue === '') return true;
              if (minValue === '') return itemValue <= maxValue;
              if (maxValue === '') return itemValue >= minValue;
              return itemValue >= minValue && itemValue <= maxValue;
            } else if (key === 'imgNo' || key === 'name') {
              return itemValue.includes(minValue);
            } else if (key === 'allowGaichu') {
              return itemValue == minValue;
            }
            return true;
          })
        );

        setInitialTableItems(parentLstFilterData);
      }

      setIsFilter(false);
    }
    if (preRegister == true) {
      setPreRegister(false);
    }
    if (isUpdate == true) {
      setIsUpdate(false);
    }
    if (isDisplay == true) {
      if (value === '2') {
        setPageSize(10); //本番の場合、200に変更
      } else if (value === '3') {
        setPageSize(15); //本番の場合、300に変更
      } else if (value === '4') {
        setPageSize(16); //本番の場合、500に変更
      } else if (value === '5') {
        setPageSize(17); //本番の場合、1000に変更
      } else {
        setPageSize(25); //本番の場合、100に変更
      }

      sortingData.map((i) => {
        var ret = columns.find((col) => col.dataIndex === i.dataIndex);
        if (ret) tempColumnsArray.push(ret);
      });
      setCheckData(false);
      if (tempColumnsArray.length > 0) {
        setChkData([...tempColumnsArray]);
      } else {
        setChkData(sortingData);
      }
      tempColumnsArray = [];
      setIsDisplay(false);
      setTestData(sortingData);
      sortingRef.current.setCurIndex(0);
    }

    setIsUpdateData(false);
  };

  const updateDataCancel = () => {
    if (isFilter == true) {
      setIsFilter(true);
    }
    if (preRegister == true) {
      setPreRegister(true);
    }
    if (isUpdate == true) {
      setIsUpdate(true);
    }
    if (isDisplay == true) {
      setIsDisplay(true);
    }
    setIsUpdateData(false);
  };

  // 表示設定_start
  const displaySetting = () => {
    setClickedAction(5);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      props.updateEstimateEditMode(false);
      setIsDisplay(true);

      setChildTitle('表示設定');
      let temp = [];
      temp = testData;
    }
  };
  const displayOk = () => {
    sortingData.map((i) => {
      var ret = columns.find((col) => col.dataIndex === i.dataIndex);
      if (ret) tempColumnsArray.push(ret);
    });
    setCheckData(false);
    if (tempColumnsArray.length > 0) {
      setChkData([...tempColumnsArray]);
    } else {
      setChkData(sortingData);
    }
    tempColumnsArray = [];
    setIsDisplay(false);
    setTestData(sortingData);
    sortingRef.current.setCurIndex(0);
  };

  const displayCancel = () => {
    setTestData(chkData);
    setIsDisplay(false);
    setSortingData(chkData);
    sortingRef.current.setItems(chkData);
    sortingRef.current.setCurIndex(0);
  };

  const handleChkChange = (e) => {
    let allselecteddata = [];
    let id = e.target.id;
    let name = e.target.name;
    let tempData = [...sortingData];
    let dispData = [];

    // チェックボックスONである、全てのItem
    dispData = displaySettingItems.filter((record, index) => {
      if (document.getElementById('chkitem' + index)?.checked === true) {
        return record;
      }
    });

    // チェックボックスONの場合、
    if (document.getElementById(id) && document.getElementById(id).checked) {
      //　ONにした項目の情報を取得する
      let newItem = dispData.find((item) => item.dataIndex === name);
      // 並び替えリストに追加
      tempData = [...tempData, newItem];
      let index = tempData.findIndex((item) => item.dataIndex === name);
      // 最初の項目として移す
      allselecteddata = arrayMoveImmutable(tempData, index, 0);
    } else {
      // チェックボックスOFFの場合、
      let index = tempData.findIndex((item) => item.dataIndex === name);
      // 並び替えリストから消去する
      tempData.splice(index, 1);
      allselecteddata = tempData;
    }

    setTestData(allselecteddata);
  };

  // 表示設定_end
  // 絞込み設定
  const filterSetting = () => {
    setClickedAction(6);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      props.updateEstimateEditMode(false);
      setIsFilter(true);
    }
  };
  const updateData = () => {
    // setIsFilter(false);
    setIsUpdateData(true);
  };
  const discardData = () => {
    setIsDiscardData(true);
    // setIsFilter(false);
  };

  const cancelFilter = () => {
    setIsFilter(false);
  };
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    props.updateEstimateEditMode(false);
    if (clickedAction == 1) {
      // selected row change
      setSelectedRowKeys(currentRecordKey);
      setCSVExportData(currentRecord);
      setSelectedData(currentRecord);
      navigate(':parentList');
    } else if (clickedAction == 3) {
      // 事前登録
      setPreRegister(true);
      setChildTitle('事前登録');
    } else if (clickedAction == 4) {
      // 一括編集
      setUpdateItem(initialTblItems);
      setIsUpdate(true);
      setChildTitle('選択したデータをまとめて編集');
    } else if (clickedAction == 5) {
      // 表示設定
      setIsDisplay(true);

      setChildTitle('表示設定');
      let temp = [];
      temp = testData;
    } else if (clickedAction == 6) {
      // 絞込み設定
      setIsFilter(true);
    }
  };

  const discardConfirmCancel = () => {
    setEditMode(true);
    props.updateEstimateEditMode(true);
    setEditModeCancelConfirm(false);
  };

  // 全てクリア
  const clearAllCheckbox = (e) => {
    const data = [];
    for (let i = 1; i <= filterSettingItems.length + 20; i++) {
      data.push(false);
    }
    if (e.target.checked == true) {
      setCheckbox1Checked(true);
      setCheckedState(data);
    } else {
      setCheckbox1Checked(false);
    }
  };

  const handleCheckboxChange = (index, checked) => {
    const newState = [...checkedState];
    newState[index] = checked;
    setCheckedState(newState);
    setCheckbox1Checked(false);
  };
  const handleInputVal = (index, e, data, dataIndex) => {
    const newData = [...inputVal];
    newData[index][data] = e.target.value;
    newData[index][2] = dataIndex;
    setInputVal(newData);
  };
  const handleSelectVal = (index, e, data, dataIndex) => {
    const newData = [...inputVal];
    newData[index][data] = e;
    newData[index][2] = dataIndex;
    setInputVal(newData);
  };

  // 画面切り替え
  const discardOkConfirm = () => {
    setConfirmScreenChangeModalOpen(false);
    setEditMode(false);
    if (clickedAction == 2) {
      // 新規
      props.getSelectedEstimateData(getNewParentInfo(initialTblItems?.length), null, true);
      navigate(':estimate.parent');
    } else if (clickedAction == 6) {
      // 子部品確認
      props.getSelectedEstimateData(updateItem, childSelectedKey);
      navigate(':estimate.iq3');
    }
  };

  const discardCancelConfirm = () => {
    setConfirmScreenChangeModalOpen(false);
  };

  // お客様コードでお客様名称を取得
  function getCustomerNameByCode(code, Lists) {
    let retValue = '';
    if (code) {
      let info = Lists.filter((info) => info.code === code);
      if (info.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  const firstFilterCol = (
    <>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[0]}
            onChange={(e) => handleCheckboxChange(0, e.target.checked)}
          >
            ID
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[0][0]}
            onChange={(e) => handleInputVal(0, e, 0, 'id')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[0][1]}
            onChange={(e) => handleInputVal(0, e, 1, 'id')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[1]}
            onChange={(e) => handleCheckboxChange(1, e.target.checked)}
          >
            お客様コード
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[1][0]}
            onChange={(e) => handleInputVal(1, e, 0, 'customerCode')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[1][1]}
            onChange={(e) => handleInputVal(1, e, 1, 'customerCode')}
          ></Input>
        </Col>
      </Row>
      <Row style={{ width: 390, padding: 0 }} className="mt-1">
        <Col span={7}>
          <Checkbox
            type="checkbox"
            className="filter-common"
            checked={checkedState[2]}
            onChange={(e) => handleCheckboxChange(2, e.target.checked)}
          >
            お客様
          </Checkbox>
        </Col>
        <Col span={17}>
          <Radio.Group onChange={customerFilterChange} value={customerRdo} style={{ marginLeft: -6 }}>
            <Row>
              <Radio value={1} style={{ marginBottom: 3 }}></Radio>
              <Select
                placeholder="選択"
                style={{ width: 212, fontSize: 11, marginBottom: 4 }}
                name="customer"
                disabled={customerRdo === 2}
              >
                {companiesName.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
            </Row>
            <Row>
              <Radio value={2}></Radio>
              <Input className="filter-input filter-input-range input-editable" disabled={customerRdo === 1}></Input>
            </Row>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[3]}
            onChange={(e) => handleCheckboxChange(3, e.target.checked)}
          >
            図番
          </Checkbox>
        </Col>
        <Col span={16}>
          <Input
            className="filter-input filter-input-range input-editable"
            value={inputVal[3][0]}
            onChange={(e) => handleInputVal(3, e, 0, 'imgNo')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[4]}
            onChange={(e) => handleCheckboxChange(4, e.target.checked)}
          >
            名称
          </Checkbox>
        </Col>
        <Col span={16}>
          <Input
            className="filter-input filter-input-range input-editable"
            value={inputVal[4][0]}
            onChange={(e) => handleInputVal(4, e, 0, 'name')}
          ></Input>
        </Col>
      </Row>

      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[5]}
            onChange={(e) => handleCheckboxChange(5, e.target.checked)}
          >
            数量
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[5][0]}
            onChange={(e) => handleInputVal(5, e, 0, 'lotNum')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[5][1]}
            onChange={(e) => handleInputVal(5, e, 1, 'lotNum')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[6]}
            onChange={(e) => handleCheckboxChange(6, e.target.checked)}
          >
            粗利益
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[6][0]}
            onChange={(e) => handleInputVal(6, e, 0, 'grossProfit')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[6][1]}
            onChange={(e) => handleInputVal(6, e, 1, 'grossProfit')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[7]}
            onChange={(e) => handleCheckboxChange(7, e.target.checked)}
          >
            粗利率
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[7][0]}
            onChange={(e) => handleInputVal(7, e, 0, 'rate')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[7][1]}
            onChange={(e) => handleInputVal(7, e, 1, 'rate')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[8]}
            onChange={(e) => handleCheckboxChange(8, e.target.checked)}
          >
            見積単価
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[8][0]}
            onChange={(e) => handleInputVal(8, e, 0, 'unitPrice')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[8][1]}
            onChange={(e) => handleInputVal(8, e, 1, 'unitPrice')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[9]}
            onChange={(e) => handleCheckboxChange(9, e.target.checked)}
          >
            見積金額
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[9][0]}
            onChange={(e) => handleInputVal(9, e, 0, 'estimatedAmount')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[9][1]}
            onChange={(e) => handleInputVal(9, e, 1, 'estimatedAmount')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" style={{ padding: 0 }} align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[10]}
            onChange={(e) => handleCheckboxChange(10, e.target.checked)}
          >
            材料原価
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[10][0]}
            onChange={(e) => handleInputVal(10, e, 0, 'materialAmount')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[10][1]}
            onChange={(e) => handleInputVal(10, e, 1, 'materialAmount')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[11]}
            onChange={(e) => handleCheckboxChange(11, e.target.checked)}
          >
            製造原価
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[11][0]}
            onChange={(e) => handleInputVal(11, e, 0, 'matufacturingAmount')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[11][1]}
            onChange={(e) => handleInputVal(11, e, 1, 'matufacturingAmount')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[12]}
            onChange={(e) => handleCheckboxChange(12, e.target.checked)}
          >
            段取金額
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[12][0]}
            onChange={(e) => handleInputVal(12, e, 0, 'setupAmount')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[10][1]}
            onChange={(e) => handleInputVal(12, e, 1, 'setupAmount')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[13]}
            onChange={(e) => handleCheckboxChange(13, e.target.checked)}
          >
            加工金額
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[13][0]}
            onChange={(e) => handleInputVal(13, e, 0, 'processingAmount')}
          ></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input
            className="filter-input-range input-editable"
            value={inputVal[13][1]}
            onChange={(e) => handleInputVal(13, e, 1, 'processingAmount')}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[14]}
            onChange={(e) => handleCheckboxChange(14, e.target.checked)}
          >
            購入品費
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input className="filter-input-range input-editable"></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input className="filter-input-range input-editable"></Input>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[15]}
            onChange={(e) => handleCheckboxChange(15, e.target.checked)}
          >
            管理費
          </Checkbox>
        </Col>
        <Col span={6}>
          <Input className="filter-input-range input-editable"></Input>
        </Col>
        <Col span={1} className="range-style">
          ～
        </Col>
        <Col span={6}>
          <Input className="filter-input-range input-editable"></Input>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[16]}
            onChange={(e) => handleCheckboxChange(16, e.target.checked)}
          >
            受注タイプ
          </Checkbox>
        </Col>
        <Col span={16}>
          <Select placeholder="選択" style={{ width: 215, fontSize: 11 }} name="customer"></Select>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[17]}
            onChange={(e) => handleCheckboxChange(17, e.target.checked)}
          >
            納期タイプ
          </Checkbox>
        </Col>
        <Col span={16}>
          <Select placeholder="選択" style={{ width: 215, fontSize: 11 }} name="customer"></Select>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[18]}
            onChange={(e) => handleCheckboxChange(18, e.target.checked)}
          >
            Image
          </Checkbox>
        </Col>
        <Col span={16}>
          <Select placeholder="選択" style={{ width: 215, fontSize: 11 }} name="customer"></Select>
        </Col>
      </Row>
      <Row className="mt-1" align="middle">
        <Col span={8}>
          <Checkbox
            className="filter-common"
            type="checkbox"
            checked={checkedState[19]}
            onChange={(e) => handleCheckboxChange(19, e.target.checked)}
          >
            受注非受注
          </Checkbox>
        </Col>
        <Col span={16}>
          <Select
            placeholder="選択"
            style={{ width: 215, fontSize: 11 }}
            name="customer"
            // value={inputVal[19][0]}
            // onChange={(e) => handleSelectVal(19, e, 0, 'allowGaichu')}
          >
            {orderOptions.map((option) => (
              <option value={option.id}>{option.name}</option>
            ))}
          </Select>
        </Col>
      </Row>
    </>
  );

  const handleCancel = () => {
    setIsUpdate(false);
  };

  // CSV
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let headers = ['Id,Name,DrawingNo,EstimateNo,Person In Charge'];
    if (csvExportData.length > 0) {
      let usersCsv = csvExportData.reduce((acc, user) => {
        const { id, customer, drawingNo, estimateNo, manager } = user;
        acc.push([id, customer, drawingNo, estimateNo, manager].join(','));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join('\n'),
        fileName: '親部品リスト.csv',
        fileType: 'text/csv',
      });
    } else {
      alert('出力するデータを選択してください');
    }
  };
  // CSV end

  const childTypeChange = (e) => {
    if (e == 1) {
      document.getElementById('iq3Row').style.display = 'block';
      document.getElementById('iq5Row').style.display = 'block';
      document.getElementById('iq7Row').style.display = 'block';
    } else if (e == 2) {
      document.getElementById('iq3Row').style.display = 'block';
      document.getElementById('iq5Row').style.display = 'none';
      document.getElementById('iq7Row').style.display = 'none';
    } else if (e == 3) {
      document.getElementById('iq3Row').style.display = 'none';
      document.getElementById('iq5Row').style.display = 'block';
      document.getElementById('iq7Row').style.display = 'none';
    } else if (e == 4) {
      document.getElementById('iq3Row').style.display = 'none';
      document.getElementById('iq5Row').style.display = 'none';
      document.getElementById('iq7Row').style.display = 'block';
    }
  };

  const addRowTable = () => {
    const data = {
      drawingno: '',
      name: '',
      customercode: '',
      customername: '',
      lotno: 1,
      order: '受注',
    };
    initRow([...rows, data]);
  };

  const tableRowRemove = (index) => {
    if (rows.length > 1) {
      const dataRow = [...rows];
      dataRow.splice(index, 1);
      initRow(dataRow);
    }
  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  const menuIconList = (
    <>
      <div
        style={{
          borderBottom: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: '#212529',
          backgroundColor: '#fafafc',
          // height: '3.8vh',
        }}
      >
        <Row style={{ marginLeft: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
            <Row style={{ marginTop: '1px', marginLeft: '0' }}>
              <Col span={9}>
                <Space size="middle">
                  <Tooltip title="新規作成" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={createParent}>
                      <Image preview={false} width={iconWidth} src={new_icon} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip>

                  <Tooltip title="呼出" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={commonTaiochuPopupOpen}>
                      <Image preview={false} width={27} src={detail_display} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip>

                  {/* <Tooltip title="追加呼出" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={commonTaiochuPopupOpen}>
                      <Image preview={false} width={27} src={call_detail} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip> */}

                  <Tooltip title="子部品確認" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={childOpen}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={detail_confirm}
                        style={{ marginLeft: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>

                  <Tooltip title="加工確認" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={manufactureOpen}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={detail_confirm}
                        style={{ marginLeft: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="複写" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={commonTaiochuPopupOpen}>
                      <Image preview={false} width={iconWidth} src={copy} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="削除" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={deleteRow}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={child_delete_icon}
                        style={{ marginLeft: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="事前登録" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={preRegistertation}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={jisen_register}
                        style={{ marginLeft: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={updateAll}>
                      <Image
                        preview={false}
                        width={28}
                        src={edit_list}
                        style={{ marginLeft: '0px', marginTop: '6px' }}
                      ></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="表示設定" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={displaySetting}>
                      <Image preview={false} width={33} src={display_setting} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="絞込設定" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={filterSetting}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={search_list}
                        style={{ marginLeft: '0px', marginTop: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="帳票出力" placement="top" overlayClassName="tooltip-text">
                    <Menu
                      onClick={reportOutputChangeEvent}
                      mode="horizontal"
                      items={reportOutputMenuitems}
                      style={{
                        marginLeft: '-19px',
                        marginTop: '-24px',
                        height: 0,
                        color: 'white',
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="リスト出力" placement="top" overlayClassName="tooltip-text">
                    <Menu
                      onClick={listOutputChangeEvent}
                      mode="horizontal"
                      items={listOutputMenuitems}
                      style={{
                        marginLeft: '-19px',
                        marginTop: '-24px',
                        height: 0,
                        color: 'white',
                        textDecoration: 'none',
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="承認登録" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={commonTaiochuPopupOpen}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={jisen_register}
                        style={{ marginLeft: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>
                  <Tooltip title="アナライズ" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={commonTaiochuPopupOpen}>
                      <Image preview={false} width={iconWidth} src={analyze} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '38%' }}>
            <Row style={{ marginTop: '0.3px', marginLeft: '10px' }}>
              <Col span={5} offset={10}>
                <Space size="middle">
                  {btnShowHide ? (
                    <RightCircleFilled onClick={contentResizeBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                  ) : (
                    <LeftCircleFilled onClick={contentResizeBackwardBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </>
  );

  const addRows = rows.map((rowsData, index) => {
    const { drawingno, name, customercode, customername, lotno, order } = rowsData;
    return (
      <tr>
        <td style={{ padding: '3px' }}>
          <Input
            onChange={(event) => onValUpdate(index, event)}
            value={drawingno}
            name="drawingno"
            className="input-editable"
          ></Input>
        </td>
        <td style={{ padding: '3px' }}>
          <Input style={{ padding: 3, height: 30 }} type="text" value={name} name="name" className="input-editable" />
        </td>
        <td style={{ padding: '3px' }}>
          <Select
            placeholder="選択"
            style={{ fontSize: '13.5px' }}
            id="isOrder"
            className="yousetsu-select"
            name="customercode"
          >
            {customersCode.map((option) => (
              <option value={option}>{option}</option>
            ))}
          </Select>
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          <Input
            style={{ padding: 3, height: 30 }}
            type="text"
            onChange={(event) => onValUpdate(index, event)}
            value={customername}
            className="input-editable"
            name="customername"
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          <Input
            style={{ padding: 3, height: 30, textAlign: 'center' }}
            type="text"
            value={lotno}
            name="lotno"
            className="input-editable"
            onChange={(event) => onValUpdate(index, event)}
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          {/* <Select
            placeholder="選択"
            style={{ width: '100%' }}
            options={orderOptions.map((option) => ({ label: option.value, value: option.label }))}
          /> */}
          <Select
            // className="iq3-detail-select"
            // value={isOrder}
            name="allowGaichu"
            id="allowGaichu"
            // onChange={(e) => {
            //   setIsOrder(e);
            //   detailsData['allowGaichu'] = e;
            // }}
            style={{ padding: 3, width: '100%' }}
          >
            {orderOptions.map((option) => (
              <option value={option.id}>{option.name}</option>
            ))}
          </Select>
        </td>
        <td
          style={{ border: 'none', width: '7%', backgroundColor: 'white', verticalAlign: 'middle' }}
          className="borderless"
        >
          <Row justify="center">
            <PlusCircleFilled
              className="add-remove-icon"
              style={{ marginLeft: 5, marginRight: 5 }}
              onClick={addRowTable}
            />
            <CloseCircleFilled className="add-remove-icon" onClick={tableRowRemove} />
          </Row>
        </td>
      </tr>
    );
  });

  const filterClearBtn = (
    <>
      <div style={{ display: 'flex' }}>
        <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
        <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
      </div>
    </>
  );

  const editModalTitle = (
    <div
      style={{
        width: isUpdate ? 1790 : 570,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const childModalTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const reportModalTitle = (
    <div
      style={{
        width: 550,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const displaySettingModalTitle = (
    <div
      style={{
        width: 1400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>表示設定</p>
    </div>
  );

  const jisenRegisterModalTitle = (
    <div
      style={{
        width: 1030,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>事前登録リスト表示</p>
    </div>
  );

  const filterModalTitle = (
    <div
      style={{
        width: 1225,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>絞込設定</p>
    </div>
  );

  const confirmChildInfoContent = (
    <>
      {' '}
      <Row style={{ height: '25px' }}>
        <Space size="large">
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>お客様：{updateItem.customerNm}</p>
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>図番：{updateItem.imgNo}</p>
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>名称：{updateItem.name}</p>
        </Space>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={1}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>種類</p>
        </Col>
        <Col span={2}>
          <Select
            style={{ width: '100%', height: '30px', fontSize: '10px', marginLeft: -12 }}
            onChange={childTypeChange}
            name="childType"
            defaultValue={childType[0].value}
          >
            {childType.map((option) => (
              <option value={option.id}>{option.label}</option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row id="iq3Row" style={{ marginTop: 5 }}>
        <p style={{ fontSize: '13.5px', marginBottom: 1, marginTop: 0, fontWeight: 'bold' }}>板金部品</p>
        <Table
          showSorterTooltip={false}
          columns={iq3Columns}
          rowKey={(record) => record.key}
          dataSource={selectedData.iq3DataLst}
          scroll={{ y: '10vh', x: '10vw' }}
          className="child-detailTable"
          pagination={false}
          onRow={(record, index) => {
            return {
              onDoubleClick: () => {
                if (record != null) {
                  setChildSelectedKey(record.key);
                  setClickedAction(6);
                  if (editMode) {
                    setConfirmScreenChangeModalOpen(true);
                  } else {
                    setEditMode(false);
                    props.getSelectedEstimateData(updateItem, record.key, false);
                    navigate(':estimate.iq3');
                  }
                }
              },
            };
          }}
        />
      </Row>
    </>
  );

  const copyParentInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10 }}>選択データ”図番”、”名称”を複写します。</p>
      <label style={{ fontSize: '13.5px' }}>図番</label>
      <Input id="drawingNo" style={{ marginBottom: 10 }}></Input>
      <label style={{ fontSize: '13.5px' }}>名称</label>
      <Input></Input>
    </>
  );

  const deleteParentInfoContent = (
    <>
      <p style={{ fontSize: '13.5px' }}>
        {/* 選択したデータ”図番”、”名称”を削除します。<br></br>削除したデータは戻せません。 */}
        選択した見積データを削除します。<br></br>削除したデータは戻せません。
      </p>
    </>
  );

  const jisenRegisterContent = (
    <>
      <div style={{ marginTop: -25 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="mt-4" style={{ marginRight: 90 }}>
            <label
              class="input-group-text"
              for="inputGroupFile"
              style={{ color: '#005fab', height: 28, fontSize: '13.5px' }}
            >
              ファイル取込
            </label>
            <Input
              // type="file"
              id="inputGroupFile"
              style={{ display: 'none' }}
              // accept=".xlsx,.csv"
              onClick={commonTaiochuPopupOpen}
            />
          </Row>
        </Col>
      </div>

      <div className="overflow-auto registerModal" style={{ height: 600, overflowY: 'scroll', marginTop: 3 }}>
        <RTable style={{ marginTop: 5, width: '99%' }} className="">
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '17%' }}>図番</th>
              <th style={{ width: '17%' }}>名称</th>
              <th style={{ width: '17%' }}>お客様コード</th>
              <th style={{ width: '17%' }}>お客様名称</th>
              <th style={{ width: '7%' }}>数量</th>
              <th style={{ width: '17%' }}>受注非受注</th>
            </tr>
          </thead>
          <tbody>{addRows}</tbody>
        </RTable>
      </div>
      <div style={{ marginTop: 5 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -5 }}>
          <Row className="mt-4" style={{ marginRight: 0 }}>
            <Button onClick={updateData} className="mainButton" id="update">
              更新
            </Button>

            <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
              破棄
            </Button>
          </Row>
        </Col>
      </div>
    </>
  );

  const editAllContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            // columns={testData}
            columns={updTblColumns}
            rowKey={(record) => record.id}
            dataSource={updateItem}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25, // 本番値確認必要
              defaultCurrent: 1,
            }}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable className="propertiesTbl">
              <thead>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">受注非受注</label>
                  </td>
                  <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                    <Select
                      className="iq3-detail-select"
                      name="isOrder"
                      id="isOrder"
                      onChange={(e) => {
                        // setIsOrder(e);
                        // detailsData['isOrder'] = e;
                      }}
                      style={{ marginLeft: 5, width: '45%' }}
                    >
                      {orderOptions.map((option) => (
                        <option value={option.id}>{option.name}</option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">サイズX</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      style={{ padding: 5, marginLeft: 5, width: '90%' }}
                      className={'input-editable'}
                    ></Input>
                  </td>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">サイズY</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      style={{ padding: 5, marginLeft: 5, width: '90%' }}
                      className={'input-editable'}
                    ></Input>
                  </td>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">サイズZ</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      style={{ padding: 5, marginLeft: 5, width: '90%' }}
                      className={'input-editable'}
                    ></Input>
                  </td>
                </tr>

                {temphiyouColumns.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}

                {templabelColumns.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}
                {tempmemoColumns.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}
                {tempdateColumns.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={updateData} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={discardData}>
          破棄
        </Button>
      </Row>
    </>
  );

  const displaySettingContent = (
    <>
      <Row>
        <Col span={16}>
          <Row style={{ fontSize: '13.5px', marginTop: 5, fontWeight: '700' }}>表示件数 </Row>
          <Row style={{ marginTop: 5, marginLeft: 20, fontSize: '13.5px' }}>
            <Radio.Group onChange={changeDisplayCount} value={value}>
              {radioOptions.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Row>
        </Col>
      </Row>

      <Row>
        <div className="left-display">
          <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700' }}>表示項目</div>
          <Space align="start" direction="horizontal">
            {new Array(Math.floor(displaySettingItems.length / 16) + 1).fill(null).map((_, cIndex) => (
              <Space align="start" direction="vertical">
                {new Array(16).fill(null).map((_, rIndex) => {
                  var index = cIndex * 16 + rIndex;
                  if (index >= displaySettingItems.length || !displaySettingItems[index]) return <></>;
                  return (
                    <>
                      <Checkbox
                        style={{ marginLeft: 10, marginRight: 10 }}
                        key={index}
                        type="checkbox"
                        name={displaySettingItems[index]?.dataIndex}
                        id={'chkitem' + index}
                        defaultChecked={
                          displaySettingItems[index]?.dataIndex ==
                          res.filter((i) => {
                            if (i == displaySettingItems[index]?.dataIndex) {
                              return displaySettingItems[index]?.dataIndex;
                            }
                          })
                        }
                        checked={
                          displaySettingItems[index]?.dataIndex ==
                          res.filter((i) => {
                            if (i == displaySettingItems[index]?.dataIndex) {
                              return displaySettingItems[index]?.dataIndex;
                            }
                          })
                        }
                        onChange={handleChkChange}
                      >
                        {displaySettingItems[index]?.title}
                      </Checkbox>
                    </>
                  );
                })}
              </Space>
            ))}
          </Space>
        </div>
        <div className="right-display">
          <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700' }}>
            ※選択されている表示項目及び並び替え項目
          </div>
          <Sorting items={testData} update={updateSortingData} sortingData={sortingData} ref={sortingRef} />
        </div>
      </Row>

      <Row className="mt-2" style={{ marginRight: 0, marginBottom: -10 }} justify="end">
        <Button onClick={updateData} className="mainButton" id="update">
          更新
        </Button>

        <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
          破棄
        </Button>
      </Row>
    </>
  );

  const filterContent = (
    <>
      <div
        style={{
          fontSize: '13.5px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        className="shiborikomiTbl"
      >
        <RTable>
          <tr>
            <td>{firstFilterCol}</td>

            <td style={{ verticalAlign: 'top' }}>
              {new Array(Math.floor(filterSettingItems.length / 21) + 1).fill(null).map((_, cIndex) => (
                <Space align="start" direction="vertical" className="filterDataRows">
                  {new Array(21).fill(null).map((_, rIndex) => {
                    var index = cIndex * 21 + rIndex;
                    if (index >= filterSettingItems.length || !filterSettingItems[index]) return <></>;
                    return (
                      <>
                        <Row style={{ width: 390 }} align="middle" className="mt-1">
                          <Col span={8}>
                            <Checkbox
                              style={{ marginLeft: 10, marginRight: 10 }}
                              key={index}
                              type="checkbox"
                              name="chkitem"
                              checked={checkedState[index + 20]}
                              onChange={(e) => handleCheckboxChange(index + 20, e.target.checked)}
                            >
                              {filterSettingItems[index]?.title}
                            </Checkbox>
                          </Col>
                          <Col span={6}>
                            {filterSettingItems.length - dateLst.length <= index ? (
                              <>
                                <DatePicker
                                  // defaultValue={dayjs(dayjs().format('YYYY-MM-DD'), 'YYYY-MM-DD')}
                                  format={'YYYY-MM-DD'}
                                  style={{ marginLeft: 0 }}
                                />
                              </>
                            ) : (
                              <>
                                <Input
                                  className="filter-input-range input-editable"
                                  value={inputVal[index + 20][0]}
                                  onChange={(e) =>
                                    handleInputVal(index + 20, e, 0, filterSettingItems[index].dataIndex)
                                  }
                                ></Input>
                              </>
                            )}
                          </Col>
                          <Col span={1} verticalAlign="center">
                            ～
                          </Col>
                          <Col span={6}>
                            {filterSettingItems.length - dateLst.length <= index ? (
                              <>
                                {/* <Input className="filter-input-range input-editable" type="date"></Input> */}
                                <DatePicker
                                  // defaultValue={dayjs(dayjs().format('YYYY-MM-DD'), 'YYYY-MM-DD')}
                                  format={'YYYY-MM-DD'}
                                  style={{ marginLeft: 0 }}
                                />
                              </>
                            ) : (
                              <>
                                <Input
                                  className="filter-input-range input-editable"
                                  value={inputVal[index + 20][1]}
                                  onChange={(e) =>
                                    handleInputVal(index + 20, e, 1, filterSettingItems[index].dataIndex)
                                  }
                                >
                                  {/*value={secondInput} onChange={(event) => changeValue(index, event)} */}
                                </Input>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Space>
              ))}
            </td>
            {/* <td>
              <div>
                {[1, 2, 3].map((num, index) => (
                  <Checkbox
                    key={num}
                    checked={checkedState[index]}
                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                  >
                    Checkbox {num}
                  </Checkbox>
                ))}
              </div>
            </td> */}
          </tr>
        </RTable>
      </div>

      <Row className="mt-1" style={{ marginBottom: -5 }} align="middle">
        <Col span={12}>
          <Checkbox onChange={clearAllCheckbox} checked={checkbox1Checked}>
            全てクリア
          </Checkbox>
        </Col>

        <Col span={5} style={{ textAlign: 'end' }} offset={7}>
          <Button onClick={updateData} className="mainButton" id="update">
            更新
          </Button>

          <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            破棄
          </Button>
        </Col>
      </Row>
    </>
  );

  const reportParentList = (
    <>
      <Row style={{ marginTop: 25 }}>
        <Space size="middle">
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力元ファイル</p>
          <Input style={{ width: 300 }} />
          <Upload>
            <Button style={{ fontSize: '13.5px' }}>参照</Button>
          </Upload>
        </Space>
      </Row>
      <Row style={{ marginTop: '17px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={isListOutputOpen ? listOutputOk : reportOutputOk} className="mainButton" id="update">
            出力
          </Button>

          <Button
            onClick={isListOutputOpen ? listOutputCancel : reportOutputCancel}
            className="cancelButton"
            style={{ marginLeft: 10 }}
            id="discard"
          >
            破棄
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <Form className="components-table-demo-control-bar" form={form} onFinish={onFinish}>
          {/* メニューバー */}
          {menuIconList}
          {/* 一覧とプロパティ表示 */}
          <div
            style={{
              height: '90.8vh',
              marginLeft: 10,
            }}
          >
            <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1} style={{ color: 'red' }}>
              <div style={{ marginTop: 7 }}>
                {/* 検索アリア */}
                <div style={{ display: 'flex', width: '89%' }}>
                  <Input
                    id="searchCustomer"
                    value={customer}
                    style={{ marginLeft: 0 }}
                    placeholder="お客様"
                    allowClear
                    onChange={changeCustomer}
                    onPressEnter={searchByCustomer}
                  />
                  <Input
                    id="searchDrawingNo"
                    style={{ marginLeft: 5 }}
                    placeholder="図番"
                    value={imageNum}
                    allowClear
                    onChange={changeDrawingNo}
                    onPressEnter={searchByDrawingNo}
                  />
                  <Input
                    id="searchName"
                    style={{ marginLeft: 5 }}
                    placeholder="名称"
                    value={name}
                    allowClear
                    onChange={changeName}
                    onPressEnter={searchByName}
                  />
                  <Input
                    id="searchAnyData"
                    style={{ marginLeft: 5 }}
                    value={keyword}
                    placeholder="キーワードを入力"
                    allowClear
                    onChange={changeKeywords}
                    onPressEnter={enterChange}
                  />
                  <Button style={{ marginLeft: 15 }} className="filterClearButton" onClick={clearAllFilter}>
                    {filterClearBtn}
                  </Button>
                </div>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    className="tbParentList"
                    columns={chkData}
                    rowKey={(record) => record.key}
                    rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
                    // dataSource={hasData ? initialTblItems : olddata}
                    dataSource={initialTblItems}
                    scroll={{ y: 590, x: '10vw' }}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      pageSize: pageSize,
                      defaultCurrent: 1,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                        onDoubleClick: () => {
                          // let key = initialTblItems.length == 0 ? 0 : 25;
                          if (record.key == 25 || record.key == 26) {
                            props.getSelectedEstimateData(record, null, false);
                            navigate(':estimate.parent');
                          }
                        },
                      };
                    }}
                  />
                </Row>
              </div>
              {/* プロパティ表示 */}
              <div>
                <Routes>
                  <Route
                    exact
                    path=":parentList"
                    element={
                      <ParentListDetail
                        editMode={editMode}
                        selectedData={selectedData}
                        onUpdateData={updatedDetails}
                        updateEditMode={updateEditMode}
                      />
                    }
                  />
                </Routes>
              </div>
            </SplitPane>
          </div>
        </Form>
      </div>

      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/** 画面切替警告メッセージ */}
      {confirmScreenChangeModalOpen
        ? commonModal(
            confirmScreenChangeModalOpen,
            confirmScreenChangeTitle,
            commonFooter(discardOkConfirm, discardCancelConfirm),
            null,
            400,
            discardOkConfirm,
            discardCancelConfirm,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            updateDataCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
      {/* 子部品確認 */}
      {isChildOpen
        ? commonModal(
            isChildOpen,
            childModalTitle,
            null,
            { height: 822 },
            1790,
            updateOk,
            updateCancel,
            confirmChildInfoContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
      {/* 加工確認 */}
      {isManufactureOpen ? <KouteibetsuInfo selectedEstimateData={selectedEstimateData} /> : ''}
      {/* 複写_Modal */}
      {isCopy
        ? commonModal(
            isCopy,
            commonModalTitle,
            commonFooter(copyOk, copyCancel),
            null,
            520,
            copyOk,
            copyCancel,
            copyParentInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 削除_Modal */}
      {isDelete
        ? commonModal(
            isDelete,
            editModalTitle,
            commonFooter(deleteOk, deleteCancel),
            null,
            520,
            deleteOk,
            deleteCancel,
            deleteParentInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 事前登録 */}
      {preRegister
        ? commonModal(
            preRegister,
            jisenRegisterModalTitle,
            null,
            null,
            1030,
            updateData,
            preRegisterModalClose,
            jisenRegisterContent,
            'registerModalStyle',
            null,
            true
          )
        : ''}
      {/* 一括編集 */}
      {isUpdate
        ? commonModal(
            isUpdate,
            editModalTitle,
            null,
            null,
            1790,
            updateData,
            updateCancel,
            editAllContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
      {/* 表示設定 */}
      {isDisplay
        ? commonModal(
            isDisplay,
            displaySettingModalTitle,
            null,
            null,
            1400,
            updateData,
            displayCancel,
            displaySettingContent,
            null,
            null,
            true
          )
        : ''}
      {/* 絞込み設定 */}
      {isFilter
        ? commonModal(
            isFilter,
            filterModalTitle,
            null,
            null,
            1225,
            updateData,
            cancelFilter,
            filterContent,
            null,
            null,
            true
          )
        : ''}
      {/* リスト出力 */}
      {isListOutputOpen
        ? commonModal(
            isListOutputOpen,
            reportModalTitle,
            null,
            { height: 80 },
            550,
            listOutputOk,
            listOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/* 帳票出力 */}
      {isReportOutputOpen
        ? commonModal(
            isReportOutputOpen,
            reportModalTitle,
            null,
            { height: 80 },
            550,
            reportOutputOk,
            reportOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToCreateParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToCreateParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default WithRouter(ParentList);
