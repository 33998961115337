import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Collapse, Checkbox, Space, Popover } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  setTimetoSec,
  secondsToHms,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const programArr = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 1800,
    dandoriTime: '00:30:00',
    sagyoAmt: 54880,
    sagyoTime: '15:14:40',
    eleCount: 2744,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 420,
    dandoriTime: '00:07:00',
    sagyoAmt: 780,
    sagyoTime: '00:13:40',
    eleCount: 39,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr3 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 600,
    dandoriTime: '00:10:00',
    sagyoAmt: 1200,
    sagyoTime: '00:20:00',
    eleCount: 60,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr4 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 300,
    dandoriTime: '00:05:00',
    sagyoAmt: 280,
    sagyoTime: '00:04:40',
    eleCount: 60,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr5 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 1500,
    dandoriTime: '00:25:00',
    sagyoAmt: 4500,
    sagyoTime: '01:15:40',
    eleCount: 60,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr1_PMX = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 600,
    dandoriTime: '00:10:00',
    sagyoAmt: 1420,
    sagyoTime: '00:23:40',
    eleCount: 71,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr2_PMX = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 160,
    dandoriTime: '00:03:00',
    sagyoAmt: 80,
    sagyoTime: '00:01:00',
    eleCount: 3,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr3_PMX = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 180,
    dandoriTime: '00:03:00',
    sagyoAmt: 120,
    sagyoTime: '00:02:00',
    eleCount: 6,
    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
];

const programArr4_PMX = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    eleCount: 0,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr1_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 600,
    dandoriTime: '00:10:00',
    sagyoAmt: 1920,
    sagyoTime: '00:32:40',
    eleCount: 96,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr2_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 300,
    dandoriTime: '00:05:00',
    sagyoAmt: 280,
    sagyoTime: '00:04:00',
    eleCount: 14,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr3_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 420,
    dandoriTime: '00:07:00',
    sagyoAmt: 700,
    sagyoTime: '00:11:40',
    eleCount: 14,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr4_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 180,
    dandoriTime: '00:03:00',
    sagyoAmt: 200,
    sagyoTime: '00:03:20',
    eleCount: 10,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr5_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 180,
    dandoriTime: '00:03:00',
    sagyoAmt: 100,
    sagyoTime: '00:01:40',
    eleCount: 5,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr6_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 180,
    dandoriTime: '00:03:00',
    sagyoAmt: 80,
    sagyoTime: '00:01:20',
    eleCount: 4,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const programArr7_PMX2 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    eleCount: 0,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const contentDandori = (
  <div>
    <p>
      段取金額=アルゴン段取金額＋ボルト段取金額
      <br /> ¥2780 = 0 + ¥2780
    </p>
    <p>
      段取時間=アルゴン段取時間＋ボルト段取時間
      <br />
      00:45:40 = 00:00:00 + 00:01:00
    </p>
  </div>
);

const IQ3KouteiCalculate_Program = forwardRef((props, ref) => {
  const [program, setProgram] = useState(false);
  const [workAmountIn, setWorkAmountIn] = useState('');
  const [dandoriAmountIn, setDandoriAmountIn] = useState('');
  // const [oyaPartIn, setOyaPartIn] = useState('');
  const [dantoriTime, setDantoriTime] = useState('');
  const [workTime, setWorkTime] = useState('');
  const [eleCount, setElecount] = useState('');
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [editMode, setEditMode] = useState(false);
  //const [resetColor, //setResetColor] = useState(true);
  const [orgProgramData, setOrgProgramData] = useState(programArr);
  const [naibuProcessValidationAmt, setNaibuProcessValidationAmt] = useState('');
  const [naibuProcessValidationTime, setNaibuProcessValidationTime] = useState('');

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail != undefined) {
      if (Object.keys(props.selectedDataDetail)?.length > 0) {
        if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
          setWorkAmountIn(orgProgramData[0].sagyoAmt);
          setDandoriAmountIn(orgProgramData[0].dandoriAmt);
          setDantoriTime(orgProgramData[0].dandoriTime);
          setWorkTime(orgProgramData[0].sagyoTime);
          setElecount(orgProgramData[0].eleCount);
          setTotalAmt(240);
          setTotalTime('15:44:40');
          setOrgProgramData(programArr);
          setNaibuProcessValidationAmt(orgProgramData[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(orgProgramData[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
          setWorkAmountIn(programArr2[0].sagyoAmt);
          setDandoriAmountIn(programArr2[0].dandoriAmt);
          setDantoriTime(programArr2[0].dandoriTime);
          setWorkTime(programArr2[0].sagyoTime);
          setElecount(programArr2[0].eleCount);
          setTotalAmt(1200);
          setTotalTime('00:20:00');
          setOrgProgramData(programArr2);
          setNaibuProcessValidationAmt(programArr2[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr2[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
          setWorkAmountIn(programArr3[0].sagyoAmt);
          setDandoriAmountIn(programArr3[0].dandoriAmt);
          setDantoriTime(programArr3[0].dandoriTime);
          setWorkTime(programArr3[0].sagyoTime);
          setElecount(programArr3[0].eleCount);
          setTotalAmt(1800);
          setTotalTime('00:30:00');
          setOrgProgramData(programArr3);
          setNaibuProcessValidationAmt(programArr3[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr3[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
          setWorkAmountIn(programArr4[0].sagyoAmt);
          setDandoriAmountIn(programArr4[0].dandoriAmt);
          setDantoriTime(programArr4[0].dandoriTime);
          setWorkTime(programArr4[0].sagyoTime);
          setElecount(programArr4[0].eleCount);
          setTotalAmt(580);
          setTotalTime('00:20:00');
          setOrgProgramData(programArr4);
          setNaibuProcessValidationAmt(programArr4[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr4[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
          setWorkAmountIn(programArr5[0].sagyoAmt);
          setDandoriAmountIn(programArr5[0].dandoriAmt);
          setDantoriTime(programArr5[0].dandoriTime);
          setWorkTime(programArr5[0].sagyoTime);
          setElecount(programArr5[0].eleCount);
          setTotalAmt(6000);
          setTotalTime('01:40:00');
          setOrgProgramData(programArr5);
          setNaibuProcessValidationAmt(programArr5[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr5[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
          setWorkAmountIn(programArr1_PMX[0].sagyoAmt);
          setDandoriAmountIn(programArr1_PMX[0].dandoriAmt);
          setDantoriTime(programArr1_PMX[0].dandoriTime);
          setWorkTime(programArr1_PMX[0].sagyoTime);
          setElecount(programArr1_PMX[0].eleCount);
          setTotalAmt(2020);
          setTotalTime('00:30:00');
          setOrgProgramData(programArr1_PMX);
          setNaibuProcessValidationAmt(programArr1_PMX[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr1_PMX[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
          setWorkAmountIn(programArr2_PMX[0].sagyoAmt);
          setDandoriAmountIn(programArr2_PMX[0].dandoriAmt);
          setDantoriTime(programArr2_PMX[0].dandoriTime);
          setWorkTime(programArr2_PMX[0].sagyoTime);
          setElecount(programArr2_PMX[0].eleCount);
          setTotalAmt(240);
          setTotalTime('15:44:40');
          setOrgProgramData(programArr2_PMX);
          setNaibuProcessValidationAmt(programArr2_PMX[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr2_PMX[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
          setWorkAmountIn(programArr3_PMX[0].sagyoAmt);
          setDandoriAmountIn(programArr3_PMX[0].dandoriAmt);
          setDantoriTime(programArr3_PMX[0].dandoriTime);
          setWorkTime(programArr3_PMX[0].sagyoTime);
          setElecount(programArr3_PMX[0].eleCount);
          setTotalAmt(480);
          setTotalTime('00:08:00');
          setOrgProgramData(programArr3_PMX);
          setNaibuProcessValidationAmt(programArr3_PMX[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr3_PMX[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
          setWorkAmountIn(programArr4_PMX[0].sagyoAmt);
          setDandoriAmountIn(programArr4_PMX[0].dandoriAmt);
          setDantoriTime(programArr4_PMX[0].dandoriTime);
          setWorkTime(programArr4_PMX[0].sagyoTime);
          setElecount(programArr4_PMX[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr4_PMX);
          setNaibuProcessValidationAmt(programArr4_PMX[0].naibuProcessValidationAmt);
          setNaibuProcessValidationTime(programArr4_PMX[0].naibuProcessValidationTime);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
          setWorkAmountIn(programArr1_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr1_PMX2[0].dandoriAmt);
          setDantoriTime(programArr1_PMX2[0].dandoriTime);
          setWorkTime(programArr1_PMX2[0].sagyoTime);
          setElecount(programArr1_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr1_PMX2);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
          setWorkAmountIn(programArr2_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr2_PMX2[0].dandoriAmt);
          setDantoriTime(programArr2_PMX2[0].dandoriTime);
          setWorkTime(programArr2_PMX2[0].sagyoTime);
          setElecount(programArr2_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr2_PMX2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
          setWorkAmountIn(programArr3_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr3_PMX2[0].dandoriAmt);
          setDantoriTime(programArr3_PMX2[0].dandoriTime);
          setWorkTime(programArr3_PMX2[0].sagyoTime);
          setElecount(programArr3_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr3_PMX2);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
          setWorkAmountIn(programArr4_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr4_PMX2[0].dandoriAmt);
          setDantoriTime(programArr4_PMX2[0].dandoriTime);
          setWorkTime(programArr4_PMX2[0].sagyoTime);
          setElecount(programArr4_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr4_PMX2);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
          setWorkAmountIn(programArr5_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr5_PMX2[0].dandoriAmt);
          setDantoriTime(programArr5_PMX2[0].dandoriTime);
          setWorkTime(programArr5_PMX2[0].sagyoTime);
          setElecount(programArr5_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr5_PMX2);
        } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
          setWorkAmountIn(programArr6_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr6_PMX2[0].dandoriAmt);
          setDantoriTime(programArr6_PMX2[0].dandoriTime);
          setWorkTime(programArr6_PMX2[0].sagyoTime);
          setElecount(programArr6_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr6_PMX2);
        } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
          setWorkAmountIn(programArr7_PMX2[0].sagyoAmt);
          setDandoriAmountIn(programArr7_PMX2[0].dandoriAmt);
          setDantoriTime(programArr7_PMX2[0].dandoriTime);
          setWorkTime(programArr7_PMX2[0].sagyoTime);
          setElecount(programArr7_PMX2[0].eleCount);
          setTotalAmt(0);
          setTotalTime('00:00:00');
          setOrgProgramData(programArr7_PMX2);
        }
      } else {
        setWorkAmountIn(programArr4_PMX[0].sagyoAmt);
        setDandoriAmountIn(programArr4_PMX[0].dandoriAmt);
        setDantoriTime(programArr4_PMX[0].dandoriTime);
        setWorkTime(programArr4_PMX[0].sagyoTime);
        setElecount(programArr4_PMX[0].eleCount);
        setTotalAmt(0);
        setTotalTime('00:00:00');
        setOrgProgramData(programArr4_PMX);
      }
    } else {
      setWorkAmountIn(programArr4_PMX[0].sagyoAmt);
      setDandoriAmountIn(programArr4_PMX[0].dandoriAmt);
      setDantoriTime(programArr4_PMX[0].dandoriTime);
      setWorkTime(programArr4_PMX[0].sagyoTime);
      setElecount(programArr4_PMX[0].eleCount);
      setTotalAmt(0);
      setTotalTime('00:00:00');
      setOrgProgramData(programArr4_PMX);
    }
    /* let arrReset = Array.prototype.slice.call(document.getElementsByClassName('resetColor'));
    arrReset.forEach((element) => {
      element.style.color = '#000000';
    }); */
  }, [props.selectedDataDetail, props.editMode]);

  const programDetail = () => {
    if (program == true) {
      setProgram(false);
    } else {
      setProgram(true);
    }
  };

  const testReset = () => {};

  const setEditModeChange = (mode) => {
    /* console.log('child_setEditModeChange', mode); */
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const handleTotalAmt = (v1, v2) => {
    v1 = checkStr(v1);
    v2 = checkStr(v2);
    let sum = v1 + v2;
    checkInputWithColor('programTotalAmt', sum, 'green');
    setTotalAmt(sum);
  };

  const handleTotalTime = (v1, v2, v3) => {
    v1 = Number(setTimetoSec(v1));
    v2 = Number(setTimetoSec(v2));
    v3 = Number(setTimetoSec(v3));
    let totalIncSec = v1 + v2;
    let timeStr = secondsToHms(totalIncSec);
    checkInputWithColor('programTotalTime', timeStr, 'green');
    return timeStr;
  };

  const changeAmtwithTime = (amt, typeTime, type, action, time) => {
    let oldtime;
    if (type == 'programWorkTime') {
      oldtime = workTime;
    } else if (type == 'programDandoriTime') {
      oldtime = dantoriTime;
    } else if (type == 'naibuProcessValidationTime') {
      oldtime = naibuProcessValidationTime;
    }
    let editedAmt;
    let intAmt = checkStr(amt);
    if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime == '10min') {
        editedAmt = intAmt - 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt - 10;
      }
    } else if (typeTime == 'input') {
      let typeTime = setTimetoSec(time) - setTimetoSec(oldtime);
      editedAmt = intAmt + typeTime;
      /* console.log('editedAmt', editedAmt); */
    }

    if (type == 'programWorkTime') {
      handleTotalAmt(editedAmt, dandoriAmountIn);
      if (document.getElementById('programWorkAmountCu').style.display !== undefined) {
        let showHideCu = document.getElementById('programWorkAmountCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor('programWorkAmount', editedAmt, 'red');
        } else {
          checkInputWithColor('programWorkAmount', editedAmt, 'green');
        }
      }
    } else if (type == 'programDandoriTime') {
      handleTotalAmt(editedAmt, workAmountIn);
      if (document.getElementById('programWorkAmountCu').style.display !== undefined) {
        let showHideCu = document.getElementById('programDandoriAmountCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor('programDandoriAmount', editedAmt, 'red');
        } else {
          checkInputWithColor('programDandoriAmount', editedAmt, 'green');
        }
      }
    } else if (type == 'naibuProcessValidationTime') {
      handleTotalAmt(editedAmt, naibuProcessValidationAmt);
      if (document.getElementById('programWorkAmountCu').style.display !== undefined) {
        let showHideCu = document.getElementById('naibuProcessValidationAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor('naibuProcessValidationAmt', editedAmt, 'red');
        } else {
          checkInputWithColor('naibuProcessValidationAmt', editedAmt, 'green');
        }
      }
    }
    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv" id="kouteiCalculateDivID">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={programDetail}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {program ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>プログラム</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          {/*  <div>¥{orgProgramData[0].dandoriAmt + orgProgramData[0].sagyoAmt}</div> */}
          <div>
            {JPYs.format(
              orgProgramData[0].dandoriAmt + orgProgramData[0].sagyoAmt + orgProgramData[0].naibuProcessValidationAmt
            )}
          </div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}> プログラム_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: program ? 'block' : 'none' }} id="testDiv">
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            プログラム
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            {/* <label id="programTotalAmt">¥{orgProgramData[0].dandoriAmt + orgProgramData[0].sagyoAmt}</label> */}
            <label id="programTotalAmt">
              {JPYs.format(
                orgProgramData[0].dandoriAmt + orgProgramData[0].sagyoAmt + orgProgramData[0].naibuProcessValidationAmt
              )}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programTotalTime">
              {secondsToHms(
                setTimetoSec(orgProgramData[0].dandoriTime) +
                  setTimetoSec(orgProgramData[0].sagyoTime) +
                  setTimetoSec(orgProgramData[0].naibuProcessValidationTime)
              )}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="programTotalAmtIn" className="resetColor">
              {/* ¥{totalAmt} */}
              {JPYs.format(totalAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="programTotalTimeIn" className="resetColor">
              {totalTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column"></Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programDandoriAmount">{JPYs.format(orgProgramData[0].dandoriAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programDandoriTime">{orgProgramData[0].dandoriTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              value={dandoriAmountIn}
              id="programDandoriAmountIn"
              style={{ border: 'none' }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              onValueChange={(e) => {
                if (dandoriAmountIn != e) {
                  if (e === undefined) {
                    e = 0;
                  }
                  setDandoriAmountIn(e);
                  checkInputWithColor('programDandoriAmount', e, 'amt');
                  handleTotalAmt(e, workAmountIn);
                }
                ////setResetColor(false);
              }}
              /* onKeyPress={(e) => {
                if (isNumber(e.key)) {
                  checkInputWithColor('programDandoriAmount', e, 'amt');
                }
              }} */
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'programDandoriAmountCu'} style={{ display: 'none' }}>
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={dantoriTime}
                  id="programDandoriTimeIn"
                  style={{ width: '100%' }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    ////setResetColor(false);
                    if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                      setDandoriAmountIn(
                        changeAmtwithTime(dandoriAmountIn, 'input', 'programDandoriTime', 'input', e.target.value)
                      );
                    }
                    setTotalTime(handleTotalTime(e.target.value, workTime, naibuProcessValidationTime));
                    setDantoriTime(e.target.value);
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {/* <Space> */}
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={(e) => {
                        let editedTime = handelIncreaseTime(dantoriTime, '10min', 'programDandoriTime');
                        setDantoriTime(editedTime);
                        if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                          setDandoriAmountIn(
                            changeAmtwithTime(dandoriAmountIn, '10min', 'programDandoriTime', 'inc', '')
                          );
                        }
                        //setResetColor(false);
                        setTotalTime(handleTotalTime(editedTime, workTime, naibuProcessValidationTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(setTimetoSec(dantoriTime));
                        if (totalSec >= 600) {
                          let editedTime = handelDecreaseTime(dantoriTime, '10min', 'programDandoriTime');
                          setDantoriTime(editedTime);
                          if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                            setDandoriAmountIn(
                              changeAmtwithTime(dandoriAmountIn, '10min', 'programDandoriTime', 'dec', '')
                            );
                          }
                          //setResetColor(false);
                          setTotalTime(handleTotalTime(editedTime, workTime, naibuProcessValidationTime));
                        }
                      }}
                    />

                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(dantoriTime, 'min', 'programDandoriTime');
                        setDantoriTime(editedTime);
                        if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                          setDandoriAmountIn(
                            changeAmtwithTime(dandoriAmountIn, 'min', 'programDandoriTime', 'inc', '')
                          );
                        }
                        setTotalTime(handleTotalTime(editedTime, workTime, naibuProcessValidationTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let totalSec = Number(setTimetoSec(dantoriTime));
                        if (totalSec >= 60) {
                          let editedTime = handelDecreaseTime(dantoriTime, 'min', 'programDandoriTime');
                          setDantoriTime(editedTime);
                          if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                            setDandoriAmountIn(
                              changeAmtwithTime(dandoriAmountIn, 'min', 'programDandoriTime', 'dec', '')
                            );
                          }
                          setTotalTime(handleTotalTime(editedTime, workTime, naibuProcessValidationTime));
                        }
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(dantoriTime, 'sec', 'programDandoriTime');
                        setDantoriTime(editedTime);
                        if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                          setDandoriAmountIn(
                            changeAmtwithTime(dandoriAmountIn, 'sec', 'programDandoriTime', 'inc', '')
                          );
                        }
                        setTotalTime(handleTotalTime(editedTime, workTime, naibuProcessValidationTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(setTimetoSec(dantoriTime));
                        if (totalSec > 0) {
                          let editedTime = handelDecreaseTime(dantoriTime, 'sec', 'programDandoriTime');
                          setDantoriTime(editedTime);
                          if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                            setDandoriAmountIn(
                              changeAmtwithTime(dandoriAmountIn, 'sec', 'programDandoriTime', 'dec', '')
                            );
                          }
                          setTotalTime(handleTotalTime(editedTime, workTime, naibuProcessValidationTime));
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column"></Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            作業金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programWorkAmount">{JPYs.format(orgProgramData[0].sagyoAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programWorkTime">{orgProgramData[0].sagyoTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              value={workAmountIn}
              id="programWorkAmountIn"
              style={{ border: 'none' }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              onValueChange={(e) => {
                if (workAmountIn != e) {
                  if (e === undefined) {
                    e = 0;
                  }
                  setWorkAmountIn(e);
                  checkInputWithColor('programWorkAmount', e, 'amt');
                  handleTotalAmt(e, dandoriAmountIn);
                }
              }}
              /* onKeyPress={(e) => {
                if (isNumber(e.key)) {
                  checkInputWithColor('programWorkAmount', e, 'amt');
                }
              }} */
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'programWorkAmountCu'} style={{ display: 'none' }}>
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={workTime}
                  id="programWorkTimeIn"
                  style={{ width: '100%' }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    //disableTimer('test');
                    if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                      setDandoriAmountIn(
                        changeAmtwithTime(workTime, 'input', 'programWorkTime', 'input', e.target.value)
                      );
                    }
                    setTotalTime(handleTotalTime(e.target.value, dantoriTime, naibuProcessValidationTime));
                    setWorkTime(e.target.value);
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {/* <Space> */}
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(workTime, '10min', 'programWorkTime');
                        setWorkTime(editedTime);
                        if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                          setWorkAmountIn(changeAmtwithTime(workAmountIn, '10min', 'programWorkTime', 'inc', ''));
                        }
                        setTotalTime(handleTotalTime(editedTime, dantoriTime, naibuProcessValidationTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(setTimetoSec(workTime));
                        if (totalSec >= 600) {
                          let editedTime = handelDecreaseTime(workTime, '10min', 'programWorkTime');
                          setWorkTime(editedTime);
                          if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                            if (workAmountIn <= 600) {
                              setWorkAmountIn(changeAmtwithTime(totalSec, '10min', 'programWorkTime', 'dec', ''));
                            } else {
                              setWorkAmountIn(changeAmtwithTime(workAmountIn, '10min', 'programWorkTime', 'dec', ''));
                            }
                          }
                          setTotalTime(handleTotalTime(editedTime, dantoriTime, naibuProcessValidationTime));
                        }
                      }}
                    />

                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let editedTime = handelIncreaseTime(workTime, 'min', 'programWorkTime');
                        setWorkTime(editedTime);
                        if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                          setWorkAmountIn(changeAmtwithTime(workAmountIn, 'min', 'programWorkTime', 'inc', ''));
                        }
                        setTotalTime(handleTotalTime(editedTime, dantoriTime, naibuProcessValidationTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let totalSec = Number(setTimetoSec(workTime));
                        if (totalSec >= 60) {
                          let editedTime = handelDecreaseTime(workTime, 'min', 'programWorkTime');
                          setWorkTime(editedTime);
                          if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                            setWorkAmountIn(changeAmtwithTime(workAmountIn, 'min', 'programWorkTime', 'dec', ''));
                          }
                          setTotalTime(handleTotalTime(editedTime, dantoriTime, naibuProcessValidationTime));
                        }
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let editedTime = handelIncreaseTime(workTime, 'sec', 'programWorkTime');
                        setWorkTime(editedTime);
                        if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                          setWorkAmountIn(changeAmtwithTime(workAmountIn, 'sec', 'programWorkTime', 'inc', ''));
                        }
                        setTotalTime(handleTotalTime(editedTime, dantoriTime, naibuProcessValidationTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let totalSec = Number(setTimetoSec(workTime));
                        if (totalSec > 0) {
                          let editedTime = handelDecreaseTime(workTime, 'sec', 'programWorkTime');
                          setWorkTime(editedTime);
                          if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                            setWorkAmountIn(changeAmtwithTime(workAmountIn, 'sec', 'programWorkTime', 'dec', ''));
                          }
                          setTotalTime(handleTotalTime(editedTime, dantoriTime, naibuProcessValidationTime));
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column"></Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            要素数
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programCount">{eleCount}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="input-col">
            <Input
              value={eleCount}
              id="programCountIn"
              style={{ border: 'none', width: '45%' }}
              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
              onChange={(e) => {
                setElecount(e.target.value);
                checkInput('programCount', e.target.value);
              }}
            ></Input>
          </Col>
          <Col span={4} className="oyabuhin-detail-col"></Col>
          <Col span={1} className="oyabuhin-detail-col">
            C㎡
          </Col>
          <Col span={4} className="formula-column"></Col>
        </Row>
        <Row
          style={{ color: 'black', display: naibuProcessValidationAmt == 0 ? 'none' : '' }}
          className="oyabuhin-detail oyabuhin-row"
        >
          <Col span={4} className="oyabuhin-detail-col">
            工程内検査金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="naibuProcessValidationAmt">{JPYs.format(orgProgramData[0].naibuProcessValidationAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="naibuProcessValidationTime">{orgProgramData[0].naibuProcessValidationTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              value={naibuProcessValidationAmt}
              id="naibuProcessValidationAmtIn"
              style={{ border: 'none' }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              onValueChange={(e) => {
                if (naibuProcessValidationAmt != e) {
                  if (e === undefined) {
                    e = 0;
                  }
                  setNaibuProcessValidationAmt(e);
                  checkInputWithColor('naibuProcessValidationAmt', e, 'amt');
                  handleTotalAmt(e, naibuProcessValidationAmt);
                }
                ////setResetColor(false);
              }}
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'naibuProcessValidationAmtCu'} style={{ display: 'none' }}>
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={naibuProcessValidationTime}
                  id="naibuProcessValidationTimeIn"
                  style={{ width: '100%' }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    ////setResetColor(false);
                    if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                      setDandoriAmountIn(
                        changeAmtwithTime(
                          naibuProcessValidationAmt,
                          'input',
                          'naibuProcessValidationTime',
                          'input',
                          e.target.value
                        )
                      );
                    }
                    setTotalTime(handleTotalTime(e.target.value, workTime, dantoriTime));
                    setDantoriTime(e.target.value);
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {/* <Space> */}
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(
                          naibuProcessValidationTime,
                          '10min',
                          'naibuProcessValidationTime'
                        );
                        setNaibuProcessValidationTime(editedTime);
                        if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                          setNaibuProcessValidationAmt(
                            changeAmtwithTime(
                              naibuProcessValidationAmt,
                              '10min',
                              'naibuProcessValidationTime',
                              'inc',
                              ''
                            )
                          );
                        }
                        //setResetColor(false);
                        setTotalTime(handleTotalTime(editedTime, workTime, dantoriTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(setTimetoSec(naibuProcessValidationTime));
                        if (totalSec >= 600) {
                          let editedTime = handelDecreaseTime(
                            naibuProcessValidationTime,
                            '10min',
                            'naibuProcessValidationTime'
                          );
                          setNaibuProcessValidationTime(editedTime);
                          if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                            setDandoriAmountIn(
                              changeAmtwithTime(
                                naibuProcessValidationAmt,
                                '10min',
                                'naibuProcessValidationTime',
                                'dec',
                                ''
                              )
                            );
                          }
                          //setResetColor(false);
                          setTotalTime(handleTotalTime(editedTime, workTime, dantoriTime));
                        }
                      }}
                    />

                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(
                          naibuProcessValidationTime,
                          'min',
                          'naibuProcessValidationTime'
                        );
                        setNaibuProcessValidationTime(editedTime);
                        if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                          setNaibuProcessValidationAmt(
                            changeAmtwithTime(naibuProcessValidationAmt, 'min', 'naibuProcessValidationTime', 'inc', '')
                          );
                        }
                        setTotalTime(handleTotalTime(editedTime, workTime, dantoriTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(setTimetoSec(naibuProcessValidationTime));
                        if (totalSec >= 60) {
                          let editedTime = handelDecreaseTime(
                            naibuProcessValidationTime,
                            'min',
                            'naibuProcessValidationTime'
                          );
                          console.log(editedTime);
                          setNaibuProcessValidationTime(editedTime);
                          if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                            setNaibuProcessValidationAmt(
                              changeAmtwithTime(
                                naibuProcessValidationAmt,
                                'min',
                                'naibuProcessValidationTime',
                                'dec',
                                ''
                              )
                            );
                          }
                          setTotalTime(handleTotalTime(editedTime, workTime, dantoriTime));
                        }
                      }}
                    />

                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(
                          naibuProcessValidationTime,
                          'sec',
                          'naibuProcessValidationTime'
                        );
                        setNaibuProcessValidationTime(editedTime);
                        if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                          setNaibuProcessValidationAmt(
                            changeAmtwithTime(naibuProcessValidationAmt, 'sec', 'naibuProcessValidationTime', 'inc', '')
                          );
                        }
                        setTotalTime(handleTotalTime(editedTime, workTime, dantoriTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(setTimetoSec(naibuProcessValidationTime));
                        if (totalSec > 0) {
                          let editedTime = handelDecreaseTime(
                            naibuProcessValidationTime,
                            'sec',
                            'naibuProcessValidationTime'
                          );
                          setNaibuProcessValidationTime(editedTime);
                          if (checkShowHideIcon('naibuProcessValidationAmtCu') === 'none') {
                            setNaibuProcessValidationAmt(
                              changeAmtwithTime(
                                naibuProcessValidationAmt,
                                'sec',
                                'naibuProcessValidationTime',
                                'dec',
                                ''
                              )
                            );
                          }
                          setTotalTime(handleTotalTime(editedTime, workTime, dantoriTime));
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column"></Col>
        </Row>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Program;
