/**
 * クラス名：板金のブランクのパンチ条件画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
const Device_Blank_PunchCondition = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [punchConditionList, setPunchConditionList] = useState([]);
  useEffect(() => {
    setEditMode(props.editMode);
    setPunchConditionList(props.punchConditionList);
  }, [props.editMode, props.punchConditionList]);

  const changeValue = (subItemId, value) => {
    // const regex = /^([0-9]|￥)+$/;
    const regex = /^[0-9,¥]+$/;
    // value = value.replace(/[０-９]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xfee0));

    if (regex.test(value)) {
      const updatedData = punchConditionList.map((subItem) => {
        if (subItem.key === subItemId) {
          return { ...subItem, val: value };
        }
        return subItem;
      });

      setPunchConditionList(updatedData);
      props.updateList(updatedData);
    }
  };
  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table style={{ width: '60%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center', verticalAlign: 'middle', height: 30 }}>
              <th style={{ width: '20%' }} className="tbl-header">
                1パンチ丸穴径
              </th>
              <th style={{ width: '20%' }} className="tbl-header">
                1パンチ矩形周長
              </th>
              <th style={{ width: '20%' }} className="tbl-header">
                二ブリングピッチ
              </th>
            </tr>
          </thead>
          {/* <tbody>
            <tr style={{ textAlign: 'center' }}>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            </tr>
          </tbody> */}
          <tbody>
            <tr>
              {punchConditionList.map((item) => (
                <td>
                  <Input
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                    value={item.val}
                    onChange={(event) => changeValue(item.key, event.target.value)}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default Device_Blank_PunchCondition;
