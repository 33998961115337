export const iq3_devicesMasterList = [
  {
    key: 1,
    type: 'iQ3',
    deviceNo: 1,
    deviceTypes: '標準',
    code: '012',
    processName: 'プログラム',
    processType: 'プログラム',
    deviceCode: '',
    deviceName: 'プログラム_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '1.0',
          },
          {
            key: 2,
            value: '1.25',
          },
          {
            key: 3,
            value: '1.5',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 2,
    type: 'iQ3',
    deviceNo: 2,
    deviceTypes: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    deviceCode: '',
    deviceName: 'レーザー_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: 'レーザー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'パンチ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '複合機',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:01:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: 'レーザー',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: 'レーザー',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: 'パンチ',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 2,
        processDetailNo: 3,
        displayFlg: true,
        detailCode: '003',
        detailType: '複合機',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        max: '20',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        max: '20',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1.25',
          },
        ],
      },

      {
        key: 2,
        max: 999,
        lstData: [
          {
            key: 1,
            value: '1.25',
          },

          {
            key: 2,
            value: '1.5',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        max: '20',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '1.0',
          },
          {
            key: 2,
            value: '0.8',
          },
          {
            key: 3,
            value: '1.2',
          },
          {
            key: 4,
            value: '1.0',
          },
        ],
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:05:00',
    pierWidth1: '10',
    pierWidth2: '10',
    grabAllowance1: '20',
    grabAllowance2: '40',
    processRange1: '0',
    processRange2: '0',
    pickupTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:12',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '8000',
    kegakiSokudoVal: '4500',
  },
  {
    key: 3,
    type: 'iQ3',
    deviceNo: 3,
    deviceTypes: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    deviceCode: '',
    deviceName: 'パンチ_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: 'レーザー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'パンチ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '複合機',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:02:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: 'パンチ',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: 'レーザー',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: 'パンチ',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 2,
        processDetailNo: 3,
        displayFlg: true,
        detailCode: '003',
        detailType: '複合機',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },

      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '00:00:00',
          },

          {
            key: 2,
            value: '00:10:00',
          },
        ],
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '15.0',
          },
          {
            key: 2,
            value: '26.0',
          },
          {
            key: 7,
            value: '51.0',
          },
          {
            key: 6,
            value: '81.0',
          },
          {
            key: 5,
            value: '101.0',
          },
          {
            key: 4,
            value: '201.0',
          },
          {
            key: 3,
            value: '301.0',
          },
          {
            key: 8,
            value: '99999',
          },
        ],
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '2',
          },
          {
            key: 6,
            value: '4',
          },

          {
            key: 5,
            value: '6',
          },

          {
            key: 4,
            value: '6',
          },

          {
            key: 3,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    processInspectionTime: '00:05:00',
    pierWidth1: '15',
    pierWidth2: '15',
    grabAllowance1: '30',
    grabAllowance2: '100',
    processRange1: '0',
    processRange2: '0',
    pickupTime: '00:01:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '50',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '60',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '6',
      },
    ],
    drillingTime: '00:00:06',
    forwardSpeed: '3000',
    rollerSpeed: '800',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 4,
    type: 'iQ3',
    deviceNo: 4,
    deviceTypes: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    deviceCode: '',
    deviceName: '複合機_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: 'レーザー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'パンチ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '複合機',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:02:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '複合機',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: 'レーザー',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: 'パンチ',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 2,
        processDetailNo: 3,
        displayFlg: true,
        detailCode: '003',
        detailType: '複合機',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'Easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },

      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '00:00:00',
          },

          {
            key: 2,
            value: '00:05:00',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },

          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },

          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },

          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },

          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.3',
          },
          {
            key: 4,
            value: '1.3',
          },
        ],
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:05:00',
    pierWidth1: '10',
    pierWidth2: '10',
    grabAllowance1: '30',
    grabAllowance2: '80',
    processRange1: '0',
    processRange2: '0',
    pickupTime: '00:1:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '50',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '0',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '0',
      },
    ],
    drillingTime: '00:00:06',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '5000',
    kegakiSokudoVal: '4000',
  },
  {
    key: 5,
    type: 'iQ3',
    deviceNo: 5,
    deviceTypes: '標準',
    code: '014',
    processName: 'ベンディング',
    processType: 'ベンディング',
    deviceCode: '',
    deviceName: 'ベンディング_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '曲げ種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '曲げ_V',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '曲げ_R',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '曲げ_FR',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '曲げ_3R',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '曲げ_HM',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: '曲げ_Z',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '曲げ_AMA',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '曲げ_01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 8,
            detailType: '曲げ_02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 9,
            detailType: '曲げ_03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 10,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '曲げ_V',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: '曲げ_R',
        lstData: [
          {
            key: 1,
            value: '00:00:15',
          },
          {
            key: 2,
            value: '00:00:15',
          },
        ],
      },
      {
        key: 3,
        materialType: '曲げ_FR',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
      },
      {
        key: 4,
        materialType: '曲げ_3R',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:01:00',
          },
        ],
      },
      {
        key: 5,
        materialType: '曲げ_HM',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:30',
          },
        ],
      },
      {
        key: 6,
        materialType: '曲げ_Z',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:30',
          },
        ],
      },
      {
        key: 7,
        materialType: '曲げ_AMA',
        lstData: [
          {
            key: 1,
            value: '00:00:15',
          },
          {
            key: 2,
            value: '00:00:15',
          },
        ],
      },
      {
        key: 8,
        materialType: '曲げ_01',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 9,
        materialType: '曲げ_02',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 10,
        materialType: '曲げ_03',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:00:40',
          },
          {
            key: 3,
            value: '00:00:30',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: '5',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: true,
        val: '10',
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '曲げ_V',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 3,
            value: '00:03:00',
          },
          {
            key: 2,
            value: '00:03:00',
          },
        ],
      },
      {
        key: 2,
        materialType: '曲げ_R',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 3,
            value: '00:03:00',
          },
          {
            key: 2,
            value: '00:03:00',
          },
        ],
      },
      {
        key: 3,
        materialType: '曲げ_FR',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 3,
            value: '00:03:00',
          },
          {
            key: 2,
            value: '00:03:00',
          },
        ],
      },
      {
        key: 4,
        materialType: '曲げ_3R',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 5,
        materialType: '曲げ_HM',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 6,
        materialType: '曲げ_Z',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 7,
        materialType: '曲げ_AMA',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 8,
        materialType: '曲げ_01',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 9,
        materialType: '曲げ_02',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 10,
        materialType: '曲げ_03',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: '5',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '20',
        displayFlag: true,
      },
      {
        id: 3,
        val: '30',
        displayFlag: true,
      },
      {
        id: 2,
        val: '31',
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 5,
            value: '1.1',
          },
          {
            key: 4,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.3',
          },
          {
            key: 2,
            value: '1.35',
          },
        ],
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '曲げ_V',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '曲げ_R',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '曲げ_FR',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '曲げ_3R',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '曲げ_HM',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: '曲げ_Z',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: '曲げ_AMA',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: '曲げ_01',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '曲げ_02',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: '曲げ_03',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },

          {
            key: 2,
            value: '00:02:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:02:00',
          },

          {
            key: 2,
            value: '00:05:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:05:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 6,
    type: 'iQ3',
    deviceNo: 6,
    deviceTypes: '標準',
    code: '015',
    processName: 'バリ取',
    processType: 'バリ取',
    deviceCode: '',
    deviceName: '手動バリ取_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 2,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:01:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '手動',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: '手動',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: '自動',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },

          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },

          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 3,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    naikei_bari: '1000',
    kakosokudo_bari: '1200',
    naikei_min: '10',
    naikei_max: '999',
    ana_min: '10',
    ana_max: '50',
  },
  {
    key: 7,
    type: 'iQ3',
    deviceNo: 7,
    deviceTypes: '標準',
    code: '015',
    processName: 'バリ取',
    processType: 'バリ取',
    deviceCode: '',
    deviceName: '自動バリ取_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 2,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:03:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
      {
        key: 3,
        max: '20',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '自動',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: '手動',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: '自動',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
          {
            key: 3,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:40',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },

          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },

          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:03:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 8,
    type: 'iQ3',
    deviceNo: 8,
    deviceTypes: '標準',
    code: '016',
    processName: '表面処理',
    processType: '表面処理',
    deviceCode: '',
    deviceName: '表面処理_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '脱脂',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'ユニクロメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'クロメート',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ニッケルメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'クロームメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '亜鉛メッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'メッキ01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'メッキ02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'メッキ03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'メラニン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'アクリル塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ウレタン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '粉体塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: 'カチオン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'プライマー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '塗装01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '塗装02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1.35',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: '',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '99999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },

          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },

          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 9,
    type: 'iQ3',
    deviceNo: 9,
    deviceTypes: '標準',
    code: '017',
    processName: '2次加工',
    processType: '2次加工',
    deviceCode: '',
    deviceName: '2次加工_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '2次加工種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'バーリング',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'タップ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'サラ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'カシメ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: 'ボルト圧入',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'ナット圧入',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '成形型穴',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '多工程穴',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },

      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'バーリング',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'タップ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サラ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'カシメ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'ボルト圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'ナット圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: '成形型穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: '多工程穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:20',
          },
          {
            key: 3,
            value: '00:00:15',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'バーリング',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'タップ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サラ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'カシメ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'ボルト圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'ナット圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: '成形型穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: '多工程穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'バーリング',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:01:00',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'タップ',
        lstData: [
          {
            key: 1,
            value: '00:00:12',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:30',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'サラ',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'カシメ',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'ボルト圧入',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'ナット圧入',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 7,
        materialType: '成形型穴',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 8,
        materialType: '多工程穴',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },

          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 10,
    type: 'iQ3',
    deviceNo: 10,
    deviceTypes: '標準',
    code: '018',
    processName: '溶接',
    processType: '溶接',
    deviceCode: '',
    deviceName: '溶接_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'アルゴン',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '半自動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '溶接線01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '溶接線02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '溶接線03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'YAGハンディ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:02:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'YAGロボット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'ロウ付',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 8,
            detailType: '開先取り',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 9,
            detailType: 'Vカット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '点付',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'プラグ溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '定置スポット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'テーブルスポット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動スタッド',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動スタッド',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ナット溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'ボルト溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '50',
          },
        ],
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '30',
          },

          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '30',
          },

          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '30',
          },

          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '30',
          },

          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '30',
          },

          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '30',
          },

          {
            key: 2,
            value: '30',
          },
        ],
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },

          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '1.2',
          },

          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },

          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },

          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2.0',
          },

          {
            key: 2,
            value: '2.0',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2.0',
          },

          {
            key: 2,
            value: '2.0',
          },
        ],
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },

          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },

          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '00:00:12',
          },

          {
            key: 2,
            value: '00:00:12',
          },
        ],
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '00:00:18',
          },

          {
            key: 2,
            value: '00:00:18',
          },
        ],
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },

          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },

          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },

          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },

          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '50',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '70',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '60',
          },

          {
            key: 2,
            value: '80',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '60',
          },

          {
            key: 2,
            value: '160',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '100',
          },
        ],
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },

      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 12,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 11,
    type: 'iQ3',
    deviceNo: 11,
    deviceTypes: '標準',
    code: '019',
    processName: '溶接仕上',
    processType: '溶接仕上',
    deviceCode: '',
    deviceName: '溶接仕上_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'バフ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '酸洗い',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'サンダー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'Vカット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '開先',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 5,
        max: '2.30',
        displayFlag: true,
      },
      {
        id: 4,
        max: '4.50',
        displayFlag: true,
      },
      {
        id: 3,
        max: '6.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '100',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '80',
          },

          {
            key: 2,
            value: '60',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '60',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },

      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '開先',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '開先',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '開先',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: '',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 12,
    type: 'iQ3',
    deviceNo: 12,
    deviceTypes: '標準',
    code: '020',
    processName: 'シャーリング',
    processType: 'シャーリング',
    deviceCode: '',
    deviceName: 'シャーリング_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:03:00',
    processTimeByUnit: '00:00:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 5,
        max: '2.30',
        displayFlag: true,
      },
      {
        id: 4,
        max: '4.50',
        displayFlag: true,
      },
      {
        id: 3,
        max: '6.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },

      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },

      {
        key: 22,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 13,
    type: 'iQ3',
    deviceNo: 13,
    deviceTypes: '標準',
    code: '021',
    processName: '検査',
    processType: '検査',
    deviceCode: '',
    deviceName: '検査_標準',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '子部品点数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '子部品種類数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    kihonDantoriTime: '00:01:00',
    processTimeByUnit: '00:03:00',
    dantoriFlag: false,
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 5,
        max: '2.30',
        displayFlag: true,
      },
      {
        id: 4,
        max: '4.50',
        displayFlag: true,
      },
      {
        id: 3,
        max: '6.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1.3',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },

          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    meishouMasterData: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    meishouLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },

      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },

      {
        key: 2,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },

      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '１',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },

      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },

          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    iq3DantoriJikanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3KosuuLstData: [
      {
        key: 1,
        val: '',
      },
      {
        key: 2,
        val: '',
      },
      {
        key: 3,
        val: '',
      },
      {
        key: 4,
        val: '',
      },
    ],
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },

      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },

      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    processInspectionTime: '00:00:00',
    pierWidth1: '',
    pierWidth2: '',
    grabAllowance1: '',
    grabAllowance2: '',
    processRange1: '',
    processRange2: '',
    pickupTime: '',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
  },
  {
    key: 14,
    type: 'iQ3',
    deviceNo: 14,
    deviceTypes: '追加',
    code: '022',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: 'ネスティング_標準',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '',
    initialAmount: 0,
  },
  {
    key: 15,
    type: 'iQ3',
    deviceNo: 15,
    deviceTypes: '追加',
    code: '022',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_1',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '仮付',
    initialAmount: 0,
  },
  {
    key: 16,
    type: 'iQ3',
    deviceNo: 16,
    deviceTypes: '追加',
    code: '022',
    processName: '追加工程①',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社内工程_2',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: 'ネスティング',
    initialAmount: 0,
  },
  {
    key: 17,
    type: 'iQ3',
    deviceNo: 17,
    deviceTypes: '追加',
    code: '023',
    processName: '追加工程②',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社外工程_1',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '株式会社社外_1',
    customerCode: '00013',
    inputFlag: false,
    itakuFlag: true,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '仮付',
    initialAmount: 0,
  },
  {
    key: 18,
    type: 'iQ3',
    deviceNo: 18,
    deviceTypes: '追加',
    code: '023',
    processName: '追加工程②',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社外工程_2',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: 'ネスティング',
    initialAmount: 0,
  },
  {
    key: 19,
    type: 'iQ3',
    deviceNo: 19,
    deviceTypes: '追加',
    code: '023',
    processName: '追加工程②',
    processType: 'カスタム',
    deviceCode: '',
    deviceName: '社外工程_3',
    setupCharge: '0',
    processCharge: '0',
    multiCharge: '',
    remarks: '',
    tsuikaKouteiTypes: ['仮付', 'ネスティング', '水張検査'],
    customerName: '株式会社社外_3',
    customerCode: '00014',
    inputFlag: false,
    itakuFlag: true,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    kosuuLstData: [],
    processDetails: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '仮付',
            displayFlg: true,
          },
          {
            key: 1,
            detailType: 'ネスティング',
            displayFlg: true,
          },
          {
            key: 2,
            detailType: '水張検査',
            displayFlg: true,
          },
        ],
        defaultRow: 0,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kobuhinSuu: [
      {
        key: 1,
        name: '子部品点数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
      {
        key: 2,
        name: '子部品種類数',
        lstData: [
          {
            key: 1,
            name: 'iQ3',
            val: '',
          },
          {
            key: 2,
            name: 'iQ5',
            val: '',
          },
          {
            key: 3,
            name: 'iQ7',
            val: '',
          },
        ],
      },
    ],
    kouteiType: '水張検査',
    initialAmount: 0,
  },
  {
    key: 20,
    type: 'iQ3',
    deviceNo: 20,
    deviceTypes: '標準',
    code: '012',
    processName: 'プログラム',
    processType: 'プログラム',
    deviceCode: '',
    deviceName: 'プログラム_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '1.0',
          },
          {
            key: 2,
            value: '1.25',
          },
          {
            key: 3,
            value: '1.5',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 21,
    type: 'iQ3',
    deviceNo: 21,
    deviceTypes: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    deviceCode: '',
    deviceName: 'レーザー_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: 'レーザー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'パンチ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '複合機',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:01:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: 'レーザー',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: 'レーザー',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: 'パンチ',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 2,
        processDetailNo: 3,
        displayFlg: true,
        detailCode: '003',
        detailType: '複合機',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        max: '20',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.25',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.5',
          },
        ],
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        max: '20',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        max: '20',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
      },
    ],
    processInspectionTime: '00:05:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:12',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    okuriSokudoVal: '8000',
    kegakiSokudoVal: '4500',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '1.0',
          },
          {
            key: 2,
            value: '0.8',
          },
          {
            key: 3,
            value: '1.2',
          },
          {
            key: 4,
            value: '1.0',
          },
        ],
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 22,
    type: 'iQ3',
    deviceNo: 22,
    deviceTypes: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    deviceCode: '',
    deviceName: 'パンチ_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: 'レーザー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'パンチ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '複合機',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:02:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: 'パンチ',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: 'レーザー',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: 'パンチ',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 2,
        processDetailNo: 3,
        displayFlg: true,
        detailCode: '003',
        detailType: '複合機',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '00:00:00',
          },
          {
            key: 2,
            value: '00:10:00',
          },
        ],
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:05:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '50',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '60',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '6',
      },
    ],
    drillingTime: '00:00:06',
    forwardSpeed: '3000',
    rollerSpeed: '800',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '15.0',
          },
          {
            key: 2,
            value: '26.0',
          },
          {
            key: 7,
            value: '51.0',
          },
          {
            key: 6,
            value: '81.0',
          },
          {
            key: 5,
            value: '101.0',
          },
          {
            key: 4,
            value: '201.0',
          },
          {
            key: 3,
            value: '301.0',
          },
          {
            key: 8,
            value: '99999',
          },
        ],
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '2',
          },
          {
            key: 6,
            value: '4',
          },
          {
            key: 5,
            value: '6',
          },
          {
            key: 4,
            value: '6',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 23,
    type: 'iQ3',
    deviceNo: 23,
    deviceTypes: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    deviceCode: '',
    deviceName: '複合機_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: 'レーザー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'パンチ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '複合機',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:02:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '複合機',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: 'レーザー',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: 'パンチ',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 2,
        processDetailNo: 3,
        displayFlg: true,
        detailCode: '003',
        detailType: '複合機',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2000',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '300',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '00:00:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'レーザー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'パンチ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '複合機',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '酸素',
          },
          {
            key: 2,
            value: '酸素',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:05:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '50',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '0',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '0',
      },
    ],
    drillingTime: '00:00:06',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:01',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:01',
          },
          {
            key: 2,
            value: '00:00:04',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '5000',
    kegakiSokudoVal: '4000',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'Easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.3',
          },
          {
            key: 4,
            value: '1.3',
          },
        ],
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 24,
    type: 'iQ3',
    deviceNo: 24,
    deviceTypes: '標準',
    code: '017',
    processName: '2次加工',
    processType: '2次加工',
    deviceCode: '',
    deviceName: '2次加工_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '2次加工種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'バーリング',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'タップ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'サラ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'カシメ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: 'ボルト圧入',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'ナット圧入',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '成形型穴',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '多工程穴',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'バーリング',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'タップ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サラ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'カシメ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'ボルト圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'ナット圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: '成形型穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: '多工程穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:20',
          },
          {
            key: 3,
            value: '00:00:15',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'バーリング',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'タップ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サラ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'カシメ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'ボルト圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'ナット圧入',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: '成形型穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: '多工程穴',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'バーリング',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:01:00',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'タップ',
        lstData: [
          {
            key: 1,
            value: '00:00:12',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:30',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'サラ',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'カシメ',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'ボルト圧入',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'ナット圧入',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 7,
        materialType: '成形型穴',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
      {
        key: 8,
        materialType: '多工程穴',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '00:00:20',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 25,
    type: 'iQ3',
    deviceNo: 25,
    deviceTypes: '標準',
    code: '014',
    processName: 'ベンディング',
    processType: 'ベンディング',
    deviceCode: '',
    deviceName: 'ベンディング_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '曲げ種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '曲げ_V',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '曲げ_R',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '曲げ_FR',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '曲げ_3R',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '曲げ_HM',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: '曲げ_Z',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '曲げ_AMA',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '曲げ_01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 8,
            detailType: '曲げ_02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 9,
            detailType: '曲げ_03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 10,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '曲げ_V',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: '曲げ_R',
        lstData: [
          {
            key: 1,
            value: '00:00:15',
          },
          {
            key: 2,
            value: '00:00:15',
          },
        ],
      },
      {
        key: 3,
        materialType: '曲げ_FR',
        lstData: [
          {
            key: 1,
            value: '00:00:05',
          },
          {
            key: 2,
            value: '00:00:05',
          },
        ],
      },
      {
        key: 4,
        materialType: '曲げ_3R',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:01:00',
          },
        ],
      },
      {
        key: 5,
        materialType: '曲げ_HM',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:30',
          },
        ],
      },
      {
        key: 6,
        materialType: '曲げ_Z',
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:00:30',
          },
        ],
      },
      {
        key: 7,
        materialType: '曲げ_AMA',
        lstData: [
          {
            key: 1,
            value: '00:00:15',
          },
          {
            key: 2,
            value: '00:00:15',
          },
        ],
      },
      {
        key: 8,
        materialType: '曲げ_01',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 9,
        materialType: '曲げ_02',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 10,
        materialType: '曲げ_03',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:00:40',
          },
          {
            key: 3,
            value: '00:00:30',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: '5',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: true,
        val: '10',
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '曲げ_V',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 3,
            value: '00:03:00',
          },
          {
            key: 2,
            value: '00:03:00',
          },
        ],
      },
      {
        key: 2,
        materialType: '曲げ_R',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 3,
            value: '00:03:00',
          },
          {
            key: 2,
            value: '00:03:00',
          },
        ],
      },
      {
        key: 3,
        materialType: '曲げ_FR',
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 3,
            value: '00:03:00',
          },
          {
            key: 2,
            value: '00:03:00',
          },
        ],
      },
      {
        key: 4,
        materialType: '曲げ_3R',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 5,
        materialType: '曲げ_HM',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 6,
        materialType: '曲げ_Z',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 7,
        materialType: '曲げ_AMA',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 8,
        materialType: '曲げ_01',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 9,
        materialType: '曲げ_02',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
      {
        key: 10,
        materialType: '曲げ_03',
        lstData: [
          {
            key: 1,
            value: '00:03:00',
          },
          {
            key: 3,
            value: '00:05:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: '5',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '20',
        displayFlag: true,
      },
      {
        id: 3,
        val: '30',
        displayFlag: true,
      },
      {
        id: 2,
        val: '31',
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 5,
            value: '1.1',
          },
          {
            key: 4,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.3',
          },
          {
            key: 2,
            value: '1.35',
          },
        ],
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '曲げ_V',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '曲げ_R',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '曲げ_FR',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '曲げ_3R',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '曲げ_HM',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: '曲げ_Z',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: '曲げ_AMA',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: '曲げ_01',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '曲げ_02',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: '曲げ_03',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:00:30',
          },
          {
            key: 2,
            value: '00:02:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:02:00',
          },
          {
            key: 2,
            value: '00:05:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:05:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 26,
    type: 'iQ3',
    deviceNo: 26,
    deviceTypes: '標準',
    code: '015',
    processName: 'バリ取',
    processType: 'バリ取',
    deviceCode: '',
    deviceName: 'バリ取_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 2,
      },
    ],
    kihonDantoriTime: '00:01:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '手動',
    kakouMethods: [
      {
        key: 0,
        processDetailNo: 1,
        displayFlg: true,
        detailCode: '001',
        detailType: '手動',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
      {
        key: 1,
        processDetailNo: 2,
        displayFlg: true,
        detailCode: '002',
        detailType: '自動',
        created: '2023-10-01',
        modified: '2023-10-01',
        remark: '',
        useFlg: false,
      },
    ],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '手動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 3,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
    kakosokudo_bari: '1200',
    naikei_bari: '1000',
    naikei_min: '10',
    naikei_max: '999',
    ana_min: '10',
    ana_max: '50',
  },
  {
    key: 27,
    type: 'iQ3',
    deviceNo: 27,
    deviceTypes: '標準',
    code: '018',
    processName: '溶接',
    processType: '溶接',
    deviceCode: '',
    deviceName: '溶接_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'アルゴン',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '半自動',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '溶接線01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '溶接線02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '溶接線03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'YAGハンディ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:02:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'YAGロボット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'ロウ付',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 8,
            detailType: '開先取り',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 9,
            detailType: 'Vカット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '点付',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'プラグ溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '定置スポット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'テーブルスポット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: [
          {
            key: 0,
            detailType: '手動スタッド',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '自動スタッド',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:10:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ナット溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'ボルト溶接',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '70',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '60',
          },
          {
            key: 2,
            value: '80',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '60',
          },
          {
            key: 2,
            value: '160',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '100',
          },
        ],
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '00:00:10',
          },
          {
            key: 2,
            value: '00:00:10',
          },
        ],
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '00:00:20',
          },
          {
            key: 2,
            value: '00:00:20',
          },
        ],
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '00:00:12',
          },
          {
            key: 2,
            value: '00:00:12',
          },
        ],
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '00:00:18',
          },
          {
            key: 2,
            value: '00:00:18',
          },
        ],
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '1.2',
          },
          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '1.5',
          },
          {
            key: 2,
            value: '1.0',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '2.0',
          },
          {
            key: 2,
            value: '2.0',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '2.0',
          },
          {
            key: 2,
            value: '2.0',
          },
        ],
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '30',
          },
          {
            key: 2,
            value: '30',
          },
        ],
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '50',
          },
          {
            key: 2,
            value: '50',
          },
        ],
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 12,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 28,
    type: 'iQ3',
    deviceNo: 28,
    deviceTypes: '標準',
    code: '019',
    processName: '溶接仕上',
    processType: '溶接仕上',
    deviceCode: '',
    deviceName: '溶接仕上_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'バフ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '酸洗い',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'サンダー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'Vカット',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '開先',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 5,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '100',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '80',
          },
          {
            key: 2,
            value: '60',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '60',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '開先',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '開先',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '開先',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        max: '',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 29,
    type: 'iQ3',
    deviceNo: 29,
    deviceTypes: '標準',
    code: '016',
    processName: '表面処理',
    processType: '表面処理',
    deviceCode: '',
    deviceName: '表面処理_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: [
          {
            key: 0,
            detailType: '脱脂',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'ユニクロメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'クロメート',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ニッケルメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: 'クロームメッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: '亜鉛メッキ',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:01:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'メッキ01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: 'メッキ02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: 'メッキ03',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:05:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: [
          {
            key: 0,
            detailType: 'メラニン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: 'アクリル塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: 'ウレタン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 3,
            detailType: '粉体塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 4,
            detailType: 'カチオン塗装',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 5,
            detailType: 'プライマー',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 6,
            detailType: '塗装01',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 7,
            detailType: '塗装02',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:03:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 8,
      },
    ],
    kihonDantoriTime: '00:00:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: '',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '99999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.35',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '脱脂',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '00:01:00',
          },
          {
            key: 2,
            value: '00:01:00',
          },
        ],
        max: 999,
        displayFlag: true,
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 30,
    type: 'iQ3',
    deviceNo: 30,
    deviceTypes: '標準',
    code: '020',
    processName: 'シャーリング',
    processType: 'シャーリング',
    deviceCode: '',
    deviceName: 'シャーリング_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [],
    kihonDantoriTime: '00:03:00',
    processTimeByUnit: '00:00:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 22,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
        displayFlag: true,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
  {
    key: 31,
    type: 'iQ3',
    deviceNo: 31,
    deviceTypes: '標準',
    code: '021',
    processName: '検査',
    processType: '検査',
    deviceCode: '',
    deviceName: '検査_SP',
    setupCharge: '-',
    processCharge: '-',
    multiCharge: '有',
    remarks: '',
    processDetails: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: [
          {
            key: 0,
            detailType: '子部品点数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 1,
            detailType: '子部品種類数',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
          {
            key: 2,
            detailType: '重量',
            displayFlg: true,
            dantori: 4200,
            kakou: 4200,
            dantoriTime: '00:00:00',
            dantoriSurface: 4200,
            dantoriFlag: false,
            kakouFlag: false,
          },
        ],
        defaultRow: 3,
      },
    ],
    kihonDantoriTime: '00:01:00',
    processTimeByUnit: '00:03:00',
    meishouDetails: [
      {
        id: 1,
        name: '極粗級',
        displayFlag: true,
      },
      {
        id: 2,
        name: '粗級',
        displayFlag: true,
      },
      {
        id: 3,
        name: '中級',
        displayFlag: true,
      },
      {
        id: 4,
        name: '精級',
        displayFlag: true,
      },
    ],
    jikanChajji: [
      {
        id: 1,
        val: 4200,
      },
      {
        id: 2,
        val: 4200,
      },
      {
        id: 3,
        val: 4200,
      },
    ],
    kouteiType: '',
    yousetsuSenchouMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    karitsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanItaatsuMasterData: [
      {
        id: 1,
        max: '1.00',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    yousetsuNanidoItaatsuMasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    areaMasterData: [
      {
        id: 1,
        max: 200,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    weightMasterData: [
      {
        id: 1,
        max: '10',
        displayFlag: true,
      },
      {
        id: 2,
        max: 999,
        displayFlag: true,
      },
    ],
    volumeMasterData: [
      {
        id: 1,
        max: 20,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    kosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
      {
        id: 3,
        val: 3,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouMasterData: [
      {
        id: 1,
        max: 0.8,
        displayFlag: true,
      },
      {
        id: 2,
        max: 1,
        displayFlag: true,
      },
      {
        id: 3,
        max: 1.2,
        displayFlag: true,
      },
    ],
    yousetsuShiageSenchouLstData: [
      {
        key: 1,
        materialType: 'バフ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '酸洗い',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'サンダー',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuSenchouLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    karitsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: 'アルゴン',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '半自動',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '溶接線01',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: '溶接線02',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: '溶接線03',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'YAGハンディ',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 7,
        materialType: 'YAGロボット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 8,
        materialType: 'ロウ付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 9,
        materialType: '開先取り',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 10,
        materialType: 'Vカット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanSizeMasterData: [
      {
        id: 1,
        max: 4,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    toritsukiJikanSizeLstData: [
      {
        key: 1,
        materialType: '手動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '自動スタッド',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'ナット溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'ボルト溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    toritsukiJikanItaatsuLstData: [
      {
        key: 1,
        materialType: '点付',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'プラグ溶接',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '定置スポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'テーブルスポット',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    yousetsuNanidoItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    tapWeldingItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    pitchItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    sizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kosuuLstData: [
      {
        key: 1,
        materialType: '個数',
        lstData: [
          {
            key: 1,
            value: '1.25',
          },
          {
            key: 2,
            value: '1.2',
          },
          {
            key: 3,
            value: '1.15',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeListData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '10',
      },
      {
        key: 2,
        max: '999',
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '１',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    kobuhinKeisuuMasterData: [
      {
        id: 1,
        max: 1,
        displayFlag: true,
      },
      {
        id: 2,
        max: 2,
        displayFlag: true,
      },
    ],
    dantoriSetteiSuuryouLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1.3',
          },
        ],
        isUsed: true,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 2,
            value: '1',
          },
        ],
        isUsed: false,
      },
    ],
    kobuhinKeisuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    customerName: '',
    customerCode: '',
    inputFlag: false,
    itakuFlag: false,
    setupTime: '00:00:00',
    workTime: '00:00:00',
    initialAmount: 0,
    yousosuuMasterData: [
      {
        id: 1,
        max: 10,
        displayFlag: true,
      },
      {
        id: 2,
        max: 25,
        displayFlag: true,
      },
      {
        id: 3,
        max: 50,
        displayFlag: true,
      },
    ],
    sagyouJikanKeisuuLstData: [
      {
        key: 1,
        materialType: '作業時間係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    shokiDankaiJikanLstData: [
      {
        key: 1,
        materialType: '初期段階時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriFlag: false,
    iq3SetupAmount: 4200,
    iq3WorkAmount: 4200,
    iq3ProcessInspection: 4200,
    secondProcessAmount: 4200,
    jointBarashiAmount: 4200,
    kakouType: '',
    kakouMethods: [],
    iq3ItaatsuMasterData: [
      {
        id: 1,
        max: 11,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3BendingTimeLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    lengthMasterData: [
      {
        id: 1,
        max: 30,
        displayFlag: true,
      },
      {
        id: 7,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        max: '450',
        displayFlag: true,
      },
      {
        id: 5,
        max: '600',
        displayFlag: true,
      },
      {
        id: 4,
        max: '800',
        displayFlag: true,
      },
      {
        id: 3,
        max: '',
        displayFlag: false,
      },
      {
        id: 10,
        max: '1200',
        displayFlag: true,
      },
      {
        id: 12,
        max: '1600',
        displayFlag: true,
      },
      {
        id: 11,
        max: '2000',
        displayFlag: true,
      },
      {
        id: 9,
        max: '2500',
        displayFlag: true,
      },
      {
        id: 8,
        max: '3000',
        displayFlag: true,
      },
      {
        id: 15,
        max: '4000',
        displayFlag: true,
      },
      {
        id: 14,
        max: '5000',
        displayFlag: true,
      },
      {
        id: 13,
        max: '6000',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    bendingTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    iq3BendingkosuuLstData: [
      {
        key: 1,
        materialType: '補正時間（秒）',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageSuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageSuuLstData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    mageKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: 2,
        displayFlag: true,
      },
    ],
    mageKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    dantoriSizeLstData: [
      {
        key: 1,
        max: 20,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    iq3MagechouDantoriJikanListData: [
      {
        key: 1,
        materialType: '曲長さ別段取時間',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3AreaMasterData: [
      {
        id: 1,
        max: 100,
        displayFlag: true,
      },

      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KakouJikanListData: [
      {
        key: 1,
        materialType: '加工時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    blankKakousokudoListData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 18,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 17,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 16,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 15,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 14,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 13,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 12,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 11,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 10,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 9,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 8,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 7,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 6,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 20,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 19,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 23,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 22,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 21,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 24,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                name: '丸穴径',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 2,
                name: '加工周長',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
              {
                key: 3,
                name: '速度',
                svalue: '',
                mvalue: '',
                lvalue: '',
              },
            ],
          },
        ],
      },
    ],
    seisanKosuuMasterData: [
      {
        id: 1,
        val: 1,
        displayFlag: true,
      },
      {
        id: 2,
        val: '51',
        displayFlag: true,
      },
    ],
    seisanKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionKosuuLstData: [
      {
        key: 1,
        materialType: '加算係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    honsuuMasterData: [
      {
        id: 1,
        val: '3',
        displayFlag: true,
      },
      {
        id: 7,
        val: '5',
        displayFlag: true,
      },
      {
        id: 6,
        val: '8',
        displayFlag: true,
      },
      {
        id: 5,
        val: '10',
        displayFlag: true,
      },
      {
        id: 4,
        val: '',
        displayFlag: false,
      },
      {
        id: 3,
        val: '',
        displayFlag: false,
      },
      {
        id: 2,
        val: '11',
        displayFlag: true,
      },
    ],
    honsuuLstData: [
      {
        key: 1,
        materialType: '金型段取時間(分)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    iq3SizeMasterData: [
      {
        id: 1,
        max: '4',
        displayFlag: true,
      },
      {
        id: 5,
        max: '6',
        displayFlag: true,
      },
      {
        id: 4,
        max: '10',
        displayFlag: true,
      },
      {
        id: 3,
        max: '14',
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    iq3KaninyuryokuListData: [
      {
        key: 1,
        materialType: '子部品点数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '子部品種類数',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: '重量',
        lstData: [
          {
            key: 1,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 5,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 4,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 3,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
          {
            key: 2,
            value: '',
            list: [
              {
                key: 1,
                materialType: 'SPHC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 2,
                materialType: 'SPCC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 3,
                materialType: 'SECC',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 4,
                materialType: 'SUS',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 5,
                materialType: 'AL',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
              {
                key: 6,
                materialType: 'CU',
                lstData: [
                  {
                    key: 1,
                    value: '',
                  },
                  {
                    key: 2,
                    value: '',
                  },
                ],
                isUsed: false,
              },
            ],
          },
        ],
        isUsed: false,
      },
    ],
    itaatsu1MasterData: [
      {
        id: 1,
        max: 5,
        displayFlag: true,
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    zaishitsuKubunn: ['SPHC', 'SPCC', 'SECC', 'SUS', 'AL', 'CU'],
    iq3ProductSizeLstData: [
      {
        key: 1,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    correctionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    materialHandlingTime: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: '20',
        displayFlag: true,
      },
      {
        key: 2,
        max: 999,
        displayFlag: true,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
      },
    ],
    programSameSizePatternValue: 5,
    gasTypeLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    processInspectionTime: '00:00:00',
    punchConditionList: [
      {
        key: 1,
        name: '1パンチ丸穴径',
        val: '',
      },
      {
        key: 2,
        name: '1パンチ矩形周長',
        val: '',
      },
      {
        key: 3,
        name: '二ブリングピッチ',
        val: '',
      },
    ],
    drillingTime: '00:00:00',
    forwardSpeed: '',
    rollerSpeed: '',
    piercingProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },

          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    punchProcessItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    okuriSokudoVal: '',
    kegakiSokudoVal: '',
    blankKakousokudoItaatsuList: [
      {
        key: 1,
        materialType: '内加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: '外加工',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    gasTypeMasterData: [
      {
        id: 1,
        val: '酸素',
        displayFlag: true,
      },
      {
        id: 2,
        val: '窒素',
        displayFlag: true,
      },
      {
        id: 3,
        val: 'Air',
        displayFlag: true,
      },
      {
        id: 4,
        val: 'easy',
        displayFlag: true,
      },
    ],
    gasKeissuuLstData: [
      {
        key: 1,
        materialType: '係数',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    extractConditionList: [
      {
        key: 1,
        materialType: '加工辺長(mm)',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    jointSizeListData: [
      {
        key: 1,
        materialType: 'ジョイント個数',
        lstData: [
          {
            key: 1,
            value: '1',
          },
          {
            key: 6,
            value: '0',
          },
          {
            key: 5,
            value: '0',
          },
          {
            key: 4,
            value: '0',
          },
          {
            key: 3,
            value: '0',
          },
          {
            key: 2,
            value: '0',
          },
        ],
      },
    ],
    jointSizeMasterData: [
      {
        id: 1,
        max: '100',
        displayFlag: true,
      },
      {
        id: 6,
        val: '',
        displayFlag: true,
        max: '400',
      },
      {
        id: 5,
        val: '',
        displayFlag: true,
        max: '800',
      },
      {
        id: 4,
        val: '',
        displayFlag: true,
        max: '1600',
      },
      {
        id: 3,
        val: '',
        displayFlag: true,
        max: '10000',
      },
      {
        id: 2,
        max: 99999,
        displayFlag: true,
      },
    ],
    jointItaatsuLstData: [
      {
        key: 1,
        materialType: 'SPHC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 2,
        materialType: 'SPCC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 3,
        materialType: 'SECC',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 4,
        materialType: 'SUS',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 5,
        materialType: 'AL',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
      {
        key: 6,
        materialType: 'CU',
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 18,
            value: '',
          },
          {
            key: 17,
            value: '',
          },
          {
            key: 16,
            value: '',
          },
          {
            key: 15,
            value: '',
          },
          {
            key: 14,
            value: '',
          },
          {
            key: 13,
            value: '',
          },
          {
            key: 12,
            value: '',
          },
          {
            key: 11,
            value: '',
          },
          {
            key: 10,
            value: '',
          },
          {
            key: 9,
            value: '',
          },
          {
            key: 8,
            value: '',
          },
          {
            key: 7,
            value: '',
          },
          {
            key: 6,
            value: '',
          },
          {
            key: 5,
            value: '',
          },
          {
            key: 4,
            value: '',
          },
          {
            key: 3,
            value: '',
          },
          {
            key: 20,
            value: '',
          },
          {
            key: 19,
            value: '',
          },
          {
            key: 23,
            value: '',
          },
          {
            key: 22,
            value: '',
          },
          {
            key: 21,
            value: '',
          },
          {
            key: 24,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        isUsed: false,
      },
    ],
    bendingCorrectionTimeFactorLstData: [
      {
        key: 1,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 20,
      },
      {
        key: 2,
        lstData: [
          {
            key: 1,
            value: '',
          },
          {
            key: 2,
            value: '',
          },
        ],
        max: 999,
      },
    ],
    retrainedMoldMasterData: [
      {
        id: 1,
        val: '5.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 2,
        val: '10.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 7,
        val: '20.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 6,
        val: '30.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 5,
        val: '40.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 4,
        val: '50.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 3,
        val: '80.0 x 5.0',
        displayFlag: true,
      },
      {
        id: 8,
        val: '110.0 x 5.0',
        displayFlag: true,
      },
    ],
  },
];
