/**
 * クラス名：担当者パラメータ
 * 説明：担当者情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Button, Row, Input, Col, Modal, Select, Space, Image, Radio, DatePicker } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { StatusCodes } from 'http-status-codes';

import PersonInChargeDetails from './PersonInChargeDetails';
import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  error,
  authorizeError,
} from '../../../common/CommonModal';
import {
  dataStateList,
  today,
  formatDate,
  formValidatorMode,
  updateAccessToken,
  ResizableTitle,
  TextArea,
} from '../../../common/Common';
import { getStaffInfo } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY/MM/DD';

const PersonInChargeParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const inputItemRef = {};

  let loginUser = JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser')));
  let token = localStorage?.getItem('iQxWeb_AccessToken');
  let companyId = loginUser?.claims?.iQxWebEstimateClaim2;

  let checkedRow = [];

  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // デフォルトページの表示件数
  const defaultPageSize = 25;

  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];

  const [paramSelected, setParamSelected] = useState('personInCharge');
  const [paramType, setParamType] = useState('common');
  const [personInChargeData, setPersonInChargeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tempCustomersData, setTempCustomersData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState(0);

  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isUsed, setIsUsed] = useState();
  const [nameKana, setNameKana] = useState('');
  const [department, setDepartment] = useState('');
  const [tel, setTel] = useState('');
  const [fax, setFax] = useState('');
  const [mail, setMail] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState();
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState(today);
  const [modifier, setModifier] = useState('');

  const [dataState, setDataState] = useState(0);
  const [keyword, setKeyWord] = useState('');

  const [outsourcingChecked, setOutsourcingChecked] = useState(false);
  const [hasCheckData, setHasCheckData] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);

  const [tmpFilteredData, setTmpFilteredData] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  //　テーブル列Resizable用変数
  // 一覧用変数
  const [nameWidth, setNameWidth] = useState(150);
  const [nameKanaWidth, setNameKanaWidth] = useState(150);
  const [departmentWidth, setDepartmentWidth] = useState(100);
  const [telWidth, setTelWidth] = useState(125);
  const [faxWidth, setFaxWidth] = useState(125);
  const [mailWidth, setMailWidth] = useState(150);
  const [remarkWidth, setRemarkWidth] = useState(200);
  // 一括編集用変数
  const [nameWidthEdit, setNameWidthEdit] = useState(150);
  const [nameKanaWidthEdit, setNameKanaWidthEdit] = useState(150);
  const [departmentWidthEdit, setDepartmentWidthEdit] = useState(100);
  const [telWidthEdit, setTelWidthEdit] = useState(125);
  const [faxWidthEdit, setFaxWidthEdit] = useState(125);
  const [mailWidthEdit, setMailWidthEdit] = useState(150);
  const [remarkWidthEdit, setRemarkWidthEdit] = useState(200);

  // 現在のページ番号
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPersonInChargeData(token);
  }, [paramType, paramSelected]);

  // フラグ又はキーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [keyword, dataState, tmpFilteredData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          setSelectedData(getSelectedData(personInChargeData, changeRecordId));
          setSelectedRowId(changeRecordId);
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          setSelectedData(getSelectedData(personInChargeData, selectedRowId));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 担当者情報をDBから取得する
  async function getPersonInChargeData(token) {
    let personInChargeData = [];
    let selectedData = [];
    try {
      personInChargeData = await getStaffInfo(token);
      if (personInChargeData.length > 0) selectedData = personInChargeData[personInChargeData.length - 1];
      setPersonInChargeData(personInChargeData);
      setFilteredData(personInChargeData);
      setSelectedRowId(selectedData?.id);
      setSelectedData(selectedData);
      setSelectedRowsData(personInChargeData);
      navigate(':common.personInCharge');
      setParamSelected('personInCharge');
      setParamType('common');
      props.setParam(paramType, paramSelected);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 55,
      render: (id, record, index) => {
        return (currentPage - 1) * defaultPageSize + (index + 1);
      },
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend',
      className: 'cm-a-right',
    },
    {
      id: '2',
      title: '表示',
      dataIndex: 'info',
      width: 75,
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '3',
      title: 'コード',
      dataIndex: 'code',
      width: 150,
    },
    {
      id: '4',
      title: '名前',
      dataIndex: 'name',
      width: isBulkEdit ? nameWidthEdit : nameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setNameWidthEdit(size.width);
          } else {
            setNameWidth(size.width);
          }
        },
      }),
    },
    {
      id: '5',
      title: 'カナ',
      dataIndex: 'info',
      render: (info) => info.nameKana,
      width: isBulkEdit ? nameKanaWidthEdit : nameKanaWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setNameKanaWidthEdit(size.width);
          } else {
            setNameKanaWidth(size.width);
          }
        },
      }),
    },
    {
      id: '6',
      title: '部署',
      dataIndex: 'info',
      render: (info) => info.department,
      width: isBulkEdit ? departmentWidthEdit : departmentWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setDepartmentWidthEdit(size.width);
          } else {
            setDepartmentWidth(size.width);
          }
        },
      }),
    },
    {
      id: '7',
      title: 'Tel',
      dataIndex: 'info',
      render: (info) => info.tel,
      width: isBulkEdit ? telWidthEdit : telWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setTelWidthEdit(size.width);
          } else {
            setTelWidth(size.width);
          }
        },
      }),
    },
    {
      id: '8',
      title: 'Fax',
      dataIndex: 'info',
      render: (info) => info.fax,
      width: isBulkEdit ? faxWidthEdit : faxWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setFaxWidthEdit(size.width);
          } else {
            setFaxWidth(size.width);
          }
        },
      }),
    },
    {
      id: '9',
      title: 'メール',
      dataIndex: 'info',
      render: (info) => info.mail,
      width: isBulkEdit ? mailWidthEdit : mailWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setMailWidthEdit(size.width);
          } else {
            setMailWidth(size.width);
          }
        },
      }),
    },

    {
      id: '10',
      title: 'ソート番号',
      dataIndex: 'info',
      render: (info) => info.sortNum,
      width: 90,
      className: 'cm-a-right',
    },
    {
      id: '11',
      title: '作成日',
      dataIndex: 'created',
      render: (created) => formatDate(created),
      width: 100,
    },
    {
      id: '12',
      title: '更新日',
      dataIndex: 'modified',
      render: (modified) => formatDate(modified),
      width: 100,
    },
    {
      id: '13',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
    },
    {
      id: '14',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
      width: isBulkEdit ? remarkWidthEdit : remarkWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setRemarkWidthEdit(size.width);
          } else {
            setRemarkWidth(size.width);
          }
        },
      }),
    },
    {
      id: '15',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ].filter((item) => !item.hidden);

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 入力項目情報
  const inputFormItem = (
    <RTable id="param_detail_tbl" className="propertiesTbl">
      <thead>
        {/* 表示 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">表示</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <RadioGroup
              name="isUsed"
              defaultValue={isUsed}
              className="radioCustomerGpEdit"
              onChange={(e) => setIsUsed(e.target.value)}
              value={isUsed}
            >
              <Radio value={true}>オン</Radio>
              <Radio value={false}>オフ</Radio>
            </RadioGroup>
          </td>
        </tr>
        {/* コード */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">コード</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="code"
              id="code"
              value={code}
              type="text"
              ref={(ref) => {
                inputItemRef['code'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setCode(e.target.value)}
            />
          </td>
        </tr>
        {/* 名前 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">名前</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="name"
              id="name"
              value={name}
              type="text"
              ref={(ref) => {
                inputItemRef['name'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
          </td>
        </tr>
        {/* カナ */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">カナ</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="nameKana"
              id="nameKana"
              value={nameKana}
              type="text"
              maxLength={50}
              onChange={(e) => setNameKana(e.target.value)}
            />
          </td>
        </tr>
        {/* 部署 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">部署</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="department"
              id="department"
              value={department}
              type="text"
              maxLength={50}
              onChange={(e) => setDepartment(e.target.value)}
            />
          </td>
        </tr>
        {/* Tel */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">Tel</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="tel"
              id="tel"
              value={tel}
              type="text"
              ref={(ref) => {
                inputItemRef['tel'] = ref;
              }}
              maxLength={20}
              onChange={(e) => setTel(e.target.value)}
            />
          </td>
        </tr>
        {/* Fax */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">Fax</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="fax"
              id="fax"
              value={fax}
              type="text"
              ref={(ref) => {
                inputItemRef['fax'] = ref;
              }}
              maxLength={20}
              onChange={(e) => setFax(e.target.value)}
            />
          </td>
        </tr>
        {/* メール */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">メール</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="mail"
              id="mail"
              value={mail}
              type="text"
              ref={(ref) => {
                inputItemRef['mail'] = ref;
              }}
              maxLength={100}
              onChange={(e) => setMail(e.target.value)}
            />
          </td>
        </tr>

        {/* ソート番号 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">ソート番号</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="sortNum"
              id="sortNum"
              value={sortNum}
              type="text"
              ref={(ref) => {
                inputItemRef['sortNum'] = ref;
              }}
              maxLength={Number.MAX_SAFE_INTEGER}
              onChange={(e) => setSortNum(e.target.value)}
            />
          </td>
        </tr>
        {/* 作成日 */}
        {/* 更新日 */}
        {/* 更新者 */}
        {isBulkEdit ? (
          ''
        ) : (
          <>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">作成日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{created}</label>
              </td>
            </tr>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{modified}</label>
              </td>
            </tr>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新者</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{modifier}</label>
              </td>
            </tr>
          </>
        )}
        {/* 備考 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">備考</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <TextArea
              className={'input-editable'}
              name="remarks"
              id="remarks"
              value={remarks}
              type="text"
              maxLength={2048}
              style={{ margin: 5, padding: 5, width: '98.3%' }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </td>
        </tr>
      </thead>
    </RTable>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, id) => {
    let ret = [];
    let record;
    record = data.find((item) => item.id === id);
    return record ? record : ret;
  };

  // 最大ソート番号を得る
  const getMaxSortNum = () => {
    let sortedList = personInChargeData.sort((a, b) => b.info.sortNum - a.info.sortNum);
    return sortedList.length > 0 ? sortedList[0].info.sortNum + 1 : 1;
  };

  // Idで降順する
  const descOrderById = (data) => {
    let sortedList = data.sort((a, b) => b.id - a.id);
    return sortedList;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let selectedPICData = [];
    if (record !== undefined) {
      selectedPICData.push({
        id: record.id,
        code: record.code,
        name: record.name,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      setChangeRecordId(record.id);
      if (editMode) {
        setDiscardChangeMode(discardChange[0]);
        setEditModeCancelConfirm(true);
      } else {
        setSelectedRowId(record.id);
        setSelectedData(selectedPICData[0]);
        navigate(':common.personInCharge');
      }
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.personInCharge');
    }
  };

  const onRowSelectWithoutEditMode = (record) => {
    let selectedPICData = [];
    if (record !== undefined) {
      selectedPICData.push({
        id: record.id,
        code: record.code,
        name: record.name,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      setChangeRecordId(record.id);

      setSelectedRowId(record.id);
      setSelectedData(selectedPICData[0]);

      const personInChargeLst = [...filteredData];
      if (personInChargeLst.length > 0) {
        setCurrentPage(Math.ceil(personInChargeLst.length / 25));
      } else {
        setCurrentPage(1);
      }
      navigate(':common.personInCharge');
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.personInCharge');
    }
  };

  const handleOkCSVInput = (e) => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    setIsDelete(true);
    setChildTitle('削除');
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    if (isDelete) {
      setIsDelete(false);
      setEditMode(false);
      let selectedId = selectedRowId;
      let deletedData = await deletePersonInChargeData(selectedId);

      let filterdData = [...filteredData];
      filterdData = filterdData.filter((item) => item.id !== deletedData.id);
      setFilteredData(filterdData);
      /* let lastId = filterdData[0]?.id;
      setSelectedRowId(lastId);
      let record = getSelectedData(filterdData, lastId);
      setSelectedData(record); */

      let currentData = [...personInChargeData];
      currentData = currentData.filter((item) => item.id !== deletedData.id);
      // currentData = currentData.map((item, index) => {
      //   item.no = index + 1;
      //   return item;
      // });
      // setCurrentPage(1);
      onRowSelectWithoutEditMode(currentData !== [] ? currentData[currentData.length - 1] : []);
      setPersonInChargeData(currentData);
      setTmpFilteredData(currentData);
      setCurrentPage(1);
    }
  };

  // 担当者情報をDBに削除する
  async function deletePersonInChargeData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/StaffDelete/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          error(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(true);
    setChildTitle('CSV入力');
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
    setChildTitle('XML入力');
  };

  const resetInputInfo = () => {
    setCode('');
    setName('');
    setIsUsed();
    setNameKana('');
    setDepartment('');
    setTel('');
    setFax('');
    setMail('');
    setRemarks('');
    setSortNum();
    setCreated(today);
    setModified(today);
    setModifier('');
  };

  // 表示を押下の場合、
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (personInChargeData.length <= 0) {
      return;
    }

    let searchBarText = keyword;
    let flag;
    let currentData = [...personInChargeData];
    let currentFilterData = currentData;

    // キーワードで絞り込み
    if (searchBarText != '') {
      currentFilterData = currentFilterData.filter(
        (item) =>
          item.code?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.name?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.nameKana?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.department?.toString().indexOf(searchBarText) >= 0 ||
          item.info.tel?.toString().indexOf(searchBarText) >= 0 ||
          item.info.fax?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.mail?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.remarks?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.sortNum === searchBarText ||
          item.created?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.modified?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.modifier?.toLowerCase().indexOf(searchBarText) >= 0
      );
    }

    //  表示で絞り込み
    if (dataState === 1 || dataState === 2) {
      dataState === 1 ? (flag = true) : (flag = false);
      currentFilterData = currentFilterData.filter((item) => item.info.isUsed === flag);
    }

    /* let index = currentFilterData.findIndex((item) => item.id === selectedRowId);
    // 検索した結果に、選択中行のKeyが無い場合、
    if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (currentFilterData.length > 0) {
        index = 0;
        setSelectedRowId(currentFilterData[index]?.id);
      }
    }
    currentFilterData.length > 0
      ? setSelectedData(currentFilterData[index]) // 検索結果がある場合、
      : setSelectedData(currentFilterData); // 検索結果が無い場合、
    // 一覧に表示するデータ */
    setFilteredData(currentFilterData);
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const addNewModalTitle = (
    <div
      style={{
        width: 700,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isCreateNew) {
      setCreateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create)) {
        return;
      }
      let lastIndex = tempCustomersData.length - 1;
      let tempData = tempCustomersData[lastIndex];
      let createdData = await createPersonInChargeData(tempData);
      setPersonInChargeData((picData) => descOrderById([...picData, createdData]));
      setTmpFilteredData(createdData);
      setFilteredData((filteredData) => descOrderById([...filteredData, createdData]));
      onRowSelectWithoutEditMode(createdData);
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit)) {
        return;
      }
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      // 選択された全ての対象行を更新、
      bulkEditSelectedRow.map((item) => {
        if (code) item.code = code;
        if (name) item.name = name;
        if (isUsed === true || isUsed === false) item.info.isUsed = isUsed;
        if (nameKana) item.info.nameKana = nameKana;
        if (department) item.info.department = department;
        if (tel) item.info.tel = tel;
        if (fax) item.info.fax = fax;
        if (mail) item.info.mail = mail;
        if (remarks) item.info.remarks = remarks;
        if (sortNum) item.info.sortNum = sortNum;
        item.modified = today;
        submitData(item);
      });
    }
    resetInputInfo();
  };

  // 担当者情報をDBに新規登録する
  async function createPersonInChargeData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/StaffInsert';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          code: createData.code,
          name: createData.name,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          error(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [];
    var updatedInfo = [];
    var index;

    updatedInfo = await updatePersonInChargeData(updateData);
    tmpData = [...personInChargeData];
    index = tmpData.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setPersonInChargeData(tmpData);
    setTmpFilteredData(tmpData);
    tmpData = [...filteredData];
    index = tmpData.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setFilteredData(tmpData);

    // 詳細情報を再表示
    if (updatedInfo.id === selectedRowId) detailRef.current?.displayDetailInfo(updatedInfo);
  };

  // 担当者情報をDBに更新する
  async function updatePersonInChargeData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/StaffUpdate';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          if (response.status === StatusCodes.OK) {
            return await response.json();
          } else if (response.status === StatusCodes.UNAUTHORIZED) {
            authorizeError(ErrorMessage().E006);
            return;
          }
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          error(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setIsUsed(true);
    setSortNum(getMaxSortNum());
    setChildTitle('担当者（新規作成）');
  };

  const createNewOK = () => {
    prepareCreateNewInfo();
  };

  const createNewCancel = (e) => {
    setCancelConfirm(true);
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempPICData = [];
    tempPICData.push({
      code: code,
      name: name,
      info: {
        isUsed: isUsed,
        nameKana: nameKana,
        department: department,
        tel: tel,
        fax: fax,
        mail: mail,
        remarks: remarks,
        sortNum: sortNum,
      },
    });
    setTempCustomersData(tempPICData);
    setCreateConfirm(true);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 653,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  // 一括編集-開始
  const onRowCheckboxSelect = (checked, record) => {
    let checkedRow = [...checked];
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow.length);
    navigate(':common.personInCharge');
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditSelectedRow([]);
    setBulkEditSelectedRowId([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('担当者（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            columns={tableColumns}
            components={components}
            rowKey={(record) => record.id}
            dataSource={filteredData}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.id == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: defaultPageSize,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/* 検索アリア */}
            <Space size="middle">
              <Select
                className="search-select"
                onChange={searchByDataState}
                id="dataStateDrop"
                name="dataStateDrop"
                value={dataState}
              >
                {dataStateList.map((item) => (
                  <option id={'dataState' + item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
              <Input
                className="input-editable search-select-input"
                id="keyword"
                placeholder="キーワードを入力"
                allowClear
                onPressEnter={searchByKeyword}
                onBlur={searchByKeyword}
                onChange={(e) => {
                  if (e.target.value === '') {
                    searchByKeyword(e);
                  }
                }}
              />
            </Space>

            {/* 一覧アリア */}
            <Row style={{ marginTop: 10 }}>
              <Table
                showSorterTooltip={false}
                columns={hasCheckData ? tableColumns : chkData}
                components={components}
                dataSource={filteredData}
                scroll={{ y: 590, x: '10vw' }}
                rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                pagination={{
                  position: ['bottomLeft'],
                  total: (record) => record.length,
                  showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                  defaultPageSize: defaultPageSize,
                  defaultCurrent: 1,
                  current: currentPage,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
                className="param_tbListShow"
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                      onRowSelect(record);
                    },
                  };
                }}
              />
            </Row>
          </div>
          {/* プロパティ表示 */}
          <div>
            <Routes>
              <Route
                exact
                path=":common.personInCharge"
                element={
                  <PersonInChargeDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* CSV取込 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleCSVInput}
        onOk={handleOkCSVInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkCSVInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          PCに保存した情報を読み込みます。<br></br>よろしいでしょうか？
        </p>
      </Modal> */}

      {/* XML呼出 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から
          <br />
          取り込みます。
          <br />
          装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default PersonInChargeParamList;
