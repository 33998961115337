import { Button, Row, Input, Col, Image, Space, Modal, Radio } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '../../assets/styles/common.css';
import calculator_white from '../../assets/images/calculator_white.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import minus_icon from '../../assets/images/minus_icon.png';
import KouteibetsuInfo from './KouteibetsuInfo';
import {
  RightCircleFilled,
  LeftCircleFilled,
  CloseOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { commonTaiochuModal } from '../common/CommonModal';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const iconWidth = 30;

let quantityItems_Len0 = [
  {
    key: 0,
    quantityNo: '1',
    a_manufacturingCost: '¥0',
    manufacturingCost: '¥0',
    a_estimateCost: '¥0',
    estimateCost: '¥0',
    grossProfit: '¥0',
    grossMargin: '0.00%',
  },
];
let quantityItems = [
  {
    key: 0,
    quantityNo: 1,
    a_manufacturingCost: '¥181,653',
    manufacturingCost: '¥181,653',
    a_estimateCost: '¥227,067',
    estimateCost: '¥227,067',
    grossProfit: '¥45,413',
    grossMargin: '20.00%',
  },
  {
    key: 1,
    quantityNo: 2,
    a_manufacturingCost: '¥142,390',
    manufacturingCost: '¥284,779',
    a_estimateCost: '¥177,987',
    estimateCost: '¥355,974',
    grossProfit: '¥71,195',
    grossMargin: '20.00%',
  },
  {
    key: 2,
    quantityNo: 3,
    a_manufacturingCost: '¥129,187',
    manufacturingCost: '¥387,560',
    a_estimateCost: '¥161,484',
    estimateCost: '¥484,450',
    grossProfit: '¥96,890',
    grossMargin: '20.00%',
  },
  {
    key: 3,
    quantityNo: 4,
    a_manufacturingCost: '¥122,500',
    manufacturingCost: '¥489,998',
    a_estimateCost: '¥153,125',
    estimateCost: '¥612,498',
    grossProfit: '¥122,499',
    grossMargin: '20.00%',
  },
  {
    key: 4,
    quantityNo: 5,
    a_manufacturingCost: '¥118,423',
    manufacturingCost: '¥592,111',
    a_estimateCost: '¥148,028',
    estimateCost: '¥740,139',
    grossProfit: '¥148,028',
    grossMargin: '20.00%',
  },
  {
    key: 5,
    quantityNo: 10,
    a_manufacturingCost: '¥110,386',
    manufacturingCost: '¥1,103,856',
    a_estimateCost: '¥137,982',
    estimateCost: '¥1,379,820',
    grossProfit: '¥275,964',
    grossMargin: '20.00%',
  },
  {
    key: 6,
    quantityNo: 20,
    a_manufacturingCost: '¥106,420',
    manufacturingCost: '¥2,128,388',
    a_estimateCost: '¥133,025',
    estimateCost: '¥2,660,485',
    grossProfit: '¥532,097',
    grossMargin: '20.00%',
  },
  {
    key: 7,
    quantityNo: 30,
    a_manufacturingCost: '¥105,098',
    manufacturingCost: '¥3,152,920',
    a_estimateCost: '¥131,372',
    estimateCost: '¥3,941,150',
    grossProfit: '¥788,230',
    grossMargin: '20.00%',
  },
  {
    key: 8,
    quantityNo: 40,
    a_manufacturingCost: '¥104,437',
    manufacturingCost: '¥4,177,452',
    a_estimateCost: '¥130,546',
    estimateCost: '¥5,221,814',
    grossProfit: '¥1,044,363',
    grossMargin: '20.00%',
  },
  {
    key: 9,
    quantityNo: 50,
    a_manufacturingCost: '¥104,040',
    manufacturingCost: '¥5,201,983',
    a_estimateCost: '¥130,050',
    estimateCost: '¥6,502,479',
    grossProfit: '¥1,300,496',
    grossMargin: '20.00%',
  },
  {
    key: 10,
    quantityNo: 100,
    a_manufacturingCost: '¥103,247',
    manufacturingCost: '¥10,324,642',
    a_estimateCost: '¥129,059',
    estimateCost: '¥12,905,803',
    grossProfit: '¥2,581,160',
    grossMargin: '20.00%',
  },
];
let quantityItems_PMX2 = [
  {
    key: 0,
    quantityNo: 1,
    a_manufacturingCost: '¥28,918',
    manufacturingCost: '¥28,918',
    a_estimateCost: '¥36,147',
    estimateCost: '¥36,147',
    grossProfit: '¥7,229',
    grossMargin: '20.00%',
  },
  {
    key: 1,
    quantityNo: 2,
    a_manufacturingCost: '¥21,669',
    manufacturingCost: '¥43,338',
    a_estimateCost: '¥27,087',
    estimateCost: '¥54,173',
    grossProfit: '¥10,834',
    grossMargin: '20.00%',
  },
  {
    key: 2,
    quantityNo: 3,
    a_manufacturingCost: '¥19,167',
    manufacturingCost: '¥57,501',
    a_estimateCost: '¥23,959',
    estimateCost: '¥71,877',
    grossProfit: '¥14,375',
    grossMargin: '20.00%',
  },
  {
    key: 3,
    quantityNo: 4,
    a_manufacturingCost: '¥17,858',
    manufacturingCost: '¥71,429',
    a_estimateCost: '¥22,322',
    estimateCost: '¥89,286',
    grossProfit: '¥17,857',
    grossMargin: '20.00%',
  },
  {
    key: 4,
    quantityNo: 5,
    a_manufacturingCost: '¥17,029',
    manufacturingCost: '¥85,143',
    a_estimateCost: '¥21,286',
    estimateCost: '¥106,429',
    grossProfit: '¥21,286',
    grossMargin: '20.00%',
  },
  {
    key: 5,
    quantityNo: 10,
    a_manufacturingCost: '¥15,426',
    manufacturingCost: '¥154,258',
    a_estimateCost: '¥19,283',
    estimateCost: '¥192,823',
    grossProfit: '¥38,564',
    grossMargin: '20.00%',
  },
  {
    key: 6,
    quantityNo: 20,
    a_manufacturingCost: '¥14,644',
    manufacturingCost: '¥292,868',
    a_estimateCost: '¥18,305',
    estimateCost: '¥366,085',
    grossProfit: '¥73,217',
    grossMargin: '20.00%',
  },
  {
    key: 7,
    quantityNo: 30,
    a_manufacturingCost: '¥14,383',
    manufacturingCost: '¥431,478',
    a_estimateCost: '¥17,979',
    estimateCost: '¥539,348',
    grossProfit: '¥107,869',
    grossMargin: '20.00%',
  },
  {
    key: 8,
    quantityNo: 40,
    a_manufacturingCost: '¥14,253',
    manufacturingCost: '¥570,089',
    a_estimateCost: '¥17,816',
    estimateCost: '¥712,611',
    grossProfit: '¥142,522',
    grossMargin: '20.00%',
  },
  {
    key: 9,
    quantityNo: 50,
    a_manufacturingCost: '¥14,174',
    manufacturingCost: '¥708,699',
    a_estimateCost: '¥17,718',
    estimateCost: '¥885,873',
    grossProfit: '¥177,175',
    grossMargin: '20.00%',
  },
  {
    key: 10,
    quantityNo: 100,
    a_manufacturingCost: '¥14,018',
    manufacturingCost: '¥1,401,750',
    a_estimateCost: '¥17,522',
    estimateCost: '¥1,752,187',
    grossProfit: '¥350,437',
    grossMargin: '20.00%',
  },
];

const KouteiSuryouBetsuBtn = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [propsParam, setPropsParam] = useState(props.paramNm);
  const [showDetailRow, setShowDetailRow] = useState(true);
  const [showDetailRowIQ3, setShowDetailRowIQ3] = useState(true);
  const [showDetailRowIQ5, setShowDetailRowIQ5] = useState(true);
  const [showDetailRowIQ7, setShowDetailRowIQ7] = useState(true);
  const [showProcessTotalAmount, setShowProcessTotalAmount] = useState(false);
  const [showQuantityComponent, setShowQuantityComponent] = useState(false);
  const [quantityItem, setQuantityItem] = useState([]);
  const [activePartMenu, setActivePartMenu] = useState('unit');
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  useEffect(() => {
    if (props.selectedEstimateData?.length == 0) {
      setQuantityItem(quantityItems_Len0);
    } else if (props.selectedEstimateData?.imgNo == 'zerofour-03') {
      setQuantityItem(quantityItems_PMX2);
    } else {
      setQuantityItem(quantityItems);
    }
  }, [props.selectedEstimateData]);
  const partMenuChange = (e) => {
    console.log('onchange--', e.target.value);
    setActivePartMenu(e.target.value);
  };
  // 開発中ポップアップ呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  /* 工程別合計コンポーネント開始 */
  const tblTile = (
    <>
      <Row className="estimate-tab2-title-row">
        <Col span={1} className="estimate-tab2-title-col">
          No.
        </Col>
        <Col span={5} className="estimate-tab2-title-col">
          工程
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          段取時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          段取金額
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          加工時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          加工金額
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          合計時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計金額
        </Col>
        <Col span={3} className="estimate-tab2-title-col" style={{ display: activePartMenu === 'unit' ? '' : 'none' }}>
          単価
        </Col>
      </Row>
    </>
  );
  const totalRow = (
    <>
      {tblTile}
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col-total"></Col>
        <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
          合計
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {'02:00:01'}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {'¥7200'}
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {'02:00:01'}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {'¥7200'}
        </Col>
        <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {'02:00:01'}
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {'¥7200'}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {'¥9200'}
        </Col>
      </Row>
      <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
        <Col span={16}></Col>
        <Col
          span={2}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ textAlign: 'right', paddingRight: '25px' }}
        >
          材料費
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {'¥7200'}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {'¥7200'}
        </Col>
      </Row>
    </>
  );
  let parentItems = [
    {
      id: 1,
      process: `親部品工程_1`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
      unitAmount: `¥1001`,
    },
    {
      id: 2,
      process: `親部品工程_2`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
      unitAmount: `¥1001`,
    },
  ];
  let parentItemComponent = [];
  parentItems.forEach((item, index) => {
    parentItemComponent.push(
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col">
          {item.id}
        </Col>
        <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
          {item.process}
        </Col>
        <Col span={2} className="estimate-tab2-col">
          {item.setupTime}
        </Col>
        <Col span={3} className="estimate-tab2-col">
          {item.setupAmount}
        </Col>
        <Col span={2} className="estimate-tab2-col">
          {item.processTime}
        </Col>
        <Col span={3} className="estimate-tab2-col">
          {item.processAmount}
        </Col>
        <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
          {item.totalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
          {item.totalAmount}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col estimate-tab2-col-total"
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {item.unitAmount}
        </Col>
      </Row>
    );
  });
  const parentTBL = (
    <>
      <div>
        {tblTile}
        {showDetailRow ? parentItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {'¥7200'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {'¥7200'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'¥7200'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {'¥7200'}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq3工程 */
  let iq3Items = [
    {
      id: 1,
      process: `プログラム`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
      unitAmount: `¥1001`,
    },
    {
      id: 2,
      process: `レーザー`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
      unitAmount: `¥1001`,
    },
  ];
  let iq3ItemComponent = [];
  iq3Items.forEach((item, index) => {
    iq3ItemComponent.push(
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col">
          {item.id}
        </Col>
        <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
          {item.process}
        </Col>
        <Col span={2} className="estimate-tab2-col">
          {item.setupTime}
        </Col>
        <Col span={3} className="estimate-tab2-col">
          {item.setupAmount}
        </Col>
        <Col span={2} className="estimate-tab2-col">
          {item.processTime}
        </Col>
        <Col span={3} className="estimate-tab2-col">
          {item.processAmount}
        </Col>
        <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
          {item.totalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
          {item.totalAmount}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col estimate-tab2-col-total "
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {item.unitAmount}
        </Col>
      </Row>
    );
  });
  const iq3TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ3 ? iq3ItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {'¥7200'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {'¥7200'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'¥7200'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {'¥7200'}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
          <Col span={16}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px' }}
          >
            材料費
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'¥7200'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {'¥7200'}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq5工程 */
  let iq5Items = [
    {
      id: 1,
      process: `切断`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
      unitAmount: `¥1001`,
    },
    {
      id: 2,
      process: `穴`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
      unitAmount: `¥1001`,
    },
  ];
  let iq5ItemComponent = [];
  iq5Items.forEach((item, index) => {
    iq5ItemComponent.push(
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col">
          {item.id}
        </Col>
        <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
          {item.process}
        </Col>
        <Col span={2} className="estimate-tab2-col">
          {item.setupTime}
        </Col>
        <Col span={3} className="estimate-tab2-col">
          {item.setupAmount}
        </Col>
        <Col span={2} className="estimate-tab2-col">
          {item.processTime}
        </Col>
        <Col span={3} className="estimate-tab2-col">
          {item.processAmount}
        </Col>
        <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
          {item.totalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
          {item.totalAmount}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col estimate-tab2-col-total"
          style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
        >
          {item.unitAmount}
        </Col>
      </Row>
    );
  });
  const iq5TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ5 ? iq5ItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {'¥7200'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {'¥7200'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'02:00:01'}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'¥7200'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {'¥7200'}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
          <Col span={16}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px' }}
          >
            材料費
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {'¥7200'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? '' : 'none' }}
          >
            {'¥7200'}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq7工程 */
  let iq7Items = [
    {
      id: 1,
      process: `切断`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
    },
    {
      id: 2,
      process: `穴`,
      setupTime: `00:00:01`,
      setupAmount: `¥1001`,
      processTime: `00:00:01`,
      processAmount: `¥1001`,
      totalTime: `00:00:01`,
      totalAmount: `¥1001`,
    },
  ];
  let iq7ItemComponent = [];
  iq7Items.forEach((item, index) => {
    iq7ItemComponent.push(
      <Row className="estimate-tab2-row">
        <Col className="estimate-tab2-col" style={{ width: 50 }}>
          {item.id}
        </Col>
        <Col className="estimate-tab2-col" style={{ textAlign: 'center' }}>
          {item.process}
        </Col>
        <Col className="estimate-tab2-col">{item.setupTime}</Col>
        <Col className="estimate-tab2-col">{item.setupAmount}</Col>
        <Col className="estimate-tab2-col">{item.setupTime}</Col>
        <Col className="estimate-tab2-col">{item.processTime}</Col>
        <Col className="estimate-tab2-col">{item.processAmount}</Col>
        <Col className="estimate-tab2-col">{item.totalTime}</Col>
        <Col className="estimate-tab2-col">{item.totalAmount}</Col>
      </Row>
    );
  });
  const iq7TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ7 ? iq7ItemComponent : ''}
        <Row className="estimate-tab2-row">
          <Col className="estimate-tab2-col-total" style={{ width: 50 }}></Col>
          <Col className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
            合計
          </Col>
          <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
          <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
          <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
          <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
          <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
          <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
          <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント */
  const showDetail = (e, type) => {
    if (type == 'parent') {
      setShowDetailRow(false);
    } else if (type == 'iq3') {
      setShowDetailRowIQ3(false);
    } else if (type == 'iq5') {
      setShowDetailRowIQ5(false);
    } else if (type == 'iq7') {
      setShowDetailRowIQ7(false);
    }
  };
  const showHide = (e, type) => {
    if (type === 'parent') {
      setShowDetailRow(true);
    } else if (type === 'iq3') {
      setShowDetailRowIQ3(true);
    } else if (type === 'iq5') {
      setShowDetailRowIQ5(true);
    } else if (type === 'iq7') {
      setShowDetailRowIQ7(true);
    }
  };
  const processTotalAmountComponent = (
    <>
      <div style={{ overflowY: 'auto', marginTop: 15, height: '715px' }}>
        <Row id="totalRow" style={{ marginBottom: '5px' }}>
          <div style={{ float: 'left', width: '60%' }}>
            <Radio.Group value={activePartMenu} onChange={partMenuChange}>
              <Radio.Button value="total" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>合計</p>
              </Radio.Button>
              <Radio.Button value="unit" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>単品</p>
              </Radio.Button>
            </Radio.Group>
          </div>

          <div style={{ float: 'right', width: '40%' }}>
            <Button key="submit" type="primary" className="csvoutput">
              CSV出力
            </Button>
          </div>
        </Row>

        <Row id="parentTitleRow" style={{ marginTop: '13px' }}>
          <Input className="estimate-tab2-tbl-thead" value="親部品工程" />
          {showDetailRow ? (
            <a onClick={(e) => showDetail(e, 'parent')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'parent')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {parentTBL}

        <Row id="iq3TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="板金子部品工程" />
          {showDetailRowIQ3 ? (
            <a onClick={(e) => showDetail(e, 'iq3')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq3')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {iq3TBL}
        <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="形鋼子部品工程" />
          {showDetailRowIQ5 ? (
            <a onClick={(e) => showDetail(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {iq5TBL}
        {/* <Row id="iq7TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="機械子部品工程" />
          {showDetailRowIQ7 ? (
            <a onClick={(e) => showDetail(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        <Row id="iq7Row" style={{ marginTop: 3 }}>
          {iq7TBL}
        </Row> */}
        <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="合計" />
        </Row>
        {totalRow}
      </div>
    </>
  );
  /* 工程別合計コンポーネント 終了*/

  /* 数量別コンポーネント 開始*/

  const addRowTable = () => {
    if (quantityItem.length > 0) {
      const suryoNo = quantityItem[quantityItem.length - 1].key + 1;
      const data = {
        key: quantityItem.length + 1,
        quantityNo: '',
        a_manufacturingCost: '',
        manufacturingCost: '',
        a_estimateCost: '',
        estimateCost: '',
        grossProfit: '',
        grossMargin: '',
      };
      let addArr = [...quantityItem, data];
      setQuantityItem(addArr);
      document.getElementById('quantityTbl')?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const tableRowRemove = (e, index) => {
    // console.log('tempSelectedRow---', quantityItem);
    // let tempSelectedRow = [];
    // tempSelectedRow.push([
    //   quantityItem.filter((row) => {
    //     return row.key != index;
    //   }),
    // ]);
    // setQuantityItem(tempSelectedRow);
    // console.log('tempSelectedRow---', tempSelectedRow);
    if (index > 0) {
      const dataRow = [...quantityItem];
      dataRow.splice(index, 1);
      setQuantityItem(dataRow);
    }
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...quantityItem];
    data[i][name] = value;
    console.log('tempSelectedRow---', name);
    setQuantityItem(data);
  };
  const quantityTblTile = (
    <>
      <Row className="estimate-tab2-title-row" style={{ marginTop: 15 }}>
        <Col span={3} className="estimate-tab2-title-col">
          数量
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          原価
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計原価
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          見積
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計見積
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          粗利益
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          粗利益率
        </Col>
        <Col span={3}></Col>
      </Row>
    </>
  );

  const quantityComponent = (
    <>
      <div id="quantityTbl" style={{ overflowY: 'auto', height: '400px' }}>
        {quantityTblTile}
        {quantityItem.map((item, index) => {
          const { quantityNo } = item;
          return (
            <Row className="estimate-tab2-row">
              <Col span={3} className="estimate-tab2-col">
                <Input
                  value={quantityNo}
                  style={{ height: '2.3vh', textAlign: 'center' }}
                  onChange={(event) => onValUpdate(index, event)}
                  name="quantityNo"
                ></Input>
              </Col>
              <Col span={3} className="estimate-tab2-col">
                {item.a_manufacturingCost}
              </Col>
              <Col span={3} className="estimate-tab2-col">
                {item.manufacturingCost}
              </Col>
              <Col span={3} className="estimate-tab2-col">
                {item.a_estimateCost}
              </Col>
              <Col span={3} className="estimate-tab2-col">
                {item.estimateCost}
              </Col>
              <Col span={3} className="estimate-tab2-col">
                {item.grossProfit}
              </Col>
              <Col span={3} className="estimate-tab2-col">
                {item.grossMargin}
              </Col>
              <Col span={3} style={{ paddingLeft: '10px', display: quantityItem.length === index + 1 ? '' : 'none' }}>
                <Space>
                  <Image
                    preview={false}
                    width={25}
                    src={child_add}
                    style={{ marginLeft: '0px' }}
                    onClick={addRowTable}
                  ></Image>
                  <Image
                    preview={false}
                    width={21}
                    src={child_delete}
                    style={{ marginBottom: '1px' }}
                    onClick={(e) => tableRowRemove(e, index)}
                  ></Image>
                </Space>
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );

  /* 数量別コンポーネント 終了*/
  const showProcessTotalAmountDialog = () => {
    setShowProcessTotalAmount(true);
    setShowQuantityComponent(false);
    setSelectedEstimateData(props.selectedEstimateData);
  };
  const showQuantityComponentDialog = () => {
    setShowProcessTotalAmount(false);
    setShowQuantityComponent(true);
    setSelectedEstimateData(props.selectedEstimateData);
  };

  const confirmCancel = () => {
    setShowProcessTotalAmount(false);
    setShowQuantityComponent(false);
  };
  const suryouConfirmComponent = (
    <>
      <div style={{ display: 'flex' }}>
        <label style={{ marginTop: 1, fontSize: '13px' }}>数量別見積確認</label>
        <Image
          preview={false}
          width={22}
          height={22}
          src={calculator_white}
          style={{ marginLeft: '5px', marginBottom: '5px' }}
        />
      </div>
    </>
  );

  return (
    <>
      <Button key="submit" type="primary" onClick={showProcessTotalAmountDialog} className="processTotalButton">
        工程別合計
      </Button>
      <Button key="submit" type="primary" onClick={showQuantityComponentDialog} className="processTotalButton">
        {suryouConfirmComponent}
      </Button>
      {showProcessTotalAmount && selectedEstimateData != null ? (
        <KouteibetsuInfo selectedEstimateData={selectedEstimateData} />
      ) : (
        ''
      )}
      <Modal
        maskClosable={false}
        title={<p style={{ fontSize: '14px', fontWeight: '600', margin: 0 }}>数量別見積確認</p>}
        width={900}
        open={showQuantityComponent}
        onOk={null}
        onCancel={confirmCancel}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'black', fontSize: '13.5px' }} />}
        bodyStyle={{ height: 590 }}
      >
        <Row id="totalRow" style={{ marginBottom: '10px' }}>
          <div style={{ float: 'left', width: '50%' }}>
            <Button
              key="submit"
              type="primary"
              className="editAllButton"
              style={{ marginRight: '10px', width: 65, height: 30 }}
              id="edit"
              onClick={commonTaiochuPopupOpen}
            >
              更新
            </Button>
          </div>

          <div style={{ float: 'right', width: '37.5%' }}>
            <Button key="submit" type="primary" className="csvoutput" onClick={commonTaiochuPopupOpen}>
              CSV出力
            </Button>
          </div>
        </Row>
        <div style={{ width: '87.5%' }}>
          <Row className="estimate-tab2-title-row">
            <Col span={6} className="estimate-tab2-title-col">
              図面番号
            </Col>
            <Col span={6} className="estimate-tab2-title-col">
              名称
            </Col>
            <Col span={6} className="estimate-tab2-title-col">
              取引先
            </Col>
            <Col span={6} className="estimate-tab2-title-col">
              見積番号
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.imgNo}
            </Col>
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.name}
            </Col>
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.customerNm}
            </Col>
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateNo}
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={18} className="estimate-tab2-title-col">
              係数
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: '#1063aa' }}>
              受注形態
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: '#1063aa' }}>
              納期
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: '#1063aa' }}>
              材料ロス
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: '#1063aa' }}>
              取引先
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: '#1063aa' }}>
              管理費
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: '#1063aa' }}>
              段取
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.orderCoefficient}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.deliveryCoefficient}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {' '}
              {props.selectedEstimateData?.materialLossCoefficient}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {' '}
              {props.selectedEstimateData?.customerCoefficient}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {' '}
              {props.selectedEstimateData?.managementCoefficient}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {' '}
              {props.selectedEstimateData?.setupCoefficient}
            </Col>
          </Row>
        </div>
        {quantityComponent}
      </Modal>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
};

export default KouteiSuryouBetsuBtn;
