/**
 * クラス名：親部品見積
 * 説明：親部品タブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Col, Image, Tabs, List } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { WithRouter } from '../../components/WithRouter';
import { DownloadOutlined } from '@ant-design/icons';
import ParentEstimateDetail from './ParentEstimateDetail';
import { Route, Routes } from 'react-router-dom';
import ParentKouteiCalculate from './ParentKouteiCalculate';
import ParentKouteiInput from './ParentKouteiInput';
import ParentKouteiSentaku from './ParentKouteiSentaku';
import ParentPurchase from './ParentPurchase';
import KouteiSuryouBetsuBtn from './KouteiSuryouBetsuBtn';
import child_delete from '../../assets/images/child_delete.png';
import {
  confirmUpdateContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../common/CommonModal';

const tempFileData = [];
for (let i = 1; i < 4; i++) {
  tempFileData.push({
    fileNm: `送付ファイル${i}.xlsx`,
    fileSize: '2KB',
    updated: '2023/03/23',
    deleted: <Image preview={false} width={18} height={18} src={child_delete} style={{ marginLeft: '0px' }}></Image>,
    download: <DownloadOutlined style={{ fontSize: '22px', color: '#1063aa' }} />,
  });
}

const ParentEstimate = forwardRef((props, ref) => {
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [discardMode, setDiscardMode] = useState(false);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('1');

  // 基本情報
  let parentEstimateRef = useRef();

  // 工程選択
  let parentKouteiSentakuRef = useRef();

  // 工程計算
  let parentKouteiCalRef = useRef();

  // 工程入力
  let parentKouteiInputRef = useRef();

  // 購入品
  let parentPurchaseRef = useRef();

  const [currentRef, setCurrentRef] = useState([parentEstimateRef]);

  //親部品・子部品タブ切替により呼び出す
  useEffect(() => {
    setEditMode(false);
  }, [props.activePartMenu]);

  //親部品情報変更により呼び出す
  useEffect(() => {
    setSelectedEstimateData(props.selectedEstimateData);
    //新規作成の場合のみ
    if (props.selectedEstimateData?.name === '初期値') {
      document.getElementById('parent-update').style.display = 'block';
      document.getElementById('parent-discard').style.display = 'block';
      document.getElementById('parent-edit').style.display = 'none';
      setEditMode(true);
      setActiveTabKey('1');
      setCurrentRef(parentEstimateRef);
    }
  }, [props.selectedEstimateData]);

  //編集モード切替により呼び出す
  useEffect(() => {
    setEditMode(props.editMode);
    if (props.editMode) {
      document.getElementById('parent-update').style.display = 'block';
      document.getElementById('parent-discard').style.display = 'block';
      document.getElementById('parent-edit').style.display = 'none';
    } else {
      document.getElementById('parent-update').style.display = 'none';
      document.getElementById('parent-discard').style.display = 'none';
      document.getElementById('parent-edit').style.display = 'block';
    }
  }, [props.editMode]);

  useImperativeHandle(ref, () => ({
    getParentEstimateInfo: () => {
      return selectedEstimateData;
    },
    getParentEditMode: () => {
      return editMode;
    },
  }));

  const onChange = (key) => {
    setActiveTabKey(key);
    if (key == '1') {
      // 基本情報
      setCurrentRef(parentEstimateRef);
    } else if (key == '2') {
      // 工程選択
      setCurrentRef(parentKouteiSentakuRef);
    } else if (key == '3') {
      // 工程入力
      setCurrentRef(parentKouteiInputRef);
    } else if (key == '4') {
      // 工程積算
      setCurrentRef(parentKouteiCalRef);
    } else if (key == '5') {
      // 購入品
      setCurrentRef(parentPurchaseRef);
    } else if (key == '6') {
      // 添付ファイル
    } else if (key == '7') {
      // メモ
    }
  };

  const editData = () => {
    setEditMode(true);
    props.updateMainEstimateEditMode(true);
    document.getElementById('parent-update').style.display = 'block';
    document.getElementById('parent-discard').style.display = 'block';
    document.getElementById('parent-edit').style.display = 'none';
  };

  const updateData = () => {
    setDiscardMode(false);
    setConfirmMsgOpen(true);
  };

  const discardData = () => {
    setDiscardMode(true);
    setConfirmMsgOpen(true);
  };

  const confirmOk = (e) => {
    setConfirmMsgOpen(false);
    setEditMode(false);
    props.updateMainEstimateEditMode(false);
    document.getElementById('parent-update').style.display = 'none';
    document.getElementById('parent-discard').style.display = 'none';
    document.getElementById('parent-edit').style.display = 'block';
    let selectedParentEstimateData = { ...selectedEstimateData };
    if (activeTabKey == '1') {
      // 基本情報
      if (!discardMode) {
        const parentEstimateInfo = currentRef
          ? currentRef.length > 0
            ? currentRef[0]?.current?.getParentEstimateInfo()
            : currentRef?.current?.getParentEstimateInfo()
          : [];
        selectedParentEstimateData = parentEstimateInfo;
      }
    } else if (activeTabKey == '2') {
      // 工程選択
      if (!discardMode) {
        const kouteiSentakuPatternNo = currentRef?.current?.getKouteiSentakuPatternNo();
        selectedParentEstimateData.kouteiSentakuPatternNo = kouteiSentakuPatternNo;
      }
    } else if (activeTabKey == '3') {
      // 工程入力
      if (!discardMode) {
        const kouteiInputInfo = currentRef?.current?.getKouteiInputInfo();
        selectedParentEstimateData.kouteiInputInfo = kouteiInputInfo;
      }
    } else if (activeTabKey == '4') {
      // 工程積算
      if (!discardMode) {
        const kouteiCalInfo = currentRef?.current?.getKouteiCalInfo();
        // const selectedFlg = currentRef?.current?.getSelectedFlg();
        console.log('selectedParentEstimateData.kouteiCalInfoEditData---', kouteiCalInfo);
        selectedParentEstimateData.kouteiCalInfoEditData = kouteiCalInfo;
      }
    } else if (activeTabKey == '5') {
      // 購入品
      if (!discardMode) {
        const parentPurchaseInfo = currentRef?.current?.getParentPurchaseInfo();
        selectedParentEstimateData.parentPurchaseInfo = parentPurchaseInfo;
      }
    } else if (activeTabKey == '6') {
      // 添付ファイル
    } else if (activeTabKey == '7') {
      // メモ
    }
    console.log('updated selectedParentEstimateData---', selectedParentEstimateData);
    setSelectedEstimateData(selectedParentEstimateData);
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
  };

  const tempFileComponent = [
    <List
      itemLayout="horizontal"
      dataSource={tempFileData}
      renderItem={(item, index) => (
        <List.Item>
          <Row style={{ textAlign: 'center', height: '10px' }}>
            <Col style={{ width: '200px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.fileNm}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.fileSize}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.updated}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.deleted}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', padding: 5 }}>{item.download}</Col>
          </Row>
        </List.Item>
      )}
    />,
  ];

  const tabItems = [
    {
      key: '1',
      label: `基本情報`,
      children: (
        <ParentEstimateDetail
          ref={parentEstimateRef}
          selectedDataDetail={selectedEstimateData}
          editMode={props.editMode}
        />
      ),
    },

    {
      key: '2',
      label: `工程選択`,
      children: (
        <ParentKouteiSentaku
          ref={parentKouteiSentakuRef}
          editMode={props.editMode}
          selectedDataDetail={selectedEstimateData}
        />
      ),
    },
    {
      key: '3',
      label: `工程入力`,
      children: (
        <ParentKouteiInput
          ref={parentKouteiInputRef}
          selectedDataDetail={selectedEstimateData}
          editMode={props.editMode}
        />
      ),
    },
    {
      key: '4',
      label: `工程積算`,
      children: (
        <ParentKouteiCalculate
          ref={parentKouteiCalRef}
          selectedDataDetail={selectedEstimateData}
          editMode={props.editMode}
          pmxMode={false}
        />
      ),
    },
    {
      key: '5',
      label: `購入品`,
      children: (
        <ParentPurchase ref={parentPurchaseRef} selectedDataDetail={selectedEstimateData} editMode={props.editMode} />
      ),
    },
    {
      key: '6',
      label: `送付ファイル`,
      children: (
        <div style={{ marginTop: '-15px', marginLeft: '-34px', width: '100%' }}>
          <Row>
            <Col style={{ width: '90%' }}>{tempFileComponent}</Col>
            <Col style={{ width: '10%', marginTop: '20px' }}>
              <Button key="tempFileAdd" type="primary" className="editAllButton" style={{ marginLeft: 0, width: 120 }}>
                送付ファイルを追加
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      key: '7',
      label: `メモ`,
      children: (
        <div style={{ marginTop: '5px', marginLeft: '0px', width: '100%' }}>{selectedEstimateData?.comment}</div>
      ),
    },
  ];

  return (
    <>
      <Form>
        {/* 部品プロパティアリア */}
        <div
          style={{
            display: 'flex',
            height: '85.9vh',
            marginLeft: '5px',
          }}
        >
          <Tabs
            className="estimate-detail-tab"
            type="card"
            defaultActiveKey="1"
            activeKey={activeTabKey}
            items={tabItems}
            onChange={onChange}
          />
          <div style={{ marginLeft: '-620px', display: 'flex' }}>
            <KouteiSuryouBetsuBtn selectedEstimateData={selectedEstimateData} />
            <Button
              key="parent-update"
              type="primary"
              onClick={updateData}
              className="mainButton"
              style={{ marginRight: '10px', width: 60, height: 25 }}
              id="parent-update"
            >
              更新
            </Button>
            <Button
              key="parent-discard"
              type="primary"
              onClick={discardData}
              className="cancelButton"
              style={{ marginRight: '30px', width: 60, height: 25 }}
              id="parent-discard"
            >
              破棄
            </Button>
            <Button
              key="parent-edit"
              type="primary"
              onClick={editData}
              className="mainButton"
              style={{ marginLeft: 220, width: 60, height: 25 }}
              id="parent-edit"
            >
              編集
            </Button>
          </div>
        </div>
      </Form>
      {/** 更新 / 破棄の確認メッセージ */}
      {confirmMsgOpen
        ? commonModal(
            confirmMsgOpen,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            discardMode ? confirmDiscardContent : confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default ParentEstimate;
