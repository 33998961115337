/**
 * クラス名：板金子部品工程選択
 * 説明：板金子部品工程選択ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';

import {
  getInitDataForProcess,
  getInitDataForProcess1,
  getInitDataForProcess2,
  getInitDataForProcess3,
  getInitDataForProcess4,
  getInitDataForProcess5,
  patternPage,
  getInitDataForProcess_PMX_1_1,
} from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';

const listDataMain = getInitDataForProcess();
const processSelectedDataMain = listDataMain.find((i) => i.key === 1);
const page = patternPage[1];

const iq3PartList = [
  {
    key: 0,
    value: 1,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.1
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          看板本体
        </p>
      </div>
    ),
  },
  {
    key: 1,
    value: 2,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.2
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          看板本体-2塗装
        </p>
      </div>
    ),
  },
  {
    key: 2,
    value: 3,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.3
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          看板スタンド-1
        </p>
      </div>
    ),
  },
  {
    key: 3,
    value: 4,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.4
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          看板スタンド-2
        </p>
      </div>
    ),
  },
  {
    key: 4,
    value: 5,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.5
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          看板スタンド-3x5
        </p>
      </div>
    ),
  },
];
const iq3PartList_PMX_1 = [
  {
    key: 0,
    value: 1,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.1
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          MRO-M08A-4110-01
        </p>
      </div>
    ),
  },
  {
    key: 1,
    value: 2,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.2
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          MRO-M08A-4110-02
        </p>
      </div>
    ),
  },
  {
    key: 2,
    value: 3,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.3
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          MRO-M08A-4110-03
        </p>
      </div>
    ),
  },
  {
    key: 3,
    value: 4,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.4
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          MRO-M08A-4110-04
        </p>
      </div>
    ),
  },
];
const iq3PartList_PMX_2 = [
  {
    key: 0,
    value: 1,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.1
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-01
        </p>
      </div>
    ),
  },
  {
    key: 1,
    value: 2,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.2
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-02
        </p>
      </div>
    ),
  },
  {
    key: 2,
    value: 3,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.3
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-03
        </p>
      </div>
    ),
  },
  {
    key: 3,
    value: 4,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.4
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-04
        </p>
      </div>
    ),
  },
  {
    key: 4,
    value: 5,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.5
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-05
        </p>
      </div>
    ),
  },
  {
    key: 5,
    value: 6,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.6
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-06
        </p>
      </div>
    ),
  },
  {
    key: 6,
    value: 7,
    label: (
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            paddingLeft: 10,
            margin: 0,
            paddingRight: 10,
          }}
        >
          No.7
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          ISZ-HRK1-1300-07
        </p>
      </div>
    ),
  },
];

const IQ3KouteiSentaku = forwardRef((props, ref) => {
  const [childPartNo, setChildPartNo] = useState(1);
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [selectedChildPartInfo, setSelectedChildPartInfo] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState([]);
  const [iq3List, setIQ3List] = useState([]);

  const patternformRef = useRef();
  const currentRoute = location.pathname;

  useEffect(() => {
    let kouteiPatternList = [];
    let kouteiSentakuPatternNo = 0;
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedIQ3DataDetail != undefined) {
      kouteiSentakuPatternNo = props.selectedIQ3DataDetail.iq3KouteiSentakuPatternNo;
    }
    kouteiPatternList =
      localStorage.getItem('iq3_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('iq3_processPattern'))
        : [];
    setListData(kouteiPatternList);
    let targertProcessPatternInfo = kouteiPatternList.filter((i) => i.patternNo === kouteiSentakuPatternNo);
    if (targertProcessPatternInfo.length > 0) {
      setProcessSelectedData(targertProcessPatternInfo[0]);
    } else {
      setProcessSelectedData(kouteiPatternList[0]);
    }

    let iq3List = props.iQ3DataLst == undefined ? [] : props.iQ3DataLst;
    let iq3List_SelectBoxData = [];
    for (let i = 0; i < iq3List.length; i++) {
      iq3List_SelectBoxData.push({ label: 'No.' + iq3List[i].no + '　' + iq3List[i].partNm, value: iq3List[i].key });
    }
    setIQ3List(iq3List_SelectBoxData);
  }, [props.selectedIQ3DataDetail, kouteiSentakuPatternNo]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
  }, [props.editMode]);

  const updateKouteiSentakuInfo = (selectedProcessPattern) => {
    setKouteiSentakuPatternNo(selectedProcessPattern.patternNo);
    setProcessSelectedData(selectedProcessPattern);
  };

  const updateSelectedData = (selectedPart) => {
    props.updateSelectedData(selectedPart);
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
    }),
    [kouteiSentakuPatternNo]
  );

  const onSearch = (value) => console.log(value);
  // const onChangeChildPartNo = (no) => {
  //   console.log(no);
  //   if (no == 1) {
  //     setListData(getInitDataForProcess1);
  //     const listData = getInitDataForProcess1();
  //     setProcessSelectedData(listData.find((i) => i.key === 1));
  //   } else if (no == 2) {
  //     setListData(getInitDataForProcess2);
  //     const listData = getInitDataForProcess2();
  //     setProcessSelectedData(listData.find((i) => i.key === 1));
  //   } else if (no == 3) {
  //     setListData(getInitDataForProcess3);
  //     const listData = getInitDataForProcess3();
  //     setProcessSelectedData(listData.find((i) => i.key === 1));
  //   } else if (no == 4) {
  //     setListData(getInitDataForProcess4);
  //     const listData = getInitDataForProcess4();
  //     setProcessSelectedData(listData.find((i) => i.key === 1));
  //   } else if (no == 5) {
  //     setListData(getInitDataForProcess5);
  //     const listData = getInitDataForProcess5();
  //     setProcessSelectedData(listData.find((i) => i.key === 1));
  //   }
  // };
  return (
    <>
      {/* <ProcessPatternForm
        selectedData={processSelectedData}
        listData={listData}
        editMode={props.editMode}
        page={page}
        iq3PartList={selectedChildPartInfo}
        childPartNo={childPartNo}
        pmxMode={props.pmxMode}
      /> */}
      <ProcessPatternForm
        ref={patternformRef}
        selectedData={processSelectedData}
        listData={listData}
        editMode={false}
        page={patternPage[1]}
        isParameter={false}
        updateKouteiSentakuInfo={updateKouteiSentakuInfo}
        kouteiSentakuEditMode={editMode}
        iq3PartList={iq3List}
        childPartNo={props.selectedIQ3DataDetail != undefined ? props.selectedIQ3DataDetail.key : 0}
        updateSelectedData={updateSelectedData}
        pmxMode={props.pmxMode}
      />
    </>
  );
});

export default IQ3KouteiSentaku;
