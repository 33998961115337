/**
 * クラス名：板金の装置設定の段取設定画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Checkbox } from 'antd';
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { commonModal } from '../../../common/CommonModal';
import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';

const Device_SetupSetting = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isMagesuu, setIsMageSuu] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [isArea, setIsArea] = useState(false);

  // 曲げ数
  const [magesuuTemp, setMagesuuTemp] = useState([]);
  const [mageSuuData, setMageSuuData] = useState([]);
  const [magesuuList, setMagesuuList] = useState([]);

  const [category, setCategory] = useState('');
  // 要素数
  const [isYousosuu, setIsYousosuu] = useState(false);
  const [yousosuu, setYousosuu] = useState([]);
  const [yousosuuTemp, setYousosuuTemp] = useState([]);
  const [yousosuuLst, setYousosuuLst] = useState([]);
  const [sagyouYousuuList, setSagyouYousuuList] = useState([]);

  //   曲げ個数
  const [mageKosuuData, setMageKosuuData] = useState([]);
  const [mageKosuuDataTemp, setMageKosuuDataTemp] = useState([]);
  const [mageKosuuList, setMageKosuuList] = useState([]);
  const [isMageKosuu, setIsMageKosuu] = useState(false);
  //   重量と曲げ長
  const [weightData, setWeightData] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);
  const [detailsList, setDetailsList] = useState([]);

  // 加工方法
  const [processMethod, setProcessMethod] = useState('');

  // 重量と面積
  const [correctionTimeFactorList, setcorrectionTimeFactorList] = useState([]);

  // 曲げ長さ別段取時間
  const [magechouDantoriJikanList, setMagechouDantoriJikanList] = useState([]);

  const [setupTime, setSetupTime] = useState('00:00:00');

  // 段取設定
  const [dantoriKouteiDetails, setDantoriKouteiDetails] = useState([]);

  // 生産個数
  const [kosuuData, setKosuuData] = useState([]);
  const [kosuuDataTemp, setKosuuDataTemp] = useState([]);
  const [kosuuList, setKosuuList] = useState([]);
  const [isKosuu, setIsKosuu] = useState(false);

  // 製品重量係数
  const [iq3SeihinSizeLst, setIq3SeihinSizeLst] = useState([]);
  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);

  // 工程内検査の生産個数
  const [processInspectionKosuuListosuuList, setProcessInspectionKosuuListosuuList] = useState([]);

  // 本数Honsuu
  const [honsuuData, setHonsuuData] = useState([]);
  const [honsuuDataTemp, sethonsuuDataTemp] = useState([]);
  const [honsuuList, setHonsuuList] = useState([]);
  const [isHonsuu, setIsHonsuu] = useState(false);

  // 段取を個別に持つ
  const [dantoriFlag, setDantoriFlag] = useState(false);
  useEffect(() => {
    setEditMode(props.editMode);

    setMageSuuData(props.mageSuuData);
    setMagesuuTemp(props.mageSuuData);
    setMagesuuList(props.mageSuuLst);
    setCategory(props.processName);

    // 要素数
    setYousosuu(props.yousosuuMasterData);
    setYousosuuTemp(props.yousosuuMasterData);
    setYousosuuLst(props.shokidankaiYousosuuList);
    setSagyouYousuuList(props.sagyouKeisuuYousosuuList);

    // 曲げ個数
    setMageKosuuData(props.mageKosuu);
    setMageKosuuDataTemp(props.mageKosuu);
    setMageKosuuList(props.mageKosuuList);

    setWeightData(props.weight);
    setLengthData(props.length);
    setDetailsLengthTemp(props.length);
    setDetailsWeightTemp(props.weight);
    setDetailsList(props.dantoriJuuryouMagechouList);

    setMagechouDantoriJikanList(props.magechouDantoriJikanList);
    setDantoriKouteiDetails(props.detailsData);

    // 生産個数
    setKosuuData(props.kosuu);
    setKosuuDataTemp(props.kosuu);
    setKosuuList(props.kosuuList);

    // 本数
    setHonsuuData(props.honSuu);
    sethonsuuDataTemp(props.honSuu);
    setHonsuuList(props.honSuuList);

    // 基本段取時間
    setSetupTime(props.dantoriTime);

    setAreaData(props.area);
    setDetailsAreaTemp(props.area);
    setcorrectionTimeFactorList(props.correctionTimeFactorListData);

    // 製品重量係数
    setIq3SeihinSizeLst(props.iq3SeihinSizeLst);
    // マテリアルハンドリング時間
    setMaterialHandlingTimeList(props.materialHandlingTimeList);
    setProcessMethod(props.processMethod);
    setProcessInspectionKosuuListosuuList(props.processInspectionKosuuListosuuList);
    setDantoriFlag(props.dantoriFlag);
  }, [
    props.processName,
    props.editMode,
    props.mageSuuData,
    props.mageSuuLst,
    props.yousosuuMasterData,
    props.shokidankaiYousosuuList,
    props.sagyouKeisuuYousosuuList,
    props.dantoriSetteiSuuryouList,
    props.mageKosuu,
    props.mageKosuuList,
    props.dantoriJuuryouMagechouList,
    props.magechouDantoriJikanList,
    props.detailsData,
    props.kosuu,
    props.kosuuList,
    props.dantoriTime,
    props.area,
    props.correctionTimeFactorListData,
    props.honSuuList,
    props.honSuu,
    props.processMethod,
    props.processInspectionKosuuListosuuList,
    props.dantoriFlag,
  ]);
  const quantity = () => {
    setIsKosuu(true);
  };
  const number = () => {
    setIsHonsuu(true);
  };
  const changeTime = (e) => {
    setSetupTime(e);
    props.updatedDantoriTime(e);
  };
  const addMagesuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>曲げ数</p>
    </div>
  );
  const addMageKosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>個数</p>
    </div>
  );
  const addYousosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>要素数</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };
  const updateDataOk = () => {
    if (isMagesuu == true) {
    }
  };
  const updateDataCancel = () => {
    if (isMagesuu == true) {
      setIsMageSuu(true);
    }
    setIsUpdateData(false);
  };

  const discardOk = () => {
    if (isMagesuu == true) {
      setIsMageSuu(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isMagesuu == true) {
      setIsMageSuu(true);
    } else if (isYousosuu === true) {
      setIsYousosuu(true);
    }
    setIsDiscardData(false);
  };
  const closeAddModal = () => {
    if (isMagesuu === true) {
      setIsMageSuu(false);
    } else if (isYousosuu === true) {
      setIsYousosuu(false);
    } else if (isMageKosuu === true) {
      setIsMageKosuu(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isArea === true) {
      setIsArea(false);
    } else if (isLength === true) {
      setIsLength(false);
    } else if (isHonsuu === true) {
      setIsHonsuu(false);
    } else if (isKosuu === true) {
      setIsKosuu(false);
    }
  };
  const handleYousosuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(yousosuuTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].max;
    if (previousValue) {
      updatedData[updatedData.length - 1].max = (parseInt(previousValue) + 1).toString();
      setYousosuuTemp(updatedData);
    } else {
      setYousosuuTemp(updatedData);
    }
  };
  const addYousosuu = (id) => {
    // const data = {
    //   id: yousosuuTemp.length + 1,

    //   max: '',
    //   displayFlag: true,
    // };
    // let result = [...yousosuuTemp, data];
    // setYousosuuTemp(result);
    const copyData = yousosuuTemp.slice(); // Create a copy of the original array

    const insertIndex = yousosuuTemp.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: yousosuuTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setYousosuuTemp(copyData);
    }
  };
  const deleteYousosuu = (id, e) => {
    // if (yousosuuTemp.filter((item) => item.displayFlag === true).length > 1) {
    //   const updatedData = yousosuuTemp.map((row) => {
    //     if (row.id === id) {
    //       return { ...row, displayFlag: false };
    //     }
    //     return row;
    //   });
    //   setYousosuuTemp(updatedData);
    // }
    const listTemp = JSON.parse(JSON.stringify(yousosuuTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = suuRyou[suuRyou.length - 2].val;
      if (previousValue) {
        updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
        setYousosuuTemp(updatedData);
      } else {
        setYousosuuTemp(updatedData);
      }
    }
  };
  const addYousosuuMasterData = () => {
    const deletedId = [];

    yousosuu.forEach((item1, index) => {
      const found = yousosuuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = yousosuuTemp
      .filter((item) => item.displayFlag && !yousosuu.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = yousosuuLst.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const sagyouYousuuDelete = sagyouYousuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedSagyouYousuuData = sagyouYousuuDelete.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = yousosuuTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = yousosuuTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updatedSagyouYousuuData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = yousosuuTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = yousosuuTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    setIsYousosuu(false);
    setYousosuuTemp(yousosuuTemp);

    setYousosuuLst(sortedList);
    props.updatedYousosuuMasterData(yousosuuTemp);

    props.updatedYousosuuList(sortedList);

    setSagyouYousuuList(sortedList2);
    props.updatedSagyouYousosuuList(sortedList2);
  };
  const cancelMasterData = () => {
    if (isYousosuu === true) {
      setYousosuuTemp(yousosuu);
      setIsYousosuu(false);
    } else if (isMagesuu === true) {
      setMagesuuTemp(mageSuuData);
      setIsMageSuu(false);
    } else if (isMageKosuu === true) {
      setMageKosuuDataTemp(mageKosuuData);
      setIsMageKosuu(false);
    } else if (isLength === true) {
      setDetailsLengthTemp(lengthData);
      setIsLength(false);
    } else if (isWeight === true) {
      setDetailsWeightTemp(weightData);
      setIsWeight(false);
    } else if (isKosuu === true) {
      setKosuuDataTemp(kosuuData);
      setIsKosuu(false);
    }
  };
  const handleMageKosuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(mageKosuuDataTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].val;
    if (previousValue) {
      updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
      setMageKosuuDataTemp(updatedData);
    } else {
      setMageKosuuDataTemp(updatedData);
    }
  };
  const addKosuu = (id) => {
    const copyData = kosuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = kosuuDataTemp.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: kosuuDataTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setKosuuDataTemp(copyData);
    }
  };
  const addHonsuu = (id) => {
    const copyData = honsuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = honsuuDataTemp.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: honsuuDataTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      sethonsuuDataTemp(copyData);
    }
  };
  const handleKosuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(kosuuDataTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].val;
    if (previousValue) {
      updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
      setKosuuDataTemp(updatedData);
    } else {
      setKosuuDataTemp(updatedData);
    }
  };
  const handleHonsuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(honsuuDataTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].val;
    if (previousValue) {
      updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
      sethonsuuDataTemp(updatedData);
    } else {
      sethonsuuDataTemp(updatedData);
    }
  };
  const deleteKosuu = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(kosuuDataTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = suuRyou[suuRyou.length - 2].val;
      if (previousValue) {
        updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
        setKosuuDataTemp(updatedData);
      } else {
        setKosuuDataTemp(updatedData);
      }
    }
  };
  const deleteHosuu = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(honsuuDataTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const filterData = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = filterData[filterData.length - 2].val;
      if (previousValue) {
        updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
        sethonsuuDataTemp(updatedData);
      } else {
        sethonsuuDataTemp(updatedData);
      }
    }
  };
  const addKosuuMasterData = () => {
    const deletedId = [];

    kosuuData.forEach((item1, index) => {
      const found = kosuuDataTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = kosuuDataTemp
      .filter((item) => item.displayFlag && !kosuuData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = kosuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const afterDeleted2 = processInspectionKosuuListosuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedData2 = afterDeleted2.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = kosuuDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = kosuuDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updatedData2.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = kosuuDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = kosuuDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setIsKosuu(false);
    setKosuuDataTemp(kosuuDataTemp);

    setKosuuList(sortedList);
    props.updatedKosuu(kosuuDataTemp);

    props.updatedKosuuList(sortedList);
    setProcessInspectionKosuuListosuuList(sortedList2);
    props.updatedProcessInspectionKosuuList(sortedList2);
  };
  const addHonsuuMasterData = () => {
    const deletedId = [];

    honsuuData.forEach((item1, index) => {
      const found = honsuuDataTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = honsuuDataTemp
      .filter((item) => item.displayFlag && !honsuuData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = honsuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = honsuuDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = honsuuDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setIsHonsuu(false);
    sethonsuuDataTemp(honsuuDataTemp);

    setHonsuuList(sortedList);
    props.updateHonsuu(honsuuDataTemp);

    props.updateHonsuuList(sortedList);
  };
  const addKosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {kosuuDataTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleKosuuChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addKosuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteKosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addKosuuMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addHonsuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {honsuuDataTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleHonsuuChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addHonsuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteHosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addHonsuuMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const handleAreaChange = (event, id, field) => {
    const updatedData = detailsAreaTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };
  const addAreaRow = (id) => {
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsAreaTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };
  const deleteArea = (id, e) => {
    if (detailsAreaTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsAreaTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
    }
  };
  const addAreaMaster = () => {
    const deletedId = [];

    areaData.forEach((item1, index) => {
      const found = detailsAreaTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsAreaTemp
      .filter((item) => item.displayFlag && !areaData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = correctionTimeFactorList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIsArea(false);
    setDetailsAreaTemp(detailsAreaTemp);

    setcorrectionTimeFactorList(sortedList);
    props.updatedArea(detailsAreaTemp);

    props.updatedcorrectionTimeFactorListData(sortedList);

    // 面積マスター更新によりマテリアルハンドリング時間一覧更新
    updateMaterialHandlingListByAreaMaster(deletedId, newId, detailsAreaTemp);
    // 面積マスター更新により製品重量係数一覧更新
    updateIq3SeihinSizeLstByAreaMaster(deletedId, newId, detailsAreaTemp);
  };
  // 面積マスター更新によりマテリアルハンドリング時間一覧更新
  const updateMaterialHandlingListByAreaMaster = (deletedId, newId, detailsAreaTemp) => {
    const deleteData = materialHandlingTimeList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setMaterialHandlingTimeList(sortedList);
    props.updateMaterialHandlingList(sortedList);
  };
  // 面積マスター更新により製品重量係数一覧更新
  const updateIq3SeihinSizeLstByAreaMaster = (deletedId, newId, detailsAreaTemp) => {
    const deleteData = iq3SeihinSizeLst.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        displayFlag: item.displayFlag,
        lstData: sortedLstData,
      };
    });

    setIq3SeihinSizeLst(sortedList);
    props.updatediq3ProductSizesList(sortedList);
  };
  const cancelAreaMaster = () => {
    setDetailsAreaTemp(areaData);
    setIsArea(false);
  };
  const addAreaContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleAreaChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelAreaMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addMageKosuu = (id) => {
    const copyData = mageKosuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = mageKosuuDataTemp.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: mageKosuuDataTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setMageKosuuDataTemp(copyData);
    }
  };
  const changeKosuu = (event, rowIndex, colIndex) => {
    const copyData = [...kosuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setKosuuList(updatedData);
    props.updatedKosuuList(updatedData);
  };
  const changeHonsuu = (event, rowIndex, colIndex) => {
    const copyData = [...honsuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setHonsuuList(updatedData);
    props.updateHonsuuList(updatedData);
  };
  const deleteMageKosuu = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(mageKosuuDataTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = suuRyou[suuRyou.length - 2].val;
      if (previousValue) {
        updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
        setMageKosuuDataTemp(updatedData);
      } else {
        setMageKosuuDataTemp(updatedData);
      }
    }
  };
  const addMageKosuuMasterData = () => {
    const deletedId = [];

    mageKosuuData.forEach((item1, index) => {
      const found = mageKosuuDataTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = mageKosuuDataTemp
      .filter((item) => item.displayFlag && !mageKosuuData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = mageKosuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = mageKosuuDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = mageKosuuDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setIsMageKosuu(false);
    setMageKosuuDataTemp(mageKosuuDataTemp);

    setMageKosuuList(sortedList);
    props.updatedMageKosuu(mageKosuuDataTemp);

    props.updatedMageKosuuList(sortedList);
  };
  const addKosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>生産個数</p>
    </div>
  );
  const addHonsuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>本数</p>
    </div>
  );
  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );
  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>曲長</p>
    </div>
  );
  const addYousosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }}>No</th>

                  <th style={{ width: '60%' }}>
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {yousosuuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleYousosuuChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addYousosuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteYousosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addYousosuuMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addMageKosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {mageKosuuDataTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleMageKosuuChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addMageKosuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteMageKosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addMageKosuuMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addMagesuu = (id) => {
    const copyData = magesuuTemp.slice(); // Create a copy of the original array

    const insertIndex = magesuuTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: magesuuTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setMagesuuTemp(copyData);
    }
  };
  const deleteYousetsuSenchou = (id, e) => {
    if (magesuuTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = magesuuTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setMagesuuTemp(updatedData);
    }
  };

  const handleMagesuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(magesuuTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].val;
    if (previousValue) {
      updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();

      setMagesuuTemp(updatedData);
    } else {
      setMagesuuTemp(updatedData);
    }
  };

  const addMagesuuMaster = () => {
    setIsMageSuu(false);

    const deletedId = [];

    mageSuuData.forEach((item1, index) => {
      const found = magesuuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = magesuuTemp
      .filter((item) => item.displayFlag && !mageSuuData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = magesuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = magesuuTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = magesuuTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setMageSuuData(magesuuTemp);

    setMagesuuList(sortedList);
    props.updatedMagesuuData(magesuuTemp);

    props.updatedMagesuuList(sortedList);
  };

  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {magesuuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleMagesuuChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addMagesuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteYousetsuSenchou(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addMagesuuMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const mageSuuModal = () => {
    setIsMageSuu(true);
  };
  const bendNumber = () => {
    setIsMageKosuu(true);
  };
  const openYousosuuModal = () => {
    setIsYousosuu(true);
  };
  const changeYousuu = (event, rowIndex, colIndex) => {
    const copyData = [...yousosuuLst];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setYousosuuLst(updatedData);
    props.updatedYousosuuList(updatedData);
  };
  const magesuuListDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...magesuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    setMagesuuList(updatedData);

    props.updatedMagesuuList(updatedData);
  };
  const changeMageKosuu = (event, rowIndex, colIndex) => {
    const copyData = [...mageKosuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setMageKosuuList(updatedData);
    props.updatedMageKosuuList(updatedData);
  };
  const changeMageSuuJikan = (event, rowIndex, colIndex) => {
    const copyData = [...magechouDantoriJikanList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setMagechouDantoriJikanList(updatedData);
    props.updatedmagechouDantoriJikanListData(updatedData);
  };
  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...correctionTimeFactorList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setcorrectionTimeFactorList(updatedData);
    props.updatedcorrectionTimeFactorListData(updatedData);
  };
  const listDataChangeMenseki = (event, rowIndex, colIndex) => {
    const copyData = [...detailsList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setDetailsList(updatedData);
    props.updatedDantoriJuuryouMagechouList(updatedData);
  };
  const addWeightMaster = () => {
    const deletedId = [];

    weightData.forEach((item1, index) => {
      const found = detailsWeightTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsWeightTemp
      .filter((item) => item.displayFlag && !weightData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const test = detailsList.filter((it) => !deletedId.includes(it.key));

    const result = test.map((item) => {
      const list = detailsWeightTemp.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId.forEach((id) => {
      const list = detailsWeightTemp.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result.push(newObj);
      }
    });
    result.sort((a, b) => {
      const aIndex = detailsWeightTemp.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    setDetailsList(result);
    props.updatedWeight(detailsWeightTemp);

    props.updatedDantoriJuuryouMagechouList(result);
  };
  const handleLengthChange = (event, id, field) => {
    const updatedData = detailsLengthTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = detailsWeightTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };
  const addLengthRow = (id) => {
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsLengthTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e) => {
    if (detailsLengthTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = detailsLengthTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
    }
  };

  const addLengthMaster = () => {
    const deletedId = [];

    lengthData.forEach((item1, index) => {
      const found = detailsLengthTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = detailsLengthTemp
      .filter((item) => item.displayFlag && !lengthData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deleteData = detailsList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const dantoriJikanMagechou = magechouDantoriJikanList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = deleteData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const updatedData2 = dantoriJikanMagechou.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsLengthTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsLengthTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updatedData2.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsLengthTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsLengthTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    console.log('曲長さ別段取時間ーーーー', sortedList2);
    setIsLength(false);
    setDetailsLengthTemp(detailsLengthTemp);

    setDetailsList(sortedList);
    props.updatedLength(detailsLengthTemp);

    props.updatedDantoriJuuryouMagechouList(sortedList);

    setMagechouDantoriJikanList(sortedList2);
    props.updatedmagechouDantoriJikanListData(sortedList2);
  };
  const deleteWeight = (id, e) => {
    if (detailsWeightTemp.filter((item) => item.displayFlag === true).length > 3) {
      const updatedData = detailsWeightTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
    }
  };
  const addWeightRow = (id) => {
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: detailsWeightTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleLengthChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addLengthMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleWeightChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const changeflag = (e) => {
    const isChecked = e.target.checked;
    setDantoriFlag(isChecked);
    props.updateDantoriFlag(isChecked);
  };
  const addLength = () => {
    setIsLength(true);
  };
  const addWeight = () => {
    setIsWeight(true);
  };
  const changeDantoriTime = (itemId, subItemId, newName) => {
    const updatedData = dantoriKouteiDetails.map((item) => {
      if (item.key === itemId) {
        const updatedSubItems = item.processDetailValue.map((subItem) => {
          if (subItem.key === subItemId) {
            return { ...subItem, dantoriTime: newName };
          }
          return subItem;
        });

        return { ...item, processDetailValue: updatedSubItems };
      }
      return item;
    });

    setDantoriKouteiDetails(updatedData);
    props.updatedDantoriSetting(updatedData);
  };
  const addArea = () => {
    setIsArea(true);
  };
  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        {category === 'プログラム' ? (
          <div className="table-container">
            <Table
              className="tsuikakoutei"
              style={{
                width:
                  yousosuu.filter((i) => i.displayFlag === true).length > 7
                    ? '100%'
                    : yousosuu.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
              }}
            >
              <tr style={{ color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                <td style={{ width: '196px' }} className="tbl-header">
                  <Row justify="center" align="middle" className="tbl-header">
                    <Space>
                      <label>（秒）</label>
                      <label>/</label>
                      {editMode ? (
                        <>
                          <Button
                            className="cancleButton"
                            onClick={openYousosuuModal}
                            style={{ pointerEvents: 'auto' }}
                          >
                            要素数
                          </Button>
                        </>
                      ) : (
                        <>
                          <label>要素数</label>
                        </>
                      )}
                    </Space>
                  </Row>
                </td>

                {yousosuu
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item) => (
                    <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}</td>
                  ))}
              </tr>

              {yousosuuLst.map((row, rowIndex) => (
                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }}>{row.materialType}</td>

                  {row.lstData.map((col, colIndex) => (
                    <td style={{ width: '98px' }}>
                      <Input
                        value={col.value}
                        onChange={(e) => changeYousuu(e, rowIndex, colIndex)}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </Table>
          </div>
        ) : category === 'ベンディング' ? (
          <>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    mageSuuData.filter((i) => i.displayFlag === true).length > 8
                      ? '100%'
                      : mageSuuData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        曲げ種類 /
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={mageSuuModal} style={{ pointerEvents: 'auto' }}>
                              曲げ数
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>曲げ数</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {mageSuuData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        {mageSuuData[mageSuuData.length - 1].id === item.id ? (
                          <>{item.val}曲げ～</>
                        ) : (
                          <>～{item.val}曲げ</>
                        )}
                      </td>
                    ))}
                </tr>

                {magesuuList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="tbl-header fixexCol">
                      {row.materialType}
                    </td>

                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => magesuuListDataChange(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    lengthData.filter((i) => i.displayFlag === true).length > 7
                      ? '100%'
                      : lengthData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol tbl-header">
                    <Row justify="center" className="tbl-header">
                      {editMode ? (
                        <>
                          <Space>
                            <label>段取時間</label>
                            <label>/</label>
                            <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                              曲長
                            </Button>
                          </Space>
                        </>
                      ) : (
                        <>
                          <Space>
                            <label>段取時間</label>
                            <label>/</label>
                            <label>曲長</label>
                          </Space>
                        </>
                      )}
                    </Row>
                  </td>

                  {lengthData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>～{item.max}mm</td>
                    ))}
                </tr>

                {magechouDantoriJikanList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }}>{row.materialType}</td>
                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => changeMageSuuJikan(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
            <Row className="chargeAmount">
              <Col style={{ width: '20%' }}>
                {/* <Table>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                      <th className="tbl-header">工程内検査時間（秒）</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Row align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{setupTime}</label>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={10}>
                                <Input
                                  value={setupTime}
                                  // onChange={(e) => {
                                  //   setSetupTime(e.target.value);
                                  //
                                  // }}
                                  onChange={(event) => changeTime(event.target.value)}
                                ></Input>
                              </Col>
                              <Col span={14}>
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 16 + 'px',
                                    marginLeft: 3,
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(setupTime, '10min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(setupTime, '10min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(setupTime, 'min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(setupTime, 'min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(setupTime, 'sec');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(setupTime, 'sec');
                                    changeTime(editedTime);
                                  }}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table> */}
                <Table>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                      <th className="tbl-header">段取を個別に持つ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Checkbox
                          style={{ marginLeft: 3 }}
                          type="checkbox"
                          disabled={!editMode}
                          // checked={subItem.dantoriFlag}
                          onChange={(e) => {
                            // const data = [...processDetails];
                            // const processDetailValue = data[item.key].processDetailValue;
                            // processDetailValue[subItem.key].dantoriFlag = e.target.checked;
                            // if (e.target.checked) {
                            //   processDetailValue[subItem.key].kakouFlag = false;
                            // }
                            // setProcessDetails(data);
                          }}
                        ></Checkbox>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    mageKosuuData.filter((i) => i.displayFlag === true).length > 7
                      ? '100%'
                      : mageKosuuData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      {editMode ? (
                        <>
                          <Button className="cancleButton" onClick={bendNumber} style={{ pointerEvents: 'auto' }}>
                            曲げ個数
                          </Button>
                        </>
                      ) : (
                        <>
                          <label>曲げ個数</label>
                        </>
                      )}
                    </Row>
                  </td>

                  {mageKosuuData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        {mageKosuuData[mageKosuuData.length - 1].id === item.id ? (
                          <>{item.val} 個～</>
                        ) : (
                          <>～{item.val}個</>
                        )}
                      </td>
                    ))}
                </tr>

                {mageKosuuList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px', position: 'sticky' }}>{row.materialType}</td>
                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => changeMageKosuu(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    lengthData.filter((i) => i.displayFlag === true).length > 8
                      ? '100%'
                      : lengthData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                              重量
                            </Button>
                            /
                            <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                              面積
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>重量 / 面積</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {areaData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.max}cm<sup className="tbl-header">2</sup>
                      </td>
                    ))}
                </tr>

                {correctionTimeFactorList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ backgroundColor: '#1063aa', color: 'white', width: '196px' }} className="fixedCol">
                      ～{row.max}kg
                    </td>

                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
          </>
        ) : category === '2次加工' ? (
          <>
            {dantoriKouteiDetails.map((item) => (
              <Row key={item.key} className="chargeAmount">
                {item.processDetailValue.map((subItem) => (
                  <Col style={{ width: '20%' }}>
                    <Table>
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">{subItem.detailType}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ textAlign: 'center', height: 35 }}>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{subItem.dantoriTime}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={10}>
                                    <Input
                                      value={subItem.dantoriTime}
                                      onChange={(event) => changeDantoriTime(item.key, subItem.key, event.target.value)}
                                    ></Input>
                                  </Col>
                                  <Col span={14}>
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 16 + 'px',
                                        marginLeft: 3,
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, '10min');

                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, '10min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, 'min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, 'min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, 'sec');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, 'sec');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ))}
              </Row>
            ))}
          </>
        ) : (
          <></>
        )}
        {category === '2次加工' || category === 'バリ取' || category === 'シャーリング' || category === 'ブランク' ? (
          <>
            <Row className="chargeAmount">
              <Col style={{ width: '40%' }}>
                <Table className="tsuikakoutei">
                  <thead>
                    <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                      <th className="tbl-header" style={{ width: '20%' }}>
                        基本段取時間
                      </th>
                      <th className="tbl-header" style={{ width: '20%' }}>
                        段取を個別に持つ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Row align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{setupTime}</label>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={10}>
                                <Input
                                  value={setupTime}
                                  onChange={(e) => {
                                    setSetupTime(e.target.value);
                                  }}
                                ></Input>
                              </Col>
                              <Col span={14}>
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 16 + 'px',
                                    marginLeft: 3,
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(setupTime, '10min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(setupTime, '10min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(setupTime, 'min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(setupTime, 'min');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(setupTime, 'sec');
                                    changeTime(editedTime);
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(setupTime, 'sec');
                                    changeTime(editedTime);
                                  }}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </td>
                      <td>
                        <Checkbox
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          checked={dantoriFlag}
                          onChange={changeflag}
                        ></Checkbox>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className="qty-table-container">
              <Table
                style={{
                  width:
                    kosuuData.filter((i) => i.displayFlag === true).length > 7
                      ? '100%'
                      : kosuuData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      {editMode ? (
                        <>
                          <Button className="cancleButton" onClick={quantity} style={{ pointerEvents: 'auto' }}>
                            生産個数
                          </Button>
                        </>
                      ) : (
                        <>
                          <label>生産個数</label>
                        </>
                      )}
                    </Row>
                  </td>

                  {kosuuData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        {kosuuData[kosuuData.length - 1].id === item.id ? <>{item.val}個 ～</> : <>～{item.val}個</>}
                      </td>
                    ))}
                </tr>

                {kosuuList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td>{row.materialType}</td>
                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => changeKosuu(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
        {category === 'バリ取' ? (
          <>
            <div className="table-container">
              <Table
                style={{
                  width:
                    areaData.filter((i) => i.displayFlag === true).length > 8
                      ? '100%'
                      : areaData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                              重量
                            </Button>
                            /
                            <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                              面積
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>重量 / 面積</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {areaData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.max}cm<sup className="tbl-header">2</sup>
                      </td>
                    ))}
                </tr>

                {correctionTimeFactorList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="fixedCol tbl-header">
                      ～{row.max}kg
                    </td>

                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => listDataChange(e, rowIndex, colIndex, 'menseki')}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
        {processMethod === 'パンチ' || processMethod === '複合機' ? (
          <>
            <div className="qty-table-container">
              <Table
                style={{
                  width:
                    honsuuData.filter((i) => i.displayFlag === true).length > 7
                      ? '100%'
                      : honsuuData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        <label>段取時間</label>
                        <label>/</label>
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={number} style={{ pointerEvents: 'auto' }}>
                              本数
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>本数</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {honsuuData
                    .filter((subitem) => subitem.displayFlag === true)
                    .map((item) => (
                      <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        {honsuuData[honsuuData.length - 1].id === item.id ? <>{item.val}本 ～</> : <>～{item.val}本</>}
                      </td>
                    ))}
                </tr>

                {honsuuList.map((row, rowIndex) => (
                  <tr style={{ textAlign: 'center' }}>
                    <td>{row.materialType}</td>
                    {row.lstData.map((col, colIndex) => (
                      <td style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => changeHonsuu(e, rowIndex, colIndex)}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {/* 曲げ数 */}
      {isMagesuu
        ? commonModal(
            isMagesuu,
            addMagesuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
      {/* 要素数 */}
      {isYousosuu
        ? commonModal(
            isYousosuu,
            addYousosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addYousosuuContent,
            null,
            null,
            true
          )
        : ''}
      {/* 曲げ個数 */}
      {isMageKosuu
        ? commonModal(
            isMageKosuu,
            addMageKosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addMageKosuuContent,
            null,
            null,
            true
          )
        : ''}
      {/* 曲長 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
      {/* 個数 */}
      {isKosuu
        ? commonModal(
            isKosuu,
            addKosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addKosuuContent,
            null,
            null,
            true
          )
        : ''}

      {/* 本数 */}
      {isHonsuu
        ? commonModal(
            isHonsuu,
            addHonsuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addHonsuuContent,
            null,
            null,
            true
          )
        : ''}
      {/* 面積 */}
      {isArea
        ? commonModal(
            isArea,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAreaContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_SetupSetting;
