import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const autoBProcessArr = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 41,
    sagyoTime: '00:00:42',
    processLength: 1800,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr2 = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 41,
    sagyoTime: '00:00:42',
    processLength: 1800,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr3 = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 41,
    sagyoTime: '00:00:42',
    processLength: 2819.88,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr4 = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 23,
    sagyoTime: '00:00:24',
    processLength: 300.0,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr5 = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 23,
    sagyoTime: '00:00:24',
    processLength: 148.94,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr1_PMX = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 41,
    sagyoTime: '00:00:42',
    processLength: 4492.7915,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr2_PMX = [
  {
    id: 1,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processLength: 99.998,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr3_PMX = [
  {
    id: 2,
    processName: '自動バリ取(No3)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processLength: 42.5,
    processCount: 1,
    materialHandlingAmt: 132,
    materialHandlingTime: '00:01:00',
    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr4_PMX = [
  {
    id: 3,
    processName: '自動バリ取(No1)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processLength: 0.0,
    processCount: 0,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr1_PMX2 = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 41,
    sagyoTime: '00:00:42',
    processLength: 4492.7915,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr2_PMX2 = [
  {
    id: 1,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 23,
    sagyoTime: '00:00:24',
    processLength: 317.676,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr3_PMX2 = [
  {
    id: 2,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 35,
    sagyoTime: '00:00:36',
    processLength: 1414.56,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr4_PMX2 = [
  {
    id: 3,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 23,
    sagyoTime: '00:00:24',
    processLength: 145.873,
    processCount: 0,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr5_PMX2 = [
  {
    id: 4,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processLength: 47.0,
    processCount: 0,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr6_PMX2 = [
  {
    id: 5,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processLength: 47.0,
    processCount: 0,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const autoBProcessArr7_PMX2 = [
  {
    id: 6,
    processName: '自動バリ取(No1)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processLength: 0.0,
    processCount: 0,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:03:00',
  },
];

const IQ3KouteiCalculate_AutoBari = forwardRef((props, ref) => {
  const [isAutoBaridetail, setIAutoBaridetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [materialHandlingAmt, setMaterialHandlingAmt] = useState('');
  const [materialHandlingTime, setMaterialHandlingTime] = useState('');
  const [naibuProcessValidationAmt, setNaibuProcessValidationAmt] = useState('');
  const [naibuProcessValidationTime, setNaibuProcessValidationTime] = useState('');

  const settingEditData = (arr) => {
    let editDataArr = [];
    for (let i = 0; i < arr.length; i++) {
      editDataArr.push({
        id: i,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        sagyoTime: arr[i].sagyoTime,
        processLength: arr[i].processLength,
        processCount: arr[i].processCount,

        materialHandlingAmt: arr[i].materialHandlingAmt,
        materialHandlingTime: arr[i].materialHandlingTime,
        naibuProcessValidationAmt: arr[i].naibuProcessValidationAmt,
        naibuProcessValidationTime: arr[i].naibuProcessValidationTime,
        sumAmt: parseFloat(arr[i].dandoriAmt) + parseFloat(arr[i].sagyoAmt) + parseFloat(arr[i].materialHandlingAmt),
        sumTime: secondsToHms(
          setTimetoSec(arr[i].dandoriTime) + setTimetoSec(arr[i].sagyoTime) + setTimetoSec(arr[i].materialHandlingTime)
        ),
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    editDataArr.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += setTimetoSec(element.sagyoTime);
      dandoriTimeSec += setTimetoSec(element.dandoriTime);
    });

    let totalSum = editDataArr[0].sumAmt + parseFloat(editDataArr[0].naibuProcessValidationAmt);
    let sagyoTimeSum = secondsToHms(sagyoTimeSec);
    let dandoriTimeSum = secondsToHms(dandoriTimeSec);
    let totalTimeSum = secondsToHms(
      setTimetoSec(editDataArr[0].sumTime) + setTimetoSec(editDataArr[0].naibuProcessValidationTime)
    );

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let dataEdit = [];
    let totals = [];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail != undefined) {
      if (Object.keys(props.selectedDataDetail).length > 0) {
        if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
          setProcessArr(autoBProcessArr);
          dataEdit = settingEditData(autoBProcessArr);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
          setProcessArr(autoBProcessArr2);
          dataEdit = settingEditData(autoBProcessArr2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
          setProcessArr(autoBProcessArr3);
          dataEdit = settingEditData(autoBProcessArr3);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
          setProcessArr(autoBProcessArr4);
          dataEdit = settingEditData(autoBProcessArr4);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
          setProcessArr(autoBProcessArr5);
          dataEdit = settingEditData(autoBProcessArr5);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
          setProcessArr(autoBProcessArr1_PMX);
          dataEdit = settingEditData(autoBProcessArr1_PMX);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
          setProcessArr(autoBProcessArr2_PMX);
          dataEdit = settingEditData(autoBProcessArr2_PMX);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
          setProcessArr(autoBProcessArr3_PMX);
          dataEdit = settingEditData(autoBProcessArr3_PMX);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
          setProcessArr(autoBProcessArr4_PMX);
          dataEdit = settingEditData(autoBProcessArr4_PMX);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
          setProcessArr(autoBProcessArr1_PMX2);
          dataEdit = settingEditData(autoBProcessArr1_PMX2);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
          setProcessArr(autoBProcessArr2_PMX2);
          dataEdit = settingEditData(autoBProcessArr2_PMX2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
          setProcessArr(autoBProcessArr3_PMX2);
          dataEdit = settingEditData(autoBProcessArr3_PMX2);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
          setProcessArr(autoBProcessArr4_PMX2);
          dataEdit = settingEditData(autoBProcessArr4_PMX2);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
          setProcessArr(autoBProcessArr5_PMX2);
          dataEdit = settingEditData(autoBProcessArr5_PMX2);
        } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
          setProcessArr(autoBProcessArr6_PMX2);
          dataEdit = settingEditData(autoBProcessArr6_PMX2);
        } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
          setProcessArr(autoBProcessArr7_PMX2);
          dataEdit = settingEditData(autoBProcessArr7_PMX2);
        }
      }

      setEditedData(dataEdit);
      console.log(dataEdit);
      /* totals = calculateTotals(dataEdit); */

      if (dataEdit.length > 0) {
        totals = calculateTotals(dataEdit);
      }
      setTotalAmt(totals[0]?.totalSum);
      setTotalSum(totals[0]?.totalSum);

      setTotalTime(totals[0]?.totalTimeSum);
      setTotalTimeSum(totals[0]?.totalTimeSum);

      setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
      setSagyoAmtSum(totals[0]?.sagyoAmtSum);

      setTotalSagyoTime(totals[0]?.sagyoTimeSum);
      setSagyoTimeSum(totals[0]?.sagyoTimeSum);

      setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
      setDandoriAmtSum(totals[0]?.dandoriAmtSum);

      setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      setDandoriTimeSum(totals[0]?.dandoriTimeSum);
    }
  }, [props.selectedDataDetail, props.editMode]);

  const autoBari = () => {
    if (isAutoBaridetail == true) {
      setIAutoBaridetail(false);
    } else {
      setIAutoBaridetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('autoBProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('autoBProcess' + i).style.display = 'none';
      document.getElementById('arrowDownAutoB' + i).style.display = 'block';
      document.getElementById('arrowUpAutoB' + i).style.display = 'none';
    } else {
      document.getElementById('autoBProcess' + i).style.display = 'block';
      document.getElementById('arrowDownAutoB' + i).style.display = 'none';
      document.getElementById('arrowUpAutoB' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'autoBDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'autoBDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'autoBDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'autoBDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      if (document.getElementById(index + 'autoBWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'autoBWorkAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'autoBWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'autoBWorkAmt', newState[index].sagyoAmt, 'green');
          checkInputWithColor(index + 'autoBWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = parseFloat(newState[index].sagyoAmt) + parseFloat(newState[index].dandoriAmt);
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = secondsToHms(
      setTimetoSec(newState[index].sagyoTime) + setTimetoSec(newState[index].dandoriTime)
    );
    newState[index].sumTime = newSubTimeSum;
    newState.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      newSagyoTimeSec += setTimetoSec(element.sagyoTime);
      newDandoriTimeSec += setTimetoSec(element.dandoriTime);
    });
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = secondsToHms(newSagyoTimeSec);
    let newDandoriTimeSum = secondsToHms(newDandoriTimeSec);
    let newTotalTimeSum = secondsToHms(newSagyoTimeSec + newDandoriTimeSec);

    // checkInputWithColor(index + 'autoSubTotal', newSubSum, 'green');
    // checkInputWithColor(index + 'autoSubTotalTime', newSubTimeSum, 'green');
    // checkInputWithColor('totalSagyoAmtAuto', newSagyoSum, 'green');
    //checkInputWithColor('totalDandoriAmtAuto', newDandoriSum, 'green');
    checkInputWithColor('totalAmtAuto', newSum, 'green');
    // checkInputWithColor('totalSagyoTimeAuto', newSagyoTimeSum, 'green');
    // checkInputWithColor('totalDandoriTimeAuto', newDandoriTimeSum, 'green');
    checkInputWithColor('totalTimeAuto', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={autoBari}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isAutoBaridetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>自動バリ取</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{totalSum == undefined ? '¥0' : JPYs.format(totalSum)}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>自動バリ取_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isAutoBaridetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            自動バリ取
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtAuto">{JPYs.format(totalSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeAuto">{totalTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtAutoIn" className="resetColor">
              {JPYs.format(totalAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeAutoIn" className="resetColor">
              {totalTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtAuto">{JPYs.format(dandoriAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeAuto">{dandoriTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtAutoIn" className="resetColor">
              {JPYs.format(totalDandoriAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeAutoIn" className="resetColor">
              {totalDandoriTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            作業金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtAuto">{JPYs.format(sagyoAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeAuto">{sagyoTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtAutoIn" className="resetColor">
              {JPYs.format(totalSagyoAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeAutoIn" className="resetColor">
              {totalSagyoTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row> */}

        {(() => {
          const arr = [];
          for (let i = 0; i < editedData.length; i++) {
            arr.push(
              <div>
                {/* <Row
                  style={{ color: 'black' }}
                  className="oyabuhin-detail oyabuhin-row"
                  onClick={(e) => subpartDetail(i)}
                >
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex' }}>
                      <CaretUpOutlined
                        id={'arrowUpAutoB' + i}
                        style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      />
                      <CaretDownOutlined id={'arrowDownAutoB' + i} style={{ fontSize: 17, marginTop: 2 }} />
                      <span style={{ marginLeft: '5px' }}>{processArr[i].processName}</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={i + 'autoSubTotal'}>
                      {JPYs.format(
                        (parseFloat(processArr[i].dandoriAmt) + parseFloat(processArr[i].sagyoAmt)).toFixed(0)
                      )}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={i + 'autoSubTotalTime'}>
                      {secondsToHms(setTimetoSec(processArr[i].dandoriTime) + setTimetoSec(processArr[i].sagyoTime))}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'autoSubTotalIn'} className="resetColor">
                      {JPYs.format(editedData[i].sumAmt)}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'autoSubTotalTimeIn'} className="resetColor">
                      {editedData[i].sumTime}
                    </label>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row> */}

                {(() => {
                  const subArr = [];
                  for (let j = i; j <= i; j++) {
                    subArr.push(
                      <div>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span>段取金額/時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'autoBDandoriAmt'}>{JPYs.format(processArr[j].dandoriAmt)}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'autoBDandoriTime'}>{processArr[j].dandoriTime}</label>
                          </Col>
                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'autoBDandoriAmtIn'}
                              value={editedData[j].dandoriAmt}
                              defaultValue={0}
                              onValueChange={(e) => {
                                //checkRegex(e.target.value);
                                if (editedData[j].dandoriAmt != e) {
                                  settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                  checkInputWithColor(j + 'autoBDandoriAmt', e, 'amt');
                                }
                                // checkInput(j + 'autoBDandoriAmt', e);
                              }}
                              /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'autoBDandoriAmt', e, 'amt');
                                  }
                                }} */
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'autoBDandoriAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editedData[j].dandoriTime}
                                  id={j + 'autoBDandoriTimeIn'}
                                  style={{ width: '100%' }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    //disableTimer('test');
                                    settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].dandoriTime,
                                          '10min',
                                          j + 'autoBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].dandoriTime,
                                          '10min',
                                          j + 'autoBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].dandoriTime,
                                          'min',
                                          j + 'autoBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].dandoriTime,
                                          'min',
                                          j + 'autoBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].dandoriTime,
                                          'sec',
                                          j + 'autoBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].dandoriTime,
                                          'sec',
                                          j + 'autoBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={5} className="formula-column"></Col>
                        </Row>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                            <CaretUpOutlined
                              id={'arrowUpAutoB' + i}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <CaretDownOutlined id={'arrowDownAutoB' + i} style={{ fontSize: 17, marginTop: 2 }} />
                            <span style={{ marginLeft: '5px' }}>作業金額/時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                            <label id={j + 'autoBWorkAmt'}>
                              {JPYs.format(processArr[j].sagyoAmt + processArr[j].materialHandlingAmt)}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                            <label id={j + 'autoBWorkTime'}>
                              {secondsToHms(
                                setTimetoSec(processArr[j].sagyoTime) + setTimetoSec(processArr[j].materialHandlingTime)
                              )}
                            </label>
                          </Col>
                          <Col span={4} className="input-col" onClick={(e) => subpartDetail(i)}>
                            <CurrencyInput
                              id={j + 'autoBWorkAmtIn'}
                              value={editedData[j].sagyoAmt + editedData[j].materialHandlingAmt}
                              defaultValue={0}
                              onValueChange={(e) => {
                                if (editedData[j].sagyoAmt != e) {
                                  settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                  checkInputWithColor(j + 'autoBWorkAmt', e, 'amt');
                                }
                              }}
                              /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'autoBWorkAmt', e, 'amt');
                                  }
                                }} */
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-non-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'autoBWorkAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col" onClick={(e) => subpartDetail(i)}>
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value /* {editedData[j].sagyoTime} */={secondsToHms(
                                    setTimetoSec(editedData[j].sagyoTime) +
                                      setTimetoSec(editedData[j].materialHandlingTime)
                                  )}
                                  id={j + 'autoBWorkTimeIn'}
                                  style={{ width: '100%' }}
                                  className={
                                    editMode ? 'input-non-editable resetColor' : 'input-non-editable resetColor'
                                  }
                                  onChange={(e) => {
                                    //disableTimer('test');
                                    settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              {/* <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].sagyoTime,
                                          '10min',
                                          j + 'autoBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].sagyoTime,
                                          '10min',
                                          j + 'autoBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].sagyoTime,
                                          'min',
                                          j + 'autoBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].sagyoTime,
                                          'min',
                                          j + 'autoBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].sagyoTime,
                                          'sec',
                                          j + 'autoBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].sagyoTime,
                                          'sec',
                                          j + 'autoBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col> */}
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                          <Col span={5} className="formula-column" onClick={(e) => subpartDetail(i)}></Col>
                        </Row>
                        <div id={'autoBProcess' + i} style={{ display: 'block' }}>
                          <Row
                            style={{ color: 'black', display: processArr[j].materialHandlingAmt == 0 ? 'none' : '' }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                              <span className="ddl_2_noImg">作業金額/時間（面積）</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                              <label id={j + 'autoBWorkAmt'}>{JPYs.format(processArr[j].sagyoAmt)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                              <label id={j + 'autoBWorkTime'}>{processArr[j].sagyoTime}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'autoBWorkAmtIn'}
                                value={editedData[j].sagyoAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].sagyoAmt != e) {
                                    settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                    checkInputWithColor(j + 'autoBWorkAmt', e, 'amt');
                                  }
                                }}
                                /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'autoBWorkAmt', e, 'amt');
                                  }
                                }} */
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'autoBWorkAmtCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].sagyoTime}
                                    id={j + 'autoBWorkTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTime,
                                            '10min',
                                            j + 'autoBWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTime,
                                            '10min',
                                            j + 'autoBWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTime,
                                            'min',
                                            j + 'autoBWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTime,
                                            'min',
                                            j + 'autoBWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTime,
                                            'sec',
                                            j + 'autoBWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTime,
                                            'sec',
                                            j + 'autoBWorkTime'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                            <Col span={5} className="formula-column" onClick={(e) => subpartDetail(i)}></Col>
                          </Row>
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">面積/回数</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proLength">{processArr[j].processLength}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proCount">{processArr[j].processCount}</label>
                            </Col>

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].processLength}
                                id="proLengthIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].processCount}
                                id="proCountIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          {/*  マテハン金額/時間 */}
                          <Row
                            style={{
                              color: 'black',
                              display: processArr[j].materialHandlingAmt == 0 ? 'none' : '',
                            }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">マテハン金額/時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'materialHandlingAmt'}>
                                {JPYs.format(processArr[j].materialHandlingAmt)}
                              </label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'materialHandlingTime'}>{processArr[j].materialHandlingTime}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'materialHandlingAmtIn'}
                                value={editedData[j].materialHandlingAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  //checkRegex(e.target.value);
                                  // if (editedData[j].dandoriAmt != e) {
                                  //   settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                  //   checkInputWithColor(j + 'autoBDandoriAmt', e, 'amt');
                                  // }
                                  // checkInput(j + 'autoBDandoriAmt', e);
                                }}
                                /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'autoBDandoriAmt', e, 'amt');
                                  }
                                }} */
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor'
                                    : 'input-non-editable currency resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'materialHandlingAmtCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].materialHandlingTime}
                                    id={j + 'materialHandlingTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      // settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   '10min',
                                          //   j + 'autoBDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   '10min',
                                          //   j + 'autoBDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'min',
                                          //   j + 'autoBDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'min',
                                          //   j + 'autoBDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'sec',
                                          //   j + 'autoBDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].dandoriTime,
                                          //   'sec',
                                          //   j + 'autoBDandoriTime'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                        </div>
                        {/*  工程内検査金額/時間 */}
                        <Row
                          style={{
                            color: 'black',
                            display: processArr[j].naibuProcessValidationAmt == 0 ? 'none' : '',
                          }}
                          className="oyabuhin-detail oyabuhin-row"
                        >
                          <Col span={4} className="oyabuhin-detail-col">
                            <span>工程内検査金額/時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'naibuProcessValidationAmt'}>
                              {JPYs.format(processArr[j].naibuProcessValidationAmt)}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'naibuProcessValidationTime'}>
                              {processArr[j].naibuProcessValidationTime}
                            </label>
                          </Col>
                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'naibuProcessValidationAmtIn'}
                              value={editedData[j].naibuProcessValidationAmt}
                              defaultValue={0}
                              onValueChange={(e) => {
                                //checkRegex(e.target.value);
                                // if (editedData[j].dandoriAmt != e) {
                                //   settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                //   checkInputWithColor(j + 'autoBDandoriAmt', e, 'amt');
                                // }
                                // checkInput(j + 'autoBDandoriAmt', e);
                              }}
                              /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'autoBDandoriAmt', e, 'amt');
                                  }
                                }} */
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'autoBDandoriAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editedData[j].naibuProcessValidationTime}
                                  id={j + 'naibuProcessValidationTimeIn'}
                                  style={{ width: '100%' }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    //disableTimer('test');
                                    // settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        // let editedTime = handelIncreaseTime(
                                        //   editedData[j].dandoriTime,
                                        //   '10min',
                                        //   j + 'autoBDandoriTime'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelDecreaseTime(
                                        //   editedData[j].dandoriTime,
                                        //   '10min',
                                        //   j + 'autoBDandoriTime'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelIncreaseTime(
                                        //   editedData[j].dandoriTime,
                                        //   'min',
                                        //   j + 'autoBDandoriTime'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelDecreaseTime(
                                        //   editedData[j].dandoriTime,
                                        //   'min',
                                        //   j + 'autoBDandoriTime'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelIncreaseTime(
                                        //   editedData[j].dandoriTime,
                                        //   'sec',
                                        //   j + 'autoBDandoriTime'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelDecreaseTime(
                                        //   editedData[j].dandoriTime,
                                        //   'sec',
                                        //   j + 'autoBDandoriTime'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={5} className="formula-column"></Col>
                        </Row>
                      </div>
                    );
                  }
                  return subArr;
                })()}
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_AutoBari;
