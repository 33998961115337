import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Button, Modal } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import ParentKouteiInput_ChildPartInfoSetting from './ParentKouteiInput_ChildPartInfoSetting';

let kensaData = [];
let iQ3Data = [];
const iQ5Data = [];
const iQ7Data = [];
const purchaseCnt = 10;
iQ3Data.push({
  key: 1,
  parentName: `iQ3板金`,
  childType: 'iQ3',
  imgNm: 'Product-20230320 105141',
  childName: `Test-1`,
  itemNum: 2,
  quality: 'SPCC',
  thick: 1.6,
  area: 10,
  weight: 3,
});
iQ5Data.push({
  key: 1,
  parentName: `iQ5形鋼`,
  childType: 'iQ5',
  imgNm: 'Product-20230320 105142',
  childName: `Test-2`,
  itemNum: 2,
  quality: 'SPCC',
  thick: 1.6,
  area: 10,
  weight: 3,
});
iQ7Data.push({
  key: 1,
  parentName: `iQ7機械`,
  childType: 'iQ7',
  imgNm: 'Product-20230320 105143',
  childName: `Test-3`,
  itemNum: 2,
  quality: 'SPCC',
  thick: 1.6,
  area: 10,
  weight: 3,
});
const childParts = [
  {
    key: 1,
    childKey: 1,
    parentName: `iQ3板金`,
    childType: 'iQ3',
    imgNm: 'Product-20230320 105141',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 2,
    childKey: 1,
    parentName: `iQ5形鋼`,
    childType: 'iQ5',
    imgNm: 'Product-20230320 105142',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 3,
    childKey: 1,
    parentName: `iQ7機械`,
    childType: 'iQ7',
    imgNm: 'Product-20230320 105143',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
];

kensaData.push({
  key: 1,
  kensaType: '子部品点数',
  childPartNm: ['板金子部品', '形鋼子部品', '機械加工子部品'],
  childParts: childParts,
  iq3: iQ3Data.length,
  iq5: iQ5Data.length,
  iq7: iQ7Data.length,
  purchaseCnt: purchaseCnt,
  sum: 30,
  totalCnt: parseInt(iQ3Data.length) + parseInt(iQ5Data.length) + parseInt(iQ7Data.length) + parseInt(purchaseCnt),
  selectedChildPartCnt: parseInt(iQ3Data.length) + parseInt(iQ5Data.length) + parseInt(iQ7Data.length),
});

const kensaTypes = [
  { id: 1, value: '子部品点数', label: '子部品点数' },
  { id: 2, value: '重量', label: '重量' },
];

const ParentKouteiInput_Konpou = forwardRef((props, ref) => {
  const [konpouDataLst, setKonpouDataLst] = useState([]);
  const [curKensaData, setCurKensaData] = useState([]);
  const [curDataIndex, setCurDataIndex] = useState(0);
  // const [editMode, setEditMode] = useState(false);
  // add new
  const [addModal, setAddModal] = useState(false);

  const [totalChildParts, setTotalChildParts] = useState(0);
  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    setKonpouDataLst(props.kensaData);
    setTotalChildParts(60);
  }, [props.kensaData]);

  // const setEditModeChange = (mode) => {
  //   setEditMode(mode);
  // };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addKonpou = () => {
    if (konpouDataLst.length > 0) {
      //
      const data = {
        key: konpouDataLst.slice(-1)[0].key + 1,
        kensaType: '子部品点数',
        childPartNm: ['板金子部品', '形鋼子部品', '機械加工子部品'],
        childParts: [],
        iq3: 0,
        iq5: 0,
        iq7: 0,
        purchaseCnt: purchaseCnt,
        totalCnt: 10,
        sum: 0,
        selectedChildPartCnt: 0,
        types: 0,
        weightVal: 0,
        bankin1: 0,
        bankin2: konpouDataLst[0].bankin2,
        katakou1: 0,
        katakou2: konpouDataLst[0].katakou2,
      };
      let addArr = [...konpouDataLst, data];
      setKonpouDataLst(addArr);
    }
  };
  const deleteKonpou = (e, index) => {
    if (konpouDataLst.length > 1) {
      setKonpouDataLst(
        konpouDataLst.filter((j) => {
          return ![e].includes(j.key);
        })
      );
    }
  };
  const onKonpouValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...konpouDataLst];
    data[i][name] = value;
    setKonpouDataLst(data);
  };
  const onKumitateValSelectUpdate = (i, event, name) => {
    const data = [...konpouDataLst];
    data[i][name] = event;
    setKonpouDataLst(data);
  };
  const showModal = (index, item) => {
    setCurKensaData(item);
    setCurDataIndex(index);
    setAddModal(true);
  };
  const updateChildPart = (selectedChildParts) => {
    const iQ3Data = selectedChildParts.filter((j) => {
      return j.childType == 'iQ3';
    });
    const iQ5Data = selectedChildParts.filter((j) => {
      return j.childType == 'iQ5';
    });
    const iQ7Data = selectedChildParts.filter((j) => {
      return j.childType == 'iQ7';
    });
    let sum = selectedChildParts.reduce(function (prev, current) {
      return prev + +current.area;
    }, 0);

    const updatedChildPart = {
      key: curKensaData.key,
      kensaType: curKensaData.kensaType,
      childPartNm: curKensaData.childPartNm,
      childParts: selectedChildParts,
      sum: sum,
      purchaseCnt: curKensaData.purchaseCnt,
      totalCnt: parseInt(iQ3Data.length) + parseInt(iQ5Data.length) + parseInt(iQ7Data.length) + parseInt(purchaseCnt),
      selectedChildPartCnt: parseInt(iQ3Data.length) + parseInt(iQ5Data.length) + parseInt(iQ7Data.length),
    };
    const data = [...konpouDataLst];
    data[curDataIndex] = updatedChildPart;
    setKonpouDataLst(data);
    setAddModal(false);
  };
  const cancelChildPart = () => {
    setAddModal(false);
  };

  const konpouComponent = (
    <>
      {konpouDataLst.map((item, index) => {
        const {
          kensaType,
          childPartNm,
          bankin1,
          bankin2,
          katakou1,
          katakou2,
          iq3,
          iq5,
          iq7,
          purchaseCnt,
          totalCnt,
          sum,
          weightVal,
          types,
          selectedChildPartCnt,
        } = item;
        return (
          <>
            <tr>
              <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {item.key}
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                {editMode ? (
                  <>
                    <Select
                      name="kensaType"
                      className="yousetsu-select"
                      style={{ pointerEvents: editMode ? 'auto' : 'none', fontSize: '13px' }}
                      onChange={(event) => onKumitateValSelectUpdate(index, event, 'kensaType')}
                      value={kensaType}
                    >
                      {kensaTypes.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>{kensaType}</>
                )}
              </td>
              <td style={{ width: '17%' }}>
                <Row align="middle">
                  <Col span={14}>
                    <label>{childPartNm[0]}</label>
                  </Col>
                  <Col span={5}>
                    <Input
                      name="bankin1"
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      value={bankin1}
                      onChange={(event) => onKonpouValUpdate(index, event)}
                    ></Input>
                  </Col>
                  <Col span={5}>
                    <Input className="input-non-editable " value={bankin2}></Input>
                  </Col>
                </Row>
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle', width: '11%', textAlign: 'center' }}>
                子部品種類
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle', width: '6%' }}>
                <Input value={types} style={{ textAlign: 'center' }} className="input-non-editable"></Input>
              </td>

              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                <Input
                  value={sum}
                  // value={5}
                  style={{ textAlign: 'center' }}
                  className="input-non-editable"
                ></Input>
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                <Row justify="center">
                  {selectedChildPartCnt}/{totalCnt}
                </Row>
                {/* <Row justify="center">{selectedChildPartCnt == totalCnt ? 'ALL' : ''}</Row> */}
                {/* <Row justify="center">
                  <Button
                    key="submit"
                    className="mainButton"
                    onClick={props.commonTaiochuPopupOpen}
                    // onClick={(e) => showModal(index, item)}
                    // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                  >
                    {editMode ? '選択' : '詳細'}
                  </Button>
                </Row> */}
              </td>
              {editMode ? (
                <>
                  <td
                    style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                    rowSpan={2}
                  >
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onClick={addKonpou}
                      />
                      <CloseCircleFilled className="add-remove-icon" onClick={(e) => deleteKonpou(item.key, e)} />
                    </Row>
                  </td>
                </>
              ) : (
                <>
                  <td
                    style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                    rowSpan={2}
                  ></td>
                </>
              )}
            </tr>
            <tr>
              <td>
                <Row align="middle">
                  <Col span={14}>
                    <label>{childPartNm[1]}</label>
                  </Col>
                  <Col span={5} style={{ textAlign: 'right' }}>
                    <Input
                      name="katakou1"
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      value={katakou1}
                      onChange={(event) => onKonpouValUpdate(index, event)}
                    ></Input>
                  </Col>
                  <Col span={5} style={{ textAlign: 'right' }}>
                    <Input className="input-non-editable " value={katakou2}></Input>
                  </Col>
                </Row>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
  return (
    <>
      {/* 組立 */}
      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '81%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                計算方法
              </th>
              <th colSpan={3} style={{ width: '43%' }} className="tbl-header">
                詳細
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                合計
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                構成子部品
              </th>
            </tr>
          </thead>
          <tbody>{konpouComponent}</tbody>
        </Table>
      </Row>
      {/* 詳細 */}
      {addModal ? (
        <ParentKouteiInput_ChildPartInfoSetting
          editMode={editMode}
          onUpdate={updateChildPart}
          onCancel={cancelChildPart}
          curData={curKensaData}
          shoriNm={'梱包' + curKensaData.key}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default ParentKouteiInput_Konpou;
