/**
 * クラス名：共通API
 * 説明：システム全体共通使うAPIへの要求関数を記載するファイルである。
 * 作成者：ナンス
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import { StatusCodes } from 'http-status-codes';

import { error, authorizeError } from './CommonModal';
import { ErrorMessage } from './Message';
import { updateAccessToken, getAccessToken } from './Common';

export const loginPath = process.env.REACT_APP_FRONTEND_URL;

//　ログアウト処理
export const logOutSubmit = async () => {
  let result = true;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Login/Logout';
    const token = localStorage.getItem('iQxWeb_AccessToken');
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then((response) => {
        if (response.status === StatusCodes.OK) {
          return response;
        } else if (response.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        }
      })
      .then((data) => {
        if (data && data.status === StatusCodes.OK) {
          // localStorage.clear(); // セクション情報をクリック
          localStorage.removeItem('iQxWeb_LoginUser');
          localStorage.removeItem('iQxWeb_AccessToken');
          // window.location.replace(loginPath); // ログインへ遷移
        } else if (data && data.status !== StatusCodes.OK) {
          error(data);
        }
      })
      .catch((e) => {
        error(ErrorMessage().E010);
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return result;
};

// ログインユーザーの情報を取得する
export const getCurrentUserInfo = async (token) => {
  let ret = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Login/CurrentUserInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response.status === StatusCodes.OK) {
          return await response.json();
        } else if (response.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        }
      })
      .then((data) => {
        if (data) {
          // セクションにログインユーザー情報を保持する
          // Access value associated with the key
          localStorage.setItem('iQxWeb_LoginUser', encodeURI(JSON.stringify(data)));
        } else if (data && data.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage().E004;
        }
      })
      .catch((error) => {
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    ret = error.message;
  }
  return ret;
};

// お客様情報の取得
export const getClientInfo = async (token) => {
  let clientsData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client/GetClientInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response.status === StatusCodes.OK) {
          return await response.json();
        } else if (response.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        }
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          clientsData = data.data;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return clientsData;
        }
      })
      .catch((e) => {
        error(ErrorMessage().E010);
        return clientsData;
      });
  } catch (e) {
    error(e.message);
    return clientsData;
  }
  return clientsData;
};

// 担当者情報をDBから取得する
export const getStaffInfo = async (token) => {
  let personInChargeData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/GetStaffInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response.status === StatusCodes.OK) {
          return await response.json();
        } else if (response.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        }
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          personInChargeData = data.data;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        error(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return personInChargeData;
};

// 案件状態情報をDBから取得する
export const getEstimateStatusData = async (token) => {
  let projectStatusData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus/GetEstimateStatusInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response.status === StatusCodes.OK) {
          return await response.json();
        } else if (response.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        }
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          projectStatusData = data.data;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        error(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return projectStatusData;
};

// 購入品情報をDBから取得する
export const getPurchaseInfo = async () => {
  let purchaseData = [];

  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/GetPurchaseInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })

      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          purchaseData = data.data;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseData;
};

// 購入品種別情報をDBから取得する
export const getPurchaseCategoryInfo = async (token) => {
  let purchaseCategoryData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory/GetPurchaseCategoryInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response.status === StatusCodes.OK) {
          return await response.json();
        } else if (response.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        }
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          purchaseCategoryData = data.data;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        error(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseCategoryData;
};
