import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Input, Radio, Checkbox, Select, Table as AntdTBL, Modal, Button, Space, Form } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import { Table } from 'react-bootstrap';
import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
} from '../common/CommonModal';
import { getPurchaseInfo } from '../common/CommonAPI';

const purchaseLstArr = [];
purchaseLstArr.push(
  {
    key: 0,
    purchaseNm: '六角ウエルドナット',
    typeNo: 'WN/SUS-M10',
    unitPrice: 1500,
    suryoubetsuUnitPrice: '',
    requiredNum: 10,
    purchaseAmount: 1500,
    totalRequiredNum: 10, // 必要数 x 数量別数
    totalPurchaseAmount: 1500,
    suryoubetsuTotalPrice: '',
    supplier: '',
    maker: '',
    purchaseRemark: 'SUS製／購入数量：6個',
  },
  {
    key: 1,
    purchaseNm: 'オネジスタッド',
    typeNo: 'OST-M10x20',
    unitPrice: 640,
    suryoubetsuUnitPrice: '',
    requiredNum: 10,
    purchaseAmount: 640,
    totalRequiredNum: 10, // 必要数 x 数量別数
    totalPurchaseAmount: 640,
    suryoubetsuTotalPrice: '',
    supplier: '',
    maker: '',
    purchaseRemark: '購入数量：20個',
  }
);

const childPurchaseLstArr = [];
childPurchaseLstArr.push(
  {
    key: 0,
    purchaseNm: 'メネジスタッド',
    typeNo: 'MST-TP_6×_8.0-M3',
    unitPrice: 28,
    suryoubetsuUnitPrice: '',
    requiredNum: 5,
    purchaseAmount: 140,
    totalRequiredNum: 5, // 必要数 x 数量別数
    totalPurchaseAmount: 140,
    suryoubetsuTotalPrice: '',
    supplier: '',
    maker: '',
    purchaseRemark: '購入数量：1000個',
  },
  {
    key: 1,
    purchaseNm: 'メネジスタッド',
    typeNo: 'MST-TP10×10.0-M4',
    unitPrice: 60,
    suryoubetsuUnitPrice: '',
    requiredNum: 10,
    purchaseAmount: 600,
    totalRequiredNum: 10, // 必要数 x 数量別数
    totalPurchaseAmount: 600,
    suryoubetsuTotalPrice: '',
    supplier: '',
    maker: '',
    purchaseRemark: '購入数量：500個',
  }
);

// const masterPurchaseLstArr = [];
// for (let i = 0; i < 50; i++) {
//   masterPurchaseLstArr.push(
//     {
//       key: i,
//       purchaseNm: 'オネジスタッド',
//       typeNo: 'OST-M10x20',
//       unitPrice: 65,
//       suryoubetsuUnitPrice: '',
//       requiredNum: 35,
//       purchaseAmount: 2275,
//       totalRequiredNum: 35, // 必要数 x 数量別数
//       totalPurchaseAmount: 525,
//       suryoubetsuTotalPrice: '',
//       supplier: '',
//       maker: '',
//       purchaseRemark: '購入数量：300個',
//     }

//     // {
//     //   key: 2,
//     //   purchaseNm: 'NUT 溶接ナット M5',
//     //   typeNo: 'WN-M5',
//     //   unitPrice: 100,
//     //   suryoubetsuUnitPrice: 80,
//     //   requiredNum: 10,
//     //   purchaseAmount: 800,
//     //   totalRequiredNum: 50, // 必要数 x 数量別数
//     //   totalPurchaseAmount: 5000,
//     //   suryoubetsuTotalPrice: 4000,
//     //   supplier: '',
//     //   maker: '',
//     //   purchaseRemark: '',
//     // }
//   );
// }

const suryou = 1;

const Purchase = forwardRef((props, ref) => {
  const [isEditPurchase, setIsEditPurchase] = useState(false);
  const [isMasterAdd, setIsMasterAdd] = useState(false);
  // const [openPurchaseDetail, setOpenPurchaseDetail] = useState(true);
  const [openChildPurchaseDetail, setOpenChildPurchaseDetail] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [childSelectedRowKeys, setChildSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = React.useState(0);
  const [modalTitle, setModalTitle] = useState([false]);
  const [purchaseKey, setPurchaseKey] = useState(1);
  const [purchaseNm, setPurchaseNm] = useState('');
  const [typeNo, setTypeNo] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [requiredNum, setRequiredNum] = useState('');
  const [supplier, setSupplier] = useState('');
  const [maker, setMaker] = useState('');
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [purchaseRemark, setPurchaseRemark] = useState('');
  const [unitPriceTotal, setUnitPriceTotal] = useState('');
  const [purchaseLst, setPurchaseLst] = useState(purchaseLstArr);
  const [suryoubetsuPrice, setSuryoubetsuPrice] = useState(0);
  const [suryoubetsuTotalPrice, setSuryoubetsuTotalPrice] = useState(0);
  // add new
  const [addModal, setAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [parentPurchaseInfo, setParentPurchaseInfo] = useState([]);

  const [masterPurchaseLst, setMasterPurchaseLst] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail.length == 0) {
      setPurchaseLst([]);
    } else {
      var purchaseTotal = 0;
      purchaseLst.map((pur) => {
        var purchaseAmountCal = pur.purchaseAmount == '' ? 0 : pur.purchaseAmount * suryou;
        purchaseTotal += purchaseAmountCal;
      });
      // setUnitPriceTotal(purchaseTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 }));
      setUnitPriceTotal(2800);
      setPurchaseAmount(2800);
      setSelectedRowKeys(['0']);
      setSelectedData(purchaseLst[0]);
    }
  }, [props.selectedDataDetail, props.editMode, purchaseLst]);

  // 購入品マスタ情報を取得する
  const getPurchaseData = async () => {
    let masterPurchaseLstArr = await getPurchaseInfo();
    setMasterPurchaseLst(masterPurchaseLstArr);
    masterPurchaseLstArr.length > 0 ? setChildSelectedRowKeys([masterPurchaseLstArr[0].id]) : [];
  };

  const openChildPurchase = () => {
    if (openChildPurchaseDetail == true) {
      setOpenChildPurchaseDetail(false);
    } else {
      setOpenChildPurchaseDetail(true);
    }
  };
  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  const onRowSelect = (record) => {
    setSelectedRowKeys(record.key);
    setSelectedData(record);
  };
  const onChildRowSelect = (record) => {
    setChildSelectedRowKeys(record.id);
    setSelectedData(record);
  };
  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
    setChkData(checkedRow.length);
    // setCSVExportData(record);
    setSelectedData(record.slice(-1));
  };

  const rowSelection = {
    onChange: onRowCheckboxSelect,
  };
  const onRdoChange = (e) => {
    console.log(e.target.value);
    setVersion(e.target.value);
  };

  const addNew = () => {
    setPurchaseNm('');
    setTypeNo('');
    setUnitPrice('');
    setRequiredNum('');
    setSupplier('');
    setMaker('');
    setPurchaseRemark('');

    setIsMasterAdd(false);
    setIsEditPurchase(false);
    setModalTitle('追加');
    setAddModal(true);
  };
  const editPurchaseInfo = () => {
    if (selectedData != null) {
      setPurchaseKey(selectedData.key);
      setPurchaseNm(selectedData.purchaseNm);
      setTypeNo(selectedData.typeNo);
      setUnitPrice(selectedData.unitPrice);
      setRequiredNum(selectedData.requiredNum);
      setSupplier(selectedData.supplier);
      setMaker(selectedData.maker);
      setPurchaseRemark(selectedData.purchaseRemark);
      setPurchaseAmount(selectedData.purchaseAmount);
    }
    setIsMasterAdd(false);
    setIsEditPurchase(true);
    setModalTitle('編集');
    setAddModal(true);
  };
  const addFromMaster = () => {
    getPurchaseData();
    setIsMasterAdd(true);
    setModalTitle('購入品マスターから追加');
    setAddModal(false);
  };
  const addOK = () => {
    if (isMasterAdd) {
      // master add
    } else {
      const addNewPurchase = {
        key: purchaseLst.length + 1,
        purchaseNm: purchaseNm,
        typeNo: typeNo,
        unitPrice: unitPrice,
        requiredNum: requiredNum,
        supplier: supplier,
        maker: maker,
        purchaseAmount: 0,
        purchaseRemark: purchaseRemark,
      };
      let addPurArr = [...purchaseLst, addNewPurchase];
      setPurchaseLst(addPurArr);
    }
    setAddModal(false);
    setIsMasterAdd(false);
  };
  const addCancel = () => {
    setAddModal(false);
    setIsMasterAdd(false);
  };
  const updatePurchase = () => {
    let editArr = [...purchaseLst];
    editArr = editArr.map((item) => (item.key === purchaseKey ? { ...item, purchaseNm, typeNo } : item));
    setPurchaseLst(editArr);
    setAddModal(false);
    setIsMasterAdd(false);
  };

  const deletePurchase = () => {
    let dataRow = [...purchaseLst];
    console.log('selectedData.key ---', selectedData.key);
    dataRow.splice(selectedData.key, 1);
    console.log('purchase remove ---', dataRow);
    setPurchaseLst(dataRow);
    // setAddModal(false);
    // setIsMasterAdd(false);
  };
  const clearPurchase = () => {
    setPurchaseLst(purchaseLstArr);
    setEditMode(false);
  };
  const addNewModalTitle = (
    <div
      style={{
        width: isMasterAdd ? '104.8%' : '109.8%',
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitle}</p>
    </div>
  );

  useImperativeHandle(
    ref,
    () => ({
      setEditModeChange: setEditModeChange,
      clearPurchase: clearPurchase,
      getParentPurchaseInfo: () => {
        return parentPurchaseInfo;
      },
    }),
    [parentPurchaseInfo]
  );
  const purchaseColumns = [
    {
      id: '1',
      title: '品名',
      dataIndex: 'purchaseNm',
      width: 80 + '%',
    },
    {
      id: '2',
      title: '型番',
      dataIndex: 'typeNo',
      width: 60 + '%',
    },
    {
      id: '3',
      title: '単価',
      dataIndex: 'unitPrice',
      width: 40 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? (
          <label style={{ fontSize: '13px', color: record.totalRequiredNum >= 50 ? '#A5A5A5' : '#4A4747' }}>
            {record.unitPrice == undefined || record.unitPrice == '' ? '' : '¥' + record.unitPrice}
          </label>
        ) : null,
    },
    {
      id: '4',
      title: '数量別単価',
      dataIndex: 'suryoubetsuUnitPrice',
      width: 60 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? (
          <label style={{ fontSize: '13px' }}>
            {record.suryoubetsuUnitPrice == undefined || record.suryoubetsuUnitPrice == ''
              ? ''
              : '¥' + record.suryoubetsuUnitPrice}
          </label>
        ) : null,
    },
    {
      id: '5',
      title: '必要数',
      dataIndex: 'requiredNum',
      width: 40 + '%',
    },
    {
      id: '6',
      title: '金額',
      dataIndex: 'purchaseAmount',
      width: 60 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? (
          <label style={{ fontSize: '13px' }}>
            {record.purchaseAmount == undefined || record.purchaseAmount == '' ? '' : '¥' + record.purchaseAmount}
          </label>
        ) : null,
    },
    {
      id: '7',
      title: '合計必要数',
      dataIndex: 'totalRequiredNum',
      width: 60 + '%',
    },
    {
      id: '8',
      title: '合計金額',
      dataIndex: 'totalPurchaseAmount',
      width: 60 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? (
          <label style={{ fontSize: '13px', color: record.totalRequiredNum >= 50 ? '#A5A5A5' : '#4A4747' }}>
            {record.totalPurchaseAmount == undefined || record.totalPurchaseAmount == ''
              ? ''
              : '¥' + record.totalPurchaseAmount}
          </label>
        ) : null,
    },
    {
      id: '9',
      title: '数量別金額',
      dataIndex: 'suryoubetsuTotalPrice',
      width: 60 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? (
          <label style={{ fontSize: '13px' }}>
            {record.suryoubetsuTotalPrice == undefined || record.suryoubetsuTotalPrice == ''
              ? ''
              : '¥' + record.suryoubetsuTotalPrice}
          </label>
        ) : null,
    },
    {
      id: '10',
      title: '仕入先',
      dataIndex: 'supplier',
      width: 60 + '%',
    },
    {
      id: '11',
      title: 'メーカー',
      dataIndex: 'maker',
      width: 60 + '%',
    },

    {
      id: '12',
      title: '備考',
      dataIndex: 'purchaseRemark',
      width: 85 + '%',
    },
  ];
  const childPurchaseColumns = [
    {
      id: '1',
      title: '品名',
      dataIndex: 'purchaseNm',
      // sorter: (a, b) => a.id - b.id,
      width: 60 + '%',
    },
    {
      id: '2',
      title: '型番',
      dataIndex: 'typeNo',
      width: 65 + '%',
    },
    {
      id: '3',
      title: '単価',
      dataIndex: 'unitPrice',
      width: 60 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? <label style={{ fontSize: '13px' }}>{'¥' + record.unitPrice}</label> : null,
    },
    {
      id: '4',
      title: '必要数',
      dataIndex: 'requiredNum',
      width: 60 + '%',
    },
    {
      id: '5',
      title: '金額',
      dataIndex: 'purchaseAmount',
      width: 60 + '%',
      render: (_, record) =>
        purchaseLst.length >= 1 ? <label style={{ fontSize: '13px' }}>{'¥' + record.purchaseAmount}</label> : null,
    },
    {
      id: '6',
      title: '合計必要数',
      dataIndex: 'totalRequiredNum',
      width: 60 + '%',
    },
    {
      id: '7',
      title: '合計金額',
      dataIndex: 'totalPurchaseAmount',
      width: 60 + '%',
    },
    {
      id: '8',
      title: '仕入先',
      dataIndex: 'supplier',
      width: 60 + '%',
    },
    {
      id: '9',
      title: 'メーカー',
      dataIndex: 'maker',
      width: 60 + '%',
    },
    {
      id: '10',
      title: '備考',
      dataIndex: 'purchaseRemark',
      width: 65 + '%',
    },
  ];
  const masterPurchaseColumns = [
    {
      id: '1',
      title: '品名',
      dataIndex: 'name',
      // sorter: (a, b) => a.id - b.id,
    },
    {
      id: '2',
      title: '型番',
      dataIndex: 'info',
      render: (info) => info.model,
    },
    {
      id: '3',
      title: '単価',
      dataIndex: 'price',
      className: 'cm-a-right',
      render: (_, record) =>
        purchaseLst.length >= 1 ? <label style={{ fontSize: '13px' }}>{'¥' + record.price}</label> : null,
    },
    {
      id: '4',
      title: '必要数',
      dataIndex: 'requiredNum',
    },

    {
      id: '5',
      title: '仕入先',
      dataIndex: 'info',
      render: (info) => info.supplierName,
    },
    {
      id: '6',
      title: 'メーカー',
      dataIndex: 'info',
      render: (info) => info.makerName,
    },
    {
      id: '7',
      title: '金額',
      dataIndex: 'purchaseAmount',
      className: 'cm-a-right',
      render: (_, record) =>
        purchaseLst.length >= 1 && record?.purchaseAmount !== undefined ? (
          <label style={{ fontSize: '13px' }}>{'¥' + record?.purchaseAmount}</label>
        ) : null,
    },
    {
      id: '8',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
    },
    {
      id: '9',
      title: 'No',
      dataIndex: 'no',
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend',
      className: 'cm-a-right',
      hidden: true,
    },
  ];

  const addMasterContent = (
    <>
      {' '}
      <Form name="AddForm" autoComplete="off">
        <div id="addNewCustomer" style={{ marginTop: 15, display: 'flex' }}>
          <Table className="table table-bordered">
            <thead>
              <tr className="detail-tbl-row">
                <td className="detail-tbl-val-title">品名</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="purchaseNm"
                    type="text"
                    id="purchaseNm"
                    value={purchaseNm}
                    onChange={(e) => setPurchaseNm(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>

              <tr>
                <td className="detail-tbl-val-title">型番</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="typeNo"
                    type="text"
                    id="typeNo"
                    value={typeNo}
                    onChange={(e) => setTypeNo(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">メーカー</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="maker"
                    type="text"
                    id="maker"
                    value={maker}
                    onChange={(e) => setMaker(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">仕入先</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="supplier"
                    type="text"
                    id="supplier"
                    value={supplier}
                    onChange={(e) => setSupplier(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
            </thead>
          </Table>
          <div style={{ marginLeft: '10px', marginTop: '79px', width: '8%' }}>
            <Button key="submit" onClick={addOK} className="mainButton">
              絞込
            </Button>
            <Button key="submit" onClick={addOK} className="cancelButton" style={{ marginTop: '5px' }}>
              クリア
            </Button>
          </div>
        </div>
        <div style={{ marginTop: '25px' }}>
          <Row>
            <AntdTBL
              className="tbPurchaseListShow"
              columns={masterPurchaseColumns}
              dataSource={masterPurchaseLst}
              rowKey={(record) => record.id}
              rowClassName={(record) => (record.id == childSelectedRowKeys ? 'active-row' : 'data-row')}
              scroll={{ y: 500, x: '10vw' }}
              pagination={false}
              rowSelection={rowSelection}
              // onRow={(record, index) => {
              //   return {
              //     onClick: (event) => {
              //       onChildRowSelect(record);
              //     },
              //   };
              // }}
            />
          </Row>
        </div>
      </Form>
    </>
  );

  return (
    <>
      <div className="overflow-auto" style={{ height: '80%' }}>
        <Row style={{ color: 'black' }}>
          <Col span={12}>
            <label style={{ marginLeft: 15, color: suryoubetsuPrice > 0 ? '#A5A5A5' : '#4A4747' }}>
              購入品　金額：{'¥' + purchaseAmount}　合計金額：{'¥' + unitPriceTotal} （製品数量　{suryou}）
            </label>
          </Col>
          <Col span={12}>
            <label style={{ color: suryoubetsuPrice == 0 ? '#A5A5A5' : '#4A4747' }}>
              数量別購入品　金額：{'¥' + suryoubetsuPrice}　合計金額：{'¥' + suryoubetsuTotalPrice}
            </label>
          </Col>
        </Row>
        <Row style={{ color: 'black', marginTop: '5px', display: editMode ? '' : 'none' }}>
          <Col span={19}>
            <Space>
              <Button
                key="submit"
                type="primary"
                onClick={addFromMaster}
                className="editAllButton"
                style={{ width: 110 }}
                id="masterAdd"
              >
                マスターから追加
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={addNew}
                className="editAllButton"
                style={{ width: 60 }}
                id="add"
              >
                追加
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={editPurchaseInfo}
                className="editAllButton"
                style={{ width: 60, marginLeft: '80px' }}
                id="editPurchase"
              >
                編集
              </Button>
            </Space>
          </Col>
          <Col span={5}>
            <Button
              key="submit"
              type="primary"
              onClick={deletePurchase}
              className="editAllButton"
              style={{ width: 60, float: 'right' }}
              id="delete"
            >
              削除
            </Button>
          </Col>
        </Row>
        {/* 購入品情報 */}
        <Row style={{ color: 'black', marginTop: '5px' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col">
            <div style={{ display: 'flex' }}>
              <label style={{ marginLeft: 0 }}>親部品購入品情報</label>
            </div>
          </Col>
        </Row>
        <div style={{ margin: '10px' }}>
          <Row>
            <AntdTBL
              className="tbPurchaseListShow"
              columns={purchaseColumns}
              rowKey={(record) => record.key}
              rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
              dataSource={purchaseLst}
              scroll={{ y: 500, x: '10vw' }}
              pagination={false}
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    onRowSelect(record);
                  },
                };
              }}
            />
          </Row>
        </div>

        {/* 子部品の購入品情報 */}
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={openChildPurchase}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              <label style={{ marginLeft: 0 }}>子部品の購入品情報</label>
            </div>
          </Col>
        </Row>
        <div style={{ margin: '10px' }}>
          <Row>
            <AntdTBL
              className={'tbPurchaseListShow'}
              columns={childPurchaseColumns}
              dataSource={props.selectedDataDetail.length == 0 ? [] : childPurchaseLstArr}
              rowKey={(record) => record.key}
              scroll={{ y: 500, x: '10vw' }}
              pagination={false}
            />
          </Row>
        </div>
      </div>
      {/* 追加・編集 */}
      <Modal
        maskClosable={false}
        title={addNewModalTitle}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={addModal}
        width={555}
        onOk={addOK}
        onCancel={addCancel}
        footer={[
          <Button
            key="submit"
            onClick={updatePurchase}
            className="mainButton"
            style={{ display: isEditPurchase ? '' : 'none' }}
          >
            更新
          </Button>,
          <Button key="submit" onClick={addOK} className="mainButton" style={{ display: isEditPurchase ? 'none' : '' }}>
            追加
          </Button>,
          <Button key="back" onClick={addCancel} className="cancelButton">
            破棄
          </Button>,
        ]}
      >
        <Form name="AddForm" autoComplete="off">
          <div id="addNewCustomer" style={{ marginTop: 15 }}>
            <Table className="propertiesTbl">
              <thead>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">品名</label>
                  </td>
                  <td style={{ width: '80%' }} className="detail-tbl-val">
                    <Input
                      name="purchaseNm"
                      type="text"
                      id="purchaseNm"
                      value={purchaseNm}
                      onChange={(e) => setPurchaseNm(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">型番</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="typeNo"
                      type="text"
                      id="typeNo"
                      value={typeNo}
                      onChange={(e) => setTypeNo(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">単価</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="unitPrice"
                      type="text"
                      id="unitPrice"
                      value={unitPrice}
                      onChange={(e) => setUnitPrice(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className="input-editable"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">必要数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="requiredNum"
                      type="text"
                      id="requiredNum"
                      value={requiredNum}
                      onChange={(e) => setRequiredNum(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className="input-editable"
                    />
                  </td>
                </tr>
                <tr style={{ display: isEditPurchase ? '' : 'none' }}>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">合計必要数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="requiredNum"
                      type="text"
                      id="requiredNum"
                      value={requiredNum}
                      onChange={(e) => setRequiredNum(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">仕入先</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="supplier"
                      type="text"
                      id="supplier"
                      value={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">メーカー</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="maker"
                      type="text"
                      id="maker"
                      value={maker}
                      onChange={(e) => setMaker(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="purchaseRemark"
                      type="text"
                      id="purchaseRemark"
                      value={purchaseRemark}
                      onChange={(e) => setPurchaseRemark(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
        </Form>
      </Modal>

      {/* マスターから追加 */}
      {isMasterAdd
        ? commonModal(
            isMasterAdd,
            addNewModalTitle,
            commonFooter(addOK, addCancel),
            null,
            1085,
            addOK,
            addCancel,
            addMasterContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default Purchase;
