/**
 * クラス名：板金のブランク加工速度画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Space, Button, Radio } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { commonModal } from '../../../common/CommonModal';
const Device_Blank_Kakousokudo = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isItaatsu, setIsItaatsu] = useState(false);

  const [mode, setMode] = useState('simple');

  const [itaatsu, setItaatsu] = useState([]);
  const [itaatsuTemp, setItaatsuTemp] = useState([]);

  const [btnEnable, setBtnEnable] = useState(true);

  const [listData, setListData] = useState([]);

  const [gasTypeList, setGasTypeList] = useState([]);
  const [jointList, setJointList] = useState([]);
  const [kakousokudoItaatsuList, setKakousokudoItaatsuList] = useState([]);
  const [piercingProcessList, setPiercingProcessList] = useState([]);
  const [punchProcessItaatsuListData, setPunchProcessItaatsuListData] = useState([]);

  // 送り速度
  const [okuriSokudoVal, setOkuriSokudoVal] = useState('');
  // ケガキ速度
  const [kegakiSokudoVal, setKegakiSokudoVal] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    setItaatsu(props.itaatsu);
    setItaatsuTemp(props.itaatsu);
    setListData(props.blankKakousokudolistData);
    setGasTypeList(props.gasTypeList);
    setJointList(props.jointItaatsuList);
    setKakousokudoItaatsuList(props.kakouSokudoItaatsuList);
    setPiercingProcessList(props.piercingProcessList);
    setPunchProcessItaatsuListData(props.punchProcessItaatsuListData);
    setOkuriSokudoVal(props.okuriSokudoVal);
    setKegakiSokudoVal(props.kegakiSokudoVal);
  }, [
    props.editMode,
    props.processName,
    props.blankKakousokudolistData,
    props.itaatsu,
    props.kakouSokudoItaatsuList,
    props.gasTypeList,
    props.jointItaatsuList,
    props.piercingProcessList,
    props.punchProcessItaatsuListData,
    props.okuriSokudoVal,
    props.kegakiSokudoVal,
  ]);

  const size = () => {
    setIsItaatsu(true);
  };

  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };

  const cancelMasterData = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
      setItaatsuTemp(itaatsu);
    }
  };
  const addData = () => {};

  const deleteListData = (list, deletedId) => {
    const deletedData = list.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    return deletedData;
  };

  const updateListData = (deletedData, newId) => {
    const list = [
      { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
      { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
      { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
    ];
    const updatedData = deletedData.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: list,
        });
      });

      return item;
    });
    return updatedData;
  };

  const sortListData = (list, masterData) => {
    const sortedList = list.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    return sortedList;
  };

  const handleItaatsuChange = (event, id, field) => {
    const updatedData = itaatsuTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };
  const addItaatsu = (id) => {
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: itaatsuTemp.length + 1,
        max: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  const deleteItaatsu = (id, e) => {
    if (itaatsuTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = itaatsuTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
    }
  };
  const addItaatsuMaster = () => {
    setBtnEnable(false);
    const deletedId = [];

    itaatsu.forEach((item1, index) => {
      const found = itaatsuTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = itaatsuTemp
      .filter((item) => item.displayFlag && !itaatsu.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListData(listData, deletedId);
    const deletedData1 = deleteListData(gasTypeList, deletedId);
    const deleteData2 = deleteListData(jointList, deletedId);
    const deleteData3 = deleteListData(kakousokudoItaatsuList, deletedId);
    const deleteData4 = deleteListData(piercingProcessList, deletedId);
    const deleteData5 = deleteListData(punchProcessItaatsuListData, deletedId);

    const updatedData = updateListData(deletedData, newId);
    const updatedData1 = updateListData(deletedData1, newId);
    const updatedData2 = updateListData(deleteData2, newId);
    const updatedData3 = updateListData(deleteData3, newId);
    const updatedData4 = updateListData(deleteData4, newId);
    const updatedData5 = updateListData(deleteData5, newId);

    const sortedList = sortListData(updatedData, itaatsuTemp);
    const sortedList1 = sortListData(updatedData1, itaatsuTemp);
    const sortedList2 = sortListData(updatedData2, itaatsuTemp);
    const sortedList3 = sortListData(updatedData3, itaatsuTemp);
    const sortedList4 = sortListData(updatedData4, itaatsuTemp);
    const sortedList5 = sortListData(updatedData5, itaatsuTemp);

    setIsItaatsu(false);
    setItaatsu(itaatsuTemp);
    props.updatedItaatsu(itaatsuTemp);
    setListData(sortedList);

    props.updatedBlankKakousokudolistData(sortedList);

    setGasTypeList(sortedList1);
    props.updatedGasTypeList(sortedList1);

    setJointList(sortedList2);
    props.updatedJointItaatsuList(sortedList2);

    setKakousokudoItaatsuList(sortedList3);
    props.updateblankKakouSokudoItaatsuList(sortedList3);

    setPiercingProcessList(sortedList4);
    props.updatePiercingProcessList(sortedList4);

    setPunchProcessItaatsuListData(sortedList5);
    props.updatePunchProcessList(sortedList5);
  };
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.max}
                          onChange={(e) => handleItaatsuChange(e, item.id, 'max')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addItaatsuMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const listDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...listData];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setListData(updatedData);
    props.updatedBlankKakousokudolistData(updatedData);
  };
  const content = (
    <Row>
      <Table
        style={{
          width: '45%',
        }}
      >
        <tr style={{ textAlign: 'center', height: 30 }}>
          {/* <td style={{ width: '15%' }}></td> */}
          <td style={{ width: '10%' }}>S</td>
          <td style={{ width: '10%' }}> M</td>
          <td style={{ width: '10%' }}>L</td>
        </tr>

        <tr style={{ textAlign: 'center' }}>
          {/* <td>丸穴径(Φ) (≦)</td> */}
          <td>
            <Input></Input>
          </td>
          <td>
            <Input></Input>
          </td>
          <td>
            <Input></Input>
          </td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          {/* <td>加工周長(mm)(≦)</td> */}
          <td>
            <Input></Input>
          </td>
          <td>
            <Input></Input>
          </td>
          <td>
            <Input></Input>
          </td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          {/* <td>速度(mm/分)</td> */}
          <td>
            <Input></Input>
          </td>
          <td>
            <Input></Input>
          </td>
          <td>
            <Input></Input>
          </td>
        </tr>
      </Table>
    </Row>
  );
  const itaatsulistDataChange = (rowIndex, colIndex, subcolIndex, value, name) => {
    setBtnEnable(false);
    const copyData = [...listData];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].list[subcolIndex][name] = value;
    setListData(updatedData);
    props.updatedBlankKakousokudolistData(updatedData);
  };
  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row>
          <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
            <Radio.Button value="simple" style={{ pointerEvents: btnEnable ? 'auto' : 'none' }}>
              簡易入力
            </Radio.Button>
            <Radio.Button value="details">詳細入力</Radio.Button>
          </Radio.Group>
        </Row>
        <Row className="qty-table-container">
          <Table
            style={{
              width:
                itaatsu.filter((i) => i.displayFlag === true).length > 8
                  ? '100%'
                  : itaatsu.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '260px' }} className="fixedCol" colSpan={2}>
                <Row justify="center" className="tbl-header">
                  <Space>
                    <label>材質区分</label> <label>/</label>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={size} style={{ pointerEvents: 'auto' }}>
                          板厚
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>板厚</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {itaatsu
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td
                    style={{
                      backgroundColor: '#1063aa',
                      width: mode === 'simple' ? '98px' : '300px',
                      // : itaatsu.filter((i) => i.displayFlag === true).length > 4
                      // ? '588px'
                      // : itaatsu.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
                    }}
                  >
                    ～{item.max}t
                  </td>
                ))}
            </tr>

            {listData.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                {mode === 'simple' ? (
                  <>
                    <td className="tbl-header fixedCol2" colSpan={2}>
                      {row.materialType}
                    </td>
                  </>
                ) : (
                  <>
                    <td className="tbl-header fixedCol2">{row.materialType}</td>
                    <td className="fixedCol3">
                      <Row style={{ padding: 0 }}>
                        {/* 丸穴径(Φ) (≦) */}
                        <Table style={{ marginBottom: 0 }} className="table-no-border-left">
                          <tr style={{ height: 36 }}>
                            <td className="removerightborder removeleftborder"></td>
                          </tr>
                          <tr style={{ height: 31 }}>
                            <td className="removerightborder removeleftborder">丸穴径(Φ) (≦)</td>
                          </tr>
                          <tr style={{ height: 31 }}>
                            <td className="removerightborder removeleftborder">加工周長(mm)(≦)</td>
                          </tr>
                          <tr style={{ height: 31 }}>
                            <td className="removerightborder removeleftborder removeBottomborder">速度(mm/分)</td>
                          </tr>
                        </Table>
                      </Row>
                      {/* <Row>加工周長(mm)(≦)</Row>
                      <Row>速度(mm/分)</Row> */}
                    </td>
                  </>
                )}

                {row.lstData.map((col, colIndex) => (
                  <>
                    {mode === 'simple' ? (
                      <>
                        <td style={{ width: '98px' }}>
                          <Input
                            value={col.value}
                            onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td style={{ width: '300px' }}>
                          <Row style={{ padding: 0 }}>
                            <Table style={{ marginBottom: 0 }} className="table table-no-border-left">
                              <thead>
                                <tr style={{ height: 35 }}>
                                  <td style={{ backgroundColor: '#ffffff' }}>S</td>
                                  <td>M</td>
                                  <td className="removerightborder">L</td>
                                </tr>
                              </thead>
                              <tbody className="listborder">
                                {col.list.map((subcol, subcolIndex) => (
                                  <>
                                    <tr>
                                      <td style={{ padding: 2 }} className="removeleftborder">
                                        <Input
                                          value={subcol.svalue}
                                          onChange={(e) =>
                                            itaatsulistDataChange(
                                              rowIndex,
                                              colIndex,
                                              subcolIndex,
                                              e.target.value,
                                              'svalue'
                                            )
                                          }
                                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                                        />
                                      </td>
                                      <td style={{ padding: 2 }}>
                                        <Input
                                          value={subcol.mvalue}
                                          onChange={(e) =>
                                            itaatsulistDataChange(
                                              rowIndex,
                                              colIndex,
                                              subcolIndex,
                                              e.target.value,
                                              'mvalue'
                                            )
                                          }
                                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                                        />
                                      </td>
                                      <td style={{ padding: 2 }} className="removerightborder">
                                        <Input
                                          value={subcol.lvalue}
                                          onChange={(e) =>
                                            itaatsulistDataChange(
                                              rowIndex,
                                              colIndex,
                                              subcolIndex,
                                              e.target.value,
                                              'lvalue'
                                            )
                                          }
                                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </Table>
                          </Row>
                        </td>
                      </>
                    )}
                  </>
                ))}
              </tr>
            ))}
          </Table>
        </Row>
        <Row>
          <Table style={{ width: '30%' }}>
            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '20%' }} className="fixedCol tbl-header">
                ケガキ速度
              </td>
              <td style={{ width: '10%' }}>
                <Input
                  value={kegakiSokudoVal}
                  onChange={(e) => {
                    setKegakiSokudoVal(e.target.value);
                    props.updateKegakiSokudoVal(e.target.value);
                  }}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            </tr>
            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '20%' }} className="fixedCol tbl-header">
                送り速度
              </td>
              <td style={{ width: '10%' }}>
                <Input
                  value={okuriSokudoVal}
                  onChange={(e) => {
                    setOkuriSokudoVal(e.target.value);
                    props.updateOkuriSokudoVal(e.target.value);
                  }}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            </tr>
          </Table>
        </Row>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_Kakousokudo;
