import React, { useImperativeHandle, forwardRef, useState, useEffect, useRef } from 'react';
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Checkbox,
  Divider,
  Space,
  Image,
  Tooltip,
  Menu,
  Upload,
  Radio,
  Collapse,
  Tabs,
  Card,
  Carousel,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '../../assets/styles/common.css';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Route, Outlet, Routes } from 'react-router-dom';
// Content resize
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import WithRouter from '../../components/WithRouter';
import { useLocation, useNavigate } from 'react-router-dom';

const { Panel } = Collapse;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const iconWidth = 30;
const now = new Date();
const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
const { Search } = Input;
const materialNm = ['02_SPHC（黒皮）', '03_SPHC（酸洗）'];
const thickness = ['9.0', '0.5'];
const data = [];

const EstimateBasicInfo = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [estimateBasicInfo, setEstimateBasicInfo] = useState([]);
  const { TextArea } = Input;

  useEffect(() => {
    setEstimateBasicInfo(props.estimateBasicInfo == undefined ? [] : props.estimateBasicInfo);
  }, [props.estimateBasicInfo]);

  const updateEstimateInfo = (estimateInfo) => {
    setEstimateBasicInfo(estimateInfo);
  };

  const onChange = () => {};

  useImperativeHandle(ref, () => ({
    updateEstimateInfo: updateEstimateInfo,
  }));

  return (
    <>
      {/* 見積基本情報 */}
      <div
        style={{
          backgroundColor: '#fafafc',
          padding: 5,
        }}
      >
        {/** 基本情報 */}
        <Card hoverable={false}>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '17px' }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo.estimateImgNo}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '16px' }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo.estimateNm}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              background: '#364d79',
              marginTop: '5px',
              borderRadius: '3px',
              padding: '3px',
            }}
          >
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo.customerNm}</p>
            </Col>
          </Row>
          {/** 重量*/}
          <Row style={{ marginTop: '5px' }}>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>重量</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo.weight != undefined ? estimateBasicInfo.weight + 'kg' : '0.00kg'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>面積</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo.surfaceArea != undefined ? estimateBasicInfo.surfaceArea + 'c㎡' : '0.00c㎡'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>体積</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo.volume != undefined ? estimateBasicInfo.volume + '.00c㎡' : '0.00c㎡'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/** 作成者*/}
          <Row style={{ marginTop: '5px' }}>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>作成者</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo.creator}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>見積日</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo.estimatedDate}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>納期日</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo.deliveryDate}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** 見積／原価／粗利・粗利率 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>
                {estimateBasicInfo.partCnt == undefined ? ' 数量　0' : ' 数量　' + estimateBasicInfo.partCnt}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>原価</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo.costPrice == undefined ? '¥0.00' : '¥' + estimateBasicInfo.costPrice}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {'¥' + estimateBasicInfo.totalCostPrice}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px', marginLeft: '10px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>見積</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {'¥' + estimateBasicInfo.estimatedAmount}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {'¥' + estimateBasicInfo.totalEstimatedAmount}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>粗利</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {'¥' + estimateBasicInfo.costProfit}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {'¥' + estimateBasicInfo.estimateProfit}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  height: '98px',
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '5px',
                  marginLeft: '10px',
                }}
              >
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>粗利率</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '7px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '25px', fontWeight: '600', color: 'white' }}>
                      {estimateBasicInfo.profitRate != undefined ? estimateBasicInfo.profitRate + '.00%' : '0.00%'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** 原価詳細 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Carousel afterChange={onChange} style={{ color: 'white' }}>
            <div>
              <Row>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>材料原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.materialCost + '-'}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.materialCost + '-'}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>加工原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.processCost + '-'}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.processCost + '-'}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>購入品</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.purchaseCost + '-'}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.purchaseCost + '-'}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>費用</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.hiyou + '-'}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥' + estimateBasicInfo.hiyou + '-'}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  {/* <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>加工原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥25,000-'}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥50,000-'}</p>
                      </Col>
                    </Row>
                  </div> */}
                </Col>
                <Col span={7}>
                  {/* <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>購入品</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥25,000-'}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>{'¥25,000-'}</p>
                      </Col>
                    </Row>
                  </div> */}
                </Col>
              </Row>
            </div>
          </Carousel>
        </Card>
        {/** 係数 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>取引先係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {estimateBasicInfo.customerCoefficient}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: '5px',
                }}
              >
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>受注形態</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {estimateBasicInfo.orderForm}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>管理費係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {estimateBasicInfo.managementCoefficient}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: '5px',
                }}
              >
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>納期係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {estimateBasicInfo.deliveryCoefficient}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '5px' }}>
            <Col span={24}>
              <div style={{ width: '50%', background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>購入品係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {estimateBasicInfo.purchaseCoefficient}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** コメント */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={24}>
              <p style={{ margin: 0, fontSize: '12px' }}>コメント</p>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={24}>
              {/* <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo.comment}</p> */}
              <TextArea
                style={{
                  verticalAlign: 'Top',
                  fontSize: '12px',
                  height: '140px',
                  background: '#364d79',
                  borderRadius: '5px',
                  padding: '10px',
                  color: 'white',
                }}
                className={'input-non-editable'}
                name="comment"
                id="comment"
                value={estimateBasicInfo.comment}
                type="text"
                rows={5}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
});

export default EstimateBasicInfo;
