import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

const menualBProcessArr1 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 141,
    sagyoTime: '00:02:48',
    processLength: 2700,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr2 = [
  {
    id: 0,
    processName: '手動バリ取取(No2)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 141,
    sagyoTime: '00:02:48',
    processLength: 2700,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr3 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 237,
    sagyoTime: '00:04:44',
    processLength: 4549.93,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr4 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 128,
    sagyoTime: '00:02:33',
    processLength: 245.0,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr5 = [
  {
    id: 0,
    processName: '手動バリ取取(No3)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 30,
    sagyoTime: '00:00:36',
    processLength: 148.94,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr1_PMX = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 227,
    sagyoTime: '00:03:14',
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    processLength: 3235.73,
    processCount: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr2_PMX = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 17,
    sagyoTime: '00:00:20',
    processLength: 99.998,
    processCount: 1,
    sagyoAmtOut: 17,
    sagyoTimeOut: '00:00:20',
    lenghtOut: 341.42,
    countOut: 1,
    sagyoAmtIn: 0,
    sagyoTimeIn: '00:00:00',
    lenghtIn: 0.0,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr3_PMX = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 14,
    sagyoTime: '00:00:16',
    processLength: 270.0,
    processCount: 1,
    sagyoAmtOut: 17,
    sagyoTimeOut: '00:00:14',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 5,
    sagyoTimeIn: '00:00:04',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 132,
    materialHandlingTime: '00:01:00',
    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
];

const menualBProcessArr4_PMX = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processLength: 0.0,
    processCount: 0,
    sagyoAmtOut: 0,
    sagyoTimeOut: '00:00:00',
    lenghtOut: 0,
    countOut: 0,
    sagyoAmtIn: 0,
    sagyoTimeIn: '00:00:00',
    lenghtIn: 0,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr1_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 119,
    sagyoTime: '00:02:22',
    processLength: 2287.707,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr2_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 23,
    sagyoTime: '00:00:24',
    processLength: 317.676,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr3_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 35,
    sagyoTime: '00:00:36',
    processLength: 1414.56,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr4_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 23,
    sagyoTime: '00:00:24',
    processLength: 145.873,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr5_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processLength: 47.0,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr6_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processLength: 47.0,
    processCount: 1,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const menualBProcessArr7_PMX2 = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 0,
    dandoriTime: '00:07:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    processLength: 0.0,
    processCount: 0,
    sagyoAmtOut: 189,
    sagyoTimeOut: '00:02:42',
    lenghtOut: 3235.73,
    countOut: 1,
    sagyoAmtIn: 38,
    sagyoTimeIn: '00:00:32',
    lenghtIn: 534.07,
    countIn: 1,
    materialHandlingAmt: 0,
    materialHandlingTime: '00:00:00',
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const IQ3KouteiCalculate_MenualBari = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState('');

  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');

  const [processArr, setProcessArr] = useState(menualBProcessArr1);
  const [editedData, setEditedData] = useState([]);

  const settingEditData = (arr) => {
    let editDataArr = [];
    for (let i = 0; i < arr.length; i++) {
      let sagyoAmtSum =
        parseFloat(arr[i].sagyoAmtOut) + parseFloat(arr[i].sagyoAmtIn) + parseFloat(arr[i].materialHandlingAmt);
      let sagyoTimeSum = secondsToHms(
        setTimetoSec(arr[i].sagyoTimeOut) + setTimetoSec(arr[i].sagyoTimeIn) + setTimetoSec(arr[i].materialHandlingTime)
      );
      editDataArr.push({
        id: i,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        sagyoAmt: sagyoAmtSum,
        sagyoTime: sagyoTimeSum,
        processLength: arr[i].processLength,
        processCount: arr[i].processCount,
        sagyoAmtOut: arr[i].sagyoAmtOut,
        sagyoTimeOut: arr[i].sagyoTimeOut,
        lenghtOut: arr[i].lenghtOut,
        countOut: arr[i].countOut,
        sagyoAmtIn: arr[i].sagyoAmtIn,
        sagyoTimeIn: arr[i].sagyoTimeIn,
        lenghtIn: arr[i].lenghtIn,
        countIn: arr[i].countIn,
        sumAmt: parseFloat(arr[i].dandoriAmt) + sagyoAmtSum,
        sumTime: secondsToHms(setTimetoSec(arr[i].dandoriTime) + setTimetoSec(sagyoTimeSum)),
        materialHandlingAmt: arr[i].materialHandlingAmt,
        materialHandlingTime: arr[i].materialHandlingTime,
        naibuProcessValidationAmt: arr[i].naibuProcessValidationAmt,
        naibuProcessValidationTime: arr[i].naibuProcessValidationTime,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += setTimetoSec(element.sagyoTime);
      dandoriTimeSec += setTimetoSec(element.dandoriTime);
    });

    let totalSum = editDataArr[0].sumAmt + editDataArr[0].naibuProcessValidationAmt;
    let sagyoTimeSum = secondsToHms(sagyoTimeSec);
    let dandoriTimeSum = secondsToHms(dandoriTimeSec);
    let totalTimeSum = secondsToHms(
      setTimetoSec(editDataArr[0].sumTime) + setTimetoSec(editDataArr[0].naibuProcessValidationTime)
    );

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let dataEdit = [];
    let totals = [];
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props.selectedDataDetail != undefined) {
      if (Object.keys(props.selectedDataDetail).length > 0) {
        if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == '看板本体') {
          setProcessArr(menualBProcessArr1);
          dataEdit = settingEditData(menualBProcessArr1);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == '看板本体-2塗装') {
          setProcessArr(menualBProcessArr2);
          dataEdit = settingEditData(menualBProcessArr2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == '看板スタンド-1') {
          setProcessArr(menualBProcessArr3);
          dataEdit = settingEditData(menualBProcessArr3);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == '看板スタンド-2') {
          setProcessArr(menualBProcessArr4);
          dataEdit = settingEditData(menualBProcessArr4);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == '看板スタンド-3x5') {
          setProcessArr(menualBProcessArr5);
          dataEdit = settingEditData(menualBProcessArr5);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-01') {
          setProcessArr(menualBProcessArr1_PMX);
          dataEdit = settingEditData(menualBProcessArr1_PMX);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-02') {
          setProcessArr(menualBProcessArr2_PMX);
          dataEdit = settingEditData(menualBProcessArr2_PMX);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-03') {
          setProcessArr(menualBProcessArr3_PMX);
          dataEdit = settingEditData(menualBProcessArr3_PMX);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'MRO-M08A-4110-04') {
          setProcessArr(menualBProcessArr4_PMX);
          dataEdit = settingEditData(menualBProcessArr4_PMX);
        } else if (props.selectedDataDetail.no == 1 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-01') {
          setProcessArr(menualBProcessArr1_PMX2);
          dataEdit = settingEditData(menualBProcessArr1_PMX2);
        } else if (props.selectedDataDetail.no == 2 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-02') {
          setProcessArr(menualBProcessArr2_PMX2);
          dataEdit = settingEditData(menualBProcessArr2_PMX2);
        } else if (props.selectedDataDetail.no == 3 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-03') {
          setProcessArr(menualBProcessArr3_PMX2);
          dataEdit = settingEditData(menualBProcessArr3_PMX2);
        } else if (props.selectedDataDetail.no == 4 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-04') {
          setProcessArr(menualBProcessArr4_PMX2);
          dataEdit = settingEditData(menualBProcessArr4_PMX2);
        } else if (props.selectedDataDetail.no == 5 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-05') {
          setProcessArr(menualBProcessArr5_PMX2);
          dataEdit = settingEditData(menualBProcessArr5_PMX2);
        } else if (props.selectedDataDetail.no == 6 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-06') {
          setProcessArr(menualBProcessArr6_PMX2);
          dataEdit = settingEditData(menualBProcessArr6_PMX2);
        } else if (props.selectedDataDetail.no == 7 && props.selectedDataDetail.partNm == 'ISZ-HRK1-1300-07') {
          setProcessArr(menualBProcessArr7_PMX2);
          dataEdit = settingEditData(menualBProcessArr7_PMX2);
        }
      }

      setEditedData(dataEdit);
      /* totals = calculateTotals(dataEdit); */
      if (dataEdit.length > 0) {
        totals = calculateTotals(dataEdit);
      }
      setTotalAmt(totals[0]?.totalSum);
      setTotalSum(totals[0]?.totalSum);

      setTotalTime(totals[0]?.totalTimeSum);
      setTotalTimeSum(totals[0]?.totalTimeSum);

      setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
      setSagyoAmtSum(totals[0]?.sagyoAmtSum);

      setTotalSagyoTime(totals[0]?.sagyoTimeSum);
      setSagyoTimeSum(totals[0]?.sagyoTimeSum);

      setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
      setDandoriAmtSum(totals[0]?.dandoriAmtSum);

      setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      setDandoriTimeSum(totals[0]?.dandoriTimeSum);
    }
  }, [props.selectedDataDetail, props.editMode]);

  const yousetsu = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('menualBProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('menualBProcess' + i).style.display = 'none';
      document.getElementById('arrowDownMenualB' + i).style.display = 'block';
      document.getElementById('arrowUpMenualB' + i).style.display = 'none';
    } else {
      document.getElementById('menualBProcess' + i).style.display = 'block';
      document.getElementById('arrowDownMenualB' + i).style.display = 'none';
      document.getElementById('arrowUpMenualB' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'menualBDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'menualBDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'menualBDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'menualBDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if ('sagyoTime'.find(objId) != -1) {
      /* (objId === 'sagyoTime')  */
      //checkInputWithColor(index + 'menualBWorkAmt', newState[index].sagyoAmt, 'green');
      if (document.getElementById(index + 'menualBWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'menualBWorkAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(index + 'menualBWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
          }
          checkInputWithColor(index + 'menualBWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = parseFloat(newState[index].sagyoAmt) + parseFloat(newState[index].dandoriAmt);
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = secondsToHms(
      setTimetoSec(newState[index].sagyoTime) + setTimetoSec(newState[index].dandoriTime)
    );
    newState[index].sumTime = newSubTimeSum;
    newState.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      newSagyoTimeSec += setTimetoSec(element.sagyoTime);
      newDandoriTimeSec += setTimetoSec(element.dandoriTime);
    });
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = secondsToHms(newSagyoTimeSec);
    let newDandoriTimeSum = secondsToHms(newDandoriTimeSec);
    let newTotalTimeSum = secondsToHms(newSagyoTimeSec + newDandoriTimeSec);

    checkInputWithColor(index + 'subTotalMenual', newSubSum, 'green');
    checkInputWithColor(index + 'subTotalTimeMenual', newSubTimeSum, 'green');
    checkInputWithColor('totalSagyoAmtMenual', newSagyoSum, 'green');
    checkInputWithColor('totalDandoriAmtMenual', newDandoriSum, 'green');
    checkInputWithColor('totalAmtMenual', newSum, 'green');
    checkInputWithColor('totalSagyoTimeMenual', newSagyoTimeSum, 'green');
    checkInputWithColor('totalDandoriTimeMenual', newDandoriTimeSum, 'green');
    checkInputWithColor('totalTimeMenual', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else if (action === 'inc') {
      if (typeTime == '10min') {
        editedAmt = intAmt + 600;
      } else if (typeTime == 'min') {
        editedAmt = intAmt + 60;
      } else if (typeTime == 'sec') {
        editedAmt = intAmt + 10;
      }
    } else if (action === 'dec') {
      if (typeTime === '10min' && intAmt > 600) {
        editedAmt = intAmt - 600;
      } else if (typeTime == '10min' && totalSec <= 600) {
        editedAmt = intAmt;
      } else if (typeTime == 'min' && intAmt > 60) {
        editedAmt = intAmt - 60;
      } else if (typeTime == 'min' && totalSec <= 60) {
        editedAmt = intAmt;
      } else if (typeTime == 'sec' && totalSec > 0) {
        editedAmt = totalSec - 10;
      } else if (totalSec <= 0 || intAmt <= 0) {
        editedAmt = intAmt;
      }
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yousetsu}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>手動バリ取</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          {/* <div>¥{totalSum}</div> */}
          <div>{totalSum == undefined ? '¥0' : JPYs.format(totalSum)}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>手動バリ取_標準</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox defaultChecked={true}>データ</Checkbox>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox> 編集データ</Checkbox>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            手動バリ取
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtMenual">{JPYs.format(totalSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeMenual">{totalTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtMenualIn" className="resetColor">
              {JPYs.format(totalAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeMenualIn" className="resetColor">
              {totalTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {(() => {
          const arr = [];
          for (let i = 0; i < editedData.length; i++) {
            arr.push(
              <div>
                {/* <Row
                  style={{ color: 'black' }}
                  className="oyabuhin-detail oyabuhin-row"
                  onClick={(e) => subpartDetail(i)}
                >
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex' }}>
                      <CaretUpOutlined
                        id={'arrowUpMenualB' + i}
                        style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      />
                      <CaretDownOutlined id={'arrowDownMenualB' + i} style={{ fontSize: 17, marginTop: 2 }} />
                      <span style={{ marginLeft: '5px' }}>{processArr[i].processName}</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalMenual'}>
                      {JPYs.format((processArr[i].dandoriAmt + processArr[i].sagyoAmt).toFixed(0))}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalTimeMenual'}>
                      {secondsToHms(
                        setTimetoSec(menualBProcessArr1[i].dandoriTime) + setTimetoSec(processArr[i].sagyoTime)
                      )}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalMenualIn'} className="resetColor">
                      {JPYs.format(editedData[i].sumAmt)}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id={i + 'subTotalTimeMenualIn'} className="resetColor">
                      {editedData[i].sumTime}
                    </label>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row> */}

                {(() => {
                  const subArr = [];
                  for (let j = i; j <= i; j++) {
                    subArr.push(
                      <div>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span>段取金額/時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'menualBDandoriAmt'}>{JPYs.format(processArr[j].dandoriAmt)}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'menualBDandoriTime'}>{processArr[j].dandoriTime}</label>
                          </Col>
                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'menualBDandoriAmtIn'}
                              value={editedData[j].dandoriAmt}
                              defaultValue={0}
                              onValueChange={(e) => {
                                if (editedData[j].dandoriAmt != e) {
                                  settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                  checkInputWithColor(j + 'menualBDandoriAmt', e, 'amt');
                                }
                              }}
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'menualBDandoriAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editedData[j].dandoriTime}
                                  id={j + 'menualBDandoriTimeIn'}
                                  style={{ width: '100%' }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    //disableTimer('test');
                                    settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].dandoriTime,
                                          '10min',
                                          j + 'menualBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].dandoriTime,
                                          '10min',
                                          j + 'menualBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].dandoriTime,
                                          'min',
                                          j + 'menualBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].dandoriTime,
                                          'min',
                                          j + 'menualBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].dandoriTime,
                                          'sec',
                                          j + 'menualBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].dandoriTime,
                                          'sec',
                                          j + 'menualBDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={5} className="formula-column"></Col>
                        </Row>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}>
                            <CaretUpOutlined
                              id={'arrowUpMenualB' + j}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <CaretDownOutlined id={'arrowDownMenualB' + j} style={{ fontSize: 17, marginTop: 2 }} />
                            <span style={{ marginLeft: '5px' }}></span>
                            <span>作業金額/時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}>
                            <label id={j + 'menualBWorkAmt'}>
                              {JPYs.format(
                                processArr[j].sagyoAmtOut + processArr[j].sagyoAmtIn + processArr[j].materialHandlingAmt
                              )}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}>
                            <label id={j + 'menualBWorkTime'}>
                              {secondsToHms(
                                setTimetoSec(processArr[j].sagyoTimeIn) +
                                  setTimetoSec(processArr[j].sagyoTimeOut) +
                                  setTimetoSec(processArr[j].materialHandlingTime)
                              )}
                            </label>
                          </Col>
                          <Col span={4} className="input-col" onClick={(e) => subpartDetail(j)}>
                            <CurrencyInput
                              id={j + 'menualBWorkAmtIn'}
                              value={editedData[j].sagyoAmt}
                              defaultValue={0}
                              onValueChange={(e) => {
                                if (editedData[j].sagyoAmt != e) {
                                  settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                  checkInputWithColor(j + 'menualBWorkAmt', e, 'amt');
                                }
                              }}
                              /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(j + 'menualBWorkAmt', e, 'amt');
                                  }
                                }} */
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-non-editable currency resetColor resetColor'
                                  : 'input-non-editable currency resetColor resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'menualBWorkAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col" onClick={(e) => subpartDetail(j)}>
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editedData[j].sagyoTime}
                                  id={j + 'menualBWorkTimeIn'}
                                  style={{ width: '100%' }}
                                  className={
                                    editMode ? 'input-non-editable resetColor' : 'input-non-editable resetColor'
                                  }
                                  onChange={(e) => {
                                    //disableTimer('test');
                                    settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              {/* <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].sagyoTime,
                                          '10min',
                                          j + 'menualBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].sagyoTime,
                                          '10min',
                                          j + 'menualBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].sagyoTime,
                                          'min',
                                          j + 'menualBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].sagyoTime,
                                          'min',
                                          j + 'menualBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j].sagyoTime,
                                          'sec',
                                          j + 'menualBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j].sagyoTime,
                                          'sec',
                                          j + 'menualBWorkTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col> */}
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}></Col>
                          <Col span={5} className="formula-column" onClick={(e) => subpartDetail(j)}></Col>
                        </Row>
                        <div id={'menualBProcess' + j} style={{ display: 'none' }}>
                          <Row
                            style={{ color: 'black', display: processArr[j].sagyoAmtOut == 0 ? 'none' : '' }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">作業金額/時間(外径)</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'menualBWorkAmtOut'}>{JPYs.format(processArr[j].sagyoAmtOut)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'menualBWorkTimeOut'}>{processArr[j].sagyoTimeOut}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'menualBWorkAmtOutIn'}
                                value={editedData[j].sagyoAmtOut}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].sagyoAmtOut != e) {
                                    settingNewDandoriAmtInArr(e, j, 'sagyoAmtOut', '', '');
                                    checkInputWithColor(j + 'menualBWorkAmtOut', e, 'amt');
                                  }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor resetColor'
                                    : 'input-non-editable currency resetColor resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'menualBWorkAmtOutCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].sagyoTimeOut}
                                    id={j + 'menualBWorkTimeOutIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTimeOut', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTimeOut,
                                            '10min',
                                            j + 'menualBWorkTimeOutIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTimeOut,
                                            '10min',
                                            j + 'menualBWorkTimeOut'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTimeOut,
                                            'min',
                                            j + 'menualBWorkTimeOut'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTimeOut,
                                            'min',
                                            j + 'menualBWorkTimeOut'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTimeOut,
                                            'sec',
                                            j + 'menualBWorkTimeOut'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTimeOut,
                                            'sec',
                                            j + 'menualBWorkTimeOut'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}></Col>
                            <Col span={5} className="formula-column" onClick={(e) => subpartDetail(j)}></Col>
                          </Row>
                          <Row
                            style={{ color: 'black', display: processArr[j].sagyoAmtOut == 0 ? 'none' : '' }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">加工長/回数</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proLength">{processArr[j].lenghtOut}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proCount">{processArr[j].countOut}</label>
                            </Col>

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].lenghtOut}
                                id="proLengthIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].countOut}
                                id="proCountIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          <Row
                            style={{ color: 'black', display: processArr[j].sagyoAmtIn == 0 ? 'none' : '' }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">作業金額/時間(内径)</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'menualBWorkAmtIn'}>{JPYs.format(processArr[j].sagyoAmtIn)}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'menualBWorkTimeIn'}>{processArr[j].sagyoTimeIn}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'menualBWorkAmtInIn'}
                                value={editedData[j].sagyoAmtIn}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  if (editedData[j].sagyoAmtIn != e) {
                                    settingNewDandoriAmtInArr(e, j, 'sagyoAmtIn', '', '');
                                    checkInputWithColor(j + 'menualBWorkAmtIn', e, 'amt');
                                  }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor resetColor'
                                    : 'input-non-editable currency resetColor resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'menualBWorkAmtInCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].sagyoTimeIn}
                                    id={j + 'menualBWorkTimeInIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTimeIn', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTimeIn,
                                            '10min',
                                            j + 'menualBWorkTimeInIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTimeIn,
                                            '10min',
                                            j + 'menualBWorkTimeIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTimeIn,
                                            'min',
                                            j + 'menualBWorkTimeIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTimeIn,
                                            'min',
                                            j + 'menualBWorkTimeIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          let editedTime = handelIncreaseTime(
                                            editedData[j].sagyoTimeIn,
                                            'sec',
                                            j + 'menualBWorkTimeIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          let editedTime = handelDecreaseTime(
                                            editedData[j].sagyoTimeIn,
                                            'sec',
                                            j + 'menualBWorkTimeIn'
                                          );
                                          settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}></Col>
                            <Col span={5} className="formula-column" onClick={(e) => subpartDetail(j)}></Col>
                          </Row>
                          <Row
                            style={{ color: 'black', display: processArr[j].sagyoAmtIn == 0 ? 'none' : '' }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">加工長/回数</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proLengthIn">{processArr[j].lenghtIn}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proCountIn">{processArr[j].countIn}</label>
                            </Col>

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].lenghtIn}
                                id="proLengthInIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <InputNumber
                                value={editedData[j].countIn}
                                id="proCountInIn"
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{ width: '70%', border: 'none' }}
                                onChange={(e) => {}}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          {/** マテハン金額/時間 */}
                          <Row
                            style={{ color: 'black', display: processArr[j].materialHandlingAmt == 0 ? 'none' : '' }}
                            className="oyabuhin-detail oyabuhin-row"
                          >
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg">マテハン金額/時間</span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'materialHandlingAmtIn'}>
                                {JPYs.format(processArr[j].materialHandlingAmt)}
                              </label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={j + 'materialHandlingTimeIn'}>{processArr[j].materialHandlingTime}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <CurrencyInput
                                id={j + 'materialHandlingAmtIn'}
                                value={editedData[j].materialHandlingAmt}
                                defaultValue={0}
                                onValueChange={(e) => {
                                  // if (editedData[j].sagyoAmtIn != e) {
                                  //   settingNewDandoriAmtInArr(e, j, 'sagyoAmtIn', '', '');
                                  //   checkInputWithColor(j + 'menualBWorkAmtIn', e, 'amt');
                                  // }
                                }}
                                style={{ border: 'none' }}
                                className={
                                  editMode
                                    ? 'input-editable currency resetColor resetColor'
                                    : 'input-non-editable currency resetColor resetColor'
                                }
                                prefix="¥"
                                decimalsLimit={100}
                              />
                              <div id={j + 'materialHandlingAmtCu'} style={{ display: 'none' }}>
                                <div className="currencyIcon">
                                  <BsUsbC style={{ fontSize: '25' }} />
                                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                                  <span class="tooltiptext">
                                    金額に変更がありましたので、以降時間と連携して自動計算されない。
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={4} className="input-col">
                              <Row style={{ width: '100%' }}>
                                <Col className="timer-input-col">
                                  <Input
                                    value={editedData[j].materialHandlingTime}
                                    id={j + 'materialHandlingTimeIn'}
                                    style={{ width: '100%' }}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    onChange={(e) => {
                                      //disableTimer('test');
                                      // settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTimeIn', 'input', 'input');
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="timer-button-col">
                                  {editMode ? (
                                    <>
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].sagyoTimeIn,
                                          //   '10min',
                                          //   j + 'menualBWorkTimeInIn'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].sagyoTimeIn,
                                          //   '10min',
                                          //   j + 'menualBWorkTimeIn'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].sagyoTimeIn,
                                          //   'min',
                                          //   j + 'menualBWorkTimeIn'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].sagyoTimeIn,
                                          //   'min',
                                          //   j + 'menualBWorkTimeIn'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'dec');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{ fontSize: 17 + 'px' }}
                                        onClick={() => {
                                          // let editedTime = handelIncreaseTime(
                                          //   editedData[j].sagyoTimeIn,
                                          //   'sec',
                                          //   j + 'menualBWorkTimeIn'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'inc');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{ fontSize: 17 + 'px', color: '#000' }}
                                        onClick={() => {
                                          // let editedTime = handelDecreaseTime(
                                          //   editedData[j].sagyoTimeIn,
                                          //   'sec',
                                          //   j + 'menualBWorkTimeIn'
                                          // );
                                          // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'dec');
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}></Col>
                            <Col span={5} className="formula-column" onClick={(e) => subpartDetail(j)}></Col>
                          </Row>
                        </div>
                        {/** 工程内検査金額/時間 */}
                        <Row
                          style={{
                            color: 'black',
                            display: processArr[j].naibuProcessValidationAmt == 0 ? 'none' : '',
                          }}
                          className="oyabuhin-detail oyabuhin-row"
                        >
                          <Col span={4} className="oyabuhin-detail-col">
                            <span>工程内検査金額/時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'naibuProcessValidationAmtIn'}>
                              {JPYs.format(processArr[j].naibuProcessValidationAmt)}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'naibuProcessValidationTimeIn'}>
                              {processArr[j].naibuProcessValidationTime}
                            </label>
                          </Col>
                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'naibuProcessValidationAmtIn'}
                              value={editedData[j].naibuProcessValidationAmt}
                              defaultValue={0}
                              onValueChange={(e) => {
                                // if (editedData[j].sagyoAmtIn != e) {
                                //   settingNewDandoriAmtInArr(e, j, 'sagyoAmtIn', '', '');
                                //   checkInputWithColor(j + 'menualBWorkAmtIn', e, 'amt');
                                // }
                              }}
                              style={{ border: 'none' }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor resetColor'
                                  : 'input-non-editable currency resetColor resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'naibuProcessValidationAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editedData[j].naibuProcessValidationTime}
                                  id={j + 'naibuProcessValidationTimeIn'}
                                  style={{ width: '100%' }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    //disableTimer('test');
                                    // settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTimeIn', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        // let editedTime = handelIncreaseTime(
                                        //   editedData[j].sagyoTimeIn,
                                        //   '10min',
                                        //   j + 'menualBWorkTimeInIn'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelDecreaseTime(
                                        //   editedData[j].sagyoTimeIn,
                                        //   '10min',
                                        //   j + 'menualBWorkTimeIn'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        // let editedTime = handelIncreaseTime(
                                        //   editedData[j].sagyoTimeIn,
                                        //   'min',
                                        //   j + 'menualBWorkTimeIn'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelDecreaseTime(
                                        //   editedData[j].sagyoTimeIn,
                                        //   'min',
                                        //   j + 'menualBWorkTimeIn'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        // let editedTime = handelIncreaseTime(
                                        //   editedData[j].sagyoTimeIn,
                                        //   'sec',
                                        //   j + 'menualBWorkTimeIn'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        // let editedTime = handelDecreaseTime(
                                        //   editedData[j].sagyoTimeIn,
                                        //   'sec',
                                        //   j + 'menualBWorkTimeIn'
                                        // );
                                        // settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}></Col>
                          <Col span={5} className="formula-column" onClick={(e) => subpartDetail(j)}></Col>
                        </Row>
                      </div>
                    );
                  }
                  return subArr;
                })()}
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_MenualBari;
