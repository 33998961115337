import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
const types = [
  { id: 1, value: 'V曲げ', label: 'V曲げ' },
  { id: 2, value: 'R曲げ', label: 'R曲げ' },
  { id: 3, value: 'FR曲げ', label: 'FR曲げ' },
  { id: 4, value: '3R曲げ', label: '3R曲げ' },
  { id: 5, value: 'HM曲げ', label: 'HM曲げ' },
  { id: 6, value: 'Z曲げ', label: 'Z曲げ' },
  { id: 7, value: 'AMA曲げ', label: 'AMA曲げ' },
  { id: 8, value: '特殊曲げ1', label: '特殊曲げ1' },
  { id: 9, value: '特殊曲げ2', label: '特殊曲げ2' },
  { id: 10, value: '特殊曲げ3', label: '特殊曲げ3' },
];

const IQ3KouteiInput_Bending = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  const [bends, setBends] = useState([]);
  const [firstTbl, setFirstTbl] = useState([]);
  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    const bend = props.bendData;
    const first = props.firstData;
    setFirstTbl(first);
    setBends(bend);
  }, [props.bendData, props.firstData]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addBending = (id) => {
    const copyData = bends.slice(); // Create a copy of the original array

    const insertIndex = bends.findIndex((item) => item.key === id); // Find the clicked index of the item
    const largestId = bends.reduce((maxId, item) => {
      return item.key > maxId ? item.key : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        key: largestId + 1,
        types: 'V曲げ',
        bendlength: '',
        lines: '',
        bendNum: '',
        num: 0,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setBends(copyData);
    }
    //  ttt
    // const data = {
    //   key: bends.slice(-1)[0].key + 1,
    //   types: 'V曲げ',
    //   bendlength: 0,
    //   lines: 0,
    //   bendNum: 0,
    //   num: 0,
    // };
    // let addArr = [...bends, data];
    // setBends(addArr);
  };
  const deleteBending = (e, index) => {
    if (bends.length > 1) {
      setBends(
        bends.filter((j) => {
          return ![e].includes(j.key);
        })
      );
    }
  };
  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(bends));
    if (types === 'types') {
      const updatedData = temp.map((row) => {
        if (row.key === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
      setBends(updatedData);
    } else {
      const updatedData = temp.map((row) => {
        if (row.key === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
      setBends(updatedData);
    }
  };
  const changeValue = (e, name) => {
    const updatedData = firstTbl.map((row) => {
      return { ...row, [name]: e.target.value };
    });
    setFirstTbl(updatedData);
  };
  return (
    <>
      <Row className="mt-1 registerModal">
        <Table className="kouteiinput" style={{ width: '44%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '22%' }} className="tbl-header">
                最大曲げ線長(mm)
              </th>
              <th style={{ width: '22%' }} className="tbl-header">
                自動抽出(最大曲げ線長)
              </th>
            </tr>
          </thead>
          <tbody>
            {firstTbl.map((i) => (
              <>
                <tr>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.val1}
                      onChange={(e) => changeValue(e, 'val1')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.val2}
                      onChange={(e) => changeValue(e, 'val2')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput" style={{ width: '60.5%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '19%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11.5%' }} className="tbl-header">
                曲げ長さ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                曲げ線数(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                FR曲げ回数(mm)
              </th>
            </tr>
          </thead>
          <tbody>
            {bends.map((i, index) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {editMode ? (
                      <>
                        <Select
                          defaultValue={1}
                          value={i.types}
                          onChange={(e) => changeDetails(e, i.key, 'types')}
                          size="middle"
                          style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                        >
                          {/* <Option value={1}> V曲げ</Option> */}
                          {types.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <>{i.types}</>
                    )}
                  </td>

                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.bendlength}
                      onChange={(e) => changeDetails(e, i.key, 'bendlength')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.lines}
                      onChange={(e) => changeDetails(e, i.key, 'lines')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    {i.types === 'FR曲げ' ? (
                      <>
                        <Input
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          value={i.bendNum}
                          onChange={(e) => changeDetails(e, i.key, 'bendNum')}
                          style={{ height: 32, textAlign: 'center' }}
                        ></Input>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>

                  {editMode ? (
                    <>
                      <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                        <Row justify="center">
                          <PlusCircleFilled
                            className="add-remove-icon"
                            style={{ marginLeft: 10, marginRight: 10 }}
                            onClick={() => addBending(i.key)}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(event) => deleteBending(i.key, event)}
                          />
                        </Row>
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                      ></td>
                    </>
                  )}
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Bending;
