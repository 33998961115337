export const iq3_processPatternMasterList = [
  {
    key: 0,
    patternNo: 1,
    patternName: '板金_標準',
    patternProcess: [
      {
        key: 0,
        processType: 'プログラム',
        processName: 'プログラム',
        checked: true,
        deviceCodeKey: 1,
        deviceName: 'プログラム_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 1,
        processType: 'ブランク',
        processName: 'ブランク',
        checked: false,
        deviceCodeKey: 2,
        deviceName: 'レーザー_標準',
        processDetailLst: [],
        addProType: 'レーザー',
      },
      {
        key: 2,
        processType: 'ブランク',
        processName: 'ブランク',
        checked: false,
        deviceCodeKey: 3,
        deviceName: 'パンチ_標準',
        processDetailLst: [],
        addProType: 'パンチ',
      },
      {
        key: 3,
        processType: 'ブランク',
        processName: 'ブランク',
        checked: false,
        deviceCodeKey: 4,
        deviceName: '複合機_標準',
        processDetailLst: [],
        addProType: '複合機',
      },
      {
        key: 4,
        processType: 'ベンディング',
        processName: 'ベンディング',
        checked: true,
        deviceCodeKey: 5,
        deviceName: 'ベンディング_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 5,
        processType: 'バリ取',
        processName: 'バリ取',
        checked: true,
        deviceCodeKey: 6,
        deviceName: '手動バリ取_標準',
        processDetailLst: [],
        addProType: '手動',
      },
      {
        key: 6,
        processType: 'バリ取',
        processName: 'バリ取',
        checked: 0,
        deviceCodeKey: 7,
        deviceName: '自動バリ取_標準',
        processDetailLst: [],
        addProType: '自動',
      },
      {
        key: 7,
        processType: '表面処理',
        processName: '表面処理',
        checked: true,
        deviceCodeKey: 8,
        deviceName: '表面処理_標準',
        numberOfProcess: 4,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '1',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '4',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 8,
        processType: '2次加工',
        processName: '2次加工',
        checked: true,
        deviceCodeKey: 9,
        deviceName: '2次加工_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 9,
        processType: '溶接',
        processName: '溶接',
        checked: true,
        deviceCodeKey: 10,
        deviceName: '溶接_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 10,
        processType: '溶接仕上',
        processName: '溶接仕上',
        checked: true,
        deviceCodeKey: 11,
        deviceName: '溶接仕上_標準',
        numberOfProcess: 4,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '0',
            processDetailCode: '002',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '0',
            processDetailCode: '003',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 11,
        processType: 'シャーリング',
        processName: 'シャーリング',
        checked: true,
        deviceCodeKey: 12,
        deviceName: 'シャーリング_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 12,
        processType: '検査',
        processName: '検査',
        checked: true,
        deviceCodeKey: 13,
        deviceName: '検査_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 13,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 15,
        deviceName: '社内工程_1',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 14,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 16,
        deviceName: '社内工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 15,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 16,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 17,
        deviceName: '社外工程_1',
        processDetailLst: [],
        companyCode: '00013',
        itakuFlag: true,
        addProType: '仮付',
      },
      {
        key: 17,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: 0,
        deviceCodeKey: 18,
        deviceName: '社外工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 18,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 19,
        deviceName: '社外工程_3',
        processDetailLst: [],
        companyCode: '00014',
        itakuFlag: true,
        addProType: '水張検査',
      },
      {
        key: 19,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 20,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 21,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 22,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 23,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 24,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 25,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 26,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 27,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
    ],
    created: '2023/07/06',
    modified: '2023/07/06',
  },
  {
    key: 1,
    patternNo: 2,
    patternName: '板金_SP',
    patternProcess: [
      {
        key: 0,
        processType: 'プログラム',
        processName: 'プログラム',
        checked: true,
        deviceCodeKey: 20,
        deviceName: 'プログラム_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 1,
        processType: 'ブランク',
        processName: 'ブランク',
        checked: false,
        deviceCodeKey: 23,
        deviceName: 'レーザー_標準',
        processDetailLst: [],
        addProType: 'レーザー',
      },
      {
        key: 2,
        processType: 'ブランク',
        processName: 'ブランク',
        checked: false,
        deviceCodeKey: 3,
        deviceName: 'パンチ_標準',
        processDetailLst: [],
        addProType: 'パンチ',
      },
      {
        key: 3,
        processType: 'ブランク',
        processName: 'ブランク',
        checked: false,
        deviceCodeKey: 4,
        deviceName: '複合機_標準',
        processDetailLst: [],
        addProType: '複合機',
      },
      {
        key: 4,
        processType: 'ベンディング',
        processName: 'ベンディング',
        checked: true,
        deviceCodeKey: 25,
        deviceName: 'ベンディング_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 5,
        processType: 'バリ取',
        processName: 'バリ取',
        checked: true,
        deviceCodeKey: 26,
        deviceName: '手動バリ取_標準',
        processDetailLst: [],
        addProType: '手動',
      },
      {
        key: 6,
        processType: 'バリ取',
        processName: 'バリ取',
        checked: 0,
        deviceCodeKey: 7,
        deviceName: '自動バリ取_標準',
        processDetailLst: [],
        addProType: '自動',
      },
      {
        key: 7,
        processType: '表面処理',
        processName: '表面処理',
        checked: true,
        deviceCodeKey: 29,
        deviceName: '表面処理_標準',
        numberOfProcess: 4,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '1',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '4',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 8,
        processType: '2次加工',
        processName: '2次加工',
        checked: true,
        deviceCodeKey: 24,
        deviceName: '2次加工_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 9,
        processType: '溶接',
        processName: '溶接',
        checked: true,
        deviceCodeKey: 27,
        deviceName: '溶接_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 10,
        processType: '溶接仕上',
        processName: '溶接仕上',
        checked: true,
        deviceCodeKey: 28,
        deviceName: '溶接仕上_標準',
        numberOfProcess: 4,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '0',
            processDetailCode: '002',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '0',
            processDetailCode: '003',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 11,
        processType: 'シャーリング',
        processName: 'シャーリング',
        checked: true,
        deviceCodeKey: 30,
        deviceName: 'シャーリング_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 12,
        processType: '検査',
        processName: '検査',
        checked: true,
        deviceCodeKey: 31,
        deviceName: '検査_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 13,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 15,
        deviceName: '社内工程_1',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 14,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 16,
        deviceName: '社内工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 15,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 16,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 17,
        deviceName: '社外工程_1',
        processDetailLst: [],
        companyCode: '00013',
        itakuFlag: true,
        addProType: '仮付',
      },
      {
        key: 17,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: 0,
        deviceCodeKey: 18,
        deviceName: '社外工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 18,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 19,
        deviceName: '社外工程_3',
        processDetailLst: [],
        companyCode: '00014',
        itakuFlag: true,
        addProType: '水張検査',
      },
      {
        key: 19,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 20,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 21,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 22,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 23,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 24,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 25,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 26,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 27,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
    ],
    created: '2023/07/06',
    modified: '2023/07/06',
  },
];
