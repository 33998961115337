/**
 * クラス名：板金の工程内検査画面
 * 作成者：チュー
 * 作成日：2023/06/27
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Checkbox } from 'antd';
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { commonModal } from '../../../common/CommonModal';
import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';

const Device_ProcessInspection = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  // 生産個数
  const [kosuuData, setKosuuData] = useState([]);
  const [kosuuDataTemp, setKosuuDataTemp] = useState([]);
  const [kosuuList, setKosuuList] = useState([]);
  const [isKosuu, setIsKosuu] = useState(false);
  const [setupTime, setSetupTime] = useState('00:00:00');

  // 段取設定の生産個数
  const [dantorikosuuList, setDantoriKosuuList] = useState([]);

  const [category, setCategory] = useState('');

  useEffect(() => {
    setEditMode(props.editMode);

    // 生産個数
    setKosuuData(props.kosuu);
    setKosuuDataTemp(props.kosuu);
    setKosuuList(props.kosuuList);

    // 基本段取時間
    setSetupTime(props.inspectionTime);

    setCategory(props.processName);
    setDantoriKosuuList(props.dantorikosuuList);
  }, [props.editMode, props.kosuu, props.kosuuList, props.inspectionTime, props.processName, props.dantorikosuuList]);
  const quantity = () => {
    setIsKosuu(true);
  };
  const changeTime = (e) => {
    setSetupTime(e);
    props.updatedDantoriTime(e);
  };

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isKosuu === true) {
      setIsKosuu(false);
    }
  };

  const cancelMasterData = () => {
    if (isKosuu === true) {
      setKosuuDataTemp(kosuuData);
      setIsKosuu(false);
    }
  };

  const addKosuu = (id) => {
    const copyData = kosuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = kosuuDataTemp.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: kosuuDataTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setKosuuDataTemp(copyData);
    }
  };

  const handleKosuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(kosuuDataTemp));
    const updatedData = temp
      .filter((i) => i.displayFlag === true)
      .map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData.length - 2].val;
    if (previousValue) {
      updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
      setKosuuDataTemp(updatedData);
    } else {
      setKosuuDataTemp(updatedData);
    }
  };
  const deleteKosuu = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(kosuuDataTemp));
    if (listTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = listTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata.filter((i) => i.displayFlag === true);
      const previousValue = suuRyou[suuRyou.length - 2].val;
      if (previousValue) {
        updatedData[updatedData.length - 1].val = (parseInt(previousValue) + 1).toString();
        setKosuuDataTemp(updatedData);
      } else {
        setKosuuDataTemp(updatedData);
      }
    }
  };

  const addKosuuMasterData = () => {
    const deletedId = [];

    kosuuData.forEach((item1, index) => {
      const found = kosuuDataTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = kosuuDataTemp
      .filter((item) => item.displayFlag && !kosuuData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const afterDeleted = kosuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });
    const afterDeleted2 = dantorikosuuList.map((item) => {
      return {
        ...item,
        lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
      };
    });

    const updatedData = afterDeleted.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const updatedData2 = afterDeleted2.map((item) => {
      newId.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = kosuuDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = kosuuDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    const sortedList2 = updatedData2.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = kosuuDataTemp.findIndex((obj) => obj.id === a.key);
        const bIndex = kosuuDataTemp.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });
    setIsKosuu(false);
    setKosuuDataTemp(kosuuDataTemp);

    setKosuuList(sortedList);
    props.updatedKosuu(kosuuDataTemp);

    props.updatedKosuuList(sortedList);
    setDantoriKosuuList(sortedList2);
    props.updatedDantorisKosuuList(sortedList2);
  };
  const addKosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {kosuuDataTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleKosuuChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addKosuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteKosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addKosuuMasterData}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const changeKosuu = (event, rowIndex, colIndex) => {
    const copyData = [...kosuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setKosuuList(updatedData);
    props.updatedKosuuList(updatedData);
  };

  const addKosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>生産個数</p>
    </div>
  );

  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row className="chargeAmount">
          <Col style={{ width: category === 'ブランク' ? '20%' : '40%' }}>
            <Table className="tsuikakoutei">
              <thead>
                <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                  <th className="tbl-header" style={{ width: '20%' }}>
                    工程内検査時間（秒）
                  </th>
                  {category === 'ブランク' ? (
                    <></>
                  ) : (
                    <>
                      <th className="tbl-header" style={{ width: '20%' }}>
                        段取を個別に持つ
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <td>
                    <Row align="middle">
                      {!editMode ? (
                        <>
                          <Col span={24}>
                            <label>{setupTime}</label>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={10}>
                            <Input
                              value={setupTime}
                              onChange={(e) => {
                                setSetupTime(e.target.value);
                              }}
                            ></Input>
                          </Col>
                          <Col span={14}>
                            <CaretUpOutlined
                              style={{
                                fontSize: 16 + 'px',
                                marginLeft: 3,
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(setupTime, '10min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(setupTime, '10min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(setupTime, 'min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(setupTime, 'min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(setupTime, 'sec');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(setupTime, 'sec');
                                changeTime(editedTime);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </td>
                  {category === 'ブランク' ? (
                    <></>
                  ) : (
                    <>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="qty-table-container">
          <Table
            style={{
              width:
                kosuuData.filter((i) => i.displayFlag === true).length > 7
                  ? '100%'
                  : kosuuData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="fixedCol">
                <Row justify="center" className="tbl-header">
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={quantity} style={{ pointerEvents: 'auto' }}>
                        曲げ個数
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>曲げ個数</label>
                    </>
                  )}
                </Row>
              </td>

              {kosuuData
                .filter((subitem) => subitem.displayFlag === true)
                .map((item) => (
                  <td style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    {kosuuData[kosuuData.length - 1].id === item.id ? <>{item.val}個 ～</> : <>～{item.val}個</>}
                  </td>
                ))}
            </tr>

            {kosuuList.map((row, rowIndex) => (
              <tr style={{ textAlign: 'center' }}>
                <td>{row.materialType}</td>
                {row.lstData.map((col, colIndex) => (
                  <td style={{ width: '98px' }}>
                    <Input
                      value={col.value}
                      onChange={(e) => changeKosuu(e, rowIndex, colIndex)}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </Table>
        </div>
      </div>

      {/* 個数 */}
      {isKosuu
        ? commonModal(
            isKosuu,
            addKosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addKosuuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_ProcessInspection;
