/**
 * クラス名：材質区分情報一覧画面
 * 説明：iQ3板金に使用の材質区分情報一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SplitPane, { Pane } from 'split-pane-react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';

import MaterialClassDetail from './MaterialClassDetail';
import '../../../../assets/styles/common.css';
import 'split-pane-react/esm/themes/default.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmCreateContent,
  confirmDeleteContent,
  commonActionFooter,
  confirmUpdateContent,
} from '../../../common/CommonModal';
import { materialRefList, actionArr, dataStateList, getMaterialClassDataSample } from '../../../common/Common.js';

dayjs.extend(customParseFormat);
const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

const MaterialClassList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [paramSelected, setParamSelected] = useState('材質区分');
  const [paramType, setParamType] = useState('common');
  const [materialClassData, setMaterialClassData] = useState([]);
  const [tempMaterialClass, setTempMaterialClass] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState();

  const [dataState, setDataState] = useState();
  const [showFlag, setShowFlag] = useState(0);
  const [filterKey, setFilterKey] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [isFilter, setIsFilter] = useState(true);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');
  const [cancelConfirm, setCancelConfirm] = useState(false);

  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  useEffect(() => {
    const materialClassDataSample = getMaterialClassDataSample();
    setMaterialClassData(materialClassDataSample);
    setFilteredData(materialClassDataSample);
    setTempMaterialClass(materialClassDataSample);
    setSelectedRowKeys(materialClassDataSample[0].no);
    setSelectedData(materialClassDataSample[0]);
    // setSelectedRowKeys(materialClassDataSample[materialClassDataSample?.length - 1].no);
    // setSelectedData(materialClassDataSample[materialClassDataSample?.length - 1]);
    setSelectedRowKeys(1);
    setSelectedData(
      materialClassDataSample != undefined && materialClassDataSample.length > 0 ? materialClassDataSample[1] : []
    );
    props.setParam(paramType, paramSelected);
    setIsFilter(true);
  }, []);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordKey !== undefined) {
        setSelectedRowKeys(changeRecordKey);
        setSelectedData(filteredData.find((item) => item.no === changeRecordKey));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordKey === undefined) {
        return;
      } else {
        const action = actionArr.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5].includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      sorter: (a, b) => a.no - b.no,
      sortOrder: 'ascend',
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'displayFlag',
      title: '表示',
      dataIndex: 'displayFlag',
      width: 95,
      render: (text, record) => {
        return record.displayFlag && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
    },
    {
      key: 'classificationCode',
      title: 'コード',
      dataIndex: 'classificationCode',
      width: 120,
    },
    {
      key: 'classificationName',
      title: '材質区分名',
      dataIndex: 'classificationName',
      width: 150,
    },

    {
      key: 'materialRefPrice',
      title: '参考価格用材質引当',
      dataIndex: 'materialRefPrice',
      width: 150,
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
    },
    {
      key: 'remark',
      title: '備考',
      dataIndex: 'remark',
      width: 200,
    },
  ];

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  const addModalContent = (
    <div id="addNewMaterialClass" style={{ marginTop: 8 }}>
      <table className="table table-bordered" style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label>
                {/* {materialClassData.length + 1} */}
                {tempMaterialClass.no}
              </label>
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">表示</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
              <RadioGroup
                name="displayFlag"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                  }
                }}
                value={tempMaterialClass.displayFlag}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">コード</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.classificationCode}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, classificationCode: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材質区分名</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.classificationName}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, classificationName: e.target.value });
                  }
                }}
              />
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">参考価格用材質引当</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, materialRefPrice: e });
                  }
                }}
                id="materialRefDDL"
                name="materialRefDDL"
                defaultValue="defaultValue"
              >
                <Option id="defaultValue" value="defaultValue">
                  参考価格用材質を選択でください。
                </Option>
                {materialRefList.map((item) => (
                  <Option id={'materialRef' + item.value} value={item.label}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">作成日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{tempMaterialClass.created}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">更新日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{tempMaterialClass.modified}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">備考</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <TextArea
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.remark}
                id="remark"
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );

  let checkedRow = [];
  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setCheckedRowKeys(checkedRow);
    setChkData(checkedRow.length);
  };

  const rowSelection = {
    onChange: onRowCheckboxSelect,
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const gpEditModalContent = (
    <Form>
      <Row>
        <Col span="14">
          <p style={{ fontSize: '14px' }}>検索結果 {selectedRowKeys?.length} 件</p>
          <Table
            showSorterTooltip={false}
            dataSource={filteredData}
            columns={columns}
            scroll={{ y: 480, x: '49vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
          />
        </Col>
        <Col span="10">
          <div id="editGpDataTb" style={{ marginTop: 40 }}>
            <Form>
              <div
                style={{
                  height: 502,
                  marginTop: 28,
                  fontSize: '13.5px',
                  overflowY: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        表示
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
                        <RadioGroup
                          name="displayFlag"
                          defaultValue={true}
                          className="radioCustomerGpEdit"
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                            }
                          }}
                          value={tempMaterialClass.displayFlag}
                        >
                          <Radio value={true}>オン</Radio>
                          <Radio value={false}>オフ</Radio>
                        </RadioGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        材質区分名
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                        <Input
                          type="text"
                          data-name="name"
                          style={{ padding: 3 }}
                          value={tempMaterialClass.classificationName}
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, classificationName: e.target.value });
                            }
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        参考価格用材質引当
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                        <Select
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, materialRefPrice: e });
                            }
                          }}
                          id="materialRefDDL"
                          name="materialRefDDL"
                          /* value={tempMaterialClass.materialRefPrice} */
                          defaultValue="defaultValue"
                        >
                          <Option id="defaultValue" value="defaultValue">
                            参考価格用材質を選択でください。
                          </Option>
                          {materialRefList.map((item) => (
                            <option id={'materialRef' + item.value} value={item.label}>
                              {item.label}
                            </option>
                          ))}
                        </Select>
                      </td>
                    </tr>
                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        備考
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                        <TextArea
                          type="text"
                          data-name="name"
                          style={{ padding: 3 }}
                          id="remark"
                          value={tempMaterialClass.remark}
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                            }
                          }}
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div style={{ marginBottom: 0, marginTop: 0, textAlign: 'end' }}>
                <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
                  更新
                </Button>
                <Button className="cancelButton" onClick={groupEditCancel}>
                  破棄
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Form>
  );

  const onRowSelect = (record, index) => {
    setChangeRecordKey(record.no);
    //setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      setEditModeCancelConfirm(true);
    } else {
      setSelectedRowKeys(record.no);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':common.materialClass');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
  };

  const handleCancel = (e) => {
    //setCancelConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setVisibleCSVInput(false);
    setVisibleXMLInput(false);
  };

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey]);
      return newObj[objKey];
    }
  };

  const updConfirmOk = (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialClassData));
    if (visibleAddNewModal) {
      stateData.push(tempMaterialClass);
      setMaterialClassData(stateData);
      localStorage.setItem('materialClassStorageData', JSON.stringify(stateData));
      setTmpFilteredData(stateData);
      resetAddModal(stateData.length + 1);
      onRowSelect(tempMaterialClass, 0);
      setCurrentPage(Math.ceil(tempMaterialClass.no / 25));
      setVisibleAddNewModal(false);
    } else if (visibleGroupEditModal) {
      if (checkedRowKeys.length > 0) {
        const updatedData = stateData.map((obj) => {
          if (checkedRowKeys.includes(obj.key)) {
            return {
              ...obj,
              displayFlag: gpEditCheck(tempMaterialClass, obj, 'displayFlag'),
              classificationName: gpEditCheck(tempMaterialClass, obj, 'classificationName'),
              materialRefPrice: gpEditCheck(tempMaterialClass, obj, 'materialRefPrice'),
              remark: gpEditCheck(tempMaterialClass, obj, 'remark'),
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        setMaterialClassData(updatedData);
        //setFilteredData(updatedData);
        setTmpFilteredData(updatedData);
        localStorage.setItem('materialClassStorageData', JSON.stringify(updatedData));
      }
      setVisibleGroupEditModal(false);
    }
    setUpdateCancelConfirm(false);
    setIsFilter(true);
  };

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
    }
  };

  const updConfirmCancel = () => {
    /* setUpdateConfirm(false); */
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
    /* setVisibleGroupEditModal(true); */
  };

  const handleGpEdit = () => {
    setTempMaterialClass({
      displayFlag: true,
      classificationName: '',

      materialRefPrice: '',
      remark: '',
    });
    setVisibleGroupEditModal(true);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleAddFormSubmit();
    }
  };

  const showDeleteModal = () => {
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const handleAddFormSubmit = () => {
    console.log(materialClassData.length);
    setTempMaterialClass({
      key: materialClassData[materialClassData.length - 1].key + 1,
      no: materialClassData.length + 1,
      displayFlag: true,
      classificationCode: (parseFloat(materialClassData[materialClassData.length - 1].classificationCode) + 1)
        .toString()
        .padStart(3, '0'),
      classificationName: '',

      materialRefPrice: ' ',
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
      remark: '',
    });
    setVisibleAddNewModal(true);
    setChildTitle('材質区分');
  };

  const deleteOK = () => {
    setVisibleDeleteModal(false);
    let data = [...materialClassData];
    data = data.filter((item) => item.key != selectedData.key);

    for (let i = 0; i < data.length; i++) {
      data[i].no = i + 1;
    }
    setMaterialClassData(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    localStorage.setItem('materialClassStorageData', JSON.stringify(data));
    setSelectedRowKeys(data.length > 0 ? data[data.length - 1].no : -1);
    setSelectedData(data.length > 0 ? data[data.length - 1] : []);
    setIsFilter(true);
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const resetAddModal = (count) => {
    setTempMaterialClass({
      key: count,
      displayFlag: false,
      classificationCode: (count + 1).toString().padStart(3, '0'),
      classificationName: '',
      materialRefPrice: '',
      remark: '',
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    getDataByFilter();
  }, [nameKeyword, modelKeyword, dataState, showFlag, tmpFilteredData]);

  // 型番のキーワードで絞り込み無効フラグの押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // 無効フラグの押下
  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  const beforeSearch = (e) => {
    {
      let action;
      setIsFilter(true);
      switch (typeof e) {
        //searchByKeyword
        case 'object':
          action = actionArr[1];
          break;
        //材質ddl
        case 'string':
          action = actionArr[4];
          break;
        //表示.非表示
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialClassData.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let currentData = [...materialClassData];
    let flag;
    let materialFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData.filter(
        (item) => item.classificationName?.toString().indexOf(nKeyword) >= 0
      );
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData.filter((item) => item.displayFlag === flag);
    }

    //参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData.filter((item) => item.materialRefPrice === dataState);
    }
    setFilteredData(materialFilterData);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = (updatedData) => {
    var tmpData = [...materialClassData];
    var index = tmpData.findIndex((item) => item.key === updatedData.key);
    tmpData[index] = updatedData;
    setMaterialClassData(tmpData);
    setTmpFilteredData(tmpData);
    setIsFilter(false);
    setSelectedRowKeys(updatedData.no);
    setSelectedData(updatedData);
    localStorage.setItem('materialClassStorageData', JSON.stringify(tmpData));
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Select
                    style={{ width: 90 }}
                    onChange={beforeSearch /* searchByShowFlg */}
                    id="showFlgDrop"
                    name="showFlgDrop"
                    value={showFlag}
                  >
                    {dataStateList.map((item) => (
                      <option id={'showFlg' + item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <Input
                    className="input-editable search-select-input"
                    placeholder="材質区分名"
                    allowClear
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch(e);
                      }
                    }}
                    onPressEnter={(e) => {
                      beforeSearch(e);
                    }}
                    onBlur={(e) => {
                      beforeSearch(e);
                    }}
                    name="name"
                    id="name"
                  />

                  <Select
                    className="input-editable search-select-input"
                    onChange={beforeSearch /* searchByDataState */}
                    id="materialRefDDL"
                    name="materialRefDDL"
                    value={dataState}
                    defaultValue={'全て'}
                  >
                    <Option id="allOption" value={'全て'}>
                      {'全ての参考価格用材質'}
                    </Option>
                    {materialRefList.map((item) => (
                      <Option id={'materialRef' + item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '10vw' }}
                    rowClassName={(record, index) => {
                      return record.no === selectedRowKeys ? 'active-row' : 'data-row';
                    }}
                    rowKey={(record) => record.no}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      defaultPageSize: 25,
                      defaultCurrent: 1,
                      pageSize: 25,
                      current: currentPage,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      /* console.log(index); */
                      return {
                        onClick: (event) => {
                          onRowSelect(record, index);
                        },
                      };
                    }}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":common.materialClass"
                element={
                  <MaterialClassDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialClassData.find((element) => element.no === selectedRowKeys)}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    itemNo={itemNo}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材質区分（選択したデータをまとめて編集）', 1300, -24),
          /* commonActionFooter(groupEditOK, groupEditCancel, '更新', '破棄'), */
          null,
          null,
          1300,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          false
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質区分（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialClassList;
