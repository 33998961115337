/**
 * クラス名：板金子部品見積材料詳細
 * 説明：板板金子部品見積材料詳細ファイルである。
 * 作成者：ケイ
 * 作成日：2023/06/29
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { Table, Row, Col, Select, Button, Input, Popover, Image } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table as RTable } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { RightCircleFilled, LeftCircleFilled } from '@ant-design/icons';

import pattern1 from '../../assets/images/pattern1.png';
import pattern2 from '../../assets/images/pattern2.png';
import outerJustify from '../../assets/images/outerJustify.png';
import innerJustify from '../../assets/images/innerJustify.png';
import leftRightFit from '../../assets/images/leftRightFit.png';
import topBottomFit from '../../assets/images/topBottomFit.png';
import { JPYs, Percent, toDecimal, getMaterialSizeStorage } from '../common/Common';
import { IQ3MaterialDetailData } from '../common/Constant';
import {
  commonModal,
  commonFooter,
  confirmModalTitle,
  confirmUpdateContent,
  confirmDiscardContent,
} from '../common/CommonModal';

// 材料名称
const materialNameList = [
  { id: 1, name: 'SPCH(黒皮) - 1.6' },
  { id: 2, name: 'SPHC(黒皮) - 2.3' },
  { id: 3, name: 'SPCH(黒皮) - 3.2' },
];

// 材質
const materialList = [
  { id: 1, name: '01_SPCC(黒皮)' },
  { id: 2, name: '02_SPCC(黒皮)' },
  { id: 3, name: '03_SPCC(黒皮)' },
];

// 板厚
const thicknessList = [
  { id: 1, name: '0.9' },
  { id: 2, name: '2.3' },
  { id: 3, name: '4.5' },
];

const IQ3MaterialDetail = forwardRef((props, ref) => {
  const pattern = ['パターン1', 'パターン2'];

  const [editMode, setEditMode] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [materialSizeData, setMaterialSizeData] = useState([]);

  const [activePattern, setActivePattern] = useState('');

  // 情報項目
  const [partName, setPartName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [xSize, setXSize] = useState('');
  const [ySize, setYSize] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [material, setMaterial] = useState('');
  const [thickness, setThickness] = useState('');
  const [surface, setSurface] = useState('');
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [xGrabAllowance, setXGrabAllowance] = useState('');
  const [yGrabAllowance, setYGrabAllowance] = useState('');
  const [xWidthOfPier, setXWidthOfPier] = useState('');
  const [yWidthOfPier, setYWidthOfPier] = useState('');
  const [xMachiningRange, setXMachiningRange] = useState('');
  const [yMachiningRange, setYMachiningRange] = useState('');

  // 計算結果項目
  const [summarizeTotalAmount, setSummarizeTotalAmount] = useState('');
  const [summarizeYield, setSummarizeYield] = useState('');
  const [summarizeNumOfMaterial, setSummarizeNumOfMaterial] = useState('');
  const [summarizePrice, setSummarizePrice] = useState('');
  const [summarizeSurface, setSummarizeSurface] = useState('');
  const [summarizeWeight, setSummarizeWeight] = useState('');
  const [summarizeMaterialPrice, setSummarizeMaterialPrice] = useState('');
  const [summarizeCostRate, setSummarizeCostRate] = useState('');
  const [summarizeScrapAmount, setSummarizeScrapAmount] = useState('');

  // パターン1項目
  const [pattern1TotalAmount, setPattern1TotalAmount] = useState('');
  const [pattern1Yield, setPattern1Yield] = useState('');
  const [pattern1NumOfMaterial, setPattern1NumOfMaterial] = useState('');
  const [pattern1XSize, setPattern1XSize] = useState('');
  const [pattern1YSize, setPattern1YSize] = useState('');
  const [pattern1MaterialName, setPattern1MaterialName] = useState('');

  // パターン2項目
  const [pattern2TotalAmount, setPattern2TotalAmount] = useState('');
  const [pattern2Yield, setPattern2Yield] = useState('');
  const [pattern2NumOfMaterial, setPattern2NumOfMaterial] = useState('');
  const [pattern2XSize, setPattern2XSize] = useState('');
  const [pattern2YSize, setPattern2YSize] = useState('');
  const [pattern2MaterialName, setPattern2MaterialName] = useState('');

  const [selectedRowId, setSelectedRowId] = useState();

  const [isPatternDetail, setIsPatternDetail] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);

  useEffect(() => {
    let materialSizeDataSample = getMaterialSizeStorage();
    materialSizeDataSample = materialSizeDataSample.map((item) => {
      return { id: item.no, name: item.materialSizeName };
    });
    setMaterialSizeData(materialSizeDataSample);
    const childPartDetail = props.selectedIQ3DataDetail;
    setDetailData({ ...childPartDetail });
    setEditMode(props.editMode != undefined ? props.editMode : false);
    setMaterialDetailInfo();
    setSummarizeInfo();
    setPattern1Info();
    setPattern2Info();
    setSelectedRowId(2);
  }, [props.selectedIQ3DataDetail, props.editMode]);

  useImperativeHandle(
    ref,
    () => ({
      getIQ3MaterialDetailInfo: () => {
        return detailData;
      },
    }),
    [detailData]
  );

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 50 + 'px',
      render: (id, record, index) => index + 1,
    },
    {
      id: '2',
      title: '材料サイズ',
      dataIndex: 'size',
      width: 100 + 'px',
      className: 'cm-a-center',
      render: (value) => materialSizeData.filter((item) => item.id === value)?.[0]?.name,
    },
    {
      id: '3',
      title: '材料枚数',
      dataIndex: 'quantity',
      width: 100 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '4',
      title: '金額',
      dataIndex: 'price',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => JPYs.format(value),
    },
    {
      id: '5',
      title: '歩留まり',
      dataIndex: 'yield',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => Percent.format(value / 100),
    },
    {
      id: '6',
      title: 'パターン1',
      dataIndex: 'pattern1',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : Percent.format(value / 100)),
    },
    {
      id: '7',
      title: '配置数',
      dataIndex: 'p1PlacementNum',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : value),
    },
    {
      id: '8',
      title: 'パターン2',
      dataIndex: 'pattern2',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : Percent.format(value / 100)),
    },
    {
      id: '9',
      title: '配置数',
      dataIndex: 'p2PlacementNum',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : value),
    },
    {
      id: '10',
      title: '最大歩留まり',
      dataIndex: 'maxYield',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => Percent.format(value / 100),
    },
    {
      id: '11',
      title: '最大加工数',
      dataIndex: 'maxProcessNum',
      width: 100 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '12',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ].filter((item) => !item.hidden);

  // 情報の値
  const setMaterialDetailInfo = () => {
    setPartName('ISZ-HRK1-1300-01');
    setQuantity('165(15)');
    setXSize(toDecimal(200));
    setYSize(toDecimal(100));
    setMaterialName(2);
    setMaterial(1);
    setThickness(2);
    setSurface('None');
    setWeight(0.362);
    setSurfaceArea(toDecimal(200, 2));
    setXGrabAllowance(toDecimal(30));
    setYGrabAllowance(toDecimal(80));
    setXWidthOfPier(toDecimal(10));
    setYWidthOfPier(toDecimal(10));
    setXMachiningRange(2500);
    setYMachiningRange(1250);
  };

  // 計算結果の値
  const setSummarizeInfo = () => {
    setSummarizeTotalAmount(JPYs.format(6480));
    setSummarizeYield(Percent.format(5.2 / 100));
    setSummarizeNumOfMaterial(1);
    setSummarizePrice(JPYs.format(120));
    setSummarizeSurface(JPYs.format(0));
    setSummarizeWeight(JPYs.format(0));
    setSummarizeMaterialPrice(JPYs.format(6480));
    setSummarizeCostRate(Percent.format(50 / 100));
    setSummarizeScrapAmount(JPYs.format(500));
  };

  // パターン1の値
  const setPattern1Info = () => {
    setPattern1TotalAmount(JPYs.format(5400));
    setPattern1Yield(Percent.format(74.0 / 100));
    setPattern1NumOfMaterial(1);
    setPattern1MaterialName(3);
    setPattern1XSize('2438.0mm');
    setPattern1YSize('1219.0mm');
  };

  // パターン2の値
  const setPattern2Info = () => {
    setPattern2TotalAmount(JPYs.format(5400));
    setPattern2Yield(Percent.format(37.0 / 100));
    setPattern2NumOfMaterial(1);
    setPattern2MaterialName(3);
    setPattern2XSize('2438.0mm');
    setPattern2YSize('1219.0mm');
  };

  // パターン1の詳細情報
  const callPattern1Detail = () => {
    setIsPatternDetail(true);
    setChildTitle('詳細');
    setActivePattern(pattern[0]);
  };

  // パターン2の詳細情報
  const callPattern2Detail = () => {
    setIsPatternDetail(true);
    setChildTitle('詳細');
    setActivePattern(pattern[1]);
  };

  // スクラップ金額の詳細
  const scrapAmount = (
    <div>
      <p>
        スクラップ金額
        <br /> {JPYs.format(500)}
      </p>
    </div>
  );

  // idで名称を取得する
  const getNameById = (id, dataList) => {
    let data = dataList.filter((item) => item.id === id);
    if (data?.length > 0) {
      return data[0]?.name;
    } else {
      return '';
    }
  };

  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList).filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };

  // パターン詳細情報を更新
  const patternUpdateOK = () => {
    setUpdateConfirm(true);
  };

  // パターン詳細情報をキャンセル
  const patternUpdateCancel = (e) => {
    setCancelConfirm(true);
  };

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isPatternDetail) {
      setUpdateConfirm(false);
      setIsPatternDetail(false);
    }
  };

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isPatternDetail) {
      setIsPatternDetail(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // パターン詳細情報の切り替え
  const changePattern = () => {
    if (activePattern === pattern[0]) {
      setActivePattern(pattern[1]);
    } else {
      setActivePattern(pattern[0]);
    }
  };

  // 入力項目情報
  const inputFormItem = (
    <>
      <div style={{ width: '100%' }}>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col">
            <div className="cm-a-center">
              <label style={{ marginLeft: 0 }}>入力項目</label>
            </div>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <RTable className="iq3material-detail">
                <thead>
                  <tr>
                    <th width={'18%'}>材料名称</th>
                    <th width={'18%'}>材質</th>
                    <th width={'18%'}>板厚</th>
                    <th width={'18%'}>表面属性</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 材料名称 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={materialName}
                            onChange={(e) => setMaterialName(e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialNameList?.map((item) => (
                              <Option value={item.id}>{item.name}</Option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(materialName, materialNameList)}</>
                      )}
                    </td>
                    {/* 材質 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={material}
                            onChange={(e) => setMaterial(e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialList?.map((item) => (
                              <Option value={item.id}>{item.name}</Option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(material, materialList)}</>
                      )}
                    </td>
                    {/* 板厚 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={thickness}
                            onChange={(e) => setThickness(e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {thicknessList?.map((item) => (
                              <Option value={item.id}>{item.name}</Option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(thickness, thicknessList)}</>
                      )}
                    </td>
                    {/* 表面属性 */}
                    <td>
                      <Input
                        name="surface"
                        id="surface"
                        className={editMode ? 'input-editable ' : 'input-non-editable '}
                        value={surface}
                        onChange={(e) => setSurface(e.target.value)}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th width={'18%'}>掴み代X</th>
                    <th width={'18%'}>掴み代Y</th>
                    <th width={'18%'}>桟幅X</th>
                    <th width={'18%'}>桟幅Y</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 掴み代X */}
                    <td>
                      <CurrencyInput
                        name="xGrabAllowance"
                        id="xGrabAllowance"
                        value={xGrabAllowance}
                        onValueChange={(e) => setXGrabAllowance(e)}
                        decimalsLimit={100}
                        className={getAntInputBoxClassName('surface', editMode)}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 掴み代Y */}
                    <td>
                      <CurrencyInput
                        name="yGrabAllowance"
                        id="yGrabAllowance"
                        value={yGrabAllowance}
                        onValueChange={(e) => setYGrabAllowance(e)}
                        decimalsLimit={100}
                        className={getAntInputBoxClassName('surface', editMode)}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 桟幅X */}
                    <td>
                      <CurrencyInput
                        name="xWidthOfPier"
                        id="xWidthOfPier"
                        value={xWidthOfPier}
                        onValueChange={(e) => setXWidthOfPier(e)}
                        decimalsLimit={100}
                        className={getAntInputBoxClassName('surface', editMode)}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 桟幅Y */}
                    <td>
                      <CurrencyInput
                        name="yWidthOfPier"
                        id="yWidthOfPier"
                        value={yWidthOfPier}
                        onChange={(e) => setYWidthOfPier(e)}
                        decimalsLimit={100}
                        className={getAntInputBoxClassName('surface', editMode)}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Col>
          </Row>
        </div>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={8} className="deli-col">
            <div style={{ display: activePattern === pattern[0] ? 'none' : 'block' }}>
              <LeftCircleFilled onClick={changePattern} style={{ fontSize: 20, verticalAlign: 'text-top' }} />
              &nbsp;
              <label>{pattern[0] + '(' + pattern1Yield + ')'}</label>
            </div>
          </Col>
          <Col span={8} className="deli-col">
            <div className="cm-a-center">
              <label>{activePattern}</label>
            </div>
          </Col>
          <Col span={8} className="deli-col">
            <div style={{ display: activePattern === pattern[1] ? 'none' : 'block' }} className="cm-a-right">
              <label>{pattern[1] + '(' + pattern2Yield + ')'}</label>
              &nbsp;
              <RightCircleFilled onClick={changePattern} style={{ fontSize: 20, verticalAlign: 'text-top' }} />
            </div>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <RTable className="iq3material-detail">
                <thead>
                  <tr>
                    <th width={'33.33%'}>合計金額</th>
                    <th width={'33.33%'}>歩留まり</th>
                    <th width={'33.33%'}>材料枚数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 合計金額 */}
                    <td>{activePattern === pattern[0] ? pattern1TotalAmount : pattern2TotalAmount}</td>
                    {/* 歩留まり */}
                    <td>{activePattern === pattern[0] ? pattern1Yield : pattern2Yield}</td>
                    {/* 材料枚数 */}
                    <td>{activePattern === pattern[0] ? pattern1NumOfMaterial : pattern2NumOfMaterial}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th width={'33.33%'}>材料名称</th>
                    <th width={'33.33%'}>Xサイズ</th>
                    <th width={'33.33%'}>Yサイズ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 材料名称 */}
                    <td>
                      <Select
                        value={activePattern === pattern[0] ? pattern1MaterialName : pattern2MaterialName}
                        onChange={(e) =>
                          activePattern === pattern[0] ? setPattern1MaterialName(e) : setPattern2MaterialName(e)
                        }
                        style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                      >
                        {materialSizeData?.map((item) => (
                          <Option value={item.id}>{item.name}</Option>
                        ))}
                      </Select>
                    </td>
                    {/* Xサイズ */}
                    <td>
                      <Input
                        name={activePattern === pattern[0] ? 'pattern1XSize' : 'pattern2XSize'}
                        id="xSize"
                        className={'input-editable '}
                        value={activePattern === pattern[0] ? pattern1XSize : pattern2XSize}
                        onChange={(e) =>
                          activePattern === pattern[0]
                            ? setPattern1XSize(e.target.value)
                            : setPattern2XSize(e.target.value)
                        }
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* Yサイズ */}
                    <td>
                      <Input
                        name={activePattern === pattern[0] ? 'pattern1YSize' : 'pattern2YSize'}
                        id="xSize"
                        className={'input-editable '}
                        value={activePattern === pattern[0] ? pattern1YSize : pattern2YSize}
                        onChange={(e) =>
                          activePattern === pattern[0]
                            ? setPattern1YSize(e.target.value)
                            : setPattern2YSize(e.target.value)
                        }
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={3} className="deli-col" style={{ display: 'grid' }}>
            <Image preview={false} width={50} src={outerJustify} style={{ marginLeft: '5px', marginTop: '15px' }} />
            <Image preview={false} width={50} src={innerJustify} style={{ marginLeft: '5px' }} />
            <Image preview={false} width={50} src={leftRightFit} style={{ marginLeft: '5px' }} />
            <Image preview={false} width={50} src={topBottomFit} style={{ marginLeft: '5px' }} />
          </Col>
          <Col span={21} className="deli-col">
            <Image
              width={'100%'}
              style={{ border: '1px solid rgb(165, 165, 165)', marginLeft: '3px' }}
              preview={false}
              src={activePattern === pattern[0] ? pattern1 : pattern2}
            />
          </Col>
        </Row>
      </div>
    </>
  );

  // パターン詳細情報の入力項目
  const patternContent = (
    <>
      <Row style={{ padding: '0px 15px 0px 15px' }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 670,
              fontSize: '13.5px',
              // overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <Button className="mainButton" onClick={patternUpdateOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={patternUpdateCancel}>
          破棄
        </Button>
      </Row>
    </>
  );

  // パターン詳細情報のタイトル
  const patternTitle = (
    <div
      style={{
        width: 650,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    setSelectedRowId(record.id);
  };

  return (
    <>
      <div style={{ display: 'flex', float: 'left', width: '100%' }}>
        {/* 左 */}
        <div style={{ width: '60%' }}>
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center">
                <label style={{ marginLeft: 0 }}>情報</label>
              </div>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th colSpan={3} width={'55%'}>
                        部品名
                      </th>
                      <th width={'15%'}>製造個数(員数)</th>
                      <th width={'15%'}>Xサイズ</th>
                      <th width={'15%'}>Yサイズ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 部品名 */}
                      <td colSpan={3}>{partName}</td>
                      {/* 製造個数(員数) */}
                      <td>{quantity}</td>
                      {/* Xサイズ */}
                      <td>{xSize}</td>
                      {/* Yサイズ */}
                      <td>{ySize}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'18%'}>材料名称</th>
                      <th width={'18%'}>材質</th>
                      <th width={'18%'}>板厚</th>
                      <th width={'15%'}>表面属性</th>
                      <th width={'15%'}>重量(kg)</th>
                      <th width={'15%'}>表面積(c㎡)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料名称 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={materialName}
                              onChange={(e) => setMaterialName(e)}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {materialNameList?.map((item) => (
                                <Option value={item.id}>{item.name}</Option>
                              ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(materialName, materialNameList)}</>
                        )}
                      </td>
                      {/* 材質 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={material}
                              onChange={(e) => setMaterial(e)}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {materialList?.map((item) => (
                                <Option value={item.id}>{item.name}</Option>
                              ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(material, materialList)}</>
                        )}
                      </td>
                      {/* 板厚 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={thickness}
                              onChange={(e) => setThickness(e)}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {thicknessList?.map((item) => (
                                <Option value={item.id}>{item.name}</Option>
                              ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(thickness, thicknessList)}</>
                        )}
                      </td>
                      {/* 表面属性 */}
                      <td>
                        <Input
                          name="surface"
                          id="surface"
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          value={surface}
                          onChange={(e) => setSurface(e.target.value)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 重量(kg) */}
                      <td>{weight}</td>
                      {/* 表面積(c㎡) */}
                      <td>{surfaceArea}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'18%'}>掴み代X</th>
                      <th width={'18%'}>掴み代Y</th>
                      <th width={'18%'}>桟幅X</th>
                      <th width={'15%'}>桟幅Y</th>
                      <th width={'15%'}>加工範囲 X</th>
                      <th width={'15%'}>加工範囲 Y</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 掴み代X */}
                      <td>
                        <CurrencyInput
                          name="xGrabAllowance"
                          id="xGrabAllowance"
                          value={xGrabAllowance}
                          onValueChange={(e) => setXGrabAllowance(e)}
                          decimalsLimit={100}
                          className={getAntInputBoxClassName('surface', editMode)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 掴み代Y */}
                      <td>
                        <CurrencyInput
                          name="yGrabAllowance"
                          id="yGrabAllowance"
                          value={yGrabAllowance}
                          onValueChange={(e) => setYGrabAllowance(e)}
                          decimalsLimit={100}
                          className={getAntInputBoxClassName('surface', editMode)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 桟幅X */}
                      <td>
                        <CurrencyInput
                          name="xWidthOfPier"
                          id="xWidthOfPier"
                          value={xWidthOfPier}
                          onValueChange={(e) => setXWidthOfPier(e)}
                          decimalsLimit={100}
                          className={getAntInputBoxClassName('surface', editMode)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 桟幅Y */}
                      <td>
                        <CurrencyInput
                          name="yWidthOfPier"
                          id="yWidthOfPier"
                          value={yWidthOfPier}
                          onChange={(e) => setYWidthOfPier(e)}
                          decimalsLimit={100}
                          className={getAntInputBoxClassName('surface', editMode)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 加工範囲 X */}
                      <td>{xMachiningRange}</td>
                      {/* 加工範囲 Y */}
                      <td>{yMachiningRange}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <div className="cm-a-center">
                <Table
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={IQ3MaterialDetailData}
                  scroll={{ y: 350, x: '10vw' }}
                  rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                  className="iq3material-detail-list-table"
                  pagination={false}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* 右 */}
        <div style={{ marginLeft: '10px', width: '40%' }}>
          {/* 計算結果 */}
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center">
                <label style={{ marginLeft: 0 }}>計算結果</label>
              </div>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th width={'33.33%'}>合計金額</th>
                      <th width={'33.33%'}>歩留まり</th>
                      <th width={'33.33%'}>材料枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 合計金額 */}
                      <td>{summarizeTotalAmount}</td>
                      {/* 歩留まり */}
                      <td>{summarizeYield}</td>
                      {/* 材料枚数 */}
                      <td>{summarizeNumOfMaterial}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>重量単価</th>
                      <th width={'33.33%'}>表面加算</th>
                      <th width={'33.33%'}>重量加算</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 重量単価 */}
                      <td>{summarizePrice}</td>
                      {/* 表面加算 */}
                      <td>{summarizeSurface}</td>
                      {/* 重量加算 */}
                      <td>{summarizeWeight}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料単価(1部品当たり)</th>
                      <th width={'33.33%'}>原価率(1部品当たり)</th>
                      <th width={'33.33%'} className="formula-column">
                        <Popover content={scrapAmount}>スクラップ金額</Popover>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料単価(1部品当たり) */}
                      <td>{summarizeMaterialPrice}</td>
                      {/* 原価率(1部品当たり) */}
                      <td>{summarizeCostRate}</td>
                      {/* スクラップ金額 */}
                      <td>{summarizeScrapAmount}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>

          {/* パターン1 */}
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center">
                {editMode ? (
                  <Button className={'pattern-btn'} id="pattern1Btn" onClick={callPattern1Detail}>
                    {pattern[0]}
                  </Button>
                ) : (
                  <label style={{ marginLeft: 0 }}>{pattern[0]}</label>
                )}
              </div>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th width={'33.33%'}>合計金額</th>
                      <th width={'33.33%'}>歩留まり</th>
                      <th width={'33.33%'}>材料枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 合計金額 */}
                      <td>{pattern1TotalAmount}</td>
                      {/* 歩留まり */}
                      <td>{pattern1Yield}</td>
                      {/* 材料枚数 */}
                      <td>{pattern1NumOfMaterial}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料名称</th>
                      <th width={'33.33%'}>Xサイズ</th>
                      <th width={'33.33%'}>Yサイズ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料名称 */}
                      <td>{getNameById(pattern1MaterialName, materialSizeData)}</td>
                      {/* Xサイズ */}
                      <td>{pattern1XSize}</td>
                      {/* Yサイズ */}
                      <td>{pattern1YSize}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>

          {/* パターン2 */}
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center">
                {editMode ? (
                  <Button className={'pattern-btn'} id="pattern1Btn" onClick={callPattern2Detail}>
                    {pattern[1]}
                  </Button>
                ) : (
                  <label style={{ marginLeft: 0 }}>{pattern[1]}</label>
                )}
              </div>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th width={'33.33%'}>合計金額</th>
                      <th width={'33.33%'}>歩留まり</th>
                      <th width={'33.33%'}>材料枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 合計金額 */}
                      <td>{pattern2TotalAmount}</td>
                      {/* 歩留まり */}
                      <td>{pattern2Yield}</td>
                      {/* 材料枚数 */}
                      <td>{pattern2NumOfMaterial}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料名称</th>
                      <th width={'33.33%'}>Xサイズ</th>
                      <th width={'33.33%'}>Yサイズ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料名称 */}
                      <td>{getNameById(pattern2MaterialName, materialSizeData)}</td>
                      {/* Xサイズ */}
                      <td>{pattern2XSize}</td>
                      {/* Yサイズ */}
                      <td>{pattern2YSize}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* パターン詳細情報 */}
      {isPatternDetail
        ? commonModal(
            isPatternDetail,
            patternTitle,
            null,
            null,
            650,
            patternUpdateOK,
            patternUpdateCancel,
            patternContent,
            'iq3material-pattern-detail',
            null,
            true
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default IQ3MaterialDetail;
