/**
 * クラス名：板金の装置設定のガス係数画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space } from 'antd';
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { commonModal } from '../../../common/CommonModal';
import { deleteListColData, updateListColData, sortListData } from '../../../common/Common';

const Device_Blank_GasKeisuu = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isGasType, setIsGasType] = useState(false);

  // ガス種類
  const [gasTypeTemp, setGasTypeTemp] = useState([]);
  const [gasTypeData, setGasTypeData] = useState([]);
  const [gasKeisuuListData, setGasKeisuuListData] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    setGasTypeData(props.gasTypeData);
    setGasTypeTemp(props.gasTypeData);

    setGasKeisuuListData(props.gasKeisuuList);
  }, [props.processName, props.editMode, props.gasTypeData, props.gasKeisuuList]);

  const addGasTypeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>ガス種類</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isGasType === true) {
      setIsGasType(false);
    }
  };
  const addGasType = (id) => {
    const copyData = gasTypeTemp.slice(); // Create a copy of the original array

    const insertIndex = gasTypeTemp.findIndex((item) => item.id === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        id: gasTypeTemp.length + 1,
        val: '',
        displayFlag: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setGasTypeTemp(copyData);
    }
  };
  const deleteGasType = (id, e) => {
    if (gasTypeTemp.filter((item) => item.displayFlag === true).length > 2) {
      const updatedData = gasTypeTemp.map((row) => {
        if (row.id === id) {
          return { ...row, displayFlag: false };
        }
        return row;
      });
      setGasTypeTemp(updatedData);
    }
  };

  const handleGasTypeChange = (event, id, field) => {
    const updatedData = gasTypeTemp.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setGasTypeTemp(updatedData);
  };

  const cancelMasterData = () => {
    setGasTypeTemp(gasTypeData);
    setIsGasType(false);
  };
  const addGasTypeMaster = () => {
    setIsGasType(false);

    const deletedId = [];

    gasTypeData.forEach((item1, index) => {
      const found = gasTypeTemp.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = gasTypeTemp
      .filter((item) => item.displayFlag && !gasTypeData.some((data2Item) => data2Item.id === item.id))
      .map((item) => item.id);

    const deletedData = deleteListColData(gasKeisuuListData, deletedId);

    const updatedData = updateListColData(deletedData, newId);

    const sortedList = sortListData(updatedData, gasTypeTemp);

    setGasTypeData(gasTypeTemp);

    setGasKeisuuListData(sortedList);
    props.updatedGasType(gasTypeTemp);

    props.updateGasKeisuuList(sortedList);
  };

  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {gasTypeTemp
                  .filter((subitem) => subitem.displayFlag === true)
                  .map((item, index) => (
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleGasTypeChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addGasType(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteGasType(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addGasTypeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const gasTypeModal = () => {
    setIsGasType(true);
  };
  const gasKeisuuListDataDataChange = (event, rowIndex, colIndex) => {
    const copyData = [...gasKeisuuListData];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    setGasKeisuuListData(updatedData);

    props.updateGasKeisuuList(updatedData);
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              gasTypeData.filter((i) => i.displayFlag === true).length > 8
                ? '100%'
                : gasTypeData.filter((i) => i.displayFlag === true).length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  <label>係数</label>
                  <label>/</label>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={gasTypeModal} style={{ pointerEvents: 'auto' }}>
                        ガス種類
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>ガス種類</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {gasTypeData
              .filter((subitem) => subitem.displayFlag === true)
              .map((item) => (
                <td style={{ backgroundColor: '#1063aa', width: '98px' }}>{item.val}</td>
              ))}
          </tr>

          {gasKeisuuListData.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td style={{ color: 'white', width: '196px' }} className="fixedCol">
                {row.materialType}
              </td>

              {row.lstData.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => gasKeisuuListDataDataChange(e, rowIndex, colIndex)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>

      {/* ガス種類 */}
      {isGasType
        ? commonModal(
            isGasType,
            addGasTypeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_GasKeisuu;
