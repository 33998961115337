export const parent_processPatternMasterList = [
  {
    key: 0,
    patternNo: 1,
    patternName: '親部品_標準',
    patternProcess: [
      {
        key: 0,
        processType: '溶接',
        processName: '溶接',
        checked: true,
        deviceCodeKey: 1,
        deviceName: '溶接_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 1,
        processType: '溶接仕上',
        processName: '溶接仕上',
        checked: true,
        deviceCodeKey: 2,
        deviceName: '溶接仕上_標準',
        numberOfProcess: 10,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '0',
            processDetailCode: '002',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '0',
            processDetailCode: '003',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 4,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 5,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 6,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 7,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 8,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 9,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 2,
        processType: '表面処理',
        processName: '表面処理',
        checked: true,
        deviceCodeKey: 3,
        deviceName: '表面処理_標準',
        numberOfProcess: 6,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '1',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '4',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 4,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 5,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 3,
        processType: '組立',
        processName: '組立',
        checked: true,
        deviceCodeKey: 4,
        deviceName: '組立_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 4,
        processType: '検査',
        processName: '検査',
        checked: true,
        deviceCodeKey: 5,
        deviceName: '検査_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 5,
        processType: '梱包',
        processName: '梱包',
        checked: true,
        deviceCodeKey: 6,
        deviceName: '梱包_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 6,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 14,
        deviceName: '社内工程_1',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 7,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 15,
        deviceName: '社内工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 8,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 16,
        deviceName: '社内工程_3',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 9,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 17,
        deviceName: '社内工程_4',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '社内工程_4',
      },
      {
        key: 10,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 18,
        deviceName: '社内工程_5',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '社内工程_5',
      },
      {
        key: 11,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 19,
        deviceName: '社外工程_1',
        processDetailLst: [],
        companyCode: '00013',
        itakuFlag: true,
        addProType: '仮付',
      },
      {
        key: 12,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: 0,
        deviceCodeKey: 20,
        deviceName: '社外工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 13,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 21,
        deviceName: '社外工程_3',
        processDetailLst: [],
        companyCode: '00014',
        itakuFlag: true,
        addProType: '水張検査',
      },
      {
        key: 14,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 15,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 16,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 17,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 18,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 19,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 20,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 21,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 22,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
    ],
    created: '2023/07/06',
    modified: '2023/07/06',
  },
  {
    key: 1,
    patternNo: 2,
    patternName: '親部品_特殊',
    patternProcess: [
      {
        key: 0,
        processType: '溶接',
        processName: '溶接',
        checked: true,
        deviceCodeKey: 8,
        deviceName: '溶接_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 1,
        processType: '溶接仕上',
        processName: '溶接仕上',
        checked: true,
        deviceCodeKey: 9,
        deviceName: '溶接仕上_標準',
        numberOfProcess: 10,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '0',
            processDetailCode: '002',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '0',
            processDetailCode: '003',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 4,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 5,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 6,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 7,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 8,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 9,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 2,
        processType: '表面処理',
        processName: '表面処理',
        checked: true,
        deviceCodeKey: 10,
        deviceName: '表面処理_標準',
        numberOfProcess: 6,
        processDetailLst: [
          {
            key: 0,
            processDetailType: '1',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 1,
            processDetailType: '4',
            processDetailCode: '001',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 2,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 3,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 4,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
          {
            key: 5,
            processDetailType: '0',
            processDetailCode: '000',
            displayFlg: true,
            useFlg: false,
          },
        ],
        companyCode: '',
        addProType: '',
      },
      {
        key: 3,
        processType: '組立',
        processName: '組立',
        checked: true,
        deviceCodeKey: 11,
        deviceName: '組立_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 4,
        processType: '検査',
        processName: '検査',
        checked: true,
        deviceCodeKey: 12,
        deviceName: '検査_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 5,
        processType: '梱包',
        processName: '梱包',
        checked: true,
        deviceCodeKey: 13,
        deviceName: '梱包_標準',
        processDetailLst: [],
        companyCode: '',
        addProType: '',
      },
      {
        key: 6,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 14,
        deviceName: '社内工程_1',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 7,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 15,
        deviceName: '社内工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 8,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 16,
        deviceName: '社内工程_3',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 9,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 17,
        deviceName: '社内工程_4',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '社内工程_4',
      },
      {
        key: 10,
        processType: 'カスタム',
        processName: '追加工程①',
        checked: true,
        deviceCodeKey: 18,
        deviceName: '社内工程_5',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: '社内工程_5',
      },
      {
        key: 11,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 19,
        deviceName: '社外工程_1',
        processDetailLst: [],
        companyCode: '00013',
        itakuFlag: true,
        addProType: '仮付',
      },
      {
        key: 12,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: 0,
        deviceCodeKey: 20,
        deviceName: '社外工程_2',
        processDetailLst: [],
        companyCode: '',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 13,
        processType: 'カスタム',
        processName: '追加工程②',
        checked: true,
        deviceCodeKey: 21,
        deviceName: '社外工程_3',
        processDetailLst: [],
        companyCode: '00014',
        itakuFlag: true,
        addProType: '水張検査',
      },
      {
        key: 14,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 15,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 16,
        processType: 'カスタム',
        processName: '追加工程③',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 17,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 18,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 19,
        processType: 'カスタム',
        processName: '追加工程④',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
      {
        key: 20,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '仮付',
      },
      {
        key: 21,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: 'ネスティング',
      },
      {
        key: 22,
        processType: 'カスタム',
        processName: '追加工程⑤',
        checked: 0,
        deviceCodeKey: '',
        deviceName: '',
        processDetailLst: [],
        companyCode: '0',
        itakuFlag: false,
        addProType: '水張検査',
      },
    ],
    created: '2023/07/06',
    modified: '2023/07/06',
  },
];
