/**
 * クラス名：ヘッダー画面
 * 説明：システムのヘッダー画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Card, Image, Space } from 'antd';
import React from 'react';
import logo from '../assets/images/logo.ico';
import user from '../assets/images/user.png';
import company from '../assets/images/company.png';
import usersetting from '../assets/images/usersetting.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: 'Header!' };
    this.loginUser = JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser')));
  }

  componentWillMount() {
    this.setState({
      text: 'Header',
    });
  }
  handleClick = (e) => {
    // window.location.href = '/login';
  };
  I;
  render() {
    return (
      <div style={{ backgroundColor: '#1063aa', display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            width: '60%',
          }}
        >
          <Space size="large">
            <Image preview={false} width={35} src={logo} style={{ marginLeft: '5px', marginTop: '0px' }}></Image>
            <h5 style={{ color: 'whitesmoke', marginLeft: '-13px', marginTop: '3px' }}>iQx-WebEstimate</h5>
          </Space>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            width: '39%',
          }}
        >
          <Image preview={false} width={32} src={company} style={{ marginLeft: '25px' }}></Image>
          <p
            style={{
              color: 'whitesmoke',
              marginLeft: '28px',
              fontSize: '14px',
              marginRight: '20px',
              marginTop: '10px',
            }}
          >
            {this.loginUser?.claims?.iQxWebEstimateClaim1}
          </p>
          {/* <Card style={{ display: 'flex', alignItems: 'center', width: 180 }}> */}
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Image preview={false} width={24} src={user}></Image>
            <p style={{ color: 'white', marginLeft: '5px', fontSize: '14px', fontWeight: 'bold', marginTop: '15px' }}>
              {this.loginUser?.userName}
            </p>
          </div>
          {/* </Card> */}
          <div
            onClick={this.handleClick}
            style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '5%', marginLeft: '10px' }}
          >
            <Image preview={false} width={37} src={usersetting}></Image>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
