/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { useEffect, useState, Input, Row, Table } from 'react';
import dayjs from 'dayjs';
import { Button, Input as antdInput } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import { Resizable } from 'react-resizable';

import { parentList, parentList_PMX_1, parentList_PMX_2 } from '../common/Constant';
import '../../assets/styles/common.css';
import { commonModal, error } from '../common/CommonModal';
import { ErrorMessage } from '../common/Message';
import { ClientType } from './enums';
import { element } from 'prop-types';

export const secondsToHms = (totalIncSec) => {
  if (totalIncSec > 0) {
    var h = Math.floor(totalIncSec / 3600).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var m = Math.floor((totalIncSec % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var s = Math.floor((totalIncSec % 3600) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return h + ':' + m + ':' + s;
  } else {
    return '00:00:00';
  }
};

export const setTimetoSec = (timeStr) => {
  if (timeStr == undefined || timeStr == '') return 0;
  var splitTime = timeStr.split(':');
  var seconds = +splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2];
  return seconds;
};

export const handelDecreaseTime = (time, timeType, idLbl) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min' && totalSec >= 600) {
    totalIncSec = totalSec - 600;
  } else if (timeType == '10min' && totalSec < 600) {
    totalIncSec = totalSec;
  } else if (timeType == 'min' && totalSec >= 60) {
    totalIncSec = totalSec - 60;
  } /* else if (timeType == 'min' && totalSec < 60) {
    totalIncSec = totalSec;
  }  */ else if (timeType == 'sec') {
    totalIncSec = totalSec - 10;
  }
  let timeStr = secondsToHms(totalIncSec);
  // checkInput(idLbl, timeStr);
  return timeStr;
};

export const handelIncreaseTime = (time, timeType, idLbl) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min') {
    totalIncSec = totalSec + 600;
  } else if (timeType == 'min') {
    totalIncSec = totalSec + 60;
  } else if (timeType == 'sec') {
    totalIncSec = totalSec + 10;
  }
  let timeStr = secondsToHms(totalIncSec);
  /*  console.log(idLbl, timeStr); */
  // checkInput(idLbl, timeStr);

  return timeStr;
};

export const checkInput = (id, input) => {
  let originalText = document.getElementById(id).textContent;
  var regexp = /^¥\d+(?:\.\d+)?$/;

  if (originalText !== input) {
    document.getElementById(id + 'In').style.color = 'red';
  } else {
    document.getElementById(id + 'In').style.color = '#000000';
  }
};

export const checkInputWithColor = (id, input, color) => {
  let originalText = document.getElementById(id).textContent;
  let originalTextNumber = 0;
  if (input === undefined) {
    input = 0;
  }
  if (typeof input === 'string' && !input.startsWith(':', 2)) {
    if (input.startsWith('¥', 0)) {
      input = input.replace('¥', '');
    }
    if (input.indexOf(',') > 0) {
      input = input.replace(',', '');
    }
    input = Number(input);
  }
  if (originalText.startsWith('¥', 0)) {
    originalText = originalText.replace('¥', '');
    if (originalText.indexOf(',') > 0) {
      originalText = originalText.replace(',', '');
    }
    originalTextNumber = Number(originalText);
  } else if (originalText.startsWith(':', 2)) {
    originalTextNumber = originalText;
  } else {
    originalTextNumber = Number(originalText);
  }
  var regexp = /^¥\d+(?:\.\d+)?$/;

  if (originalTextNumber != input && color === 'red') {
    document.getElementById(id + 'In').style.color = '#F4031E';
  } else if (originalTextNumber != input && color === 'green') {
    document.getElementById(id + 'In').style.color = '#4cc12f';
  } else if (originalTextNumber != input && color === 'amt') {
    document.getElementById(id + 'In').style.color = '#F4031E';
    let siblingEle = document.getElementById(id + 'In').nextSibling;
    siblingEle.firstChild.classList.remove('reset');
    document.getElementById(id + 'Cu').style.display = 'block';
  } else {
    document.getElementById(id + 'In').style.color = 'black';
  }
};

export const checkInputDDL = (id, input, color) => {
  let originalText = document.getElementById(id).textContent;
  if (originalText === undefined) {
    originalText = '';
  }
  if (input === undefined) {
    input = '';
  }
  let parent = document.getElementById(id + 'In').parentElement;
  if (originalText != input && color === 'red') {
    parent.nextSibling.style.color = '#F4031E';
    document.getElementById(id + 'In').style.color = '#F4031E';
  } else {
    parent.nextSibling.style.color = '#000000';
    document.getElementById(id + 'In').style.color = '#F4031E';
  }
};

export const checkDDLLbl = (id, input) => {
  /* console.log(document.getElementById(id).innerText); */

  let originalText;
  if (document.getElementById(id) != null) {
    originalText = document.getElementById(id).innerText;
  }

  if (originalText === undefined) {
    originalText = '';
  }
  if (input === undefined) {
    input = '';
  }
  let test;
  if (originalText != input) {
    test = true;
  } else {
    test = false;
  }
  return test;
};
export const totalEditData = (arr) => {
  var sum = 0;
  for (var i = 0; i < arr.length; i++) {
    sum += isNaN(parseFloat(arr[i])) ? 0 : parseFloat(arr[i]);
  }
  return sum;
};

export const formatCurrency = (input, blur) => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var input_val = input;

  // don't validate empty input
  if (input_val === '') {
    return;
  }

  // original length
  var original_len = input_val.length;

  // initial caret position
  //var caret_pos = input.prop('selectionStart');

  // check for decimal
  if (input_val.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    //var decimal_pos = input_val.indexOf('.');

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      right_side += '00';
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = '$' + left_side + '.' + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = '$' + input_val;

    // final formatting
    if (blur === 'blur') {
      input_val += '.00';
    }
  }

  // send updated string to input
  input.val(input_val);

  // put caret back in the right position
  var updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0].setSelectionRange(caret_pos, caret_pos);
};

export const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getInitDataForProcess = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 0, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 3, type: 3 }],
          process3: [{ no: 5, type: 3 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 1 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 0 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const getInitDataForProcess2 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess3 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess4 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess5 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const getInitDataForProcess_PMX_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 0 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 0, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 5, type: 3 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 0, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_1_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_2 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 0,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 5, type: 3 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 0, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 1 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_2_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    }).map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const patternPage = [
  { mode: '1', page: '親部品' },
  { mode: '2', page: '板金' },
  { mode: '3', page: 'パラメータ' },
];

export const getTenjikaiSampleData = () => {
  // 親部品リスト
  // const parentList = [];
  // parentList.push(parentList);

  // サンプルデータ纏め
  const sampleData = {
    key: 1,
    parentList: parentList,
    parentList_PMX_1: parentList_PMX_1,
    parentList_PMX_2: parentList_PMX_2,
  };
  return sampleData;
};
export const isNumber = (char) => {
  return /^\d$/.test(char);
};

export const checkShowHideIcon = (id) => {
  let showHideCu = document.getElementById(id).style.display;
  return showHideCu;
};

export const JPYs = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',
});

export const Percent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
});

export const kouteiCalculateSelectedArr = [
  { key: 0, value: 'データ' },
  { key: 1, value: '編集データ' },
];
export const dataStateList = [
  { value: 0, label: '全て' },
  { value: 1, label: '表示' },
  { value: 2, label: '非表示' },
];
export const flgStateList = [
  { value: 0, label: '全て' },
  { value: 1, label: 'オン' },
  { value: 2, label: 'オフ' },
];
const parameterCostLst = [
  {
    key: 1,
    title: '諸経費',
    data: 0,
    dataIndex: 'costLst1',
  },
  {
    key: 2,
    title: '特別費',
    data: 0,
    dataIndex: 'costLst2',
  },
  {
    key: 3,
    title: '調査費​',
    data: 0,
    dataIndex: 'costLst3',
  },
  {
    key: 4,
    title: 'カスタマイズ費用',
    data: 0,
    dataIndex: 'costLst4',
  },
];
const parameterDateLst = [
  {
    key: 1,
    title: '社内納期',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst1',
  },
  {
    key: 2,
    title: '回答日',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst2',
  },
  {
    key: 3,
    title: 'カスタマイズ日付',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst3',
  },
];
const parameterLabelLst = [
  {
    key: 1,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst1',
  },
  {
    key: 2,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst2',
  },
  {
    key: 3,
    title: 'カスタマイズラベル',
    data: '',
    dataIndex: 'labelLst3',
  },
];
const parameterMemoLst = [
  {
    key: 1,
    title: '注意事項',
    data: '',
    dataIndex: 'memoLst1',
  },
  {
    key: 2,
    title: '見積備考',
    data: '',
    dataIndex: 'memoLst2',
  },
  {
    key: 3,
    title: 'コメント',
    data: '',
    dataIndex: 'memoLst3',
  },
  {
    key: 4,
    title: 'カスタマイズメモ',
    data: '',
    dataIndex: 'memoLst4',
  },
];
export const getNewParentInfo = (key) => {
  return {
    key: key > 0 ? key - 1 : 0,
    id: key,
    customerCode: '',
    customerNm: '初期値',
    imgNo: '初期値',
    name: '初期値',
    lotNum: 1,
    grossProfit: 0,
    rate: 0,
    unitPrice: 0,
    estimatedAmount: 0,
    materialAmount: 0,
    matufacturingAmount: 0,
    setupAmount: 0,
    processingAmount: 0,
    purchaseCost: 0,
    managementCost: 0,
    isOrder: '単発',
    deliveryType: '普通',
    img: '',
    allowGaichu: '受注',
    sizeX: '0.00',
    sizeY: '0.00',
    sizeZ: '0.00',
    childPartTotalWeight: 0,
    iq3Weight: 0,
    iq5Weight: 0,
    iq7Weight: 0,
    childPartTypeCnt: 0,
    iq3TypeCnt: 0,
    iq5TypeCnt: 0,
    iq7TypeCnt: 0,
    childPartTotalProduceCnt: 0,
    iq3TotalProduceCnt: 0,
    iq5TotalProduceCnt: 0,
    iq7TotalProduceCnt: 0,
    kumitate: 0,
    inspection: 0,
    packing: 0,
    temporaryAttachment: 0,
    outsourcing: 0,
    polishing: 0,
    comment: '',
    estimateNo: '初期値',
    estimator: '石田 浩太郎',
    creator: '石田 浩太郎',
    latestUpdator: '石田 浩太郎',
    createdDate: 'YYYY-MM-DD',
    updatedDate: 'YYYY-MM-DD',
    costLst: parameterCostLst,
    dateLst: parameterDateLst,
    labelLst: parameterLabelLst,
    memoLst: parameterMemoLst,
    customerCoefficient: '1.00',
    managementCoefficient: '1.00',
    materialLossCoefficient: '1.00',
    orderCoefficient: '1.00',
    setupCoefficient: '1.00',
    surfaceAreaCoefficient: '1.00',
    purchaseCoefficient: '1.00',
    deliveryCoefficient: '1.00',
    orderCoefficient: '1.00',
    shippingMethod: '普通',
    shippingArea: '普通',
    weight: 0,
    surfaceArea: '0.00',
    parentProcess: [],
    iq3Process: [],
    iq5Process: [],
    iq3MaterialAmount: 0,
    iq5MaterialAmount: 0,
    iq3DataLst: [],
    volume: 0,
    costPrice: 0,
    totalCostPrice: 0,
    estimatedAmount: 0,
    totalEstimatedAmount: 0,
    costProfit: 0,
    estimateProfit: 0,
    materialCost: 0,
    processCost: 0,
    purchaseCost: 0,
    hiyou: 0,
    kouteiCalInfoData: [],
    kouteiCalInfoEditData: [],
  };
};

// 現在の日付「YYYY/MM/DD」
export const today = new Date().toLocaleString('ja-JP', {
  timeZone: 'Asia/Tokyo',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});
// YYYY/MM/DDへ変換
export function formatDate(date) {
  return new Date(date).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

/* export const zaishittsuNameDDL = [
  { value: 1, label: 'SPCC' },
  { value: 2, label: 'SPHC（黒皮）' },
  { value: 3, label: 'SPHC（酸洗）' },
  { value: 4, label: 'SECC（ボンデ）' },
  { value: 5, label: 'SS400' },
  { value: 6, label: 'ZAM' },
  { value: 7, label: 'SUS304/2B' },
  { value: 8, label: 'SUS304/NO1' },
  { value: 9, label: 'SUS304/#400' },
  { value: 10, label: 'SUS304/両面#400' },
  { value: 11, label: 'SUS304/HL' },
  { value: 12, label: 'SUS304/両面HL' },
  { value: 13, label: 'SUS304/BA' },
  { value: 14, label: 'SUS316' },
  { value: 15, label: 'SUS316L' },
  { value: 16, label: 'SUS430/2B' },
  { value: 17, label: 'SUS430/BA' },
  { value: 18, label: 'SUS430/#400' },
  { value: 19, label: 'SUS430/HL' },
  { value: 20, label: 'A1050' },
  { value: 21, label: 'A1100' },
  { value: 22, label: 'A5052' },
  { value: 23, label: 'C1100' },
]; */

export const getZaishittsuNameDDL = () => {
  let materialClassNameStorage = getMaterialClassNameStorage();
  const zaishittsuNameDDL = [];
  let count = 0;
  materialClassNameStorage?.forEach((item) => {
    if (item.displayFlag) {
      zaishittsuNameDDL.push({ value: count++, label: item.materialName });
    }
  });
  /*  console.log(kubunNameDDL); */
  return zaishittsuNameDDL;
};

export const materialRefList = [
  { value: 1, label: '鉄' },
  { value: 2, label: 'ステンレス' },
  { value: 3, label: 'アルミ' },
  { value: 4, label: '銅' },
  { value: 5, label: 'その他' },
];

export const getKubunNameDDL = () => {
  let materialClassStorageData = getMaterialClassDataSample();
  const kubunNameDDL = [];
  let count = 0;
  materialClassStorageData?.forEach((item) => {
    if (item.displayFlag) {
      kubunNameDDL.push({ value: count++, label: item.classificationName });
    }
  });
  return kubunNameDDL;
};

// 工程パターン画面のCarousel操作
const iconClassName_inactive = 'carousel-circleOutlined-inactive';
const iconClassName_active = 'carousel-circleOutlined-active';
let iconClassName_pre = iconClassName_active;
let iconClassName_next = iconClassName_active;
const slickActive = 'slick-active';

export const CarouselPrevArrow = (props) => {
  const { className, style, onClick, targetDivCnt, name } = props;

  const onChangePrevArrow = () => {
    let activeDiv = [];
    let activeDivIndex = 0;
    activeDiv = document.getElementById(name).getElementsByClassName(slickActive);
    if (activeDiv.length > 1) activeDivIndex = activeDiv[activeDiv.length - 1].innerText;
    let lastDivIndex = parseInt(activeDivIndex) - 1;
    if (activeDivIndex == 1) {
      // iconClassName_pre = iconClassName_inactive;
    } else if (activeDivIndex <= targetDivCnt) {
      // iconClassName_next = iconClassName_active;
      onClick();
      // if (lastDivIndex === 1) {
      //   iconClassName_pre = iconClassName_inactive;
      // } else {
      //   iconClassName_pre = iconClassName_active;
      // }
    }
  };

  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onChangePrevArrow}
      icon={<LeftCircleOutlined id="leftCarouselCircle" className={iconClassName_pre} />}
    />
  );
};
export const CarouselNextArrow = (props) => {
  const { className, style, onClick, targetDivCnt, name } = props;

  const onChangeNextArrow = () => {
    let activeDiv = [];
    let activeDivIndex = 0;
    activeDiv = document.getElementById(name).getElementsByClassName(slickActive);
    if (activeDiv.length > 1) activeDivIndex = activeDiv[activeDiv.length - 1].innerText;
    let lastDivIndex = parseInt(activeDivIndex) + 1;

    if (activeDivIndex < targetDivCnt) {
      // iconClassName_pre = iconClassName_active;
      onClick();
      // if (lastDivIndex === targetDivCnt) {
      //   iconClassName_next = iconClassName_inactive;
      // } else {
      //   iconClassName_next = iconClassName_active;
      // }
    } else {
      // iconClassName_next = iconClassName_inactive;
    }
  };

  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onChangeNextArrow}
      icon={<RightCircleOutlined id="rightCarouselCircle" className={iconClassName_next} />}
    />
  );
};

// export const initialCarouselBtn = () => {
//   let leftCircleList = document.getElementsByClassName('anticon-left-circle');
//   Object.keys(leftCircleList).map((anticonLeftCircle, j) => {
//     leftCircleList[anticonLeftCircle].classList.remove(iconClassName_active);
//     leftCircleList[anticonLeftCircle].classList.add(iconClassName_inactive);
//   });
//   let rightCircleList = document.getElementsByClassName('anticon-right-circle');
//   Object.keys(rightCircleList).map((anticonRightCircle, j) => {
//     rightCircleList[anticonRightCircle].classList.remove(iconClassName_inactive);
//     rightCircleList[anticonRightCircle].classList.add(iconClassName_active);
//   });
// };

export function groupBy(items) {
  return items.reduce((acc, curr) => {
    if (curr.detailGroup) {
      const { detailGroup } = curr;
      const currentItems = acc[detailGroup];

      return {
        ...acc,
        [detailGroup]: currentItems ? [...currentItems, curr] : [curr],
      };
    }
    return acc;
  }, {});
}

// 装置の段取時間更新
export const souchiHandleIncreaseTime = (time, timeType) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min') {
    totalIncSec = totalSec + 600;
  } else if (timeType == 'min') {
    totalIncSec = totalSec + 60;
  } else if (timeType == 'sec') {
    totalIncSec = totalSec + 10;
  }
  let timeStr = secondsToHms(totalIncSec);

  return timeStr;
};
export const souchiHandleDecreaseTime = (time, timeType) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min' && totalSec >= 600) {
    totalIncSec = totalSec - 600;
  } else if (timeType == '10min' && totalSec < 600) {
    totalIncSec = totalSec;
  } else if (timeType == 'min' && totalSec >= 60) {
    totalIncSec = totalSec - 60;
  } /* else if (timeType == 'min' && totalSec < 60) {
    totalIncSec = totalSec;
  }  */ else if (timeType == 'sec') {
    totalIncSec = totalSec - 10;
  }
  let timeStr = secondsToHms(totalIncSec);

  return timeStr;
};

export const actionArr = [
  {
    key: 0,
    methodName: 'rowSelect',
  },
  {
    key: 1,
    methodName: 'searchByKeyword',
  },
  {
    key: 2,
    methodName: 'handleGpEdit',
  },
  {
    key: 3,
    methodName: 'handleAddFormSubmit',
  },
  {
    key: 4,
    methodName: 'searchByDataState',
  },
  {
    key: 5,
    methodName: 'searchByShowFlg',
  },
];

export const removeYen = (value) => {
  let noYen = String(value);
  if (value === '' || value === undefined) {
    noYen = '0';
  }
  if (value.startsWith('¥', 0)) {
    noYen = noYen.replace('¥', '');
  }
  noYen = noYen.replace(/,/g, '');
  return noYen;
};

export const { TextArea } = antdInput;

// メールフォーマットのチェック
export function isEmail(val) {
  // ####@####の形式
  let regEmail = /^[^\s]+@[^\s]+$/;
  return regEmail.test(val);
}

// 数字のチェック
export function isDigit(val) {
  // 整数値のみ
  let regDigit = /^[0-9]+$/;
  return regDigit.test(val);
}

// Tel又Faxのチェック
export function isTelAndFax(val) {
  // プラス、ハイフン、整数値のみ
  let regStr = /^[0-9+-]+$/;
  return regStr.test(val);
}

// 小数点のチェック
export function isDecimal(val) {
  // 小数点
  let regStr = /^\d*\.?\d*$/;
  return regStr.test(val);
}

// 入力項目のチェックを行う処理タイプ
export const formValidatorMode = {
  Create: 1, // 新規作成
  Edit: 2, // 編集
  BulkEdit: 3, // 一括編集
};

// セクションにトークン情報を更新する
export const updateAccessToken = (token) => {
  localStorage.setItem('iQxWeb_AccessToken', token);
};

const releaseDate = '2023-10-01';
export const getMaterialClassDataSample = () => {
  let count = 0;
  const materialClassDataSample = [
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SPHC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SPCC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SECC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SUS',
      materialRefPrice: 'ステンレス',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'AL',
      materialRefPrice: 'アルミ',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'CU',
      materialRefPrice: '銅',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
  ];
  if (
    localStorage.getItem('materialClassStorageData') === null ||
    localStorage.getItem('materialClassStorageData').length <= 0
  ) {
    localStorage.setItem('materialClassStorageData', JSON.stringify(materialClassDataSample));
  }
  return JSON.parse(localStorage.getItem('materialClassStorageData'));
};

export const getMaterialClassNameStorage = () => {
  let count = 0;

  const materialClassNameDataSample = [
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPCC',
      materialClassName: 'SPCC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '冷延鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPHC（黒皮）',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '熱延鋼材：黒皮',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPHC（酸洗）',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '熱延鋼材：酸洗',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SECC（ボンデ）',
      materialClassName: 'SECC',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ボンデ鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SS400',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '一般構造用圧延鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'ZAM',
      materialClassName: 'SECC',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/2B',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/NO1',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/両面#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/両面HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/BA',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS316',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS316L',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/2B',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/BA',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A1050',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A1100',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A5052',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'C1100',
      materialClassName: 'CU',
      gravity: 8.0,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
  ];
  if (
    localStorage.getItem('materialClassNameStorage') === null ||
    localStorage.getItem('materialClassNameStorage').length <= 0
  ) {
    localStorage.setItem('materialClassNameStorage', JSON.stringify(materialClassNameDataSample));
  }

  return JSON.parse(localStorage.getItem('materialClassNameStorage'));
};

export const getMaterialNameStorage = () => {
  let count = 0;

  const materialNameDataSample = [
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.4',
      materialName: 'SPCC',
      thickness: 0.4,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [{ updId: 0, updateDate: releaseDate, updPrice: 120 }],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC-  0.51',
      materialName: 'SPCC',
      thickness: 0.5,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.6',
      materialName: 'SPCC',
      thickness: 0.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.7',
      materialName: 'SPCC',
      thickness: 0.7,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.8',
      materialName: 'SPCC',
      thickness: 0.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.9',
      materialName: 'SPCC',
      thickness: 0.9,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.0',
      materialName: 'SPCC',
      thickness: 1,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.2',
      materialName: 'SPCC',
      thickness: 1.2,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.4',
      materialName: 'SPCC',
      thickness: 1.4,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.6',
      materialName: 'SPCC',
      thickness: 1.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.8',
      materialName: 'SPCC',
      thickness: 1.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.0',
      materialName: 'SPCC',
      thickness: 2,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.3',
      materialName: 'SPCC',
      thickness: 2.3,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.5',
      materialName: 'SPCC',
      thickness: 2.5,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.6',
      materialName: 'SPCC',
      thickness: 2.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.8',
      materialName: 'SPCC',
      thickness: 2.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.9',
      materialName: 'SPCC',
      thickness: 2.9,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
  ];
  if (localStorage.getItem('materialNameStorage') === null || localStorage.getItem('materialNameStorage').length <= 0) {
    localStorage.setItem('materialNameStorage', JSON.stringify(materialNameDataSample));
  }

  return JSON.parse(localStorage.getItem('materialNameStorage'));
};

export const getMaterialSizeStorage = () => {
  let count = 0;

  const materialSizeDataSample = [
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: 'Other',
      sizeX: 0,
      sizeY: 0,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "3'x6'",
      sizeX: 1829,
      sizeY: 914,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "4'x8'",
      sizeX: 1829,
      sizeY: 914,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "5'x10'",
      sizeX: 3048,
      sizeY: 1524,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '1x2m',
      sizeX: 2000,
      sizeY: 1000,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '2x4m',
      sizeX: 4000,
      sizeY: 2000,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '1.25x2.5m',
      sizeX: 2500,
      sizeY: 1250,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '小板',
      sizeX: 1000,
      sizeY: 365,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: 'free',
      sizeX: 0,
      sizeY: 0,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
  ];
  if (localStorage.getItem('materialSizeStorage') === null || localStorage.getItem('materialSizeStorage').length <= 0) {
    localStorage.setItem('materialSizeStorage', JSON.stringify(materialSizeDataSample));
  }

  return JSON.parse(localStorage.getItem('materialSizeStorage'));
};

export const getMaterialSurfaceStorage = () => {
  let count = 0;

  const materialSurfaceDataSample = [
    {
      key: 0,
      no: 1,
      displayFlag: true,
      materialName: 'SPCC',
      surfaceName: '片面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: 4000,
      additionalAmount: '',
      created: '2023-10-01',
      modified: '2023-07-10',
    },
    {
      key: 1,
      no: 2,
      displayFlag: true,
      materialName: 'SUS304/2B',
      surfaceName: '片面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: 4000,
      additionalAmount: '',
      remark: '',
      created: '2023-07-10',
      modified: '2023-07-10',
    },
    {
      key: 2,
      no: 3,
      displayFlag: true,
      materialName: 'SUS304/2B',
      surfaceName: '両面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: '2000',
      additionalAmount: '',
      remark: '',
      created: '2023-07-10',
      modified: '2023-07-10',
    },
  ];
  if (
    localStorage.getItem('materialSurfaceStorage') === null ||
    localStorage.getItem('materialSurfaceStorage').length <= 0
  ) {
    localStorage.setItem('materialSurfaceStorage', JSON.stringify(materialSurfaceDataSample));
  }

  return JSON.parse(localStorage.getItem('materialSurfaceStorage'));
};

// 小数点１へ変換する
export const toDecimal = (value, places = 1) => {
  if (!isDecimal(value)) return value;
  if (value.length <= 0) value = 0;
  return parseFloat(value).toFixed(places);
};

// テーブル列の幅Resizable
export const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

// 破壊ポップアップを表示させる処理タイプ
export const discardChange = [
  { no: 1, state: 'rowSelect' },
  { no: 2, state: 'search' },
  { no: 3, state: 'bulkEdit' },
  { no: 4, state: 'createNew' },
];

//　係数値下げ、上げ計算
export const increaseDecreaseKeisu = (value, increaseFlag) => {
  let targetValue = parseFloat(value).toFixed(2);
  if (increaseFlag) {
    targetValue = targetValue + 1;
  } else {
    targetValue = targetValue - 1;
  }
  return targetValue;
};

// export const enumString = (id) => {
//   // Object.keys(ClientType).forEach((name) => {
//   //   // alert(ClientType[name]);
//   //   if (ClientType[name] === id) alert(name);
//   // });

//   // var keys = Object.keys(ClientType).reduce(function (acc, key) {
//   //   return (acc[ClientType[key]] = key), acc;
//   // }, {});

//   let result = id & ClientType.Customer;
//   result = id & ClientType.Supplier;
//   result = id & ClientType.Contractor;
//   console.log('result', result);
// };

export const isCustomer = (value) => {
  if ((value & ClientType.Customer) === ClientType.Customer) return true;
  return false;
};

export const isSupplier = (value) => {
  if ((value & ClientType.Supplier) === ClientType.Supplier) return true;
  return false;
};

export const isContractor = (value) => {
  if ((value & ClientType.Contractor) === ClientType.Contractor) return true;
  return false;
};
// 装置設定
export const deleteListColData = (list, deletedId) => {
  const deleteData = list.map((item) => {
    return {
      ...item,
      lstData: item.lstData.filter((it) => !deletedId.includes(it.key)),
    };
  });
  return deleteData;
};
export const updateListColData = (list, newId) => {
  const updatedData = list.map((item) => {
    newId.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '',
      });
    });

    return item;
  });
  return updatedData;
};
export const sortListData = (list, masterData) => {
  const sortedData = list.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = masterData.findIndex((obj) => obj.id === a.key);
      const bIndex = masterData.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      materialType: item.materialType,
      lstData: sortedLstData,
    };
  });
  return sortedData;
};

export const getProcessDetailNameList = (type) => {
  let kouteiMaster =
    window.localStorage.getItem('kouteiMasterData') != undefined
      ? JSON.parse(window.localStorage.getItem('kouteiMasterData'))
      : [];
  let processDetail = [];
  kouteiMaster.forEach((element) => {
    if (element.type === type) {
      processDetail = element.processDetail;
    }
  });
  let detailTypes = [];
  processDetail.forEach((element) => {
    let processDetailValue = element.processDetailValue;
    processDetailValue.forEach((e) => {
      detailTypes.push(e.detailType);
    });
  });
  return detailTypes;
};

// 最新のセクションにトークン情報を取得する
export const getAccessToken = () => {
  return localStorage?.getItem('iQxWeb_AccessToken');
};
